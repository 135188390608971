import React, { FC } from 'react';

import { Controller } from 'react-hook-form';

import ReactSelect from 'components/common/ui-kit/Select';
import ProfileForm from 'components/profile/ProfileForm';
import ProfileSection from 'components/profile/ProfileSection';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import useLazyEnumSelectProps from 'hooks/common/useI18nEnumSelectProps';
import useProfileRoutesContext from 'hooks/profile/useProfileRoutesContext';
import useLegalRepresentativeForm from 'hooks/questionnaire/legalRepresentativeDraft/useLegalRepresentativeForm';
import terms from 'i18n';
import { useGetMobileCountryCodesQuery } from 'redux/api/common/enum-api';
import { relationDegreeSelectOptions } from 'utils/enum-select';
import { createInputV2Props, createSelectProps } from 'utils/form-helpers';

import useUploadFiles from '../../../hooks/files/useUploadFiles';
import { selectIsDraftReadonly } from '../../../redux/selectors/enrollee/questionnaire';
import regexp from '../../../utils/regexp';
import { InputV2 } from '../../common/ui-kit/Input/view';

const LegalRepresentativeForm: FC = () => {
  const { onNextPageNavigate } = useProfileRoutesContext();
  const readOnly = useAppSelector(selectIsDraftReadonly);
  const dispatch = useAppDispatch();

  const {
    fields,
    control,
    onSubmit,
    required,
    formState: { errors },
    onDownloadTemplateDoc,
    watch,
  } = useLegalRepresentativeForm(onNextPageNavigate);

  const {
    files: docs,
    onDrop,
    onChange,
    onDelete,
    uploadFiles: uploadDocs,
  } = useUploadFiles(
    terms.CONSENT_OF_PERSONAL_DATA_PROCESSING,
    watch('personalDataConsentId')
  );

  const mobileCountryCodeSelectProps = useLazyEnumSelectProps(
    useGetMobileCountryCodesQuery
  );

  const inputV2Props = createInputV2Props(errors, readOnly);
  const selectProps = createSelectProps(errors, readOnly);

  return (
    <ProfileForm
      onSubmit={
        readOnly
          ? e => {
              e.preventDefault();
              onNextPageNavigate();
            }
          : e => {
              e.preventDefault();
              onSubmit({ personalDataConsentId: undefined })(e);
              /* if (Object.keys(errors).length === 0) {
                if (docs.length) {
                  uploadDocs()
                    .then(fileId => {
                      onSubmit({ personalDataConsentId: fileId })(e);
                    })
                    .catch(error => {
                      if (required) {
                        if (error === FileUploadError.FILE_NOT_ATTACHED)
                          dispatch(
                            createToast(
                              terms.CONSENT_OF_PERSONAL_DATA_IS_REQUIRED,
                              'danger'
                            )
                          );
                        return;
                      }
                      onSubmit({ personalDataConsentId: undefined })(e);
                    });
                } else {
                  onSubmit({ personalDataConsentId: undefined })(e);
                }
              }*/
            }
      }
    >
      <ProfileSection title={terms.BASIC_DATA}>
        <div className="profile-form__fields">
          <Controller
            name={fields.surname.name}
            control={control}
            rules={{
              pattern: {
                value: regexp.ruOrEnWithSpecialSigns,
                message: terms.CYRILLIC_OR_LATIN_WITH_SPECIALS_CHARACTERS_ONLY,
              },
            }}
            render={({ field }) => (
              <InputV2
                className="profile-form__field"
                placeholder={terms.SURNAME}
                markRequired={required}
                {...inputV2Props(field)}
              />
            )}
          />

          <Controller
            name={fields.name.name}
            control={control}
            rules={{
              pattern: {
                value: regexp.ruOrEnWithSpecialSigns,
                message: terms.CYRILLIC_OR_LATIN_WITH_SPECIALS_CHARACTERS_ONLY,
              },
            }}
            render={({ field }) => (
              <InputV2
                className="profile-form__field"
                placeholder={terms.NAME}
                markRequired={required}
                {...inputV2Props(field)}
              />
            )}
          />

          <Controller
            name={fields.patronymic.name}
            control={control}
            rules={{
              pattern: {
                value: regexp.ruOrEnWithSpecialSigns,
                message: terms.CYRILLIC_OR_LATIN_WITH_SPECIALS_CHARACTERS_ONLY,
              },
            }}
            render={({ field }) => (
              <InputV2
                className="profile-form__field"
                placeholder={terms.PATRONYMIC}
                {...inputV2Props(field)}
              />
            )}
          />

          {/* <Controller
            name={fields.birthday.name}
            control={control}
            render={({ field }) => (
              <InputV2
                className="profile-form__field"
                placeholder={terms.DATE_OF_BIRTH}
                markRequired={required}
                type="date"
                max="100"
                min="10"
                {...inputV2Props(field)}
              />
            )}
          />*/}

          <div className="profile-form__field profile-form__field--container">
            <Controller
              name="mobileCountryCode"
              control={control}
              render={({ field }) => (
                <ReactSelect
                  className="profile-form__input profile-form__input--fb-33"
                  styles={{ menu: base => ({ ...base, minWidth: '300px' }) }}
                  placeholder={terms.COUNTRY_CODE}
                  markRequired={required}
                  isClearable
                  {...selectProps(field, mobileCountryCodeSelectProps)}
                />
              )}
            />

            <Controller
              name={fields.mobile.name}
              control={control}
              render={({ field }) => (
                <InputV2
                  className="profile-form__input profile-form__input--fb-66"
                  placeholder={terms.MOBILE_PHONE}
                  markRequired={required}
                  type="tel"
                  {...inputV2Props(field)}
                />
              )}
            />
          </div>

          <Controller
            name="relationDegree"
            control={control}
            render={({ field }) => (
              <ReactSelect
                isClearable
                placeholder={terms.RELATIONSHIP}
                className="profile-form__field"
                markRequired={required}
                {...selectProps(field, {
                  options: relationDegreeSelectOptions,
                })}
              />
            )}
          />
        </div>
      </ProfileSection>

      {/* <ProfileSection>
        <Button type="button" onClick={onDownloadTemplateDoc}>
          {terms.CONSENT_OF_PERSONAL_DATA_PROCESSING}
        </Button>
      </ProfileSection>

      <ProfileSection
        title={terms.CONSENT_OF_PERSONAL_DATA_PROCESSING}
        markRequired={required}
      >
        <FileUploader
          files={docs}
          disabled={readOnly}
          onChange={onChange}
          onDrop={onDrop}
          onDelete={onDelete}
          className="profile-form__file-uploader"
          description={terms.CONSENT_OF_PERSONAL_DATA_PROCESSING}
        />
      </ProfileSection>*/}
    </ProfileForm>
  );
};

export default LegalRepresentativeForm;
