import React, { FC, useMemo } from 'react';

import { Navigate } from 'react-router-dom';

import Loader from 'components/common/ui-kit/Loader';
import useAuthInitialization from 'hooks/auth/useAuthInitialization';
import useAppSelector from 'hooks/common/useAppSelector';
import { UserRole } from 'models/user';
import { selectUser } from 'redux/selectors';
import { ChildrenProps } from 'utils/default-props';

const RequireStaffRole: FC<ChildrenProps> = ({ children }) => {
  const { token, loading } = useAuthInitialization();

  const { id, role } = useAppSelector(selectUser);

  const isStaff = useMemo(() => role !== UserRole.USER, [role]);

  if (loading) {
    return <Loader />;
  }

  if (!token) {
    return <Navigate to="/admin/sign-in" />;
  }

  if (!isStaff && id !== null) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

export default RequireStaffRole;
