import React, { FC, useState } from 'react';

import classNames from 'classnames';

import Button from 'components/common/ui-kit/Button';
import Checkbox from 'components/common/ui-kit/Checkbox';
import { BLANK_RECEIPT_DOCUMENTS } from 'config/constants';
import terms from 'i18n';

import useAppSelector from '../../../hooks/common/useAppSelector';
import useCardForDownloadFile from '../../../hooks/statements/documents/useCardForDownloadFile';
import { ApplicationDocument } from '../../../models/applications/application-documents';
import { selectIsApplicationDirectionsReadonly } from '../../../redux/selectors/enrollee/application';
import { getLocalDateTimeFormattedString } from '../../../utils/date';
import { useIsImpersonate } from '../../../utils/domain/impersonate';

type CardForDownloadFileProps = {
  className?: string;
  showCard: boolean;
  title: string;
  date?: string;
  direction?: string;
  type?: string;
  blankId?: number;
  acceptBlank: boolean;
  code?: string;
  refetchDocuments?: () => void;
  document?: ApplicationDocument;
  onDownload?: () => void;
};

/*
 * document - ApplicationDocument, для которого скачивается файл
 * onDownload - альтернативная функция, вызываемая если не указывается document
 */
const CardForDownloadFile: FC<CardForDownloadFileProps> = ({
  className,
  showCard,
  title,
  date,
  direction,
  blankId,
  acceptBlank,
  code,
  refetchDocuments,
  document,
  onDownload,
}) => {
  const [isSignable, setSignable] = useState(false);
  const isImpersonate = useIsImpersonate();
  const isSubmittable =
    useAppSelector(selectIsApplicationDirectionsReadonly) && !isImpersonate;

  const { handleClickDownloadDocs, subscribeBlank } = useCardForDownloadFile(
    acceptBlank,
    blankId,
    title,
    refetchDocuments,
    () => setSignable(true)
  );

  return (
    <div className={classNames('card', className)}>
      <span className="card__header">{title}</span>
      {date && (
        <span className="card__content">
          {terms.APPLICATION_DATE}:{' '}
          <strong>{getLocalDateTimeFormattedString(date)}</strong>
        </span>
      )}{' '}
      {direction && (
        <div className="card__content-direction">
          {' '}
          <span className="card__content">
            {terms.ADMISSION_CAMPAIGN} / {terms.DIRECTION}:{' '}
          </span>
          <div>
            <strong>{direction}</strong>
          </div>
        </div>
      )}
      <div className="card__download">
        <Button
          type="button"
          disabled={!showCard}
          onClick={() => {
            if (document) handleClickDownloadDocs(document);
            else if (onDownload) onDownload();
          }}
        >
          {terms.APPLICATION_DOWNLOAD}
        </Button>
        {code !== undefined && (
          <span className="card__download-text">
            {code !== String(BLANK_RECEIPT_DOCUMENTS)
              ? terms.HINT_APPLICATION_DOWNLOAD
              : terms.HINT_RECEIPT_DOWNLOAD}
          </span>
        )}
      </div>
      {code !== undefined && (
        <div className="card__signature">
          {code !== String(BLANK_RECEIPT_DOCUMENTS) && (
            <Checkbox
              className={acceptBlank ? 'active' : ''}
              disabled={isSubmittable || !showCard || !isSignable}
              theme="success-filled"
              checked={acceptBlank}
              onChange={e => subscribeBlank(e.target.checked)}
              text={terms.SING}
              cardSignature
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CardForDownloadFile;
