import React, { CSSProperties, FC } from 'react';

import classNames from 'classnames';

import { DataList, DataListItemAlignment } from 'models/moderator';

type ModeratorDataListProps = {
  list: DataList;
  style?: CSSProperties;
};

const ModeratorDataList: FC<ModeratorDataListProps> = ({ list, style }) => (
  <div className="moderator-data-list" style={style}>
    {list.map((el, index) => (
      <div
        key={index}
        className={classNames('moderator-data-list__element', {
          'moderator-data-list__element--align-start':
            el.alignment === DataListItemAlignment.START,
        })}
      >
        <p>{el.title}</p>
        <span>{el.label}</span>
      </div>
    ))}
  </div>
);

export default ModeratorDataList;
