import React, { forwardRef, useEffect, useId, useRef, useState } from 'react';

import classNames from 'classnames';

import { ReactComponent as EyeIcon } from 'assets/images/icons/eye.svg';
import { ReactComponent as SearchSVG } from 'assets/images/icons/search.svg';
import { getRandomString } from 'utils/common';
import mergeRefs from 'utils/merge-refs';

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  inputClassName?: string;
  hint?: string;
  enableSwitcher?: boolean;
  markRequired?: boolean;
  error?: string;
  search?: boolean;
};

type InputV2Props = React.InputHTMLAttributes<HTMLInputElement> & {
  inputClassName?: string;
  hint?: string;
  enableSwitcher?: boolean;
  markRequired?: boolean;
  error?: string;
  search?: boolean;
};

/**
 * Updated version of Input without using refs
 *
 * @param placeholder
 * @param error
 * @param hint
 * @param inputClassName
 * @param enableSwitcher
 * @param markRequired
 * @param search
 * @param rest
 * @constructor
 */
export const InputV2 = ({
  placeholder,
  error,
  hint,
  inputClassName,
  enableSwitcher = false,
  markRequired = false,
  search = false,
  ...rest
}: InputV2Props) => {
  const id = placeholder + getRandomString();
  const [active, setActive] = useState(false);
  const [isSecret, setIsSecret] = useState(true);
  const [initialized, setInitialized] = useState(false);

  const handleFocusOut = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setActive(false);
    }
  };

  const initializeState = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!initialized) {
      setInitialized(true);
      if (e.target.value) {
        setActive(true);
      }
    }
  };

  useEffect(() => {
    if (rest.value && !active) {
      setActive(true);
    }

    if (!rest.value) {
      setActive(false);
    }
  }, [rest.value]);

  useEffect(() => {
    if (rest.disabled && !rest.value) {
      setActive(false);
    }
  }, [!rest.value, rest.disabled]);

  return (
    <div
      className={classNames('input-container', rest.className, {
        'input-container--active':
          active ||
          rest.type === 'date' ||
          rest.type === 'time' ||
          rest.type === 'datetime-local',
      })}
    >
      <div className="input-container__inner">
        <input
          {...rest}
          value={rest.value || ''}
          type={enableSwitcher && isSecret ? 'password' : rest.type || 'text'}
          onFocus={e => {
            rest.onFocus?.(e);
            setActive(true);
          }}
          onBlur={e => {
            rest.onBlur?.(e);
            handleFocusOut(e);
          }}
          onChange={e => {
            rest.onChange?.(e);
            initializeState(e);
          }}
          className={classNames(
            'input-container__input',
            inputClassName && inputClassName,
            { 'input-container__input--error': Boolean(error) }
          )}
          id={id}
        />
        {enableSwitcher && (
          <EyeIcon
            width="16"
            height="11"
            className="input-container__icon"
            onClick={() => setIsSecret(!isSecret)}
          />
        )}
        <label
          htmlFor={id}
          className={classNames('input-container__placeholder', {
            'input-container__placeholder--required': markRequired,
          })}
        >
          {placeholder}
        </label>
        {search && !active && (
          <SearchSVG className="input-container__search-icon" />
        )}
      </div>
      {error ? (
        <div className="input-container__error">{error}</div>
      ) : (
        hint && <span className="input-container__hint">{hint}</span>
      )}
    </div>
  );
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      placeholder,
      error,
      hint,
      inputClassName,
      enableSwitcher = false,
      markRequired = false,
      search = false,
      ...rest
    },
    ref
  ) => {
    const id = useId();
    const inputRef = useRef<HTMLInputElement>(null);
    const [active, setActive] = useState(false);
    const [isSecret, setIsSecret] = useState(true);
    const [initialized, setInitialized] = useState(false);

    const handleFocusOut = (e: React.FocusEvent<HTMLInputElement>) => {
      if (e.target.value === '') {
        setActive(false);
      }
    };

    const initializeState = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!initialized) {
        setInitialized(true);
        if (e.target.value) {
          setActive(true);
        }
      }
    };

    useEffect(() => {
      if (inputRef.current?.value && !active) {
        setActive(true);
      }

      if (!inputRef.current?.value) {
        setActive(false);
      }
    }, [inputRef.current?.value]);

    useEffect(() => {
      if (inputRef.current?.disabled && !inputRef.current?.value) {
        setActive(false);
      }
    }, [inputRef.current?.value, inputRef.current?.disabled]);

    return (
      <div
        className={classNames('input-container', rest.className, {
          'input-container--active':
            active || rest.type === 'date' || rest.type === 'time',
        })}
      >
        <div className="input-container__inner">
          <input
            {...rest}
            type={enableSwitcher && isSecret ? 'password' : rest.type || 'text'}
            ref={mergeRefs([inputRef, ref])}
            onFocus={e => {
              rest.onFocus?.(e);
              setActive(true);
            }}
            onBlur={e => {
              rest.onBlur?.(e);
              handleFocusOut(e);
            }}
            onChange={e => {
              rest.onChange?.(e);
              initializeState(e);
            }}
            className={classNames(
              'input-container__input',
              inputClassName && inputClassName,
              { 'input-container__input--error': Boolean(error) }
            )}
            id={id}
          />
          {enableSwitcher && (
            <EyeIcon
              width="16"
              height="11"
              className="input-container__icon"
              onClick={() => setIsSecret(!isSecret)}
            />
          )}
          <label
            htmlFor={id}
            className={classNames('input-container__placeholder', {
              'input-container__placeholder--required': markRequired,
            })}
          >
            {placeholder}
          </label>
          {search && !active && (
            <SearchSVG className="input-container__search-icon" />
          )}
        </div>
        {error ? (
          <div className="input-container__error">{error}</div>
        ) : (
          hint && <span className="input-container__hint">{hint}</span>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
