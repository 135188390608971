import { DirectionRequestType } from '../../../models/applications/applications';
import {
  useGetEducationFormsQuery,
  useGetPaymentFormsQuery,
} from '../../../redux/api/applications/directions-api';
import { selectDirectionsRequestType } from '../../../redux/selectors/enrollee/application';
import useAppSelector from '../../common/useAppSelector';
import useLazyIdEnumSelectProps from '../../common/useLazyIdEnumSelectProps';

// TODO. Избавиться от этого хардкода.
// Данные приёмки 2024
// id            | 1
// external_id   | a5ae8977-5972-11eb-803a-0050569f980a
// title         | Бюджетная основа
// title_foreign | State-funded
// -[ RECORD 2 ]-+-------------------------------------
// id            | 2
// external_id   | a5ae8978-5972-11eb-803a-0050569f980a
// title         | Контракт
// title_foreign | Self-funded
// -[ RECORD 3 ]-+-------------------------------------
// id            | 3
// external_id   | 6bb0a134-3692-11ee-8f61-0050560f355a
// title         | Особое право
// title_foreign |
// -[ RECORD 4 ]-+-------------------------------------
// id            | 4
// external_id   | 7f2119b9-3692-11ee-8f61-0050560f355a
// title         | Отдельная квота
// title_foreign |
// -[ RECORD 5 ]-+-------------------------------------
// id            | 5
// external_id   | a5ae8979-5972-11eb-803a-0050569f980a
// title         | Целевой прием
// title_foreign | Employer-sponsored education
const budgetRequestTypePaymentForms = [1, 3, 4, 5];
const contractRequestTypePaymentForms = [2];

export const useDirectionsTrainingSearchSelectProps = () => {
  const directionsRequestType = useAppSelector(selectDirectionsRequestType);

  const paymentFormSelectProps = useLazyIdEnumSelectProps(
    useGetPaymentFormsQuery
  );

  const educationFormSelectProps = useLazyIdEnumSelectProps(
    useGetEducationFormsQuery
  );

  return {
    educationFormSelectProps,
    paymentFormSelectProps: {
      ...paymentFormSelectProps,
      options: paymentFormSelectProps.options
        .map(option => {
          if (
            directionsRequestType === DirectionRequestType.ALL ||
            (directionsRequestType === DirectionRequestType.BUDGET &&
              budgetRequestTypePaymentForms.includes(option.value)) ||
            (directionsRequestType === DirectionRequestType.CONTRACT &&
              contractRequestTypePaymentForms.includes(option.value))
          )
            return option;
        })
        .filter(option => !!option),
    },
  };
};
