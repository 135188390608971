import React from 'react';

import useDirectoryInfo from 'hooks/admin/useDirectoryInfo';

const DirectoryInfo = () => {
  const {
    changeDirectoryView,
    deleteDirectory,
    editDirectory,
    createNewDirectory,
    columnsAdminDirectoriesList,
  } = useDirectoryInfo();
  /*
  if (!directoryInfo?.data?.length) {
    return <Loader />;
  }*/

  return (
    <>
      {/* <TableDirectoriesInfo
        columns={columnsAdminDirectoriesList}
        data={mappedData}
        changeDirectoryView={changeDirectoryView}
        editDirectory={editDirectory}
        deleteDirectory={deleteDirectory}
        directory={directory}
        createNewDirectory={createNewDirectory}
      />*/}
      <div>
        {/* {directoryInfo?.data.length && directoryInfo.total > 10 && (
          <Pagination
            lastPage={directoryInfo.lastPage}
            currentPage={directoryInfo.currentPage}
            perPage={directoryInfo.perPage}
            setPage={setAdminPageDirectoriesInfo}
            setPerPage={setAdminPerPageDirectoriesInfo}
          />
        )}*/}
      </div>
    </>
  );
};

export default DirectoryInfo;
