import React, { FC } from 'react';

import classnames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as TabArrowDown } from 'assets/images/icons/tab-arrow-down.svg';
import Button from 'components/common/ui-kit/Button';

import useAppDispatch from '../../../../hooks/common/useAppDispatch';
import useAppSelector from '../../../../hooks/common/useAppSelector';
import { showModal } from '../../../../redux/actions';
import { enrolleesApi } from '../../../../redux/api/questionnaire/enrollees-api';
import { useIsImpersonate } from '../../../../utils/domain/impersonate';

type BreadcrumbsProps = {
  link?: string;
  title: string;
  className: string;
};

const Breadcrumbs: FC<BreadcrumbsProps> = ({ link, title, className }) => {
  const navigate = useNavigate();
  const isImpersonate = useIsImpersonate();
  const { userId: userIdParam } = useParams();
  const userId = Number(userIdParam);
  const dispatch = useAppDispatch();
  const { data: isEnrolleeExists } = enrolleesApi.useIsEnrolleeExistsQuery(
    userId!,
    {
      skip: !isImpersonate || isNaN(userId),
      refetchOnMountOrArgChange: true,
    }
  );
  const { questionnaireId } = useAppSelector(state => state.questionnaire);

  const handleNavigate = () => {
    link ? navigate(link) : navigate('..', { relative: 'path' });
  };

  return (
    <div className={classnames('breadcrumbs', className)}>
      <Button
        disabled={isImpersonate && isEnrolleeExists === undefined}
        className="breadcrumbs__button"
        onClick={() => {
          isImpersonate && !isEnrolleeExists
            ? dispatch(
                showModal({
                  name: 'IMPERSONATE_EXIT_MODAL',
                  data: {
                    onNavigate: handleNavigate,
                    userId,
                    questionnaireId,
                  },
                })
              )
            : handleNavigate();
        }}
      >
        <TabArrowDown />
      </Button>
      <h1 className="breadcrumbs__title">{title}</h1>
    </div>
  );
};

export default Breadcrumbs;
