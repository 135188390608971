import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { ApplicationState } from '../../../models/applications/enum';
import { useGetDocumentsQuery } from '../../../redux/api/applications/application-documents-api';
import { useGetDirectionsByApplicationIdQuery } from '../../../redux/api/applications/directions-api';
import { selectApplication } from '../../../redux/selectors/enrollee/application';
import { applicationSlice } from '../../../redux/slices/enrollee/application-slice';
import useAppDispatch from '../../common/useAppDispatch';
import useAppSelector from '../../common/useAppSelector';

const useDownloadFilesForm = ({
  isAllCheckBoxesChecked,
  checkAllCheckboxes,
}: {
  readonly isAllCheckBoxesChecked: boolean;
  readonly checkAllCheckboxes: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const { applicationId } = useParams();
  const application = useAppSelector(selectApplication);

  const { data: documents, refetch: refetchDocuments } = useGetDocumentsQuery(
    Number(applicationId)
  );
  const { data: directions } = useGetDirectionsByApplicationIdQuery({
    applicationId: Number(applicationId),
  });

  useEffect(() => {
    refetchDocuments();
  }, []);

  useEffect(() => {
    if (
      application &&
      application.state !== ApplicationState.DRAFT &&
      application.state !== ApplicationState.SENT_FOR_EDITING &&
      application.state !== ApplicationState.DIRECTIONS_EDITING
    ) {
      disableSubmit();
      checkAllCheckboxes();
      setIsFormDisabled(true);
      return;
    }

    disableSubmit();

    if (
      documents?.every(doc => doc.isSigned) &&
      isAllCheckBoxesChecked &&
      directions &&
      Object.keys(directions).length !== 0
    ) {
      enableSubmit();
    }
  }, [
    applicationId,
    application,
    documents,
    directions,
    isAllCheckBoxesChecked,
  ]);

  const disableSubmit = () =>
    dispatch(applicationSlice.actions.setIsApplicationSubmittable(false));

  const enableSubmit = () =>
    dispatch(applicationSlice.actions.setIsApplicationSubmittable(true));

  return {
    disableSubmit,
    enableSubmit,
    documents,
    refetchDocuments,
    isFormDisabled,
  };
};

export default useDownloadFilesForm;
