import React, { FC } from 'react';

import { Controller } from 'react-hook-form';

import AdminTableListPage from 'components/admin/AdminTableListPage';
import { InputV2 } from 'components/common/ui-kit/Input/view';
import ReactSelect from 'components/common/ui-kit/Select/view';
import { ExamStatementsUploadStatus } from 'models/applications/statements';
import { SelectOption } from 'models/common';
import { renderDateString } from 'pages/Enrollment/common/column-renderers';
import { statementsApi } from 'redux/api/applications/statements-api';
import { createInputV2Props, createSelectProps } from 'utils/form-helpers';

export const getFullName = (
  surname: string,
  name: string,
  patronymic?: string
) => {
  if (!patronymic) return `${surname} ${name}`;
  return `${surname} ${name} ${patronymic}`;
};

const uploadStatusOptions: SelectOption<ExamStatementsUploadStatus | null>[] = [
  { value: null, label: 'Все' },
  { value: ExamStatementsUploadStatus.DELAYED, label: 'Не в очереди' },
  { value: ExamStatementsUploadStatus.NEW, label: 'На выгрузку' },
  { value: ExamStatementsUploadStatus.IN_PROCESS, label: 'В процессе' },
  { value: ExamStatementsUploadStatus.SUCCEEDED, label: 'Успешно выгружено' },
  { value: ExamStatementsUploadStatus.FAILED, label: 'Ошибка выгрузки' },
];

export const UploadQueuePageFilter = ({ control, formState: { errors } }) => {
  const inputV2Props = createInputV2Props(errors);
  const selectProps = createSelectProps(errors);
  return (
    <>
      <Controller
        name="id"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="ID пользователя"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="uploadStatus"
        control={control}
        render={({ field }) => (
          <ReactSelect
            className="filter-form__field"
            {...selectProps(field, { options: uploadStatusOptions })}
            options={uploadStatusOptions}
            placeholder="Статус выгрузки"
            hideSelectedOptions={false}
          />
        )}
      />
    </>
  );
};

export const renderUploadQueuePageFilter = props => (
  <UploadQueuePageFilter {...props} />
);

const statementUploadColumns = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Название', accessor: 'name' },
  { Header: 'Название предмета', accessor: 'subjectTitle' },
  { Header: 'Номер Приёмной кампании', accessor: 'campaignId' },
  { Header: 'Название Приёмной кампании', accessor: 'campaignName' },
  {
    Header: 'Наличие ошибок',
    accessor: 'hasErrors',
    render: statement => (statement.uploadError ? 'Есть' : 'Нет'),
  },
  {
    Header: 'Дата загрузки',
    accessor: 'uploadDate',
    render: ({ uploadDate }) =>
      uploadDate ? renderDateString(uploadDate) : '-',
  },
];

const StatementUploadsPage: FC = () => {
  return (
    <AdminTableListPage
      storeKey="statement-uploads"
      title="Выгрузка ведомостей"
      query={{
        use: statementsApi.useGetAllExamStatementQueuePageableQuery,
        mapQueryResultToData: uploadQueueInfos =>
          uploadQueueInfos.map(uploadQueueInfo => {
            return {
              ...uploadQueueInfo,
              fullName:
                uploadQueueInfo.surname &&
                uploadQueueInfo.name &&
                getFullName(
                  uploadQueueInfo.surname,
                  uploadQueueInfo.name,
                  uploadQueueInfo.patronymic
                ),
            };
          }),
      }}
      columns={statementUploadColumns}
      filtersConfig={{
        defaultValues: {
          id: '',
          uploadStatus: null,
        },
        renderFilters: renderUploadQueuePageFilter,
        mapFiltersToRequest: (filters, defaultMap) => {
          const mapped = defaultMap(filters);
          if (!mapped) return {};
          return mapped;
        },
      }}
      onRowClick={null}
    />
  );
};

export default StatementUploadsPage;
