import React, { FC, useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import Checkbox from 'components/common/ui-kit/Checkbox';
import ProfileForm from 'components/profile/ProfileForm';
import ProfileSection from 'components/profile/ProfileSection';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import useProfileRoutesContext from 'hooks/profile/useProfileRoutesContext';
import { useAddressesDraft } from 'hooks/questionnaire/addressesDraft/useAddressesDraft';
import {
  Address,
  SavedCompletion,
  ForeignAddressInfo,
  KladrIds,
} from 'hooks/questionnaire/addressesDraft/useAddressesForm';
import { isAddressesFormSpecified } from 'hooks/questionnaire/common/draft/common';
import { useUpdateDraftStatus } from 'hooks/questionnaire/common/util/useUpdateDraftStatus';
import terms, { isRussianLocale } from 'i18n';
import { SelectOption } from 'models/common';
import { DadataSuggestion } from 'models/questionnaire/dadata';
import { UpdateAddressesDraftRequest } from 'models/questionnaire/questionnaire';
import { createToast } from 'redux/actions';

import { selectIsDraftReadonly } from '../../../redux/selectors/enrollee/questionnaire';
import { createCheckboxProps } from '../../../utils/form-helpers';
import RadioButton from '../../common/ui-kit/RadioButton';
import AddressForm, { dadataCountryIds } from './TwoStepDadataFormBody/view';

type Completion = SavedCompletion | DadataSuggestion;

type ForeignRegistrationFields = {
  hasNoRegistrationStreet: boolean;
  foreignRegistrationRegion?: string;
  foreignRegistrationDistrict?: string;
  foreignRegistrationCity: string;
  foreignRegistrationStreet?: string;
  foreignRegistrationHouse: string;
  foreignRegistrationHouseExtension?: string;
  foreignRegistrationApartment?: string;
  foreignRegistrationPostcode: string;
};

type ForeignActualFields = {
  hasNoActualStreet: boolean;
  foreignActualRegion?: string;
  foreignActualDistrict?: string;
  foreignActualCity: string;
  foreignActualStreet?: string;
  foreignActualHouse: string;
  foreignActualHouseExtension?: string;
  foreignActualApartment?: string;
  foreignActualPostcode: string;
};

export type AddressSuggestion = {
  data: {
    city_district_with_type: string;
    region: string;
    city: string;
    street_with_type: string;
    house: string;
    block: string;
    postal_code: string;
    flat: string;
  };
  unrestricted_values: string;
  value: string;
};

export type SavedAddressSuggestion = {
  value: string;
};

export const getKladrIdsFromDadataSuggestion = (
  suggestion: DadataSuggestion
): KladrIds => {
  return {
    kladrId: suggestion.data.kladr_id ?? undefined,
    districtKladrId: suggestion.data.area_kladr_id ?? undefined,
    regionKladrId: suggestion.data.region_kladr_id ?? undefined,
    cityKladrId:
      suggestion.data.city_kladr_id ??
      suggestion.data.settlement_kladr_id ??
      undefined,
    streetKladrId: suggestion.data.street_kladr_id ?? undefined,
    houseKladrId: suggestion.data.house_kladr_id ?? undefined,
  };
};

export const getKladrIdsFromSavedAddress = (
  address: ForeignAddressInfo
): KladrIds => {
  const {
    kladrId,
    districtKladrId,
    regionKladrId,
    cityKladrId,
    streetKladrId,
    houseKladrId,
  } = address;
  return {
    kladrId,
    districtKladrId,
    regionKladrId,
    cityKladrId,
    streetKladrId,
    houseKladrId,
  };
};

export const getForeignAddressFieldsFromSuggestion = (
  suggestion: DadataSuggestion
): Partial<ForeignAddressInfo> => {
  const {
    area_with_type,
    region_with_type,
    city,
    street_with_type,
    house,
    block,
    postal_code,
    flat,
    settlement_with_type,
  } = suggestion.data;
  return {
    district: area_with_type ?? undefined,
    region: region_with_type ?? undefined,
    city: city ?? settlement_with_type ?? undefined,
    street: street_with_type ?? undefined,
    house: house ?? undefined,
    houseExtension: block ?? undefined,
    postcode: postal_code ?? undefined,
    apartment: flat ?? undefined,
  };
};

export const getCompletedFieldNames = (
  address: Partial<ForeignAddressInfo>
): (keyof Address)[] => {
  return Object.entries(address)
    .filter(([, value]) => value)
    .map(([key]) => key) as (keyof Address)[];
};

export const getFieldsDisabledStatusesFromDadataSuggestion = (
  suggestion: DadataSuggestion
): { [key in keyof Address]: boolean } => {
  const {
    area_with_type,
    region_with_type,
    city,
    street_with_type,
    house,
    block,
    postal_code,
    flat,
    settlement_with_type,
  } = suggestion.data;
  return {
    district: area_with_type ? true : false,
    region: region_with_type ? true : false,
    city: city || settlement_with_type ? true : false,
    street: street_with_type ? true : false,
    house: house ? true : false,
    houseExtension: block ? true : false,
    postcode: postal_code ? true : false,
    apartment: flat ? true : false,
  };
};

export const getFieldsDisabledStatusesFromSavedCompletion = (
  completion: SavedCompletion
): { [key in keyof Address]: boolean } => {
  const { completedFields } = completion;
  return completedFields.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {}) as { [key in keyof Address]: boolean };
};

export const getCompletedFieldsFromSavedDraft = (
  savedForeignAddressInfo: ForeignAddressInfo
): Partial<ForeignAddressInfo> | undefined => {
  const { completion } = savedForeignAddressInfo;
  if (!completion) return;
  return completion.completedFields.reduce((acc, curr) => {
    const value = savedForeignAddressInfo[curr];
    if (!value) return acc;
    acc[curr] = value;
    return acc;
  }, {});
};

const checkIfCompletionIsSaved = (
  completion: Completion | undefined
): completion is SavedCompletion => {
  return !!completion && 'query' in completion;
};

export const getForeignAddressInfo = <T,>(
  formValues: T,
  getAddress: (arg: T) => Address,
  completion: Completion | undefined,
  savedForeignAddressInfo: ForeignAddressInfo | undefined,
  isCompletionManuallyDisabled: boolean,
  countryId: number
): ForeignAddressInfo => {
  const isCompletionAlreadySaved = checkIfCompletionIsSaved(completion);
  const address = getAddress(formValues);
  const isDadataAutocompletable = dadataCountryIds.includes(countryId);
  const isCompletionUsed =
    isDadataAutocompletable && !!completion && !isCompletionManuallyDisabled;

  const kladrIds = isCompletionUsed
    ? isCompletionAlreadySaved
      ? savedForeignAddressInfo &&
        getKladrIdsFromSavedAddress(savedForeignAddressInfo)
      : getKladrIdsFromDadataSuggestion(completion)
    : undefined;

  const foreignAddressFieldsFromCompletion:
    | Partial<ForeignAddressInfo>
    | undefined = isCompletionUsed
    ? isCompletionAlreadySaved
      ? savedForeignAddressInfo
        ? getCompletedFieldsFromSavedDraft(savedForeignAddressInfo)
        : undefined
      : getForeignAddressFieldsFromSuggestion(completion)
    : undefined;

  // Убираем все undefined поля
  const normalizedForeignAddressFieldsFromCompletion =
    foreignAddressFieldsFromCompletion
      ? Object.entries(foreignAddressFieldsFromCompletion).reduce(
          (acc, [key, value]) => {
            if (value === undefined) return acc;
            acc[key] = value;
            return acc;
          },
          {}
        )
      : undefined;

  // Метаинформация об автодополнении для корректной работы
  const completionMeta = isCompletionUsed
    ? isCompletionAlreadySaved
      ? completion
      : normalizedForeignAddressFieldsFromCompletion
      ? {
          query: completion.value,
          completedFields: getCompletedFieldNames(
            normalizedForeignAddressFieldsFromCompletion
          ),
        }
      : undefined
    : undefined;

  return {
    completion: completionMeta,
    ...address,
    ...normalizedForeignAddressFieldsFromCompletion,
    // Kladr-айдюки из дадаты
    ...kladrIds,
  };
};

const AddressesForm: FC = () => {
  const readOnly = useAppSelector(selectIsDraftReadonly);
  const updateDraftStatus = useUpdateDraftStatus('addresses');
  const {
    draft,
    saveDraft,
    isAddressesFormFilled,
    foreignActualData,
    foreignRegistrationData,
  } = useAddressesDraft();
  const { onNextPageNavigate } = useProfileRoutesContext();
  const dispatch = useAppDispatch();
  const form = useForm<
    {
      isDadataManuallyDisabledForRegistration: boolean;
      isDadataManuallyDisabledForActual: boolean;
      isAddressesSame: boolean;
      stringifiedNeedDormitory: string;
      registrationCountryId: number;
      actualCountryId: number;
      registrationAddressCompletion: SelectOption<Completion>;
      actualAddressCompletion: SelectOption<Completion>;
    } & ForeignRegistrationFields &
      ForeignActualFields
  >({
    defaultValues: {
      isDadataManuallyDisabledForRegistration: false,
      isDadataManuallyDisabledForActual: false,
      hasNoRegistrationStreet: false,
      hasNoActualStreet: false,
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = form;
  const isAddressesSame = watch('isAddressesSame');
  const stringifiedNeedDormitory = register('stringifiedNeedDormitory', {
    required: terms.REQUIRED_FIELD,
  });

  useEffect(() => {
    if (!draft) return;
    const updatedDraft = {
      ...draft,
    };
    const foreignAddressRegistrationFields = foreignRegistrationData
      ? {
          hasNoRegistrationStreet: !foreignRegistrationData.street,
          foreignRegistrationRegion: foreignRegistrationData.region,
          foreignRegistrationDistrict: foreignRegistrationData.district,
          foreignRegistrationCity: foreignRegistrationData.city,
          foreignRegistrationStreet: foreignRegistrationData.street,
          foreignRegistrationHouse: foreignRegistrationData.house,
          foreignRegistrationHouseExtension:
            foreignRegistrationData.houseExtension,
          foreignRegistrationApartment: foreignRegistrationData.apartment,
          foreignRegistrationPostcode: foreignRegistrationData.postcode,
        }
      : undefined;
    const foreignAddressActualFields = foreignActualData
      ? {
          hasNoActualStreet: !foreignActualData.street,
          foreignActualRegion: foreignActualData.region,
          foreignActualDistrict: foreignActualData.district,
          foreignActualCity: foreignActualData.city,
          foreignActualStreet: foreignActualData.street,
          foreignActualHouse: foreignActualData.house,
          foreignActualHouseExtension: foreignActualData.houseExtension,
          foreignActualApartment: foreignActualData.apartment,
          foreignActualPostcode: foreignActualData.postcode,
        }
      : undefined;

    const registrationCompletion = foreignRegistrationData?.completion;
    const actualCompletion = foreignActualData?.completion;

    reset({
      stringifiedNeedDormitory:
        draft.needDormitory === undefined || draft.needDormitory === null
          ? undefined
          : draft.needDormitory
          ? 'need'
          : 'no need',
      isAddressesSame: draft.isAddressesSame,
      registrationCountryId: draft.registrationCountry?.id,
      actualCountryId: draft.actualCountry?.id,
      ...foreignAddressRegistrationFields,
      ...foreignAddressActualFields,
      registrationAddressCompletion: registrationCompletion
        ? {
            value: registrationCompletion,
            label: registrationCompletion.query,
          }
        : undefined,
      actualAddressCompletion: actualCompletion
        ? {
            value: actualCompletion,
            label: actualCompletion.query,
          }
        : undefined,
      isDadataManuallyDisabledForRegistration: isAddressesFormFilled
        ? !registrationCompletion
        : false,
      isDadataManuallyDisabledForActual: isAddressesFormFilled
        ? !actualCompletion
        : false,
    });

    if (isAddressesFormSpecified(updatedDraft)) {
      updateDraftStatus('valid');
    }
  }, [
    draft,
    foreignActualData,
    foreignRegistrationData,
    isAddressesFormFilled,
  ]);

  const onSubmit = handleSubmit(
    data => {
      if (!draft) return;
      const needDormitory = data.stringifiedNeedDormitory === 'need';
      const foreignRegistrationAddressData = getForeignAddressInfo(
        data,
        data => ({
          region: data.foreignRegistrationRegion,
          district: data.foreignRegistrationDistrict,
          city: data.foreignRegistrationCity,
          street: data.hasNoRegistrationStreet
            ? undefined
            : data.foreignRegistrationStreet,
          house: data.foreignRegistrationHouse,
          houseExtension: data.foreignRegistrationHouseExtension,
          apartment: data.foreignRegistrationApartment,
          postcode: data.foreignRegistrationPostcode,
        }),
        data.registrationAddressCompletion?.value,
        foreignRegistrationData,
        data.isDadataManuallyDisabledForRegistration,
        data.registrationCountryId
      );

      const stringifiedRegistrationAddress = JSON.stringify(
        foreignRegistrationAddressData
      );

      const submittableDraft: UpdateAddressesDraftRequest = {
        id: draft.id,
        needDormitory,
        isAddressesSame: data.isAddressesSame,
        // Адрес регистрации
        registrationCountryId: data.registrationCountryId,
        foreignRegistrationAddress: stringifiedRegistrationAddress,
        // Актуальный адрес
        actualCountryId: data.isAddressesSame
          ? data.registrationCountryId
          : data.actualCountryId,
        foreignActualAddress: data.isAddressesSame
          ? stringifiedRegistrationAddress
          : JSON.stringify(
              getForeignAddressInfo(
                data,
                data => ({
                  region: data.foreignActualRegion,
                  district: data.foreignActualDistrict,
                  city: data.foreignActualCity,
                  street: data.hasNoActualStreet
                    ? undefined
                    : data.foreignActualStreet,
                  house: data.foreignActualHouse,
                  houseExtension: data.foreignActualHouseExtension,
                  apartment: data.foreignActualApartment,
                  postcode: data.foreignActualPostcode,
                }),
                data.actualAddressCompletion?.value,
                foreignActualData,
                data.isDadataManuallyDisabledForActual,
                data.actualCountryId
              )
            ),
      };

      saveDraft(submittableDraft)
        ?.unwrap()
        .then(() => onNextPageNavigate())
        .catch(error => {
          const errorDescriptionLocale: string = isRussianLocale()
            ? error.data?.descriptionRu ?? error.data.description
            : error.data.description;
          dispatch(createToast(errorDescriptionLocale, 'danger'));
        });
    },
    errors => console.log(errors)
  );

  const checkboxProps = createCheckboxProps(errors, readOnly);

  return (
    <ProfileForm
      onSubmit={
        readOnly
          ? e => {
              e.preventDefault();
              onNextPageNavigate();
            }
          : e => onSubmit(e)
      }
    >
      <FormProvider {...form}>
        <AddressForm
          isReadOnly={readOnly}
          fieldsToProps={{
            isCompletionManuallyDisabled: {
              name: 'isDadataManuallyDisabledForRegistration',
            },
            completion: {
              name: 'registrationAddressCompletion',
            },
            country: {
              name: 'registrationCountryId',
            },
            district: {
              name: 'foreignRegistrationDistrict',
            },
            region: {
              name: 'foreignRegistrationRegion',
            },
            city: {
              name: 'foreignRegistrationCity',
            },
            street: {
              name: 'foreignRegistrationStreet',
            },
            hasNoStreet: {
              name: 'hasNoRegistrationStreet',
            },
            house: {
              name: 'foreignRegistrationHouse',
            },
            houseExtension: {
              name: 'foreignRegistrationHouseExtension',
            },
            apartment: {
              name: 'foreignRegistrationApartment',
            },
            postcode: {
              name: 'foreignRegistrationPostcode',
            },
          }}
          title={terms.PERMANENT_ADDRESS}
        />
        <Checkbox
          text={terms.PERMANENT_PASSPORT_ADDRESS_AND_HOME_ADDRESS_THE_SAME}
          className="profile-form__checkbox addresses-form__checkbox"
          {...checkboxProps(register('isAddressesSame'))}
        />
        {!isAddressesSame && (
          <AddressForm
            isReadOnly={readOnly}
            fieldsToProps={{
              isCompletionManuallyDisabled: {
                name: 'isDadataManuallyDisabledForActual',
              },
              completion: {
                name: 'actualAddressCompletion',
              },
              country: {
                name: 'actualCountryId',
              },
              district: {
                name: 'foreignActualDistrict',
              },
              region: {
                name: 'foreignActualRegion',
              },
              city: {
                name: 'foreignActualCity',
              },
              street: {
                name: 'foreignActualStreet',
              },
              hasNoStreet: {
                name: 'hasNoActualStreet',
              },
              house: {
                name: 'foreignActualHouse',
              },
              houseExtension: {
                name: 'foreignActualHouseExtension',
              },
              apartment: {
                name: 'foreignActualApartment',
              },
              postcode: {
                name: 'foreignActualPostcode',
              },
            }}
            title={terms.CURRENT_HOME_ADDRESS}
          />
        )}
      </FormProvider>
      <ProfileSection markRequired title={terms.I_NEED_PLACE_OF_DORMITORY}>
        <RadioButton className="profile-form__radio">
          <RadioButton.Radio
            disabled={readOnly}
            {...stringifiedNeedDormitory}
            value="need"
            text={terms.NEED}
          />
          <RadioButton.Radio
            disabled={readOnly}
            {...stringifiedNeedDormitory}
            value="no need"
            text={terms.NO_NEED}
          />
        </RadioButton>
        {errors.stringifiedNeedDormitory?.message && (
          <div className="profile-form__error">
            {errors.stringifiedNeedDormitory?.message as string}
          </div>
        )}
      </ProfileSection>
    </ProfileForm>
  );
};

export default AddressesForm;
