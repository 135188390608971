import { Enrollee } from '../../../models/enrollee';
import { RootState } from '../../store';

export const selectSupportQuestionnaireState = (state: RootState) =>
  state.supportQuestionnaires;

export const selectCurrentSupportQuestionnaire = (state: RootState) =>
  state.supportQuestionnaires.currentQuestionnaire;

export const selectCurrentSupportUser = (state: RootState) =>
  (state.supportQuestionnaires.currentQuestionnaire as unknown as Enrollee)
    ?.user;
