import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import useAppDispatch from 'hooks/common/useAppDispatch';
import terms from 'i18n';
import { createToast } from 'redux/actions';
import { useRequestResetPasswordMutation } from 'redux/api/common/auth-api';
import HttpStatus from 'utils/http-status';
import regexp from 'utils/regexp';

const useRequestResetPasswordForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { register, handleSubmit, ...rest } = useForm<{
    email: string;
  }>();
  const [requestResetPassword] = useRequestResetPasswordMutation();

  const fields = {
    email: register('email', {
      required: terms.REQUIRED_FIELD,
      pattern: {
        value: regexp.email,
        message: terms.VALID_EMAIL,
      },
    }),
  };

  const onSubmit = handleSubmit(async ({ email }) => {
    requestResetPassword({ email })
      .unwrap()
      .then(() => {
        dispatch(
          createToast(`${terms.PASSWORD_RESET_EMAIL_SENT} ${email}`, 'success')
        );
        navigate('/sign-in');
      })
      .catch(error => {
        if (error.status === HttpStatus.NOT_FOUND) {
          dispatch(createToast(terms.ACCOUNT_NOT_FOUND, 'danger'));
        } else dispatch(createToast(terms.ERROR_HAS_OCCURRED, 'danger'));
      });
  });

  return {
    fields,
    onSubmit,
    ...rest,
  };
};

export default useRequestResetPasswordForm;
