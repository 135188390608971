import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import useAppDispatch from 'hooks/common/useAppDispatch';
import terms from 'i18n';
import { createToast } from 'redux/actions';
import { useResetPasswordMutation } from 'redux/api/common/auth-api';
import regexp from 'utils/regexp';

const useResetPasswordForm = (code: string, userId: number) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { register, handleSubmit, getValues, ...rest } = useForm<{
    newPassword: string;
    newPasswordConfirmation: string;
  }>();
  const [resetPassword] = useResetPasswordMutation();

  const fields = {
    newPassword: register('newPassword', {
      required: terms.REQUIRED_FIELD,
      pattern: {
        value: regexp.password,
        message: terms.PASSWORD_MIXED,
      },
      minLength: {
        value: 6,
        message: terms.PASSWORD_MIN_LENGTH,
      },
    }),
    newPasswordConfirmation: register('newPasswordConfirmation', {
      required: terms.REQUIRED_FIELD,
      validate: value => {
        return value === getValues('newPassword') || terms.PASSWORDS_MUST_MATCH;
      },
    }),
  };

  const onSubmit = handleSubmit(
    async ({ newPassword, newPasswordConfirmation }) => {
      resetPassword({
        newPassword,
        code,
        userId,
      })
        .unwrap()
        .then(() => {
          dispatch(createToast(terms.PASSWORD_UPDATED, 'success'));
          navigate('/sign-in');
        })
        .catch(error => {
          dispatch(createToast(terms.ERROR_HAS_OCCURRED, 'danger'));
        });
    }
  );

  return {
    fields,
    onSubmit,
    ...rest,
  };
};

export default useResetPasswordForm;
