import React, { FC } from 'react';

import ResultExamForm from 'components/profile/ResultExamForm';

const ResultsExamPage: FC = () => (
  <div>
    <ResultExamForm />
  </div>
);

export default ResultsExamPage;
