import { useLocation, useNavigate } from 'react-router-dom';

import useAppDispatch from 'hooks/common/useAppDispatch';
import { resetStore } from 'redux/actions';
import { deleteAuthCredentialsFromStorage } from 'utils/auth-token';

import { clearAuthCredentials } from '../../redux/slices/common/auth-slice';

const useOnLogout = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return () => {
    if (
      pathname.includes('admin/users/') &&
      (pathname.includes('personal-information/') ||
        pathname.includes('statement') ||
        pathname.includes('additional-files'))
    ) {
      window.close();
    } else {
      deleteAuthCredentialsFromStorage();
      dispatch(clearAuthCredentials());
      dispatch(resetStore());
      navigate('/sign-in');
    }
  };
};

export default useOnLogout;
