import { ApplicationEducationLevel } from '../../../models/applications/enum';
import {
  CreatePrivilegeRequest,
  Privilege,
  PrivilegeExtras,
  PrivilegeGroupingType,
  PrivilegeType,
  UpdatePrivilegeRequest,
} from '../../../models/applications/privileges';
import { Entity } from '../../../models/common';
import { apiSlice } from '../api-slice';

const baseUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/privileges`;

export const privilegesApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createPrivilege: builder.mutation<Privilege, CreatePrivilegeRequest>({
      query: request => ({
        url: `${baseUrl}/create`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['Privilege'],
    }),

    getPrivilegesByApplicationId: builder.query<Privilege[], number>({
      query: applicationId => ({
        url: `${baseUrl}/by-application-id/${applicationId}`,
      }),
      providesTags: ['Privilege'],
    }),

    updatePrivilege: builder.mutation<Privilege, UpdatePrivilegeRequest>({
      query: request => ({
        url: `${baseUrl}/update`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['Privilege'],
    }),

    deletePrivilege: builder.mutation<void, number>({
      query: privilegeId => ({
        url: `${baseUrl}/delete/${privilegeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Privilege'],
    }),

    getPrivilegeTypes: builder.query<
      PrivilegeType[],
      {
        educationLevel?: ApplicationEducationLevel;
        groupingType: PrivilegeGroupingType;
      }
    >({
      query: ({ educationLevel, groupingType }) => ({
        url: `${baseUrl}/privilege-types/${groupingType}`,
        params: {
          educationLevel,
        },
      }),
    }),

    getPrerogativePrivilegesTypes: builder.query<
      PrivilegeType[],
      ApplicationEducationLevel | void
    >({
      query: educationLevel => ({
        url: `${baseUrl}/privilege-types/prerogative`,
        params: {
          educationLevel,
        },
      }),
    }),

    getBenefitPrivilegesTypes: builder.query<
      PrivilegeType[],
      ApplicationEducationLevel | void
    >({
      query: educationLevel => ({
        url: `${baseUrl}/privilege-types/benefit`,
        params: {
          educationLevel,
        },
      }),
    }),

    getSpecialPrivilegesTypes: builder.query<
      PrivilegeType[],
      ApplicationEducationLevel | void
    >({
      query: educationLevel => ({
        url: `${baseUrl}/privilege-types/special`,
        params: {
          educationLevel,
        },
      }),
    }),

    getPrivilegesConfirmationDocTypes: builder.query<Entity[], number>({
      query: typeId =>
        `${baseUrl}/privilege-types/${typeId}/confirmation-document-types`,
    }),

    getPrivilegeForm: builder.query<
      {
        readonly privilegeExtras: PrivilegeExtras[];
        readonly privileges: {
          readonly [key in PrivilegeGroupingType]: Privilege[];
        };
      },
      number
    >({
      query: applicationId =>
        `${baseUrl}/form-by-application-id/${applicationId}`,
      providesTags: ['Privilege'],
    }),

    createPrivilegesExtras: builder.mutation<
      PrivilegeExtras,
      {
        readonly extraType:
          | 'military-support'
          | 'entrance-test-special-condition'
          | 'disabled-person';
        readonly applicationId: number;
        readonly documentId: number;
        readonly specialConditions?: string;
      }
    >({
      query: ({ extraType, ...request }) => ({
        url: `${baseUrl}/extras/create/${extraType}`,
        method: 'POST',
        body: request,
      }),
    }),

    updatePrivilegesExtras: builder.mutation<
      PrivilegeExtras,
      {
        readonly extraType:
          | 'military-support'
          | 'entrance-test-special-condition'
          | 'disabled-person';
        readonly id: number;
        readonly documentId: number;
        readonly specialConditions?: string;
      }
    >({
      query: ({ extraType, ...request }) => ({
        url: `${baseUrl}/extras/update/${extraType}`,
        method: 'POST',
        body: request,
      }),
    }),

    revivePrivilegeExtras: builder.mutation<PrivilegeExtras, number>({
      query: extrasId => ({
        url: `${baseUrl}/extras/revive/${extrasId}`,
        method: 'POST',
      }),
    }),

    deletePrivilegeExtras: builder.mutation<void, number>({
      query: extrasId => ({
        url: `${baseUrl}/extras/delete/${extrasId}`,
        method: 'DELETE',
      }),
    }),

    getMasterLocalPrivilegeType: builder.query<PrivilegeType[], void>({
      query: () => `${baseUrl}/master-local-privilege-types`,
    }),

    updateAdmittedFlagForPrivilege: builder.mutation<
      void,
      { id: number; value: boolean }
    >({
      query: ({ id, value }) => ({
        url: `${baseUrl}/${id}/admitted`,
        method: 'PUT',
        params: {
          value,
        },
      }),
      invalidatesTags: ['Privilege', 'CheckList'],
    }),
  }),
});
