import React, { FC } from 'react';

import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import AdminTableListPage from 'components/admin/AdminTableListPage';
import { InputV2 } from 'components/common/ui-kit/Input/view';
import ReactSelect from 'components/common/ui-kit/Select/view';
import { columnsUploadQueue } from 'config/moderator-table';
import { SelectOption } from 'models/common';
import { uploadQueueApi } from 'redux/api/staff/admin/upload-queue-api';
import { savePreviousLocation } from 'utils/domain/impersonate';
import { createInputV2Props, createSelectProps } from 'utils/form-helpers';

export const getFullName = (
  surname: string,
  name: string,
  patronymic?: string
) => {
  if (!patronymic) return `${surname} ${name}`;
  return `${surname} ${name} ${patronymic}`;
};

const hasErrorOptions: SelectOption[] = [
  { value: null, label: 'Все' },
  {
    value: true,
    label: 'Выгружено с ошибкой',
  },
  { value: false, label: 'Выгружено без ошибок' },
];

const fromEpguOptions: SelectOption[] = [
  { value: null, label: 'Все' },
  {
    value: true,
    label: 'Да',
  },
  { value: false, label: 'Нет' },
];

export const UploadQueuePageFilter = ({ control, formState: { errors } }) => {
  const inputV2Props = createInputV2Props(errors);
  const selectProps = createSelectProps(errors);
  return (
    <>
      <Controller
        name="id"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="ID пользователя"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="lastName"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Фамилия"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Имя"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="middleName"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Отчество"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Email"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="hasError"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption>
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...selectProps(field, hasErrorOptions)}
            options={hasErrorOptions}
            placeholder="Состояние выгрузки"
            hideSelectedOptions={false}
            // components={{ Option }}
          />
        )}
      />

      <Controller
        name="fromEpgu"
        control={control}
        render={({ field }) => (
          <ReactSelect
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...selectProps(field, fromEpguOptions)}
            options={fromEpguOptions}
            placeholder="Из ЕПГУ"
            hideSelectedOptions={false}
            // components={{ Option }}
          />
        )}
      />
    </>
  );
};

export const renderUploadQueuePageFilter = props => (
  <UploadQueuePageFilter {...props} />
);

const UploadQueuePage: FC = () => {
  const navigate = useNavigate();
  return (
    <AdminTableListPage
      storeKey="upload-queue"
      title="Выгрузка данных"
      query={{
        use: uploadQueueApi.useGetAllUploadQueuePageableQuery,
        mapQueryResultToData: uploadQueueInfos =>
          uploadQueueInfos.map(uploadQueueInfo => {
            return {
              ...uploadQueueInfo,
              fullName:
                uploadQueueInfo.surname &&
                uploadQueueInfo.name &&
                getFullName(
                  uploadQueueInfo.surname,
                  uploadQueueInfo.name,
                  uploadQueueInfo.patronymic
                ),
            };
          }),
      }}
      columns={columnsUploadQueue}
      filtersConfig={{
        defaultValues: {
          id: '',
          lastName: '',
          firstName: '',
          middleName: '',
          email: '',
          hasError: null,
          fromEpgu: null,
        },
        renderFilters: renderUploadQueuePageFilter,
      }}
      onRowClick={item => {
        savePreviousLocation();
        navigate(item.userId.toString());
      }}
    />
  );
};

export default UploadQueuePage;
