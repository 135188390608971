import { Chat, ChatMessage, UnreadCount } from 'models/chat';
import { chatClient } from 'utils/axios-clients';

export default {
  getUserChats: (applicantId: number) =>
    chatClient.get<Chat[]>('/availableChatsOfApplicant', {
      params: { applicantId },
    }),

  getUnreadCount: (chatId: number) =>
    chatClient.get<number>('/unreadCount', {
      params: { chatId },
    }),

  getUnreadCountApplicant: (applicantId: number) =>
    chatClient.get<UnreadCount>('/unreadCountApplicantMapped', {
      params: { applicantId },
    }),

  getMessages: (chatId: number) =>
    chatClient.get<ChatMessage[]>('/messages', {
      params: { chatId },
    }),

  readChat: (chatId: number) =>
    chatClient.get<number>(`/readChat/${chatId}`, {}),
};
