import React, { FC } from 'react';

import classnames from 'classnames';

import { ReactComponent as QuestionMark } from 'assets/images/icons/question-mark.svg';

type ProfileSectionProps = React.PropsWithChildren<{
  title?: string;
  titleSize?: 'normal' | 'large';
  markRequired?: boolean;
  fileUploadingHint?: string;
}>;

const ProfileSection: FC<ProfileSectionProps> = ({
  title,
  titleSize = 'normal',
  children,
  markRequired = false,
  fileUploadingHint,
}) => (
  <section className="profile-section">
    {title && (
      <div className="profile-section__heading">
        <h3
          className={classnames('profile-section__title', titleSize, {
            'profile-section__title--required': markRequired,
          })}
        >
          {title}
        </h3>

        {fileUploadingHint && (
          <div className="profile-section__svg" title-hint={fileUploadingHint}>
            <QuestionMark width="24" height="24" />
          </div>
        )}
      </div>
    )}
    <div className="profile-section__body">{children}</div>
  </section>
);

export default ProfileSection;
