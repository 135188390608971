import React from 'react';

import 'components/common/layouts/admission-campaign/header.scss';
import { faPhone, faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { ReactComponent as HeaderIcon } from 'assets/images/enroll-lists-header-logo.svg';
const Header = () => {
  return (
    <header className="main-header">
      <div className="top-nav">
        <div className="container">
          <div className="row">
            <div className="m-wrapper">
              <a href="http://spbstu.ru/" className="poly-logo">
                <HeaderIcon />
              </a>
              <a href="/abit/" className="main-logo">
                Поступление <span>2024</span>
              </a>
              <a href="tel:88007071899" className="phone-mail">
                <FontAwesomeIcon icon={faPhone} size="sm" />
                <span> 8 (800) 707-18-99</span>
              </a>
              <a
                href="mailto:abitur@spbstu.ru?subject=%D0%92%D0%BE%D0%BF%D1%80%D0%BE%D1%81+%D0%BF%D0%BE+%D0%BF%D1%80%D0%B8%D0%B5%D0%BC%D1%83"
                className="phone-mail"
              >
                <FontAwesomeIcon icon={faEnvelope} size="sm" />
                <span>abitur@spbstu.ru</span>
              </a>
              <a
                href="http://enroll.spbstu.ru"
                className="cabinet"
                target="_blank"
              >
                <FontAwesomeIcon icon={faUser} size="sm" />
                <span>Личный кабинет абитуриента</span>
              </a>
              {/* <div className="nav-trigger">*/}
              {/*  <span></span>*/}
              {/* </div>*/}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-nav">
        <div className="container">
          <div className="row">
            <div className="m-wrapper">
              <ul className="nav-btns-02 nav-btns">
                <li className="scnd-li">
                  <a href="https://college.spbstu.ru/" target="_blank">
                    Колледж
                  </a>
                </li>
                <li className="scnd-li">
                  <a
                    href="https://www.spbstu.ru/abit/bachelor/"
                    target="_blank"
                  >
                    Бакалавриат и специалитет
                  </a>
                </li>
                <li className="scnd-li">
                  <a href="https://www.spbstu.ru/abit/master/">Магистратура</a>
                </li>
                <li className="scnd-li">
                  <a
                    href="https://www.spbstu.ru/education/management-structure/postgraduate/entrance/"
                    target="_blank"
                  >
                    Аспирантура
                  </a>
                </li>
                <li className="scnd-li">
                  <a href="https://www.spbstu.ru/abit/faq/">
                    Советы абитуриентам
                  </a>
                </li>
                <li className="scnd-li current">
                  <Link to="/applicants">Ход приема</Link>
                </li>
                <li className="scnd-li">
                  <a href="https://www.spbstu.ru/abit/materials/">Материалы</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
