import { useMemo } from 'react';

import { UserRole } from '../../models/user';
import { readChat, resetUnreadCountByType } from '../../redux/actions';
import { selectChatType, selectUnreadCount } from '../../redux/selectors';
import useAppDispatch from '../common/useAppDispatch';
import useAppSelector from '../common/useAppSelector';
import useChatId from './useChatId';

const useHasHiddenReadPrivilege = () => {
  const unreadCount = useAppSelector(selectUnreadCount) || {};
  const userRole = useAppSelector(state => state.user.role);
  const dispatch = useAppDispatch();
  const chatId = useChatId();
  const chatType = useAppSelector(selectChatType);

  const unreadCountSum = useMemo(
    () => Object.values(unreadCount).reduce((acc, count) => acc + count, 0),
    [unreadCount]
  );

  const canAllRead = useMemo(() => {
    return !!unreadCountSum && userRole === UserRole.MODERATOR;
  }, [unreadCountSum, userRole]);

  const readReceivedMessages = () => {
    if (chatId) {
      dispatch(readChat({ chatId }));
      dispatch(resetUnreadCountByType(chatType));
    }
  };

  return {
    canAllRead: canAllRead,
    readReceivedMessages: readReceivedMessages,
  };
};

export default useHasHiddenReadPrivilege;
