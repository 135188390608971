import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  GeneralDataDraft,
  PassportDraft,
} from '../../../models/questionnaire/questionnaire';
import { UserData } from '../../../models/user';

export type UserDataByStaff = {
  readonly generalDataView: GeneralDataDraft;
  readonly passportView: PassportDraft;
  readonly userView: UserData;
};

type UserManagementState = {
  readonly filters: object;
  readonly page: number;
  readonly size: number;
  readonly totalPages: number;
  readonly totalElements: number;
  readonly elements: UserDataByStaff[];
};

const initialState: UserManagementState = {
  filters: {},
  page: 1,
  size: 10,
  totalElements: 0,
  totalPages: 0,
  elements: [],
};

export const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    cleanFilters: state => {
      state.filters = {};
    },

    setPage: (state, action) => {
      state.page = action.payload;
    },

    setSize: (state, action) => {
      state.size = action.payload;
    },

    setUsers: (
      state,
      action: PayloadAction<{
        readonly users: UserDataByStaff[];
        readonly page: number;
        readonly totalPages: number;
        readonly size: number;
        readonly totalElements: number;
      }>
    ) => {
      state.elements = action.payload.users;
      state.size = action.payload.size;
      state.totalPages = action.payload.totalPages;
      state.page = action.payload.page;
      state.totalElements = action.payload.totalElements;
    },
  },
});

export default userManagementSlice.reducer;
