import React, { FC } from 'react';

import { Controller } from 'react-hook-form';

import TemplateModal from 'components/common/modals/TemplateModal';
import Button from 'components/common/ui-kit/Button';
import Input from 'components/common/ui-kit/Input';
import ReactSelect from 'components/common/ui-kit/Select';
import useAddUserAdminModalForm from 'hooks/admin/useAddUserAdminModalForm';
import useAppSelector from 'hooks/common/useAppSelector';
import terms from 'i18n';
import { SelectOption } from 'models/common';
import { modalDataSelector } from 'redux/selectors';

import { useAddUserFormSelectProps } from '../../../../hooks/admin/useAddUserFormSelectProps';
import { UserRole } from '../../../../models/user';

const AddUserAdminModal: FC = () => {
  const { adminUserIdData } = useAppSelector(modalDataSelector);
  const isEditing = Boolean(adminUserIdData);

  const {
    control,
    fields,
    onSubmit,
    loading,
    formState: { errors },
  } = useAddUserAdminModalForm({ adminUserIdData, isEditing });

  const {
    roleSelectOptions,
    activatedSelectOptions,
    canChooseBachelorOptions,
    countries,
  } = useAddUserFormSelectProps();

  return (
    <TemplateModal
      title={isEditing ? 'Редактировать пользователя' : 'Добавить пользователя'}
    >
      <form onSubmit={onSubmit}>
        <div className="profile-form__fields">
          {!isEditing && (
            <Controller
              name={fields.activated.name}
              control={control}
              render={({ field }) => (
                <ReactSelect<SelectOption>
                  options={activatedSelectOptions}
                  placeholder="Активен"
                  className="profile-form__field"
                  markRequired={true}
                  {...(field as object)}
                  error={errors.activated?.message}
                />
              )}
            />
          )}

          <Controller
            name={fields.role.name}
            control={control}
            render={({ field }) => (
              <ReactSelect<SelectOption>
                options={roleSelectOptions}
                placeholder="Роль"
                className="profile-form__field"
                markRequired={true}
                {...(field as object)}
                error={errors.role?.message}
              />
            )}
          />

          {!isEditing && (
            <>
              <Input
                placeholder="Фамилия"
                className="profile-form__field profile-form__input--fb-50"
                markRequired={true}
                {...fields.surname}
              />
              <Input
                placeholder="Имя"
                markRequired={true}
                className="profile-form__field profile-form__input--fb-50"
                {...fields.name}
              />
              <Input
                placeholder="Отчество"
                className="profile-form__field profile-form__input--fb-50"
                {...fields.patronymic}
              />
              <Input
                className="profile-form__field profile-form__input--fb-50"
                placeholder="Дата рождения"
                type="date"
                markRequired={true}
                {...fields.birthDate}
                error={errors.birthDate?.message}
              />
            </>
          )}

          <Input
            placeholder="Логин/email"
            className="profile-form__field profile-form__field--fb-100"
            markRequired={true}
            {...fields.email}
            error={errors.email?.message}
          />

          {!isEditing && (
            <>
              <Controller
                name={fields.country.name}
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    className="profile-form__field profile-form__field--fb-100"
                    isClearable
                    placeholder={terms.COUNTRY_OF_BIRTH}
                    markRequired
                    {...(field as object)}
                    options={countries}
                  />
                )}
              />
              <Input
                className="profile-form__field profile-form__field--fb-100"
                hint={terms.PASS_HINT}
                placeholder="Пароль"
                enableSwitcher={true}
                markRequired={true}
                {...fields.password}
                error={errors.password?.message}
              />
              <Input
                className="profile-form__field profile-form__field--fb-100"
                placeholder="Подтверждение пароля"
                enableSwitcher={true}
                markRequired={true}
                {...fields.passwordConfirmation}
                error={errors.passwordConfirmation?.message}
              />
            </>
          )}

          {isEditing &&
            adminUserIdData.userView.role === UserRole[UserRole.USER] &&
            adminUserIdData.userView.epguId === null &&
            adminUserIdData.userView.isRussian && (
              <>
                <Controller
                  name="canChooseBachelor"
                  control={control}
                  rules={{
                    required: terms.REQUIRED_FIELD,
                  }}
                  shouldUnregister
                  render={({ field }) => (
                    <ReactSelect<SelectOption>
                      options={canChooseBachelorOptions}
                      placeholder="Подача на бакалавриат"
                      className="profile-form__field"
                      markRequired={true}
                      {...(field as object)}
                      error={errors.canChooseBachelor?.message}
                    />
                  )}
                />
              </>
            )}
        </div>
        <Button
          theme="success"
          loading={loading}
          className="profile-form__button profile-form__button--align-left"
        >
          {terms.SAVE}
        </Button>
      </form>
    </TemplateModal>
  );
};

export default AddUserAdminModal;
