import { FC } from 'react';

import { useParams } from 'react-router-dom';

import './ModeratorEgpuInfoPage.scss';
import Button from 'components/common/ui-kit/Button';
import Loader from 'components/common/ui-kit/Loader';
import useAppDispatch from 'hooks/common/useAppDispatch';
import { createToast } from 'redux/actions';
import { uploadQueueApi } from 'redux/api/staff/admin/upload-queue-api';

import { useGetStaffApplicationByIdQuery } from '../../../redux/api/staff/staff-applications-api';

type Moderator1cPageButtonProps = {
  label: string;
  param?: number | string;
  mutation: {
    use;
    successMessage: string;
    errorMessage?: string;
  };
  className?: string;
};

const Moderator1cPageButton: FC<Moderator1cPageButtonProps> = ({
  mutation: { use: useMutation, successMessage, errorMessage },
  label,
  param,
  className,
}) => {
  const [mutate, { isLoading }] = useMutation();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    mutate(param)
      .unwrap()
      .then(() => {
        dispatch(createToast(successMessage, 'success'));
      })
      .catch(() => {
        dispatch(
          createToast(
            errorMessage ?? 'Во время постановки в очередь произошла ошибка',
            'danger'
          )
        );
      });
  };

  return (
    <Button
      className={className}
      theme="success"
      onClick={handleClick}
      loading={isLoading}
    >
      {label}
    </Button>
  );
};

const Moderator1cPage: FC = () => {
  const { id: applicationId } = useParams();
  const { data: application } = useGetStaffApplicationByIdQuery(
    Number(applicationId),
    {
      skip: applicationId === undefined,
    }
  );

  if (!application) return <Loader />;

  const buttons: Omit<Moderator1cPageButtonProps, 'userId'>[] = [
    {
      label:
        'Поставить в очередь все данные абитуриента, включая все заявления',
      mutation: {
        use: uploadQueueApi.useSendWholeUserToQueueMutation,
        successMessage: 'Данные абитуриента добавлены в очередь',
      },
    },
    {
      label: 'Поставить в очередь анкету',
      mutation: {
        use: uploadQueueApi.useSendQuestionnaireToQueueMutation,
        successMessage: 'Анкета добавлена в очередь',
      },
    },
    {
      label: 'Поставить в очередь законного представителя',
      mutation: {
        use: uploadQueueApi.useSendRepresentativeToQueueMutation,
        successMessage: 'Представитель добавлен в очередь',
      },
    },
    {
      label: 'Поставить в очередь все документы об образовании',
      mutation: {
        use: uploadQueueApi.useSendEduDocsToQueueMutation,
        successMessage: 'Документы добавлены в очередь',
      },
    },
    {
      label: 'Поставить в очередь все заявления',
      mutation: {
        use: uploadQueueApi.useSendApplicationsToQueueMutation,
        successMessage: 'Заявления добавлены в очередь',
      },
    },
    {
      label: 'Поставить в очередь текущее заявление',
      param: application.id,
      mutation: {
        use: uploadQueueApi.useSendApplicationToQueueMutation,
        successMessage: 'Заявление добавлено в очередь',
      },
    },
    {
      label: 'Поставить в очередь все оригиналы',
      mutation: {
        use: uploadQueueApi.useSendOriginalsToQueueMutation,
        successMessage: 'Оригиналы добавлены в очередь',
      },
    },
    {
      label: 'Поставить в очередь все согласия на зачисления',
      mutation: {
        use: uploadQueueApi.useSendAgreementsToQueueMutation,
        successMessage: 'Согласия добавлены в очередь',
      },
    },
    {
      label: 'Поставить в очередь все договоры',
      mutation: {
        use: uploadQueueApi.useSendContractsToQueueMutation,
        successMessage: 'Договоры добавлены в очередь',
      },
    },
    {
      label: 'Поставить в очередь все ЕГЭ',
      mutation: {
        use: uploadQueueApi.useSendSchoolTestsToQueueMutation,
        successMessage: 'ЕГЭ добавлены в очередь',
      },
    },
    {
      label: 'Поставить в очередь все олимпиады',
      mutation: {
        use: uploadQueueApi.useSendOlympiadsToQueueMutation,
        successMessage: 'Олимпиады добавлены в очередь',
      },
    },
    {
      label: 'Поставить в очередь все льготы и ИД',
      mutation: {
        use: uploadQueueApi.useSendFeaturesToQueueMutation,
        successMessage: 'Льготы и ИД добавлены в очередь',
      },
    },
  ];

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 15,
        }}
      >
        {buttons.map(button => (
          <Moderator1cPageButton
            {...button}
            param={button.param ?? application.userId}
          />
        ))}
      </div>
    </>
  );
};

export default Moderator1cPage;
