import { useEffect } from 'react';

import useChatApplicantId from 'hooks/chat/useChatApplicantId';
import useAppDispatch from 'hooks/common/useAppDispatch';
import { getUserChats } from 'redux/actions';

const useChatsInit = () => {
  const dispatch = useAppDispatch();
  const applicantId = useChatApplicantId();

  useEffect(() => {
    if (applicantId) dispatch(getUserChats({ applicantId }));
  }, [applicantId]);
};

export default useChatsInit;
