import { useNavigate } from 'react-router-dom';

import useAppSelector from 'hooks/common/useAppSelector';
import { selectCurrentTrajectory } from 'redux/selectors/enrollee/application';

const useTrajectoryPage = () => {
  const navigate = useNavigate();
  const trajectory = useAppSelector(selectCurrentTrajectory);

  const onSubmit = () => {
    navigate('../training-level');
  };

  return {
    onSubmit,
    isSubmitButtonActive: trajectory === undefined,
  };
};

export default useTrajectoryPage;
