import { useMemo } from 'react';

import terms from 'i18n';

export type SelectOption = {
  id: number;
  value: string;
  label: string;
};

export type SelectProps = {
  options: SelectOption[];
  isLoading: boolean;
  error?: string;
};

const useLazyEnumSelectProps: (
  useGetEnumQuery: Function
) => SelectProps = useGetEnumQuery => {
  const { data, isLoading, isError } = useGetEnumQuery();
  const options = useMemo(() => {
    return data
      ? Object.keys(data).map((value, index) => ({
          id: index++,
          value: value,
          label: data[value],
        }))
      : [];
  }, [data]);

  return {
    options,
    isLoading,
    error: isError && !data ? terms.LOADING_ERROR : undefined,
  };
};

export default useLazyEnumSelectProps;
