import React, { FC } from 'react';

import useAppSelector from '../../../hooks/common/useAppSelector';
import { selectDirectionsRequestType } from '../../../redux/selectors/enrollee/application';
import { DirectionsForm } from './ui/DirectionsForm';

export type DirectionsFormState = 'BUDGET' | 'CONTRACT';

const DirectionsPage: FC = () => {
  const requestType = useAppSelector(selectDirectionsRequestType);

  return <DirectionsForm requestType={requestType} />;
};

export default DirectionsPage;
