import { EducationSystemStatus } from '../../models/applications/applications';

export const useExpertExaminationResultFormProps = () => {
  return {
    educationLevelSelectOptions: [
      { value: 'preparatory', label: 'Программа довузовской подготовки' },
      { value: 'secondaryBasic', label: 'Среднее общее образование' },
      {
        value: 'secondaryVocational',
        label: 'Среднее профессиональное образование',
      },
      { value: 'bachelor', label: 'Бакалавриат' },
      { value: 'speciality', label: 'Специалитет' },
      { value: 'master', label: 'Магистратура' },
      { value: 'phd', label: 'Аспирантура' },
    ],
    educationSystemStatusOptions: [
      {
        value:
          EducationSystemStatus.PART_OF_EDUCATION_SYSTEM_OF_DOCUMENT_ISSUER_COUNTRY,
        label: 'Является частью системы образования страны выдачи документов',
      },
      {
        value: EducationSystemStatus.PART_OF_EDUCATION_SYSTEM,
        label: 'Является частью системы образования',
      },
      {
        value: EducationSystemStatus.NOT_PART_OF_EDUCATION_SYSTEM,
        label: 'Не является частью системы образования какой-либо страны',
      },
    ],
  };
};
