import React, { FC } from 'react';

import EntranceExamsForm from 'components/statements/EntranceExamsForm';
import FillingStatementsForm from 'components/statements/FillingStatementsForm';
import useFillingStatementsRoutesContext from 'hooks/statements/useFillingStatementsRoutesContext';

const EntranceExamsPage: FC = () => {
  const { onNextPageNavigate } = useFillingStatementsRoutesContext();

  return (
    <FillingStatementsForm
      className="entry-exams__form"
      onSubmit={e => {
        e.preventDefault();
        onNextPageNavigate();
      }}
    >
      <EntranceExamsForm />
    </FillingStatementsForm>
  );
};

export default EntranceExamsPage;
