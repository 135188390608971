import { RootState } from 'types/redux';

export const selectChatOpened = (state: RootState) => state.chat.opened;

export const selectChatType = (state: RootState) => state.chat.chatType;

export const selectChatSocket = (state: RootState) => state.chat.socket;

export const selectChatViewed = (state: RootState) => state.chat.viewed;

export const selectUserChats = (state: RootState) =>
  state.chat.entities.userChats.data;

export const selectChatMessages = (state: RootState) =>
  state.chat.entities.chatMessages.data;

export const selectUnreadCount = (state: RootState) =>
  state.chat.entities.unreadCount.data;
