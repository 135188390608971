import React, { FC, MouseEventHandler, ReactNode } from 'react';

import cn from 'classnames';
import { NavRoute } from 'types/custom';

import Button from 'components/common/ui-kit/Button';
import SidenavLink from 'components/common/ui-kit/Sidenav/SidenavLink';
import terms from 'i18n';
import { ApplicationView } from 'models/applications/applications';

type SideNavProps = {
  className?: string;
  routes: NavRoute[];
  children?: React.ReactNode;
  mobileMenu?: {
    isMobile: boolean;
    routeContent: ReactNode;
    next: boolean;
    lastBtn: string;
    onSubmitForm: () => void;
    dis: boolean;
    readonly readonly: boolean;
    readonly onEditClick: MouseEventHandler<HTMLButtonElement>;
    currentApplication: ApplicationView | null;
  };
};

const Sidenav: FC<SideNavProps> = ({
  className,
  routes,
  children,
  mobileMenu,
}) => {
  return (
    <div className={className}>
      <nav>
        <ul>
          {routes.map(({ to, text, title, status }) => (
            <li
              key={to}
              className={cn({ 'mobile-border': mobileMenu?.isMobile })}
            >
              <SidenavLink
                to={to}
                text={text}
                status={
                  status === 'valid' || status === 'invalid'
                    ? status
                    : undefined
                }
              >
                {title}
              </SidenavLink>
              {mobileMenu?.isMobile &&
                (location.pathname.includes(to) ||
                  location.pathname === '/personal-information' ||
                  location.pathname.slice(-19) === '/filling-statements') && (
                  <div>
                    <div className="sidenav-page__inner">
                      {mobileMenu.routeContent}
                      {window.location.pathname.includes(
                        'personal-information'
                      ) &&
                        !mobileMenu.readonly &&
                        mobileMenu?.next && (
                          <Button
                            theme="success"
                            className="sidenav-page__button"
                            onClick={() => mobileMenu.onSubmitForm()}
                            disabled={mobileMenu.dis}
                          >
                            {mobileMenu.lastBtn
                              ? mobileMenu.lastBtn
                              : terms.NEXT}
                          </Button>
                        )}

                      {window.location.pathname.includes(
                        'personal-information'
                      ) &&
                        mobileMenu.readonly && (
                          <Button
                            theme="success"
                            className="sidenav-page__button"
                            onClick={mobileMenu?.onEditClick}
                            disabled={mobileMenu.dis}
                          >
                            {terms.EDIT}
                          </Button>
                        )}

                      {!window.location.pathname.includes(
                        'personal-information'
                      ) &&
                        mobileMenu?.next && (
                          <>
                            <Button
                              theme="success"
                              className="sidenav-page__button"
                              onClick={() => mobileMenu.onSubmitForm()}
                              disabled={
                                mobileMenu.dis ||
                                (location.pathname.includes('admin/users') &&
                                  location.pathname.includes('blank'))
                              }
                            >
                              {mobileMenu.lastBtn
                                ? mobileMenu.lastBtn
                                : terms.NEXT}
                            </Button>
                          </>
                        )}
                    </div>
                  </div>
                )}
            </li>
          ))}
        </ul>
        <div>{children}</div>
      </nav>
    </div>
  );
};

export default Sidenav;
