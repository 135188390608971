import { TermKey } from 'i18n/terms.ru';

const en: Record<TermKey, string> = {
  LOADING: 'Loading',

  // Электронная приемная комиссия
  SPBPU_ONLINE_APPLICATION_SYSTEM: 'SPbPU online application system',
  REVIEW_INFORMATION_ABOUT_ADMISSION:
    'In order to submit your application, please, review the information about admission on SPbPU website and',
  COMPLETE_REGISTRATION_PROCEDURE:
    'complete your application in accordance with the user manual',
  USER_MANUAL_FOR_RUSSIAN_APPLICANTS: 'User manual for Russian applicants',
  USER_MANUAL_FOR_FOREIGNERS:
    'User manual for foreign applicants (contract-based studies)',
  USER_MANUAL_FOR_FOREIGN_OPEN_DOORS:
    'User manual for foreign applicants (Russian Governmental scholarship holders and Open Doors Olympiad winners)',
  IF_ALREADY_HAVE_ACCOUNT:
    'If you have an account in the system, please use your e-mail and password to log in',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  RESTORE_PASSWORD: 'Restore password',
  REGISTER: 'Register',
  LOG_IN: 'Log in',
  ACCOUNT_CONFIRMATION_SENT:
    'An account confirmation email has been sent to your email',
  PASSWORD_UPDATED: 'Password updated',
  ACCOUNT_NOT_FOUND: 'Account not found',
  PASSWORD_RESET_EMAIL_SENT:
    'An email to change your password has been sent to',

  // Регистрация
  REGISTRATION: 'Registration',
  BASIC_DATA: 'Basic data',
  SURNAME_IN_RUSSIAN: 'Surname in Russian',
  CYRILLIC_CHARACTERS_ONLY: 'Cyrillic characters only',
  SURNAME_IN_LATIN: 'Surname in latin characters',
  LATIN_CHARACTERS_ONLY: 'Latin characters only',
  CYRILLIC_OR_LATIN_CHARACTERS_ONLY: 'Latin  or cyrillic characters only',
  CYRILLIC_OR_LATIN_WITH_SPECIALS_CHARACTERS_ONLY:
    "Only the characters of the Cyrillic alphabet, Latin, numbers or symbols [,.'`-/]",
  CYRILLIC_OR_LATIN_WITH_SPECIALS_CHARACTERS_AND_NUMBER_SIGN_ONLY:
    "Only the characters of the Cyrillic alphabet, Latin, numbers or symbols [,.'`-/№]",
  NAME_IN_RUSSIAN: 'Name in Russian',
  NAME_IN_LATIN: 'Name in latin characters',
  PATRONYMIC_IN_RUSSIAN: 'Patronymic name (if applicable) in Russian',
  PATRONYMIC_IN_LATIN: 'Patronymic name (if applicable) in latin characters',
  DATE_OF_BIRTH: 'Date of birth',
  CITIZENSHIP: 'Citizenship',
  AUTHORIZATION: 'Authorization',
  CREATE_PASSWORD: 'Create password',
  PASS_HINT:
    'Password must contain minimum 6 characters (uppercase and lowercase latin characters, numbers and special symbols)',
  CONSENT_TO_DATA_PROCESSING:
    'By clicking "register" button you agree to processing of the entered personal data in accordance with the Federal Law of the Russian Federation No. 152-ФЗ “On Personal Data” dated 27.07.2006',
  BACK: 'Back',
  EMAIL_CONFIRMED: 'Email confirmed',
  ACCOUNT_CREATED_AND_CONFIRMED:
    'Your account successfully created and confirmed. Now you can log in SPbPU online application system',
  PASSWORD_RESET: 'Password Reset',
  ENTER_YOUR_EMAIL_FOR_PASSWORD_RESET:
    'Please, enter your email that you used for registration and we will send you instructions for password reset',
  RESET: 'Reset',
  YOUR_ID_IN_SYSTEM: 'Your id in the system',
  CONTACT_TECHNICAL_SUPPORT: 'Contact technical support',

  // Анкета
  PERSONAL_INFORMATION: 'Personal information',
  APPLICATION: 'Application',
  ADDITIONAL_FILES: 'Additional files',
  LIST_OF_APPLICANTS: 'List of applicants',
  BASIC_DATA_HINT:
    'Fill in as it appears on your passport. If you do not know how to spell your name in Russian, your admission manager will do it for you',
  COMPATRIOT_DOCUMENTS: 'Documents confirming status of compatriot',
  COMPATRIOT_DOCUMENTS_HINT:
    'Fill in the compatriot-identification form and upload confirming documents',
  INFORMATION_ABOUT_PARENTS: 'Information about parents',
  INFORMATION_ABOUT_PARENTS_HINT: 'Fill in the information about your parents',
  PASSPORT_DATA: 'Passport data',
  VISA: 'Visa',
  VISA_HINT:
    'If you need a visa your passport should be valid at least for 18 months',
  PERMANENT_ADDRESS_AND_CURRENT_HOME_ADDRESS:
    'Permanent address in your country and current home address',
  PERMANENT_ADDRESS_AND_CURRENT_HOME_ADDRESS_HINT:
    'Fill in your permanent address (for example, where your parents live) and current address (where you live right now)',
  EDUCATIONAL_BACKGROUND: 'Educational background',
  EDUCATIONAL_BACKGROUND_HINT:
    'Upload your educational document and transcript (grades) in one file. In case you have not received your final educational documents, upload your current transcript of records and a document confirming the approximate issue date. You can upload the rest of your educational documents in the section "additional files"',
  PASSPORT_DATA_HINT: 'Fill in as it appears on your passport',
  SAVE_AND_CONTINUE: 'Save and continue',
  CREATED: 'Created at',
  EDITED: 'Edited at',
  ADDITIONAL_FILE_UPLOADED: 'Additional file uploaded',
  OWNER: 'Owner',
  REGISTRATION_ADDRESS: 'Registration address',
  ACTUAL_ADDRESS: 'Address of actual residence',
  ERROR_QUESTIONNAIRE_NOT_SAVED:
    'The questionnaire of this applicant does not saved',

  // Раздел "Основные данные"
  PHOTO: 'Photo',
  CHANGE_PASSWORD: 'Change password',
  SURNAME: 'Surname',
  NAME: 'Name',
  PATRONYMIC: 'Patronymic name (if applicable)',
  NO_PATRONYMIC: 'No patronymic name',
  NO_SURNAME: 'No surname',
  GENDER: 'Gender',
  MOBILE_PHONE: 'Mobile phone',
  ADDITIONAL_EMAIL_IF_ANY: 'Additional email (if any)',
  RECRUITING_COMPANY: 'Recruiting company',
  SNILS: 'SNILS',
  DATE_OF_ISSUE_SNILS: 'Date of issue of SNILS',
  NO_SNILS: 'No SNILS',
  COUNTRY_CODE: 'Country code',

  // Окно "Изменить пароль"
  CURRENT_PASSWORD: 'Current password',
  NEW_PASSWORD: 'New password',
  REPEAT_NEW_PASSWORD: 'Repeat new password',
  SAVE: 'Save',

  // Окно "Редактировать фотографию"
  SELECT_ANOTHER_PHOTO: 'Select another photo',
  SELECT_PHOTO: 'Select photo',
  PHOTO_REQUIREMENTS: 'Photo requirements: official photo.',
  PHOTO_REQUIREMENTS_BG:
    'Light background. Light grey is suggested. No patterns.',
  PHOTO_REQUIREMENTS_FACE:
    'The head should be completely visible and needs to be centered.',
  PHOTO_REQUIREMENTS_WIDTH: '',
  PHOTO_REQUIREMENTS_HEIGHT: '',
  PHOTO_REQUIREMENTS_SIZE: 'File size: maximum 1 MB',
  PHOTO_REQUIREMENTS_EXTENSION: 'File format: JPG or JPEG or PNG format',
  PHOTO_REQUIREMENTS_PX: '',
  PHOTO_REQUIREMENTS_CARD: 'This photo will be used for your student card.',
  PROFILE_PHOTO_UPDATED: 'Profile photo updated',
  EDIT_PHOTO: 'Edit photo',

  // Раздел "Документы, подтверждающие статус соотечественника"
  FILE_UPLOADER_TITLE: 'Upload',
  FILE_UPLOADER_DESCRIPTION:
    'You can upload JPG images (to be combined into one PDF) or one PDF document',

  // Раздел "Законный представитель"
  RELATIONSHIP: 'Relationship',
  DOCUMENT_TYPE: 'Document type',
  SERIES: 'Series',
  NUMBER: 'Number',
  DIVISION_CODE: 'Issuing authority code',
  DATE_OF_ISSUE: 'Date of issue',
  ISSUING_AUTHORITY: 'Issuing authority',
  COUNTRY: 'Country',
  CITY: 'City',
  REGION: 'Region',
  DISTRICT: 'District',
  STREET: 'Street',
  NO_STREET: 'No street',
  BUILDING: 'Building',
  CHARACTER: 'Character',
  SITE: 'Site',
  Apartment: 'Apartment',
  ZIP_CODE: 'ZIP-code',
  CONSENT_OF_PERSONAL_DATA_PROCESSING:
    'Consent for processing of personal data of the applicant (authorized by parent/legal representative)',

  // Раздел "Паспортные данные"
  DATE_OF_EXPIRATION: 'Date of expiration',
  COUNTRY_OF_BIRTH: 'Country of birth',
  PLACE_OF_BIRTH: 'Place of birth',
  I_HAVE_DUAL_CITIZENSHIP: 'I have dual citizenship',
  DUAL_CITIZENSHIP: 'Dual citizenship',
  COPY_OF_PASSPORT: 'Copy of passport',
  COPY_OF_PASSPORT_CLARIFIED:
    'Copy of the passport: the main page, as well as the page with the registration',
  I_AM_COMPATRIOT: 'I am a compatriot',
  I_NEED_VISA: 'I need a visa',
  I_HAVE_VNZ:
    'I have a permanent residence permit in Russia (вид на жительство в России)',
  RECEIVED_MINOBR_QUOTA:
    'I received a scholarship of the Russian Ministry of science and higher education / I am a winner of the Open Doors olympiad',
  I_HAVE_VISA_OR_RVP:
    'I have a Russian visa / Temporary residence permit in Russia (РВП)',
  DOCUMENT_NUMBER: 'Document number',
  VNZ: 'Permanent residence permit in Russia (вид на жительство в России)',
  REG_NUMBER_EDUCATION_IN_RUSSIA:
    'Application number on education-in-russia.com',
  MSHE_CONFIRMATION_LETTER:
    'Confirmation letter from the Russian Ministry of science and higher education',
  DOCUMENTS_REQUIRED: 'Make sure you attached following documents:',

  // Раздел "Виза"
  COUNTRY_OF_VISA_APPLICATION: 'Country of visa application',
  CITY_OF_VISA_APPLICATION: 'City of visa application',
  HAVE_BEEN_TO_RUSSIA_WITH_VISA:
    'Have you ever been to Russia with a study visa?',
  YES: 'Yes',
  NO: 'No',

  // Раздел "Адрес регистрации (по паспорту) и адрес проживания в настоящий момент"
  PERMANENT_ADDRESS:
    'Permanent address in your country (for example, where your parents live)',
  PERMANENT_PASSPORT_ADDRESS_AND_HOME_ADDRESS_THE_SAME:
    'Permanent address in your country and current home address are the same',
  CURRENT_HOME_ADDRESS: 'Current home address (where you live right now)',
  I_NEED_PLACE_OF_DORMITORY: 'I need a place at the dormitory',
  NEED: 'Yes, I need a place at the dormitory',
  NO_NEED: 'No, I do not need a place at the dormitory',

  // Раздел "Сведения об образовании"
  COUNTRY_WHERE_YOU_STUDIED: 'Country where you studied',
  MODE_OF_STUDY: 'Mode of study',
  EDUCATIONAL_INSTITUTION: 'Educational institution',
  YEAR_OF_GRADUATION: 'Year of graduation',
  STUDY_LEVEL: 'Study level',
  DOCUMENT_SERIES_AND_NUMBER: 'Document series (if any) and number',
  INSTITUTION_REGION: 'Region of the educational institution',
  DIPLOMA_WITH_DISTINCTION: 'Certificate/Diploma with distinction',
  GRADUATED_FROM_SPBPU: 'Graduated from SPbPU',
  STUDIED_IN_SPBPU_FOUNDATION_PROGRAM: 'Studied in SPbPU foundation program',
  EDUCATIONAL_DOCUMENT: 'Educational document',
  EDUCATIONAL_DOCUMENT_CLARIFIED: 'Copy of document',
  GPA: 'GPA',
  EDUCATIONAL_DOCUMENT_IS_REQUIRED: 'You have to attach educational document',
  GRADE_9: '9th grade',
  GRADE_11: '11th grade',
  ADD_DOCUMENT: 'Add document',
  ERROR_CAN_NOT_EDIT_DOCUMENT_TYPE:
    "Can't change Document Type of Application with ids",
  ERROR_CAN_NOT_DELETE_DOCUMENT:
    "Can't Delete Document. It used in Applications with ids",

  // Дополнительные файлы
  FILE: 'File',
  DESIGNATION: 'Name',
  ADD_FILE: 'Add file',
  EDIT_FILE: 'Edit file',
  EDIT: 'Edit',
  FILE_DELETED: 'File deleted',
  FILENAME_REQUIRED: 'File name is required',
  AVAILABLE_FORMATS:
    'This file format is not supported. Only pdf or jpeg are allowed',
  SUBMIT_ORIGINAL: 'Submit original',
  REVOKE_ORIGINAL: 'Revoke original',
  ERROR_SUBMITTING_ORIGINAL: 'Error submitting original',
  SUCCESS_SUBMITTING_ORIGINAL: 'Original submitted successfully',
  ERROR_REVOKING_ORIGINAL: 'Error revoking original',
  SUCCESS_REVOKING_ORIGINAL: 'Original revoked successfully',
  // Валидация
  REQUIRED_FIELD: 'Required field',
  TWO_CHARACTERS_MIN: 'Two characters minimum',
  ONLY_LOCALE_IS_ALLOWED: 'Latin characters only',
  DATE_RANGE:
    'The date of birth must be at least 14 and not more than 100 years ago',
  VALID_EMAIL:
    'The value of the field must be a valid email - Please, use a valid email address',
  VALID_MOBILE: 'The value of the field must be a valid phone',
  PASSWORD_MIXED:
    'The password must contain large and small Latin letters, numbers and special characters',
  PASSWORD_MIN_LENGTH: 'The minimum password length is 6 characters',
  PHONE_LENGTH: 'The phone length is 10 characters',
  PASSPORT_SERIES_LENGTH: 'The series length is 4 characters',
  INTERNATIONAL_RUSSIAN_PASSPORT_SERIES_LENGTH:
    'The series length is 2 characters',
  ONLY_DIGITS_ALLOWED: 'Only digits allowed',
  DIGITS_AND_SYMBOLS_ALLOWED:
    'Only digits and latin and cyrillic symbols allowed',
  PASSPORT_NUMBER_LENGTH: 'The number length is 6 characters',
  DIVISION_CODE_LENGTH: 'The division code length is 7 characters',
  DIVISION_CODE_TYPE: 'The division code must looks like: 000-000',
  DATE_DOCUMENT_ISSUE_INVALID:
    'The date of issue cannot be longer than the current one',
  PASSPORT_ISSUED_AT_DATE_INVALID:
    'The year of issue of the passport cannot be greater than the current year or less than the year of birth.',

  // FileUploader
  ONLY_ONE_PDF: 'Only one pdf file',
  ONLY_JPG: 'jpg and jpeg formats only',
  SIZE_BIGGER_3: 'The files size exceed 3MB',
  SIZE_BIGGER_10: 'The files size exceed 10MB',

  // Раздел Бланки документов
  APPLICATION_DATE: 'Application date and time',
  APPLICATION_DOWNLOAD: 'Download',
  HINT_APPLICATION_DOWNLOAD:
    'Download the document, check the data and click the button "sign"',
  HINT_RECEIPT_DOWNLOAD:
    'Present a printed receipt along with the original document of education for enrollment in SPbPU',

  // Раздел "Заявление на поступление" - "Направления поступления"
  DIRECTIONS_TRAINING: 'Fields of study',
  MAX_PROGRAMS_COUNT: 'You can select maximum ',
  // Родительный падеж (genitive case)
  DIRECTION_GEN_ONE: 'program',
  DIRECTION_GEN_MANY: 'programs',
  PROGRAM_GEN_ONE: 'program',
  PROGRAM_GEN_MANY: 'programs',
  ERROR_SETTING_DIRECTIONS:
    'An error occurred while updating fields of study. Make sure each field of study has the correct priority.',
  DIRECTIONS_CHOICE_ERROR: 'Directions choice error',
  // Раздел "Заявление на поступление" - "Бланки документов"
  FORMS_OF_DOCUMENTS: 'Forms and downloads',
  FORMS_OF_DOCUMENTS_TEXT: 'Please, check the forms and downloads',

  // Неотсортированное
  ERROR_HAS_OCCURRED: 'Unfortunately, an error has occurred. Please try again',
  LOADING_ERROR: 'Loading data error.',
  PASSWORDS_MUST_MATCH: 'Passwords must match',
  NEXT: 'Save and continue',

  CREATE_APPLICATION: 'Create application',
  NEW_APPLICATION: 'New application for admission',
  TRAJECTORY_FOR_ADMISSION: 'Admission track',
  LEVEL_OF_TRAINING: 'Study level',
  DIRECTIONS_TYPE: 'Funding',
  FACULTY: 'Faculty',
  EDUCATION_DOCUMENT: 'Education documents',
  FILLING_APPLICATION: 'Application',
  SAVE_AND_NEXT: 'Save and continue',
  ADD_MORE: 'Show more',
  DATA_NOT_RECEIVED: 'No programs',
  RESET_FILTER: 'Reset filter',
  SNILS_INVALID: 'SNILS entered incorrectly',
  APPLICATION_DELETED: 'Application deleted',
  APPLICATION_TAKEN_FOR_EDITING: 'Now you can edit your application',
  APPLICATION_STATUS_CHANGING_ERROR:
    'An error occurred when changing the status of the application',
  APPLICATION_DELETION_ERROR: 'An error occurred during application deletion',
  PREPARATORY_DEGREE: 'Preparatory',
  APPLICATION_EDITED_BY_USER: 'Edited by user',

  APPLICATION_TERMS_RESPONSIBILITY:
    'I am acquainted with the information on responsibility for the accuracy of the information specified in the application, and for the authenticity of the documents submitted upon admission.',
  APPLICATION_TERMS_RULES:
    'I am acquainted with SPbPU Charter, the License to carry out educational activities and its annexes, State Accreditation Certificate and its annexes, SPbPU Admission rules, SPbPU rules of conduct and the regulated information regarding organization and carrying out educational activities',
  APPLICATION_TERMS_DIPLOMA_ABSENCE:
    'Hereby I confirm that I do not have bachelor’s/specialist/master’s degree (for admission for state-funded studies).',
  APPLICATION_TERMS_PERSONAL_INFORMATION: 'I agree to personal data processing',
  APPLICATION_TERMS_UNIVERSITY_LIMIT:
    'I have applied for admission to not more than 5 universities.',
  APPLICATION_TERMS_EXAMS_PRIVILEGE:
    'Hereby I confirm my application in accordance with the special rights to priority admission only to SPbPU (for applicants eligible to direct admission without entrance exams).',
  APPLICATION_TERMS_SPECIALIST_DIPLOMA_ABSENCE:
    'Hereby I confirm that I do not have specialist degree diploma (except for higher vocational education diploma with professional qualifications) or master’s degree diploma (for admission for state-funded studies)',
  APPLICATION_TERMS_GRADUATE_COPIES:
    'I am acquainted with SPbPU License to carry out educational activities and its annexes, SPbPU Charter, Admission rules and learning environment',
  APPLICATION_TERMS_GRADUATE_GOVERNMENT_RIGHT:
    'I am acquainted with existence/absence (strike out whichever does not apply) of State Accreditation Certificate and its annexes entitled to issuance of nationally recognized document for selected study field (program)',
  APPLICATION_TERMS_GRADUATE_DIPLOMA_ABSENCE_BUDGET:
    'Hereby I confirm that I do not have a PhD degree diploma or doctoral certificate (for admission for state-funded studies)',
  APPLICATION_TERMS_GRADUATE_APPEAL:
    'I am acquainted with SPbPU exam appeal procedure',
  APPLICATION_TERMS_GRADUATE_DIPLOMA_ABSENCE_CONTRACT:
    'Hereby I confirm that I do not have a PhD degree diploma or doctoral certificate (within admission quota).',
  APPLICATION_TERMS_GRADUATE_DOCUMENT_ORIGINAL:
    'I undertake an obligation to submit original educational documents until 19.09.2024 in case of admission for state-funded places',
  APPLICATION_TERMS_GRADUATE_CONTRACT:
    'In case of admission for self-funded places, I should submit written consent for study contract drawing-up before 24.09.2024',
  CREATE_CONTRACT_ERROR:
    'You cannot create a study contract if you have not passed all the entrance exams or if you filled out the form incorrectly. ' +
    'If you have questions, please, contact your admission manager via chat in this system.',
  STATEMENT: 'Application',
  DATE_AND_TIME: 'Application date and time',
  STATUS: 'Status',
  SUBMITTED: 'Submitted',
  SUBMITTED_AT: 'Original documents submission date and time',
  NOT_SUBMITTED: 'Not submitted',
  REVOKED: 'Revoked',
  ORIGINAL_DOCUMENT: 'Original document',
  ACHIEVEMENTS: 'Achievements',
  PRIORITY_ENROLLMENT: 'Priority enrollment',
  CONTRACT: 'Study contract',
  DOWNLOAD_CONTRACT: 'Download contract',
  CONTRACT_TEXT: 'Upload a signed copy of your study contract',
  AGREEMENT: 'Enrollment agreement',
  SUBMIT_AGREEMENT: 'Submit agreement',
  REVOKE_AGREEMENT: 'Revoke agreement',
  AGREEMENT_SEND_DATE_CAPTION: 'Agreement was sent:',
  ENTRANCE_EXAMS: 'Entrance exams',
  SELECT_EXAM_DATE: 'Select exam date',
  WAIT_EXAM_DATE: 'Wait exam date',
  PASSING_SCORE: 'Passing score',
  EDUCATIONAL_PROGRAM: 'Educational program',
  ENTRANCE_EXAMS_TEXT: 'Select exam date and time',
  UNIFIED_EXAMS: 'Russian Unified State Exam (ЕГЭ) results',
  OFFLINE_EXAM: 'Offline exam',
  UNIFIED_EXAMS_TEXT:
    'Fill in this section if you have taken Russian Unified State Exam (ЕГЭ) or Centralized Testing (ЦТ) for citizens of Belarus',

  CHOOSE_DIRECTION: 'Select field of study',
  DIRECTION: 'Direction',
  FORM_PAYMENT_ID: 'Financing',
  BUDGET_LEVEL: 'Budget level',
  EDUCATION_LANGUAGE: 'Language',
  FIND: 'find',
  CONFIRM: 'Confirm',
  PAY_TUITION: 'Pay tuition',
  CHOOSE: 'Select',
  CANCEL: 'Cancel',

  PAYMENT_TYPE: 'Payment type',
  PAYER_APPLICANT_THE_SAME:
    'The payer under the contract (the customer) and the applicant are the same person',
  PAYER_IS_NOT_APPLICANT:
    'The payer under the contract (the customer) is not an applicant',
  PAYER_IS_ORGANIZATION:
    'The payer under the contract (the customer) is a legal organization',

  BANK_IDENTIFIER_CODE: 'The BIC length must be 9 characters',
  CHECKING_ACCOUNT: 'The checking account length must be 20 characters',
  CORRESPONDENT_ACCOUNT:
    'The correspondent account length must be 20 characters',

  POLYTECH_FULLNAME: 'Peter the Great St.Petersburg Polytechnic University',
  DOWNLOAD_FILE_ERROR: 'An error occurred while downloading the file',

  TITLE_BLANK_HEADER:
    'In order to send your application to SPbPU Admission office, you should do the following:',
  TITLE_BLANK_EL1: '1.  Select ALL checkboxes at the bottom of this page.',
  TITLE_BLANK_EL2: '2.  Download and check your application form.',
  TITLE_BLANK_EL3:
    '3.  Sign the application form by clicking the button “sign”. Your consent for processing of personal data was formed automatically upon registration in SPbPU online application system.',
  COMPLIANCE_LETTER: 'Compliance letter',
  RECEIPT_FOR_THE_ACCEPTED_ORIGINAL_DOCUMENT_OF_EDUCATION:
    'Receipt for the accepted original document of education',

  OPEN_APPLICATION: 'View application',
  EDIT_APPLICATION: 'Edit application',
  EDIT_FIELDS: 'Edit field of study or program choice',
  SEND_APPLICATION: 'Send application to Admission office',
  PLAN_CONTINUE:
    'After graduating from this university foundation program I plan to continue my education in:',
  STUDY_FIELD: 'Field of study',
  WITHDRAW_APPLICATION: 'Withdraw application',

  HINT_EXAM_DATE_TITLE:
    'When choosing the date of the entrance test, please pay attention to the following information:',
  HINT_EXAM_DATE_CLOSE_24H:
    'Registration for the exam closes 24 hours before the start of the exam',
  HINT_EXAM_DATE_AUTOMATIC_ENTRY:
    'Registration is activated automatically when picking a date from the menu',
  HINT_EXAM_DATE_IF_NOT_PLANNING:
    'If you changed your mind about the exam date you can reschedule the exam but no later than 24 hours before the start of the exam you wish to sign up for',
  HINT_EXAM_DATE_NEED_PRESS_BUTTON:
    'To start the exam you must click the “Start the exam” button on the date of the exam',
  HINT_EXAM_DATE_MOSCOW_TIME: 'Exam schedule uses Moscow time (GMT+3)',
  EXAM: 'Exam',
  EXAM_DATE: 'Exam date and time',
  NOT_GRADED: 'Not yet graded',
  EXAM_DATE_MOSCOW: 'Exam date and time (Moscow time zone GMT +3)',
  EXAM_DATE_CHANGE_ERROR:
    'You cannot select another exam date. Please, contact your admission manager.',
  EXAM_DATE_DELETE_ERROR: 'Could not change the date of the exam',
  HINT_NOT_AVAILABLE: 'Not available',
  DOWNLOAD_FORM: 'Download',
  HINT_DOWNLOAD_FORM:
    ' Download the contract, print it, sign it and upload the signed copy below',
  SIGNED_CONTRACT: 'Signed contract',
  SEND: 'Send',
  SET_BY_MODER_MARK: 'The score is set manually.',

  SING: 'Sign',
  START_EXAM: 'Schedule for entrance exams will be available here later.',
  CONTRACT_HINT:
    'In order to pay tuition fee, please, upload a scanned copy of your study contract',

  DISCIPLINES_HINT:
    'In order to give consent for enrollment you should create a study contract and upload a scanned signed copy of it in the section "Study contract"',
  ACHIEVEMENTS_HINT: 'To save the changes, click the "Continue" button',

  CONSENT_GIVEN: 'Consent was given',
  GIVE_CONSENT: 'Give consent for enrollment',
  CONFIRM_CONSENT: 'Confirm',

  // Льготы и преимущественные права
  ADD_PRIVILEGES_TITLE: 'Add privileges',
  EDIT_PRIVILEGES_TITLE: 'Edit privileges',
  ADD_PREEMPTIVE_RIGHT_TITLE: 'Add preemptive right',
  EDIT_PREEMPTIVE_RIGHT_TITLE: 'Edit preemptive right',
  ADD_INDIVIDUAL_QUOTA_TITLE: 'Add individual quota',
  EDIT_INDIVIDUAL_QUOTA_TITLE: 'Edit individual quota',
  TYPE_PREEMPTIVE: 'Cause',
  TYPE_LGOTA: 'Type privileges',
  TYPE_INDIVIDUAL_QUOTA: 'Type individual quota',
  TYPE_ACHIEVEMENT: 'Type of achievement',
  TYPE_DOCUMENT: 'Type document',
  NUMBER_DOCUMENT: 'Series (if any) and document number',
  DATE_ISSUE: 'Date of issue',
  DOCUMENT: 'Document',
  CHECKBOX_LGOTA: "I don't have benefits or preemption",
  CHECKBOX_PREEMPTIVE_RIGHTS: "I don't have priority enrollment",
  ADD_BUTTON: 'ADD',
  LGOTS: 'Privileges',
  LGOT: 'Benefit',
  SPECIAL_QUOTA: 'Specialized quota',
  SPECIAL_ADMISSION: 'According to the features of admission in 2024',
  INDIVIDUAL_QUOTA: 'Individual quota',
  PREEMPTIVE_RIGHTS: 'Preemptive right',
  PRIORITY_RIGHTS: 'Priority right',
  PRIORITY_RIGHT_TYPE: 'Priority right type',
  ADD_PRIORITY_RIGHT: 'Add priority right',
  EDIT_PRIORITY_RIGHT: 'Edit priority right',
  CONFIRMATION_DOCUMENT: 'Confirmation document',
  SERIES_AND_NUMBER: 'Series and number',

  // Отличительные признаки
  ADD_HALLMARK_TITLE: 'Add hallmark',
  EDIT_HALLMARK_TITLE: 'Edit hallmark',
  HALLMARK_TYPE: 'Hallmark type',

  // Направление подготовки (модератор)
  REMOVED: 'Removed',
  DISCIPLINES: 'Disciplines',
  SOURCE_OF_FINANCING: 'Source of financing',
  TARGET_ORGANIZATION: 'Target organization',
  TARGET_ORGANIZATION_NAME: 'Full name',
  TARGET_ORGANIZATION_SHORTNAME: 'Short name',
  TARGET_ORGANIZATION_ADDRESS: 'Address',
  TARGET_ORGANIZATION_PHONE: 'Phone',
  TARGET_ORGANIZATION_EMAIL: 'Email',
  FORM_OF_STUDY: 'Form of study',
  THE_LEVEL_OF_EDUCTION: 'The level of education',
  SUBDIVISION: 'Subdivision',
  CREATION_DATE: 'Date and time for adding',
  DELETION_DATE: 'Date and time for recall',
  REFUSE_ENROLLMENT_DATE: 'Date and time for enrollment refuse',
  ADMITTED: 'Available to unloading',
  ENTRANCE_TESTS_SATISFIED: 'There is a confirmed benefit',
  PRIVILEGE_ADMITTED: 'There is a confirmed privilege',
  TARGET_CONTRACT_PRESENT: 'There is a target contract',
  DELETE: 'Delete',
  CONFIRM_MODAL:
    'Please, confirm your action. By clicking the button "yes" you sign consent for enrollment form',
  CONFIRM_WITHDRAWN_APPLICATION:
    'After clicking the button "Withdraw the application" the application will be transferred to the status "Withdraw approved"',
  ERROR_CANT_UPDATE_CHECKLIST: "Can't update checklist",
  ERROR_CANT_PARSE_CHECKLIST: "Can't parse checklist",

  // ЕГЭ
  DIDNT_TAKE: 'Did not take Russian Unified State Exam (ЕГЭ)',
  SUBJECT: 'Subject',
  ADD_SUBJECT: 'Add subject',
  SCORE: 'Score',
  EXAM_YEAR: 'Year of exam',
  MINIMUM_SCORE: 'Minimum passing score',
  CHECK_RESULTS: 'Check the results',
  ANOTHER_PASSPORT: 'I took Russian Unified State Exam with another passport',

  // Договора
  CREATE_CONTRACT: 'Create contract',
  PAYER_INFORMATION: 'Information about the payer',
  PAYER_PASSPORT: 'Passport data',

  // Чат
  CHAT_TITLE: 'Chat',
  CHAT_SUBTITLE: 'Write your question here',
  CHAT_PLACEHOLDER: 'Write your question here',
  CHAT_FILE_UPLOAD:
    'Place the file here to attach it to the message (max 5 mb)',

  // Списки поступающих
  APPLICANTS_PROGRAM: 'Admission to',
  STUDIES: 'studies',
  PROGRAMS: 'programs',
  PHD: 'PhD',
  FOR_RUSSIAN_CITIZEN: '2022 (Russian citizens)',
  FOR_FOREIGN_CITIZEN: '2022 (Foreign citizens)',
  ENROLLMENT_OPTION: 'Enrollment option',
  INSTITUTE: 'Institute',
  ENTER_APPLICANT_ID: 'Enter applicant ID',
  PROFILE: 'Educational program',
  WITHOUT_PROFILE: 'N/A',
  SEARCH: 'Search',
  PRIORITY_ENROLLMENT_APPLICANTS: 'Priority enrollment',
  LAST_UPDATE: 'Last update: ',
  TABLE_SEARCH: 'Search',
  TABLE_NUMBER: 'No.',
  TABLE_ID: 'ID',
  TABLE_OLYMPIAD: 'Olympiad',
  TABLE_GRADE: 'Total grade (entrance exams)',
  TABLE_GRADE_ALL_SPO: 'Average score of the certificate',
  TABLE_GRADE_ACHIEVEMENTS: 'Total grade (achievements)',
  TABLE_GRADE_ALL: 'Total grade (all)',
  TABLE_ORIGINAL: 'Original education documents',
  TABLE_COPY: 'Copy of education document',
  TABLE_CONSENT: 'Enrollment consent',
  TABLE_PRIORITY: 'Priority enrollment',
  TABLE_STATUS: 'Status',
  TABLE_FULL_NAME: 'Full name',
  TABLE_NEED_HOSTEL: 'The need for a hostel',
  TABLE_PROFILE_POINT: 'Profile score',
  TABLE_ADVANTAGE: 'Priority',

  // Gender
  MALE: 'Male',
  FEMALE: 'Female',

  // EducationForm
  DISTANT: 'Distant',
  PART_TIME: 'Part time',
  FULL_TIME: 'Full time',

  // GeneralDataForm
  ERROR_SNILS_ISSUE_BEFORE_BIRTHDAY:
    'The date of issue of the SNILS cannot be earlier than the date of birth',
  ERROR_FIRST_SPECIFY_BIRTHDAY: 'First enter your date of birth',

  // LegalRepresentativeFrom
  ERROR_SPECIFY_ALL_OR_NOTHING:
    'You need to specify either all required fields or none',

  // PassportForm
  ERROR_SAME_PRIMARY_AND_SECONDARY_CITIZENSHIP:
    "Primary and secondary citizenship can't be same",
  ERROR_DOC_EXP_DATE_BEFORE_ISSUE:
    "Document expiration date can't be before document issuing date",
  ERROR_DIFF_BIRTHDAY_DOC_ISSUE_SHOULD_BE_LESS_14:
    'The date of birth must be less than the date of document issue by at least 14 years',
  ERROR_DOC_EXP_LESS_THAN_1900:
    'Document expiration date should be greater than 1900 year',
  ERROR_DOC_ISSUE_LESS_THAN_1900:
    'Document issue should be greater than 1900 year',

  // EducationInformationForm
  ERROR_GRADUATION_YEAR_TOO_SMALL: "Graduation year shouldn't be before 1960",
  ERROR_GRADUATION_YEAR_TOO_BIG:
    'The end year cannot be in the future or after the date of issue of the document',
  ERROR_GRAD_YEAR_SHOULD_BE_GREATER_THAN_BIRTHDAY:
    'The year of graduation must be at least 14 years older than the year of birth',
  ERROR_GRAD_CANT_BE_AFTER_ISSUE:
    'The year of graduation cannot be after the year of receipt of the education document',
  ERROR_FIRST_SPECIFY_ISSUED_AT:
    'First, specify the date of issue of the document',

  // ApplicationState
  NOT_CREATED: 'Не создано',
  DRAFT: 'Draft',
  UNDER_CONSIDERATION: 'Sent to Admission office',
  IN_WORK: 'Under consideration',
  SENT_FOR_EDITING: 'Editing',
  ACCEPTED: 'Approved',
  ALLOWED: 'Accepted',
  ENROLLED: 'Enrolled',
  DIRECTIONS_EDITING: 'Editing (Fields of study)',
  DIRECTIONS_EDITED: 'Edited and sent to Admission office',
  WITHDRAWN: 'Withdrawn',
  WITHDRAWN_APPROVED: 'Withdrawal approved',
  REJECTED: 'Rejected',
  EDITED_BY_USER: 'Edited by user',

  // ContactState
  PROJECT: 'Draft',
  EDITING: 'Editing',
  APPROVED: 'Approved',
  CONFIRMED: 'Confirmed',
  CANCELLED: 'Cancelled',

  SENT_TO_EXPERT: 'Отправлено на экспертизу',
  APPROVED_BY_EXPERT: 'Одобрено экспертом',
  REJECTED_BY_EXPERT: 'Отказано экспертом',

  SENT_TO_COORDINATOR: 'Отправлено координатору',
  APPROVED_BY_COORDINATOR: 'Одобрено координатором',
  REJECTED_BY_COORDINATOR: 'Отказано координатором',

  READ: 'Нет непрочитанных сообщений',
  UNREAD: 'Есть непрочитанные сообщения',

  SECONDARY: 'СПО',

  // Directions request type
  BUDGET_FORM: 'State funded',
  CONTRACT_FORM: 'Contract-based (paid education)',

  // Education levels
  BACHELOR_EDUCATION_LEVEL: "Bachelor's degree",
  SECONDARY_VOCATIONAL_EDUCATION_LEVEL: 'Secondary vocational education',
  SPECIALIST_EDUCATION_LEVEL:
    'Specialist degree (SPbPU branch in Sosnoviy Bor)',
  MASTER_EDUCATION_LEVEL: "Master's degree",
  GRADUATE_EDUCATION_LEVEL: 'PhD degree',
  UNIVERSITY_FOUNDATION_PROGRAMS_EDUCATION_LEVEL:
    'University foundation programs',

  LOCAL: 'Поступаю как гражданин РФ',
  FOREIGN: 'Admission of foreign citizens',
  QUOTA:
    'Admission of the applicants within quotas of the Russian Ministry of science and higher education',

  HAVE_MINOBR_DIRECTION:
    'I received the scholarship of the Russian Ministry of science and higher education',
  OPEN_DOORS_WINNER: 'I am a winner of the Open Doors olympiad',
  STATE_FUNDED_OR_PAID_PLACES: 'Поступаю на бюджетные или платные места',
  GETTING_DORMITORY_COMPETITIVE:
    'Получаю место в общежитии на конкурсной основе',
  HAS_COMPATRIOT_DOCS: 'Имею документы, подтверждающие статус соотечественника',
  OR_CITIZEN: 'или являюсь гражданином',
  BELARUS: 'Республики Беларусь',
  KAZAKHSTAN: 'Республики Казахстан',
  KYRGYZ: 'Киргизской республики',
  TAJIKISTAN: 'Республики Таджикистан',

  APPLY_CONTRACT_ONLY: 'Self-funded (contract based) studies only',
  GETTING_DORMITORY_ONLY_IF_ANY_LEFT: 'Shared student accommodation on campus',
  ADMISSION_INFO_HERE: 'Information about admission is available here',

  OLYMPIAD: 'Без вступительных испытаний/Олимпиада',
  GOVERNMENT_CONTRACT: 'Целевой прием',
  BENEFIT: 'Особое право/Льгота',
  PREROGATIVE: 'Преимущественное право',

  RUSSIAN_PASSPORT: 'Российский паспорт',
  INTERNATIONAL_RUSSIAN_PASSPORT: 'Международный российский паспорт',
  FOREIGN_PASSPORT: 'Иностранный паспорт',
  STATELESS_IDENTITY_CARD: 'Удостоверение личности без гражданства',
  OTHER: 'Другое',

  POSTGRADUATE: 'PhD degree',
  PRIMARY_VOCATIONAL: 'Initial professional education',
  SECONDARY_VOCATIONAL: 'Secondary professional education',
  PRIMARY_BASIC: 'Initial basic',
  SECONDARY_BASIC: 'Secondary basic',
  NO_EDUCATION_DOCUMENT_TYPE: 'No education document type',
  HIGHER_EDUCATION: 'Higher education',

  POSTGRADUATE_DIPLOMA: 'Postgraduate diploma',
  BACHELOR_DEGREE: "Bachelor's degree",
  MASTER_DEGREE: "Master's degree",
  SPECIALIST_DIPLOMA: 'Diploma of specialist',
  PRIMARY_VOCATIONAL_EDUCATION_DIPLOMA:
    'Diploma of primary vocational education',
  SECONDARY_VOCATIONAL_EDUCATION_DIPLOMA:
    'Diploma of secondary vocational education',
  BASIC_GENERAL_EDUCATION_CERTIFICATE: 'Certificate of basic general education',
  SECONDARY_GENERAL_EDUCATION_CERTIFICATE:
    'Certificate of secondary general education',
  ACADEMIC_CERTIFICATE: 'Academic background',

  CANNOT_EDIT_IF_APPLICATIONS_HAS:
    'You cannot edit the section "personal information" if it was received from gosuslugi.ru, or if your application is sent to Admission office',
  CANNOT_CREATE_NEW_APPLICATION_WITH_USED_EDU_LEVEL:
    'You cannot create multiple applications for the same level of education',
  CANNOT_CREATE_NEW_APPLICATION_WITHOUT_EDUCATIONAL_DOCUMENT:
    'You cannot create application without choosing educational document',

  ERROR_ALREADY_HAS_SIGNED_AGREEMENT_DOCUMENT:
    'You cannot sign an enrollment agreement if you have previously signed an enrollment agreement for another program',

  DOC_WAS_SIGNED: 'Document was signed',
  ERROR_DOC_DESIRED_DIRECTION_NOT_CHOSEN:
    'Select the desired direction before generating the document',
  ERROR_FIRST_CHOOSE_DIRECTIONS:
    'You should select at least one program in order to create an application.',
  ERROR_TRAJECTORY_NOT_CHOSEN:
    'You cannot create statements if you have not chosen an admission trajectory',
  PHOTO_REQUIRED: 'Photo required',

  MAKE_SURE_ALL_FILES_ATTACHED: 'Make sure all files are attached',
  APPLICATION_OF_RECOGNITION: 'Application of recognition',
  BIRTH_CERTIFICATE: 'Birth certificate copy',
  COMPATRIOT_STATUS_DOCS:
    'Copies of documents confirming the status of a compatriot:\n' +
    '           documents confirming the citizenship of the USSR, residence in the states that were part of the USSR, obtaining citizenship of these states;\n' +
    '           documents of a native (emigrant) from the Russian state, the Russian Republic, the RSFSR, the USSR and the Russian Federation, who has the appropriate citizenship and has become a citizen of a foreign state;\n' +
    '           documents confirming kinship in a direct ascending line with the above citizens',

  CONTRACT_PAYMENT_TYPE: 'Payment type',
  REQUIRED_FOR_SECOND_VOCATIONAL:
    'Required for completion at the Institute of Secondary vocational education (college)',
  NUMBER_OF_SATISFACTORY_GRADES: 'Number of «Satisfactory» grades',
  NUMBER_OF_GOOD_GRADES: 'Number of «Good» grades',
  NUMBER_OF_EXCELLENT_GRADES: 'Number of «Excellent» grades',
  SUBMIT_QUESTIONNAIRE_BEFORE_ACCESSING_APPLICATIONS:
    'Please, press the button «SAVE AND CONTINUE» in the section «EDUCATION BACKGROUND» at the bottom of this page',

  // Enroll list
  SNILS_OR_UNIQUE_CODE: 'SNILS / Unique code',
  FULLNAME_OR_SNILS_OR_UNIQUE_CODE: 'Full name / SNILS / Unique code',
  NO_ENTRANCE_EXAMS_OR_OLYMPIC: 'No entrance tests / Olympics',
  TOTAL_SCORE: 'Total score',
  TOTAL_SUBJECTS_SCORE: 'Total score for subjects',
  MATH: 'Maths / Elements of higher maths',
  RUSSIAN: 'Russian language',
  INFORMATICS_OR_PHYSICS: 'Informatics / Physics / Informational technologies',
  PERSONAL_ACHIEVEMENTS_SCORE: 'Total score for personal achievements',
  FEATURE: 'Prerogative right',
  EDUCATION_DOCUMENT_TYPE: 'Education document type',
  ENROLLMENT_AGREEMENT: 'Consent to enrollment',
  PRIORITY: 'Priority',
  EDUCATION_FORMAT: 'Education form',
  ADMISSION_CONDITIONS: 'Admission conditions',
  DIRECTION_EDUCATIONAL_PROGRAM: 'Direction / Educational program',
  ENTER_DIRECTION_CODE: 'Enter direction code',

  BACHELOR_OR_SPECIALIST: 'Bachelor/Specialist',
  SECONDARY_DEGREE_SHORT: 'SECONDARY',

  BACHELOR: "Bachelor's degree",
  MASTER: 'Master',
  GRADUATE: 'Graduate',
  PREPARATORY: 'Preparatory',

  SELF_PAYMENT:
    'The payer under the contract (the customer) and the applicant are the same person',
  THIRD_PARTY: 'The payer under the contract (customer) is not an applicant',
  MATERNAL: '',
  JURIDICAL:
    'The payer under the contract (customer) is a juridical organization',

  ERROR_SUBMITTING_APPLICATION: 'Error when submitting the application',

  USER_ADDED_ADDITIONAL_FILE:
    'The user added the file to the "Additional files" section',

  // Roles
  USER: 'User',
  ADMIN: 'Admin',
  COORDINATOR: 'Coordinator',
  MODERATOR: 'Moderator',
  SUPPORT: 'Support',
  EXPERT: 'Expert',
  SPECIALIST: 'Specialist',
  INSTITUTE_STAFF: 'Institute staff',
  CALL_CENTER: 'Call center',

  // RelationDegree
  MOTHER: 'Mother',
  FATHER: 'Father',
  TRUSTEE: 'Legal guardian',

  // Modal
  DIRECTIONS_EDITING_MODAL_TEXT:
    'Are you sure that you need to edit the fields of study in your application?',
  EXAM_DATETIME_DELETE_TEXT_1: 'Вы собираетесь стереть выбор экзамена ',
  EXAM_DATETIME_DELETE_TEXT_2: 'на',
  EXAM_DATETIME_DELETE_PASSED:
    'Этот экзамен сдан и за него выставлена оценка>. Это действие нельзя отменить. ' +
    'Вы сотрете выбор даты экзамена и выставленную оценку. ' +
    'Ранее выбранная дата может быть больше недоступна для выбора. ' +
    'Хотите продолжить?',

  // AuditEvent
  APPLICATION_STATUS_UPDATE: 'Изменение статуса заявления',
  APPLICATION_AGREEMENT_UPDATE: 'Подача / отзыв согласия',
  APPLICATION_CREATION: 'Создание заявления',
  APPLICATION_CONTRACT_CREATE: 'Создание договора',
  APPLICATION_CONTRACT_UPDATE: 'Обновление договора заявления',
  APPLICATION_DIRECTIONS_UPDATE: 'Обновление направлений заявления',
  APPLICATION_MINOR_UPDATE: 'Редактирование заявления',
  APPLICATION_DIRECTION_DELETE: 'Удаление направления',
  APPLICATION_EDU_DOC_CHANGE: 'Изменение ДОО в заявление',
  APPLICATION_ORIGINAL_CHANGE: 'Изменение оригинала',
  APPLICATION_DESIRED_DIRECTION_CHANGE: 'Изменение "планируемого" направления',
  APPLICATION_ACHIEVEMENT_FORM_CHANGE:
    'Изменение данных о количестве публикаций',
  APPLICATION_SENT_TO_QUEUE_CHANGE: 'Ручная отправка заявления в очередь',
  APPLICATION_PROFILE_SUBJECT_SCORE_SET: 'Изменение бала профильных предметов',
  APPLICATION_PRIVILEGE_CREATE: 'Создание особой отметки',
  APPLICATION_PRIVILEGE_UPDATE: 'Редактирование особой отметки',
  APPLICATION_PRIVILEGE_DELETE: 'Удаление особой отметки',
  APPLICATION_OLYMPIAD_CREATE: 'Создание олимпиады',
  APPLICATION_OLYMPIAD_UPDATE: 'Редактирование олимпиады',
  APPLICATION_OLYMPIAD_DELETE: 'Удаление олимпиады',
  APPLICATION_ACHIEVEMENT_CREATE: 'Создание индивидуального достижения',
  APPLICATION_ACHIEVEMENT_UPDATE: 'Редактирование индивидуального достижения',
  APPLICATION_ACHIEVEMENT_DELETE: 'Удаление индивидуального достижения',
  APPLICATION_GOVERNMENT_CONTRACT_CREATE: 'Создание целевого договора',
  APPLICATION_GOVERNMENT_CONTRACT_UPDATE: 'Редактирование целевого договора',
  APPLICATION_GOVERNMENT_CONTRACT_DELETE: 'Удаление целевого договора',
  APPLICATION_PRIVILEGE_EXTRA_CREATE:
    'Создание особенности приёма для сдачи ВИ',
  APPLICATION_PRIVILEGE_EXTRA_UPDATE:
    'Редактирование особенности приёма для сдачи ВИ',
  APPLICATION_PRIVILEGE_EXTRA_DELETE:
    'Удаление особенности приёма для сдачи ВИ',
  USER_REGISTERED: 'Регистрация пользователя',
  USER_EDITED: 'Пользователь отредактирован',
  USER_ARCHIVED: 'Пользователь заблокирован',
  USER_UNARCHIVED: 'Пользователь разблокирован',
  QUESTIONNAIRE_SAVED: 'Сохранение анкеты',
  QUESTIONNAIRE_TAKEN: 'Редактирование анкеты',
  UPLOAD_USER_BLOCKS: 'Выгрузка информации в 1С',
  PROCESS_BLOCKS_UPLOAD_CONFIRMATION: 'Подтверждение обработки выгрузки',

  // AuditEventTypeHead
  QUESTIONNAIRE: 'Анкета',
  ONE_C: 'Взаимодействие с 1С',

  // Достижения
  INDIVIDUAL_ACHIEVEMENTS: 'Individual achievements',
  ADMISSION_CAMPAIGN: 'Admission campaign',

  // Juridical contract form
  ORGANISATION_NAME: 'Organisation name',
  ORGANISATION_SHORT_NAME: 'Organisation short name',
  MANAGER_BASES: 'Details of the document of the customer representative',
  BANK_NAME: 'Bank name',
  BANK_BIC: 'Russian Central Bank Identification Code (RCBIC)',
  PAYMENT_ACC: 'Account',
  CORRESPONDENT_ACC: 'Correspondent account',
  PHONE_NUMBER: 'Phone number',
  ORGANISATION_PHYS_ADDR: 'Actual address of the organisation',
  JURIDICAL_ADDR: 'Juridical address of the organisation',
  IN_NAME: 'Surname, name, patronymic of the representative of the Customer',
  INN: 'Taxpayer Identification Number (INN)',
  KPP: 'Tax Registration Reason Code (KPP)',
  OGRN: 'Primary State Registration Number (OGRN)',

  // Entrance exams form
  EXAM_PASSED: 'Exam passed',
  EXAM_FAILED: 'Exam failed',
  EXAM_START: 'Start exam',
  ENTRANCE_EXAM_DATE_CHANGE_SUCCESS:
    'Please note that admission to the exam is made when the application status becomes “Approved”. If it is discovered that you do not have the right to take the entrance test, the exam results will be cancelled.',

  // Olympiads form
  OLYMPIADS: 'Olympiads',

  // Table directories
  ADD_DIRECTORY: 'Add section to the directory',

  // Target agreement form
  HAS_NO_CONTRACT: "I don't plan to do target quota",
  OFFER_DATA: 'Applicant’s personal data for targeted training contract',
  ORGANIZATION_NAME: 'Name of the institution',
  OFFER_NUMBER: 'Quotation ID number',
  ISSUE_DATE: 'Offer date',
  DOCUMENT_FILE: 'Targeted Training Contract Request File',

  // ContractList
  DOCUMENT_STATUS: 'Document status',
  DOCUMENT_NAME: 'Document name',
  VALID_UNTIL: 'Valid until',
  PAYER: 'Payer',
  ORGANISATION_FULL_NAME: 'Full name',
  ORGANISATION_GENERAL_DIRECTOR: 'General director',
  ADDRESS: 'Address',
  CONTACT_NUMBER: 'Contact phone number',
  FIO: 'Full name',
  PHONE_NUMBERS: 'Phone number(s)',
  PROFILE_SCORE: 'Profile score',

  // ЕПГУ
  NOT_RELATED: 'Не из ЕПГУ',
  SUCCESS: 'Загружено из ЕПГУ',
  ATTENTION: 'Ручная синхронизация',

  // Инструкции
  ENTRANCE_TESTS_INSTRUCTION: 'Instructions for taking the entrance test',
  CONTRACT_CREATION_INSTRUCTION: 'Instructions for creating contracts',
  CONTRACT_HEADER_MESSAGE:
    'Dear Applicants, after the information concerning the contract is generated on the Public Services Portal of the Russian Federation, we kindly ask you to submit the original signed contracts (from both the Contractor and the Student) to SPbPU. Please ensure that this is done within the deadlines specified in the Admission Rules and the instructions below.\n',

  INSTRUCTIONS_WILL_BE_LATER:
    'User manual for foreign applicants will be uploaded to the section "Additional files".',

  UNSENT_MESSAGE_WARN_1: 'You have messages',
  UNSENT_MESSAGE_WARN_2: 'waiting to be sent',
  UNSENT_MESSAGE_WARN_3: 'Closing the tab you risk losing them',
  CONFIRM_EXIT: 'Leave page',
  STAY_ON_PAGE: 'Stay on page',

  SIGN_UP_UNAVAILABLE_MESSAGE: 'Online registration starts March 25, 2024.',

  NONE: 'No measures',
  LOW: 'Less than 15 thousand rubles per year',
  MEDIUM: 'from 15 to 30 thousand rubles per year',
  HIGH: 'over 30 thousand rubles per year',

  PHYSICAL: 'Physical original',
  ELECTRONIC_COPY: 'Electronic copy',
  SIGNED_PHYSICAL_COPY: 'Trusted physical copy',

  // TargetAgreeStatus
  REFUSED_BY_EMPLOYER: 'Заявка отклонена Заказчиком',
  REFUSED_DUE_TO_EXPIRATION:
    'Заявка отклонена в связи с истечением срока рассмотрения',
  ACCEPTED_BY_EMPLOYER: 'Заявка одобрена Заказчиком',
  UNDER_CONSIDERATION_BY_EMPLOYER: 'На рассмотрении у заказчика',
  REVOKED_BY_APPLICANT: 'Заявка отозвана гражданином',

  // epguStatus статус КГ из ССПВО
  NEW: 'Новое',
  RECEIVED_BY_UNIVERSITY: 'Получено вузом',
  ADDITIONAL_INFORMATION_REQUIRED: 'Запрос дополнительных сведений',
  PROFILE_EDITING: 'Редактирование профиля',
  EXAM_DATE_ASSIGNED: 'Назначена дата ВИ',
  EXAM_DATE_SELECTION: 'Выбор даты ВИ',
  ON_COMPETITION: 'Участвует в конкурсе',
  COMPETITION_FAILED: 'Не прошло по конкурсу',
  INCLUDED_IN_ENROLLMENT_ORDER: 'Включено в приказ на зачисление',
  REFUSED: 'Отклонено',
  AGREEMENT_REVOKED: 'Отзыв согласия',
  ENROLLMENT_REFUSED: 'Отказ от зачисления',
  AGREEMENT_EDUCATION_DOCUMENT: 'Согласие/Документ об образовании',
  AGREEMENT_EDUCATION_DOCUMENT_REVOKED:
    'Отзыв согласие/Документ об образовании',
  PROGRESS_OF_THE_ADMISSION_CAMPAIGN: 'Progress of the admission campaign',
};

export default en;
