import useChatInitBadge from 'hooks/chat/useChatInitBadge';
import useChatInitMessages from 'hooks/chat/useChatInitMessages';
import useChatInitMethods from 'hooks/chat/useChatInitMethods';
import useChatInitSocket from 'hooks/chat/useChatInitSocket';
import useChatsInit from 'hooks/chat/useChatsInit';
import useChatTypeInit from 'hooks/chat/useChatTypeInit';

const useChatInit = () => {
  useChatsInit();
  useChatTypeInit();
  const refetchBadge = useChatInitBadge();
  const refetchMessages = useChatInitMessages();
  useChatInitSocket();
  useChatInitMethods({ refetchMessages, refetchBadge });
};

export default useChatInit;
