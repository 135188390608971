import React, { ReactElement, ReactNode } from 'react';

import classNames from 'classnames';

import terms from 'i18n';

export const renderDefault = (value?: ReactNode) => {
  if (typeof value === 'boolean') {
    return value ? terms.YES : terms.NO;
  }
  return value ?? '-';
};

const ObjectEntriesView = <T extends { [key: string]: any }>({
  object,
  className,
  keysToTitles,
  keysToRenderers,
}: {
  object: T;
  className?: string;
  keysToTitles: Partial<Record<keyof T, string>>;
  keysToRenderers?: Partial<
    Record<keyof T, (value: any, object: T) => ReactNode>
  >;
}): ReactElement => {
  return (
    <div className={classNames('object-entries-view', className)}>
      {Object.entries(object).map(([key, value]) => {
        const title = keysToTitles[key];
        if (!title) return;
        const render = keysToRenderers?.[key];
        return (
          <div className="object-entries-view__entry" key={key}>
            <p>{title}:</p>
            <span className="object-entries-view__value">
              {render ? render(value, object) : renderDefault(value)}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default ObjectEntriesView;
