import { v4 as uuidv4 } from 'uuid';

import useChatId from 'hooks/chat/useChatId';
import { toMessage } from 'hooks/chat/useChatInitMethods';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import { ChatAttachment, ChatMessage, SendPayload } from 'models/chat';
import { addUpdateChatMessage, resetUnreadCountByType } from 'redux/actions';
import {
  selectChatMessages,
  selectChatSocket,
  selectChatType,
} from 'redux/selectors/common/chat';

import { useChatUserName } from './useChatUserName';

const createChatMessage = (
  uuid: string,
  text: string,
  profileId: number,
  chatId: number,
  attachments: ChatAttachment[]
): ChatMessage => ({
  uuid,
  text,
  senderId: profileId,
  chatId,
  timestamp: new Date().toISOString().replace(/Z$/, ''),
  read: false,
  sent: false,
  attachments,
});

const useChatSendMessage = () => {
  const dispatch = useAppDispatch();
  const { id: userId } = useAppSelector(state => state.user);
  const chatSocket = useAppSelector(selectChatSocket);
  const chatMessages = useAppSelector(selectChatMessages);
  const chatType = useAppSelector(selectChatType);
  const userName = useChatUserName();
  const chatId = useChatId();

  const createSocketMessage = (
    uuid: string,
    text: string,
    attachments: ChatAttachment[]
  ): string | undefined => {
    if (!userId || !chatId || !userName.senderName) return undefined;
    return toMessage<SendPayload>('SEND', {
      uuid,
      text,
      senderId: userId,
      chatId,
      attachments,
      ...userName,
    });
  };

  const addMessage = (
    uuid: string,
    text: string,
    attachments: ChatAttachment[]
  ) => {
    if (!userId || !chatId || !chatMessages) return;
    const chatMessage = createChatMessage(
      uuid,
      text,
      userId,
      chatId,
      attachments
    );
    dispatch(addUpdateChatMessage(chatMessage));
  };

  return (text: string, attachments: ChatAttachment[]) => {
    const uuid = uuidv4();
    const socketMessage = createSocketMessage(uuid, text, attachments);
    if (socketMessage) {
      chatSocket?.send(socketMessage);
      if (chatSocket?.readyState !== chatSocket?.OPEN) {
        chatSocket?.reconnect();
      }
      dispatch(resetUnreadCountByType(chatType));
    }
    addMessage(uuid, text, attachments);
  };
};

export default useChatSendMessage;
