import { UserRole } from '../../../models/user';
import { useGetStaffPageableQuery } from '../../../redux/api/staff/staff-api';

export const useGetModeratorSelectProps = () => {
  const { data: moderators } = useGetStaffPageableQuery({
    pageSize: 1000,
    pageNumber: 1,
    roles: [
      UserRole.MODERATOR,
      UserRole.ADMIN,
      UserRole.INSTITUTE_STAFF,
      UserRole.SPECIALIST,
      UserRole.SUPPORT,
    ],
  });

  return (moderators?.content ?? []).map(moderator => ({
    label: `${moderator.surname} ${moderator.name} ${
      moderator.patronymic ?? ''
    }`,
    value: moderator.userId,
  }));
};
