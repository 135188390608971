import React, { FC } from 'react';

import classNames from 'classnames';

type LoaderProps = {
  className?: string;
};

const Loader: FC<LoaderProps> = ({ className }) => {
  return <div className={classNames('loader', className)} />;
};

export default Loader;
