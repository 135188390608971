import { useState } from 'react';

import { createToast } from '../../../../redux/actions';
import useAppDispatch from '../../../common/useAppDispatch';

export const useTimeoutToast = () => {
  const dispatch = useAppDispatch();
  const [isBlocked, setIsBlocked] = useState(false);

  const dangerToast = (message: string) => {
    if (!isBlocked) {
      dispatch(createToast(message, 'danger'));
      setIsBlocked(true);
      setTimeout(() => setIsBlocked(false), 3000);
    }
  };

  return {
    dangerToast,
  };
};
