import {
  KladrAddress,
  KladrDistrictsParams,
  KladrHousesParams,
  KladrLocalitiesParams,
  KladrRegionParams,
  KladrStreetsParams,
} from '../../../models/kladr';
import { apiSlice } from '../api-slice';

const baseUrl = `${process.env.REACT_APP_USER_MANAGER_API_URL}/kladr`;

export const kladrApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getRegions: builder.query<KladrAddress[], KladrRegionParams>({
      query: ({ name }) => ({
        url: `${baseUrl}/region`,
        params: {
          name,
        },
      }),
    }),

    getDistricts: builder.query<KladrAddress[], KladrDistrictsParams>({
      query: ({ name, regionCode }) => ({
        url: `${baseUrl}/district`,
        params: {
          name,
          regionCode,
        },
      }),
    }),

    getLocalities: builder.query<KladrAddress[], KladrLocalitiesParams>({
      query: ({ name, regionCode, districtCode }) => ({
        url: `${baseUrl}/locality`,
        params: {
          name,
          regionCode,
          districtCode,
        },
      }),
    }),

    getStreets: builder.query<KladrAddress[], KladrStreetsParams>({
      query: ({ name, regionCode, districtCode, localityCode, cityCode }) => ({
        url: `${baseUrl}/street`,
        params: {
          name,
          regionCode,
          districtCode,
          localityCode,
          cityCode,
        },
      }),
    }),

    getHouses: builder.query<KladrAddress[], KladrHousesParams>({
      query: ({
        name,
        regionCode,
        districtCode,
        localityCode,
        cityCode,
        streetCode,
      }) => ({
        url: `${baseUrl}/house`,
        params: {
          name,
          regionCode,
          districtCode,
          localityCode,
          cityCode,
          streetCode,
        },
      }),
    }),

    getKladrAddressById: builder.query<
      KladrAddress,
      {
        readonly code: string;
        readonly type: 'street' | 'region' | 'locality' | 'house' | 'district';
        readonly houseName?: string;
      }
    >({
      query: ({ type, code, houseName }) => ({
        url: `${baseUrl}/${type}`,
        params: {
          code,
          name: houseName,
        },
      }),
    }),
  }),
});
