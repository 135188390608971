import React, { FC } from 'react';

import terms, { getTranslationOrEmptyString } from '../../../i18n';
import { Questionnaire } from '../../../models/questionnaire/questionnaire';

type ModeratorContractIdInfoEntrantsProps = {
  readonly questionnaire: Questionnaire;
};

const ModeratorContractIdInfoEntrants: FC<
  ModeratorContractIdInfoEntrantsProps
> = ({ questionnaire: { generalDataForm, passportForm, addressForm } }) => (
  <div className="moderator-contract-info__inline">
    <div className="moderator-contract-info__block">
      <h2>{terms.PAYER_INFORMATION}:</h2>
      <div className="moderator-contract-info__block-row">
        <p>{terms.FIO}:</p>
        <span>
          {generalDataForm?.name
            ? `${generalDataForm?.surname} ${generalDataForm?.name} ${
                generalDataForm?.patronymic ?? ''
              }`
            : `${generalDataForm?.surnameLatin} ${generalDataForm?.nameLatin} ${
                generalDataForm?.patronymic ?? ''
              }`}
        </span>
      </div>
      <div className="moderator-contract-info__block-row">
        <p>{terms.DATE_OF_BIRTH}:</p>
        <span>{generalDataForm?.birthday}</span>
      </div>
      <div className="moderator-contract-info__block-row">
        <p>{terms.PHONE_NUMBER}:</p>
        <span>{generalDataForm?.mobile}</span>
      </div>
      {/* <div className="moderator-contract-info__block-row">*/}
      {/*  <p>{terms.REGISTRATION_ADDRESS}:</p>*/}
      {/*  <span>*/}
      {/*    {users.address[0].city}, ул.{users.address[0].street}{' '}*/}
      {/*    {users.address[0].house} */}
      {/*  </span>*/}
      {/* </div>*/}
      {/* <div className="moderator-contract-info__block-row">*/}
      {/*  <p>{terms.ACTUAL_ADDRESS}:</p>*/}
      {/*  <span>*/}
      {/*    {users.address[1].city}, ул.{users.address[1].street}{' '}*/}
      {/*    {users.address[1].house}*/}
      {/*  </span>*/}
      {/* </div>*/}
    </div>
    <div className="moderator-contract-info__block">
      <h2>{terms.PAYER_PASSPORT}:</h2>
      <div className="moderator-contract-info__block-row">
        <p>{terms.DOCUMENT_NAME}:</p>{' '}
        <span>
          {getTranslationOrEmptyString(
            passportForm?.identificationDocumentType
          )}
        </span>
      </div>
      <div className="moderator-contract-info__block-row">
        <p>{terms.SERIES}:</p>
        <span>{passportForm?.identificationDocumentSerial ?? '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row">
        <p>{terms.NUMBER}:</p>
        <span>{passportForm?.identificationDocumentNumber ?? '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row">
        <p>{terms.DATE_OF_ISSUE}:</p>
        <span>{passportForm?.identificationDocumentIssuedAt ?? '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row">
        <p>{terms.ISSUING_AUTHORITY}:</p>
        <span>{passportForm?.identificationDocumentIssuedBy ?? '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row">
        <p>{terms.DIVISION_CODE}:</p>
        <span>{passportForm?.identificationDocumentDivisionCode ?? '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row">
        <p>{terms.PLACE_OF_BIRTH}:</p>
        <span>{passportForm?.countryOfBirth?.title ?? '-'}</span>
      </div>
    </div>
  </div>
);

export default ModeratorContractIdInfoEntrants;
