import React, { FC, ReactNode } from 'react';

import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import Breadcrumbs from 'components/common/ui-kit/Breadcrumbs';
import CenterContainer from 'components/common/ui-kit/CenterContainer';
import Loader from 'components/common/ui-kit/Loader';

export type AdminEntryViewPageProps<T extends object> = {
  // FIXME types
  useGetEntryQuery: UseQuery<QueryDefinition<any, any, any, any>>;
  // FIXME entry type
  getBreadcrumbsLabel: (entry: any) => string;
  children: (entry: any) => ReactNode;
  idParamName?: string;
  className?: string;
};

const AdminEntryViewPage: FC<AdminEntryViewPageProps<any>> = ({
  useGetEntryQuery,
  getBreadcrumbsLabel,
  children,
  idParamName = 'id',
  className,
}) => {
  const id = useParams()[idParamName];
  if (!id) {
    throw new Error('No id param found');
  }
  const { data } = useGetEntryQuery(id);

  if (!data) {
    return (
      <CenterContainer>
        <Loader />
      </CenterContainer>
    );
  }

  return (
    <main className={classNames('upload-queue-entry-page', className)}>
      <Breadcrumbs
        className="upload-queue-entry-page__breadcrumbs"
        title={getBreadcrumbsLabel(data)}
      />
      {children(data)}
    </main>
  );
};

export default AdminEntryViewPage;
