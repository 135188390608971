import { useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';

import terms from 'i18n';
import { ChosenDirectionWithPriority } from 'models/applications/directions';
import { applicationsApi } from 'redux/api/applications/applications-api';
import { directionsApi } from 'redux/api/applications/directions-api';
import { buildContractNameFromDirection } from 'utils/domain/direction';

import { SelectOption } from '../../../models/common';
import useAppDispatch from '../../common/useAppDispatch';

type DirectionAgreementForm = {
  directionId: SelectOption;
};

const useDirectionAgreementForm = (applicationId?: number) => {
  const dispatch = useAppDispatch();
  const [submitAgreement] =
    applicationsApi.useSubmitDirectionAgreementMutation();

  const [getAgreements, { data: agreements }] =
    applicationsApi.useGetDirectionAgreementsMutation();

  const [getDirections, { data: directions }] =
    directionsApi.useLazyGetDirectionsByApplicationIdQuery();

  useEffect(() => {
    if (applicationId) {
      getDirections({
        applicationId,
      });
      getAgreements(applicationId);
    }
  }, [applicationId]);

  const agreement = useMemo(
    () => agreements?.find(elem => elem.isAgreed),
    [agreements]
  );

  const directionOptions = useMemo(() => {
    if (!directions) return [];
    const dirOptions = [
      ...(directions.BUDGET ?? []),
      ...(directions.CONTRACT ?? []),
    ].map((d: ChosenDirectionWithPriority) => ({
      label: buildContractNameFromDirection(d),
      value: d.id,
    }));
    if (!agreement) return dirOptions;
    const agreementOpt = dirOptions.find(
      elem => elem.value === agreement.applicationDirectionId
    );
    return agreementOpt ? [agreementOpt] : dirOptions;
  }, [directions, agreement]);

  const { register, handleSubmit, watch, setValue, getValues, ...rest } =
    useForm<DirectionAgreementForm>({ mode: 'all' });

  useEffect(() => {
    if (applicationId && directionOptions.length) {
      rest.reset({
        directionId: directionOptions[0],
      });
    }
  }, [directionOptions.length]);

  const fields = {
    directionId: register('directionId', {
      required: terms.REQUIRED_FIELD,
    }),
  };

  const onSubmit = (postHandler?: () => void) =>
    handleSubmit(data => {
      if (applicationId) {
        submitAgreement({
          applicationDirectionId: !agreement
            ? data.directionId.value
            : agreement.applicationDirectionId,
          agreement: !agreement,
        }).then(() => {
          getAgreements(applicationId);
          postHandler?.();
        });
      }
    });

  return {
    fields,
    onSubmit,
    setValue,
    getValues,
    watch,
    ...rest,
    directionOptions,
    agreement,
  };
};

export default useDirectionAgreementForm;
