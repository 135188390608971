import React, { FC } from 'react';

import TemplateModal from 'components/common/modals/TemplateModal';
import Button from 'components/common/ui-kit/Button';
import useAppSelector from 'hooks/common/useAppSelector';
import terms from 'i18n';
import { modalDataSelector } from 'redux/selectors';

const DirectionsModal: FC = () => {
  const { text, func } = useAppSelector(modalDataSelector);

  return (
    <TemplateModal title={terms.CONFIRM_CONSENT}>
      <div className="content">
        <p>{text}</p>
        <Button theme="success" onClick={func}>
          {terms.YES}
        </Button>
      </div>
    </TemplateModal>
  );
};

export default DirectionsModal;
