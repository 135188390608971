import React, { FC, useEffect } from 'react';

import TrajectoryElement from 'components/statements/TrajectoryList/TrajectoryElement';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';

import terms from '../../../i18n';
import { Trajectory } from '../../../models/applications/enum';
import { applicationsInfoApi } from '../../../redux/api/applications/applications-info-api';
import { selectUser } from '../../../redux/selectors';
import { selectCurrentTrajectory } from '../../../redux/selectors/enrollee/application';
import { applicationSlice } from '../../../redux/slices/enrollee/application-slice';

export const trajectories = {
  [Trajectory.FOREIGN]: {
    name: terms.FOREIGN,
    content: `${terms.APPLY_CONTRACT_ONLY};\n${terms.GETTING_DORMITORY_ONLY_IF_ANY_LEFT};\n${terms.ADMISSION_INFO_HERE}.`,
  },
  [Trajectory.QUOTA]: {
    name: terms.QUOTA,
    content: `${terms.HAVE_MINOBR_DIRECTION};\n${terms.OPEN_DOORS_WINNER}`,
  },
  [Trajectory.LOCAL]: {
    name: terms.LOCAL,
    content: `${terms.STATE_FUNDED_OR_PAID_PLACES};\n${terms.GETTING_DORMITORY_COMPETITIVE};\n${terms.HAS_COMPATRIOT_DOCS},\n${terms.OR_CITIZEN}:\n1. ${terms.BELARUS}\n2. ${terms.KAZAKHSTAN}\n3. ${terms.KYRGYZ}\n4. ${terms.TAJIKISTAN}`,
  },
};

const TrajectoryList: FC = () => {
  const dispatch = useAppDispatch();

  const trajectory = useAppSelector(selectCurrentTrajectory);

  const { id } = useAppSelector(selectUser);
  const [getAvailableTrajectories, { data: availableTrajectories }] =
    applicationsInfoApi.useLazyGetPossibleTrajectoriesForUserQuery();

  useEffect(() => {
    if (id) getAvailableTrajectories(id);
  }, [id]);

  const setTrajectory = value => {
    dispatch(applicationSlice.actions.setCurrentTrajectory(value));
  };

  return (
    <section className="trajectory-list">
      {(availableTrajectories ?? [])
        .map(trajectory => ({
          type: trajectory,
          ...trajectories[trajectory],
        }))
        .map((t, index) => (
          <TrajectoryElement
            key={index}
            name={t.name}
            content={t.content}
            active={t.type === trajectory}
            setTrajectory={() => setTrajectory(t.type)}
          />
        ))}
    </section>
  );
};

export default TrajectoryList;
