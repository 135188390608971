import { useEffect, useRef, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { NavRoute } from 'types/custom';

import { APPLICATION_SECTION_ROUTE } from 'config/routes/profile-page-routes';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import terms from 'i18n';
import { DirectionRequestType } from 'models/applications/applications';
import { FillingStatementsSectionKey } from 'models/statements';
import { applicationSlice } from 'redux/slices/enrollee/application-slice';
import {
  findNextRoute,
  getFilteredRoutes,
  getRouteStatus,
} from 'utils/route-helpers';

import {
  ApplicationEducationLevel,
  ApplicationState,
  Trajectory,
} from '../../models/applications/enum';
import { createToast } from '../../redux/actions';
import {
  useSubmitApplicationEditingMutation,
  useSubmitApplicationMutation,
  useSubmitDirectionsEditingMutation,
} from '../../redux/api/applications/applications-api';
import { entranceTestsApi } from '../../redux/api/applications/entrance-tests-api';
import {
  selectApplication,
  selectApplicationEntranceTests,
  selectCurrentEducationLevel,
  selectCurrentTrajectory,
  selectDirectionsRequestType,
} from '../../redux/selectors/enrollee/application';

type Route = NavRoute<FillingStatementsSectionKey>;

type ApplicationsRoutes = {
  achievements: boolean;
  privileges: boolean;
  priorityRights: boolean;
  directions: boolean;
  target: boolean;
  contract: boolean;
  blank: boolean;
  examResults: boolean;
  test: boolean;
  agreement: boolean;
};

const foreignBachelorRoutes: ApplicationsRoutes = {
  examResults: true,
  achievements: false,
  privileges: false,
  priorityRights: false,
  target: false,
  contract: false,
  test: false,
  directions: true,
  blank: true,
  agreement: false,
};

const foreignBaseRoutes: ApplicationsRoutes = {
  achievements: false,
  privileges: false,
  priorityRights: false,
  examResults: false,
  target: false,
  contract: false,
  test: false,
  directions: true,
  blank: true,
  agreement: false,
};

const quotaRoutes: ApplicationsRoutes = {
  achievements: false,
  privileges: false,
  priorityRights: false,
  target: false,
  contract: false,
  examResults: false,
  test: false,
  directions: true,
  blank: true,
  agreement: false,
};

const localMasterRoutes: ApplicationsRoutes = {
  achievements: true,
  privileges: true,
  priorityRights: false,
  target: true,
  contract: false,
  examResults: false,
  test: false,
  directions: true,
  blank: true,
  agreement: false,
};

const localPostGraduateRoutes: ApplicationsRoutes = {
  achievements: true,
  privileges: false,
  priorityRights: false,
  target: true,
  contract: false,
  examResults: false,
  test: false,
  directions: true,
  blank: true,
  agreement: false,
};

const localBachelorRoutes: ApplicationsRoutes = {
  achievements: true,
  privileges: true,
  priorityRights: false,
  target: true,
  contract: false,
  examResults: true,
  test: false,
  directions: true,
  blank: true,
  agreement: false,
};

const localSecondaryRoutes: ApplicationsRoutes = {
  achievements: true,
  privileges: true,
  priorityRights: true,
  target: true,
  contract: false,
  examResults: false,
  test: false,
  directions: true,
  blank: true,
  agreement: false,
};

const useFillingStatementsProvideRoutes = (initialRoutes: Route[] = []) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const currentRoute = useRef<string>();
  const visitedRoutesPaths = useRef<string[]>([]);
  const [routes, setRoutes] = useState<Route[]>(initialRoutes || []);
  const initialRoutesPathNames = useRef(initialRoutes?.map(r => r.to));

  const [lastBtn, setBtn] = useState<string>('');

  const { visitedRoutes, unvisitedRoutes, visibleRoutes, visitedSortedRoutes } =
    getFilteredRoutes(routes, currentRoute.current);

  const trainingLevel = useAppSelector(selectCurrentEducationLevel);
  const trajectory = useAppSelector(selectCurrentTrajectory);
  const currentApplication = useAppSelector(selectApplication);
  const directionsRequestType = useAppSelector(selectDirectionsRequestType);

  useEffect(() => {
    setRoutes(prev =>
      prev.map(route => {
        if (route.key === 'examResults') {
          return {
            ...route,
            title: initialRoutes[0].title,
            text: initialRoutes[0].text,
          };
        }
        return { ...route };
      })
    );
  }, [initialRoutes[0].title]);

  const { applicationId } = useParams();

  const { data: fetchedEntranceTests } =
    entranceTestsApi.useGetAllEntranceTestsByApplicationIdQuery(
      Number(applicationId),
      { skip: applicationId === undefined }
    );

  useEffect(() => {
    if (!fetchedEntranceTests) return;
    dispatch(applicationSlice.actions.setEntranceTests(fetchedEntranceTests));
  }, [fetchedEntranceTests]);

  const entranceTests = useAppSelector(selectApplicationEntranceTests);
  const isEntranceExamsTabVisible = entranceTests.length !== 0;

  useEffect(() => {
    if (trajectory && trainingLevel && currentApplication) {
      const approved =
        currentApplication.state === ApplicationState.ALLOWED ||
        currentApplication.state === ApplicationState.ACCEPTED;
      currentApplication.state === ApplicationState.ENROLLED;

      const isContract = currentApplication?.educationBasis === 'CONTRACT';

      if (trajectory === Trajectory.FOREIGN) {
        if (trainingLevel === ApplicationEducationLevel.PREPARATORY) {
          changeRouteVisibility({
            ...foreignBaseRoutes,
            contract: isContract,
            test: isEntranceExamsTabVisible,
          });
        } else {
          changeRouteVisibility({
            ...foreignBaseRoutes,
            contract: isContract,
            test: isEntranceExamsTabVisible,
          });
        }

        if (trainingLevel === ApplicationEducationLevel.BACHELOR) {
          changeRouteVisibility({
            ...foreignBachelorRoutes,
            contract: isContract,
            test: isEntranceExamsTabVisible,
          });
        }
      }

      if (trajectory === Trajectory.QUOTA) {
        if (trainingLevel === ApplicationEducationLevel.PREPARATORY) {
          changeRouteVisibility({
            ...quotaRoutes,
            test: false,
          });
        } else {
          changeRouteVisibility({
            ...quotaRoutes,
            test: isEntranceExamsTabVisible,
          });
        }
      }

      if (trajectory === Trajectory.LOCAL) {
        if (trainingLevel === ApplicationEducationLevel.SECONDARY) {
          changeRouteVisibility({
            ...localSecondaryRoutes,
            contract: isContract,
            test: isEntranceExamsTabVisible,
          });
        }

        if (trainingLevel === ApplicationEducationLevel.MASTER) {
          changeRouteVisibility({
            ...localMasterRoutes,
            contract: isContract,
            test: isEntranceExamsTabVisible,
          });
        }

        if (trainingLevel === ApplicationEducationLevel.GRADUATE) {
          changeRouteVisibility({
            ...localPostGraduateRoutes,
            contract: isContract,
            test: isEntranceExamsTabVisible,
            agreement: approved,
          });
        }

        if (trainingLevel === ApplicationEducationLevel.BACHELOR) {
          changeRouteVisibility({
            ...localBachelorRoutes,
            contract: isContract,
            test: isEntranceExamsTabVisible,
          });
        }

        if (trainingLevel === ApplicationEducationLevel.PREPARATORY) {
          changeRouteVisibility({
            ...foreignBaseRoutes,
            contract: isContract,
            test: false,
          });
        }
      }
    }
    changeRouteVisibility({ test: isEntranceExamsTabVisible });
    changeRouteVisibility({
      target:
        directionsRequestType !== DirectionRequestType.CONTRACT &&
        trajectory !== Trajectory.QUOTA,
    });
  }, [
    trajectory,
    trainingLevel,
    currentApplication,
    isEntranceExamsTabVisible,
    directionsRequestType,
    isEntranceExamsTabVisible,
  ]);

  const [submitApplication] = useSubmitApplicationMutation();
  const [submitApplicationEditing] = useSubmitApplicationEditingMutation();
  const [submitDirectionsEditing] = useSubmitDirectionsEditingMutation();
  const application = useAppSelector(selectApplication);

  const onNextPageNavigate = async () => {
    const route = findNextRoute(visibleRoutes, String(currentRoute.current));

    if (route) {
      return navigate(route.to);
    }

    if (!application || !location.pathname.includes('blank')) return;

    if (application.state === ApplicationState.SENT_FOR_EDITING) {
      submitApplicationEditing(Number(applicationId))
        .unwrap()
        .then(() => {
          navigate(APPLICATION_SECTION_ROUTE);
        })
        .catch(() => {
          dispatch(createToast('Ошибка при отправки заявления', 'danger'));
        });
      return;
    }

    if (application.state === ApplicationState.DIRECTIONS_EDITING) {
      submitDirectionsEditing(Number(applicationId))
        .unwrap()
        .then(() => {
          navigate(APPLICATION_SECTION_ROUTE);
        })
        .catch(() => {
          dispatch(createToast('Ошибка при отправки заявления', 'danger'));
        });
      return;
    }

    submitApplication(Number(applicationId))
      .unwrap()
      .then(() => {
        navigate(APPLICATION_SECTION_ROUTE);
      })
      .catch(({ data: { message } }) => {
        dispatch(
          createToast(
            `${terms.ERROR_SUBMITTING_APPLICATION}: ${message}`,
            'danger'
          )
        );
      });
  };

  const changeRouteVisibility = (params: {
    [key in Route['key']]?: boolean;
  }) => {
    const keys = Object.keys(params);
    setRoutes(prev =>
      prev.map(route => {
        if (keys.includes(route.key)) {
          return { ...route, hidden: !params[route.key] };
        }
        return { ...route };
      })
    );
  };

  const updateRoutesStatuses = (invalidKeys: FillingStatementsSectionKey[]) => {
    setRoutes(prev =>
      prev.map(route => ({
        ...route,
        status: getRouteStatus(
          invalidKeys,
          visitedRoutesPaths.current,
          route,
          currentRoute.current
        ),
      }))
    );
  };

  useEffect(() => {
    const { pathname } = location;
    const key = pathname.split('/filling-statements/')[1];

    if (key === 'blank') {
      setBtn(terms.SEND_APPLICATION);
    } else {
      setBtn('');
    }

    if (key && !visibleRoutes.find(route => key.includes(route.to))) {
      navigate(visibleRoutes[0].to);
    }

    if (!initialRoutesPathNames.current.includes(key)) return;

    if (!visitedRoutesPaths.current.includes(key)) {
      visitedRoutesPaths.current.push(key);
    }

    if (key !== currentRoute.current) {
      currentRoute.current = key;
    }
  }, [location.pathname, routes]);

  useEffect(() => {
    const { pathname } = location;
    setRoutes(prevState =>
      prevState.map(route =>
        route.to === 'achievements'
          ? {
              ...route,
              text:
                trainingLevel === ApplicationEducationLevel.SECONDARY
                  ? 'Заполните раздел, если Вы имеете результаты индивидуальных достижений, которые могут быть учтены в качестве преимущества при зачислении\n'
                  : 'Заполните раздел, если Вы имеете результаты олимпиад, которые могут быть использованы для поступления без вступительных испытаний, или индивидуальные достижения, учитываемые при приеме',
            }
          : route.to === 'privileges'
          ? {
              ...route,
              title:
                trainingLevel === ApplicationEducationLevel.SECONDARY
                  ? terms.PREEMPTIVE_RIGHTS
                  : terms.PRIORITY_ENROLLMENT,
              text:
                trainingLevel === ApplicationEducationLevel.SECONDARY
                  ? 'Заполните раздел, если Вы имеете преимущественное право на поступление (преимущественное право используется только при прочих равных условиях ранжирования)'
                  : 'Заполните раздел, если Вы имеете особые права или преимущественное право на поступление (преимущественное право используется только при прочих равных условиях ранжирования)',
            }
          : route
      )
    );
  }, [location.pathname, trajectory]);

  return {
    lastBtn,
    routes: visibleRoutes,
    visitedRoutes,
    unvisitedRoutes,
    onNextPageNavigate,
    visitedSortedRoutes,
  };
};

export default useFillingStatementsProvideRoutes;
