import { DateString } from './common';

export type UserId = number;

export enum UserRole {
  ADMIN = 1,
  COORDINATOR,
  MODERATOR,
  INSTITUTE_STAFF,
  SPECIALIST,
  SUPPORT,
  EXPERT,
  CALL_CENTER,
  USER,
}

export type UserData = {
  id: UserId;
  idWithPrefix: string;
  username: string;
  email: string;
  role: UserRole;
  isPasswordTemporary: boolean;
  archived: boolean;
  blocked: boolean;
  activated: boolean;
  photoUploadId?: number;
  createdAt: DateString;
  prefLang: string;
  profileImageBase64: string | null;
  canChooseBachelor: boolean | null;
  epguId: string | null;
  isRussian: boolean;
};

export type UserDataRespOk = Omit<UserData, 'role'> & { role: string };

export type UpdateUserPayload = {
  username: string;
  email: string;
  photoUploadId?: number;
  id?: number;
};

export type ChangeItselfPasswordRequest = {
  oldPassword: string;
  newPassword: string;
};
