import { useEffect, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { NavRoute } from 'types/custom';

import useAppSelector from 'hooks/common/useAppSelector';
import { ApplicationEducationLevel } from 'models/applications/enum';
import { ModeratorStatementsSectionKey } from 'models/moderator';
import { UserRole } from 'models/user';
import { selectUserId, selectUserRole } from 'redux/selectors';
import { selectCurrentApplication } from 'redux/selectors/staff/applications';
import { getFilteredRoutes } from 'utils/route-helpers';

type Route = NavRoute<ModeratorStatementsSectionKey>;

const useModeratorStatementsProvideRoutes = (initialRoutes: Route[] = []) => {
  const userId = useAppSelector(selectUserId);
  const role = useAppSelector(selectUserRole);
  const currentRoute = useRef<string>();
  const lastBtn = '';
  const onNextPageNavigate = async () => {};
  const [routes, setRoutes] = useState<Route[]>(initialRoutes);
  const location = useLocation();

  const application = useAppSelector(selectCurrentApplication);

  useEffect(() => {
    if (!application) return;
    if (application.educationLevel === ApplicationEducationLevel.SECONDARY) {
      setRoutes(routes =>
        routes.map(route =>
          route.key === 'hallmarks'
            ? { ...route, title: 'Преимущественное право' }
            : route
        )
      );
    }
    if (!application.epguInfo) {
      changeRouteVisibility({
        epguInfo: false,
      });
    }
    changeRouteVisibility({
      examResults:
        application.educationLevel !== ApplicationEducationLevel.SECONDARY,
    });
  }, [application]);

  useEffect(() => {
    if (location.pathname.split('/')[2] === 'personal-information') {
      changeRouteVisibility({
        hallmarks: false,
        examResults: false,
        directions: false,
        additionalForms: false,
        downloads: false,
        targetContract: false,
        duplicates: false,
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    changeRouteVisibility({
      '1c': role === UserRole.ADMIN,
      'expert-examination':
        role === UserRole.EXPERT && application?.expertId === userId,
    });
  }, [role, application, userId]);

  const changeRouteVisibility = (params: {
    [key in Route['key']]?: boolean;
  }) => {
    const keys = Object.keys(params);
    setRoutes(prev =>
      prev.map(route => {
        if (keys.includes(route.key)) {
          return { ...route, hidden: !params[route.key] };
        }
        return { ...route };
      })
    );
  };
  const { visitedRoutes, unvisitedRoutes, visibleRoutes, visitedSortedRoutes } =
    getFilteredRoutes(routes, currentRoute.current);

  return {
    lastBtn,
    routes: visibleRoutes,
    visitedRoutes,
    unvisitedRoutes,
    onNextPageNavigate,
    visitedSortedRoutes,
  };
};

export default useModeratorStatementsProvideRoutes;
