import { useSaveCompatriotDraftMutation } from 'redux/api/questionnaire/questionnaire-api';

import { CompatriotDraft } from '../../../../models/questionnaire/questionnaire';
import useDraft from './useDraft';

export const useCompatriotDraft = () => {
  return useDraft<CompatriotDraft>(
    questionnaire => questionnaire.compatriotForm,
    useSaveCompatriotDraftMutation
  );
};
