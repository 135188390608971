import { DateTimeString } from '../common';

export enum AuditEventTypeHead {
  APPLICATION = 'APPLICATION',
  USER = 'USER',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  ONE_C = 'ONE_C',
}

export enum AuditEventType {
  APPLICATION_STATUS_UPDATE = 'APPLICATION_STATUS_UPDATE',
  APPLICATION_AGREEMENT_UPDATE = 'APPLICATION_AGREEMENT_UPDATE',
  APPLICATION_CREATION = 'APPLICATION_CREATION',
  APPLICATION_CONTRACT_CREATE = 'APPLICATION_CONTRACT_CREATE', // Создание договора
  APPLICATION_CONTRACT_UPDATE = 'APPLICATION_CONTRACT_UPDATE',
  APPLICATION_DIRECTIONS_UPDATE = 'APPLICATION_DIRECTIONS_UPDATE',
  APPLICATION_MINOR_UPDATE = 'APPLICATION_MINOR_UPDATE',
  APPLICATION_DIRECTION_DELETE = 'APPLICATION_DIRECTION_DELETE', // Удаление направления
  APPLICATION_EDU_DOC_CHANGE = 'APPLICATION_EDU_DOC_CHANGE', // Изменение ДОО в заявление
  APPLICATION_ORIGINAL_CHANGE = 'APPLICATION_ORIGINAL_CHANGE', // Изменение оригинала
  APPLICATION_DESIRED_DIRECTION_CHANGE = 'APPLICATION_DESIRED_DIRECTION_CHANGE', // Изменение "планируемого" направления
  APPLICATION_ACHIEVEMENT_FORM_CHANGE = 'APPLICATION_ACHIEVEMENT_FORM_CHANGE', // Изменение данных о количестве публикаций
  APPLICATION_SENT_TO_QUEUE_CHANGE = 'APPLICATION_SENT_TO_QUEUE_CHANGE', // Ручная отправка заявления в очередь
  APPLICATION_PROFILE_SUBJECT_SCORE_SET = 'APPLICATION_PROFILE_SUBJECT_SCORE_SET', // Изменение бала профильных предметов
  APPLICATION_PRIVILEGE_CREATE = 'APPLICATION_PRIVILEGE_CREATE', // Создание особой отметки
  APPLICATION_PRIVILEGE_UPDATE = 'APPLICATION_PRIVILEGE_UPDATE', // Редактирование особой отметки
  APPLICATION_PRIVILEGE_DELETE = 'APPLICATION_PRIVILEGE_DELETE', // Удаление особой отметки
  APPLICATION_OLYMPIAD_CREATE = 'APPLICATION_OLYMPIAD_CREATE', // Создание олимпиады
  APPLICATION_OLYMPIAD_UPDATE = 'APPLICATION_OLYMPIAD_UPDATE', // Редактирование олимпиады
  APPLICATION_OLYMPIAD_DELETE = 'APPLICATION_OLYMPIAD_DELETE', // Удаление олимпиады
  APPLICATION_ACHIEVEMENT_CREATE = 'APPLICATION_ACHIEVEMENT_CREATE', // Создание индивидуального достижения
  APPLICATION_ACHIEVEMENT_UPDATE = 'APPLICATION_ACHIEVEMENT_UPDATE', // Редактирование индивидуального достижения
  APPLICATION_ACHIEVEMENT_DELETE = 'APPLICATION_ACHIEVEMENT_DELETE', // Удаление индивидуального достижения
  APPLICATION_GOVERNMENT_CONTRACT_CREATE = 'APPLICATION_GOVERNMENT_CONTRACT_CREATE', // Создание целевого договора
  APPLICATION_GOVERNMENT_CONTRACT_UPDATE = 'APPLICATION_GOVERNMENT_CONTRACT_UPDATE', // Редактирование целевого договора
  APPLICATION_GOVERNMENT_CONTRACT_DELETE = 'APPLICATION_GOVERNMENT_CONTRACT_DELETE', // Удаление целевого договора
  APPLICATION_PRIVILEGE_EXTRA_CREATE = 'APPLICATION_PRIVILEGE_EXTRA_CREATE', // Создание особенности приёма для сдачи ВИ
  APPLICATION_PRIVILEGE_EXTRA_UPDATE = 'APPLICATION_PRIVILEGE_EXTRA_UPDATE', // Редактирование особенности приёма для сдачи ВИ
  APPLICATION_PRIVILEGE_EXTRA_DELETE = 'APPLICATION_PRIVILEGE_EXTRA_DELETE', // Удаление особенности приёма для сдачи ВИ

  USER_REGISTERED = 'USER_REGISTERED',
  USER_EDITED = 'USER_EDITED',
  USER_ARCHIVED = 'USER_ARCHIVED',
  USER_UNARCHIVED = 'USER_UNARCHIVED',

  QUESTIONNAIRE_SAVED = 'QUESTIONNAIRE_SAVED',
  QUESTIONNAIRE_TAKEN = 'QUESTIONNAIRE_TAKEN',

  UPLOAD_USER_BLOCKS = 'UPLOAD_USER_BLOCKS', // Выгрузка информации в 1С
  PROCESS_BLOCKS_UPLOAD_CONFIRMATION = 'PROCESS_BLOCKS_UPLOAD_CONFIRMATION', // Подтверждение обработки выгрузки
}

export type EventUser = {
  id: number;
  firstName?: string;
  firstNameForeign?: string;
  secondName?: string;
  secondNameForeign?: string;
  lastName?: string;
  lastNameForeign?: string;
  role?: string;
};

export type EventWithUsers = {
  id: number;
  eventType: AuditEventType;
  eventTime: DateTimeString;
  applicant: EventUser;
  actor?: EventUser;
  applicationId?: number;
  message: string;
};

export type AuditFilters = {
  applicationId?: number;
  applicantId?: number;
  actorId?: number;
  actorRole?: string;
  startDate?: DateTimeString;
  endDate?: DateTimeString;
  eventType?: AuditEventType;
  eventTypeHead?: AuditEventTypeHead;
  message?: string;
};

export type DetailedEvent = {
  details?: string;
} & EventWithUsers;
