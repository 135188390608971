import { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { DirectionFilters } from 'models/applications/directions';

const useDirectionsTrainingSearch = (
  onData: (data: DirectionFilters) => void,
  isPaymentFormInputDisabled: boolean,
  paymentFormId?: number
) => {
  const navigate = useNavigate();

  const { register, handleSubmit, setValue, reset, formState, ...rest } =
    useForm<DirectionFilters>({
      mode: 'all',
    });

  const fields = {
    title: register('name', {
      onChange: e => setValue('name', e.target.value || undefined),
    }),
    educationFormId: register('educationFormId', {
      onChange: e => {
        setValue('educationFormId', e.target.value);
        onSubmit();
      },
    }),
    paymentFormId: register('paymentFormId', {
      onChange: e => {
        setValue('paymentFormId', e.target.value);
        onSubmit();
      },
    }),
  };

  useEffect(() => {
    setValue('paymentFormId', paymentFormId);
    onSubmit();
  }, [paymentFormId]);

  const resetForm = () => {
    reset();
    setValue('name', undefined);
    setValue('paymentFormId', paymentFormId);
    onSubmit();
  };

  const onSubmit = handleSubmit(onData);

  const onBackClick = () => navigate('..');

  return {
    fields,
    onSubmit,
    resetForm,
    setValue,
    formState,
    onBackClick,
    ...rest,
  };
};

export default useDirectionsTrainingSearch;
