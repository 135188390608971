import terms from 'i18n';

export const progressTabRoutes: { title: string; to: string; id: string }[] = [
  {
    to: 'trajectory',
    title: terms.TRAJECTORY_FOR_ADMISSION,
    id: '1',
  },
  {
    to: 'training-level',
    title: terms.LEVEL_OF_TRAINING,
    id: '2',
  },
  {
    to: 'directions-type',
    title: terms.DIRECTIONS_TYPE,
    id: '3',
  },
  {
    to: 'educational-documents',
    title: terms.EDUCATION_DOCUMENT,
    id: '4',
  },
  {
    to: 'filling-statements',
    title: terms.FILLING_APPLICATION,
    id: '5',
  },
];
