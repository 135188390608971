import React, { FC } from 'react';

import TemplateModal from 'components/common/modals/TemplateModal';
import Button from 'components/common/ui-kit/Button';
import useAppSelector from 'hooks/common/useAppSelector';
import terms from 'i18n';
import { modalDataSelector } from 'redux/selectors';

import useAppDispatch from '../../../../hooks/common/useAppDispatch';
import { hideModal } from '../../../../redux/actions';

const DirectionsEditingModal: FC = () => {
  const dispatch = useAppDispatch();
  const { onApprove } = useAppSelector(modalDataSelector);

  return (
    <TemplateModal title={terms.CONFIRM_CONSENT}>
      <div className="directions-editing-modal__content">
        <p>{terms.DIRECTIONS_EDITING_MODAL_TEXT}</p>
        <div>
          <Button theme="success" onClick={onApprove}>
            {terms.YES}
          </Button>
          <Button onClick={() => dispatch(hideModal())}>{terms.NO}</Button>
        </div>
      </div>
    </TemplateModal>
  );
};

export default DirectionsEditingModal;
