import { useEffect, useState } from 'react';

import useAppDispatch from 'hooks/common/useAppDispatch';
import terms from 'i18n';
import { FileId } from 'models/files';
import { createToast } from 'redux/actions';
import {
  useDownloadFileQuery,
  useUploadFileMutation,
} from 'redux/api/common/files-api';

import { fileExtensions } from '../../utils/mime-types';
import { useEnrolleeUserId } from '../questionnaire/common/draft/useQuestionnaire';

export enum FileUploadError {
  FILE_NOT_ATTACHED = 'FILE_NOT_ATTACHED',
  SERVER_ERROR = 'SERVER_ERROR',
}

// Currently uploads only first file from list
// Previous version of backend accepted either multiple jpgs or one pdf and always returned one pdf
/**
 * @deprecated
 * @param filename
 * @param initialFileId
 * @param userIdParam
 */
const useUploadFiles = (
  filename: string,
  initialFileId?: FileId | null,
  userIdParam?: number
) => {
  const [files, setFiles] = useState<File[]>([]);
  const userEnrolleeId = useEnrolleeUserId();
  const userId = userIdParam ?? userEnrolleeId;
  const dispatch = useAppDispatch();
  const [uploadFile] = useUploadFileMutation();
  const { data: downloadedFile } = useDownloadFileQuery(
    { userId: userId!, fileId: initialFileId! },
    {
      skip:
        initialFileId === null ||
        initialFileId === undefined ||
        userId === null ||
        userId === undefined,
    }
  );

  const uploadFiles = (
    successCallback?: (fileId: FileId) => void,
    errorCallback?: () => void
  ): Promise<FileId> => {
    if (files.length === 0 || !userId)
      return Promise.reject(FileUploadError.FILE_NOT_ATTACHED);
    // fixme once back is done
    return uploadFile({ file: files[0], userId, fileName: filename })
      .unwrap()
      .then(fileId => {
        // dispatch(createToast('Файл загружен', 'success'));
        if (successCallback) {
          successCallback(fileId);
        }
        return fileId;
      })
      .catch(() => {
        dispatch(createToast(terms.ERROR_HAS_OCCURRED, 'danger'));
        if (errorCallback) {
          errorCallback();
        }
        return Promise.reject(FileUploadError.SERVER_ERROR);
      });
  };

  const onChange = (files: File[]) => setFiles(files);

  const onDrop = (files: File[]) => setFiles(prev => [...prev, ...files]);

  const onDelete = (file: File) =>
    setFiles(prev => prev.filter(f => f.name !== file.name));

  useEffect(() => {
    if (downloadedFile) {
      setFiles([
        new File(
          [downloadedFile],
          `${filename}.${fileExtensions[downloadedFile.type]}`
        ),
      ]);
    }
  }, [downloadedFile]);

  return {
    files,
    onDrop,
    onChange,
    onDelete,
    uploadFiles,
  };
};

export default useUploadFiles;
