import React, { FC, useState } from 'react';

import Loader from 'components/common/ui-kit/Loader';
import { AdmissionBasis } from 'models/applications/directions';
import { ApplicationEducationLevel } from 'models/applications/enum';
import { commonColumnKeysToRenderers } from 'pages/Enrollment/common/column-renderers';
import { EnrollListsFilters } from 'pages/Enrollment/common/EnrollListsFilters';
import { EnrollmentTable } from 'pages/Enrollment/common/EnrollmentTable';
import { getColumnSet } from 'pages/Enrollment/common/getColumnSets';
import { admissionColumnKeysToSets } from 'pages/Enrollment/EnrollListsPage/current/columns-order';
import { columnLabels } from 'pages/Enrollment/EnrollmentRatingsPage/column-labels';
import {
  useGetDirectionByIdQuery,
  useGetDirectionTestsPrioritiesQuery,
} from 'redux/api/applications/directions-api';
import { useGetAdmissionsListFormQuery } from 'redux/api/common/eac-admission-list-api';
import {
  convertUtcDateToLocalDate,
  formatDateTimeToLocaleString,
} from 'utils/date';

import 'pages/Enrollment/common/enrollListsPage.scss';

type EnrollListsPageTypes = {
  readonly educationLevel: ApplicationEducationLevel;
};

const EnrollListsPage: FC<EnrollListsPageTypes> = ({ educationLevel }) => {
  const [currentEducationFormId, setCurrentEducationFormId] = useState<
    number | null
  >(null);
  const [currentAdmissionBasis, setCurrentAdmissionBasis] =
    useState<AdmissionBasis | null>(null);
  const [currentDirectionId, setCurrentDirectionId] = useState<number | null>(
    null
  );

  const { data: admissionsInfo, isLoading } = useGetAdmissionsListFormQuery(
    {
      applicationEducationLevel: educationLevel,
      directionEducationFormId: currentEducationFormId!,
      directionId: currentDirectionId!,
    },
    {
      skip: currentEducationFormId === null || currentDirectionId === null,
    }
  );

  const { data: direction } = useGetDirectionByIdQuery(currentDirectionId!, {
    skip: currentDirectionId === null,
  });

  const { data: priorities } = useGetDirectionTestsPrioritiesQuery(
    currentDirectionId!,
    {
      skip: currentDirectionId === null,
    }
  );

  return (
    <div className="enroll-lists">
      <EnrollListsFilters
        educationLevel={educationLevel}
        educationFormId={currentEducationFormId}
        onEducationFormIdChange={setCurrentEducationFormId}
        admissionBasis={currentAdmissionBasis}
        onAdmissionBasisChange={setCurrentAdmissionBasis}
        directionId={currentDirectionId}
        onDirectionIdChange={setCurrentDirectionId}
      />
      {isLoading ? (
        <Loader className="enroll-lists__loader" />
      ) : (
        <>
          {admissionsInfo && (
            <section className="enroll-lists__summary">
              <div className="enroll-lists__summary-row">
                <div className="enroll-lists__summary-item">
                  <div className="enroll-lists__summary-item-count">
                    {admissionsInfo.directionCapacity}
                  </div>
                  <div className="enroll-lists__summary-item-label">
                    количество мест
                  </div>
                </div>
                <div className="enroll-lists__summary-item">
                  <div className="enroll-lists__summary-item-count">
                    {admissionsInfo.total}
                  </div>
                  <div className="enroll-lists__summary-item-label">
                    поданных заявлений
                  </div>
                </div>
                <div className="enroll-lists__summary-item">
                  <div className="enroll-lists__summary-item-count">
                    {admissionsInfo.totalWithOriginals}
                  </div>
                  <div className="enroll-lists__summary-item-label">
                    оригиналов
                  </div>
                </div>
              </div>
              <div className="enroll-lists__summary-row">
                Время формирования:{' '}
                <span className="enroll-lists__summary-item-count">
                  {formatDateTimeToLocaleString(
                    convertUtcDateToLocalDate(
                      new Date(admissionsInfo.createdDate)
                    )
                  )}
                </span>
              </div>
            </section>
          )}

          {direction && educationLevel && currentAdmissionBasis && priorities && (
            <>
              <h2 className="enroll-lists__title">Список подавших документы</h2>
              <EnrollmentTable
                enrollments={admissionsInfo?.list ?? []}
                direction={direction}
                orderedColumnKeys={
                  getColumnSet(
                    admissionColumnKeysToSets,
                    educationLevel,
                    currentAdmissionBasis
                  ) ?? []
                }
                columnKeysToLabels={columnLabels}
                columnKeysToRenderers={commonColumnKeysToRenderers}
                priorities={priorities}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default EnrollListsPage;
