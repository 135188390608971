import AdminEntryViewPage from 'components/admin/AdminEntryViewPage';
import ObjectEntriesView from 'components/admin/ObjectEntriesView';
import { renderDefault } from 'components/admin/ObjectEntriesView/view';
import terms from 'i18n';
import { DetailedEvent } from 'models/staff/audit';
import { auditApi } from 'redux/api/staff/admin/audit-api';
import { formatDateTimeToRuFormat } from 'utils/date';

import { mapAuditUserToName } from '../LogPage/view';

type LogViewProps = {
  event: DetailedEvent;
};

function LogView({ event }: LogViewProps) {
  const object = {
    ...event,
    applicantName: mapAuditUserToName(event.applicant),
    actorName: mapAuditUserToName(event.actor),
    details: event.details,
  };
  return (
    <div className="registry-entry-view">
      <ObjectEntriesView
        object={object}
        keysToTitles={{
          id: 'ID',
          eventType: 'Тип события',
          eventTime: 'Дата',
          applicationId: 'ID заявления',
          message: 'Сообщение',
          actorName: 'ФИО инициатора',
          applicantName: 'ФИО абитуриента',
          details: 'Детали',
        }}
        keysToRenderers={{
          eventTime: datetime => formatDateTimeToRuFormat(datetime),
          eventType: type => terms[type],
          details: details =>
            details ? <pre>{details}</pre> : renderDefault(details),
        }}
      />
    </div>
  );
}

export default function LogEntryPage() {
  return (
    <AdminEntryViewPage
      useGetEntryQuery={auditApi.useGetDetailedAuditByIdQuery}
      getBreadcrumbsLabel={() => 'Действие'}
    >
      {event => <LogView event={event} />}
    </AdminEntryViewPage>
  );
}
