import React, { FC, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import Button from 'components/common/ui-kit/Button';
import Loader from 'components/common/ui-kit/Loader';
import useInfiniteQuery from 'hooks/common/useInfiniteQuery';
import { defaultSize } from 'hooks/common/usePagination';
import terms from 'i18n';
import { DirectionFilters } from 'models/applications/directions';
import { useLazyGetDirectionsQuery } from 'redux/api/applications/directions-api';
import { getNounDeclension } from 'utils/text';

import useAppSelector from '../../../hooks/common/useAppSelector';
import useDirectionTrainingList from '../../../hooks/statements/directions/useDirectionTrainingList';
import {
  ApplicationEducationLevel,
  Trajectory,
} from '../../../models/applications/enum';
import { selectCurrentEducationLevel } from '../../../redux/selectors/enrollee/application';
import DirectionsTrainingElement from './DirectionsTrainingElement';

type DirectionsTrainingListProps = {
  readonly filters: DirectionFilters;
  readonly trajectory: Trajectory;
  readonly maximumDirections: number;
  readonly otherAppsUsedDirectionCodes: string[];
  readonly checkUnique: boolean;
  readonly currentOnly: boolean;
};

export const getDirectionUniqueCode = (code: string) => {
  return code.replace(/_\d*$/, '');
};

const DirectionsTrainingList: FC<DirectionsTrainingListProps> = ({
  filters,
  maximumDirections,
  otherAppsUsedDirectionCodes,
  currentOnly,
  checkUnique,
}) => {
  const {
    state: {
      directions: savedSelectedDirections,
      allDirections: allSelectedDirections,
    },
  } = useLocation();
  const { selectedDirections, onSubmit, selectDirection } =
    useDirectionTrainingList(savedSelectedDirections);

  const trainingLevel = useAppSelector(selectCurrentEducationLevel);

  const selectedDirectionsUniqueCodes = useMemo(() => {
    const codes = selectedDirections.map(dir =>
      getDirectionUniqueCode(dir.code)
    );

    const checkedCodes = currentOnly
      ? [...codes]
      : [...codes, ...otherAppsUsedDirectionCodes];

    return checkUnique ? Array.from(new Set(checkedCodes)) : checkedCodes;
  }, [
    selectedDirections,
    allSelectedDirections,
    otherAppsUsedDirectionCodes,
    checkUnique,
  ]);

  const isMaxSelectedReached =
    selectedDirectionsUniqueCodes.length >= maximumDirections;

  const {
    data: directions,
    isLoading,
    loadNext,
    hasNext,
  } = useInfiniteQuery(useLazyGetDirectionsQuery, filters);

  const useDirectionInsteadOfProgram = useMemo(
    () =>
      trainingLevel &&
      ![
        ApplicationEducationLevel.PREPARATORY,
        ApplicationEducationLevel.GRADUATE,
      ].includes(trainingLevel),
    [trainingLevel]
  );

  const getErrorMessage = () => {
    return (
      terms.MAX_PROGRAMS_COUNT +
      maximumDirections +
      ' ' +
      getNounDeclension(maximumDirections, [
        useDirectionInsteadOfProgram
          ? terms.DIRECTION_GEN_ONE
          : terms.PROGRAM_GEN_ONE,
        useDirectionInsteadOfProgram
          ? terms.DIRECTION_GEN_MANY
          : terms.PROGRAM_GEN_MANY,
        useDirectionInsteadOfProgram
          ? terms.DIRECTION_GEN_MANY
          : terms.PROGRAM_GEN_MANY,
      ])
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  if (directions?.length && directions.length === 0) {
    return <p>{terms.DATA_NOT_RECEIVED}</p>;
  }

  return (
    <section
      className="directions-training-list"
      onKeyDown={e => {
        if (e.key !== 'Enter') return;
        e.stopPropagation();
        e.preventDefault();
        onSubmit();
      }}
    >
      {directions?.map((el, index) => {
        // Если направление уже выбрано, нужно всегда иметь возможность отменить этот выбор
        const isDirectionIncludedInChoseArr = selectedDirections.some(
          choseDir => choseDir.id === el.id
        );

        // Нужно иметь возможность добавить 38.03.01_29, если уже есть 38.03.01
        const canBeAddedOverLimit = checkUnique
          ? selectedDirectionsUniqueCodes.includes(
              getDirectionUniqueCode(el.code)
            )
          : false;

        return (
          <DirectionsTrainingElement
            readOnly={
              isMaxSelectedReached &&
              !isDirectionIncludedInChoseArr &&
              !canBeAddedOverLimit
            }
            isSelected={isDirectionIncludedInChoseArr}
            onSelect={selectDirection}
            key={index}
            direction={el}
            errorMessage={getErrorMessage()}
          />
        );
      })}
      {hasNext && (
        <Button type="button" onClick={loadNext} className="add-more__button">
          {terms.ADD_MORE} {defaultSize}
        </Button>
      )}
      <Button
        type="button"
        style={{ marginLeft: 'auto' }}
        className="self-end"
        onClick={onSubmit}
        theme="success"
      >
        {terms.CONFIRM}
      </Button>
    </section>
  );
};

export default DirectionsTrainingList;
