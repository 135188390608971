import React, { FC } from 'react';

import RouteButtonLink from 'components/common/ui-kit/RouteButtonLink';
import ContractList from 'components/statements/ContractList';
import useAppSelector from 'hooks/common/useAppSelector';
import terms from 'i18n';
import { selectIsApplicationContractsListReadonly } from 'redux/selectors/enrollee/application';
import { useIsImpersonate } from 'utils/domain/impersonate';

const ContractListPage: FC = () => {
  const isImpersonate = useIsImpersonate();
  const isContractListReadOnly =
    useAppSelector(selectIsApplicationContractsListReadonly) && !isImpersonate;

  return (
    <div>
      <header className="contract-list-page__header">
        <RouteButtonLink
          to="create"
          type="button"
          theme="success"
          disabled={isContractListReadOnly}
        >
          {terms.CREATE_CONTRACT}
        </RouteButtonLink>
      </header>
      <ContractList />
    </div>
  );
};

export default ContractListPage;
