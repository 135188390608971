import { NavRoute } from 'types/custom';
import { FillingStatementsSectionKey } from 'models/statements';
import terms from 'i18n';

export const fillingStatementsRoutes: NavRoute<FillingStatementsSectionKey>[] =
  [
    {
      to: 'exam-results',
      title: terms.UNIFIED_EXAMS,
      text: terms.UNIFIED_EXAMS_TEXT,
      key: 'examResults',
      status: 'not visited',
    },
    {
      to: 'achievements',
      title: terms.ACHIEVEMENTS,
      text: 'Заполните раздел, если Вы имеете результаты олимпиад, которые могут быть использованы для поступления без вступительных испытаний, или индивидуальные достижения, учитываемые при приеме',
      key: 'achievements',
      status: 'not visited',
    },
    {
      to: 'privileges',
      title: terms.PRIORITY_ENROLLMENT,
      text: 'Заполните раздел, если Вы имеете особые права или преимущественное право на поступление (преимущественное право используется только при прочих равных условиях ранжирования)',
      key: 'privileges',
      status: 'not visited',
    },
    {
      to: 'priority-rights',
      title: terms.PRIORITY_RIGHTS,
      text: 'Заполните раздел, если Вы имеете право на зачисление в первоочередном порядке',
      key: 'priorityRights',
      status: 'not visited',
    },
    {
      to: 'target',
      title: 'Целевой договор',
      text: 'Заполните раздел, если у Вас есть договор о целевом обучении',
      key: 'target',
      status: 'not visited',
    },
    {
      to: 'directions',
      title: terms.DIRECTIONS_TRAINING,
      text: '',
      key: 'directions',
      status: 'not visited',
    },
    {
      to: 'test',
      title: terms.ENTRANCE_EXAMS,
      text: terms.ENTRANCE_EXAMS_TEXT,
      key: 'test',
      status: 'not visited',
    },
    {
      to: 'contract',
      title: terms.CONTRACT,
      text: terms.CONTRACT_TEXT,
      key: 'contract',
      status: 'not visited',
    },
    {
      to: 'agreement',
      title: terms.AGREEMENT,
      text: '',
      key: 'agreement',
      status: 'not visited',
    },
    {
      to: 'blank',
      title: terms.FORMS_OF_DOCUMENTS,
      text: terms.FORMS_OF_DOCUMENTS_TEXT,
      key: 'blank',
      status: 'not visited',
    },
  ];
