import { Locale } from 'types/custom';

import en from 'i18n/terms.en';
import ru from 'i18n/terms.ru';

const terms = {
  ru,
  en,
};

const getLocale = () => {
  const locale = localStorage.getItem('locale') || 'ru';
  return locale as Locale;
};

export const locale = getLocale();

export const isRussianLocale = (): boolean => {
  return locale === 'ru';
};

export const getTranslationOrEmptyString = (key: string | undefined) =>
  key ? terms[locale][key] : '';

export const changeLocale = (newLocale: Locale) => {
  localStorage.setItem('locale', newLocale);
  window.location.reload();
};

export default terms[locale] || terms.ru;
