import React, { FC } from 'react';

import AddressesForm from 'components/profile/AddressesForm';

const ResidencePage: FC = () => (
  <div>
    <AddressesForm />
  </div>
);

export default ResidencePage;
