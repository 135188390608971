import { useEffect, useState } from 'react';

import { COUNTRY_RUSSIA_ID } from '../../../../models/enum';
import { GeneralDataDraft } from '../../../../models/questionnaire/questionnaire';
import { useGeneralDataDraft } from './useGeneralDataDraft';

export const useIsRussianEnrollee = () => {
  const { draft: generalDataDraft } = useGeneralDataDraft();
  const [isRussianEnrollee, setIsRussianEnrollee] = useState(false);

  useEffect(() => {
    if (generalDataDraft?.primaryCitizenshipCountry?.id === COUNTRY_RUSSIA_ID) {
      setIsRussianEnrollee(true);
    }
  }, [generalDataDraft]);

  return {
    isRussianEnrollee,
    generalDataDraft,
  };
};

export const useIsEnrolleeRussian = () => {
  const [flag, setFlag] = useState(false);

  const setIsEnrolleeRussian = (generalDataDraft: GeneralDataDraft) => {
    setFlag(
      generalDataDraft?.primaryCitizenshipCountry?.id === COUNTRY_RUSSIA_ID
    );
  };

  return {
    isEnrolleeRussian: flag,
    setIsEnrolleeRussian,
  };
};
