import React, { FC, PropsWithChildren } from 'react';

import classNames from 'classnames';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import SidenavV2 from 'components/common/ui-kit/SidenavV2';
import { SidenavRoute } from 'components/common/ui-kit/SidenavV2/view';
import { TABLET_SCREEN_WIDTH } from 'config/constants';
import useWindowsSize from 'hooks/common/useWindowsSize';

// TODO Fix routes not being rendered for mobile
const SidenavPageV2: FC<
  PropsWithChildren<{
    routes: SidenavRoute[];
  }>
> = ({ routes }) => {
  const { isMobile, windowSize } = useWindowsSize();
  const isTabletScreen = windowSize.innerWidth <= TABLET_SCREEN_WIDTH;

  const { pathname } = useLocation();

  return (
    <div className="sidenav-page-wrapper">
      <div className="sidenav-page">
        {isMobile ? (
          <div className="sidenav-page__content">
            <div className="sidenav-page__nav-container">
              <SidenavV2
                routes={routes}
                className="sidenav-page__nav"
                isMobile={isMobile}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="sidenav-page__content">
              <div className="sidenav-page__nav-container">
                <SidenavV2 routes={routes} className="sidenav-page__nav" />
              </div>
              <div
                className={classNames('sidenav-page__inner', {
                  'sidenav-page__inner--first-selected':
                    pathname.includes('basic-data'),
                })}
              >
                <Routes>
                  {routes.length > 0 && (
                    <Route index element={<Navigate to={routes[0].to} />} />
                  )}
                  {routes.map(({ to, element }) => (
                    <Route path={to} element={element} />
                  ))}
                </Routes>
              </div>
              {isTabletScreen && (
                <div className="sidenav-page__nav-container">
                  <SidenavV2 routes={routes} className="sidenav-page__nav" />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SidenavPageV2;
