import { staffApplicationsApi } from '../../../redux/api/staff/staff-applications-api';

export const useGetEpguRejectReasonsSelectProps = () => {
  const { data: rejectReasons } =
    staffApplicationsApi.useGetEpguRejectReasonsQuery();

  return {
    options: (rejectReasons ?? []).map(rejectReason => ({
      label: rejectReason.name,
      value: rejectReason.externalId,
    })),
  };
};
