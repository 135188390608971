import React, { FC } from 'react';

import classnames from 'classnames';

import Button from 'components/common/ui-kit/Button';
import useCreatePageHeader from 'hooks/statements/useCreatePageHeader';
import terms from 'i18n';

type CreatePageHeaderProps = {
  list: any;
};

const CreatePageHeader: FC<CreatePageHeaderProps> = ({ list }) => {
  const { onSubmit } = useCreatePageHeader();

  return (
    <header
      className={classnames(
        'create-page__header',
        list && list.length > 0
          ? 'create-page__header--row'
          : 'create-page__header--column'
      )}
    >
      <h1 className="create-page__title">{terms.APPLICATION}</h1>
      <div onClick={onSubmit}>
        <Button type="button" theme="success">
          {terms.CREATE_APPLICATION}
        </Button>
      </div>
    </header>
  );
};

export default CreatePageHeader;
