import React, { FC, memo, useMemo } from 'react';

import { RestFilled } from '@ant-design/icons';
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { Tooltip } from 'react-tooltip';

import Button from 'components/common/ui-kit/Button';
import Checkbox from 'components/common/ui-kit/Checkbox';
import { InputV2 } from 'components/common/ui-kit/Input/view';
import ReactSelect from 'components/common/ui-kit/Select';
import ModeratorDataList from 'components/moderator/ModeratorDataList';
import useAppSelector from 'hooks/common/useAppSelector';
import { useKladrRegions } from 'hooks/questionnaire/common/kladr/useKladrRegions';
import { useGetQuestionnaireUser } from 'hooks/questionnaire/common/useGetQuestionnaireUser';
import { useTimeoutToast } from 'hooks/questionnaire/common/util/useTimeoutToast';
import { useGetEducationInformationFormEnums } from 'hooks/questionnaire/educationInformationDraft/useGetEducationInformationFormEnums';
import terms from 'i18n';
import { SelectOption } from 'models/common';
import { COUNTRY_RUSSIA_ID } from 'models/enum';
import { UserRole } from 'models/user';
import {
  questionnaireApi,
  useDeleteEducationFormMutation,
} from 'redux/api/questionnaire/questionnaire-api';
import { selectUserRole } from 'redux/selectors';
import { selectIsDraftReadonly } from 'redux/selectors/enrollee/questionnaire';
import {
  createCheckboxProps,
  createInputV2Props,
  createSelectProps,
} from 'utils/form-helpers';
import {
  dateLessThanCurrentOne,
  normalizeYearTo4Characters,
} from 'utils/form-validators';

import regexp from '../../../utils/regexp';
import ProfileSection from '../ProfileSection';
import MultiLazyFileUploader from './MultiLazyFileUploader';
import { EducationInformation, EducationInformationForm } from './view';

export const DOC_TYPES_ON_GRADES_REQUIRED = [
  'PRIMARY_VOCATIONAL_EDUCATION_DIPLOMA',
  'SECONDARY_VOCATIONAL_EDUCATION_DIPLOMA',
  'BASIC_GENERAL_EDUCATION_CERTIFICATE',
  'SECONDARY_GENERAL_EDUCATION_CERTIFICATE',
];

export const calculateMeanGrade = (
  satisfactoryGrades: number | undefined,
  goodGrades: number | undefined,
  excellentGrades: number | undefined
): string => {
  if (
    Number(satisfactoryGrades) ||
    Number(goodGrades) ||
    Number(excellentGrades)
  ) {
    const meanGrade =
      (4 * Number(goodGrades) +
        5 * Number(excellentGrades) +
        3 * Number(satisfactoryGrades)) /
      (Number(goodGrades) +
        Number(excellentGrades) +
        Number(satisfactoryGrades));
    return meanGrade ? meanGrade.toFixed(3) : '0.000';
  } else return '0.000';
};

const MultiLazyFileUploaderMemo = memo(MultiLazyFileUploader);

const ACADEMIC_CERTIFICATE = 'ACADEMIC_CERTIFICATE';

const AVAILABLE_LEVELS_BY_TYPE = {
  POSTGRADUATE_DIPLOMA: ['POSTGRADUATE'],
  BACHELOR_DEGREE: ['HIGHER_EDUCATION'],
  MASTER_DEGREE: ['HIGHER_EDUCATION'],
  SPECIALIST_DIPLOMA: ['HIGHER_EDUCATION'],
  PRIMARY_VOCATIONAL_EDUCATION_DIPLOMA: ['PRIMARY_VOCATIONAL'],
  SECONDARY_VOCATIONAL_EDUCATION_DIPLOMA: ['SECONDARY_VOCATIONAL'],
  BASIC_GENERAL_EDUCATION_CERTIFICATE: ['PRIMARY_BASIC'],
  SECONDARY_GENERAL_EDUCATION_CERTIFICATE: ['SECONDARY_BASIC'],
  ACADEMIC_CERTIFICATE: [
    'POSTGRADUATE',
    'HIGHER_EDUCATION',
    'PRIMARY_VOCATIONAL',
    'SECONDARY_VOCATIONAL',
    'PRIMARY_BASIC',
    'SECONDARY_BASIC',
  ],
  DOCUMENT_MISSING: ['OTHER'],
};

export const EducationFields: FC<{
  education: EducationInformation;
  formIndex: number;
  isEnrolleeRussian: boolean;
  addUploadFilesFunction: (func) => void;
  removeUploadFilesFunction: (func) => void;
  onRemove: (index: number) => void;
}> = ({
  education,
  formIndex,
  isEnrolleeRussian,
  addUploadFilesFunction,
  removeUploadFilesFunction,
  onRemove,
}) => {
  const user = useGetQuestionnaireUser();
  const readOnly = useAppSelector(selectIsDraftReadonly);
  const role = useAppSelector(selectUserRole);

  const {
    control,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<EducationInformationForm>();

  const responsibleFields = watch('education');

  const regionProps = useKladrRegions();

  const {
    countrySelectProps,
    educationLevelSelectProps,
    educationDocumentTypeSelectProps,
  } = useGetEducationInformationFormEnums(isEnrolleeRussian);

  const inputV2Props = createInputV2Props(errors.education ?? [], readOnly);
  const checkboxProps = createCheckboxProps(errors.education ?? [], readOnly);
  const selectProps = createSelectProps(errors.education ?? [], readOnly);

  const { dangerToast } = useTimeoutToast();

  const [deleteEducationForm, { isLoading: isDeletingEducation }] =
    useDeleteEducationFormMutation();

  const handleDeleteEducation = async () => {
    try {
      await deleteEducationForm(education.id).unwrap();
      onRemove(formIndex);
    } catch (e) {
      dangerToast(
        `${
          terms.ERROR_CAN_NOT_DELETE_DOCUMENT
        } [${questionnaireIds?.toString()}]`
      );
    }
  };

  const selectedDocumentType = watch(`education.${formIndex}.documentType`);

  const educationLevelOptions = useMemo(() => {
    if (!selectedDocumentType) return [];
    return educationLevelSelectProps.options.filter(option =>
      AVAILABLE_LEVELS_BY_TYPE[selectedDocumentType].some(
        value => value === option.value
      )
    );
  }, [selectedDocumentType, educationLevelSelectProps]);

  const { data: questionnaireIds } =
    questionnaireApi.useGetApplicationsByDocumentIdQuery(
      education.educationalInformationDraftId
    );

  const isDocumentUsedInApplication = useMemo(() => {
    return !!questionnaireIds?.length;
  }, [questionnaireIds]);

  const isEdicationRegionRequired = user
    ? watch(`education.${formIndex}.educationCountryId`) ===
        COUNTRY_RUSSIA_ID && !user.registerFromEpgu
    : true;

  return (
    <div className={classNames('profile-form__element')} key={education.id}>
      {responsibleFields.length > 1 && (
        <div>
          <Button
            onClick={handleDeleteEducation}
            disabled={readOnly || isDeletingEducation}
            size="micro"
            type="button"
            theme="danger"
            className="profile-form__button--position-absolute"
          >
            <RestFilled size={12} rev={undefined} />
            <span>{terms.DELETE}</span>
          </Button>
        </div>
      )}
      <ProfileSection title={terms.EDUCATION_DOCUMENT} titleSize="large">
        {role !== UserRole.MODERATOR &&
          (education.epguExternalId ||
            education.epguWithHonorsDocumentGuid) && (
            <>
              <div style={{ margin: '20px 0' }} />
              <ModeratorDataList
                list={[
                  {
                    title: 'ЕПГУ GUID документа:',
                    label: education.epguExternalId ?? '-',
                  },
                  {
                    title: 'ЕПГУ GUID документа с отличием:',
                    label: education.epguWithHonorsDocumentGuid ?? '-',
                  },
                ]}
              />
              <div style={{ margin: '20px 0' }} />
            </>
          )}

        <div className="profile-form__fields">
          <Controller
            name={`education.${formIndex}.documentType`}
            rules={{
              required: terms.REQUIRED_FIELD,
              onChange: e => {
                const value = e.target.value;
                if (
                  !(
                    value &&
                    DOC_TYPES_ON_GRADES_REQUIRED.some(type => type === value)
                  )
                ) {
                  setValue(
                    `education.${formIndex}.satisfactoryGrades`,
                    undefined
                  );
                  setValue(`education.${formIndex}.goodGrades`, undefined);
                  setValue(`education.${formIndex}.excellentGrades`, undefined);
                }
                const availableLevels = AVAILABLE_LEVELS_BY_TYPE[value];
                if (!availableLevels) return;
                if (availableLevels.length === 1) {
                  setValue(
                    `education.${formIndex}.educationLevel`,
                    availableLevels[0],
                    { shouldValidate: true }
                  );
                } else {
                  setValue(`education.${formIndex}.educationLevel`, undefined, {
                    shouldValidate: true,
                  });
                }
              },
            }}
            control={control}
            render={({ field }) => (
              <>
                {isDocumentUsedInApplication && (
                  <Tooltip
                    anchorSelect={`.profile-form__field.doc-${education.id}`}
                    clickable
                    style={{
                      maxWidth: 400,
                      padding: '10px 15px',
                      zIndex: '2',
                      marginTop: '-1.5rem',
                    }}
                    place="bottom"
                    variant="dark"
                  >
                    {terms.ERROR_CAN_NOT_EDIT_DOCUMENT_TYPE} [
                    {questionnaireIds?.toString()}]
                  </Tooltip>
                )}
                <ReactSelect<SelectOption>
                  placeholder={terms.DOCUMENT_TYPE}
                  className={`profile-form__field doc-${education.id}`}
                  markRequired
                  {...selectProps(field, {
                    ...educationDocumentTypeSelectProps,
                    options: educationDocumentTypeSelectProps.options,
                  })}
                  isDisabled={isDocumentUsedInApplication || readOnly}
                />
              </>
            )}
          />

          <div className="profile-form__field">
            <Controller
              name={`education.${formIndex}.educationalInstitution`}
              rules={{
                required: terms.REQUIRED_FIELD,
                pattern: {
                  value: regexp.lettersAndSymbolsExtended,
                  message: terms.CYRILLIC_OR_LATIN_CHARACTERS_ONLY,
                },
                onChange: ({ target: { value } }) => {
                  if (value !== terms.POLYTECH_FULLNAME) {
                    setValue(
                      `education.${formIndex}.graduatedFromSpbpu`,
                      false
                    );
                  } else if (value === terms.POLYTECH_FULLNAME) {
                    setValue(`education.${formIndex}.graduatedFromSpbpu`, true);
                  }
                },
              }}
              control={control}
              render={({ field }) => (
                <InputV2
                  placeholder={terms.EDUCATIONAL_INSTITUTION}
                  markRequired
                  {...inputV2Props(field)}
                />
              )}
            />

            <Checkbox
              text={terms.GRADUATED_FROM_SPBPU}
              className="profile-form__checkbox"
              {...checkboxProps(
                register(`education.${formIndex}.graduatedFromSpbpu`, {
                  onChange: event => {
                    const { checked } = event.target;
                    if (checked) {
                      setValue(
                        `education.${formIndex}.educationalInstitution`,
                        terms.POLYTECH_FULLNAME,
                        { shouldValidate: true }
                      );
                      setValue(
                        `education.${formIndex}.educationCountryId`,
                        COUNTRY_RUSSIA_ID,
                        {
                          shouldValidate: true,
                        }
                      );
                      if (
                        watch(`education.${formIndex}.educationCountryId`) ===
                        COUNTRY_RUSSIA_ID
                      ) {
                        setValue(
                          `education.${formIndex}.russianKladrEducationRegion`,
                          {
                            label: 'г. Санкт-Петербург',
                            value: '7800000000000',
                          },
                          { shouldValidate: true }
                        );
                      }
                    } else {
                      setValue(
                        `education.${formIndex}.educationalInstitution`,
                        '',
                        {
                          shouldValidate: true,
                        }
                      );
                      setValue(
                        `education.${formIndex}.educationCountryId`,
                        undefined,
                        {
                          shouldValidate: true,
                        }
                      );
                      setValue(
                        `education.${formIndex}.russianKladrEducationRegion`,
                        undefined,
                        {
                          shouldValidate: true,
                        }
                      );
                    }
                  },
                })
              )}
            />
          </div>

          <Controller
            name={`education.${formIndex}.educationLevel`}
            control={control}
            rules={{
              required: terms.REQUIRED_FIELD,
            }}
            render={({ field }) => {
              // if (!educationLevelSelectProps[formIndex]) {
              //   return (
              //     <ReactSelect<SelectOption>
              //       className="profile-form__field"
              //       placeholder={terms.STUDY_LEVEL}
              //       markRequired
              //       isDisabled={true}
              //     />
              //   );
              // }

              return (
                <ReactSelect<SelectOption>
                  className="profile-form__field"
                  placeholder={terms.STUDY_LEVEL}
                  markRequired
                  {...selectProps(field, {
                    options: educationLevelOptions,
                  })}
                />
              );
            }}
          />

          <Controller
            name={`education.${formIndex}.educationCountryId`}
            rules={{
              required: terms.REQUIRED_FIELD,
              onChange: () => {
                setValue(
                  `education.${formIndex}.russianKladrEducationRegion`,
                  undefined
                );
              },
            }}
            control={control}
            render={({ field }) => (
              <ReactSelect<SelectOption>
                className="profile-form__field"
                placeholder={terms.COUNTRY_WHERE_YOU_STUDIED}
                markRequired
                {...selectProps(field, countrySelectProps)}
              />
            )}
          />

          <Controller
            name={`education.${formIndex}.educationalDocumentNumber`}
            rules={{
              required: terms.REQUIRED_FIELD,
              pattern: {
                value: regexp.ruOrEnWithSpecialSigns,
                message: terms.CYRILLIC_OR_LATIN_WITH_SPECIALS_CHARACTERS_ONLY,
              },
            }}
            control={control}
            render={({ field }) => (
              <InputV2
                className="profile-form__field"
                placeholder={terms.DOCUMENT_SERIES_AND_NUMBER}
                markRequired
                {...inputV2Props(field)}
              />
            )}
          />

          {watch(`education.${formIndex}.educationCountryId`) ===
            COUNTRY_RUSSIA_ID && (
            <Controller
              name={`education.${formIndex}.russianKladrEducationRegion`}
              control={control}
              rules={{
                required: {
                  value: isEdicationRegionRequired,
                  message: terms.REQUIRED_FIELD,
                },
              }}
              render={({ field, fieldState }) => (
                <ReactSelect<SelectOption>
                  className="profile-form__field"
                  placeholder={terms.INSTITUTION_REGION}
                  markRequired={isEdicationRegionRequired}
                  isClearable
                  {...regionProps}
                  {...(field as object)}
                  isDisabled={readOnly}
                  error={fieldState.error?.message}
                />
              )}
            />
          )}

          <Controller
            name={`education.${formIndex}.educationalDocumentIssuedAt`}
            control={control}
            rules={{
              required: terms.REQUIRED_FIELD,
              validate: value =>
                (value && dateLessThanCurrentOne(value)) ||
                terms.DATE_DOCUMENT_ISSUE_INVALID,
              onChange: ({ target: { value } }) =>
                value &&
                setValue(
                  `education.${formIndex}.educationalDocumentIssuedAt`,
                  normalizeYearTo4Characters(value)
                ),
            }}
            render={({ field }) => (
              <InputV2
                className="profile-form__field"
                placeholder={terms.DATE_OF_ISSUE}
                markRequired
                type="date"
                {...inputV2Props(field)}
              />
            )}
          />

          <Controller
            name={`education.${formIndex}.graduationYear`}
            control={control}
            rules={{
              required: terms.REQUIRED_FIELD,
              minLength: {
                value: 4,
                message: terms.ERROR_GRADUATION_YEAR_TOO_SMALL,
              },
              max: {
                value: (() => {
                  const issuedAt = watch(
                    `education.${formIndex}.educationalDocumentIssuedAt`
                  );

                  return issuedAt
                    ? new Date(issuedAt).getFullYear()
                    : new Date().getFullYear();
                })(),
                message: terms.ERROR_GRADUATION_YEAR_TOO_BIG,
              },
              min: {
                value: 1960,
                message: terms.ERROR_GRADUATION_YEAR_TOO_SMALL,
              },
              onChange: ({ target: { value } }) => {
                const issuedAt = watch(
                  `education.${formIndex}.educationalDocumentIssuedAt`
                );

                if (issuedAt) {
                  setValue(
                    `education.${formIndex}.graduationYear`,
                    value.slice(0, 4)
                  );
                } else {
                  dangerToast(terms.ERROR_FIRST_SPECIFY_ISSUED_AT);
                  setValue(`education.${formIndex}.graduationYear`, undefined);
                }
              },
            }}
            render={({ field }) => (
              <InputV2
                maxLength={4}
                className="profile-form__field"
                placeholder={terms.YEAR_OF_GRADUATION}
                markRequired
                type="number"
                {...inputV2Props(field)}
              />
            )}
          />

          {watch(`education.${formIndex}.documentType`) &&
            DOC_TYPES_ON_GRADES_REQUIRED.some(
              type => type === watch(`education.${formIndex}.documentType`)
            ) && (
              <>
                <div className="input-container__error">
                  {terms.REQUIRED_FOR_SECOND_VOCATIONAL}:
                </div>

                <Controller
                  name={`education.${formIndex}.satisfactoryGrades`}
                  control={control}
                  rules={{
                    onChange: ({ target: { value } }) => {
                      const result = value >= 0 ? value : 0;
                      setValue(
                        `education.${formIndex}.satisfactoryGrades`,
                        result
                      );
                      setValue(
                        `education.${formIndex}.meanGrade`,
                        calculateMeanGrade(
                          result,
                          watch(`education.${formIndex}.goodGrades`),
                          watch(`education.${formIndex}.excellentGrades`)
                        )
                      );
                    },
                  }}
                  render={({ field }) => (
                    <InputV2
                      className="profile-form__field"
                      placeholder={terms.NUMBER_OF_SATISFACTORY_GRADES}
                      type="number"
                      {...inputV2Props(field)}
                    />
                  )}
                />

                <Controller
                  name={`education.${formIndex}.goodGrades`}
                  control={control}
                  rules={{
                    onChange: ({ target: { value } }) => {
                      const result = value >= 0 ? value : 0;
                      setValue(`education.${formIndex}.goodGrades`, result);
                      setValue(
                        `education.${formIndex}.meanGrade`,
                        calculateMeanGrade(
                          watch(`education.${formIndex}.satisfactoryGrades`),
                          result,
                          watch(`education.${formIndex}.excellentGrades`)
                        )
                      );
                    },
                  }}
                  render={({ field }) => (
                    <InputV2
                      className="profile-form__field"
                      placeholder={terms.NUMBER_OF_GOOD_GRADES}
                      type="number"
                      {...inputV2Props(field)}
                    />
                  )}
                />

                <Controller
                  name={`education.${formIndex}.excellentGrades`}
                  control={control}
                  rules={{
                    onChange: ({ target: { value } }) => {
                      const result = value >= 0 ? value : 0;
                      setValue(
                        `education.${formIndex}.excellentGrades`,
                        result
                      );
                      setValue(
                        `education.${formIndex}.meanGrade`,
                        calculateMeanGrade(
                          watch(`education.${formIndex}.satisfactoryGrades`),
                          watch(`education.${formIndex}.goodGrades`),
                          result
                        )
                      );
                    },
                  }}
                  render={({ field }) => (
                    <InputV2
                      className="profile-form__field"
                      placeholder={terms.NUMBER_OF_EXCELLENT_GRADES}
                      type="number"
                      {...inputV2Props(field)}
                    />
                  )}
                />

                <Controller
                  name={`education.${formIndex}.meanGrade`}
                  control={control}
                  render={({ field }) => (
                    <InputV2
                      className="profile-form__field"
                      placeholder={terms.GPA}
                      readOnly
                      {...inputV2Props(field)}
                    />
                  )}
                />
              </>
            )}
          <div>
            {isEnrolleeRussian && (
              <Checkbox
                text={terms.DIPLOMA_WITH_DISTINCTION}
                className="profile-form__checkbox"
                {...register(`education.${formIndex}.withHonors`)}
                disabled={readOnly}
              />
            )}
            {!isEnrolleeRussian && (
              <Checkbox
                text={terms.STUDIED_IN_SPBPU_FOUNDATION_PROGRAM}
                className="profile-form__checkbox"
                {...register(
                  `education.${formIndex}.studiedSpbpuPreparatoryDepartment`
                )}
                disabled={readOnly}
              />
            )}
          </div>
        </div>
      </ProfileSection>
      <ProfileSection
        title={terms.EDUCATIONAL_DOCUMENT_CLARIFIED}
        markRequired={true}
      >
        <MultiLazyFileUploaderMemo
          filename={terms.EDUCATIONAL_DOCUMENT}
          addUploadFilesFunction={addUploadFilesFunction}
          removeUploadFilesFunction={removeUploadFilesFunction}
          disabled={readOnly}
          className="profile-form__file-uploader"
          initialFileIds={responsibleFields[formIndex].documentCopyUploadId}
        />
      </ProfileSection>
    </div>
  );
};
