export type ErrorResponse = {
  message: string;
  messageRu: string;
  errorType: ErrorResponseType;
};

export enum ErrorResponseType {
  COMMON = 'COMMON',
  NEED_FORCE = 'NEED_FORCE',
}
