import { VisaCity, VisaCountry } from '../../../models/questionnaire/visa-form';
import { apiSlice } from '../api-slice';

const baseUrl = `${process.env.REACT_APP_USER_MANAGER_API_URL}/visas`;

export const visaFormApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getVisaFormCountries: builder.query<VisaCountry[], string>({
      query: country => ({
        url: `${baseUrl}/countries`,
        params: {
          query: country,
        },
      }),
    }),

    getVisaFormCities: builder.query<
      VisaCity[],
      {
        readonly query: string;
        readonly countryId: number;
      }
    >({
      query: ({ query, countryId }) => ({
        url: `${baseUrl}/cities`,
        params: {
          query,
          countryId,
        },
      }),
    }),
  }),
});
