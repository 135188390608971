import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Breadcrumbs from 'components/common/ui-kit/Breadcrumbs';
import ModeratorContractIdInfo from 'components/moderator/ModeratorContractIdInfo';

import useAppDispatch from '../../../hooks/common/useAppDispatch';
import { useGetEnrolleeOrQuestionnaireByUserId } from '../../../hooks/moderator/useGetEnrolleeOrQuestionnaireByUserId';
import { contractApi } from '../../../redux/api/applications/contract-api';
import { directionsApi } from '../../../redux/api/applications/directions-api';
import { applicationSlice } from '../../../redux/slices/enrollee/application-slice';
import { staffContractsSlice } from '../../../redux/slices/staff/staffContractsSlice';

const ModeratorContractIdPage = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<{ id?: string }>();
  const [questionnaireId, setQuestionnaireId] = useState<number | undefined>();

  const [getContract, { data: contract }] = contractApi.useLazyGetByIdQuery();
  const [getDirection, { data: direction }] =
    directionsApi.useLazyGetDirectionByIdQuery();
  const questionnaire = useGetEnrolleeOrQuestionnaireByUserId(questionnaireId);

  useEffect(() => {
    if (id) {
      getContract(Number(id));
    }
  }, [id]);

  useEffect(() => {
    if (contract) {
      setQuestionnaireId(contract.userId);
      dispatch(
        applicationSlice.actions.setCurrentTrajectory(
          contract.applicationTrajectory
        )
      );
      getDirection(contract.directionId);
    }
  }, [contract]);

  useEffect(() => {
    if (contract && questionnaire && direction) {
      dispatch(
        staffContractsSlice.actions.setCurrentContract({
          contract,
          questionnaire,
          user: questionnaire.user,
          direction,
        })
      );

      return () => {
        dispatch(staffContractsSlice.actions.cleanCurrentContract());
      };
    }
  }, [contract, questionnaire, direction]);

  return (
    <main className="moderator-contract-id-page">
      <Breadcrumbs
        className="moderator-contract-id-page__title"
        title="Договор"
      />
      <ModeratorContractIdInfo />
    </main>
  );
};

export default ModeratorContractIdPage;
