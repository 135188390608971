import React, { FC } from 'react';

import classNames from 'classnames';

import { ReactComponent as FileIcon } from 'assets/images/icons/file.svg';

import terms from '../../../i18n';

type ModeratorFileProps = {
  title: string;
  content?: string;
  upload: () => void;
  className?: string;
  size?: number;
};

const ModeratorFile: FC<ModeratorFileProps> = ({
  title,
  content,
  upload,
  className,
  size = 61,
}) => (
  <div className={classNames('moderator-file', className)}>
    <FileIcon width={size} height={size} onClick={upload} />
    <div>
      <h5>{title}</h5>
      {content && (
        <p>
          {terms.CREATED}: {content}
        </p>
      )}
    </div>
  </div>
);

export default ModeratorFile;
