import { filesManagerClient } from 'utils/axios-clients';

export default {
  getViewerProfilePhoto: (userId, photoId) =>
    filesManagerClient
      .get(`/download/${userId}/${photoId}`, {
        responseType: 'blob',
      })
      .then(response => {
        return new Promise<string | null>((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(response.data);
          reader.onloadend = () => {
            resolve(reader.result as string);
          };
          reader.onerror = () => {
            reject(null);
          };
        });
      })
      .then(base64Blob => {
        return {
          photo: base64Blob,
        };
      }),
};
