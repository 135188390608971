import React, { useEffect, useMemo, useState } from 'react';

import { Controller } from 'react-hook-form';

import ReactSelect from 'components/common/ui-kit/Select';
import useEnrollListPage from 'hooks/admin/useEnrollListPage';
import useAppSelector from 'hooks/common/useAppSelector';
import useLazyIdEnumSelectProps from 'hooks/common/useLazyIdEnumSelectProps';
import { useDebounce } from 'hooks/questionnaire/common/util/useDebounce';
import terms from 'i18n';
import { ApplicationEducationLevel } from 'models/applications/enum';
import {
  useGetEducationFormsQuery,
  useGetEducationLevelMappingQuery,
  useGetPaymentFormsQuery,
  useLazyGetDirectionsQuery,
} from 'redux/api/applications/directions-api';

export const EnrollListsFilters = () => {
  const {
    control,
    formState: { errors },
  } = useEnrollListPage();

  const {
    currentEducationLevel,
    currentEducationFormId,
    currentPaymentFormId,
  } = useAppSelector(state => state.enrollList);

  const educationFormSelectProps = useLazyIdEnumSelectProps(
    useGetEducationFormsQuery
  );

  const paymentFormSelectProps = useLazyIdEnumSelectProps(
    useGetPaymentFormsQuery
  );

  // Directions
  const [directionsSearch, setDirectionsSearch] = useState('');
  const debouncedDirectionsSearch = useDebounce(directionsSearch, 500);
  const [
    getDirections,
    { data: directionsPage, isLoading: isDirectionsPageLoading },
  ] = useLazyGetDirectionsQuery();

  const {
    data: educationLevelMapping,
    isLoading: isEducationLevelMappingLoading,
  } = useGetEducationLevelMappingQuery(
    {
      educationLevel:
        currentEducationLevel ?? ApplicationEducationLevel.BACHELOR,
    },
    {
      skip: !currentEducationLevel,
    }
  );

  useEffect(() => {
    if (educationLevelMapping?.[0]) {
      getDirections({
        name: debouncedDirectionsSearch,
        educationLevelId: educationLevelMapping[0].id.toString(),
        educationFormId: currentEducationFormId,
        paymentFormId: currentPaymentFormId,
      });
    }
  }, [
    debouncedDirectionsSearch,
    currentEducationLevel,
    currentEducationFormId,
    currentPaymentFormId,
    educationLevelMapping,
  ]);
  const directionOptions = useMemo(
    () =>
      directionsPage?.result.map(direction => ({
        value: direction.id,
        label:
          `${direction.title}${
            direction.forForeign &&
            currentEducationLevel !== ApplicationEducationLevel.SECONDARY
              ? ' (ИНО)'
              : ''
          }` +
          (direction.educationProgram
            ? ` - ${direction.educationProgram.title}`
            : '') +
          (direction.targetOrganization
            ? ` ${direction.targetOrganization?.shortName}`
            : ''),
        id: direction.id,
      })) ?? [],
    [directionsPage]
  );

  return (
    <>
      <div className={'Enroll-lists-page__filters'}>
        <Controller
          name="educationForm"
          control={control}
          render={({ field }) => (
            <ReactSelect
              className="profile-form__field Enroll-lists-page__filter"
              {...(field as object)}
              {...educationFormSelectProps}
              placeholder={terms.EDUCATION_FORMAT}
              error={errors.educationForm?.message as string}
              isClearable={true}
            />
          )}
        />

        <Controller
          name="paymentForm"
          control={control}
          render={({ field }) => (
            <ReactSelect
              className="profile-form__field Enroll-lists-page__filter"
              {...(field as object)}
              {...paymentFormSelectProps}
              placeholder={terms.ADMISSION_CONDITIONS}
              error={errors.paymentForm?.message as string}
              isClearable={true}
            />
          )}
        />

        <Controller
          name="direction"
          control={control}
          render={({ field }) => (
            <ReactSelect
              className="profile-form__field Enroll-lists-page__filter"
              {...(field as object)}
              isLoading={
                isDirectionsPageLoading || isEducationLevelMappingLoading
              }
              options={directionOptions}
              inputValue={directionsSearch}
              onInputChange={value => setDirectionsSearch(value)}
              placeholder={terms.DIRECTION_EDUCATIONAL_PROGRAM}
              error={errors.paymentForm?.message as string}
              isClearable={true}
              isDisabled={
                currentEducationLevel === undefined ||
                currentEducationFormId === undefined ||
                currentPaymentFormId === undefined
              }
            />
          )}
        />

        {/* <Input*/}
        {/*  className={'Enroll-lists-page__search'}*/}
        {/*  placeholder={terms.LIST_SEARCH}*/}
        {/*  {...fields.listSearch}*/}
        {/*  type="text"*/}
        {/* />*/}

        {/* <Controller
          name="selectType"
          control={control}
          render={({ field }) => (
            <ReactSelect
              className="profile-form__field"
              {...(field as object)}
              options={[
                { label: 'Конкурсный', value: 'Конкурсный' },
                { label: 'Подавших', value: 'Подавших' },
              ]}
              placeholder="Конкурсный/Подавших"
              error={errors.selectType?.message as string}
              isClearable={true}
            />
          )}
        />*/}

        {/* <Controller
          name="trainingLevel"
          defaultValue={undefined}
          control={control}
          render={({ field }) => (
            <ReactSelect
              className="profile-form__field"
              isMulti={true}
              styles={{
                valueContainer: provided => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: base => ({ ...base, minWidth: '300px' }),
              }}
              {...(field as object)}
              options={trainingLevels?.data?.reduce(
                (acc, level: any) =>
                  [
                    ...acc,
                    {
                      label: level?.trainingLevelTranslate[0]?.name,
                      value: level.id,
                    },
                  ] as any,
                []
              )}
              placeholder="Уровень подготовки"
              error={errors.selectType?.message as string}
              components={{ Option, MultiValue }}
            />
          )}
        />*/}
      </div>
    </>
  );
};
