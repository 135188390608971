import {
  COUNTRY_RUSSIA_ID,
  RUSSIAN_PASSPORT_ENUM,
} from '../../../../models/enum';
import {
  AddressView,
  CompatriotDraft,
  GeneralDataDraft,
  LegalRepresentativeDraft,
  PassportDraft,
  VisaRequestDraft,
} from '../../../../models/questionnaire/questionnaire';

export const isGeneralDataFormSpecified = (form: GeneralDataDraft) =>
  form.nameLatin &&
  form.gender &&
  form.birthday &&
  form.mobileCountryCode &&
  form.mobile &&
  form.primaryCitizenshipCountry?.id;

export const isAddressesFormSpecified = (form: AddressView) =>
  form.foreignActualAddress && form.foreignRegistrationAddress;

export const isLegalRepresentativeFormSpecified = (
  form: LegalRepresentativeDraft
) => {
  const allFieldsComplete =
    form.mobile &&
    form.mobileCountryCode &&
    form.name &&
    form.surname &&
    form.relationDegree;

  const allFieldsIncomplete =
    !form.mobile &&
    !form.mobileCountryCode &&
    !form.name &&
    !form.surname &&
    !form.relationDegree;

  return allFieldsComplete || allFieldsIncomplete;
};

export const isPassportFormSpecified = (
  form: PassportDraft,
  generalDataForm: GeneralDataDraft
) =>
  form.countryOfBirth?.id &&
  (generalDataForm.primaryCitizenshipCountry?.id === COUNTRY_RUSSIA_ID
    ? form.identificationDocumentSerial
    : true) &&
  form.identificationDocumentNumber &&
  form.identificationDocumentIssuedAt &&
  form.identificationDocumentIssuedBy &&
  (form.identificationDocumentType !== RUSSIAN_PASSPORT_ENUM
    ? form.identificationDocumentExpirationDate
    : true) &&
  form.identificationDocumentPlaceOfBirth &&
  form.countryOfBirth?.id &&
  form.passportCopyUploadId !== null &&
  (form.hasMinobrBenefit
    ? form.educationInRussiaWebsiteIdentifier &&
      form.minobrReferralUploadId !== null
    : true) &&
  (form.haveTemporaryResidencePermit
    ? form.temporaryResidencePermitNumber &&
      form.temporaryResidencePermitIssuedBy &&
      form.temporaryResidencePermitIssuedAt &&
      form.temporaryResidencePermitUploadId !== null
    : true);

export const isCompatriotFormSpecified = (form: CompatriotDraft) =>
  form.applicationOfRecognitionUploadId &&
  form.birthCertificateUploadId &&
  form.relativesBirthCertificatesUploadIds;

export const isVisaFormSpecified = (form: VisaRequestDraft) =>
  form.cityToDeliverView && form.countryToDeliver;
