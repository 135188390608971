import React, { FC, PropsWithChildren } from 'react';

import { ErrorBoundary, ErrorBoundaryProps } from 'react-error-boundary';

import ChatPanel from 'components/chat/ChatWidget/ChatPanel';
import ChatToggle from 'components/chat/ChatWidget/ChatToggle';
import useChatInit from 'hooks/chat/useChatInit';
import useChatVisible from 'hooks/chat/useChatVisible';

const ChatErrorBoundary = ErrorBoundary as unknown as FC<
  PropsWithChildren<ErrorBoundaryProps>
>;

const ChatWidget: FC = () => {
  useChatInit();
  const chatVisible = useChatVisible();

  return (
    <ChatErrorBoundary fallbackRender={() => null}>
      {chatVisible && (
        <div className="chat-widget">
          <ChatPanel className="chat-widget__panel" />
          <ChatToggle />
        </div>
      )}
    </ChatErrorBoundary>
  );
};

export default ChatWidget;
