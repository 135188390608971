import React, { FC, useRef } from 'react';

import Button from 'components/common/ui-kit/Button';
import { useDownloadDocumentTemplateType } from 'hooks/admin/useDownloadDocumentTemplateType';
import { useUploadDocumentTemplateType } from 'hooks/admin/useUploadDocumentTemplateType';
import { DocumentTemplateType } from 'models/applications/application-documents';

type AdminUserListElementProps = {
  title: string;
  documentTemplateType: DocumentTemplateType;
};

const AdminDocumentTemplateType: FC<AdminUserListElementProps> = ({
  title,
  documentTemplateType,
}) => {
  const downloadDocumentTemplateType = useDownloadDocumentTemplateType();
  const uploadDocumentTemplateType = useUploadDocumentTemplateType();
  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <div className="admin-document-template">
      <h2 className="admin-document-template__title">{title}</h2>
      <div className="admin-document-template__buttons">
        <Button
          theme="success"
          onClick={() => {
            fileInputRef.current?.click();
          }}
        >
          Загрузить
        </Button>
        <input
          ref={fileInputRef}
          type="file"
          hidden
          accept=".jpeg, .jpg, .pdf, .doc, .docx"
          onChange={e => {
            const file = e.target.files?.[0];
            if (!file) return;
            uploadDocumentTemplateType(documentTemplateType, file);
          }}
        />
        <Button
          theme="success"
          onClick={() =>
            downloadDocumentTemplateType(documentTemplateType, title)
          }
        >
          Скачать
        </Button>
      </div>
    </div>
  );
};

export default AdminDocumentTemplateType;
