import React from 'react';

import './AdmissionCampaign.scss';
import { AdmissionCampaignBlock } from 'pages/AdmissionCampaign/AdmissionCampaignBlock';

// Copied from https://www.spbstu.ru/abit/admission-campaign/
const AdmissionCampaignPage = () => {
  return (
    <div className="admission-campaign">
      <h2 className="admission-campaign__title">Ход приема</h2>
      <div className="admission-campaign__row">
        <AdmissionCampaignBlock
          className="admission-campaign__block"
          educationLevel="Бакалавриат/Специалитет"
          competitiveListsUrl="bachelor-specialist/rating"
          applicantListsUrl="bachelor-specialist"
        />
        <AdmissionCampaignBlock
          className="admission-campaign__block"
          educationLevel="Подготовительный факультет"
          competitiveListsUrl="preparatory/rating"
          applicantListsUrl="preparatory"
        />
      </div>
      <div className="admission-campaign__row">
        <AdmissionCampaignBlock
          className="admission-campaign__block"
          educationLevel="Институт среднего профессионального образования"
          competitiveListsUrl="spo/rating"
          applicantListsUrl="spo"
        />
        <AdmissionCampaignBlock
          className="admission-campaign__block"
          educationLevel="Магистратура"
          competitiveListsUrl="master/rating"
          applicantListsUrl="master"
        />
      </div>
      <div className="admission-campaign__row">
        <AdmissionCampaignBlock
          className="admission-campaign__block"
          educationLevel="Аспирантура"
          competitiveListsUrl="postgraduate/rating"
          applicantListsUrl="postgraduate"
        />
      </div>
      {/* <div className="admission-campaign__row">*/}
      {/*  <div className="campaign-block admission-campaign__block">*/}
      {/*    <a*/}
      {/*      href="https://www.spbstu.ru/education/management-structure/postgraduate/orders-zachislen/"*/}
      {/*      target="_blank"*/}
      {/*      className="campaign-block__button"*/}
      {/*    >*/}
      {/*      Приказы о зачислении*/}
      {/*    </a>*/}
      {/*  </div>*/}
      {/* </div>*/}
    </div>
  );
};

export default AdmissionCampaignPage;
