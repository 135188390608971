import { useState } from 'react';

import { useForm } from 'react-hook-form';

import { SelectOption } from '../../../models/common';
import { useGetModeratorSelectProps } from './useGetModeratorSelectProps';
import { useModeratorApplication } from './useModeratorApplication';

type RejectReason = {
  readonly rejectReason: SelectOption;
};

const useSetEpguRejectReasonForm = () => {
  const { register, control, watch } = useForm<RejectReason>();

  const { rejectApplication } = useModeratorApplication(
    useGetModeratorSelectProps()
  );

  const [showRejectButton, setShowRejectButton] = useState(false);

  const handleRejectButtonClick = () => {
    if (watch('rejectReason').value)
      rejectApplication(watch('rejectReason').value);
  };

  const fields = {
    rejectReason: register('rejectReason', {
      onChange: ({ target: { value } }) => {
        if (value) setShowRejectButton(true);
      },
    }),
  };

  return {
    fields,
    control,
    showRejectButton,
    handleRejectButtonClick,
  };
};
export default useSetEpguRejectReasonForm;
