import { useMemo } from 'react';

import useAppSelector from 'hooks/common/useAppSelector';
import { UserRole } from 'models/user';

const useChatIsEnrollee = () => {
  const userRole = useAppSelector(state => state.user.role);
  return useMemo(() => userRole === UserRole.USER, [userRole]);
};

export default useChatIsEnrollee;
