import { mimeTypes, FileExtension, MimeType } from 'utils/mime-types';

export const getRandomString = () => Math.random().toString(16).slice(2);

export const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

export const openInNewPage = (link, id) =>
  window.open(`../admin/${link}/${id}`, '_blank');

export const downloadFile = (file?: File) => {
  if (!file) return;
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = file.name;
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export const uploadFile = (
  callback: (files: FileList) => void,
  accept?: string
) => {
  const input = document.createElement('input');
  input.type = 'file';
  input.style.display = 'none';
  if (accept) {
    input.accept = accept;
  }
  const handleChangeEvent = e => {
    // console.log({
    //   e,
    // });
    callback(e.target.files);
    removeInput();
  };
  const handleCancelEvent = () => {
    removeInput();
  };
  const removeInput = () => {
    input.removeEventListener('change', handleChangeEvent);
    input.removeEventListener('cancel', handleCancelEvent);
    document.body.removeChild(input);
  };
  input.addEventListener('change', handleChangeEvent);
  input.addEventListener('cancel', handleCancelEvent);
  document.body.appendChild(input);
  input.click();
};

export const blobToBase64 = (blob: Blob) =>
  new Promise<string | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.onerror = () => {
      reject(null);
    };
  });

export const urlToFile = (url: string, filename: string, mimeType: MimeType) =>
  fetch(url)
    .then(res => res.arrayBuffer())
    .then(buf => new File([buf], filename, { type: mimeType }));

export const base64toFile = (
  base64: string,
  mimeType: MimeType,
  fileType: FileExtension,
  filename?: string
) =>
  urlToFile(
    `data:${mimeType};base64,${base64}`,
    `${filename || getRandomString()}.${fileType}`,
    mimeType
  );

export const base64ToPdf = (base64: string, filename?: string) =>
  base64toFile(base64, mimeTypes.pdf, 'pdf', filename);

export const getScrollbarWidth = () => {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  (
    outer.style as CSSStyleDeclaration & { msOverflowStyle: string }
  ).msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);
  const inner = document.createElement('div');
  outer.appendChild(inner);
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
  outer?.parentNode?.removeChild(outer);
  return scrollbarWidth;
};

export const trimFileExtension = (filename: string) =>
  filename.replace(/\.[^/.]+$/, '');
