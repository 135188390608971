import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import useAppSelector from 'hooks/common/useAppSelector';
import terms, { locale } from 'i18n';
import { EducationBasis } from 'models/staff/upload-queue';
import { selectDisableOnIos } from 'redux/selectors';

import {
  ApplicationDocument,
  DocumentTemplateType,
  DocumentType,
} from '../../../models/applications/application-documents';
import { DirectionRequestType } from '../../../models/applications/applications';
import {
  ApplicationEducationLevel,
  Trajectory,
} from '../../../models/applications/enum';
import { createToast } from '../../../redux/actions';
import {
  applicationDocumentsApi,
  useSignDocumentMutation,
} from '../../../redux/api/applications/application-documents-api';
import { useGetDirectionsByApplicationIdQuery } from '../../../redux/api/applications/directions-api';
import {
  selectApplication,
  selectCurrentEducationLevel,
  selectCurrentTrajectory,
} from '../../../redux/selectors/enrollee/application';
import { downloadFile } from '../../../utils/common';
import useAppDispatch from '../../common/useAppDispatch';

export const educationBasisToDirectionPaymentForm: Record<
  EducationBasis,
  DirectionRequestType
> = {
  [EducationBasis.CONTRACT]: DirectionRequestType.CONTRACT,
  [EducationBasis.BUDGET]: DirectionRequestType.BUDGET,
};

export const useDownloadApplicationDocument = (
  applicationId: number,
  educationLevel?: ApplicationEducationLevel,
  trajectory?: Trajectory,
  onDownload?: () => void,
  educationBasis?: EducationBasis
) => {
  const dispatch = useAppDispatch();

  const errorhandler = err => {
    if (err.status === 400)
      dispatch(
        createToast(terms.ERROR_DOC_DESIRED_DIRECTION_NOT_CHOSEN, 'danger')
      );
  };

  const [generateDocumentWithMeta] =
    applicationDocumentsApi.useLazyGenerateDocumentWithMetaQuery();
  const [generateDocument] =
    applicationDocumentsApi.useLazyGenerateDocumentQuery();

  // console.log(`${educationLevel} ${trajectory} ${educationBasis}`);

  if (!educationLevel || !trajectory) return undefined;

  return (document: ApplicationDocument) => {
    const docType =
      document.type === DocumentType.PERSONAL_INFO_AGREEMENT
        ? DocumentTemplateType.PERSONAL_INFO_AGREEMENT
        : educationLevel === ApplicationEducationLevel.PREPARATORY
        ? DocumentTemplateType.APPLICATION_PREPARATION
        : educationLevel === ApplicationEducationLevel.BACHELOR
        ? DocumentTemplateType.APPLICATION_UNDERGRADUATE
        : educationLevel === ApplicationEducationLevel.GRADUATE
        ? DocumentTemplateType.APPLICATION_PHD
        : educationLevel === ApplicationEducationLevel.SECONDARY
        ? DocumentTemplateType.APPLICATION_SPECIALTY
        : DocumentTemplateType.APPLICATION_MAGISTRACY;

    if (trajectory === Trajectory.LOCAL) {
      if (!educationBasis) return;
      // const paymentFormMappingRU = {
      //   [DirectionRequestType.BUDGET]: ' (бюджет)',
      //   [DirectionRequestType.CONTRACT]: ' (контракт)',
      //   [DirectionRequestType.ALL]: '',
      // };
      // const paymentFormMappingEN = {
      //   [DirectionRequestType.BUDGET]: ' (budget)',
      //   [DirectionRequestType.CONTRACT]: ' (contract)',
      //   [DirectionRequestType.ALL]: '',
      // };
      const directionPaymentForm =
        educationBasisToDirectionPaymentForm[educationBasis];
      generateDocumentWithMeta({
        id: applicationId,
        docType,
        directionPaymentForm,
      })
        ?.unwrap()
        .then(result => {
          const filename =
            locale === 'ru'
              ? `${document.title}.docx`
              : `${document.titleForeign}.docx`;
          downloadFile(new File([result], filename));
          onDownload?.();
        })
        .catch(errorhandler);
    } else {
      generateDocument({ id: applicationId, docType })
        ?.unwrap()
        .then(result => {
          const filename =
            locale === 'ru'
              ? `${document.title}.docx`
              : `${document.titleForeign}.docx`;
          downloadFile(new File([result], filename));
          onDownload?.();
        })
        .catch(errorhandler);
    }
  };
};

const useCardForDownloadFile = (
  acceptBlank: boolean,
  blankId: number | undefined,
  title: string,
  refetchDocuments?: () => void,
  onDownload?: () => void
) => {
  const dispatch = useAppDispatch();
  const disableOnIos = useAppSelector(selectDisableOnIos);
  const { applicationId } = useParams();
  const trajectory = useSelector(selectCurrentTrajectory);
  const educationBasis = useSelector(selectApplication)?.educationBasis;

  const currentEducationLevel = useAppSelector(selectCurrentEducationLevel);
  const selectedApplication = useAppSelector(
    state => state.application.selectedApplication
  );

  const { data: directions } = useGetDirectionsByApplicationIdQuery({
    applicationId: Number(applicationId),
  });

  const [signDocument] = useSignDocumentMutation();

  const downloadDocument = useDownloadApplicationDocument(
    Number(applicationId),
    currentEducationLevel,
    trajectory,
    onDownload,
    educationBasis
  );

  const handleClickDownloadDocs = async (document: ApplicationDocument) => {
    if (!currentEducationLevel || !selectedApplication || !directions) return;

    if (
      Object.keys(directions).length === 0 &&
      document.type === DocumentType.APPLICATION_DOCUMENT
    ) {
      dispatch(createToast(terms.ERROR_FIRST_CHOOSE_DIRECTIONS, 'danger'));
      return;
    }

    if (!trajectory) {
      dispatch(createToast(terms.ERROR_TRAJECTORY_NOT_CHOSEN, 'danger'));
      return;
    }

    downloadDocument?.(document);
  };

  const subscribeBlank = signed => {
    if (blankId) {
      signDocument({ documentId: blankId, isSigned: signed })
        .unwrap()
        .then(() => {
          refetchDocuments?.();
          if (signed) dispatch(createToast(terms.DOC_WAS_SIGNED, 'success'));
        });
    }
  };

  return {
    handleClickDownloadDocs,
    subscribeBlank,
    disableOnIos,
  };
};

export default useCardForDownloadFile;
