import { useEffect, useState } from 'react';

import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import { selectAccessToken } from 'redux/selectors';
import { getAuthCredentialsFromStorage } from 'utils/auth-token';

import { UserRole } from '../../models/user';
import { getViewerProfileImage } from '../../redux/actions';
import { useGetUserQuery } from '../../redux/api/common/user-api';
import { setAuthCredentials } from '../../redux/slices/common/auth-slice';
import { setUser } from '../../redux/slices/common/user-slice';

const useAuthInitialization = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectAccessToken);
  const { data: user } = useGetUserQuery();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!token) {
      const authCredentials = getAuthCredentialsFromStorage();
      if (authCredentials) {
        dispatch(setAuthCredentials(authCredentials));
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (user) {
      const role: UserRole = UserRole[user.role];
      dispatch(setUser({ ...user, role }));
      if (!user.photoUploadId) return;
      dispatch(
        getViewerProfileImage({ userId: user.id, photoId: user.photoUploadId })
      );
    }
  }, [user]);

  return { loading, token };
};

export default useAuthInitialization;
