import React, { FC } from 'react';

import classnames from 'classnames';

import { DirectionRequestType } from 'models/applications/applications';

type DirectionsTypeElementProps = {
  name: string;
  type: DirectionRequestType;
  active: boolean;
  setActive: (value: DirectionRequestType) => void;
};

const DirectionsTypeElement: FC<DirectionsTypeElementProps> = ({
  name,
  active,
  setActive,
  type,
}) => {
  return (
    <div
      className={classnames('directions-type-list__element', { active })}
      onClick={() => setActive(type)}
    >
      <h2>{name}</h2>
    </div>
  );
};

export default DirectionsTypeElement;
