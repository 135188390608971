import React, { FC } from 'react';

import classNames from 'classnames';

import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import { Chat, ChatType } from 'models/chat';
import { UserRole } from 'models/user';
import { setChatType } from 'redux/actions';
import {
  selectChatType,
  selectUnreadCount,
  selectUserChats,
} from 'redux/selectors/common/chat';

import { chatTypeToName } from '..';

export type Chats = {
  [key in ChatType]?: Chat;
};

const ChatSelector: FC = () => {
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(state => state.user.role);
  const userChats = useAppSelector(selectUserChats) || {};
  const chatType = useAppSelector(selectChatType);
  const badgeCount = useAppSelector(selectUnreadCount) || {};

  const selectChat = chatType => dispatch(setChatType(chatType));

  const isSelectedChat = type => type === chatType;

  const isBadgeVisible = chatType =>
    (!isSelectedChat(chatType) ||
      userRole === UserRole.MODERATOR ||
      userRole === UserRole.INSTITUTE_STAFF ||
      userRole === UserRole.SPECIALIST) &&
    badgeCount[chatType] > 0;

  const getBadgeCount = chatType => {
    const count = badgeCount[chatType];
    return count > 99 ? '...' : count;
  };

  return (
    <div className="chat-selector">
      {Object.keys(chatTypeToName).map(
        chatType =>
          userChats[chatType] && (
            <div
              className={classNames('chat-selector__button', {
                'chat-selector__button--selected': isSelectedChat(chatType),
              })}
              key={chatType}
              onClick={() => selectChat(chatType)}
            >
              <span>{chatTypeToName[chatType]}</span>
              {isBadgeVisible(chatType) && (
                <span className="chat-selector__badge">
                  {getBadgeCount(chatType)}
                </span>
              )}
            </div>
          )
      )}
    </div>
  );
};

export default ChatSelector;
