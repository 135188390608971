import { createSlice } from '@reduxjs/toolkit';

type ProfileInitialState = {
  showMobileMenu: boolean;
};

const initialState: ProfileInitialState = {
  showMobileMenu: false,
};

/**
 * TODO - migrate
 * @param state
 */
export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    changeMobileMenu: state => {
      state.showMobileMenu = !state.showMobileMenu;
    },
  },
});

export default profileSlice.reducer;
