import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  ActivatePayload,
  ActivateResp,
  RequestPasswordResettingRequest,
  ResetPasswordRequest,
  SignInPayload,
  SignInResp,
  SignUpPayload,
  SignUpResp,
} from '../../../models/auth';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({}),
  endpoints: builder => ({
    login: builder.mutation<SignInResp, SignInPayload>({
      query: credentials => ({
        url: `${process.env.REACT_APP_USER_MANAGER_API_URL}/auth/login`,
        method: 'POST',
        body: credentials,
      }),
    }),
    register: builder.mutation<SignUpResp, SignUpPayload>({
      query: data => ({
        url: `${process.env.REACT_APP_USER_MANAGER_API_URL}/auth/register`,
        method: 'POST',
        body: data,
      }),
    }),
    activate: builder.mutation<ActivateResp, ActivatePayload>({
      query: data => ({
        url: `${process.env.REACT_APP_USER_MANAGER_API_URL}/auth/activate`,
        method: 'POST',
        body: data,
      }),
    }),
    requestResetPassword: builder.mutation<
      void,
      RequestPasswordResettingRequest
    >({
      query: data => ({
        url: `${process.env.REACT_APP_USER_MANAGER_API_URL}/auth/request-reset-password`,
        method: 'POST',
        body: data,
      }),
    }),

    resetPassword: builder.mutation<void, ResetPasswordRequest>({
      query: data => ({
        url: `${process.env.REACT_APP_USER_MANAGER_API_URL}/auth/reset-password`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useActivateMutation,
  useRequestResetPasswordMutation,
  useResetPasswordMutation,
} = authApi;
