import React, { FC, useEffect, useRef } from 'react';

import ReactCrop from 'react-image-crop';

import { ReactComponent as FlipIcon } from 'assets/images/icons/flip.svg';
import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg';
import profileImagePlaceholder from 'assets/images/profile.png';
import TemplateModal from 'components/common/modals/TemplateModal';
import Button from 'components/common/ui-kit/Button';
import useAppSelector from 'hooks/common/useAppSelector';
import useViewerProfileImage from 'hooks/profile/useViewerProfileImage';
import terms from 'i18n';
import { modalDataSelector } from 'redux/selectors';

import { UserData } from '../../../../models/user';

const EditProfileImageModal: FC = () => {
  const uploadButtonRef = useRef<HTMLInputElement>(null);
  const modalData = useAppSelector(modalDataSelector) as {
    mode: 'upload' | 'edit' | 'delete';
    user: UserData;
    isImpersonate: boolean;
    userId?: number | null;
  };

  const { crop, image, onSave, onLoad, onClear, setCrop, onUpload, onRotate } =
    useViewerProfileImage(
      modalData.user,
      modalData.isImpersonate,
      modalData.userId
    );

  useEffect(() => {
    if (modalData.mode === 'upload') {
      uploadButtonRef.current?.click();
    } else if (modalData.mode === 'delete') {
      onClear();
    }
  }, [modalData]);

  return (
    <TemplateModal title={terms.PHOTO}>
      <div className="edit-profile-img-modal">
        <div className="edit-profile-img-modal__cropper">
          <ReactCrop
            className="edit-profile-img-modal__area"
            src={image || profileImagePlaceholder}
            crop={crop}
            disabled={!image}
            onChange={setCrop}
            onImageLoaded={onLoad}
            imageStyle={{ objectFit: 'contain', maxHeight: '500px' }}
          />
        </div>
        <div className="edit-profile-img-modal__controls">
          <Button
            className="edit-profile-img-modal__control"
            onClick={() => onRotate('left')}
            disabled={!image}
            type="button"
          >
            <FlipIcon />
          </Button>
          <Button
            className="edit-profile-img-modal__control"
            onClick={() => onRotate('right')}
            disabled={!image}
            type="button"
          >
            <FlipIcon style={{ transform: 'scale(-1, 1)' }} />
          </Button>
          <Button
            className="edit-profile-img-modal__control"
            onClick={onClear}
            disabled={!image}
            type="button"
          >
            <TrashIcon />
          </Button>
        </div>
        <div>
          <p>{terms.PHOTO_REQUIREMENTS}</p>
          <p>{terms.PHOTO_REQUIREMENTS_BG} </p>
          <p>{terms.PHOTO_REQUIREMENTS_FACE} </p>
          {terms.PHOTO_REQUIREMENTS_WIDTH.length > 0 && (
            <p>{terms.PHOTO_REQUIREMENTS_WIDTH}</p>
          )}
          {terms.PHOTO_REQUIREMENTS_HEIGHT.length > 0 && (
            <p>{terms.PHOTO_REQUIREMENTS_HEIGHT}</p>
          )}
          <p>{terms.PHOTO_REQUIREMENTS_PX} </p>
          <p>{terms.PHOTO_REQUIREMENTS_SIZE}</p>
          <p>{terms.PHOTO_REQUIREMENTS_EXTENSION}</p>
          <p>
            {terms.PHOTO_REQUIREMENTS_CARD.length > 0 && (
              <p>{terms.PHOTO_REQUIREMENTS_CARD}</p>
            )}
          </p>
        </div>
        <footer className="edit-profile-img-modal__footer">
          <label className="button edit-profile-img-modal__button">
            <input
              type="file"
              className="edit-profile-img-modal__file-input"
              onChange={onUpload}
              ref={uploadButtonRef}
            />
            {terms.SELECT_ANOTHER_PHOTO}
          </label>
          <Button
            theme="success"
            className="edit-profile-img-modal__button"
            onClick={onSave}
            type="button"
          >
            {terms.SAVE}
          </Button>
        </footer>
      </div>
    </TemplateModal>
  );
};

export default EditProfileImageModal;
