import { useEffect, useState } from 'react';

import useAppDispatch from 'hooks/common/useAppDispatch';
import { entranceTestsApi } from 'redux/api/applications/entrance-tests-api';
import { applicationSlice } from 'redux/slices/enrollee/application-slice';

import { DirectionRequestType } from '../../../models/applications/applications';
import { ChosenDirectionWithPriority } from '../../../models/applications/directions';
import {
  useDeleteApplicationDirectionMutation,
  useLazyAddDirectionsQuery,
} from '../../../redux/api/applications/applications-api';
import { useLazyGetDirectionsByApplicationIdQuery } from '../../../redux/api/applications/directions-api';

export const useGetDirections = ({
  applicationId,
  requestType,
}: {
  readonly applicationId: number;
  readonly requestType: DirectionRequestType;
}) => {
  const [setDirections] = useLazyAddDirectionsQuery();
  const [deleteApplicationDirection] = useDeleteApplicationDirectionMutation();

  const [localDirections, setLocalDirections] = useState<
    ChosenDirectionWithPriority[]
  >([]);

  const dispatch = useAppDispatch();

  const [getAllEntranceTests] =
    entranceTestsApi.useLazyGetAllEntranceTestsByApplicationIdQuery();

  useEffect(() => {
    if (localDirections.length === 0) {
      dispatch(applicationSlice.actions.setEntranceTests([]));
      return;
    }
    getAllEntranceTests(applicationId)
      .unwrap()
      .then(entranceTests =>
        dispatch(applicationSlice.actions.setEntranceTests(entranceTests))
      );
  }, [localDirections]);

  const [getDirections, { data: groupedDirections }] =
    useLazyGetDirectionsByApplicationIdQuery();

  useEffect(() => {
    if (applicationId) {
      getDirections({
        applicationId: applicationId,
      });
    }
  }, [applicationId, requestType]);

  useEffect(() => {
    if (groupedDirections) {
      let directions: ChosenDirectionWithPriority[] = [];

      if (requestType === DirectionRequestType.ALL)
        directions = Object.values(groupedDirections).flatMap(
          direction => direction
        );

      if (requestType === DirectionRequestType.BUDGET)
        directions = [...(groupedDirections?.BUDGET ?? [])];

      if (requestType === DirectionRequestType.CONTRACT)
        directions = [...(groupedDirections?.CONTRACT ?? [])];

      setLocalDirections(sortByPriority(directions));
    }
  }, [groupedDirections, requestType]);

  const sortByPriority = (directions: ChosenDirectionWithPriority[]) =>
    [...directions].sort((a, b) => a.priority - b.priority);

  const updateDirections = (
    chosenDirectionWithPriorities: ChosenDirectionWithPriority[]
  ) => {
    setDirections({
      applicationId: Number(applicationId),
      request: chosenDirectionWithPriorities.map(direction => ({
        directionId: direction.direction.id,
        priority: direction.priority,
      })),
      requestType,
    });
  };

  const swapDirections = (from: number, to: number) => {
    const fromElement = localDirections[from];
    const toElement = localDirections[to];

    const updatedDirections = sortByPriority(
      localDirections.map((direction, index) => {
        if (index === from) {
          return {
            ...direction,
            priority: toElement.priority,
          };
        } else if (index === to) {
          return {
            ...direction,
            priority: fromElement.priority,
          };
        } else {
          return direction;
        }
      })
    );

    setLocalDirections(updatedDirections);
    updateDirections(updatedDirections);
  };

  const changePriority = (direction, newPriority) => {
    setLocalDirections(prevState =>
      prevState.map(localDirection =>
        localDirection.id === direction.id
          ? { ...localDirection, priority: newPriority }
          : localDirection
      )
    );
  };

  const submitDirectionsWithPriorities = () =>
    setDirections({
      applicationId: Number(applicationId),
      request: localDirections.map(direction => ({
        directionId: direction.direction.id,
        priority: direction.priority,
      })),
      requestType,
    });

  const onDragEnd = result => {
    if (!result.destination || localDirections.length === 0) {
      return;
    }

    const reorderedDirections = Array.from(localDirections);
    const [removed] = reorderedDirections.splice(result.source.index, 1);
    reorderedDirections.splice(result.destination.index, 0, removed);

    const updatedDirections = reorderedDirections.map((direction, index) => ({
      ...direction,
      priority: index + 1,
    }));

    setLocalDirections(updatedDirections);
    updateDirections(updatedDirections);
  };

  const deleteDirection = (id: number) => {
    deleteApplicationDirection({
      applicationId,
      applicationDirectionId: id,
    })
      .unwrap()
      .then(() =>
        getDirections({
          applicationId: applicationId,
        })
      );
  };

  return {
    directions: localDirections,
    allDirections: groupedDirections
      ? Object.values(groupedDirections).flatMap(direction => direction)
      : [],
    deleteDirection,
    swapDirections,
    changePriority,
    onDragEnd,
    submitDirectionsWithPriorities,
  };
};
