import React, { FC, useEffect, useMemo } from 'react';

import { AppModal } from 'types/custom';

import AddDictionaryAdminModal from 'components/common/modals/AddDictionaryAdminModal';
import AddFileModal from 'components/common/modals/AddFileModal';
import AddIndividualAchievementModal from 'components/common/modals/AddIndividualAchivementModal';
import AddOlympiadModal from 'components/common/modals/AddOlympiadModal';
import AddPrivilegesModal from 'components/common/modals/AddPrivilegesModal';
import AddUserAdminModal from 'components/common/modals/AddUserAdminModal';
import ChangePasswordModal from 'components/common/modals/ChangePasswordModal';
import DirectionsModal from 'components/common/modals/DirectionsModal';
import EditProfileImageModal from 'components/common/modals/EditProfileImageModal';
import useAppSelector from 'hooks/common/useAppSelector';
import { modalNameSelector } from 'redux/selectors';
import { getScrollbarWidth } from 'utils/common';

import ChangeCompetitiveGroupStatusModal from '../ChangeCompetitiveGroupStatusModal';
import ClearExamDateTimeModal from '../ClearExamDateTimeModal';
import ConfirmWithdrawnApplicationModal from '../ConfirmWithdrawnApplicationModal';
import CreateExpertExaminationReferralModal from '../CreateExpertExaminationModal';
import DirectionsEditingModal from '../DirectionsEditingModal';
import EditEntranceTestModal from '../EditEntranceTestModal';
import ForceAcceptApplicationModal from '../ForceAcceptApplicationModal';
import HallmarkModal from '../HallmarkModal';
import ImpersonateExitModal from '../ImpersonateExitModal';
import SetEpguRejectReasonModal from '../SetEpguRejectReasonModal';
import StatementJsonToUploadModal from '../StatementJsonToUploadModal';
import UnsentMessageExitModal from '../UnsentMessageExitModal';

const modalNameToComponent: { [key in AppModal]?: FC } = {
  CHANGE_PASSWORD_MODAL: ChangePasswordModal,
  EDIT_PROFILE_IMAGE_MODAL: EditProfileImageModal,
  ADD_INDIVIDUAL_ACHIEVEMENT: AddIndividualAchievementModal,
  ADD_OLYMPIAD_MODAL: AddOlympiadModal,
  PRIVILEGES: AddPrivilegesModal,
  ADD_FILE_MODAL: AddFileModal,
  EDIT_FILE_MODAL: AddFileModal,
  ADMIN_USER: AddUserAdminModal,
  ADMIN_ADD_DICTIONARY: AddDictionaryAdminModal,
  DIRECTIONS_MODAL: DirectionsModal,
  DIRECTIONS_EDITING_MODAL: DirectionsEditingModal,
  CLEAR_EXAM_DATETIME_MODAL: ClearExamDateTimeModal,
  SET_EPGU_REJECT_REASON: SetEpguRejectReasonModal,
  EDIT_ENTRANCE_TEST_MODAL: EditEntranceTestModal,
  IMPERSONATE_EXIT_MODAL: ImpersonateExitModal,
  UNSENT_MESSAGE_EXIT_MODAL: UnsentMessageExitModal,
  STATEMENT_JSON_TO_UPLOAD_MODAL: StatementJsonToUploadModal,
  HALLMARK_MODAL: HallmarkModal,
  FORCE_ACCEPT_APPLICATION_MODAL: ForceAcceptApplicationModal,
  EXPERT_EXAMINE_REFERRAL: CreateExpertExaminationReferralModal,
  CONFIRM_WITHDRAWN_APPLICATION: ConfirmWithdrawnApplicationModal,
  CHANGE_COMPETITIVE_GROUP_MODAL: ChangeCompetitiveGroupStatusModal,
};

const ModalLayer: FC = () => {
  const modalName = useAppSelector(modalNameSelector);

  useEffect(() => {
    if (modalName) {
      document.documentElement.style.maxHeight = '100vh';
      document.documentElement.style.overflow = 'hidden';
      document.documentElement.style.marginRight = getScrollbarWidth() + 'px';
    } else {
      document.documentElement.style.maxHeight = 'unset';
      document.documentElement.style.overflow = 'unset';
      document.documentElement.style.marginRight = 'auto';
    }
  }, [modalName]);

  const Modal = useMemo(
    () => (modalName ? modalNameToComponent[modalName] : null),
    [modalName]
  );

  return Modal ? <Modal /> : null;
};

export default ModalLayer;
