import React, { FC } from 'react';

import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { MutationDefinition } from '@reduxjs/toolkit/query';

import Loader from 'components/common/ui-kit/Loader';
import ProfileForm from 'components/profile/ProfileForm';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import { FileUploadError } from 'hooks/files/useUploadFiles';
import useProfileRoutesContext from 'hooks/profile/useProfileRoutesContext';
import { useCompatriotDraft } from 'hooks/questionnaire/common/draft/useCompatriotDataDraft';

import Button from '../../../components/common/ui-kit/Button';
import { useLazyUploadFiles } from '../../../hooks/files/useLazyUploadFiles';
import terms from '../../../i18n';
import { DocumentTemplateType } from '../../../models/applications/application-documents';
import { Draft, DraftId } from '../../../models/questionnaire/questionnaire';
import { createToast } from '../../../redux/actions';
import { filesApi } from '../../../redux/api/common/files-api';
import { selectIsDraftReadonly } from '../../../redux/selectors/enrollee/questionnaire';
import { downloadFile } from '../../../utils/common';
import Section from './Section';

const CompatriotDataPage: FC = () => {
  const dispatch = useAppDispatch();

  const [downloadSelfIdentificationStatement] =
    filesApi.useLazyDownloadTemplateQuery();
  const { onNextPageNavigate } = useProfileRoutesContext();
  const readOnly = useAppSelector(selectIsDraftReadonly);

  const onDownloadDocument = () => {
    downloadSelfIdentificationStatement(
      DocumentTemplateType.SELF_IDENTIFICATION_STATEMENT
    )
      ?.unwrap()
      .then(result => downloadFile(new File([result], 'compatriot.pdf')))
      .catch(() => dispatch(createToast(terms.DOWNLOAD_FILE_ERROR, 'danger')));
  };

  const { draft: compatriotDraft, saveDraft } = useCompatriotDraft();

  const {
    files: applicationOfRecognitionFiles,
    uploadFiles: uploadApplicationOfRecognitionFiles,
    ...applicationFileProps
  } = useLazyUploadFiles({
    filename: terms.APPLICATION_OF_RECOGNITION,
    initialFileIds: compatriotDraft?.applicationOfRecognitionUploadId,
  });

  const {
    files: birthCertificatesFiles,
    uploadFiles: uploadBirthCertificatesFiles,
    ...birthFileProps
  } = useLazyUploadFiles({
    filename: terms.BIRTH_CERTIFICATE,
    initialFileIds: compatriotDraft?.birthCertificateUploadId,
  });

  const {
    files: relativesBirthCertificatesFiles,
    uploadFiles: uploadRelativesBirthCertificatesFiles,
    ...relativesFileProps
  } = useLazyUploadFiles({
    filename: terms.COMPATRIOT_DOCUMENTS,
    initialFileIds: compatriotDraft?.relativesBirthCertificatesUploadIds[0],
  });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!compatriotDraft) return;

    Promise.all([
      uploadApplicationOfRecognitionFiles(),
      uploadBirthCertificatesFiles(),
      uploadRelativesBirthCertificatesFiles(),
    ])
      .then(
        ([
          applicationOfRecognitionFileId,
          birthCeritificateFileId,
          relativeBirthCeritificateFileIds,
        ]) => {
          const draftResult = saveDraft({
            ...compatriotDraft,
            applicationOfRecognitionUploadId: applicationOfRecognitionFileId,
            birthCertificateUploadId: birthCeritificateFileId,
            relativesBirthCertificatesUploadIds: [
              relativeBirthCeritificateFileIds,
            ], // fixme
          }) as MutationActionCreatorResult<
            MutationDefinition<{ id: DraftId; draft: Draft }, any, any, any>
          >;

          return draftResult.unwrap();
        }
      )
      .then(() => {
        onNextPageNavigate();
      })
      .catch(error => {
        if (error === FileUploadError.FILE_NOT_ATTACHED) {
          dispatch(createToast(terms.MAKE_SURE_ALL_FILES_ATTACHED, 'danger'));
        }
      });
  };

  if (!compatriotDraft) {
    return <Loader />;
  }

  return (
    <ProfileForm onSubmit={onSubmit}>
      <Button type="button" onClick={onDownloadDocument}>
        Скачать Заявление о самоидентификации
      </Button>

      <Section
        title={terms.APPLICATION_OF_RECOGNITION}
        readOnly={readOnly}
        docs={applicationOfRecognitionFiles}
        uploaderDescription="Загрузите заполненное заявление"
        {...applicationFileProps}
      />

      <Section
        title={terms.BIRTH_CERTIFICATE}
        readOnly={readOnly}
        docs={birthCertificatesFiles}
        {...birthFileProps}
      />

      <Section
        title={terms.COMPATRIOT_STATUS_DOCS}
        readOnly={readOnly}
        docs={relativesBirthCertificatesFiles}
        {...relativesFileProps}
      />
    </ProfileForm>
  );
};

export default CompatriotDataPage;
