import {
  useGetEducationLevelsQuery,
  useGetEducationTypesQuery,
} from '../../../redux/api/common/enum-api';
import { educationFormSelectOptions } from '../../../utils/enum-select';
import { useGetCountriesSelectProps } from '../../common/api/useGetCountriesSelectProps';
import useLazyEnumSelectProps from '../../common/useI18nEnumSelectProps';

const ACADEMIC_CERTIFICATE = 'ACADEMIC_CERTIFICATE';

export const useGetEducationInformationFormEnums = (
  isRussianEnrollee: boolean
) => {
  const educationalFormSelectProps = educationFormSelectOptions;
  const countrySelectProps = useGetCountriesSelectProps(true);
  const educationDocumentTypeSelectProps = useLazyEnumSelectProps(
    useGetEducationTypesQuery
  );
  const educationLevelSelectProps = useLazyEnumSelectProps(
    useGetEducationLevelsQuery
  );

  return {
    countrySelectProps: {
      options: countrySelectProps,
    },
    educationLevelSelectProps,
    educationalFormSelectProps: {
      options: educationalFormSelectProps,
    },
    educationDocumentTypeSelectProps: {
      options: isRussianEnrollee
        ? educationDocumentTypeSelectProps.options.filter(
            value => value.value !== ACADEMIC_CERTIFICATE
          )
        : educationDocumentTypeSelectProps.options.sort((a, b) =>
            a.label.localeCompare(b.label)
          ),
      error: educationDocumentTypeSelectProps.error,
      isLoading: educationDocumentTypeSelectProps.isLoading,
    },
  };
};
