import { locale } from 'i18n';
import { getAuthCredentialsFromStorage, getAuthTokenFromStorage, saveAuthCredentialsToStorage } from 'utils/auth-token';
import camelize from 'camelize';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

const onRequest = (config) => {
  config.headers = {
    ...config.headers,
    'X-localization': locale,
  };
  const credentials = getAuthCredentialsFromStorage();
  if (credentials?.accessToken)
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${credentials?.accessToken}`,
    };
  return { ...config, metadata: { startTime: Date.now() } };
};

const onResponse = async (response: AxiosResponse) => {
  if (response.config.responseType === 'blob') {
    const data = response.data;
    const newResp = camelize(response);
    newResp.data = data;
    return newResp;
  }

  return camelize(response);
};

const onError = async (error: AxiosError) => {
  if (error.response?.status !== 401) {
    throw camelize(error.response);
  }
  const credentials = getAuthCredentialsFromStorage();
  const originalConfig = {...error.config};

  if (!credentials || !originalConfig?.headers) {
    throw camelize(error.response); 
  }

  const { refreshToken } = credentials;

  try {
    const { data: { access }} = await axios.post(`${process.env.REACT_APP_USER_MANAGER_API_URL}/auth/refresh`, {
      refreshToken
    });
    saveAuthCredentialsToStorage(access);
    originalConfig.headers['Authorization'] = `Bearer ${access.accessToken}`;
  } catch(e) {
    throw camelize(error.response);
  }

  try {
    const res = await axios.request(originalConfig);
    return res;
  } catch (e) {
    throw camelize(e);
  }
};

const createAxiosClient = (config?: AxiosRequestConfig) => {
  const client = axios.create(config);
  client.interceptors.request.use(onRequest);
  client.interceptors.response.use(onResponse, onError);
  return client;
};

export default createAxiosClient;
