import React, { FC } from 'react';

import PassportDataForm from 'components/profile/PassportDataForm';

const PassportDataPage: FC = () => (
  <div>
    <PassportDataForm />
  </div>
);

export default PassportDataPage;
