import React, { FC } from 'react';

import terms from 'i18n';
import { ApplicationView } from 'models/applications/applications';
import { ApplicationEducationLevel } from 'models/applications/enum';

import Application from './Application';

type ApplicationListProps = {
  list: ApplicationView[];
  refetch: () => void;
};

export const educationLevelToText: {
  [key in ApplicationEducationLevel]: string;
} = {
  [ApplicationEducationLevel.BACHELOR]: terms.BACHELOR_EDUCATION_LEVEL,
  [ApplicationEducationLevel.SECONDARY]:
    terms.SECONDARY_VOCATIONAL_EDUCATION_LEVEL,
  [ApplicationEducationLevel.MASTER]: terms.MASTER_EDUCATION_LEVEL,
  [ApplicationEducationLevel.GRADUATE]: terms.GRADUATE_EDUCATION_LEVEL,
  [ApplicationEducationLevel.PREPARATORY]:
    terms.UNIVERSITY_FOUNDATION_PROGRAMS_EDUCATION_LEVEL,
};

const ApplicationsList: FC<ApplicationListProps> = ({ list, refetch }) => {
  const sortList: ApplicationView[] = [...list].sort((a, b) => a.id - b.id);

  return (
    <section className="statements-container">
      {sortList.map((el: ApplicationView, index) => (
        <Application key={index} el={el} refetch={refetch} />
      ))}
    </section>
  );
};

export default ApplicationsList;
