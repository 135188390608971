import terms from 'i18n';
import { OriginalEducationDocumentSubmissionType } from 'models/applications/applications';
import { SelectOption } from 'models/common';
import { EducationForm, Gender, RelationDegree } from 'models/enum';

import { ContractState } from '../models/applications/contracts';
import {
  ApplicationAdmissionCondition,
  ApplicationEducationLevel,
  ApplicationEpguStatus,
  ApplicationFlag,
  ApplicationState,
  CoordinatorStatus,
  ExpertStatus,
  MessagesState,
  Trajectory,
} from '../models/applications/enum';
import { AuditEventType, AuditEventTypeHead } from '../models/staff/audit';
import { UserRole } from '../models/user';

const getEnumSelectOptions = (
  enumKeys: string[],
  keyToLabel?: { [key: string]: string }
): SelectOption[] =>
  enumKeys.map(key => ({
    value: key,
    label: keyToLabel?.[key] ?? terms[key],
  }));

export const genderSelectOptions = getEnumSelectOptions(Object.keys(Gender));

export const roleSelectOptions = getEnumSelectOptions(
  Object.keys(UserRole).filter(key => isNaN(Number(key)))
);

export const relationDegreeSelectOptions = getEnumSelectOptions(
  Object.keys(RelationDegree)
);

export const educationFormSelectOptions = getEnumSelectOptions(
  Object.keys(EducationForm)
);

const applicationStates = Object.keys(ApplicationState);
applicationStates.splice(
  applicationStates.indexOf(ApplicationState.SENT_FOR_EDITING) + 1,
  0,
  ApplicationFlag.EDITED_BY_USER
);
export const applicationStatusSelectOptions =
  getEnumSelectOptions(applicationStates);

export const expertStatusSelectOptions = getEnumSelectOptions(
  Object.keys(ExpertStatus)
);

export const contractStateSelectOptions = getEnumSelectOptions(
  Object.keys(ContractState)
);

export const coordinatorStatusSelectOptions = getEnumSelectOptions(
  Object.keys(CoordinatorStatus)
);

export const messagesStatusSelectOptions = getEnumSelectOptions(
  Object.keys(MessagesState)
);

export const educationLevelSelectOptions = getEnumSelectOptions(
  Object.keys(ApplicationEducationLevel)
);

export const trajectorySelectOptions = getEnumSelectOptions(
  Object.keys(Trajectory)
);

export const applicationAdmissionConditionSelectOptions = getEnumSelectOptions(
  Object.keys(ApplicationAdmissionCondition),
  { [ApplicationAdmissionCondition.SPECIAL_QUOTA]: terms.INDIVIDUAL_QUOTA }
);

export const auditEventTypeOptions = getEnumSelectOptions(
  Object.keys(AuditEventType)
);

export const auditEventTypeHeadOptions = getEnumSelectOptions(
  Object.keys(AuditEventTypeHead)
);

export const applicationEpguStatusOptions = getEnumSelectOptions(
  Object.keys(ApplicationEpguStatus)
);

export const originalEducationDocumentSubmissionTypeOptions =
  getEnumSelectOptions(Object.keys(OriginalEducationDocumentSubmissionType));

export const findSelectOption = (
  selectOptions?: SelectOption[],
  value?: any
): SelectOption | undefined => selectOptions?.find(el => el.value === value);
