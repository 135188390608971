import React, { FC } from 'react';

import CardForDownloadFile from '../../../components/statements/CardForDownloadFile';
import terms from '../../../i18n';
import { DocumentTemplateType } from '../../../models/applications/application-documents';
import { useLazyDownloadTemplateQuery } from '../../../redux/api/common/files-api';
import { downloadFile } from '../../../utils/common';
import ContractListPage from '../ContractListPage';
import './ContractPage.scss';

const ContractPage: FC = () => {
  const [downloadTemplate] = useLazyDownloadTemplateQuery();

  return (
    <>
      <header style={{ marginBottom: '1.5rem' }}>
        <p>{terms.CONTRACT_HEADER_MESSAGE}</p>
      </header>
      <CardForDownloadFile
        className="contract-page__file"
        showCard={true}
        title={`${terms.CONTRACT_CREATION_INSTRUCTION}`}
        acceptBlank={true}
        onDownload={() => {
          downloadTemplate(DocumentTemplateType.CONTRACT_CREATION_INSTRUCTION)
            ?.unwrap()
            .then(result => {
              downloadFile(
                new File(
                  [result],
                  `${terms.CONTRACT_CREATION_INSTRUCTION}.docx`
                )
              );
            });
        }}
      />
      <ContractListPage />
    </>
  );
};

export default ContractPage;
