import { createSlice } from '@reduxjs/toolkit';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { Entity } from 'types/redux';

import { ChatMessages } from 'components/chat/ChatWidget/ChatPanel/Chat';
import { Chats } from 'components/chat/ChatWidget/ChatPanel/ChatHeader/ChatSelector';
import { ChatType, UnreadCount } from 'models/chat';
import {
  getChatMessages,
  getUnreadCount,
  getUnreadCountApplicant,
  getUserChats,
} from 'redux/actions';
import { findChatTypeById } from 'utils/chat-helpers';
import { entityReducerBuilder, initialEntityState } from 'utils/redux-helpers';

type ChatInitialState = {
  entities: {
    userChats: Entity<Chats>;
    chatMessages: Entity<ChatMessages>;
    unreadCount: Entity<UnreadCount>;
  };
  opened: boolean;
  chatType: ChatType;
  socket: ReconnectingWebSocket | null;
  viewed: boolean;
};

const initialState: ChatInitialState = {
  entities: {
    userChats: initialEntityState,
    chatMessages: initialEntityState,
    unreadCount: initialEntityState,
  },
  opened: false,
  chatType: ChatType.ENROLLEE,
  socket: null,
  viewed: false,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatOpened(state, action) {
      state.opened = action.payload;
    },
    setChatType(state, action) {
      state.chatType = action.payload;
    },
    setChatSocket(state, action) {
      state.socket = action.payload;
    },
    setChatViewed(state, action) {
      state.viewed = action.payload;
    },
    addUpdateChatMessage(state, action) {
      if (!state.entities.chatMessages.data) return;
      const chatMessage = action.payload;
      state.entities.chatMessages.data[chatMessage.uuid] = chatMessage;
    },
    applyToChatMessages(state, action) {
      const chatMessages = state.entities.chatMessages.data;
      if (!chatMessages) return;
      state.entities.chatMessages.data = Object.entries(chatMessages).reduce(
        (acc, [uuid, message]) => {
          acc[uuid] = action.payload(message);
          return acc;
        },
        {}
      );
    },
    resetChatMessages(state) {
      state.entities.chatMessages.data = null;
    },
    incUnreadCountById(state, action) {
      const chatType = findChatTypeById(state, action.payload);
      if (chatType) {
        state.entities.unreadCount.data
          ? state.entities.unreadCount.data[chatType]!++
          : (state.entities.unreadCount.data = { [chatType]: 1 });
      }
    },
    resetUnreadCountByType(state, action) {
      if (!state.entities.unreadCount.data) return;
      state.entities.unreadCount.data[action.payload] = 0;
    },
    resetUnreadCountById(state, action) {
      if (!state.entities.unreadCount.data) return;
      const chatType = findChatTypeById(state, action.payload);
      if (chatType) state.entities.unreadCount.data[chatType] = 0;
    },
  },
  extraReducers(builder) {
    entityReducerBuilder(builder)
      .add(
        getUserChats,
        s => s.entities.userChats,
        chats =>
          chats.reduce((acc, chat) => {
            acc[chat.marker] = chat;
            return acc;
          }, {})
      )
      .add(
        getChatMessages,
        s => s.entities.chatMessages,
        chatMessages =>
          chatMessages.reduce((acc, chatMessage) => {
            chatMessage.sent = true;
            acc[chatMessage.uuid] = chatMessage;
            return acc;
          }, {})
      )
      .add(
        getUnreadCount,
        s => s.entities.unreadCount,
        unreadCount => ({ [ChatType.ENROLLEE]: unreadCount })
      )
      .add(getUnreadCountApplicant, s => s.entities.unreadCount);
  },
});

export default chatSlice.reducer;
