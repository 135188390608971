import { useForm } from 'react-hook-form';

import useAppDispatch from 'hooks/common/useAppDispatch';
import { useEnrolleeUserId } from 'hooks/questionnaire/common/draft/useQuestionnaire';
import terms from 'i18n';
import { showModal } from 'redux/actions';
import { useLazyPreviewFilesQuery } from 'redux/api/common/files-api';

import {
  Privilege,
  PrivilegeGroupingType,
} from '../../models/applications/privileges';
import { privilegesApi } from '../../redux/api/applications/privileges-api';
import { useDownloadFile } from '../common/api/useDownloadFile';
import { usePrivilegeFormCheckBoxes } from './usePrivilegeFormCheckBoxes';

export type PrivilegeForm = {
  readonly hasMilitarySupport: boolean;
  readonly isDisabledPerson: boolean;
  readonly hasSpecialConditionsForEntranceTest: boolean;

  readonly specialConditions?: string;
  readonly specialConditionsDocumentId?: number;

  readonly disabledPersonDocumentId?: number;

  readonly militarySupportDocumentId?: number;
};

const usePrivilegeForm = ({
  applicationId,
}: {
  readonly applicationId: number;
}) => {
  const dispatch = useAppDispatch();
  const userId = useEnrolleeUserId();

  const { data: formData } = privilegesApi.useGetPrivilegeFormQuery(
    applicationId,
    { refetchOnMountOrArgChange: true }
  );
  const [deletePrivilege] = privilegesApi.useDeletePrivilegeMutation();
  const download = useDownloadFile();

  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    setValue,
    getValues,
    ...rest
  } = useForm<PrivilegeForm>();

  const {
    onSubmitClick,
    onSpecialsConditionChange,
    onDisabledConditionChange,
    onMilitaryConditionChange,
    ...fileProps
  } = usePrivilegeFormCheckBoxes({
    privilegeExtras: formData?.privilegeExtras,
    watch,
    setValue,
    applicationId,
    clearErrors,
    getValues,
  });

  const [previewFiles] = useLazyPreviewFilesQuery();

  const hasSpecialConditionsForEntranceTest = watch(
    'hasSpecialConditionsForEntranceTest'
  );
  const isDisabledPerson = watch('isDisabledPerson');

  const fields = {
    hasSpecialConditionsForEntranceTest: register(
      'hasSpecialConditionsForEntranceTest',
      {
        onChange: onSpecialsConditionChange,
      }
    ),

    specialConditions: register('specialConditions', {
      required: {
        value: hasSpecialConditionsForEntranceTest,
        message: terms.REQUIRED_FIELD,
      },
    }),
    specialConditionsDocumentId: register('specialConditionsDocumentId', {
      required: {
        value: hasSpecialConditionsForEntranceTest,
        message: terms.REQUIRED_FIELD,
      },
    }),

    isDisabledPerson: register('isDisabledPerson', {
      onChange: onDisabledConditionChange,
    }),
    disabledPersonDocumentId: register('disabledPersonDocumentId', {
      required: { value: isDisabledPerson, message: terms.REQUIRED_FIELD },
    }),

    hasMilitarySupport: register('hasMilitarySupport', {
      onChange: onMilitaryConditionChange,
    }),
    militarySupportDocumentId: register('militarySupportDocumentId', {
      required: {
        value: watch('hasMilitarySupport'),
        message: terms.REQUIRED_FIELD,
      },
    }),
  };

  const onDownloadDocumentsClick = (documentId: number) => {
    if (!userId) return;
    previewFiles({ userId, ids: [documentId] })
      .unwrap()
      .then(files => {
        if (!files[0]) return;
        download({
          userId,
          fileId: documentId,
          fileName: files[0].originalName,
        });
      });
  };

  const onDeletePrivilegeClick = (id: number) => deletePrivilege(id);

  const onOpenModalClick = ({
    type,
    privilege,
  }: {
    readonly type: PrivilegeGroupingType;
    readonly privilege?: Privilege;
  }) =>
    dispatch(
      showModal({
        name: 'PRIVILEGES',
        data: {
          applicationId,
          type,
          privilege,
          userId,
        },
      })
    );

  const onSubmit = handleSubmit(data => onSubmitClick(data));

  return {
    watch,
    fields,
    onSubmit,
    hasSpecialConditionsForEntranceTest,
    isDisabledPerson,
    benefits: formData?.privileges?.BENEFIT || [],
    prerogatives: formData?.privileges?.PREROGATIVE || [],
    individualQuotas: [...(formData?.privileges?.SPECIAL || [])],
    ...fileProps,
    onDownloadDocumentsClick,
    onDeletePrivilegeClick,
    onAddBenefitButtonClick: () =>
      onOpenModalClick({
        type: PrivilegeGroupingType.BENEFIT,
      }),
    onEditBenefitButtonClick: privilege =>
      onOpenModalClick({
        privilege,
        type: PrivilegeGroupingType.BENEFIT,
      }),
    onAddPrerogativeButtonClick: () =>
      onOpenModalClick({
        type: PrivilegeGroupingType.PREROGATIVE,
      }),
    onEditPrerogativeButtonClick: privilege =>
      onOpenModalClick({
        privilege,
        type: PrivilegeGroupingType.PREROGATIVE,
      }),
    onAddIndividualQuotaButtonClick: () =>
      onOpenModalClick({
        type: PrivilegeGroupingType.SPECIAL,
      }),
    onEditIndividualQuotaButtonClick: privilege =>
      onOpenModalClick({
        privilege,
        type: PrivilegeGroupingType.SPECIAL,
      }),
    ...rest,
  };
};

export default usePrivilegeForm;
