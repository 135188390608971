import { useNavigate } from 'react-router-dom';

import Button from 'components/common/ui-kit/Button';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import terms from 'i18n';
import { ApplicationEducationLevel } from 'models/applications/enum';
import { selectCurrentEnrollListsEducationLevel } from 'redux/selectors/common/enroll-lists';
import { enrollListsSlice } from 'redux/slices/common/enroll-lists-slice';

const buttonData: {
  readonly route: string;
  readonly educationLevel: ApplicationEducationLevel;
  readonly label: string;
}[] = [
  {
    route: '../bachelor-specialist',
    educationLevel: ApplicationEducationLevel.BACHELOR,
    label: terms.BACHELOR_OR_SPECIALIST,
  },
  {
    route: '../postgraduate',
    educationLevel: ApplicationEducationLevel.GRADUATE,
    label: terms.GRADUATE_EDUCATION_LEVEL,
  },
  {
    route: '../master',
    educationLevel: ApplicationEducationLevel.MASTER,
    label: terms.MASTER_EDUCATION_LEVEL,
  },
  {
    route: '../spo',
    educationLevel: ApplicationEducationLevel.SECONDARY,
    label: terms.SECONDARY_DEGREE_SHORT,
  },
  {
    route: '../preparatory',
    educationLevel: ApplicationEducationLevel.PREPARATORY,
    label: terms.PREPARATORY_DEGREE,
  },
];

export const EnrollListsCommandButtons = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const educationLevel = useAppSelector(selectCurrentEnrollListsEducationLevel);

  const onClick = (
    route: string,
    educationLevel: ApplicationEducationLevel
  ) => {
    dispatch(enrollListsSlice.actions.setEducationLevel(educationLevel));
    navigate(route);
  };

  return (
    <div className="Enroll-lists-page__filters">
      {buttonData.map((data, index) => (
        <Button
          className="Enroll-lists-page__filter-button"
          key={index}
          onClick={() => onClick(data.route, data.educationLevel)}
          theme={data.educationLevel === educationLevel ? 'success' : undefined}
        >
          {data.label}
        </Button>
      ))}
    </div>
  );
};
