import React, { FC, useMemo } from 'react';

import classNames from 'classnames';

import Checkbox from 'components/common/ui-kit/Checkbox';
import useAppSelector from 'hooks/common/useAppSelector';
import terms, { isRussianLocale } from 'i18n';
import { Direction } from 'models/applications/directions';
import {
  selectCurrentEducationLevel,
  selectCurrentTrajectory,
} from 'redux/selectors/enrollee/application';

import useAppDispatch from '../../../../hooks/common/useAppDispatch';
import {
  ApplicationEducationLevel,
  DirectionApplicationEducationLevel,
  Trajectory,
} from '../../../../models/applications/enum';
import { translate } from '../../../../models/common';
import { createToast } from '../../../../redux/actions';
import {
  getAllSubjects,
  getReplaceableSubjectsString,
  getUniqueSubjects,
} from '../DirectionsTrainingElementStatement/view';

type DirectionsTrainingElementProps = {
  direction: Direction;
  readOnly: boolean;
  isSelected: boolean;
  onSelect: (id: { id: number; code: string }) => void;

  readonly errorMessage?: string;
};

const DirectionsTrainingElement: FC<DirectionsTrainingElementProps> = ({
  readOnly,
  direction,
  errorMessage,
  isSelected,
  onSelect,
}) => {
  const level = useAppSelector(selectCurrentEducationLevel);
  const trajectory = useAppSelector(selectCurrentTrajectory);
  const dispatch = useAppDispatch();

  const allSubjects = useMemo(
    () => getAllSubjects(direction.entranceTestsSets, direction),
    [direction.entranceTestsSets]
  );

  const subjects = useMemo(
    () => getUniqueSubjects(direction.entranceTestsSets, direction),
    [direction.entranceTestsSets]
  );

  return (
    <div
      className={classNames('directions-training-element', {
        'directions-training-element--closed': direction.closed,
      })}
    >
      <h2>
        {(isRussianLocale()
          ? direction.code + ' ' + direction.title
          : direction.code +
            ' ' +
            (direction.titleForeign || direction.title)) +
          (direction.targetOrganization
            ? ` ${direction.targetOrganization.shortName}`
            : '')}
      </h2>
      <div className="row">
        <div className="column">
          {direction.asBenefit && (
            <p>
              <b>{terms.LGOT}</b>
            </p>
          )}

          {direction.asQuota && (
            <p>
              <b>{terms.INDIVIDUAL_QUOTA}</b>
            </p>
          )}

          {direction.applicationEducationLevel ===
            DirectionApplicationEducationLevel.SECONDARY && (
            <p>
              <b>{terms.GRADE_9}</b>
            </p>
          )}
          {direction.applicationEducationLevel ===
            DirectionApplicationEducationLevel.UPPER_SECONDARY && (
            <p>
              <b>{terms.GRADE_11}</b>
            </p>
          )}

          <p>
            {terms.MODE_OF_STUDY}: <b>{translate(direction.educationForm)}</b>
          </p>
          <p>
            {terms.FORM_PAYMENT_ID}: <b>{translate(direction.paymentForm)}</b>
          </p>
        </div>
        <div className="column">
          <p>
            {terms.LEVEL_OF_TRAINING}: {/* TODO STUB*/}
            <b>{translate(direction.educationLevel)}</b>
          </p>
          {direction.educationProgram && (
            <p>
              {terms.EDUCATIONAL_PROGRAM}:{' '}
              <b>
                {isRussianLocale()
                  ? direction.educationProgram.title
                  : direction.educationProgram.titleForeign ||
                    direction.educationProgram.title}
              </b>
            </p>
          )}
        </div>
      </div>
      {direction.budgetLevel && (
        <div className="row">
          <p>
            {terms.BUDGET_LEVEL}: <b>{direction.budgetLevel}</b>
          </p>
        </div>
      )}
      <div className="row">
        <p>
          {terms.EDUCATION_LANGUAGE}: <b>{direction.educationLanguageName}</b>
        </p>
      </div>
      {direction.targetOrganization && (
        <div className="row">
          <p>
            {terms.TARGET_ORGANIZATION}:{' '}
            <b>{direction.targetOrganization.name}</b>
          </p>
        </div>
      )}
      <div className="row">
        <p>
          {terms.FACULTY}: <b>{translate(direction.faculty)}</b>
        </p>
      </div>
      <div className="row">
        <div className="column">
          {!(
            trajectory === Trajectory.QUOTA ||
            (trajectory === Trajectory.FOREIGN &&
              level === ApplicationEducationLevel.PREPARATORY) ||
            level === ApplicationEducationLevel.SECONDARY
          ) &&
            subjects.length !== 0 && (
              <>
                <p>
                  {terms.ENTRANCE_EXAMS} / {terms.PASSING_SCORE}
                </p>
                {trajectory &&
                  level &&
                  subjects.map((subject, index) => {
                    return (
                      <b key={index}>
                        {getReplaceableSubjectsString(
                          subject,
                          allSubjects,
                          direction
                        )}
                      </b>
                    );
                  })}
              </>
            )}
        </div>
        <div
          className="directions-training-element__control-btn-container"
          onClick={() =>
            readOnly &&
            errorMessage &&
            dispatch(createToast(errorMessage, 'danger'))
          }
        >
          <Checkbox
            disabled={readOnly}
            checked={isSelected}
            onChange={() =>
              onSelect({ id: direction.id, code: direction.code })
            }
            text={terms.CHOOSE}
            theme="success-filled"
            cardSignature
          />
        </div>
      </div>
    </div>
  );
};

export default DirectionsTrainingElement;
