import React, { FC } from 'react';

import ModeratorDataList from 'components/moderator/ModeratorDataList';
import useAppSelector from 'hooks/common/useAppSelector';
import terms from 'i18n';
import { DataList } from 'models/moderator';
import { formatDateYYYYMMDD } from 'utils/date';

import Loader from '../../../components/common/ui-kit/Loader';
import CheckListEntryValueSelectWithRoleChecker from '../../../components/moderator/CheckListEntryValueSelectWithRoleChecker';
import { selectCurrentQuestionnaire } from '../../../redux/selectors/staff/applications';
import { selectCurrentSupportQuestionnaire } from '../../../redux/selectors/staff/questionnaires';

const ModeratorLegalRepresentativePage: FC<{ isSupport?: boolean }> = ({
  isSupport = false,
}) => {
  const questionnaire = useAppSelector(
    isSupport ? selectCurrentSupportQuestionnaire : selectCurrentQuestionnaire
  );

  if (!questionnaire) {
    return <Loader />;
  }

  const list: DataList = [
    {
      title: 'Степень родства:',
      label: questionnaire?.legalRepresentativeForm?.relationDegree
        ? terms[questionnaire?.legalRepresentativeForm?.relationDegree]
        : '-',
    },
    {
      title: 'Фамилия:',
      label: questionnaire.legalRepresentativeForm?.surname || '-',
    },
    {
      title: 'Имя:',
      label: questionnaire.legalRepresentativeForm?.name || '-',
    },
    {
      title: 'Отчество:',
      label: questionnaire.legalRepresentativeForm?.patronymic || '-',
    },
    {
      title: 'Дата рождения:',
      label: questionnaire.legalRepresentativeForm?.birthday
        ? formatDateYYYYMMDD(questionnaire.legalRepresentativeForm?.birthday)
        : '-',
    },
    {
      title: 'Мобильный телефон:',
      label: questionnaire.legalRepresentativeForm?.mobile || '-',
    },
  ];

  return (
    <div>
      <ModeratorDataList list={list} />
      <CheckListEntryValueSelectWithRoleChecker
        getValue={checkList =>
          checkList.unrelatedCheckMarks['legal-representative']
        }
        updateValue={newValue => ({
          unrelatedCheckMarks: {
            'legal-representative': newValue,
          },
        })}
      />
    </div>
  );
};

export default ModeratorLegalRepresentativePage;
