import { useEffect } from 'react';

import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useForm } from 'react-hook-form';

import terms, { isRussianLocale } from '../../../i18n';
import { SelectOption } from '../../../models/common';
import {
  SaveVisaDraftRequest,
  VisaRequestDraft,
} from '../../../models/questionnaire/questionnaire';
import { VisaCity, VisaCountry } from '../../../models/questionnaire/visa-form';
import { createToast } from '../../../redux/actions';
import { visaFormApi } from '../../../redux/api/questionnaire/visa-form-api';
import useAppDispatch from '../../common/useAppDispatch';
import { useVisaDraft } from './useVisaDraft';

export type VisaForm = {
  readonly countryToDeliver?: SelectOption;
  readonly cityToDeliver?: SelectOption;
  readonly stringifiedHadStudyVisa?: string;
};

const useGetVisaPlaces = <T>({
  useGetPlaces,
  mapper,
}: {
  useGetPlaces: UseLazyQuery<any>;
  mapper: (value: T) => SelectOption;
}) => {
  const [getPlaces, { data: places }] = useGetPlaces();

  return {
    fetch: getPlaces,
    places: ((places as T[]) || []).map(mapper),
  };
};

export const useVisaForm = (onSuccess?: () => void) => {
  const dispatch = useAppDispatch();

  const { draft, saveDraft, ...draftInfo } = useVisaDraft();

  const { fetch: fetchCountries, places: countryProps } =
    useGetVisaPlaces<VisaCountry>({
      useGetPlaces: visaFormApi.useLazyGetVisaFormCountriesQuery,
      mapper: country => ({
        label: isRussianLocale() ? country.countryRus : country.country,
        value: country.id,
      }),
    });

  const getCountries = (country: string | null | undefined) =>
    fetchCountries(country || '');

  const { register, handleSubmit, watch, setValue, reset, ...rest } =
    useForm<VisaForm>();

  const onCountriesInputChange = e => getCountries(e);

  const { fetch: fetchCities, places: citiesProps } =
    useGetVisaPlaces<VisaCity>({
      useGetPlaces: visaFormApi.useLazyGetVisaFormCitiesQuery,
      mapper: city => ({
        label: isRussianLocale() ? city.cityRus : city.city,
        value: city.id,
      }),
    });

  const getCities = ({
    query,
    countryId,
  }: {
    readonly query: string | undefined | null;
    readonly countryId: number | undefined;
  }) => {
    if (!countryId) return;

    return fetchCities({
      query: query || '',
      countryId,
    });
  };

  const onCitiesInputChange = e => {
    const country = watch('countryToDeliver');
    if (!country) return;
    getCities({
      query: e,
      countryId: country.value,
    });
  };

  useEffect(() => {
    if (draft) {
      const castedDraft = draft as VisaRequestDraft;

      Promise.all([
        getCountries(
          isRussianLocale()
            ? castedDraft.countryToDeliver?.title
            : castedDraft.countryToDeliver?.titleForeign
        ).unwrap(),
        getCities({
          query: castedDraft.cityToDeliverView?.city,
          countryId: castedDraft.countryToDeliver?.id,
        })?.unwrap(),
      ]).then(response => {
        reset({
          stringifiedHadStudyVisa: castedDraft.hadStudyVisa ? 'yes' : 'no',
          countryToDeliver: {
            value: castedDraft.countryToDeliver?.id,
            label: isRussianLocale()
              ? castedDraft.countryToDeliver?.title
              : castedDraft.countryToDeliver?.titleForeign,
          },
          cityToDeliver: {
            label: isRussianLocale()
              ? castedDraft.cityToDeliverView?.cityRus
              : castedDraft.cityToDeliverView?.city,
            value: (response as Array<any>)?.[1]?.[0]?.id,
          },
        });

        if (!castedDraft.cityToDeliverView?.city) {
          reset({
            stringifiedHadStudyVisa: undefined,
          });
        }
      });
    }
  }, [draft]);

  const fields = {
    countryToDeliver: register('countryToDeliver', {
      required: terms.REQUIRED_FIELD,
      onChange: ({ target: { value } }) => {
        setValue('cityToDeliver', undefined);
        if (value) {
          getCities({
            query: '',
            countryId: watch('countryToDeliver')?.value,
          });
        } else {
          getCountries('');
        }
      },
    }),
    cityToDeliver: register('cityToDeliver', {
      required: terms.REQUIRED_FIELD,
    }),
    stringifiedHadStudyVisa: register('stringifiedHadStudyVisa', {
      required: terms.REQUIRED_FIELD,
    }),
  };

  const onSubmit = handleSubmit(data => {
    if (!draft?.id) return;

    const draftToSave: SaveVisaDraftRequest = {
      id: draft?.id,
      hadStudyVisa: data.stringifiedHadStudyVisa === 'yes',
      cityToDeliver: data.cityToDeliver?.label,
      countryToDeliverId: data.countryToDeliver?.value,
    };

    saveDraft(draftToSave)
      ?.unwrap()
      .then(() => onSuccess?.())
      .catch(error => {
        const errorDescriptionLocale: string = isRussianLocale()
          ? error.data?.descriptionRu ?? error.data.description
          : error.data.description;
        dispatch(createToast(errorDescriptionLocale, 'danger'));
      });
  });

  return {
    fields,
    watch,
    onSubmit,
    setValue,
    countryProps,
    citiesProps,
    onCitiesInputChange,
    onCountriesInputChange,
    ...rest,
    ...draftInfo,
  };
};
