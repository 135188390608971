import {
  Achievement,
  AchievementConfirmationDocumentTypes,
  AchievementTypes,
  CreateAchievementRequest,
  UpdateAchievementRequest,
} from '../../../models/applications/achievements';
import { ApplicationEducationLevel } from '../../../models/applications/enum';
import { Olympiad } from '../../../models/applications/olympiads';
import { apiSlice } from '../api-slice';

const baseUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/achievements`;

export const achievementsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createAchievement: builder.mutation<Achievement, CreateAchievementRequest>({
      query: request => ({
        url: `${baseUrl}/create`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['Achievement'],
    }),

    updateAchievement: builder.mutation<Achievement, UpdateAchievementRequest>({
      query: request => ({
        url: `${baseUrl}/update`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['Achievement'],
    }),

    updateAchievementFormInfo: builder.mutation<
      Achievement,
      {
        readonly applicationId: number;
        readonly publicationsAmount?: number;
        readonly inventionsAmount?: number;
      }
    >({
      query: request => ({
        url: `${baseUrl}/achievement-form`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['AchievementForm'],
    }),

    deleteAchievement: builder.mutation<Achievement, number>({
      query: id => ({
        url: `${baseUrl}/delete/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Achievement'],
    }),

    getAchievement: builder.query<Achievement, number>({
      query: id => ({
        url: `${baseUrl}/${id}`,
      }),
    }),

    getAchievementForm: builder.query<
      {
        readonly achievements: Achievement[];
        readonly olympiads: Olympiad[];
        readonly publicationsAmount: number;
        readonly inventionsAmount: number;
      },
      number
    >({
      query: applicationId => ({
        url: `${baseUrl}/form-by-application-id/${applicationId}`,
      }),
      providesTags: ['AchievementForm'],
    }),

    getAchievementsByApplicationId: builder.query<Achievement[], number>({
      query: applicationId => ({
        url: `${baseUrl}/by-application-id/${applicationId}`,
      }),
      providesTags: ['Achievement'],
    }),

    getAchievementTypes: builder.query<
      AchievementTypes,
      { educationLevel?: ApplicationEducationLevel }
    >({
      query: ({ educationLevel }) => ({
        url: `${baseUrl}/achievement-types`,
        params: { educationLevel },
      }),
    }),

    getAchievementConfirmationDocTypes: builder.query<
      AchievementConfirmationDocumentTypes,
      number
    >({
      query: achievementTypeId => ({
        url: `${baseUrl}/achievement-types/${achievementTypeId}/confirmation-document-types`,
      }),
    }),

    updateAdmittedFlagForAchievement: builder.mutation<
      void,
      { id: number; value: boolean }
    >({
      query: ({ id, value }) => ({
        url: `${baseUrl}/${id}/admitted`,
        method: 'PUT',
        params: {
          value,
        },
      }),
      invalidatesTags: ['AchievementForm', 'CheckList'],
    }),
  }),
});
