import React, { FC } from 'react';

import { NavRoute } from 'types/custom';

import ProgressTab from 'components/statements/ProgressTab';

type PageWithSidenavProps = React.PropsWithChildren<{
  activePage: NavRoute | undefined;
}>;

const PageWithProgressTab: FC<PageWithSidenavProps> = ({
  children,
  activePage,
}) => (
  <div className="progress-tab-page">
    <h1 className="progress-tab-page__title">{activePage?.title}</h1>
    <div className="progress-tab-page__tab-container">
      <ProgressTab
        activePage={activePage}
        className="progress-tab-page__tab"
        classNameElement="progress-tab-page__tab_element"
      />
    </div>
    <div className="progress-tab-page__content">
      <div className="progress-tab-page__inner">{children}</div>
    </div>
  </div>
);

export default PageWithProgressTab;
