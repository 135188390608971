import React, { FC } from 'react';

import Button from 'components/common/ui-kit/Button';
import LevelStatementsList from 'components/statements/LevelStatementsList';
import useLevelPage from 'hooks/statements/useLevelPage';
import terms from 'i18n';
import ProgressTabPage from 'pages/ProgressTabPage';

import { statementsTabRotes } from '../../../config/routes/statements-tab-routes';

const LevelPage: FC = () => {
  const { onSubmit, isSubmitButtonDisabled } = useLevelPage();

  return (
    <main className="level-page">
      <ProgressTabPage
        activePage={statementsTabRotes.find(l =>
          location.pathname.includes(l.to)
        )}
      >
        <LevelStatementsList />
        <Button
          onClick={onSubmit}
          theme="success"
          disabled={isSubmitButtonDisabled}
        >
          {terms.SAVE_AND_NEXT}
        </Button>
      </ProgressTabPage>
    </main>
  );
};

export default LevelPage;
