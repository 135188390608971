import React, { FC } from 'react';

import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';

export type RouteLinkProps = LinkProps & { disabled?: boolean };

const RouteLink: FC<RouteLinkProps> = props => {
  return (
    <Link
      {...props}
      className={classNames(
        'link',
        { 'link--disabled': props.disabled },
        props.className
      )}
    />
  );
};

export default RouteLink;
