import React from 'react';

import AdminDocumentTemplate from 'components/admin/AdminDocumentTemplate';
import { DocumentTemplateType } from 'models/applications/application-documents';

import useAppSelector from '../../../hooks/common/useAppSelector';
import { selectUserManagementState } from '../../../redux/selectors/staff/user-management';

const documentTemplateTypesToStrings: Record<DocumentTemplateType, string> = {
  [DocumentTemplateType.APPLICATION_UNDERGRADUATE]: 'Заявление бакалавриат',
  [DocumentTemplateType.APPLICATION_MAGISTRACY]: 'Заявление магистратура',
  [DocumentTemplateType.APPLICATION_PHD]: 'Заявление аспирантура',
  [DocumentTemplateType.APPLICATION_SPECIALTY]: 'Заявление ИСПО',
  [DocumentTemplateType.APPLICATION_PREPARATION]:
    'Заявление на подготовительный факультет',
  [DocumentTemplateType.APPLICATION_EXPERT_EXAMINATION_REFERRAL]:
    'Бланк направления на предварительную экспертизу',
  [DocumentTemplateType.APPLICATION_EXPERT_EXAMINATION_RESULT]:
    'Бланк предварительной экспертизы',
  [DocumentTemplateType.PERSONAL_INFO_AGREEMENT]:
    'Согласие на обработку персональных данных',
  // [DocumentTemplateType.LEGAL_REPRESENTATIVE_INFO_AGREEMENT]:
  // 'Согласие на обработку персональных данных от законного представителя',
  [DocumentTemplateType.SELF_IDENTIFICATION_STATEMENT]:
    'Заявление о самоидентификации',
  [DocumentTemplateType.CONTRACT_RF_INDIVIDUAL]:
    'Договор РФ (Бакалавриат и магистратура) физлицо',
  [DocumentTemplateType.CONTRACT_RF_LEGAL_ENTITY]:
    'Договор РФ (Бакалавриат и магистратура) юрлицо',
  [DocumentTemplateType.CONTRACT_RF_DEGREE_INDIVIDUAL]:
    'Договор РФ (Аспирантура) физлицо',
  [DocumentTemplateType.CONTRACT_RF_DEGREE_LEGAL_ENTITY]:
    'Договор РФ (Аспирантура) юрлицо',
  [DocumentTemplateType.CONTRACT_RF_SPECIALITY_INDIVIDUAL]:
    'Договор РФ (ИСПО) физлицо',
  [DocumentTemplateType.CONTRACT_RF_SPECIALITY_LEGAL_ENTITY]:
    'Договор РФ (ИСПО) юрлицо',
  [DocumentTemplateType.CONTRACT_FOREIGN_INDIVIDUAL_RU]:
    'Договор ИНО (Бакалавриат и магистратура) индивидуальный русскоязычный',
  [DocumentTemplateType.CONTRACT_FOREIGN_PARTNER_RU]:
    'Договор ИНО (Бакалавриат и магистратура) партнер русскоязычный',
  [DocumentTemplateType.CONTRACT_FOREIGN_INDIVIDUAL_BILINGUAL]:
    'Договор ИНО (Бакалавриат и магистратура) индивидуальный двуязычный',
  [DocumentTemplateType.CONTRACT_FOREIGN_PARTNER_BILINGUAL]:
    'Договор ИНО (Бакалавриат и магистратура) партнер двуязычный',
  [DocumentTemplateType.CONTRACT_FOREIGN_PARTNER_BILINGUAL_WITH_PRICE]:
    'Договор ИНО (бакалавриат и магистратура) партнер двуязычный с указанием стоимости',
  [DocumentTemplateType.CONTRACT_FOREIGN_DEGREE_INDIVIDUAL_RU]:
    'Договор ИНО (Аспирантура) индивидуальный русскоязычный',
  [DocumentTemplateType.CONTRACT_FOREIGN_DEGREE_PARTNER_RU]:
    'Договор ИНО (Аспирантура) партнер русскоязычный',
  [DocumentTemplateType.CONTRACT_FOREIGN_PREPARATORY_INDIVIDUAL_BILINGUAL]:
    'Договор ИНО (Подготовительный факультет) индивидуальный двуязычный',
  [DocumentTemplateType.CONTRACT_FOREIGN_PREPARATORY_SEMESTER_INDIVIDUAL_BILINGUAL]:
    'Договор ИНО семестр (Подготовительный факультет) индивидуальный двуязычный',
  [DocumentTemplateType.CONTRACT_FOREIGN_PREPARATORY_PARTNER_BILINGUAL]:
    'Договор ИНО (Подготовительный факультет) партнер двуязычный',
  [DocumentTemplateType.CONTRACT_FOREIGN_PREPARATORY_SEMESTER_PARTNER_BILINGUAL_WITH_PRICE]:
    'Договор ИНО семестр (Подготовительный факультет) партнер двуязычный с указанием стоимости',
  [DocumentTemplateType.CONTRACT_FOREIGN_PARTNER_RU_WITH_PRICE]:
    'Договор ИНО (бакалавриат и магистратура) партнер русскоязычный, с указанием стоимости',
  [DocumentTemplateType.CONTRACT_FOREIGN_PREPARATORY_PARTNER_BILINGUAL_WITH_PRICE]:
    'Договор ИНО (Подготовительный факультет) партнер двуязычный, с указанием стоимости',
  [DocumentTemplateType.RECEIPT_FOR_ACCEPTED_ORIGINAL_EDUCATION_DOCUMENT]:
    'Расписка за принятый оригинал документа об образовании',
  [DocumentTemplateType.COMPLIANCE_LETTER_BILINGUAL]:
    'Расписка об информировании СПбПУ двуязычный',
  [DocumentTemplateType.COMPLIANCE_LETTER_BILINGUAL_SPECIALITY]:
    'Расписка об информировании ИСПО СПбПУ двуязычный',
  [DocumentTemplateType.ENTRANCE_TESTS_INSTRUCTION_BACHELOR]:
    'Инструкция по прохождению вступительных испытаний Бакалавр',
  [DocumentTemplateType.ENTRANCE_TESTS_INSTRUCTION_MASTER]:
    'Инструкция по прохождению вступительных испытаний Магистр',
  [DocumentTemplateType.CONTRACT_CREATION_INSTRUCTION]:
    'Инструкция по оформлению договора',
  [DocumentTemplateType.APPLICATION_ENROLL_AGREEMENT]:
    'Заявление о согласии на зачисление',
  [DocumentTemplateType.APPLICATION_ENROLL_WITHDRAW_AGREEMENT]:
    'Заявление об отзыве согласия на зачисление',
  [DocumentTemplateType.APPLICATION_DOCUMENTS_WITHDRAW]:
    'Заявление об отзыве документов',
  [DocumentTemplateType.APPLICATION_ENROLL_REJECT_ORIGINAL_WITHDRAW]:
    'Заявление об отказе от зачисления + отзыв оригинала',
  [DocumentTemplateType.APPLICATION_ENROLL_REJECT]:
    'Заявление об отказе от зачисления',
  [DocumentTemplateType.APPLICATION_ORIGINAL_WITHDRAW]:
    'Заявление об отзыве оригинала',
  [DocumentTemplateType.APPLICATION_DOCUMENTS_ORIGINAL_WITHDRAW]:
    'Заявление об отзыве документов + отзыв оригинала',
};

const DocumentTemplatesPage = () => {
  const { elements, page, size, totalPages } = useAppSelector(
    selectUserManagementState
  );

  return (
    <main className="moderator-contract-page">
      <h1 className="moderator-contract-page__title">Все шаблоны документов</h1>
      {Object.entries(documentTemplateTypesToStrings).map(
        ([documentTemplateType, title]) => (
          <AdminDocumentTemplate
            title={title}
            documentTemplateType={documentTemplateType as DocumentTemplateType}
          />
        )
      )}
    </main>
  );
};

export default DocumentTemplatesPage;
