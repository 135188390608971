import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import { useEnrolleeUserId } from 'hooks/questionnaire/common/draft/useQuestionnaire';
import terms from 'i18n';
import { FileId } from 'models/files';
import { createToast, showModal } from 'redux/actions';
import {
  useLazyDownloadFileQuery,
  usePreviewFilesQuery,
} from 'redux/api/common/files-api';
import {
  useGetAdditionalFilesIdsQuery,
  useUpdateAdditionalFilesIdsMutation,
} from 'redux/api/questionnaire/questionnaire-api';
import { downloadFile } from 'utils/common';

import { selectUserRole } from '../../redux/selectors';
import { selectCurrentApplicationUser } from '../../redux/selectors/staff/applications';

const useAdditionalFiles = () => {
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(selectUserRole);

  const userForStaff = useAppSelector(selectCurrentApplicationUser);

  const enrolleeUserId = useEnrolleeUserId();
  const userId = userForStaff?.id ?? enrolleeUserId;

  const { data: fileIds = [], refetch: refetchFileIds } =
    useGetAdditionalFilesIdsQuery(userId!, {
      skip: userId === null || userId === undefined,
    });

  const { data: filePreviews = [] } = usePreviewFilesQuery(
    {
      userId: userId!,
      ids: fileIds,
    },
    {
      skip: userId === null || fileIds.length === 0,
    }
  );

  const [updateAdditionalFilesIds] = useUpdateAdditionalFilesIdsMutation();
  const [download] = useLazyDownloadFileQuery();

  const onOpenModal = () => {
    dispatch(
      showModal({ name: 'ADD_FILE_MODAL', data: { refetchFileIds, userId } })
    );
  };

  const onEditFile = (name, fileId, date) => {
    dispatch(
      showModal({
        name: 'EDIT_FILE_MODAL',
        data: { name, fileId, date, refetchFileIds, userId },
      })
    );
  };

  const onDelete = (fileId: FileId) => {
    if (!userId || !fileIds) return;
    updateAdditionalFilesIds({
      userId,
      files: fileIds.filter(id => id !== fileId),
    })
      .unwrap()
      .then(() => dispatch(createToast(terms.FILE_DELETED, 'success')))
      .catch(() => dispatch(createToast(terms.ERROR_HAS_OCCURRED, 'danger')))
      .finally(() => refetchFileIds());
  };

  const onDownload = (fileId: FileId, filename?: string) => {
    if (!userId) return;
    download({
      userId,
      fileId,
    })
      .unwrap()
      .then(result => {
        downloadFile(new File([result], `${filename}`));
      })
      .catch(() => dispatch(createToast(terms.DOWNLOAD_FILE_ERROR, 'danger')));
  };

  return {
    filePreviews,
    onDelete,
    onDownload,
    onOpenModal,
    onEditFile,
  };
};

export default useAdditionalFiles;
