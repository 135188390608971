import { useMemo } from 'react';

import {
  INTERNATIONAL_RUSSIAN_PASSPORT_ENUM,
  RUSSIAN_PASSPORT_ENUM,
} from '../../../models/enum';
import { useGetIdentificationDocumentTypesQuery } from '../../../redux/api/common/enum-api';
import useLazyEnumSelectProps from '../../common/useI18nEnumSelectProps';

export const useGetIdentificationDocumentTypeOptions = (
  isRussianEnrollee?: boolean
) => {
  const identificationDocumentTypes = useLazyEnumSelectProps(
    useGetIdentificationDocumentTypesQuery
  );

  const options = useMemo(() => {
    return isRussianEnrollee
      ? identificationDocumentTypes.options.filter(
          value =>
            value.value === RUSSIAN_PASSPORT_ENUM ||
            value.value === INTERNATIONAL_RUSSIAN_PASSPORT_ENUM
        )
      : identificationDocumentTypes.options;
  }, [identificationDocumentTypes, isRussianEnrollee]);

  return {
    options,
    error: identificationDocumentTypes.error,
    isLoading: identificationDocumentTypes.isLoading,
  };
};
