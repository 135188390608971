import React, { FC } from 'react';

import { useParams } from 'react-router-dom';

import Checkbox from 'components/common/ui-kit/Checkbox';
import Input from 'components/common/ui-kit/Input';
import ProfileSection from 'components/profile/ProfileSection';
import FillingStatementsForm from 'components/statements/FillingStatementsForm';
import useAppSelector from 'hooks/common/useAppSelector';
import usePrivilegeForm from 'hooks/privilege/usePrivilegeForm';
import terms from 'i18n';

import LazyFileUploader from '../../../components/common/ui-kit/LazyFileUploader';
import { PrivilegesTableHead } from '../../../components/statements/PrivilegesTable/view';
import { ApplicationEducationLevel } from '../../../models/applications/enum';
import {
  selectCurrentEducationLevel,
  selectIsApplicationReadonly,
} from '../../../redux/selectors/enrollee/application';
import { useIsImpersonate } from '../../../utils/domain/impersonate';
import { PrivilegesTableWrapper } from './ui/PrivilegesTableWrapper';

const benefitsHead: PrivilegesTableHead = {
  typeLgota: terms.TYPE_LGOTA,
  typeDoc: terms.CONFIRMATION_DOCUMENT,
  number: terms.SERIES_AND_NUMBER,
  dateIssue: terms.DATE_ISSUE,
};

const prerogativesHead: PrivilegesTableHead = {
  typeLgota: terms.TYPE_PREEMPTIVE,
  typeDoc: terms.CONFIRMATION_DOCUMENT,
  number: terms.SERIES_AND_NUMBER,
  dateIssue: terms.DATE_ISSUE,
};

const quotasHead: PrivilegesTableHead = {
  typeLgota: terms.TYPE_INDIVIDUAL_QUOTA,
  typeDoc: terms.CONFIRMATION_DOCUMENT,
  number: terms.SERIES_AND_NUMBER,
  dateIssue: terms.DATE_ISSUE,
};

const PrivilegesPage: FC = () => {
  const { applicationId } = useParams();

  const isImpersonate = useIsImpersonate();
  const readOnly =
    useAppSelector(selectIsApplicationReadonly) && !isImpersonate;

  const {
    watch,
    fields,
    onSubmit,
    benefits,
    prerogatives,
    individualQuotas,
    hasSpecialConditionsForEntranceTest,
    isDisabledPerson,
    onDeletePrivilegeClick,
    onDownloadDocumentsClick,
    onAddBenefitButtonClick,
    onAddPrerogativeButtonClick,
    onEditPrerogativeButtonClick,
    onEditBenefitButtonClick,
    onAddIndividualQuotaButtonClick,
    onEditIndividualQuotaButtonClick,
    specialConditionFileProps,
    militarySupportFileProps,
    disabledPersonFileProps,
    formState: { errors },
  } = usePrivilegeForm({
    applicationId: Number(applicationId),
  });

  const trainingLevel = useAppSelector(selectCurrentEducationLevel);

  return (
    <FillingStatementsForm
      onSubmit={e => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <div className="privileges-page">
        {trainingLevel !== ApplicationEducationLevel.MASTER && (
          <div>
            {trainingLevel !== ApplicationEducationLevel.SECONDARY && (
              <PrivilegesTableWrapper
                title={terms.LGOTS}
                onAddButtonClick={onAddBenefitButtonClick}
                privileges={benefits}
                readOnly={readOnly}
                head={benefitsHead}
                onDelete={onDeletePrivilegeClick}
                onDownload={onDownloadDocumentsClick}
                onEdit={onEditBenefitButtonClick}
              />
            )}

            <PrivilegesTableWrapper
              title={terms.PREEMPTIVE_RIGHTS}
              onAddButtonClick={onAddPrerogativeButtonClick}
              head={prerogativesHead}
              privileges={prerogatives}
              readOnly={readOnly}
              onDelete={onDeletePrivilegeClick}
              onEdit={onEditPrerogativeButtonClick}
              onDownload={onDownloadDocumentsClick}
            />

            {trainingLevel !== ApplicationEducationLevel.SECONDARY && (
              <PrivilegesTableWrapper
                title={terms.INDIVIDUAL_QUOTA}
                onAddButtonClick={onAddIndividualQuotaButtonClick}
                head={quotasHead}
                privileges={individualQuotas}
                readOnly={readOnly}
                onDelete={onDeletePrivilegeClick}
                onEdit={onEditIndividualQuotaButtonClick}
                onDownload={onDownloadDocumentsClick}
              />
            )}

            <div className="entry-exams__prompt directory-hint">
              <p>{terms.ACHIEVEMENTS_HINT}</p>
            </div>

            <div>
              {trainingLevel !== ApplicationEducationLevel.SECONDARY && (
                <Checkbox
                  disabled={readOnly}
                  text={
                    'Нужны специальные условия для проведения вступительных испытаний'
                  }
                  {...fields.hasSpecialConditionsForEntranceTest}
                />
              )}

              {hasSpecialConditionsForEntranceTest && (
                <div className="special-docs__container">
                  <Input
                    disabled={readOnly}
                    className="profile-form__field profile-form__field--fb-100"
                    {...fields.specialConditions}
                    placeholder="Специальные условия"
                    markRequired={true}
                    error={errors.specialConditions?.message}
                  />

                  <ProfileSection title="Копия документа" markRequired={true}>
                    <LazyFileUploader
                      {...specialConditionFileProps}
                      className="profile-form__file-uploader lgota-file-uploader"
                    />
                  </ProfileSection>
                </div>
              )}
            </div>
          </div>
        )}
        <div>
          {trainingLevel !== ApplicationEducationLevel.SECONDARY &&
            trainingLevel !== ApplicationEducationLevel.MASTER && (
              <Checkbox
                disabled={readOnly}
                text={
                  'Имею основание для сдачи экзамена в СПбПУ (основание - справка об инвалидности)'
                }
                {...fields.isDisabledPerson}
              />
            )}

          {isDisabledPerson && (
            <div className="special-docs__container">
              <ProfileSection title="Копия документа" markRequired={true}>
                <LazyFileUploader
                  className="profile-form__file-uploader lgota-file-uploader"
                  {...disabledPersonFileProps}
                />
              </ProfileSection>
            </div>
          )}

          <div className="special-docs__container">
            {trainingLevel !== ApplicationEducationLevel.SECONDARY && (
              <Checkbox
                disabled={readOnly}
                text="Поступаю на основании Особенностей приема в 2024 году"
                {...fields.hasMilitarySupport}
              />
            )}
            {watch('hasMilitarySupport') && (
              <div className="special-docs__container">
                <ProfileSection
                  title="Подтверждающий документ"
                  markRequired={watch('hasMilitarySupport')}
                >
                  <LazyFileUploader
                    {...militarySupportFileProps}
                    className="profile-form__file-uploader lgota-file-uploader"
                  />
                </ProfileSection>
              </div>
            )}
          </div>
        </div>
      </div>
    </FillingStatementsForm>
  );
};

export default PrivilegesPage;
