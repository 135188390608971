import React, { createContext, useContext } from 'react';

import useFillingStatementsProvideRoutes from 'hooks/statements/useFillingStatementsProvideRoutes';

import { fillingStatementsRoutes } from '../../config/routes/filling-statements-routes';

type FillingStatementsRoutesContextProps = ReturnType<
  typeof useFillingStatementsProvideRoutes
>;

const FillingStatementsRoutesContext =
  createContext<FillingStatementsRoutesContextProps>(
    {} as FillingStatementsRoutesContextProps
  );

export const FillingStatementsRoutesContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <FillingStatementsRoutesContext.Provider
      value={useFillingStatementsProvideRoutes(fillingStatementsRoutes)}
    >
      {children}
    </FillingStatementsRoutesContext.Provider>
  );
};

export default () => {
  return useContext(FillingStatementsRoutesContext);
};
