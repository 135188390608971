import { useMemo } from 'react';

import { matchPath, useLocation } from 'react-router-dom';

import useChatIsEnrollee from 'hooks/chat/useChatIsEnrollee';
import useChatIsStaff from 'hooks/chat/useChatIsStaff';
import useAppSelector from 'hooks/common/useAppSelector';
import { selectUserChats } from 'redux/selectors/common/chat';

const isChatPath = (path: string) =>
  [
    '/admin/personal-information/:userId/*',
    '/admin/statements/:id/*',
    '/admin/contracts/:id/*',
  ].some(profilePath => matchPath(profilePath, path));

const useChatVisible = () => {
  const { pathname } = useLocation();
  const chats = useAppSelector(selectUserChats);
  const isEnrollee = useChatIsEnrollee();
  const isChatStaff = useChatIsStaff();

  return useMemo(
    () => (isEnrollee || (isChatStaff && isChatPath(pathname))) && !!chats,
    [isEnrollee, isChatStaff, pathname, chats]
  );
};

export default useChatVisible;
