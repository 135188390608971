import React, { FC, useState } from 'react';

import classnames from 'classnames';

import { ReactComponent as TabArrowDown } from 'assets/images/icons/tab-arrow-down.svg';
import Button from 'components/common/ui-kit/Button';

type AccordionTabProps = {
  title: string;
  children?: React.ReactNode;
};

const AccordionTab: FC<AccordionTabProps> = ({ title, children }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="accordion-tab">
      <div className="accordion-tab__header" onClick={() => setOpen(!open)}>
        <h2 className="accordion-tab__header_title">{title}</h2>
        <Button
          className={classnames('accordion-tab__header_btn', open && 'rotate')}
        >
          <TabArrowDown />
        </Button>
      </div>
      <div className={classnames('accordion-tab__body', open && 'show')}>
        {children}
      </div>
    </div>
  );
};

export default AccordionTab;
