import React, { FC } from 'react';

import { useParams } from 'react-router-dom';

import AchievementsForm from 'components/statements/AchievementsForm';
import FillingStatementsForm from 'components/statements/FillingStatementsForm';
import OlympiadsForm from 'components/statements/OlympiadsForm';
import { useDownloadFile } from 'hooks/common/api/useDownloadFile';
import useAppDispatch from 'hooks/common/useAppDispatch';
import { useEnrolleeUserId } from 'hooks/questionnaire/common/draft/useQuestionnaire';
import useFillingStatementsRoutesContext from 'hooks/statements/useFillingStatementsRoutesContext';
import terms from 'i18n';
import { showModal } from 'redux/actions';
import { privilegesApi } from 'redux/api/applications/privileges-api';
import { useLazyPreviewFilesQuery } from 'redux/api/common/files-api';

import Button from '../../../components/common/ui-kit/Button';
import Input from '../../../components/common/ui-kit/Input';
import useAppSelector from '../../../hooks/common/useAppSelector';
import { useAchievementsPageForm } from '../../../hooks/statements/achievements/useAchievementsPageForm';
import {
  ApplicationEducationLevel,
  Trajectory,
} from '../../../models/applications/enum';
import {
  selectCurrentEducationLevel,
  selectCurrentTrajectory,
  selectIsApplicationReadonly,
} from '../../../redux/selectors/enrollee/application';
import { useIsImpersonate } from '../../../utils/domain/impersonate';
import { PrivilegesTableWrapper } from '../PrivilegesPage/ui/PrivilegesTableWrapper';

const AchievementsPage: FC = () => {
  const { applicationId } = useParams();

  const isImpersonate = useIsImpersonate();
  const userId = useEnrolleeUserId();
  const readOnly =
    useAppSelector(selectIsApplicationReadonly) && !isImpersonate;
  const { onNextPageNavigate } = useFillingStatementsRoutesContext();

  const trainingLevel = useAppSelector(selectCurrentEducationLevel);
  const trajectroy = useAppSelector(selectCurrentTrajectory);

  const dispatch = useAppDispatch();
  const {
    isFormVisible,
    fields,
    onSubmit,
    achievements,
    olympiads,
    privileges,
    refetchAchievementsForm,
  } = useAchievementsPageForm(Number(applicationId));

  const onOpenModalClick = (privilege?) =>
    dispatch(
      showModal({
        name: 'HALLMARK_MODAL',
        data: {
          applicationId,
          userId,
          privilege,
        },
      })
    );

  const [previewFiles] = useLazyPreviewFilesQuery();
  const download = useDownloadFile();

  const onDownloadDocumentsClick = (documentId: number) => {
    if (!userId) return;
    previewFiles({ userId, ids: [documentId] })
      .unwrap()
      .then(files => {
        if (!files[0]) return;
        download({
          userId,
          fileId: documentId,
          fileName: files[0].originalName,
        });
      });
  };
  const [deletePrivilege] = privilegesApi.useDeletePrivilegeMutation();

  const onDeletePrivilegeClick = (id: number) => deletePrivilege(id);

  return (
    <div>
      <FillingStatementsForm
        onSubmit={e => {
          e.preventDefault();
          onNextPageNavigate();
        }}
        className="achievements"
      >
        {trainingLevel !== ApplicationEducationLevel.SECONDARY &&
          (trainingLevel === ApplicationEducationLevel.MASTER &&
          trajectroy === Trajectory.LOCAL ? (
            <PrivilegesTableWrapper
              title={'Конкурс портфолио / Олимпиада "Я-профессионал"'}
              onAddButtonClick={() => onOpenModalClick()}
              onEdit={privilege => onOpenModalClick(privilege)}
              onDelete={onDeletePrivilegeClick}
              onDownload={onDownloadDocumentsClick}
              privileges={privileges}
              readOnly={readOnly}
              head={{
                typeLgota: terms.TYPE_ACHIEVEMENT,
                typeDoc: terms.CONFIRMATION_DOCUMENT,
                number: terms.SERIES_AND_NUMBER,
                dateIssue: terms.DATE_ISSUE,
              }}
            />
          ) : (
            <OlympiadsForm
              needToFetchOlympiads={false}
              olympiads={olympiads}
              refetchAchievementsForm={refetchAchievementsForm}
            />
          ))}
        <AchievementsForm
          needToFetchAchievements={false}
          achievements={achievements}
          refetchAchievementsForm={refetchAchievementsForm}
        />

        {isFormVisible && (
          <>
            <h4 className="achievements__title">Дополнительная информация</h4>
            <Input
              className="profile-form__field"
              placeholder="Количество публикаций"
              type="number"
              {...fields.publicationsAmount}
              disabled={readOnly}
            />

            <Input
              className="profile-form__field"
              placeholder="Количество изобретений"
              type="number"
              {...fields.inventionsAmount}
              disabled={readOnly}
            />

            <Button
              type="button"
              className="achievements__button"
              onClick={onSubmit}
              disabled={readOnly}
            >
              Добавить
            </Button>
          </>
        )}
      </FillingStatementsForm>
    </div>
  );
};

export default AchievementsPage;
