import React, { useEffect, useState } from 'react';

import useAppDispatch from 'hooks/common/useAppDispatch';
import terms from 'i18n';
import { createToast, hideModal } from 'redux/actions';
import {
  useGetAdditionalFilesIdsQuery,
  useUpdateAdditionalFilesIdsMutation,
} from 'redux/api/questionnaire/questionnaire-api';

import { UserRole } from '../../models/user';
import { selectUserRole } from '../../redux/selectors';
import useChatSendMessage from '../chat/useChatSendMessage';
import useAppSelector from '../common/useAppSelector';
import { useLazyUploadFiles } from '../files/useLazyUploadFiles';

const useAdditionalFileUploading = (
  name,
  fileId,
  userId,
  date,
  refetchFileIds
) => {
  const dispatch = useAppDispatch();
  const role = useAppSelector(selectUserRole);
  const sendMessageToChat = useChatSendMessage();

  const [filenameError, setFilenameError] = useState('');
  const [fileError, setFileError] = useState('');
  const [filename, setFilename] = useState(name);

  const { uploadFiles, filesMeta, ...fileAreaProps } = useLazyUploadFiles({
    filename,
    initialFileIds: fileId,
    userId,
  });

  const [updateAdditionalFilesIds] = useUpdateAdditionalFilesIdsMutation();
  const { data: fileIds = [] } = useGetAdditionalFilesIdsQuery(userId!, {
    skip: userId === null,
  });

  const isEditing = Boolean(name);

  const onSave = () => {
    if (!role || !userId || filesMeta.length === 0) return;

    if (!filename) {
      dispatch(createToast(terms.FILENAME_REQUIRED, 'danger'));
      setFilenameError(terms.REQUIRED_FIELD);
      return;
    } else {
      setFilenameError('');
    }

    if (isEditing) {
      uploadFiles()
        .then(newFileId =>
          updateAdditionalFilesIds({
            userId,
            files: fileIds.filter(id => id !== fileId).concat(newFileId),
          }).unwrap()
        )
        .then(() => {
          dispatch(createToast(terms.ADDITIONAL_FILE_UPLOADED, 'success'));
          dispatch(hideModal());
        });
    } else {
      uploadFiles()
        .then(newFileId =>
          updateAdditionalFilesIds({
            userId,
            files: fileIds.concat(newFileId),
          }).unwrap()
        )
        .then(() => {
          dispatch(createToast(terms.ADDITIONAL_FILE_UPLOADED, 'success'));
          if (role === UserRole.USER)
            sendMessageToChat(terms.USER_ADDED_ADDITIONAL_FILE, []);
          refetchFileIds?.();
          dispatch(hideModal());
        });
    }
  };

  const onFilenameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFilename(value);
    if (value && filenameError) {
      setFilenameError('');
    }
  };

  useEffect(() => {
    if (fileError && filesMeta.length) {
      setFileError('');
    }
  }, [filesMeta.length, fileError]);

  return {
    inputProps: {
      value: filename,
      error: filenameError,
      onChange: onFilenameChange,
    },
    uploaderProps: {
      filesMeta,
      ...fileAreaProps,
    },
    fileError,
    onSave,
  };
};

export default useAdditionalFileUploading;
