import React, { FC } from 'react';

import terms, { getTranslationOrEmptyString } from 'i18n';

import { ContractThirdPartyPayerData } from '../../../models/applications/contracts';

type ModeratorContractIdInfoNotEntrantsProps = {
  readonly contractNotEntrants?: ContractThirdPartyPayerData;
};

const ModeratorContractIdInfoNotEntrants: FC<
  ModeratorContractIdInfoNotEntrantsProps
> = ({ contractNotEntrants }) => (
  <div className="moderator-contract-info__inline">
    <div className="moderator-contract-info__block">
      <h2>{terms.PAYER_INFORMATION}:</h2>
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.FIO}:</p>
        <span>
          {`${contractNotEntrants?.lastName || ''} ${
            contractNotEntrants?.firstName || ''
          } ${contractNotEntrants?.middleName || ''}`}
        </span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.DATE_OF_BIRTH}:</p>
        <span>{contractNotEntrants?.birthDate || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.PHONE_NUMBERS}:</p>
        <span>{contractNotEntrants?.phone || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.REGISTRATION_ADDRESS}:</p>
        <span>{contractNotEntrants?.addressRegistration || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.ACTUAL_ADDRESS}:</p>
        <span>{contractNotEntrants?.addressResidential || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.PLACE_OF_BIRTH}:</p>
        <span>{contractNotEntrants?.placeOfBirth || '-'}</span>
      </div>
    </div>
    <div className="moderator-contract-info__block">
      <h2>{terms.PAYER_PASSPORT}:</h2>

      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.DOCUMENT_NAME}:</p>
        <span>
          {getTranslationOrEmptyString(contractNotEntrants?.docType) || '-'}
        </span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.SERIES}:</p>
        <span>{contractNotEntrants?.series || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.NUMBER}:</p>
        <span>{contractNotEntrants?.number || '-'}</span>
      </div>
      {contractNotEntrants?.divisionCode && (
        <div className="moderator-contract-info__block-row juridical">
          <p>{terms.DIVISION_CODE}:</p>
          <span>{contractNotEntrants?.divisionCode || '-'}</span>
        </div>
      )}
      {contractNotEntrants?.docExpirationDate && (
        <div className="moderator-contract-info__block-row juridical">
          <p>{terms.VALID_UNTIL}:</p>
          <span>{contractNotEntrants?.docExpirationDate || '-'}</span>
        </div>
      )}
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.DATE_OF_ISSUE}:</p>
        <span>{contractNotEntrants?.issuedAt || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.ISSUING_AUTHORITY}:</p>
        <span>{contractNotEntrants?.issuedBy || '-'}</span>
      </div>
    </div>
  </div>
);

export default ModeratorContractIdInfoNotEntrants;
