import { useCallback, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { DirectionRequestType } from 'models/applications/applications';

import terms from '../../i18n';
import { createToast } from '../../redux/actions';
import { useCreateApplicationMutation } from '../../redux/api/applications/applications-api';
import { applicationsInfoApi } from '../../redux/api/applications/applications-info-api';
import {
  selectCurrentEducationalInfo,
  selectCurrentEducationLevel,
  selectCurrentTrajectory,
  selectDirectionsRequestType,
} from '../../redux/selectors/enrollee/application';
import useAppDispatch from '../common/useAppDispatch';
import useAppSelector from '../common/useAppSelector';

const useEducationalInfoPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id: userId } = useAppSelector(state => state.user);

  const trajectory = useAppSelector(selectCurrentTrajectory);
  const trainingLevel = useAppSelector(selectCurrentEducationLevel);
  const educationalInfo = useAppSelector(selectCurrentEducationalInfo);
  const directionsRequestType = useAppSelector(selectDirectionsRequestType);

  const [getAvailableEducationalInformation, { data: educationalInfos }] =
    applicationsInfoApi.useLazyGetUserEducationalInformationQuery();

  const [createApplication, { data: application, isSuccess, isLoading }] =
    useCreateApplicationMutation();

  const onSubmit = useCallback(() => {
    if (!userId || !educationalInfos) return;

    if (
      trainingLevel &&
      trajectory &&
      educationalInfo &&
      directionsRequestType &&
      educationalInfos.findIndex(
        el => el.educationProfileId === educationalInfo.educationProfileId
      ) !== -1
    ) {
      createApplication({
        userId,
        trajectory,
        educationLevel: trainingLevel,
        educationBasis:
          directionsRequestType === DirectionRequestType.BUDGET
            ? 'BUDGET'
            : directionsRequestType === DirectionRequestType.CONTRACT
            ? 'CONTRACT'
            : undefined,
        educationalProfileId: educationalInfo.educationProfileId,
      })
        .unwrap()
        .catch(response => {
          dispatch(createToast('Ошибка создания заявления.', 'danger'));
        });
    } else {
      dispatch(
        createToast(
          terms.CANNOT_CREATE_NEW_APPLICATION_WITHOUT_EDUCATIONAL_DOCUMENT,
          'danger'
        )
      );
    }
  }, [
    userId,
    educationalInfo,
    educationalInfos,
    directionsRequestType,
    trainingLevel,
    trajectory,
    dispatch,
    createApplication,
  ]);

  useEffect(() => {
    if (userId)
      if (trajectory && trainingLevel) {
        getAvailableEducationalInformation({
          userId,
          educationalLevel: trainingLevel,
        });
      } else {
        navigate('/application');
      }
  }, [userId, trajectory, trainingLevel]);

  useEffect(() => {
    if (isSuccess && application) {
      navigate(`../${application.id}`);
    }
  }, [isSuccess]);

  return {
    onSubmit,
    isSubmitButtonDisabled: educationalInfo === undefined || isLoading,
  };
};

export default useEducationalInfoPage;
