import { NavRoute } from 'types/custom';

import { ModeratorStatementsSectionKey } from 'models/moderator';

export const moderatorStatementsRoutes: NavRoute<ModeratorStatementsSectionKey>[] =
  [
    {
      to: 'basic-data',
      key: 'basicData',
      title: 'Основные данные',
      text: '',
      status: 'not visited',
    },
    {
      to: 'legal-representative',
      key: 'legalRepresentative',
      title: 'Законный представитель',
      text: '',
      status: 'not visited',
    },
    {
      to: 'passport-data',
      key: 'passportData',
      title: 'Паспортные данные',
      text: '',
      status: 'not visited',
    },
    {
      to: 'visa',
      key: 'visa',
      title: 'Виза',
      text: '',
      status: 'not visited',
    },
    {
      to: 'registration-address',
      key: 'realAddress',
      title: 'Адрес постоянной регистрации',
      text: '',
      status: 'not visited',
    },
    {
      to: 'actual-address',
      key: 'actualAddress',
      title: 'Адрес фактического проживания',
      text: '',
      status: 'not visited',
    },
    {
      to: 'educational-background',
      key: 'educationalBackground',
      title: 'Сведения об образовании',
      text: '',
      status: 'not visited',
    },
    {
      to: 'documents',
      key: 'documents',
      title: 'Копии документов',
      text: '',
      status: 'not visited',
    },
    {
      to: 'hallmarks',
      key: 'hallmarks',
      title: 'Отличительные признаки',
      text: '',
      status: 'not visited',
    },
    {
      to: 'exam-results',
      key: 'examResults',
      title: 'Результаты ЕГЭ / Расписание ВИ',
      text: '',
      status: 'not visited',
    },
    {
      to: 'directions',
      key: 'directions',
      title: 'Направления поступления',
      text: '',
      status: 'not visited',
    },
    {
      to: 'target-contract',
      key: 'targetContract',
      title: 'Целевой договор',
      text: '',
      status: 'not visited',
    },
    {
      to: 'additional-forms',
      key: 'additionalForms',
      title: 'Дополнительные файлы',
      text: '',
      status: 'not visited',
    },
    {
      to: 'downloads',
      key: 'downloads',
      title: 'Файлы для скачивания',
      text: '',
      status: 'not visited',
    },
    {
      to: 'expert-examination',
      key: 'expert-examination',
      title: 'ЦЭИДО',
      text: '',
      status: 'not visited',
    },
    {
      to: 'duplicates',
      key: 'duplicates',
      title: 'Дубликаты',
      text: '',
      status: 'not visited',
    },
    {
      to: 'epgu-info',
      key: 'epguInfo',
      title: 'ЕПГУ',
      text: '',
      status: 'not visited',
    },
    {
      to: '1c',
      key: '1c',
      title: 'Выгрузка в 1С',
      text: '',
      status: 'not visited',
      hidden: true,
    }
  ];
