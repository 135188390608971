import { useParams } from 'react-router-dom';

import { useGetCountriesQuery } from '../../../redux/api/common/enum-api';
import { staffApplicationsApi } from '../../../redux/api/staff/staff-applications-api';

export const useApplicationDuplicatesPage = () => {
  const { id } = useParams();

  const { data: countries } = useGetCountriesQuery();
  const { data: duplicates } =
    staffApplicationsApi.useGetApplicationsDuplicatesQuery(Number(id));

  return { duplicates, countries };
};
