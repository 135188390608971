import React, { FC } from 'react';

import TemplateAuthForm from 'components/auth/TemplateAuthForm';
import Button from 'components/common/ui-kit/Button';
import Input from 'components/common/ui-kit/Input';
import terms from 'i18n';

import useResetPasswordForm from '../../../hooks/auth/useResetPasswordForm';

type ResetPasswordFormProps = {
  title: string;
  code: string;
  userId: string;
};

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({
  title,
  code,
  userId,
}) => {
  const {
    formState: { errors },
    fields,
    onSubmit,
  } = useResetPasswordForm(code, Number(userId));

  return (
    <TemplateAuthForm title={title}>
      <form onSubmit={onSubmit}>
        <div className="auth-form__body">
          <div className="auth-form__fields">
            <Input
              className="change-password-modal__field change-password-modal__field--min-h-90"
              placeholder={terms.NEW_PASSWORD}
              hint={terms.PASS_HINT}
              enableSwitcher={true}
              {...fields.newPassword}
              error={errors.newPassword?.message}
            />
            <Input
              className="change-password-modal__field"
              placeholder={terms.REPEAT_NEW_PASSWORD}
              enableSwitcher={true}
              {...fields.newPasswordConfirmation}
              error={errors.newPasswordConfirmation?.message}
            />
          </div>
        </div>
        <div className="auth-form__buttons">
          <Button theme="success" className="auth-form__button">
            {terms.SAVE}
          </Button>
        </div>
      </form>
    </TemplateAuthForm>
  );
};

export default ResetPasswordForm;
