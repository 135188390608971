import React, { FC } from 'react';

import { Navigate } from 'react-router-dom';

import Loader from 'components/common/ui-kit/Loader';
import useAuthInitialization from 'hooks/auth/useAuthInitialization';
import useAppSelector from 'hooks/common/useAppSelector';
import { selectIsUserCommonUser } from 'redux/selectors';
import { ChildrenProps } from 'utils/default-props';

const RequireAuth: FC<ChildrenProps> = ({ children }) => {
  const { token, loading } = useAuthInitialization();
  const isUserCommonUser = useAppSelector(selectIsUserCommonUser);

  if (loading) {
    return <Loader />;
  }

  if (!token) {
    return <Navigate to="/sign-in" />;
  }

  if (!isUserCommonUser) {
    return <Navigate to="/admin" />;
  }

  return <>{children}</>;
};

export default RequireAuth;
