import React, { FC, useEffect } from 'react';

import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import { ApplicationEducationLevel } from 'models/applications/enum';
import { EnrollListsCommandButtons } from 'pages/Enrollment/EnrollListsPage/legacy/components/EnrollListsCommandButtons';
import { EnrollListsFilters } from 'pages/Enrollment/EnrollListsPage/legacy/components/EnrollListsFilters';
import { EnrollListsTable } from 'pages/Enrollment/EnrollListsPage/legacy/components/EnrollListsTable';
import { useGetAdmissionsListQuery } from 'redux/api/common/admission-list-api';
import { enrollListsSlice } from 'redux/slices/common/enroll-lists-slice';

type EnrollListsPageTypes = {
  readonly educationLevel: ApplicationEducationLevel;
};

const EnrollListsPage: FC<EnrollListsPageTypes> = ({ educationLevel }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(enrollListsSlice.actions.setEducationLevel(educationLevel));
  }, []);

  const {
    currentEducationLevel,
    currentEducationFormId,
    currentPaymentFormId,
    currentDirectionId,
  } = useAppSelector(state => state.enrollList);

  const { data: admissions = [] } = useGetAdmissionsListQuery(
    {
      applicationEducationLevel: currentEducationLevel,
      directionEducationFormId: currentEducationFormId,
      directionPaymentFormId: currentPaymentFormId,
      directionId: currentDirectionId,
    },
    {
      skip:
        !currentEducationLevel ||
        currentEducationFormId === undefined ||
        currentPaymentFormId === undefined ||
        currentDirectionId === undefined,
    }
  );

  return (
    <main className={'Enroll-lists-page'}>
      <h1 className={'Enroll-lists-page__title'}>
        Фильтры по спискам поступающих
      </h1>
      <EnrollListsCommandButtons />
      <EnrollListsFilters />

      {currentEducationLevel && currentEducationFormId && currentPaymentFormId && (
        <>
          <h1 className={'Enroll-lists-page__title'}>Список</h1>
          <EnrollListsTable admissions={admissions} />
        </>
      )}
    </main>
  );
};

export default EnrollListsPage;
