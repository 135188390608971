import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Contract, ContractView } from '../../../models/applications/contracts';
import { Direction } from '../../../models/applications/directions';
import { User } from '../../../models/enrollee';
import { Questionnaire } from '../../../models/questionnaire/questionnaire';

export type StaffContractsState = {
  readonly filters: object;
  readonly page: number;
  readonly size: number;
  readonly orderBy?: string;
  readonly orderDirection?: 'ASC' | 'DESC';
  readonly totalPages: number;
  readonly totalElements: number;
  readonly elements: ContractView[];
  readonly currentContract?: {
    readonly questionnaire: Questionnaire;
    readonly user: User;
    readonly contract: Contract;
    readonly direction: Direction;
  };
};

const initialState: StaffContractsState = {
  filters: {},
  page: 1,
  size: 10,
  orderBy: undefined,
  orderDirection: undefined,
  totalPages: 0,
  totalElements: 0,
  elements: [],
  currentContract: undefined,
};

export const staffContractsSlice = createSlice({
  name: 'staffContractsSlice',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    cleanFilters: state => {
      state.filters = {};
    },

    setOrderDirection: (state, action) => {
      if (action.payload) {
        state.orderDirection = action.payload;
      } else {
        state.orderDirection = undefined;
      }
    },

    setOrderBy: (state, action) => {
      if (action.payload) {
        state.orderBy = action.payload;
      } else {
        state.orderBy = undefined;
      }
    },

    setContracts: (
      state,
      action: PayloadAction<{
        readonly contracts: ContractView[];
        readonly page: number;
        readonly totalPages: number;
        readonly size: number;
        readonly totalElements: number;
      }>
    ) => {
      state.elements = action.payload.contracts;
      state.size = action.payload.size;
      state.totalPages = action.payload.totalPages;
      state.page = action.payload.page;
      state.totalElements = action.payload.totalElements;
    },

    setPage: (state, action) => {
      state.page = action.payload;
    },

    setSize: (state, action) => {
      state.size = action.payload;
    },

    setCurrentContract: (
      state,
      action: PayloadAction<{
        readonly contract: Contract;
        readonly questionnaire: Questionnaire;
        readonly user: User;
        readonly direction: Direction;
      }>
    ) => {
      state.currentContract = {
        ...action.payload,
      };
    },

    updateContract: (state, action) => {
      if (state.currentContract) {
        state.currentContract.contract = action.payload;
      }
    },

    cleanCurrentContract: state => {
      state.currentContract = undefined;
    },
  },
});

export default staffContractsSlice.reducer;
