import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import useAppDispatch from 'hooks/common/useAppDispatch';
import terms, { isRussianLocale } from 'i18n';
import { createToast } from 'redux/actions';
import { getMobileValidators, moreThan18age } from 'utils/form-validators';

// import { DocumentTemplateType } from '../../../models/applications/application-documents';
import { LegalRepresentativeDraft } from '../../../models/questionnaire/questionnaire';
import { filesApi } from '../../../redux/api/common/files-api';
// import { downloadFile } from '../../../utils/common';
import { useGeneralDataDraft } from '../common/draft/useGeneralDataDraft';
import { useLegalRepresentativeDraft } from './useLegalRepresentativeDraft';

export type LegalRepresentativeForm = LegalRepresentativeDraft;

const useLegalRepresentativeForm = (onSuccess?: () => void) => {
  const dispatch = useAppDispatch();

  const { draft, saveDraft, ...draftInfo } = useLegalRepresentativeDraft();

  const [downloadLegalRepresentativeAgreement] =
    filesApi.useLazyDownloadTemplateQuery();

  const { draft: generalDataDraft } = useGeneralDataDraft();

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    getValues,
    watch,
    reset,
    ...rest
  } = useForm<LegalRepresentativeForm>();

  useEffect(() => {
    if (draft) {
      reset(draft);
    }
  }, [draft]);

  const [required, setRequired] = useState(true);

  useEffect(() => {
    if (generalDataDraft?.birthday) {
      setRequired(!moreThan18age(generalDataDraft.birthday));
    }
  }, [generalDataDraft]);

  const requiredParams = {
    required: { value: required, message: terms.REQUIRED_FIELD },
  };

  const fields = {
    surname: register('surname', requiredParams),
    name: register('name', requiredParams),
    patronymic: register('patronymic'),
    /* birthday: register('birthday', {
      required: { value: required, message: terms.REQUIRED_FIELD },
      onChange: ({ target: { value } }) =>
        value && setValue('birthday', normalizeYearTo4Characters(value)),
    }),*/
    mobile: register('mobile', {
      ...requiredParams,
      ...getMobileValidators(watch('mobileCountryCode')),
      onChange: ({ target: { value } }) =>
        setValue('mobile', value?.slice(0, 10)),
    }),
    mobileCountryCode: register('mobileCountryCode', {
      ...requiredParams,
      onChange: ({ target: { value } }) => value && clearErrors('mobile'),
    }),
    relationDegree: register('relationDegree', requiredParams),
    // TODO maybe required when start filling?
  };

  const onDownloadTemplateDoc = () => {
    // downloadLegalRepresentativeAgreement(
    //   DocumentTemplateType.LEGAL_REPRESENTATIVE_INFO_AGREEMENT
    // )
    //   ?.unwrap()
    //   .then(result => {
    //     downloadFile(new File([result], 'Согласие.pdf'));
    //   })
    //   .catch(() => dispatch(createToast(terms.DOWNLOAD_FILE_ERROR, 'danger')));
  };

  const onSubmit = (overwriteData: Partial<LegalRepresentativeDraft>) =>
    handleSubmit(values => {
      const draft: LegalRepresentativeDraft = {
        ...values,
        ...overwriteData,
      };

      saveDraft(draft)
        ?.unwrap()
        .then(() => onSuccess?.())
        .catch(error => {
          const errorDescriptionLocale: string = isRussianLocale()
            ? error.data?.descriptionRu ?? error.data.description
            : error.data.description;
          dispatch(createToast(errorDescriptionLocale, 'danger'));
        });
    });

  return {
    fields,
    onSubmit,
    setValue,
    getValues,
    watch,
    required,
    ...rest,
    ...draftInfo,
    onDownloadTemplateDoc,
  };
};

export default useLegalRepresentativeForm;
