import createAxiosClient from 'config/axios-client';

export const filesManagerClient = createAxiosClient({
  baseURL: process.env.REACT_APP_FILES_MANAGER_API_URL,
});

export const chatClient = createAxiosClient({
  baseURL: process.env.REACT_APP_CHAT_API_URL,
});

export const applicationsManagerClient = createAxiosClient({
  baseURL: process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL,
});
