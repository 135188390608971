import React, { FC } from 'react';

import { Controller, FieldErrorsImpl } from 'react-hook-form';

import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg';
import Button from 'components/common/ui-kit/Button';
import Checkbox from 'components/common/ui-kit/Checkbox';
import Input from 'components/common/ui-kit/Input';
import ReactSelect from 'components/common/ui-kit/Select';
import FillingStatementsForm from 'components/statements/FillingStatementsForm';
import useAppSelector from 'hooks/common/useAppSelector';
import useFillingStatementsRoutesContext from 'hooks/statements/useFillingStatementsRoutesContext';
import terms from 'i18n';
import { ApplicationState } from 'models/applications/enum';
import { SelectOption } from 'models/common';

import {
  TestValuesWithOldDocument,
  useSchoolTestForm,
} from '../../../hooks/statements/schoolTests/useSchoolTestForm';
import { useSchoolTestFormOptions } from '../../../hooks/statements/schoolTests/useSchoolTestFormOptions';
import {
  selectApplication,
  selectIsApplicationReadonly,
} from '../../../redux/selectors/enrollee/application';
import { useIsImpersonate } from '../../../utils/domain/impersonate';

const ResultExamForm: FC = () => {
  const type = '';
  const centralizedTesting = false;
  const isImpersonate = useIsImpersonate();

  const { onNextPageNavigate } = useFillingStatementsRoutesContext();

  const { subjects, years, identificationDocumentTypeOptions } =
    useSchoolTestFormOptions();

  const {
    fields,
    onSubmit,
    testFields,
    append,
    remove,
    control,
    register,
    errors,
    watch,
    onSubjectChange,
    onWasAnotherPassportInUseChange,
  } = useSchoolTestForm(onNextPageNavigate, identificationDocumentTypeOptions);

  const application = useAppSelector(selectApplication);

  const readOnly =
    useAppSelector(selectIsApplicationReadonly) &&
    !isImpersonate &&
    application?.state !== ApplicationState.DIRECTIONS_EDITING;

  return (
    <FillingStatementsForm
      className="result-exam"
      onSubmit={e => {
        if (!readOnly) {
          onSubmit(e);
        } else {
          e.preventDefault();
          onNextPageNavigate();
        }
      }}
    >
      {centralizedTesting && (
        <p>
          Если у Вас есть результаты Централизованного тестирования (ЦТ),
          которые Вы хотите зачесть как вступительные испытания, заполните их в
          Результаты
          {' ' + type} с пометкой “Да”
        </p>
      )}
      {testFields &&
        testFields.map((field, index) => {
          const isOldDocumentRequired = watch(
            `tests.${index}.wasAnotherPassportInUse`
          );
          const testFieldErrors = errors.tests?.[index];
          const checkIfTestFieldErrorsIncludeOldDoc = (
            testFieldErrors
          ): testFieldErrors is FieldErrorsImpl<TestValuesWithOldDocument> => {
            return isOldDocumentRequired;
          };
          const hasOldDocErrors =
            checkIfTestFieldErrorsIncludeOldDoc(testFieldErrors);
          return (
            <div key={`${field.id}${index}`}>
              <div className="result-exam__element">
                <Controller
                  name={`tests.${index}.subjectId`}
                  control={control}
                  rules={{
                    required: terms.REQUIRED_FIELD,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <ReactSelect
                      options={subjects}
                      placeholder={terms.SUBJECT}
                      className="result-exam__element_field"
                      markRequired={true}
                      styles={{
                        menu: base => ({ ...base, minWidth: '200px' }),
                      }}
                      {...(field as object)}
                      onChange={onSubjectChange(field, index)}
                      isDisabled={readOnly}
                      error={error?.message}
                    />
                  )}
                />

                <Input
                  className="result-exam__element_field"
                  placeholder={terms.SCORE}
                  type="number"
                  // disabled={readOnly || !!checkedValue || verified}
                  disabled={readOnly}
                  // markRequired={true}
                  {...fields.testsScore(index)}
                  error={testFieldErrors?.score?.message}
                />

                <Controller
                  name={`tests.${index}.year`}
                  control={control}
                  rules={{
                    required: terms.REQUIRED_FIELD,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <ReactSelect<SelectOption>
                      options={years}
                      placeholder={terms.EXAM_YEAR}
                      className="result-exam__element_field"
                      markRequired={true}
                      styles={{
                        menu: base => ({ ...base, minWidth: '100px' }),
                      }}
                      {...(field as object)}
                      isDisabled={readOnly}
                      error={error?.message}
                    />
                  )}
                />
                <div className="result-exam__element_status">
                  <Button
                    type="button"
                    disabled={readOnly}
                    onClick={() => remove(index, field.testId)}
                  >
                    <TrashIcon />
                  </Button>
                </div>
              </div>

              <Checkbox
                disabled={readOnly}
                {...register(`tests.${index}.wasAnotherPassportInUse`, {
                  onChange: e => {
                    if (!e.target.checked) return;
                    onWasAnotherPassportInUseChange(index);
                  },
                })}
                text={terms.ANOTHER_PASSPORT}
              />

              {isOldDocumentRequired && (
                <div className="result-exam__document">
                  <Controller
                    name={`tests.${index}.identificationDocumentType`}
                    control={control}
                    rules={{
                      required: isOldDocumentRequired && terms.REQUIRED_FIELD,
                    }}
                    render={({ field }) => (
                      <ReactSelect<SelectOption>
                        isDisabled={readOnly}
                        markRequired={true}
                        className="result-exam__document-field"
                        {...(field as object)}
                        options={identificationDocumentTypeOptions}
                        placeholder={terms.DOCUMENT_TYPE}
                        error={
                          hasOldDocErrors
                            ? testFieldErrors?.identificationDocumentType
                                ?.message
                            : undefined
                        }
                      />
                    )}
                  />

                  <div className="result-exam__document-container">
                    <Input
                      disabled={readOnly}
                      className="result-exam__document-field"
                      placeholder={terms.SERIES}
                      // TODO
                      // markRequired={isSeriesRequired}
                      {...register(
                        `tests.${index}.identificationDocumentSerial`
                      )}
                    />
                    <Input
                      disabled={readOnly}
                      className="result-exam__document-field"
                      placeholder={terms.NUMBER}
                      markRequired={true}
                      {...register(
                        `tests.${index}.identificationDocumentNumber`,
                        {
                          required:
                            isOldDocumentRequired && terms.REQUIRED_FIELD,
                        }
                      )}
                      error={
                        hasOldDocErrors
                          ? testFieldErrors?.identificationDocumentNumber
                              ?.message
                          : undefined
                      }
                    />
                  </div>
                  <Input
                    disabled={readOnly}
                    className="result-exam__document-field"
                    placeholder={terms.SURNAME}
                    markRequired={true}
                    {...register(`tests.${index}.surname`, {
                      required: isOldDocumentRequired && terms.REQUIRED_FIELD,
                    })}
                    error={
                      hasOldDocErrors
                        ? testFieldErrors?.surname?.message
                        : undefined
                    }
                  />
                  <Input
                    disabled={readOnly}
                    className="result-exam__document-field"
                    placeholder={terms.NAME}
                    markRequired={true}
                    {...register(`tests.${index}.name`, {
                      required: isOldDocumentRequired && terms.REQUIRED_FIELD,
                    })}
                    error={
                      hasOldDocErrors
                        ? testFieldErrors?.name?.message
                        : undefined
                    }
                  />
                  <Input
                    disabled={readOnly}
                    placeholder={terms.PATRONYMIC_IN_RUSSIAN}
                    className="result-exam__document-field"
                    {...register(`tests.${index}.patronymic`)}
                  />
                </div>
              )}
            </div>
          );
        })}

      <div className="result-exam__btn">
        <Button type="button" onClick={append} disabled={readOnly}>
          {terms.ADD_SUBJECT}
        </Button>

        {/* {trainingLevel !== TrainingLevel.PREPARATORY && (
              <Button
                theme="success"
                // loading={checkLoading}
                type="button"
                onClick={() => {}}
                disabled={readOnly}
              >
                {terms.CHECK_RESULTS}
              </Button>
            )}*/}
      </div>
    </FillingStatementsForm>
  );
};

export default ResultExamForm;
