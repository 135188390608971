import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { useLazyDownloadFileQuery } from '../../../redux/api/common/files-api';
import { staffApplicationsApi } from '../../../redux/api/staff/staff-applications-api';
import { staffApplicationsSlice } from '../../../redux/slices/staff/staffApplicationsSlice';
import { blobToBase64 } from '../../../utils/common';
import useAppDispatch from '../../common/useAppDispatch';
import { useGetEnrolleeOrQuestionnaireByUserId } from '../useGetEnrolleeOrQuestionnaireByUserId';

export const useInitializeStaffApplication = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const { data: application } =
    staffApplicationsApi.useGetStaffApplicationByIdQuery(Number(id));

  const [downloadFile] = useLazyDownloadFileQuery();

  const questionnaire = useGetEnrolleeOrQuestionnaireByUserId(
    application?.userId
  );

  useEffect(() => {
    if (questionnaire && application) {
      dispatch(
        staffApplicationsSlice.actions.setCurrentApplication({
          user: questionnaire.user,
          questionnaire,
          application,
        })
      );

      if (!questionnaire.user.photoUploadId) {
        dispatch(
          staffApplicationsSlice.actions.cleanCurrentApplicationProfileImage()
        );
        return;
      }

      downloadFile({
        userId: questionnaire.user.id,
        fileId: questionnaire.user.photoUploadId,
      })
        .unwrap()
        .then(response => blobToBase64(response))
        .then(profileImageBase64 => {
          if (!profileImageBase64) throw new Error();
          dispatch(
            staffApplicationsSlice.actions.setCurrentApplicationProfileImage(
              profileImageBase64
            )
          );
        })
        .catch(() =>
          staffApplicationsSlice.actions.cleanCurrentApplicationProfileImage()
        );
    }
  }, [questionnaire, application]);
};
