import { useLazyExportSchoolTestsResultQuery } from '../../../redux/api/applications/school-test-api';
import { downloadFile } from '../../../utils/common';

export const useModeratorSchoolTests = (
  userId?: number,
  userIdWithPrefix?: string
) => {
  const [exportSchoolTestResults] = useLazyExportSchoolTestsResultQuery();
  return () => {
    if (userId !== undefined) {
      exportSchoolTestResults(userId)
        ?.unwrap()
        ?.then(result => {
          downloadFile(new File([result], `${userIdWithPrefix}-ege.csv`));
        });
    }
  };
};
