import {
  DirectionEnum,
  EducationProgram,
  Direction,
  DirectionFilters,
  ChosenDirectionWithPriority,
  Faculty,
  DirectionEntranceTestPriority,
} from 'models/applications/directions';
import { Page, Pagination } from 'models/common';
import { apiSlice } from 'redux/api/api-slice';

import {
  ApplicationId,
  DirectionRequestType,
} from '../../../models/applications/applications';
import { ExternalEntityNamedResponseWithExternalId } from '../../../models/applications/contracts';
import {
  ApplicationEducationLevel,
  Trajectory,
} from '../../../models/applications/enum';
import { Privilege } from '../../../models/applications/privileges';

const baseUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/directions`;

export const directionsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getPaymentForms: builder.query<DirectionEnum[], void>({
      query: () => ({ url: `${baseUrl}/payment-forms` }),
    }),

    getFaculties: builder.query<Faculty[], void>({
      query: () => ({ url: `${baseUrl}/faculties` }),
    }),

    getEducationLevels: builder.query<
      DirectionEnum[],
      { forPreparatory: boolean }
    >({
      query: forPreparatory => ({
        url: `${baseUrl}/education-levels`,
        params: forPreparatory,
      }),
    }),

    getEducationForms: builder.query<DirectionEnum[], void>({
      query: () => ({ url: `${baseUrl}/education-forms` }),
    }),

    getEducationPrograms: builder.query<EducationProgram[], void>({
      query: () => ({ url: `${baseUrl}/education-programs` }),
    }),

    getDirections: builder.query<
      Page<Direction>,
      Pagination &
        DirectionFilters & {
          readonly trajectory?: Trajectory;
        }
    >({
      query: ({
        page,
        size,
        name,
        educationLevelId,
        educationFormId,
        trajectory,
        applicationId = undefined,
        admissionBasis,
        paymentFormId,
        showClosed = true,
      }) => ({
        url: `${baseUrl}/all-pageable`,
        params: {
          page,
          size,
          name,
          educationFormId,
          educationLevelId,
          trajectory,
          applicationId,
          admissionBasis,
          paymentFormId,
          showClosed,
        },
      }),
    }),

    getDirectionsByApplicationId: builder.query<
      {
        BUDGET?: ChosenDirectionWithPriority[];
        CONTRACT?: ChosenDirectionWithPriority[];
      },
      {
        readonly applicationId: number;
        readonly showDeleted?: boolean;
      }
    >({
      query: ({ applicationId, showDeleted = false }) => ({
        url: `${baseUrl}/all-by-application-id/${applicationId}`,
        params: {
          showDeleted,
        },
      }),
    }),

    getDirectionById: builder.query<Direction, number>({
      query: id => ({
        url: `${baseUrl}/${id}`,
      }),
    }),

    getDirectionTestsPriorities: builder.query<
      DirectionEntranceTestPriority[],
      number
    >({
      query: id => ({
        url: `${baseUrl}/tests-priorities/${id}`,
      }),
    }),

    getEducationLevelMapping: builder.query<
      (ExternalEntityNamedResponseWithExternalId & {
        readonly directionsAllowed: {
          directionsAllowed: number;
          directionsAllowedBudget: number;
          directionsAllowedContract?: number;
        };
      })[],
      {
        readonly educationLevel: ApplicationEducationLevel;
        readonly trajectory?: Trajectory;
        readonly directionsRequestType?: DirectionRequestType;
      }
    >({
      query: ({ educationLevel, trajectory, directionsRequestType }) => ({
        url: `${baseUrl}/education-level-mapping`,
        params: {
          educationLevel,
          trajectory,
          directionsRequestType,
        },
      }),
    }),

    getNumberOfAvailableDirections: builder.query<
      ExternalEntityNamedResponseWithExternalId & {
        directionsAllowed: number;
        additionalLevelIds: number[];
        otherAppsUsedDirectionCodes: string[];
        currentAppUsedDirectionCodes: string[];
        checkUnique: boolean;
        currentOnly: boolean;
      },
      {
        readonly userId: number;
        readonly applicationId?: ApplicationId;
        readonly educationLevel: ApplicationEducationLevel;
        readonly trajectory?: Trajectory;
        readonly directionsRequestType?: DirectionRequestType;
      }
    >({
      query: ({
        applicationId,
        educationLevel,
        trajectory,
        directionsRequestType,
        userId,
      }) => ({
        url: `${baseUrl}/number-of-available-directions/${userId}`,
        params: {
          applicationId,
          educationLevel,
          trajectory,
          directionsRequestType,
        },
      }),
    }),

    getAvailablePrivileges: builder.query<Privilege[], number>({
      query: (applicationDirectionId: number) => ({
        url: `${baseUrl}/possible-privileges/${applicationDirectionId}`,
      }),
    }),

    setPrivilegeToDirection: builder.mutation<
      ChosenDirectionWithPriority,
      {
        applicationDirectionId: number;
        privilegeId: number;
      }
    >({
      query: ({ applicationDirectionId, privilegeId }) => ({
        url: `${baseUrl}/set-privilege-to-direction/${applicationDirectionId}`,
        method: 'POST',
        params: {
          privilegeId: privilegeId,
        },
      }),
    }),
  }),
});

export const {
  useGetPaymentFormsQuery,
  useGetFacultiesQuery,
  useGetEducationLevelsQuery,
  useGetEducationFormsQuery,
  useGetEducationProgramsQuery,
  useLazyGetDirectionsQuery,
  useGetDirectionsByApplicationIdQuery,
  useLazyGetDirectionsByApplicationIdQuery,
  useLazyGetEducationLevelMappingQuery,
  useLazyGetNumberOfAvailableDirectionsQuery,
  useGetEducationLevelMappingQuery,
  useGetDirectionByIdQuery,
  useGetDirectionTestsPrioritiesQuery,
  useGetAvailablePrivilegesQuery,
  useSetPrivilegeToDirectionMutation,
} = directionsApi;
