import React, { FC } from 'react';

import { Controller } from 'react-hook-form';

import AdminTableListPage from 'components/admin/AdminTableListPage';
import { InputV2 } from 'components/common/ui-kit/Input/view';
import Loader from 'components/common/ui-kit/Loader';
import ReactSelect, {
  MultiValue,
  Option,
} from 'components/common/ui-kit/Select/view';
import { columnsPersonalInformation } from 'config/moderator-table';
import useAppSelector from 'hooks/common/useAppSelector';
import { useQuestionnaireFilterOptions } from 'hooks/moderator/questionnaires/useQuestionnaireFilterOptions';
import terms from 'i18n';
import { SelectOption } from 'models/common';
import { UserRole } from 'models/user';
import { useGetCountriesQuery } from 'redux/api/common/enum-api';
import { questionnaireApi } from 'redux/api/questionnaire/questionnaire-api';
import { selectUserRole } from 'redux/selectors';
import { createInputV2Props } from 'utils/form-helpers';

export const ModeratorStatementsFilter = ({
  control,
  formState: { errors },
}) => {
  const inputV2Props = createInputV2Props(errors);
  const {
    educationLevelSelectProps,
    countriesSelectProps,
    messagesStateSelectProps,
  } = useQuestionnaireFilterOptions();

  return (
    <>
      <Controller
        name="id"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="ID пользователя"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="lastName"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Фамилия"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Имя"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="middleName"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Отчество"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Email"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        defaultValue={undefined}
        name="citizenshipCountryIds"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            placeholder={terms.CITIZENSHIP}
            className="filter-form__field"
            {...(field as object)}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
            options={countriesSelectProps}
          />
        )}
      />

      <Controller
        defaultValue={undefined}
        name="educationLevels"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={educationLevelSelectProps.options}
            placeholder="Вид полученного образования"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />

      <Controller
        name="createdAt"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Дата создания аккаунта"
            type="date"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="unreadMessages"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={messagesStateSelectProps.options}
            placeholder="Непрочитанные сообщения"
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
          />
        )}
      />
    </>
  );
};

export const renderQuestionnairesFilter = props => (
  <ModeratorStatementsFilter {...props} />
);

const ModeratorPersonalInformationPage: FC = () => {
  const { data: countries } = useGetCountriesQuery();
  const role = useAppSelector(selectUserRole);

  if (!countries) {
    return <Loader />;
  }

  return (
    <AdminTableListPage
      storeKey="questionnaires"
      title="Анкеты"
      query={{
        use: questionnaireApi.useGetAllQuestionnairesPageableQuery,
        mapQueryResultToData: data =>
          data.map(questionnaire => ({
            ...questionnaire,
            fullName: questionnaire.patronymic
              ? `${questionnaire.surname} ${questionnaire.name} ${questionnaire.patronymic}`
              : `${questionnaire.surname} ${questionnaire.name}`,
            createdAt: questionnaire.createdAt,
            updatedAt: questionnaire.updatedAt,
            nationality: countries.find(
              country => country.id === questionnaire.citizenshipCountryId
            )?.title,
            message:
              role === UserRole.ADMIN
                ? questionnaire.unreadMessagesCount?.ADMIN ?? 0
                : role === UserRole.MODERATOR
                ? questionnaire.unreadMessagesCount?.MODERATOR ?? 0
                : role === UserRole.EXPERT
                ? questionnaire.unreadMessagesCount?.EXPERT ?? 0
                : role === UserRole.SUPPORT
                ? questionnaire.unreadMessagesCount?.SUPPORT ?? 0
                : role === UserRole.COORDINATOR
                ? questionnaire.unreadMessagesCount?.COORDINATOR ?? 0
                : 0,
          })),
      }}
      displayCountLabel="Количество анкет без созданных заявок"
      columns={columnsPersonalInformation}
      filtersConfig={{
        // defaultValues: {
        //   userId: '',
        //   lastName: '',
        //   firstName: '',
        //   middleName: '',
        //   directionPaymentFormId: undefined,
        //   directionEducationFormId: undefined,
        //   citizenshipIds: undefined,
        //   applicationState: undefined,
        //   applicationExpertStatus: undefined,
        //   applicationCoordinatorStatus: undefined,
        //   unreadMessages: undefined,
        //   directionName: '',
        //   facultyIds: undefined,
        //   recruitedIds: undefined,
        //   applicationEducationLevel: undefined,
        //   applicationTrajectory: undefined,
        //   educationProgram: undefined,
        //   creationDate: undefined,
        //   moderatorIds: undefined,
        // },
        defaultValues: {},
        renderFilters: renderQuestionnairesFilter,
        mapFiltersToRequest: (filters, defaultMap) => {
          const result = {
            ...filters,
            unreadMessages:
              filters.unreadMessages?.value === undefined
                ? undefined
                : filters.unreadMessages.value === 'READ',
          };
          return defaultMap(result);
        },
      }}
      shouldOpenInNewTab
    />
  );
};

export default ModeratorPersonalInformationPage;
