import { DocumentTemplateType } from '../../../models/applications/application-documents';
import {
  Contract,
  ContractFilters,
  ContractLegalEntityPayerData,
  ContractThirdPartyPayerData,
  ContractType,
  ContractView,
  ExternalEntityNamedResponseWithExternalId,
} from '../../../models/applications/contracts';
import { DateString, Page, Pagination, Sorting } from '../../../models/common';
import { apiSlice } from '../api-slice';

const baseUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/contracts`;

export const contractApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getById: builder.query<Contract, number>({
      query: contractId => `${baseUrl}/${contractId}`,
    }),

    getPaymentsTypes: builder.query<
      ExternalEntityNamedResponseWithExternalId[],
      void
    >({
      query: () => `${baseUrl}/payment-types`,
    }),

    getByApplicationId: builder.query<Contract[], number>({
      query: applicationId => `${baseUrl}/by-application-id/${applicationId}`,
      providesTags: ['Contract'],
    }),

    submitContract: builder.query<
      Contract,
      {
        readonly id: number;
        readonly documentId?: number;
      }
    >({
      query: ({ id, documentId }) => ({
        url: `${baseUrl}/submit/${id}`,
        params: { documentId },
        method: 'POST',
      }),
    }),

    sendContractForEditing: builder.query<Contract, number>({
      query: id => ({
        url: `${baseUrl}/send-for-editing/${id}`,
        method: 'POST',
      }),
    }),

    create: builder.query<
      Contract,
      {
        applicationDirectionId: number;
        contractType: ContractType;
        paymentTypeId: number;
        contractThirdPartyPayerData?: ContractThirdPartyPayerData;
        contractLegalEntityPayerData?: ContractLegalEntityPayerData;
        contractDate?: DateString;
      }
    >({
      query: body => ({
        url: `${baseUrl}/create`,
        method: 'POST',
        body,
      }),
    }),

    updateContract: builder.mutation<
      Contract,
      {
        id: number;
        applicationDirectionId: number;
        contractType: ContractType;
        paymentTypeId: number;
        contractThirdPartyPayerData?: ContractThirdPartyPayerData;
        contractLegalEntityPayerData?: ContractLegalEntityPayerData;
        contractDate?: DateString;
      }
    >({
      query: body => ({
        url: `${baseUrl}/update`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Contract'],
    }),

    approveContract: builder.query<Contract, number>({
      query: id => ({
        url: `${baseUrl}/approve/${id}`,
        method: 'POST',
      }),
    }),

    confirmContract: builder.query<Contract, number>({
      query: id => ({
        url: `${baseUrl}/confirm/${id}`,
        method: 'POST',
      }),
    }),

    cancelContract: builder.query<Contract, number>({
      query: id => ({
        url: `${baseUrl}/cancel/${id}`,
        method: 'POST',
      }),
    }),

    deleteContract: builder.query<void, number>({
      query: id => ({
        url: `${baseUrl}/delete/${id}`,
        method: 'DELETE',
      }),
    }),

    takeToWork: builder.query<Contract, number>({
      query: id => ({
        url: `${baseUrl}/take-to-work/${id}`,
        method: 'POST',
      }),
    }),

    checkIfPriceExists: builder.query<
      {
        result: boolean;
      },
      {
        contractId: number;
        withPartner: boolean;
      }
    >({
      query: ({ contractId, withPartner }) => ({
        url: `${baseUrl}/contract-price-exists/${contractId}`,
        params: {
          partner: withPartner,
        },
      }),
    }),

    getContractDocumentType: builder.query<DocumentTemplateType, number>({
      query: contractId => ({
        url: `${baseUrl}/guess-contract-document-type/${contractId}`,
      }),
    }),

    getAll: builder.query<
      Page<ContractView>,
      Pagination &
        Sorting & {
          request?: ContractFilters;
        }
    >({
      query: ({ page, size, orderBy, orderDirection, request }) => ({
        url: `${baseUrl}/all-pageable`,
        method: 'POST',
        body: request,
        params: {
          page,
          size,
          orderBy,
          orderDirection,
        },
      }),
    }),
  }),
});
