import React, { FC, useEffect, useMemo, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';

import ReactSelect from 'components/common/ui-kit/Select';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useLazyIdEnumSelectProps from 'hooks/common/useLazyIdEnumSelectProps';
import { useDebounce } from 'hooks/questionnaire/common/util/useDebounce';
import terms from 'i18n';
import {
  AdmissionBasis,
  admissionBasesToLabels,
} from 'models/applications/directions';
import {
  ApplicationEducationLevel,
  DirectionApplicationEducationLevel,
} from 'models/applications/enum';
import { SelectOption } from 'models/common';
import { EnrollListFormData } from 'models/students';
import {
  useGetEducationFormsQuery,
  useGetEducationLevelMappingQuery,
  useLazyGetDirectionsQuery,
} from 'redux/api/applications/directions-api';
import { enrollListsSlice } from 'redux/slices/common/enroll-lists-slice';

import 'pages/Enrollment/common/enrollListsFilters.scss';

export type EnrollListsFilters = {
  educationLevel: ApplicationEducationLevel;
  educationFormId: number | null;
  onEducationFormIdChange: (educationFormId: number | null) => void;
  admissionBasis: AdmissionBasis | null;
  onAdmissionBasisChange: (admissionBasis: AdmissionBasis | null) => void;
  directionId: number | null;
  onDirectionIdChange: (directionId: number | null) => void;
};

const paymentFormIdsToAdmissionBases: { [key: number]: AdmissionBasis } = {
  1: AdmissionBasis.BUDGET,
  2: AdmissionBasis.CONTRACT,
  3: AdmissionBasis.GOVERNMENT,
};

const hiddenDirectionIds = [2245, 2151, 2267, 2496];

const mapAdmissionBasesToSelectOptions = (admissionBases: AdmissionBasis[]) =>
  admissionBases.map(admissionBasis => ({
    value: admissionBasis,
    label: admissionBasesToLabels[admissionBasis],
  }));

export const EnrollListsFilters: FC<EnrollListsFilters> = ({
  educationLevel,
  educationFormId,
  onEducationFormIdChange,
  admissionBasis,
  onAdmissionBasisChange,
  directionId,
  onDirectionIdChange,
}) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<EnrollListFormData>({ mode: 'all' });
  const dispatch = useAppDispatch();
  // do not print name for this organizations in selector
  const targetOrganizationIds = [2209, 2152, 2013, 2278];
  // const targetOrganizationIds: Array<number> = [];

  const admissionBasisOptions: SelectOption[] = useMemo(() => {
    return educationLevel === ApplicationEducationLevel.SECONDARY
      ? mapAdmissionBasesToSelectOptions([
          AdmissionBasis.BUDGET,
          AdmissionBasis.CONTRACT,
        ])
      : mapAdmissionBasesToSelectOptions(Object.values(AdmissionBasis));
  }, [educationLevel]);

  const onSubmit = handleSubmit(data => {
    onAdmissionBasisChange(data.admissionBasis?.value ?? null);
    onEducationFormIdChange(data.educationForm?.value ?? null);
    onDirectionIdChange(data.direction?.value ?? null);
  });

  const clearCurrentDirection = () => {
    setValue('direction', undefined);
    dispatch(enrollListsSlice.actions.setDirectionId(undefined));
  };

  const fields = {
    educationLevel: register('educationLevel', {
      onChange: () => {
        clearCurrentDirection();
        onSubmit();
      },
    }),
    educationForm: register('educationForm', {
      onChange: () => {
        clearCurrentDirection();
        onSubmit();
      },
    }),
    admissionBasis: register('admissionBasis', {
      onChange: () => {
        clearCurrentDirection();
        onSubmit();
      },
    }),
    direction: register('direction', {
      onChange: () => {
        onSubmit();
      },
    }),
  };

  const educationFormSelectProps = useLazyIdEnumSelectProps(
    useGetEducationFormsQuery
  );

  // const paymentFormSelectProps = useLazyIdEnumSelectProps(
  //   useGetPaymentFormsQuery
  // );

  // Directions
  const [directionsSearch, setDirectionsSearch] = useState('');
  const debouncedDirectionsSearch = useDebounce(directionsSearch, 500);
  const [
    getDirections,
    { data: directionsPage, isLoading: isDirectionsPageLoading },
  ] = useLazyGetDirectionsQuery();

  const {
    data: educationLevelMapping,
    isLoading: isEducationLevelMappingLoading,
  } = useGetEducationLevelMappingQuery({ educationLevel: educationLevel });

  useEffect(() => {
    if (
      !educationLevelMapping?.[0] ||
      educationFormId === null ||
      admissionBasis === null ||
      debouncedDirectionsSearch === ''
    )
      return;
    getDirections({
      name: debouncedDirectionsSearch,
      educationLevelId: educationLevelMapping.map(m => m.id).join(','),
      educationFormId: educationFormId,
      admissionBasis: admissionBasis,
      size: 20,
    });
  }, [
    debouncedDirectionsSearch,
    educationLevel,
    educationFormId,
    educationLevelMapping,
    admissionBasis,
  ]);

  const directionOptions = useMemo(
    () =>
      directionsPage?.result
        // .filter(direction => !hiddenDirectionIds.includes(direction.id))
        .map(direction => ({
          value: direction.id,
          label:
            `${direction.code} ${direction.title}${
              direction.forForeign &&
              educationLevel !== ApplicationEducationLevel.SECONDARY
                ? ' (ИНО)'
                : ''
            }` +
            (direction.applicationEducationLevel !==
            DirectionApplicationEducationLevel.GRADUATE
              ? direction.applicationEducationLevel !==
                  DirectionApplicationEducationLevel.SECONDARY &&
                direction.applicationEducationLevel !==
                  DirectionApplicationEducationLevel.UPPER_SECONDARY
                ? direction.educationProgram
                  ? ` - ${direction.educationProgram.title}`
                  : ''
                : ` (на базе ${
                    direction.applicationEducationLevel ===
                    DirectionApplicationEducationLevel.SECONDARY
                      ? '9'
                      : '11'
                  } классов)`
              : '') +
            (direction.targetOrganization &&
            !targetOrganizationIds.includes(direction.id)
              ? ` ${direction.targetOrganization?.shortName}`
              : ''),
          id: direction.id,
        })) ?? [],
    [directionsPage, educationLevel]
  );

  return (
    <>
      <div className="enroll-lists-filters">
        <Controller
          name="educationForm"
          control={control}
          render={({ field }) => (
            <ReactSelect
              className="profile-form__field enroll-lists-filters__filter"
              {...(field as object)}
              {...educationFormSelectProps}
              placeholder={terms.EDUCATION_FORMAT}
              error={errors.educationForm?.message as string}
              isClearable={true}
            />
          )}
        />

        <Controller
          name="admissionBasis"
          control={control}
          render={({ field }) => (
            <ReactSelect
              className="profile-form__field enroll-lists-filters__filter"
              {...(field as object)}
              options={admissionBasisOptions}
              placeholder={terms.ADMISSION_CONDITIONS}
              error={errors.admissionBasis?.message as string}
              isClearable={true}
            />
          )}
        />

        <Controller
          name="direction"
          control={control}
          render={({ field }) => (
            <ReactSelect
              className="profile-form__field enroll-lists-filters__filter"
              {...(field as object)}
              isLoading={
                isDirectionsPageLoading || isEducationLevelMappingLoading
              }
              options={directionOptions}
              menuIsOpen={debouncedDirectionsSearch === '' ? false : undefined}
              inputValue={directionsSearch}
              onInputChange={value => setDirectionsSearch(value)}
              placeholder={terms.ENTER_DIRECTION_CODE}
              error={errors.direction?.message as string}
              isClearable={true}
              isDisabled={
                educationLevel === null ||
                educationFormId === null ||
                admissionBasis === null
              }
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          )}
        />
      </div>
    </>
  );
};
