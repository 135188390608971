import React, { FC, useEffect } from 'react';

import { Outlet, useLocation, useParams } from 'react-router-dom';

import { statementsTabRotes } from 'config/routes/statements-tab-routes';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import { DirectionRequestType } from 'models/applications/applications';
import ProgressTabPage from 'pages/ProgressTabPage';

import { useGetApplicationByIdQuery } from '../../../redux/api/applications/applications-api';
import { selectApplication } from '../../../redux/selectors/enrollee/application';
import { applicationSlice } from '../../../redux/slices/enrollee/application-slice';
import { useIsImpersonate } from '../../../utils/domain/impersonate';

const ApplicationIdPage: FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isImpersonate = useIsImpersonate();

  const { applicationId } = useParams();
  const { data: applicationData } = useGetApplicationByIdQuery(
    Number(applicationId),
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (applicationData) {
      dispatch(
        applicationSlice.actions.setSelectedApplication(applicationData)
      );
    }
  }, [applicationId, applicationData]);

  const application = useAppSelector(selectApplication);

  useEffect(() => {
    if (application) {
      dispatch(
        applicationSlice.actions.setCurrentTrajectory(application.trajectory)
      );
      dispatch(
        applicationSlice.actions.setCurrentTrainingLevel(
          application.educationLevel
        )
      );
      dispatch(
        applicationSlice.actions.setCurrentEducationalInfo(
          application.educationalProfile
        )
      );
      dispatch(
        applicationSlice.actions.setDirectionsRequestType(
          !application.educationBasis
            ? DirectionRequestType.ALL
            : application.educationBasis === 'BUDGET'
            ? DirectionRequestType.BUDGET
            : DirectionRequestType.CONTRACT
        )
      );
    }

    return () => {
      dispatch(applicationSlice.actions.cleanCurrentTrajectoryAndLevel());
      dispatch(
        applicationSlice.actions.setDirectionsRequestType(
          DirectionRequestType.ALL
        )
      );
    };
  }, [application]);

  return (
    <ProgressTabPage
      activePage={statementsTabRotes.find(l =>
        location.pathname.includes(l.to)
      )}
    >
      <Outlet />
    </ProgressTabPage>
  );
};

export default ApplicationIdPage;
