import React, { FC, useEffect } from 'react';

import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import terms from 'i18n';
import { DirectionRequestType } from 'models/applications/applications';
import { applicationsInfoApi } from 'redux/api/applications/applications-info-api';

import {
  selectCurrentEducationLevel,
  selectCurrentTrajectory,
  selectDirectionsRequestType,
} from '../../../redux/selectors/enrollee/application';
import { applicationSlice } from '../../../redux/slices/enrollee/application-slice';
import DirectionsTypeElement from './DirectionsTypeElement';

export const directionsRequestTypeToText: {
  [key in DirectionRequestType]: string;
} = {
  [DirectionRequestType.ALL]: '',
  [DirectionRequestType.BUDGET]: terms.BUDGET_FORM,
  [DirectionRequestType.CONTRACT]: terms.CONTRACT_FORM,
};

const DirectionsTypeList: FC = () => {
  const dispatch = useAppDispatch();

  const directionsRequestType = useAppSelector(selectDirectionsRequestType);

  const setActive = (value: DirectionRequestType) => {
    dispatch(applicationSlice.actions.setDirectionsRequestType(value));
  };

  const { id: userId } = useAppSelector(state => state.user);
  const trajectory = useAppSelector(selectCurrentTrajectory);
  const educationLevel = useAppSelector(selectCurrentEducationLevel);

  const [getAvailableEducationBases, { data: educationBases }] =
    applicationsInfoApi.useLazyGetPossibleEducationBasesForUserQuery();

  useEffect(() => {
    if (!userId || !trajectory || !educationLevel) return;
    getAvailableEducationBases({ userId, trajectory, educationLevel });
  }, [userId, trajectory, educationLevel, getAvailableEducationBases]);

  return (
    <section className="directions-type-list">
      {educationBases?.map((key, index) => (
        <DirectionsTypeElement
          key={key + index}
          setActive={setActive}
          active={key.toString() === directionsRequestType.toString()}
          type={key}
          name={directionsRequestTypeToText[key]}
        />
      ))}
    </section>
  );
};

export default DirectionsTypeList;
