import React, { FC } from 'react';

import SignInForm from 'components/auth/SignInForm';
import TemplateAuthPage from 'pages/TemplateAuthPage';

const ModeratorSignInPage: FC = () => (
  <TemplateAuthPage moderator>
    <SignInForm title="Авторизация" moderator />
  </TemplateAuthPage>
);

export default ModeratorSignInPage;
