import { useLocation, useParams } from 'react-router-dom';

/**
 * Так как по требованиям имперсонейт должен открываться на новой
 * странице, то появляется проблема для фронта понять куда именно
 * нужно навигировать при нажатии кнопки "Назад" или когда успешно
 * меняют анкету (после успешного изменения происходит навигация
 * на предыдущую страницу). Поэтому когда нажимается имперсонейт,
 * сохраняется рут откуда это произошло в локал сторадже.
 *
 * В имперсонейт можно попасть из:
 * - интерфейса анкет у сапорта
 * - интерфейса заявлений у стафа
 * - интерфейса всех пользователей у админа
 * - интерфейса просмотра контрактов
 */
const PREVIOUS_LOCATION_KEY = 'location:previous';

/**
 * @see PREVIOUS_LOCATION_KEY
 */
export const savePreviousLocation = () => {
  localStorage.setItem(PREVIOUS_LOCATION_KEY, location.pathname);
};

/**
 * @see PREVIOUS_LOCATION_KEY
 */
export const getPreviousLocation = (): string | null => {
  return localStorage.getItem(PREVIOUS_LOCATION_KEY);
};

/**
 * @see PREVIOUS_LOCATION_KEY
 */
export const deletePreviousLocation = () =>
  localStorage.removeItem(PREVIOUS_LOCATION_KEY);

/**
 * @see PREVIOUS_LOCATION_KEY
 */
export const getReturnRouteFromImpersonate = ({
  userId,
  applicationId,
}: {
  readonly userId?: string;
  readonly applicationId?: string;
}): string => {
  const previousLocation = getPreviousLocation();

  if (!previousLocation) return '';

  if (previousLocation.includes('admin')) {
    if (previousLocation.includes('users')) {
      return '/admin/users';
    }

    if (previousLocation.includes('personal-information')) {
      return `/admin/personal-information/${userId}`;
    }

    if (previousLocation.includes('statements') && applicationId) {
      return `/admin/statements/${applicationId}`;
    }

    if (previousLocation.includes('contracts')) {
      // trust me
      return `/admin/contracts/${previousLocation.split('/')[3]}`;
    }
  }

  return '';
};

export const useIsImpersonate = () => {
  const { pathname } = useLocation();
  return pathname.includes('impersonate');
};

export const useImpersonatedUserId = () => {
  const { userId } = useParams();
  return userId;
};
