import {
  auditEventTypeHeadOptions,
  auditEventTypeOptions,
  roleSelectOptions,
} from '../../utils/enum-select';

export const useLogFiltersOptions = () => {
  return {
    roleSelectOptions,
    auditEventTypeOptions,
    auditEventTypeHeadOptions,
  };
};
