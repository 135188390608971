import { useCallback, useEffect, useMemo } from 'react';

import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { MutationDefinition } from '@reduxjs/toolkit/query';

import {
  Draft as DraftType,
  DraftElement,
  DraftId,
  Questionnaire,
} from '../../../../models/questionnaire/questionnaire';
import { questionnaireSlice } from '../../../../redux/slices/enrollee/questionnaire-slice';
// import { moreThan18age } from '../../../../utils/form-validators';
import useAppDispatch from '../../../common/useAppDispatch';
import { isEducationInformationDraftSpecified } from '../../educationInformationDraft/useGetEducationFormWithValidation';
import { useUpdateDraftStatus } from '../util/useUpdateDraftStatus';
import {
  isAddressesFormSpecified,
  isCompatriotFormSpecified,
  isGeneralDataFormSpecified,
  isLegalRepresentativeFormSpecified,
  isPassportFormSpecified,
  isVisaFormSpecified,
} from './common';
import { useQuestionnaire } from './useQuestionnaire';

export const useDraft = <Draft extends DraftType>(
  getDraft: (questionnaire: Questionnaire) => Draft,
  useSaveDraftMutation: UseMutation<
    MutationDefinition<{ id: DraftId; draft: Draft }, any, any, any>
  >
) => {
  const dispatch = useAppDispatch();
  const updateGeneralDataDraftStatus = useUpdateDraftStatus('generalData');
  const updateAddressesDraftStatus = useUpdateDraftStatus('addresses');
  const updateCompatriotDraftStatus = useUpdateDraftStatus('compatriot');
  const updateVisaDraftStatus = useUpdateDraftStatus('visa');
  const updateEducationInformationDraftStatus = useUpdateDraftStatus(
    'educationInformation'
  );
  const updateLegalRepresentativeDraftStatus = useUpdateDraftStatus(
    'legalRepresentative'
  );
  const updatePassportDraftStatus = useUpdateDraftStatus('passport');

  const {
    data: questionnaire,
    isLoading: isQuestionnaireLoading,
    isError: isQuestionnaireError,
    refetch: refetchQuestionnaire,
  } = useQuestionnaire();

  useEffect(() => {
    if (!questionnaire) return;

    if (isGeneralDataFormSpecified(questionnaire.generalDataForm)) {
      updateGeneralDataDraftStatus('valid');
    }

    if (isAddressesFormSpecified(questionnaire.addressForm)) {
      updateAddressesDraftStatus('valid');
    }

    if (
      isLegalRepresentativeFormSpecified(questionnaire.legalRepresentativeForm)
    ) {
      updateLegalRepresentativeDraftStatus('valid');
    } else {
      updateLegalRepresentativeDraftStatus('invalid');
    }

    if (
      isPassportFormSpecified(
        questionnaire.passportForm,
        questionnaire.generalDataForm
      )
    ) {
      updatePassportDraftStatus('valid');
    }

    if (
      isEducationInformationDraftSpecified(
        questionnaire.educationalInformationForm
      )
    ) {
      updateEducationInformationDraftStatus('valid');
    }

    if (isCompatriotFormSpecified(questionnaire.compatriotForm)) {
      updateCompatriotDraftStatus('valid');
    }

    if (isVisaFormSpecified(questionnaire.visaRequestForm)) {
      updateVisaDraftStatus('valid');
    }

    dispatch(
      questionnaireSlice.actions.setNeedVisa(
        questionnaire.passportForm.needVisa
      )
    );

    dispatch(
      questionnaireSlice.actions.setIsCompatriot(
        questionnaire.passportForm.isCompatriot
      )
    );
  }, [questionnaire]);

  const draft = useMemo(() => {
    if (!questionnaire) return null;
    return getDraft(questionnaire);
  }, [questionnaire, getDraft]);

  const [
    saveDraft,
    {
      data: savedDraft,
      isLoading: isSaveLoading,
      isError: isSaveError,
      isSuccess: isSaveSuccess,
    },
  ] = useSaveDraftMutation();

  useEffect(() => {
    if (isSaveSuccess) {
      refetchQuestionnaire();
    }
  }, [isSaveSuccess]);

  useEffect(() => {
    if (questionnaire) {
      dispatch(questionnaireSlice.actions.setQuestionnaireId(questionnaire.id));
    }
  }, [questionnaire]);

  const save = useCallback((draft: DraftElement) => {
    const id = draft!.id;
    delete (draft as any).id;

    return saveDraft({ id, draft: draft as Draft });
  }, []);

  return {
    questionnaireId: questionnaire?.id,
    createdAt: questionnaire?.createdAt,
    draft,
    isDraftLoading: isQuestionnaireLoading,
    isDraftError: isQuestionnaireError,
    saveDraft: <T extends DraftElement>(updatedDraft: T) => save(updatedDraft),
    saveDraftArray: async <T extends DraftElement>(updatedDraftArray: T[]) => {
      return Promise.all(
        updatedDraftArray.map(updatedDraft => save(updatedDraft).unwrap())
      );
    },
    savedDraft,
    isSaveLoading,
    isSaveError,
  };
};

export default useDraft;
