import React, { FC, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import useAppSelector from 'hooks/common/useAppSelector';
import { selectAccessToken } from 'redux/selectors';

type TemplatePageProps = {
  children?: React.ReactNode;
  moderator?: React.ReactNode;
  navigateOnTokenUpdate?: boolean;
};

const TemplateAuthPage: FC<TemplatePageProps> = ({
  children,
  moderator,
  navigateOnTokenUpdate = true,
}) => {
  const token = useAppSelector(selectAccessToken);
  const navigate = useNavigate();

  useEffect(() => {
    if (!navigateOnTokenUpdate) return;
    if (token) {
      navigate('/');
    }
    if (token && moderator) {
      navigate('/admin');
    }
  }, [token, navigate]);

  return <div className="template-auth-page">{children}</div>;
};

export default TemplateAuthPage;
