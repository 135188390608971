import { SelectOption } from 'models/common';

import { ApplicationUploadStatus } from '../../../models/applications/applications';
import {
  useGetEducationFormsQuery,
  useGetEducationProgramsQuery,
  useGetFacultiesQuery,
  useGetPaymentFormsQuery,
} from '../../../redux/api/applications/directions-api';
import {
  applicationAdmissionConditionSelectOptions,
  applicationEpguStatusOptions,
  applicationStatusSelectOptions,
  coordinatorStatusSelectOptions,
  educationLevelSelectOptions,
  expertStatusSelectOptions,
  messagesStatusSelectOptions,
  trajectorySelectOptions,
} from '../../../utils/enum-select';
import { useGetCountriesSelectProps } from '../../common/api/useGetCountriesSelectProps';
import { useGetRecruiters } from '../../common/api/useGetRecruiters';
import useLazyIdEnumSelectProps from '../../common/useLazyIdEnumSelectProps';
import { useGetCoordinatorsSelectProps } from './useGetCoordinatorsSelectProps';
import { useGetExpertSelectProps } from './useGetExpertSelectProps';
import { useGetModeratorSelectProps } from './useGetModeratorSelectProps';

export enum ServeMethod {
  INDIVIDUAL = 'INDIVIDUAL',
  PARTNER = 'PARTNER',
}

export const useApplicationFilterOptions = () => {
  const educationFormSelectProps = useLazyIdEnumSelectProps(
    useGetEducationFormsQuery
  );

  const paymentFormSelectProps = useLazyIdEnumSelectProps(
    useGetPaymentFormsQuery
  );

  const facultiesFormSelectProps =
    useLazyIdEnumSelectProps(useGetFacultiesQuery);

  const countrySelectProps = useGetCountriesSelectProps();

  const recruiters = useGetRecruiters();

  const educationProgramsSelectProps = useLazyIdEnumSelectProps(
    useGetEducationProgramsQuery
  );

  const moderators = useGetModeratorSelectProps();
  const experts = useGetExpertSelectProps();
  const coordinators = useGetCoordinatorsSelectProps();

  const serveMethodOptions: SelectOption[] = [
    {
      value: ServeMethod.INDIVIDUAL,
      label: 'Индивидуально',
    },
    {
      value: ServeMethod.PARTNER,
      label: 'Партнер(Рекрутинговая компания)',
    },
  ];

  const uploadStatusOptions: SelectOption[] = [
    { value: ApplicationUploadStatus.NOT_UPLOADED, label: 'Не выгружено в 1С' },
    {
      value: ApplicationUploadStatus.UPLOAD_ERROR,
      label: 'Выгружено с ошибкой',
    },
    { value: ApplicationUploadStatus.UPLOADED, label: 'Выгружено в 1С' },
    { value: ApplicationUploadStatus.WAITING, label: 'Выгружено, в ожидании' },
  ];

  return {
    moderatorSelectProps: {
      options: moderators,
    },
    expertsSelectProps: {
      options: experts,
    },
    coordinatorsSelectProps: {
      options: coordinators,
    },
    educationFormSelectProps,
    paymentFormSelectProps,
    facultiesFormSelectProps,
    countrySelectProps: {
      options: countrySelectProps,
    },
    applicationStatusSelectProps: {
      options: applicationStatusSelectOptions,
    },
    expertStatusSelectProps: {
      options: expertStatusSelectOptions,
    },
    coordinatorStatusSelectProps: {
      options: coordinatorStatusSelectOptions,
    },
    messagesStateSelectProps: {
      options: messagesStatusSelectOptions,
    },
    recruitersSelectProps: recruiters,
    educationLevelSelectProps: {
      options: educationLevelSelectOptions,
    },
    trajectorySelectProps: {
      options: trajectorySelectOptions,
    },
    educationProgramsSelectProps,
    applicationAdmissionConditionSelectProps: {
      options: applicationAdmissionConditionSelectOptions,
    },
    serveMethodProps: {
      options: serveMethodOptions,
    },
    applicationEpguStatusSelectProps: {
      options: applicationEpguStatusOptions,
    },
    uploadStatusSelectProps: {
      options: uploadStatusOptions,
    },
  };
};
