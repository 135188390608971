import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import useAppSelector from 'hooks/common/useAppSelector';
import terms, { locale } from 'i18n';
import { EducationBasis } from 'models/staff/upload-queue';
import { selectDisableOnIos } from 'redux/selectors';

import {
  ApplicationDocument,
  DocumentTemplateType,
  DocumentType,
} from '../../../models/applications/application-documents';
import { DirectionRequestType } from '../../../models/applications/applications';
import {
  ApplicationEducationLevel,
  Trajectory,
} from '../../../models/applications/enum';
import { createToast } from '../../../redux/actions';
import {
  applicationDocumentsApi,
  useSignEnrollmentDocumentMutation,
} from '../../../redux/api/applications/application-documents-api';
import { useGetDirectionsByApplicationIdQuery } from '../../../redux/api/applications/directions-api';
import {
  selectApplication,
  selectCurrentEducationLevel,
  selectCurrentTrajectory,
} from '../../../redux/selectors/enrollee/application';
import { downloadFile } from '../../../utils/common';
import useAppDispatch from '../../common/useAppDispatch';

export const educationBasisToDirectionPaymentForm: Record<
  EducationBasis,
  DirectionRequestType
> = {
  [EducationBasis.CONTRACT]: DirectionRequestType.CONTRACT,
  [EducationBasis.BUDGET]: DirectionRequestType.BUDGET,
};

export const useDownloadApplicationEnrollmentDocument = (
  applicationId: number,
  educationLevel?: ApplicationEducationLevel,
  trajectory?: Trajectory,
  onDownload?: () => void,
  educationBasis?: EducationBasis
) => {
  const dispatch = useAppDispatch();

  const errorhandler = err => {
    if (err.status === 400)
      dispatch(
        createToast(terms.ERROR_DOC_DESIRED_DIRECTION_NOT_CHOSEN, 'danger')
      );
  };

  applicationDocumentsApi.useLazyGenerateDocumentQuery();
  const [generateEnrollmentDocumentWithMeta] =
    applicationDocumentsApi.useLazyGenerateEnrollmentDocumentWithMetaQuery();
  const [generateEnrollmentDocument] =
    applicationDocumentsApi.useLazyGenerateEnrollmentDocumentQuery();

  if (!educationLevel || !trajectory) return undefined;

  return (document: ApplicationDocument) => {
    const docType =
      // document.type === DocumentType.PERSONAL_INFO_AGREEMENT
      //   ? DocumentTemplateType.PERSONAL_INFO_AGREEMENT
      //   : document.type === DocumentType.ENROLL_AGREEMENT_DOCUMENT
      //   ? DocumentTemplateType.APPLICATION_ENROLL_AGREEMENT
      //   : educationLevel === ApplicationEducationLevel.PREPARATORY
      //   ? DocumentTemplateType.APPLICATION_PREPARATION
      //   : educationLevel === ApplicationEducationLevel.BACHELOR
      //   ? DocumentTemplateType.APPLICATION_UNDERGRADUATE
      //   : educationLevel === ApplicationEducationLevel.GRADUATE
      //   ? DocumentTemplateType.APPLICATION_PHD
      //   : educationLevel === ApplicationEducationLevel.SECONDARY
      //   ? DocumentTemplateType.APPLICATION_SPECIALTY
      //   : DocumentTemplateType.APPLICATION_MAGISTRACY;
      document.type === DocumentType.ENROLL_AGREEMENT_DOCUMENT
        ? DocumentTemplateType.APPLICATION_ENROLL_AGREEMENT
        : DocumentTemplateType.APPLICATION_ENROLL_WITHDRAW_AGREEMENT;

    if (!document.applicationDirectionId) return;

    if (trajectory === Trajectory.LOCAL) {
      if (!educationBasis) return;
      // const paymentFormMappingRU = {
      //   [DirectionRequestType.BUDGET]: ' (бюджет)',
      //   [DirectionRequestType.CONTRACT]: ' (контракт)',
      //   [DirectionRequestType.ALL]: '',
      // };
      // const paymentFormMappingEN = {
      //   [DirectionRequestType.BUDGET]: ' (budget)',
      //   [DirectionRequestType.CONTRACT]: ' (contract)',
      //   [DirectionRequestType.ALL]: '',
      // };
      const directionPaymentForm =
        educationBasisToDirectionPaymentForm[educationBasis];
      generateEnrollmentDocumentWithMeta({
        id: document.id,
        applicationDirectionId: document.applicationDirectionId,
        docType,
        directionPaymentForm,
      })
        ?.unwrap()
        .then(result => {
          const filename =
            locale === 'ru'
              ? `${document.title}.docx`
              : `${document.titleForeign}.docx`;
          downloadFile(new File([result], filename));
          onDownload?.();
        })
        .catch(errorhandler);
    } else {
      generateEnrollmentDocument({
        id: applicationId,
        applicationDirectionId: document.applicationDirectionId,
        docType,
      })
        ?.unwrap()
        .then(result => {
          const filename =
            locale === 'ru'
              ? `${document.title}.docx`
              : `${document.titleForeign}.docx`;
          downloadFile(new File([result], filename));
          onDownload?.();
        })
        .catch(errorhandler);
    }
  };
};

const useCardForDownloadEnrollmentFile = (
  blankId: number | undefined,
  refetchDocuments?: () => void,
  onDownload?: () => void
) => {
  const dispatch = useAppDispatch();
  const disableOnIos = useAppSelector(selectDisableOnIos);
  const { applicationId } = useParams();
  const trajectory = useSelector(selectCurrentTrajectory);
  const educationBasis = useSelector(selectApplication)?.educationBasis;

  const [signEnrollmentDocument] = useSignEnrollmentDocumentMutation();

  const currentEducationLevel = useAppSelector(selectCurrentEducationLevel);
  const selectedApplication = useAppSelector(
    state => state.application.selectedApplication
  );

  const { data: directions } = useGetDirectionsByApplicationIdQuery({
    applicationId: Number(applicationId),
  });

  const downloadDocument = useDownloadApplicationEnrollmentDocument(
    Number(applicationId),
    currentEducationLevel,
    trajectory,
    onDownload,
    educationBasis
  );

  const handleClickDownloadDocs = async (document: ApplicationDocument) => {
    if (!currentEducationLevel || !selectedApplication || !directions) return;

    if (
      Object.keys(directions).length === 0 &&
      document.type === DocumentType.APPLICATION_DOCUMENT
    ) {
      dispatch(createToast(terms.ERROR_FIRST_CHOOSE_DIRECTIONS, 'danger'));
      return;
    }

    if (!trajectory) {
      dispatch(createToast(terms.ERROR_TRAJECTORY_NOT_CHOSEN, 'danger'));
      return;
    }
    downloadDocument?.(document);
  };

  const errorhandler = err => {
    if (err.status === 400)
      dispatch(
        createToast(terms.ERROR_ALREADY_HAS_SIGNED_AGREEMENT_DOCUMENT, 'danger')
      );
  };

  const subscribeEnrollmentBlank = (
    applicationDirectionId: number,
    isSigned: boolean,
    isWithdraw: boolean
  ) => {
    if (blankId) {
      if (applicationId) {
        signEnrollmentDocument({
          applicationDirectionId: applicationDirectionId,
          enrollmentAgreement: {
            isWithdraw: isWithdraw,
            isSigned: isSigned,
          },
        })
          .unwrap()
          .then(() => {
            refetchDocuments?.();
            if (isSigned)
              dispatch(createToast(terms.DOC_WAS_SIGNED, 'success'));
          })
          .catch(errorhandler);
      }
    }
  };

  return {
    handleClickDownloadDocs,
    subscribeEnrollmentBlank,
    disableOnIos,
  };
};

export default useCardForDownloadEnrollmentFile;
