import { useEffect } from 'react';

import { useMatch } from 'react-router-dom';

import { apiSlice } from 'redux/api/api-slice';
import { useIsImpersonate } from 'utils/domain/impersonate';

import {
  enrolleesApi,
  useGetByUserIdQuery,
} from '../../../../redux/api/questionnaire/enrollees-api';
import { useCreateOrGetEmptyQuestionnaireQuery } from '../../../../redux/api/questionnaire/questionnaire-api';
import { selectUser } from '../../../../redux/selectors';
import { questionnaireSlice } from '../../../../redux/slices/enrollee/questionnaire-slice';
import useAppDispatch from '../../../common/useAppDispatch';
import useAppSelector from '../../../common/useAppSelector';

export const useEnrolleeUserId = () => {
  const { id, role } = useAppSelector(selectUser);
  const isImpersonate = useIsImpersonate();

  // Вынужденная мера из-за недоступности userId через useParams из модалок
  const match = useMatch({
    path: '/admin/users/impersonate/:userId',
    end: false,
  });

  if (!role) return null;

  // Если impersonate, пробуем взять id юзера из урла
  if (isImpersonate) {
    const result = Number(match?.params.userId);
    return isNaN(result) ? null : result;
  }
  // Иначе возвращаем id текущего юзера
  return id;
};

export const useQuestionnaire = () => {
  const dispatch = useAppDispatch();
  const userId = useEnrolleeUserId();

  const { data: isEnrolleeExists } = enrolleesApi.useIsEnrolleeExistsQuery(
    userId!,
    {
      skip: userId === null,
      refetchOnMountOrArgChange: true,
    }
  );

  const enrolleeQuestionnaireResult = useGetByUserIdQuery(userId!, {
    skip: userId === null || !isEnrolleeExists,
  });

  useEffect(() => {
    dispatch(questionnaireSlice.actions.setReadonly(isEnrolleeExists));
  }, [isEnrolleeExists]);

  const questionnaireResult = useCreateOrGetEmptyQuestionnaireQuery(userId!, {
    skip: userId === null || isEnrolleeExists || isEnrolleeExists === undefined,
  });

  return !isEnrolleeExists ? questionnaireResult : enrolleeQuestionnaireResult;
};

export enum Invalidateable {
  ENROLLEE,
  QUESTIONNAIRE,
}

export const useRefetchQuestionnaire = () => {
  const dispatch = useAppDispatch();
  return (invalidateEnrolleeExists = true, invalidateable?: Invalidateable) => {
    if (invalidateEnrolleeExists) {
      dispatch(apiSlice.util.invalidateTags(['IsEnrolleeExists']));
    }
    if (invalidateable === Invalidateable.ENROLLEE) {
      dispatch(apiSlice.util.invalidateTags(['Enrollee']));
    } else {
      dispatch(apiSlice.util.invalidateTags(['Questionnaire']));
    }
  };
};
