import React, { FC } from 'react';

import LegalRepresentativeForm from 'components/profile/LegalRepresentativeForm';

const LegalRepresentativePage: FC = () => (
  <div>
    <LegalRepresentativeForm />
  </div>
);

export default LegalRepresentativePage;
