import React, { FC } from 'react';

import cn from 'classnames';

import { ReactComponent as AvatarIcon } from 'assets/images/icons/avatar.svg';
import { ReactComponent as BurgerIcon } from 'assets/images/icons/burger.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';
import { ReactComponent as LogoutIcon } from 'assets/images/icons/logout.svg';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import useOnLogout from 'hooks/common/useOnLogout';
import useWindowsSize from 'hooks/common/useWindowsSize';
import terms from 'i18n';
import { changeMobileMenu } from 'redux/actions';
import {
  selectMobileMenu,
  selectUser,
  selectUserProfileImage,
} from 'redux/selectors';

import { useLocalizedFullName } from '../../../../../hooks/questionnaire/common/draft/useLocalizedFullName';

const UserMenu: FC = () => {
  const dispatch = useAppDispatch();
  const { isMobile } = useWindowsSize();
  const onLogout = useOnLogout();

  const showMobileMenu = useAppSelector(selectMobileMenu);
  const user = useAppSelector(selectUser);
  const {
    name,
    surname,
    isCorrect: isFullNameCorrect,
  } = useLocalizedFullName();

  const profileImage = useAppSelector(selectUserProfileImage);

  return isMobile ? (
    <div className={cn('user-menu', { 'close-bg': showMobileMenu })}>
      {showMobileMenu ? (
        <CloseIcon
          width="40"
          height="40"
          viewBox="-8 -3 25 15"
          onClick={() => dispatch(changeMobileMenu())}
        />
      ) : (
        <BurgerIcon
          width="40"
          height="40"
          viewBox="-8 -2 30 15"
          onClick={() => dispatch(changeMobileMenu())}
        />
      )}
    </div>
  ) : (
    <div className="user-menu">
      {profileImage ? (
        <img src={profileImage} alt={terms.PHOTO} className="user-menu__icon" />
      ) : (
        <AvatarIcon width="40" height="40" />
      )}

      <div className="user-menu__info">
        <div className="user-menu__name">
          {isFullNameCorrect ? `${surname} ${name}` : ''}
        </div>
        <div>
          {terms.YOUR_ID_IN_SYSTEM}: {user.idWithPrefix}
        </div>
      </div>

      <button className="user-menu__logout" onClick={onLogout}>
        <LogoutIcon width="14" height="12" />
      </button>
    </div>
  );
};

export default UserMenu;
