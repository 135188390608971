import { useNavigate } from 'react-router-dom';

import useAppDispatch from 'hooks/common/useAppDispatch';
import terms from 'i18n';
import { createToast, hideModal, showModal } from 'redux/actions';

import { ApplicationView } from '../../models/applications/applications';
import {
  applicationsApi,
  useDeleteApplicationMutation,
  useTakeToDirectionsEditingMutation,
  useWithdrawApplicationMutation,
} from '../../redux/api/applications/applications-api';

const useApplicationList = (refetchApplications?: () => void) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [deleteApplicationMutationFn] = useDeleteApplicationMutation();
  const [withdrawApplicationMutationFn] = useWithdrawApplicationMutation();
  const [takeToDirectionEditingMutationFn] =
    useTakeToDirectionsEditingMutation();

  const [takeBack] = applicationsApi.useTakeBackMutation();

  const navigateToApplication = (application: ApplicationView) =>
    navigate(`../${application.id}/filling-statements`);

  const navigateToCreateApplication = (application: ApplicationView) =>
    navigate(`../${application.id}/filling-statements/blank`);

  const navigateToDirections = (applicationId: number | string) =>
    navigate(`../${applicationId}/filling-statements/directions`);

  const deleteApplication = id => {
    deleteApplicationMutationFn(id)
      .unwrap()
      .then(() => {
        dispatch(createToast(terms.APPLICATION_DELETED, 'success'));
        refetchApplications?.();
      })
      .catch(() => {
        dispatch(createToast(terms.APPLICATION_DELETION_ERROR, 'danger'));
      });
  };

  const withdrawApplication = id => {
    withdrawApplicationMutationFn(id)
      .unwrap()
      .then(() => {
        dispatch(createToast(terms.APPLICATION_DELETED, 'success'));
        refetchApplications?.();
      })
      .catch(() => {
        dispatch(createToast(terms.APPLICATION_DELETION_ERROR, 'danger'));
      });
  };

  const takeApplicationBack = id => {
    takeBack(id)
      .unwrap()
      .then(() => {
        dispatch(createToast(terms.APPLICATION_TAKEN_FOR_EDITING, 'success'));
        refetchApplications?.();
      })
      .catch(() => {
        dispatch(createToast(terms.APPLICATION_DELETION_ERROR, 'danger'));
      });
  };

  const onDirectionsEditingClick = id =>
    dispatch(
      showModal({
        name: 'DIRECTIONS_EDITING_MODAL',
        data: {
          onApprove: () => {
            takeToDirectionEditingMutationFn(id)
              ?.unwrap()
              .then(application => {
                if (application) navigateToDirections(application.id);
              })
              .catch(() => {
                dispatch(
                  createToast(terms.APPLICATION_STATUS_CHANGING_ERROR, 'danger')
                );
              })
              .finally(() => dispatch(hideModal()));
          },
        },
      })
    );

  return {
    withdrawApplication,
    deleteApplication,
    takeApplicationBack,
    onDirectionsEditingClick,
    navigateToApplication,
    navigateToCreateApplication,
    navigateToDirections,
  };
};

export default useApplicationList;
