import React, { FC } from 'react';

import { useForm } from 'react-hook-form';

import TemplateModal from 'components/common/modals/TemplateModal';
import Button from 'components/common/ui-kit/Button';
import useAppSelector from 'hooks/common/useAppSelector';
import terms from 'i18n';
import { modalDataSelector } from 'redux/selectors';

import useAppDispatch from '../../../../hooks/common/useAppDispatch';
import { createToast, hideModal } from '../../../../redux/actions';
import { entranceTestsApi } from '../../../../redux/api/applications/entrance-tests-api';
import Input from '../../ui-kit/Input/view';

const EditEntranceTestModal: FC = () => {
  const modalData = useAppSelector(modalDataSelector) as {
    score?: number;
    applicationEntranceTestId: number;
    onSubmit?: () => void;
  };

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit: handleFormSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      score: modalData?.score,
    },
  });

  const [updateOrDeleteScore] =
    entranceTestsApi.useUpdateOrDeleteScoreMutation();

  const handleSubmit = handleFormSubmit(data => {
    updateOrDeleteScore({
      id: modalData.applicationEntranceTestId,
      score:
        data.score === null || data.score === undefined || isNaN(data.score)
          ? undefined
          : data.score,
    })
      .unwrap()
      .then(() => {
        modalData.onSubmit?.();
        dispatch(createToast('Оценка изменена', 'success'));
        dispatch(hideModal());
      })
      .catch(() => {
        createToast('Во время изменения оценки произошла ошибка', 'danger');
      });
  });

  return (
    <TemplateModal title="Редактировать вступительное испытание">
      <form onSubmit={handleSubmit} className="edit-profile-img-modal">
        <div>
          <Input
            placeholder={terms.SCORE}
            type="number"
            className="profile-form__field"
            {...register('score', {
              valueAsNumber: true,
              onChange: event => {
                if (event.target.value > 200) {
                  setValue('score', 200);
                }
                if (event.target.value < 0) {
                  setValue('score', 0);
                }
              },
            })}
          />
        </div>
        <footer className="edit-profile-img-modal__footer">
          <Button theme="success" className="edit-profile-img-modal__button">
            {terms.SAVE}
          </Button>
        </footer>
      </form>
    </TemplateModal>
  );
};

export default EditEntranceTestModal;
