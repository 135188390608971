import { useEffect, useMemo } from 'react';

import { showModal } from '../../redux/actions';
import { selectChatMessages, selectChatSocket } from '../../redux/selectors';
import useAppDispatch from '../common/useAppDispatch';
import useAppSelector from '../common/useAppSelector';

export const useUnsentMessagesWarn = () => {
  const dispatch = useAppDispatch();
  const chatSocket = useAppSelector(selectChatSocket);

  const chatMessages = useAppSelector(selectChatMessages);

  const unsentMessagesCount = useMemo(
    () =>
      Object.values(chatMessages || {}).reduce(
        (sum, message) => sum + Number(!message.sent),
        0
      ),
    [chatMessages]
  );

  const beforeUnloadFunction = event => {
    if (unsentMessagesCount > 0) {
      event.preventDefault();
      event.returnValue = null;
      dispatch(
        showModal({
          name: 'UNSENT_MESSAGE_EXIT_MODAL',
          data: {
            messageCount: unsentMessagesCount,
          },
        })
      );
      return null;
    }
  };

  useEffect(() => {
    if (chatSocket)
      window.addEventListener('beforeunload', beforeUnloadFunction);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadFunction);
    };
  }, [chatSocket, chatMessages]);
};
