import React, { FC, useEffect } from 'react';

import { Outlet, useNavigate } from 'react-router-dom';

import useAppDispatch from 'hooks/common/useAppDispatch';
import { createToast } from 'redux/actions';

import CenterContainer from '../../../components/common/ui-kit/CenterContainer';
import Loader from '../../../components/common/ui-kit/Loader';
import useAppSelector from '../../../hooks/common/useAppSelector';
import terms from '../../../i18n';
import { useLazyGetByUserIdQuery } from '../../../redux/api/questionnaire/enrollees-api';
import { selectUser } from '../../../redux/selectors';

const StatementsPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useAppSelector(selectUser);

  const [getEnrollee, { isLoading }] = useLazyGetByUserIdQuery();

  useEffect(() => {
    if (id) {
      getEnrollee(id)
        .unwrap()
        .catch(() => {
          dispatch(
            createToast(
              terms.SUBMIT_QUESTIONNAIRE_BEFORE_ACCESSING_APPLICATIONS,
              'danger'
            )
          );

          navigate('/personal-information');
        });
    }
  }, []);

  if (isLoading) {
    return (
      <CenterContainer>
        <Loader />
      </CenterContainer>
    );
  }

  return <Outlet />;
};

export default StatementsPage;
