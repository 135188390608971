import React, { FC, PropsWithChildren, ReactNode } from 'react';

export type AdminPageProps = PropsWithChildren<{
  title: string;
  controls?: ReactNode;
}>;

const AdminPage: FC<AdminPageProps> = ({ title, children, controls }) => {
  return (
    <main className="admin-page">
      <div className="admin-page__header">
        <h1 className="admin-page__title">{title}</h1>
        {controls}
      </div>
      {children}
    </main>
  );
};

export default AdminPage;
