import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import terms from 'i18n';
import { useDateLessThanCurrentAndMoreThanBirthdayValidator } from 'utils/form-validators';

import {
  CreatePrivilegeRequest,
  Privilege,
  PrivilegeGroupingType,
  PrivilegeType,
} from '../../models/applications/privileges';
import { DateString, Entity, SelectOption } from '../../models/common';
import { createToast, hideModal } from '../../redux/actions';
import { privilegesApi } from '../../redux/api/applications/privileges-api';
import { selectCurrentEducationLevel } from '../../redux/selectors/enrollee/application';
import useAppDispatch from '../common/useAppDispatch';
import useAppSelector from '../common/useAppSelector';
import { useLazyUploadFiles } from '../files/useLazyUploadFiles';

type AddPrivilegesForm = {
  readonly privilegeType: SelectOption;
  readonly confirmationDocumentType?: SelectOption;
  readonly documentCode: string;
  readonly dateOfIssue: DateString;
  readonly documentId: number;

  readonly isDocumentAttached: boolean;
};

export const mapPrivilegeTypeToSelectOption = (
  type: PrivilegeType
): SelectOption => ({
  label: type.title,
  value: type.id,
});

const useAddPrivilegesForm = (
  applicationId: number,
  privilege: Privilege,
  type: PrivilegeGroupingType,
  userId?: number | null
) => {
  const dispatch = useAppDispatch();

  const [create] = privilegesApi.useCreatePrivilegeMutation();
  const [update] = privilegesApi.useUpdatePrivilegeMutation();

  const applicationEducationLevel = useAppSelector(selectCurrentEducationLevel);

  const { data: privilegeTypes } = privilegesApi.useGetPrivilegeTypesQuery({
    educationLevel: applicationEducationLevel,
    groupingType: type,
  });

  const [getConfirmationDocTypes] =
    privilegesApi.useLazyGetPrivilegesConfirmationDocTypesQuery();

  const [confirmationDocs, setConfirmationDocs] = useState<Entity[]>([]);

  const { register, handleSubmit, reset, watch, setValue, ...rest } =
    useForm<AddPrivilegesForm>();

  const fileProps = useLazyUploadFiles({
    initialFileIds: watch('documentId'),
    userId,
  });

  useEffect(() => {
    if (privilege) {
      getConfirmationDocTypes(privilege.privilegeTypeResponse.id)
        .unwrap()
        .then(types => {
          setConfirmationDocs(types);

          reset({
            ...privilege,
            privilegeType: {
              label: privilege.privilegeTypeResponse.title,
              value: privilege.privilegeTypeResponse.id,
            },
            confirmationDocumentType: {
              label: privilege.confirmationDocumentTypeResponse.title,
              value: privilege.confirmationDocumentTypeResponse.id,
            },
            isDocumentAttached: !!privilege.documentId,
          });
        });
    }
  }, []);

  useEffect(() => {
    setValue('isDocumentAttached', fileProps.files.length > 0);
  }, [fileProps.files]);

  const validateDate = useDateLessThanCurrentAndMoreThanBirthdayValidator();

  const fields = {
    privilegeType: register('privilegeType', {
      required: terms.REQUIRED_FIELD,
      onChange: ({ target: { value } }) => {
        if (value) {
          setValue('confirmationDocumentType', undefined);
          getConfirmationDocTypes(value.value)
            .unwrap()
            .then(types => setConfirmationDocs(types));
        }
      },
    }),

    confirmationDocumentType: register('confirmationDocumentType', {
      required: terms.REQUIRED_FIELD,
    }),

    documentCode: register('documentCode', { required: terms.REQUIRED_FIELD }),
    dateOfIssue: register('dateOfIssue', {
      required: terms.REQUIRED_FIELD,
      validate: value =>
        validateDate(
          value,
          'Дата выдачи должна быть позже даты рождения и раньше текущей даты'
        ),
    }),

    documentId: register('documentId'),
    isDocumentAttached: register('isDocumentAttached', {
      required: terms.REQUIRED_FIELD,
    }),
  };

  const onSubmit = handleSubmit(data => {
    fileProps
      .uploadFiles()
      .then(fileId => setValue('documentId', fileId))
      .then(() => {
        const request: CreatePrivilegeRequest = {
          ...data,
          confirmationDocumentTypeId: data.confirmationDocumentType?.value,
          privilegeTypeId: data.privilegeType.value,
          documentId: watch('documentId'),
          applicationId,
          groupingType: type,
        };

        return privilege
          ? update({
              ...request,
              id: privilege.id,
              groupingType: privilege.groupingType,
            }).unwrap()
          : create(request).unwrap();
      })
      .then(() => {
        dispatch(createToast('Привилегия была успешно добавлена', 'success'));
        dispatch(hideModal());
      });
  });

  return {
    fields,
    onSubmit,
    ...rest,
    fileProps,
    privilegeSelectOptions: privilegeTypes?.map(mapPrivilegeTypeToSelectOption),
    confirmationDocsSelectOptions: confirmationDocs.map(type => ({
      value: type.id,
      label: type.title,
    })),
  };
};

export default useAddPrivilegesForm;
