import { useEffect, useState } from 'react';

import {
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import {
  PrivilegeExtras,
  PrivilegeExtrasType,
} from '../../models/applications/privileges';
import { FileId } from '../../models/files';
import { privilegesApi } from '../../redux/api/applications/privileges-api';
import { useLazyUploadFiles } from '../files/useLazyUploadFiles';
import useFillingStatementsRoutesContext from '../statements/useFillingStatementsRoutesContext';
import { PrivilegeForm } from './usePrivilegeForm';

export const usePrivilegeFormCheckBoxes = ({
  privilegeExtras,
  watch,
  setValue,
  applicationId,
  clearErrors,
  getValues,
}: {
  readonly privilegeExtras?: PrivilegeExtras[];
  readonly applicationId: number;
  readonly watch: UseFormWatch<PrivilegeForm>;
  readonly setValue: UseFormSetValue<PrivilegeForm>;
  readonly clearErrors: UseFormClearErrors<PrivilegeForm>;
  readonly getValues: UseFormGetValues<PrivilegeForm>;
}) => {
  const [createExtras] = privilegesApi.useCreatePrivilegesExtrasMutation();
  const [deleteExtras] = privilegesApi.useDeletePrivilegeExtrasMutation();
  const [updateExtras] = privilegesApi.useUpdatePrivilegesExtrasMutation();
  const [reviveExtras] = privilegesApi.useRevivePrivilegeExtrasMutation();

  const { onNextPageNavigate } = useFillingStatementsRoutesContext();

  const specialConditionFileProps = useLazyUploadFiles({
    filename: 'Специальные_условия',
    initialFileIds: watch('specialConditionsDocumentId'),
  });
  const militarySupportFileProps = useLazyUploadFiles({
    filename: 'Особенности_приёма',
    initialFileIds: watch('militarySupportDocumentId'),
  });
  const disabledPersonFileProps = useLazyUploadFiles({
    filename: 'Справка_об_инвалидности',
    initialFileIds: watch('disabledPersonDocumentId'),
  });

  const [initialSpecialCondition, setInitialSpecialCondition] = useState<{
    readonly has: boolean;
    readonly specialConditions?: string;
    readonly documentId?: number;
  }>({
    has: false,
    specialConditions: undefined,
    documentId: undefined,
  });

  const [initialDisabledCondition, setInitialDisabledCondition] = useState<{
    readonly has: boolean;
    readonly documentId?: number;
  }>({
    has: false,
    documentId: undefined,
  });

  const [initialMilitaryCondition, setInitialMilitaryCondition] = useState<{
    readonly has: boolean;
    readonly documentId?: number;
  }>({
    has: false,
    documentId: undefined,
  });

  const onFileChange = (
    fileProps: { files: File[]; uploadFiles: () => Promise<FileId> },
    formProperty: keyof PrivilegeForm
  ) => {
    if (fileProps.files.length) {
      fileProps.uploadFiles().then(fileId => setValue(formProperty, fileId));
    } else {
      setValue(formProperty, undefined);
    }
  };

  useEffect(
    () =>
      onFileChange(specialConditionFileProps, 'specialConditionsDocumentId'),
    [specialConditionFileProps.files.length]
  );

  useEffect(
    () => onFileChange(militarySupportFileProps, 'militarySupportDocumentId'),
    [militarySupportFileProps.files.length]
  );

  useEffect(
    () => onFileChange(disabledPersonFileProps, 'disabledPersonDocumentId'),
    [disabledPersonFileProps.files.length]
  );

  const getSpecialCondition = (extras?: PrivilegeExtras[]) =>
    extras &&
    extras.find(
      extras =>
        extras.type === PrivilegeExtrasType.ENTRANCE_TEST_SPECIAL_CONDITION
    );

  const getMilitaryCondition = (extras?: PrivilegeExtras[]) =>
    extras &&
    extras.find(extras => extras.type === PrivilegeExtrasType.MILITARY_SUPPORT);

  const getDisabledCondition = (extras?: PrivilegeExtras[]) =>
    extras &&
    extras.find(extras => extras.type === PrivilegeExtrasType.DISABLED_PERSON);

  useEffect(() => {
    if (privilegeExtras) {
      const specialCondition = getSpecialCondition(privilegeExtras);
      const militaryCondition = getMilitaryCondition(privilegeExtras);
      const disabledCondition = getDisabledCondition(privilegeExtras);

      if (specialCondition) {
        if (specialCondition.active) {
          setValue('hasSpecialConditionsForEntranceTest', true);
          setValue('specialConditions', specialCondition.specialConditions);
          setValue('specialConditionsDocumentId', specialCondition.documentId);
        } else {
          setValue('hasSpecialConditionsForEntranceTest', false);
          setValue('specialConditions', undefined);
          setValue('specialConditionsDocumentId', undefined);
        }
      }

      if (militaryCondition) {
        if (militaryCondition.active) {
          setValue('hasMilitarySupport', true);
          setValue('militarySupportDocumentId', militaryCondition.documentId);
        } else {
          setValue('hasMilitarySupport', false);
          setValue('militarySupportDocumentId', undefined);
        }
      }

      if (disabledCondition) {
        if (disabledCondition.active) {
          setValue('isDisabledPerson', true);
          setValue('disabledPersonDocumentId', disabledCondition.documentId);
        } else {
          setValue('isDisabledPerson', false);
          setValue('disabledPersonDocumentId', undefined);
        }
      }

      setInitialSpecialCondition({
        has: getValues('hasSpecialConditionsForEntranceTest'),
        documentId: getValues('specialConditionsDocumentId'),
        specialConditions: getValues('specialConditions'),
      });

      setInitialDisabledCondition({
        has: getValues('isDisabledPerson'),
        documentId: getValues('disabledPersonDocumentId'),
      });

      setInitialMilitaryCondition({
        has: getValues('hasMilitarySupport'),
        documentId: getValues('militarySupportDocumentId'),
      });
    }
  }, [privilegeExtras]);

  const onSubmitClick = (data: PrivilegeForm) => {
    handleSpecialConditionsSubmit(data);
    handleDisabledPersonSubmit(data);
    handleMilitarySupportSubmit(data);
    onNextPageNavigate();
  };

  const handleSpecialConditionsSubmit = (data: PrivilegeForm) => {
    const specialCondition = getSpecialCondition(privilegeExtras);

    if (
      data.hasSpecialConditionsForEntranceTest ===
        initialSpecialCondition.has &&
      data.specialConditions === initialSpecialCondition.specialConditions &&
      data.specialConditionsDocumentId === initialSpecialCondition.documentId
    ) {
      return;
    }

    if (
      data.hasSpecialConditionsForEntranceTest &&
      data.specialConditions &&
      data.specialConditionsDocumentId
    ) {
      if (specialCondition) {
        if (specialCondition.active) {
          updateExtras({
            extraType: 'entrance-test-special-condition',
            id: specialCondition.id,
            specialConditions: data.specialConditions,
            documentId: data.specialConditionsDocumentId,
          });
        } else {
          reviveExtras(specialCondition.id);
        }
      } else {
        createExtras({
          extraType: 'entrance-test-special-condition',
          documentId: data.specialConditionsDocumentId,
          applicationId,
          specialConditions: data.specialConditions,
        });
      }
    }

    if (!data.hasSpecialConditionsForEntranceTest && specialCondition) {
      deleteExtras(specialCondition.id);
    }
  };

  const handleDisabledPersonSubmit = ({
    disabledPersonDocumentId,
    isDisabledPerson,
  }: PrivilegeForm) => {
    const disabledPerson = getDisabledCondition(privilegeExtras);

    if (
      isDisabledPerson === initialDisabledCondition.has &&
      disabledPersonDocumentId === initialDisabledCondition.documentId
    ) {
      return;
    }

    if (isDisabledPerson && disabledPersonDocumentId) {
      if (disabledPerson) {
        if (disabledPerson.active) {
          updateExtras({
            extraType: 'disabled-person',
            id: disabledPerson.id,
            documentId: disabledPersonDocumentId,
          });
        } else {
          reviveExtras(disabledPerson.id);
        }
      } else {
        createExtras({
          extraType: 'disabled-person',
          documentId: disabledPersonDocumentId,
          applicationId,
        });
      }
    }

    if (!isDisabledPerson && disabledPerson) {
      deleteExtras(disabledPerson.id);
    }
  };

  const handleMilitarySupportSubmit = ({
    hasMilitarySupport,
    militarySupportDocumentId,
  }: PrivilegeForm) => {
    const militaryCondition = getMilitaryCondition(privilegeExtras);

    if (
      hasMilitarySupport === initialMilitaryCondition.has &&
      militarySupportDocumentId === initialMilitaryCondition.documentId
    ) {
      return;
    }

    if (hasMilitarySupport && militarySupportDocumentId) {
      if (militaryCondition) {
        if (militaryCondition.active) {
          updateExtras({
            extraType: 'military-support',
            id: militaryCondition.id,
            documentId: militarySupportDocumentId,
          });
        } else {
          reviveExtras(militaryCondition.id);
        }
      } else {
        createExtras({
          extraType: 'military-support',
          documentId: militarySupportDocumentId,
          applicationId,
        });
      }
    }

    if (!hasMilitarySupport && militaryCondition) {
      deleteExtras(militaryCondition.id);
    }
  };

  return {
    onSubmitClick,
    specialConditionFileProps,
    militarySupportFileProps,
    disabledPersonFileProps,
    onSpecialsConditionChange: ({ target: { checked } }) => {
      const specialCondition = getSpecialCondition(privilegeExtras);

      if (!checked) {
        setValue('specialConditions', undefined);
        clearErrors('specialConditions');
        setValue('specialConditionsDocumentId', undefined);
      } else if (specialCondition && !specialCondition.active) {
        setValue('specialConditions', specialCondition.specialConditions);
        setValue('specialConditionsDocumentId', specialCondition.documentId);
      }
    },
    onDisabledConditionChange: ({ target: { checked } }) => {
      const disabledCondition = getDisabledCondition(privilegeExtras);

      if (!checked) {
        setValue('disabledPersonDocumentId', undefined);
      } else if (disabledCondition && !disabledCondition.active) {
        setValue('disabledPersonDocumentId', disabledCondition.documentId);
      }
    },
    onMilitaryConditionChange: ({ target: { checked } }) => {
      const militaryCondition = getMilitaryCondition(privilegeExtras);

      if (!checked) {
        setValue('militarySupportDocumentId', undefined);
      } else if (militaryCondition && !militaryCondition.active) {
        setValue('militarySupportDocumentId', militaryCondition.documentId);
      }
    },
  };
};
