import React, { FC } from 'react';

import { Controller } from 'react-hook-form';

import AdminTableListPage from 'components/admin/AdminTableListPage';
import { InputV2 } from 'components/common/ui-kit/Input/view';
import ReactSelect, {
  MultiValue,
  Option,
} from 'components/common/ui-kit/Select/view';
import { columnsContracts } from 'config/moderator-table';
import { ServeMethod } from 'hooks/moderator/applications/useApplicationFilterOptions';
import { useContractFilterOptions } from 'hooks/moderator/contracts/useContractFilterOptions';
import terms from 'i18n';
import { SelectOption } from 'models/common';
import { contractApi } from 'redux/api/applications/contract-api';
import { createInputV2Props } from 'utils/form-helpers';

const ModeratorContractPageFilter = ({ control, formState: { errors } }) => {
  const inputV2Props = createInputV2Props(errors);

  const {
    paymentFormSelectProps,
    educationFormSelectProps,
    countrySelectProps,
    applicationStatusSelectProps,
    contractStateSelectOptions,
    facultiesFormSelectProps,
    trajectorySelectProps,
    educationProgramsSelectProps,
    moderatorSelectProps,
    serveMethodProps,
    recruitersSelectProps,
    educationLevelSelectProps,
  } = useContractFilterOptions();
  return (
    <>
      <Controller
        name="userId"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="ID пользователя"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="lastName"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Фамилия"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Имя"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="middleName"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Отчество"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Email"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="directionPaymentTypeIds"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={paymentFormSelectProps.options}
            placeholder="Основания поступления"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />

      <Controller
        name="directionEducationFormIds"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={educationFormSelectProps.options}
            placeholder={terms.MODE_OF_STUDY}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />

      <Controller
        name="citizenshipCountryIds"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            placeholder={terms.CITIZENSHIP}
            className="filter-form__field"
            options={countrySelectProps.options}
            {...(field as object)}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />

      <Controller
        name="applicationsStates"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={applicationStatusSelectProps.options}
            placeholder="Статус заявления"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />

      <Controller
        name="contractStates"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={contractStateSelectOptions.options}
            placeholder="Статус договора"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />
      <Controller
        name="directionName"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Направление"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="educationProgram"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={educationProgramsSelectProps.options}
            placeholder="Программа"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />

      <Controller
        name="facultyIds"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={facultiesFormSelectProps.options}
            placeholder="Подразделение"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />
      <Controller
        name="serveMethods"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            placeholder="Способ подачи"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option, MultiValue }}
            options={serveMethodProps.options}
          />
        )}
      />
      <Controller
        name="recruitedIds"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={recruitersSelectProps}
            placeholder="Партнер"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />
      <Controller
        name="applicationEducationLevel"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={educationLevelSelectProps.options}
            placeholder="Сценарий поступления"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />

      <Controller
        name="applicationTrajectories"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={trajectorySelectProps.options}
            placeholder="Приемная кампания"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />
      <Controller
        name="moderatorIds"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={moderatorSelectProps.options}
            placeholder="Модератор"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />
    </>
  );
};

export const renderContractsFilter = props => (
  <ModeratorContractPageFilter {...props} />
);

const ModeratorContractPage: FC = () => {
  return (
    <AdminTableListPage
      storeKey="contracts"
      title="Договоры"
      query={{
        use: contractApi.useGetAllQuery,
      }}
      columns={columnsContracts}
      filtersConfig={{
        defaultValues: {
          userId: '',
          lastName: '',
          firstName: '',
          middleName: '',
          email: '',
          directionPaymentTypeIds: undefined,
          directionEducationFormIds: undefined,
          citizenshipCountryIds: undefined,
          applicationsStates: undefined,
          contractStates: undefined,
          facultyIds: undefined,
          applicationTrajectories: undefined,
        },
        renderFilters: renderContractsFilter,
        mapFiltersToRequest: (filters, map) => {
          const request = {
            ...filters,
          };

          for (const filter in request) {
            if (
              Array.isArray(request[filter]) &&
              request[filter].length === 0
            ) {
              delete request[filter];
            } else if (request[filter] === '') {
              delete request[filter];
            }
          }

          if (request.serveMethods) {
            request.submitWithRecruiter = request.serveMethods.some(
              el => el.value === ServeMethod.PARTNER
            );
          }

          return map(request);
        },
      }}
      sortingConfig={{
        options: [
          { label: 'по дате', value: 'CREATED_AT' },
          { label: 'по ФИО', value: 'NAME' },
        ],
      }}
      shouldOpenInNewTab
    />
  );
};

export default ModeratorContractPage;
