import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { isRussianLocale } from '../../../i18n';
import { SubjectResponse } from '../../../models/applications/school-tests';
import { useLazyGetAllSubjectsQuery } from '../../../redux/api/applications/school-test-api';
import { useGetIdentificationDocumentTypeOptions } from '../../questionnaire/passportDraft/useGetIdentificationDocumentTypeOptions';

export const useSchoolTestFormOptions = () => {
  const { applicationId } = useParams();
  const [getAllSubjects] = useLazyGetAllSubjectsQuery();

  const [fetchedSubjects, setFetchedSubjects] = useState<SubjectResponse[]>([]);

  useEffect(() => {
    if (applicationId)
      getAllSubjects(Number(applicationId))
        ?.unwrap()
        .then(subjects => setFetchedSubjects(subjects));
  }, [applicationId]);

  const year = new Date().getFullYear();
  const identificationDocumentTypeOptions =
    useGetIdentificationDocumentTypeOptions(false);

  return {
    subjects:
      fetchedSubjects?.map(subject => ({
        label: isRussianLocale() ? subject.title : subject.titleForeign,
        value: subject.id,
      })) ?? [],
    identificationDocumentTypeOptions:
      identificationDocumentTypeOptions.options,
    years: [
      { value: +year, label: year + '' },
      { value: year - 1, label: year - 1 + '' },
      { value: year - 2, label: year - 2 + '' },
      { value: year - 3, label: year - 3 + '' },
      { value: year - 4, label: year - 4 + '' },
    ],
  };
};
