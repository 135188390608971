import { useEffect, useRef, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { NavRoute } from 'types/custom';

import useAppSelector from 'hooks/common/useAppSelector';
import terms from 'i18n';
import { ProfileSectionKey } from 'models/profile';
import { findNextRoute, getFilteredRoutes } from 'utils/route-helpers';

import { selectQuestionnaire } from '../../redux/selectors/enrollee/questionnaire';
import { useProfileRoutes } from './useProfileRoutes';

type Route = NavRoute<ProfileSectionKey>;

const useProvideProfileRoutes = (initialRoutes: Route[] = []) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = useRef<string>();
  const visitedRoutesPaths = useRef<string[]>([]);
  const [routes, setRoutes] = useState<Route[]>(initialRoutes);
  const initialRoutesPathNames = useRef(initialRoutes.map(r => r.to));
  const [needNavigate, setNeedNavigate] = useState(false);

  const [lastBtn, setBtn] = useState<string>('');

  const { visitedRoutes, unvisitedRoutes, visibleRoutes, visitedSortedRoutes } =
    getFilteredRoutes(routes, currentRoute.current);

  const { questionnaireId, createEnrolleeFromQuestionnaire } =
    useProfileRoutes();

  const { passportDraft, draftStates } = useAppSelector(selectQuestionnaire);

  useEffect(() => {
    if (passportDraft) {
      changeRouteVisibility({
        compatriot: passportDraft.isCompatriot,
        visa: passportDraft.needVisa,
      });
    }
  }, [passportDraft]);

  useEffect(() => {
    if (draftStates) {
      setRoutes(previousRoutes =>
        previousRoutes.map(route => ({
          ...route,
          status: draftStates[route.key],
        }))
      );
    }
  }, [draftStates]);

  useEffect(() => {
    if (needNavigate) {
      const route = findNextRoute(visibleRoutes, String(currentRoute.current));
      setNeedNavigate(false);

      if (route) {
        return navigate(route.to);
      } else if (questionnaireId !== null) {
        createEnrolleeFromQuestionnaire();
      }
    }
  }, [passportDraft, needNavigate, questionnaireId]);

  const onNextPageNavigate = () => {
    setNeedNavigate(true);
  };

  const changeRouteVisibility = (params: {
    [key in Route['key']]?: boolean;
  }) => {
    const keys = Object.keys(params);
    setRoutes(prev =>
      prev.map(route => {
        if (keys.includes(route.key)) {
          return { ...route, hidden: !params[route.key] };
        }
        return { ...route };
      })
    );
  };

  useEffect(() => {
    const { pathname } = location;

    const key = pathname.split('/personal-information/')[1];

    if (key === 'educational-background') {
      setBtn(terms.SAVE_AND_CONTINUE);
    } else {
      setBtn('');
    }

    if (!initialRoutesPathNames.current.includes(key)) return;

    if (!visitedRoutesPaths.current.includes(key)) {
      visitedRoutesPaths.current.push(key);
    }

    if (key !== currentRoute.current) {
      currentRoute.current = key;
    }
  }, [location.pathname]);

  return {
    lastBtn,
    routes: visibleRoutes,
    visitedRoutes,
    unvisitedRoutes,
    onNextPageNavigate,
    visitedSortedRoutes,
  };
};

export default useProvideProfileRoutes;
