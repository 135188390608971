import { useGeneralDataDraft } from 'hooks/questionnaire/common/draft/useGeneralDataDraft';
import terms from 'i18n';
import regexp from 'utils/regexp';

/**
 * Normalizes year to 4 characters.
 * @param date date in "yyyy-mm-dd" format
 */
export const normalizeYearTo4Characters = (date: string) => {
  if (!date) return date;

  const splitDate = date.split('-');
  const [, ...rest] = splitDate;
  let [year] = splitDate;

  if (year.length > 4) {
    year = year.slice(1, 5);

    if (year.startsWith('0')) {
      year = '1' + year.slice(1, 4);
    }
  }

  return [year, ...rest].join('-');
};

export const isDateGreaterThan1900 = (date: string | Date) =>
  new Date('1900-01-01') < new Date(date);

export const moreThan18age = (date: string | Date) => {
  const d = new Date(date);
  return (
    new Date(d.getFullYear() + 18, d.getMonth(), d.getDate()) <= new Date()
  );
};

export const differenceInYears = (
  first: string | Date,
  second: string | Date
) => {
  const diffInMs = Math.abs(+new Date(first) - +new Date(second));
  const diffDate = new Date(diffInMs);
  return Math.abs(diffDate.getUTCFullYear() - 1970);
};

export const dateInRange = (
  value?: string | Date,
  minYear = 10,
  maxYear = 100
) => {
  if (!value) return false;
  const diffBetweenNow =
    new Date(Date.now() - new Date(value).getTime()).getUTCFullYear() - 1970;
  return diffBetweenNow >= minYear && diffBetweenNow <= maxYear;
};

export const useDateLessThanCurrentAndMoreThanBirthdayValidator = () => {
  const { draft } = useGeneralDataDraft();
  return (value: Date | string, errorMessage: string) => {
    if (!draft?.birthday) return terms.ERROR_FIRST_SPECIFY_BIRTHDAY;
    const valueAsDate = new Date(value);
    return (
      (valueAsDate <= new Date() && valueAsDate > new Date(draft.birthday)) ||
      errorMessage
    );
  };
};

export const dateLessThanCurrentOne = (date: string | Date) =>
  new Date(date) < new Date();

/**
 * Validates snils.
 * @param snils snils in "nnn-nnn-nnn nn" format
 */
export const isValidSnils = (snils?: string) => {
  if (!snils) return false;
  const number = snils.toString().replace(/\D/g, '');
  if (number.length !== 11) return false;

  const controlCode = Number(number.substring(9));
  const calcCode = number
    .substring(0, 9)
    .split('')
    .map((N, idx) => Number(N) * (9 - idx))
    .reduce((Sum, N) => Sum + N);

  return (
    (calcCode < 100 && calcCode === controlCode) ||
    ((calcCode === 100 || calcCode === 101) && controlCode === 0) ||
    calcCode % 101 === controlCode ||
    (calcCode % 101 === 100 && controlCode === 0)
  );
};

export const normalizeSnils = (snils: string) => {
  const part1 = snils
    .replace(/\D/g, '')
    .match(/.{1,3}/g)
    ?.join('-')
    .substring(0, 11);

  const lastChar = snils[snils.length - 1];
  if (snils.length === 12 && lastChar !== ' ') {
    return part1 + ' ' + lastChar;
  }

  const hasSecondPart = snils.length > 11;
  const part2 = hasSecondPart ? snils.substring(12, 14) : '';
  return part1 + (hasSecondPart ? ' ' + part2 : '') !== 'undefined'
    ? part1 + (hasSecondPart ? ' ' + part2 : '')
    : '';
};

export const getMobileValidators = (phoneCode?: string) => ({
  pattern: { value: regexp.mobile, message: terms.VALID_MOBILE },
  maxLength: {
    value: phoneCode === '+7' ? 10 : 1000000000000,
    message: terms.PHONE_LENGTH,
  },
  minLength: {
    value: phoneCode === '+7' ? 10 : 0,
    message: terms.PHONE_LENGTH,
  },
});

export const getPassportNumber = (isRussianPassport: boolean) => ({
  pattern: {
    value: isRussianPassport
      ? regexp.passportNumber
      : regexp.passportNumberForeign,
    message: isRussianPassport
      ? terms.ONLY_DIGITS_ALLOWED
      : terms.DIGITS_AND_SYMBOLS_ALLOWED,
  },
  maxLength: {
    value: isRussianPassport ? 6 : 10000000000,
    message: terms.PASSPORT_NUMBER_LENGTH,
  },
  minLength: {
    value: isRussianPassport ? 6 : 0,
    message: terms.PASSPORT_NUMBER_LENGTH,
  },
});

export const getPassportSeries = (
  required: boolean,
  isInternationRussianPassport = false
) => ({
  pattern: {
    value: required
      ? isInternationRussianPassport
        ? regexp.internationalRussianPassportSeries
        : regexp.passportSeries
      : regexp.passportSeriesForeign,
    message: required
      ? terms.ONLY_DIGITS_ALLOWED
      : terms.DIGITS_AND_SYMBOLS_ALLOWED,
  },
  maxLength: {
    value: required ? (isInternationRussianPassport ? 2 : 4) : 1000000000,
    message: isInternationRussianPassport
      ? terms.INTERNATIONAL_RUSSIAN_PASSPORT_SERIES_LENGTH
      : terms.PASSPORT_SERIES_LENGTH,
  },
  minLength: {
    value: required ? (isInternationRussianPassport ? 2 : 4) : 0,
    message: isInternationRussianPassport
      ? terms.INTERNATIONAL_RUSSIAN_PASSPORT_SERIES_LENGTH
      : terms.PASSPORT_SERIES_LENGTH,
  },
});

export const getDivisionCode = () => ({
  pattern: {
    value: regexp.divisionCode,
    message: terms.DIVISION_CODE_TYPE,
  },
  maxLength: {
    value: 7,
    message: terms.DIVISION_CODE_LENGTH,
  },
  minLength: {
    value: 7,
    message: terms.DIVISION_CODE_LENGTH,
  },
});

export const getBankIdentifierCode = () => ({
  pattern: {
    value: regexp.bankIdentifierCode,
    message: terms.BANK_IDENTIFIER_CODE,
  },
});

export const getCheckingAccount = () => ({
  pattern: {
    value: regexp.checkingAndCorrespondentAccounts,
    message: terms.CHECKING_ACCOUNT,
  },
});

export const getCorrespondentAccount = () => ({
  pattern: {
    value: regexp.checkingAndCorrespondentAccounts,
    message: terms.CORRESPONDENT_ACCOUNT,
  },
});
