import React, { FC, useEffect } from 'react';

import ApplicationsList from 'components/statements/ApplicationsList';
import CreatePageHeader from 'components/statements/CreatePageHeader';
import useAppSelector from 'hooks/common/useAppSelector';

import { useGetApplicationsMutation } from '../../../redux/api/applications/applications-api';
import { selectUser } from '../../../redux/selectors';

const StatementsListPage: FC = () => {
  const [getApplications, { data: applications }] =
    useGetApplicationsMutation();

  const { id: userId } = useAppSelector(selectUser);

  useEffect(() => {
    if (userId !== null) getApplications(userId);
  }, [userId]);

  return (
    <div className="create-page">
      <CreatePageHeader list={ApplicationsList} />
      {applications && (
        <ApplicationsList
          list={applications}
          refetch={userId === null ? () => {} : () => getApplications(userId)}
        />
      )}
    </div>
  );
};

export default StatementsListPage;
