import { useEffect } from 'react';

import useChatApplicantId from 'hooks/chat/useChatApplicantId';
import useChatId from 'hooks/chat/useChatId';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import { getUnreadCount, getUnreadCountApplicant } from 'redux/actions';
import { selectUserChats } from 'redux/selectors/common/chat';

const useChatInitBadge = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(state => state.user.id);
  const userChats = useAppSelector(selectUserChats);
  const applicantId = useChatApplicantId();
  const chatId = useChatId();

  const refetchBadge = () => {
    if (userId && applicantId && chatId && userId === applicantId) {
      dispatch(getUnreadCount({ chatId }));
    } else if (applicantId) {
      dispatch(getUnreadCountApplicant({ applicantId }));
    }
  };

  useEffect(() => {
    refetchBadge();
  }, [userId, userChats]);

  return refetchBadge;
};

export default useChatInitBadge;
