import useAppDispatch from 'hooks/common/useAppDispatch';
import { DocumentTemplateType } from 'models/applications/application-documents';
import { createToast } from 'redux/actions';
import { useUploadTemplateMutation } from 'redux/api/common/files-api';

export const useUploadDocumentTemplateType = () => {
  const [uploadTemplate] = useUploadTemplateMutation();
  const dispatch = useAppDispatch();
  return (documentTemplateType: DocumentTemplateType, file: Blob) => {
    uploadTemplate({ type: documentTemplateType, file })
      ?.unwrap()
      .then(() => {
        dispatch(createToast('Шаблон загружен', 'success'));
      })
      .catch(() => {
        dispatch(
          createToast('Во время загрузки шаблона произошла ошибка.', 'danger')
        );
      });
  };
};
