import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import useAppSelector from 'hooks/common/useAppSelector';
import terms from 'i18n';

import { createToast } from '../../redux/actions';
import { applicationsInfoApi } from '../../redux/api/applications/applications-info-api';
import {
  selectCurrentEducationLevel,
  selectCurrentTrajectory,
} from '../../redux/selectors/enrollee/application';
import useAppDispatch from '../common/useAppDispatch';

const useLevelPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id: userId } = useAppSelector(state => state.user);

  const [getAvailableEducationLevels, { data: educationLevels }] =
    applicationsInfoApi.useLazyGetPossibleEducationLevelsForUserQuery();

  const trajectory = useAppSelector(selectCurrentTrajectory);
  const trainingLevel = useAppSelector(selectCurrentEducationLevel);

  useEffect(() => {
    if (userId) {
      if (trajectory) {
        getAvailableEducationLevels({
          userId,
          trajectory,
        });
      } else {
        navigate('/application');
      }
    }
  }, [userId, trajectory]);

  const onSubmit = () => {
    if (!userId || !educationLevels) return;

    if (
      trainingLevel &&
      trajectory &&
      educationLevels.some(level => level.educationLevel === trainingLevel)
    ) {
      navigate('../directions-type');
    } else {
      dispatch(
        createToast(
          terms.CANNOT_CREATE_NEW_APPLICATION_WITH_USED_EDU_LEVEL,
          'danger'
        )
      );
    }
  };

  return {
    onSubmit,
    isSubmitButtonDisabled: trainingLevel === undefined,
  };
};

export default useLevelPage;
