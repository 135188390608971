import { useMemo } from 'react';

import useAppSelector from 'hooks/common/useAppSelector';
import { UserRole } from 'models/user';

const staffRoles = [
  UserRole.SUPPORT,
  UserRole.EXPERT,
  UserRole.COORDINATOR,
  UserRole.MODERATOR,
  UserRole.ADMIN,
  UserRole.INSTITUTE_STAFF,
  UserRole.SPECIALIST,
  UserRole.CALL_CENTER,
];

const useChatIsStaff = () => {
  const userRole = useAppSelector(state => state.user.role);
  return useMemo(() => staffRoles.includes(userRole), [userRole]);
};

export default useChatIsStaff;
