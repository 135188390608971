import React, { FC } from 'react';

import VisaForm from 'components/profile/VisaForm';

const VisaPge: FC = () => (
  <div>
    <VisaForm />
  </div>
);

export default VisaPge;
