import React, { FC } from 'react';

import { Controller } from 'react-hook-form';

import useAppDispatch from '../../../../hooks/common/useAppDispatch';
import { useGetEpguRejectReasonsSelectProps } from '../../../../hooks/moderator/applications/useGetEgpuRejectReasonsSelectProps';
import useSetEpguRejectReasonForm from '../../../../hooks/moderator/applications/useSetEpguRejectReasonForm';
import terms from '../../../../i18n';
import { SelectOption } from '../../../../models/common';
import { hideModal } from '../../../../redux/actions';
import Button from '../../ui-kit/Button';
import ReactSelect from '../../ui-kit/Select';
import TemplateModal from '../TemplateModal';
import './SetEpguRejectReasonModal.scss';

const SetEpguRejectReasonModal: FC = () => {
  const rejectReasonsProps = useGetEpguRejectReasonsSelectProps();

  const { fields, control, showRejectButton, handleRejectButtonClick } =
    useSetEpguRejectReasonForm();

  const dispatch = useAppDispatch();

  return (
    <TemplateModal title={terms.CONFIRM_CONSENT}>
      <div className="set-epgu-reject-reason-modal">
        <p>
          Чтобы отказать в заявлении, полученном из ЕПГУ, нужно указать причину
          отказа
        </p>
        <Controller
          name={fields.rejectReason.name}
          control={control}
          render={({ field }) => (
            <ReactSelect<SelectOption, true>
              styles={{
                valueContainer: provided => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: base => ({
                  ...base,
                  minWidth: '200px',
                }),
              }}
              className="filter-form__field"
              {...(field as object)}
              placeholder="Причина отказа"
              options={rejectReasonsProps.options}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
            />
          )}
        />
        {showRejectButton && (
          <Button
            type="button"
            onClick={() => {
              handleRejectButtonClick();
              dispatch(hideModal());
            }}
            className="set-epgu-reject-reason-modal__button"
          >
            Отказать
          </Button>
        )}
      </div>
    </TemplateModal>
  );
};

export default SetEpguRejectReasonModal;
