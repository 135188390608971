import { useEffect, useState } from 'react';

type UseInitializeInputsProps<T, Value> = {
  data: T | null;
  omitKeys: Array<keyof T>;
  onInit: (key: keyof T, value: Value) => void;
};

export const useInitializeInputs = <T, Value = any>({
  data,
  onInit,
  omitKeys,
}: UseInitializeInputsProps<T, Value>) => {
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (data && !initialized) {
      Object.keys(data).forEach(key => {
        const typedKey = key as keyof T;
        if (omitKeys.includes(typedKey)) return;
        onInit(typedKey, data[key]);
        setInitialized(true);
      });
    }
  }, [data, initialized, onInit]);
};

export default useInitializeInputs;
