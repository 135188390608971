import { FC, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Button from 'components/common/ui-kit/Button';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import { UserRole } from 'models/user';
import { createToast } from 'redux/actions';
import { selectUserRole } from 'redux/selectors';

import Checkbox from '../../../components/common/ui-kit/Checkbox';
import ModeratorDataList from '../../../components/moderator/ModeratorDataList';
import useModeratorEpguInfoForm from '../../../hooks/moderator/applications/useModeratorEpguInfoForm';
import { EpguInfo } from '../../../models/applications/applications';
import { DataList, DataListItemAlignment } from '../../../models/moderator';
import './ModeratorEgpuInfoPage.scss';
import {
  staffApplicationsApi,
  useLazyGetEpguRejectReasonDescriptionQuery,
  useLazyGetStaffApplicationByIdQuery,
} from '../../../redux/api/staff/staff-applications-api';
import { createCheckboxProps } from '../../../utils/form-helpers';

const ModeratorEpguInfoPage: FC = () => {
  const dispatch = useAppDispatch();
  const role = useAppSelector(selectUserRole);
  const { id: applicationId } = useParams();
  const [clearUndefinedFields, { isLoading }] =
    staffApplicationsApi.useClearUndefinedFieldsMutation();

  const [epguInfo, setEpguInfo] = useState<EpguInfo | undefined>();

  const [getApplication, { data: application }] =
    useLazyGetStaffApplicationByIdQuery();

  const [getRejectDescription, { data: rejectDescription }] =
    useLazyGetEpguRejectReasonDescriptionQuery();

  useEffect(() => {
    getApplication(Number(applicationId));
    if (application?.epguInfo) setEpguInfo(application.epguInfo);
  }, [application?.epguInfo]);

  useEffect(() => {
    if (epguInfo?.reasonRejectExternalId)
      getRejectDescription(epguInfo?.reasonRejectExternalId);
  }, [epguInfo]);

  const {
    fields,
    formState: { errors },
  } = useModeratorEpguInfoForm(
    Number(application?.id),
    !!application?.epguInfo?.epguManualSyncRequired
  );

  const checkboxProps = createCheckboxProps(errors);

  const list: DataList = [
    {
      title: 'Время последней синхронизации с ЕПГУ:',
      label: epguInfo?.changedAtEpguTimestamp || '-',
    },
    {
      title: 'Комментарий последней синхронизации с ЕПГУ:',
      label: epguInfo?.changedAtEpguComment || '-',
    },
    {
      title: 'Причина отказа в заявлении',
      label: rejectDescription?.name || '-',
    },
    {
      title: 'ID заявления в ЕПГУ:',
      label: epguInfo?.epguId || '-',
    },
    {
      title: 'JWT ID заявления в ЕПГУ:',
      label: epguInfo?.epguJwtId ? String(epguInfo?.epguJwtId) : '-',
    },
    {
      title: 'Ошибки в заявлении:',
      label: application?.undefinedFields ?? '-',
      alignment: DataListItemAlignment.START,
    },
  ];

  const handleClearUndefinedFieldsButtonClick = () => {
    clearUndefinedFields(Number(applicationId))
      .unwrap()
      .then(() => dispatch(createToast('Ошибки очищены', 'success')))
      .catch(() =>
        dispatch(
          createToast('Во время очистки ошибок произошла ошибка', 'danger')
        )
      );
  };

  return (
    <>
      {epguInfo && (
        <div className="moderator-epgu-info-page">
          <h2>Это заявление получено из ЕПГУ</h2>
          <ModeratorDataList list={list} />
          {application?.undefinedFields && role !== UserRole.CALL_CENTER && (
            <Button
              onClick={handleClearUndefinedFieldsButtonClick}
              style={{ marginBottom: 10 }}
              size="small"
              disabled={isLoading}
            >
              Очистить проблемы
            </Button>
          )}
          <Checkbox
            text="Необходима ручная синхронизация с ЕПГУ"
            {...checkboxProps(fields.epguManualSyncRequired)}
          />
        </div>
      )}
    </>
  );
};

export default ModeratorEpguInfoPage;
