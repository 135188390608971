import React, { FC } from 'react';

import terms from 'i18n';

import Loader from '../../../components/common/ui-kit/Loader';
import { useApplicationDuplicatesPage } from '../../../hooks/moderator/applications/useApplicationDuplicatesPage';
import { formatDateTimeToRuFormat } from '../../../utils/date';

const ModeratorApplicationDuplicatesPage: FC = () => {
  const { duplicates, countries } = useApplicationDuplicatesPage();

  if (!duplicates || !countries) return <Loader />;

  return duplicates.length === 0 ? (
    <p>Дубликатов в системе нет</p>
  ) : (
    <table className="table-list__body">
      <thead>
        <tr>
          <th>ID</th>
          <th>ФИО</th>
          <th>Гражданство</th>
          <th>Статус заявления</th>
          <th>Дата подачи</th>
          <th>Уровень обр-я</th>
          <th>Направление</th>
          <th>Программа</th>
        </tr>
      </thead>
      <tbody>
        {duplicates.map((duplicate, index) => (
          <tr key={index}>
            <td>{duplicate?.userIdWithPrefix}</td>
            <td>{duplicate.fullName}</td>
            <td>
              {
                countries.find(
                  country => country.id === duplicate?.citizenshipId
                )?.title
              }
            </td>
            <td>{terms[duplicate.state]}</td>
            <td>{formatDateTimeToRuFormat(duplicate.createdAt)}</td>
            <td>{terms[duplicate.educationLevel]}</td>
            <td>{duplicate.direction}</td>
            <td>{duplicate.educationProgram ?? '-'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ModeratorApplicationDuplicatesPage;
