import React, { FC } from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Button from 'components/common/ui-kit/Button';
import Table from 'components/statements/OlympiadsForm/OlympiadTable';
import useAppSelector from 'hooks/common/useAppSelector';
import { useEnrolleeUserId } from 'hooks/questionnaire/common/draft/useQuestionnaire';
import useOlympiadsForm from 'hooks/statements/achievements/useOlympiadsForm';
import terms from 'i18n';
import { showModal } from 'redux/actions';

import { Olympiad } from '../../../models/applications/olympiads';
import {
  selectApplication,
  selectIsApplicationReadonly,
} from '../../../redux/selectors/enrollee/application';
import { useIsImpersonate } from '../../../utils/domain/impersonate';

interface OlympiadsFormProps {
  needToFetchOlympiads: boolean;
  olympiads: Array<Olympiad>;
  refetchAchievementsForm: () => void;
}
const OlympiadsForm: FC<OlympiadsFormProps> = ({
  needToFetchOlympiads,
  olympiads,
  refetchAchievementsForm,
}) => {
  const dispatch = useDispatch();

  const { applicationId } = useParams();
  const isImpersonate = useIsImpersonate();

  const readOnly =
    useAppSelector(selectIsApplicationReadonly) && !isImpersonate;

  const { onDelete, onDownloadFile, onEdit } = useOlympiadsForm(
    Number(applicationId),
    refetchAchievementsForm
  );

  const application = useAppSelector(selectApplication);
  const userId = useEnrolleeUserId();

  const onOpenOlympiadsModal = () => {
    dispatch(
      showModal({
        name: 'ADD_OLYMPIAD_MODAL',
        data: {
          applicationId,
          educationLevel: application?.educationLevel,
          refetchAchievementsForm,
          userId,
        },
      })
    );
  };

  return (
    <>
      <h4 className="achievements__title">{terms.OLYMPIADS}</h4>
      <Button
        type="button"
        className="achievements__button"
        onClick={onOpenOlympiadsModal}
        disabled={readOnly}
      >
        {terms.ADD_BUTTON}
      </Button>
      {olympiads.length > 0 && (
        <Table
          items={olympiads}
          onDelete={onDelete}
          onEdit={onEdit}
          onDownload={onDownloadFile}
          readOnly={readOnly}
        />
      )}
    </>
  );
};

export default OlympiadsForm;
