import { RecruiterResponse } from 'models/questionnaire/recruiters';
import { apiSlice } from 'redux/api/api-slice';

const baseUrl = `${process.env.REACT_APP_USER_MANAGER_API_URL}/recruiters`;

export const recruiterApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getRecruiters: builder.query<RecruiterResponse[], void>({
      query: () => `${baseUrl}/`,
    }),
  }),
});

export const { useGetRecruitersQuery } = recruiterApi;
