import { useEffect } from 'react';

import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import { setChatType } from 'redux/actions';
import { selectUserChats } from 'redux/selectors/common/chat';

const useChatTypeInit = () => {
  const dispatch = useAppDispatch();
  const userChats = useAppSelector(selectUserChats);

  useEffect(() => {
    if (userChats) dispatch(setChatType(Object.keys(userChats)[0]));
  }, [userChats]);
};

export default useChatTypeInit;
