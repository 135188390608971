import { ChatAttachment } from 'models/chat';

export type MessageProps = {
  text: string;
  date: Date;
  attachments?: ChatAttachment[];
  className?: string;
};

export const timestampToDate = (timestamp: string) => {
  const utcDate = new Date(timestamp);
  return new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000);
};

export const formatDate = (date: Date) =>
  `${date.toLocaleDateString('ru-RU')} ${date.toLocaleTimeString('ru-RU', {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
  })}`;

const isChatLastMessageInView = (chat: Element | null) => {
  const chatViewport = chat?.getBoundingClientRect();
  const lastMessage = chat?.firstElementChild?.getBoundingClientRect();
  if (!chatViewport || !lastMessage) return false;
  return lastMessage.bottom <= chatViewport.bottom;
};

export const isLastMessageViewed = (
  chatOpened: boolean,
  chat: Element | null,
  isLoaded: boolean
) =>
  chatOpened &&
  document.hasFocus() &&
  isLoaded &&
  isChatLastMessageInView(chat);
