import { AdmissionBasis } from 'models/applications/directions';
import { ApplicationEducationLevel } from 'models/applications/enum';

export const getColumnSet = <T extends object>(
  columnsSets: {
    [key in ApplicationEducationLevel]?:
      | {
          [key in AdmissionBasis]: (keyof T | string)[];
        }
      | string[];
  },
  educationLevel: ApplicationEducationLevel,
  admissionBasis: AdmissionBasis
): string[] | undefined => {
  const setByEducationLevel = columnsSets[educationLevel];
  if (setByEducationLevel === undefined || Array.isArray(setByEducationLevel))
    return setByEducationLevel;
  return setByEducationLevel[admissionBasis] as string[];
};
