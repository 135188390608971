import {
  ApplicationExpertExaminationResult,
  ApplicationId,
  ApplicationView,
} from '../../../../models/applications/applications';
import { apiSlice } from '../../api-slice';

const baseUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/applications/expert-managed`;

export const expertApplicationsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    rejectApplicationByExpert: builder.mutation<ApplicationView, ApplicationId>(
      {
        query: applicationId => ({
          url: `${baseUrl}/reject-by-expert/${applicationId}`,
          method: 'POST',
        }),
      }
    ),

    approveApplicationByExpert: builder.mutation<
      ApplicationView,
      ApplicationId
    >({
      query: applicationId => ({
        url: `${baseUrl}/approve-by-expert/${applicationId}`,
        method: 'POST',
      }),
    }),

    takeApplicationToWorkByExpert: builder.mutation<
      ApplicationView,
      ApplicationId
    >({
      query: applicationId => ({
        url: `${baseUrl}/take-to-work/${applicationId}`,
        method: 'POST',
      }),
    }),

    getExaminationResult: builder.query<
      ApplicationExpertExaminationResult,
      ApplicationId
    >({
      query: applicationId => ({
        url: `${baseUrl}/examination-results/${applicationId}`,
      }),
    }),

    saveExaminationResult: builder.mutation<
      void,
      {
        applicationId: ApplicationId;
        request: ApplicationExpertExaminationResult;
      }
    >({
      query: ({ applicationId, request }) => ({
        url: `${baseUrl}/examination-results/${applicationId}`,
        method: 'POST',
        body: request,
      }),
    }),
  }),
});

export const {
  useApproveApplicationByExpertMutation,
  useRejectApplicationByExpertMutation,
  useGetExaminationResultQuery,
  useSaveExaminationResultMutation,
} = expertApplicationsApi;
