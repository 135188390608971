import { selectCurrentApplication } from 'redux/selectors/staff/applications';

import {
  ApplicationView,
  DirectionRequestType,
} from '../../../models/applications/applications';
import {
  ApplicationEducationLevel,
  ApplicationState,
} from '../../../models/applications/enum';
import { RootState } from '../../../types/redux';

export const selectApplicationState = (state: RootState) => state.application;

export const selectCurrentTrajectory = (state: RootState) =>
  selectApplicationState(state).current.trajectory;

export const selectCurrentEducationLevel = (state: RootState) =>
  selectApplicationState(state).current.trainingLevel;

export const selectCurrentEducationalInfo = (state: RootState) =>
  selectApplicationState(state).current.educationalInfo;

export const selectApplicationEducationLevel = (
  state: RootState
): ApplicationEducationLevel | undefined =>
  selectApplication(state)?.educationLevel;

export const selectApplication = (state: RootState): ApplicationView | null =>
  state.application.selectedApplication;

export const selectIsApplicationSubmittable = (state: RootState): boolean =>
  !!state.application.isApplicationSubmittable;

export const selectDirectionsRequestType = (
  state: RootState
): DirectionRequestType =>
  selectApplicationState(state).current.directionsRequestType;

export const selectIsApplicationReadonly = (state: RootState): boolean => {
  const application = selectApplication(state);
  const currentApplication = selectCurrentApplication(state); // проверка на impersonate

  return (
    !currentApplication &&
    !!application &&
    (![ApplicationState.DRAFT, ApplicationState.SENT_FOR_EDITING].includes(
      application.state
    ) ||
      application.createdFromEpgu)
  );
};

export const selectIsApplicationDirectionsReadonly = (
  state: RootState
): boolean => {
  const application = selectApplication(state);
  const currentApplication = selectCurrentApplication(state); // проверка на impersonate

  return (
    !currentApplication &&
    !!application &&
    (![
      ApplicationState.DRAFT,
      ApplicationState.SENT_FOR_EDITING,
      ApplicationState.DIRECTIONS_EDITING,
    ].includes(application.state) ||
      application.createdFromEpgu)
  );
};

export const selectIsApplicationContractsListReadonly = (
  state: RootState
): boolean => {
  const application = selectApplication(state);
  const currentApplication = selectCurrentApplication(state); // проверка на impersonate

  return !currentApplication && !!application && application.createdFromEpgu;
};

export const selectApplicationEntranceTests = (state: RootState) =>
  state.application.current.entranceTests;
