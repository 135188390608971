import React, { FC } from 'react';

import useAppDispatch from '../../../../hooks/common/useAppDispatch';
import useAppSelector from '../../../../hooks/common/useAppSelector';
import terms from '../../../../i18n';
import { hideModal } from '../../../../redux/actions';
import { modalDataSelector } from '../../../../redux/selectors';
import Button from '../../ui-kit/Button';
import TemplateModal from '../TemplateModal';
import './ClearExamDateTimeModal.scss';

const ClearExamDateTimeModal: FC = () => {
  const dispatch = useAppDispatch();
  const { onApprove, testTitle, testDateTime, testScore } =
    useAppSelector(modalDataSelector);

  return (
    <TemplateModal title={terms.CONFIRM_CONSENT}>
      <div className="clear-exam-date-time-modal__content">
        <p>
          {`${terms.EXAM_DATETIME_DELETE_TEXT_1} ${testTitle} ${terms.EXAM_DATETIME_DELETE_TEXT_2} ${testDateTime}`}
        </p>
        {testScore && (
          <p className="warn">{terms.EXAM_DATETIME_DELETE_PASSED}</p>
        )}
        <div>
          <Button theme="success" onClick={onApprove}>
            {terms.YES}
          </Button>
          <Button onClick={() => dispatch(hideModal())}>{terms.NO}</Button>
        </div>
      </div>
    </TemplateModal>
  );
};

export default ClearExamDateTimeModal;
