import { useEffect, useState } from 'react';

import terms, { isRussianLocale } from 'i18n';

import { FileId } from '../../models/files';
import { createToast } from '../../redux/actions';
import { filesApi } from '../../redux/api/common/files-api';
import { downloadFile } from '../../utils/common';
import { mimeTypes } from '../../utils/mime-types';
import { useDownloadFile } from '../common/api/useDownloadFile';
import useAppDispatch from '../common/useAppDispatch';
import { useEnrolleeUserId } from '../questionnaire/common/draft/useQuestionnaire';

export enum FileUploadError {
  FILE_NOT_ATTACHED = 'FILE_NOT_ATTACHED',
  SERVER_ERROR = 'SERVER_ERROR',
}

export type FileMetaView = {
  readonly name: string;
  readonly type: string;
  readonly id?: number;
};

export type UploadFilesFunction = (
  successCallback?: (fileId: FileId) => void,
  errorCallback?: () => void
) => Promise<FileId>;

/**
 * Хук для загрузки файлов с поддержкой ленивой загрузки.
 */
export const useLazyUploadFiles = ({
  filename,
  initialFileIds,
  userId: userIdParam,
}: {
  readonly filename?: string;
  readonly initialFileIds?: Array<FileId | undefined> | FileId | null;
  readonly userId?: number | null;
}) => {
  const dispatch = useAppDispatch();

  const enrolleeUserId = useEnrolleeUserId();
  const userId = userIdParam ?? enrolleeUserId;
  const [files, setFiles] = useState<File[]>([]);
  const [filesMeta, setFilesMeta] = useState<FileMetaView[]>([]);

  const [uploadFile] = filesApi.useUploadFileMutation();
  const [pdfizeFiles] = filesApi.usePdfizeMutation();
  const download = useDownloadFile();

  const { data: fileMeta } = filesApi.usePreviewFilesQuery(
    {
      userId: userId!,
      ids: initialFileIds
        ? Array.isArray(initialFileIds)
          ? initialFileIds.map(el => el!)
          : [initialFileIds!]
        : [],
    },
    {
      skip: !initialFileIds || !userId,
    }
  );

  useEffect(() => {
    if (fileMeta) {
      setFilesMeta(
        fileMeta.map(meta => ({
          name: meta.originalName,
          type: meta.contentType,
          id: meta.id,
        }))
      );
    }
  }, [JSON.stringify(fileMeta)]);

  const uploadFiles: UploadFilesFunction = (successCallback, errorCallback) => {
    if (filesMeta.length === 0 || !userId)
      return Promise.reject(FileUploadError.FILE_NOT_ATTACHED);

    if (files.length === 0 && filesMeta.length === 1 && filesMeta[0].id) {
      return Promise.resolve(filesMeta[0].id);
    }

    let request;
    const name = filename ?? files[0]?.name.split('.')[0];
    if (files.length === 1 && files[0].type === mimeTypes.pdf) {
      request = uploadFile({
        file: files[0],
        userId,
        fileName: name,
      });
    } else {
      request = pdfizeFiles({
        userId,
        fileName: name,
        files,
      });
    }

    return request
      .unwrap()
      .then(fileId => {
        if (successCallback) successCallback(fileId);
        return fileId;
      })
      .catch(e => {
        if (e.data?.message && e.data?.messageRu) {
          dispatch(
            createToast(
              isRussianLocale() ? e.data.messageRu : e.data.message,
              'danger'
            )
          );
        } else {
          dispatch(createToast(terms.ERROR_HAS_OCCURRED, 'danger'));
        }
        if (errorCallback) errorCallback();
        return Promise.reject(FileUploadError.SERVER_ERROR);
      });
  };

  const onChange = (files: File[]) => {
    setFiles(files);
    setFilesMeta(
      files.map(file => ({
        name: file.name,
        type: file.type,
      }))
    );
  };

  const onDrop = (files: File[]) => {
    setFiles(prev => [...prev, ...files]);
    setFilesMeta(prev => [
      ...prev,
      ...files.map(file => ({
        name: file.name,
        type: file.type,
      })),
    ]);
  };

  const onDelete = (file: FileMetaView) => {
    setFiles(prev => prev.filter(f => f.name !== file.name));
    setFilesMeta(prev => prev.filter(f => f.name !== file.name));
  };

  const onDownloadFile = (fileMeta: FileMetaView) =>
    fileMeta?.id && userId
      ? download({ fileId: fileMeta.id, userId, fileName: fileMeta.name })
      : downloadFile(files.find(f => f.name === fileMeta.name));

  return {
    files,
    onDrop,
    onChange,
    onDelete,
    filesMeta,
    uploadFiles,
    onDownloadFile,
  };
};
