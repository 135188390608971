import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  EducationLevel,
  EducationType,
  I18nEnum,
  IdentificationDocumentType,
  MobileCountryCode,
} from 'models/enum';

import { isRussianLocale } from '../../../i18n';
import { Country } from '../../../models/common-entities';

const ACCEPT_LANGUAGE_HEADER = 'Accept-Language';

export const enumApi = createApi({
  reducerPath: 'enumApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, _) => {
      isRussianLocale()
        ? headers.set(ACCEPT_LANGUAGE_HEADER, 'ru')
        : headers.set(ACCEPT_LANGUAGE_HEADER, 'en');

      return headers;
    },
  }),
  endpoints: builder => ({
    getCountries: builder.query<Country[], void>({
      query: () =>
        `${process.env.REACT_APP_USER_MANAGER_API_URL}/enums/countries`,
    }),
    getMobileCountryCodes: builder.query<I18nEnum<MobileCountryCode>, void>({
      query: () => ({
        url: `${process.env.REACT_APP_USER_MANAGER_API_URL}/enums/mobile-codes`,
        params: {
          russianLocale: isRussianLocale(),
        },
      }),
    }),
    getIdentificationDocumentTypes: builder.query<
      I18nEnum<IdentificationDocumentType>,
      void
    >({
      query: () =>
        `${process.env.REACT_APP_USER_MANAGER_API_URL}/enums/identification-doc-types`,
    }),
    getEducationLevels: builder.query<I18nEnum<EducationLevel>, void>({
      query: () =>
        `${process.env.REACT_APP_USER_MANAGER_API_URL}/enums/education-levels`,
    }),
    getEducationTypes: builder.query<I18nEnum<EducationType>, void>({
      query: () =>
        `${process.env.REACT_APP_USER_MANAGER_API_URL}/enums/educational-doc-types`,
    }),
  }),
});

export const {
  useGetCountriesQuery,
  useGetMobileCountryCodesQuery,
  useGetIdentificationDocumentTypesQuery,
  useGetEducationLevelsQuery,
  useGetEducationTypesQuery,
} = enumApi;
