import React, { FC, useCallback, useEffect } from 'react';

import useAppDispatch from '../../../hooks/common/useAppDispatch';
import useAppSelector from '../../../hooks/common/useAppSelector';
import { ApplicationUserEducationalInformation } from '../../../models/applications/application-user-educational-information';
import { applicationsInfoApi } from '../../../redux/api/applications/applications-info-api';
import { selectUser } from '../../../redux/selectors';
import {
  selectCurrentEducationalInfo,
  selectCurrentEducationLevel,
} from '../../../redux/selectors/enrollee/application';
import { applicationSlice } from '../../../redux/slices/enrollee/application-slice';
import Loader from '../../common/ui-kit/Loader';
import EducationalDocumentElement from './EducationalDocumentElement';
import './EducationalDocumentList.scss';

const EducationalDocumentList: FC = () => {
  const dispatch = useAppDispatch();

  const educationalInfo = useAppSelector(selectCurrentEducationalInfo);
  const educationalLevel = useAppSelector(selectCurrentEducationLevel);

  const [
    getAvailableEducationalInformation,
    { data: availableEducationalInformation, isFetching, isUninitialized },
  ] = applicationsInfoApi.useLazyGetUserEducationalInformationQuery();

  const { id: userId } = useAppSelector(selectUser);

  const setEducationalDocument = useCallback(
    (value: ApplicationUserEducationalInformation) => {
      dispatch(applicationSlice.actions.setCurrentEducationalInfo(value));
    },
    [dispatch]
  );

  useEffect(() => {
    if (userId)
      getAvailableEducationalInformation({ userId, educationalLevel });
  }, [userId]);

  if (isFetching || isUninitialized) {
    return <Loader />;
  }

  return (
    <section className="educational-information-list">
      {availableEducationalInformation?.map(el => (
        <EducationalDocumentElement
          element={el}
          onClick={() => setEducationalDocument(el)}
          active={educationalInfo?.educationProfileId === el.educationProfileId}
        />
      ))}
    </section>
  );
};

export default EducationalDocumentList;
