import { isRussianLocale } from '../../i18n';
import { ChosenDirectionWithPriority } from '../../models/applications/directions';
import { LocalizedEntityWithExternalId } from '../../models/common';

export const buildContractNameFromDirection = (
  direction: ChosenDirectionWithPriority
): string =>
  isRussianLocale()
    ? `${
        direction?.direction.educationProgram?.title ??
        direction.direction.title
      } (${direction.direction.educationForm.title} форма)`
    : `${
        direction?.direction.educationProgram?.titleForeign ??
        direction.direction.titleForeign
      } (${direction.direction.educationForm.titleForeign})`;

export const buildContractNameFromDirectionProperties = ({
  educationProgramTitle,
  educationProgramTitleForeign,
  title,
  titleForeign,
  educationForm,
}: {
  readonly educationForm: LocalizedEntityWithExternalId;
  readonly educationProgramTitle?: string;
  readonly educationProgramTitleForeign?: string;
  readonly title: string;
  readonly titleForeign: string;
}): string =>
  isRussianLocale()
    ? `${educationProgramTitle || title} (${educationForm.title} форма)`
    : `${
        educationProgramTitleForeign ||
        titleForeign ||
        educationProgramTitle ||
        title
      } (${educationForm.titleForeign || educationForm.title})`;

export const collectDirections = (directions: {
  BUDGET?: ChosenDirectionWithPriority[];
  CONTRACT?: ChosenDirectionWithPriority[];
}): ChosenDirectionWithPriority[] => [
  ...(directions.BUDGET ?? []),
  ...(directions.CONTRACT ?? []),
];
