import React, { useRef, useState, DragEvent } from 'react';

import classNames from 'classnames';

import { ReactComponent as FileIcon } from 'assets/images/icons/file.svg';
import { ReactComponent as CrossIcon } from 'assets/images/icons/red-cross.svg';
import { ReactComponent as FileDownloadIcon } from 'assets/images/icons/upload-file.svg';
import terms from 'i18n';
import { downloadFile } from 'utils/common';

type FileUploaderProps = (
  | {
      isMultiple: false;
      value: File | null;
      onChange: (file: File | null) => void;
    }
  | {
      isMultiple: true;
      value: File[];
      onChange: (files: File[]) => void;
    }
) & {
  className?: string;
  disabled?: boolean;
  description?: string;
  accept?: string;
};

const FileUploaderV2 = ({
  className,
  disabled = false,
  description,
  isMultiple,
  value,
  onChange,
  accept,
}: FileUploaderProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [counter, setCounter] = useState(0);
  const isActive = counter > 0;

  const triggerInput = () => {
    inputRef.current?.click();
  };

  const handleDragEnter = () => {
    setCounter(prev => ++prev);
  };

  const handleDrageLeave = () => {
    setCounter(prev => --prev);
  };

  const handleDragOver = (event: DragEvent) => {
    event.preventDefault();
  };

  const updateValueFromFileList = (fileList: FileList) => {
    const newFiles = Array.from(fileList);
    if (isMultiple) {
      onChange(newFiles);
      return;
    }
    onChange(newFiles[0] ?? null);
    return;
  };

  const handleDrop = (event: DragEvent) => {
    event.preventDefault();
    const fileList = event.dataTransfer.files;
    if (!fileList) {
      return;
    }
    updateValueFromFileList(fileList);
    setCounter(0);
  };

  const handleDelete = (index: number) => {
    if (isMultiple) {
      onChange(value.splice(index, 1));
      return;
    }
    onChange(null);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    updateValueFromFileList(fileList);
  };

  const files = isMultiple ? value : value ? [value] : [];

  return (
    <div className={classNames('file-uploader__wrapper', className)}>
      {!!files.length && (
        <div className="file-uploader__files">
          {files.map((file, index) => (
            <div key={file.name} className="file-uploader__file">
              <FileIcon
                className="file-uploader__file-icon"
                onClick={() => downloadFile(file)}
              />

              {!disabled && (
                <button
                  className="file-uploader__cross"
                  onClick={() => handleDelete(index)}
                  type="button"
                >
                  <CrossIcon className="file-uploader__cross-icon" />
                </button>
              )}

              <span
                className="file-uploader__filename"
                onClick={() => downloadFile(file)}
                title={file.name}
              >
                {file.name}
              </span>
            </div>
          ))}
        </div>
      )}

      <label
        className={classNames(
          'file-uploader__area',
          {
            'file-uploader__active': isActive,
          },
          disabled && 'disabled'
        )}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDrageLeave}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <input
          type="file"
          className="file-uploader"
          multiple={isMultiple}
          disabled={disabled}
          onChange={handleInputChange}
          ref={inputRef}
          accept={accept}
        />

        <button
          className="file-uploader__button"
          onClick={triggerInput}
          disabled={disabled}
          type="button"
        >
          <div className="file-uploader__info">
            <FileDownloadIcon width="18" height="24" />

            <div className="file-uploader__description">
              <div>{terms.FILE_UPLOADER_TITLE}</div>

              {!files.length && <div>{description}</div>}
            </div>

            <div className="file-uploader__mobile-description">
              {description}
            </div>
          </div>
        </button>
      </label>
    </div>
  );
};

export default FileUploaderV2;
