export enum ImportTaskStatus {
  NEW = 'NEW',
  IN_PROCESS = 'IN_PROCESS',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export enum ImportTaskType {
  FIS_GIA = 'FIS_GIA',
  MANUAL_COMPETITIVE_GROUP_CHANGE = 'MANUAL_COMPETITIVE_GROUP_CHANGE',
}
