import React, { FC } from 'react';

import { useParams } from 'react-router-dom';

import ModeratorFile from 'components/moderator/ModeratorFile';
import { useDownloadApplicationDocument } from 'hooks/statements/documents/useCardForDownloadFile';
import { downloadFile } from 'utils/common';

import Loader from '../../../components/common/ui-kit/Loader';
import useAppSelector from '../../../hooks/common/useAppSelector';
import { useDownloadApplicationEnrollmentDocument } from '../../../hooks/statements/documents/useCardForDownloadEnrollmentFile';
import {
  DocumentTemplateType,
  DocumentType,
} from '../../../models/applications/application-documents';
import { translate } from '../../../models/common';
import {
  applicationDocumentsApi,
  useGetDocumentsQuery,
} from '../../../redux/api/applications/application-documents-api';
import { selectCurrentApplication } from '../../../redux/selectors/staff/applications';
import { getLocalDateTimeFormattedString } from '../../../utils/date';

const ModeratorDownloadsPage: FC = () => {
  const { id: applicationId } = useParams();

  const application = useAppSelector(selectCurrentApplication);

  const { data: documents } = useGetDocumentsQuery(Number(applicationId));

  const [generateDocument] =
    applicationDocumentsApi.useLazyGenerateDocumentQuery();

  const downloadDocument = useDownloadApplicationDocument(
    Number(applicationId),
    application?.educationLevel,
    application?.trajectory,
    () => {},
    application?.educationBasis
  );

  const downloadEnrollmentDocument = useDownloadApplicationEnrollmentDocument(
    Number(applicationId),
    application?.educationLevel,
    application?.trajectory,
    () => {},
    application?.educationBasis
  );

  if (!application || !documents) return <Loader />;

  return (
    <div className="moderator-documents-page">
      {(documents ?? []).map((document, index) => (
        <ModeratorFile
          key={index}
          title={translate(document)}
          content={getLocalDateTimeFormattedString(document.updatedAt)}
          upload={() => {
            if (
              [
                DocumentType.ENROLL_AGREEMENT_DOCUMENT,
                DocumentType.ENROLL_WITHDRAW_DOCUMENT,
              ].includes(document.type)
            )
              downloadEnrollmentDocument?.(document);
            else downloadDocument?.(document);
          }}
        />
      ))}
      <ModeratorFile
        title="Расписка об информировании"
        upload={() => {
          if (!application) return;

          generateDocument({
            id: Number(applicationId),
            docType: DocumentTemplateType.COMPLIANCE_LETTER_BILINGUAL,
          })
            ?.unwrap()
            .then(result => {
              downloadFile(
                new File([result], 'Расписка об информировании.docx')
              );
            });
        }}
      />

      <ModeratorFile
        title="Расписка за принятый оригинал документа об образовании"
        upload={() => {
          if (!application) return;

          generateDocument({
            id: Number(applicationId),
            docType:
              DocumentTemplateType.RECEIPT_FOR_ACCEPTED_ORIGINAL_EDUCATION_DOCUMENT,
          })
            ?.unwrap()
            .then(result => {
              downloadFile(
                new File(
                  [result],
                  'Расписка за принятый оригинал документа об образовании.docx'
                )
              );
            });
        }}
      />

      <ModeratorFile
        title="Бланк направления на предварительную экспертизу"
        upload={() => {
          if (!application) return;

          generateDocument({
            id: Number(applicationId),
            docType:
              DocumentTemplateType.APPLICATION_EXPERT_EXAMINATION_REFERRAL,
          })
            ?.unwrap()
            .then(result => {
              downloadFile(
                new File(
                  [result],
                  'Бланк направления на предварительную экспертизу.docx'
                )
              );
            });
        }}
      />

      <ModeratorFile
        title="Бланк предварительной экспертизы"
        upload={() => {
          if (!application) return;

          generateDocument({
            id: Number(applicationId),
            docType: DocumentTemplateType.APPLICATION_EXPERT_EXAMINATION_RESULT,
          })
            ?.unwrap()
            .then(result => {
              downloadFile(
                new File([result], 'Бланк предварительной экспертизы.docx')
              );
            });
        }}
      />

      <ModeratorFile
        title="Заявление об отзыве оригинала"
        upload={() => {
          if (!application) return;

          generateDocument({
            id: Number(applicationId),
            docType: DocumentTemplateType.APPLICATION_ORIGINAL_WITHDRAW,
          })
            ?.unwrap()
            .then(result => {
              downloadFile(
                new File([result], 'Заявление об отзыве оригинала.docx')
              );
            });
        }}
      />

      <ModeratorFile
        title="Заявление об отзыве документов + отзыв оригинала"
        upload={() => {
          if (!application) return;

          generateDocument({
            id: Number(applicationId),
            docType:
              DocumentTemplateType.APPLICATION_DOCUMENTS_ORIGINAL_WITHDRAW,
          })
            ?.unwrap()
            .then(result => {
              downloadFile(
                new File(
                  [result],
                  'Заявление об отзыве документов + отзыв оригинала.docx'
                )
              );
            });
        }}
      />

      <ModeratorFile
        title="Заявление об отказе от зачисления"
        upload={() => {
          if (!application) return;

          generateDocument({
            id: Number(applicationId),
            docType: DocumentTemplateType.APPLICATION_ENROLL_REJECT,
          })
            ?.unwrap()
            .then(result => {
              downloadFile(
                new File([result], 'Заявление об отказе от зачисления.docx')
              );
            });
        }}
      />

      <ModeratorFile
        title="Заявление об отказе от зачисления + отзыв оригинала"
        upload={() => {
          if (!application) return;

          generateDocument({
            id: Number(applicationId),
            docType:
              DocumentTemplateType.APPLICATION_ENROLL_REJECT_ORIGINAL_WITHDRAW,
          })
            ?.unwrap()
            .then(result => {
              downloadFile(
                new File(
                  [result],
                  'Заявление об отказе от зачисления + отзыв оригинала.docx'
                )
              );
            });
        }}
      />

      <ModeratorFile
        title="Заявление об отзыве документов"
        upload={() => {
          if (!application) return;

          generateDocument({
            id: Number(applicationId),
            docType: DocumentTemplateType.APPLICATION_DOCUMENTS_WITHDRAW,
          })
            ?.unwrap()
            .then(result => {
              downloadFile(
                new File([result], 'Заявление об отзыве документов.docx')
              );
            });
        }}
      />
    </div>
  );
};

export default ModeratorDownloadsPage;
