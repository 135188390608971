import React, { FC } from 'react';

import cn from 'classnames';

import TemplateModal from 'components/common/modals/TemplateModal';
import Button from 'components/common/ui-kit/Button';
import Checkbox from 'components/common/ui-kit/Checkbox';
import Input from 'components/common/ui-kit/Input';
import useAddDictionaryAdminModal from 'hooks/admin/useAddDictionaryAdminModal';
import useAppSelector from 'hooks/common/useAppSelector';
import terms from 'i18n';
import { modalDataSelector } from 'redux/selectors';

const AddDictionaryAdminModal: FC = () => {
  const { data } = useAppSelector(modalDataSelector);
  const { onSubmit, fields } = useAddDictionaryAdminModal(data);

  return (
    <TemplateModal
      title="Добавить раздел"
      hint="Зависимости необходимо указывать через запятую"
      className={cn({
        'template-modal-big': data.directoryName === 'competitive_groups',
      })}
    >
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className="profile-form__fields">
          {[...data.connections]
            .sort()
            .filter(item => item !== 'visible' && item !== 'duplicate')
            .map((item, index) => (
              <Input
                key={index + 300}
                placeholder={`${item}`}
                className="profile-form__field profile-form__field--fb-100"
                {...fields[item]}
              />
            ))}
        </div>
        <div className="profile-form__fields-checkboxes">
          {[...data.connections]
            .sort()
            .filter(item => item === 'visible' || item === 'duplicate')
            .map((item, index) => (
              <Checkbox
                key={index + 300}
                text={`${item === 'visible' ? 'Видимость' : item}`}
                theme="success-filled"
                {...fields[item]}
              />
            ))}
        </div>
        <Button
          theme="success"
          className="profile-form__button profile-form__button--align-left"
        >
          {terms.SAVE}
        </Button>
      </form>
    </TemplateModal>
  );
};

export default AddDictionaryAdminModal;
