import React, { FC, useState } from 'react';

import { useParams } from 'react-router-dom';

import { ReactComponent as DownloadIcon } from 'assets/images/icons/download.svg';
import { ReactComponent as FileIcon } from 'assets/images/icons/file.svg';
import Checkbox from 'components/common/ui-kit/Checkbox';
import ModeratorDataList from 'components/moderator/ModeratorDataList';
import ProfileSection from 'components/profile/ProfileSection';
import useAppDispatch from 'hooks/common/useAppDispatch';
import { UserRole } from 'models/user';
import { createToast } from 'redux/actions';
import { olympiadsApi } from 'redux/api/applications/olympiads-api';
import { selectUserRole } from 'redux/selectors';

import Loader from '../../../components/common/ui-kit/Loader';
import CheckListEntryValueSelectWithRoleChecker from '../../../components/moderator/CheckListEntryValueSelectWithRoleChecker';
import { useDownloadFileRaw } from '../../../hooks/common/api/useDownloadFileRaw';
import useAppSelector from '../../../hooks/common/useAppSelector';
import {
  PrivilegeExtrasType,
  PrivilegeGroupingType,
} from '../../../models/applications/privileges';
import { achievementsApi } from '../../../redux/api/applications/achievements-api';
import { privilegesApi } from '../../../redux/api/applications/privileges-api';
import { selectCurrentApplicationUser } from '../../../redux/selectors/staff/applications';

const headers = [
  'Признак',
  'Тип признака',
  'Документ',
  'Реквизиты',
  'Количество',
  'Дополнительная информация',
  'Соответствует правилам',
];

const privilegeGroupingTypeToString = {
  [PrivilegeGroupingType.BENEFIT]: 'Льгота',
  [PrivilegeGroupingType.SPECIAL]: 'Отдельная квота',
  [PrivilegeGroupingType.PRIORITY_RIGHT]: 'Приоритетное право',
  default: 'Преимущественное право',
};

const privilegeGroupingTypeToChecklistArray = {
  [PrivilegeGroupingType.BENEFIT]: 'benefitCheckMarks',
  [PrivilegeGroupingType.PREROGATIVE]: 'prerogativeCheckMarks',
  [PrivilegeGroupingType.SPECIAL]: 'specialQuotaCheckMarks',
  [PrivilegeGroupingType.PRIORITY_RIGHT]: 'priorityRightCheckMarks',
};

type AdmittedStatusCheckboxProps = {
  value: boolean;
  useMutation: () => any;
  id: number;
};

const AdmittedStatusCheckbox: FC<AdmittedStatusCheckboxProps> = ({
  useMutation,
  value,
  id,
}) => {
  const dispatch = useAppDispatch();
  const [checked, setChecked] = useState(value);

  const role = useAppSelector(selectUserRole);

  const [mutate, { isLoading }] = useMutation();

  const handleChange = e => {
    const {
      target: { checked: newChecked },
    } = e;
    setChecked(newChecked);
    mutate({ id, value: newChecked })
      .unwrap()
      .catch(() => {
        setChecked(checked);
        dispatch(
          createToast(
            'Во время проверки на соответствие правилам произошла ошибка',
            'danger'
          )
        );
      });
  };

  return (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      disabled={
        isLoading ||
        !role ||
        [UserRole.USER, UserRole.CALL_CENTER].includes(role)
      }
    />
  );
};

const ModeratorHallmarksPage: FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const download = useDownloadFileRaw();
  const user = useAppSelector(selectCurrentApplicationUser);

  const { data: achievementsForm } = achievementsApi.useGetAchievementFormQuery(
    Number(id)
  );

  const { data: privilegeForm } = privilegesApi.useGetPrivilegeFormQuery(
    Number(id)
  );

  if (!privilegeForm || !achievementsForm || !user) return <Loader />;

  const specialConditions = privilegeForm.privilegeExtras.find(
    extra => extra.type === PrivilegeExtrasType.ENTRANCE_TEST_SPECIAL_CONDITION
  );

  const disabledPerson = privilegeForm.privilegeExtras.find(
    extra => extra.type === PrivilegeExtrasType.DISABLED_PERSON
  );

  const militarySupport = privilegeForm.privilegeExtras.find(
    extra => extra.type === PrivilegeExtrasType.MILITARY_SUPPORT
  );

  const downloadDocument = (fileId: number, filename: string) =>
    download(user.id, fileId, filename);

  return (
    <div className="moderator-data-list">
      <table className="moderator-hallmarks-table">
        <thead>
          <tr>
            {headers.map(header => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {achievementsForm.achievements
            .toSorted(
              (achievement1, achievement2) => achievement1.id - achievement2.id
            )
            .map(achievement => (
              <tr key={`${achievement.achievementType} ${achievement.id}`}>
                <td>Достижения</td>
                <td>{achievement.achievementType.title}</td>
                <td>{achievement.confirmationDocumentTypeId.title}</td>
                <td>
                  {achievement.documentCode}
                  <br />
                  {achievement.dateOfIssue}
                </td>
                <td>-</td>
                <td>-</td>
                <td>
                  <AdmittedStatusCheckbox
                    id={achievement.id}
                    value={achievement.admitted}
                    useMutation={
                      achievementsApi.useUpdateAdmittedFlagForAchievementMutation
                    }
                  />
                </td>
                <td>
                  <div className="buttons">
                    <button
                      onClick={() =>
                        downloadDocument(
                          achievement.documentId,
                          `${achievement.confirmationDocumentTypeId.title}.pdf`
                        )
                      }
                    >
                      <DownloadIcon />
                    </button>
                  </div>
                </td>
                <td>
                  <CheckListEntryValueSelectWithRoleChecker
                    size={'icons'}
                    getValue={checkList =>
                      checkList.achievementsCheckMarks.find(
                        achvmnt => achvmnt.id === achievement.id
                      )?.status
                    }
                    updateValue={newValue => ({
                      achievementsCheckMarks: [
                        {
                          id: achievement.id,
                          status: newValue,
                        },
                      ],
                    })}
                  />
                </td>
              </tr>
            ))}
          {achievementsForm.olympiads
            .toSorted((olympiad1, olympiad2) => olympiad1.id - olympiad2.id)
            .map(olympiad => (
              <tr key={`${olympiad.olympiadType} ${olympiad.id}`}>
                <td>Олимпиада</td>
                <td>{olympiad.olympiadType.title}</td>
                <td>{olympiad.confirmationDocumentTypeId.title}</td>
                <td>
                  {olympiad.documentCode}
                  <br />
                  {olympiad.dateOfIssue}
                </td>
                <td>-</td>
                <td>-</td>
                <td>
                  <AdmittedStatusCheckbox
                    id={olympiad.id}
                    value={olympiad.admitted}
                    useMutation={
                      olympiadsApi.useUpdateAdmittedFlagForOlympiadMutation
                    }
                  />
                </td>
                <td>
                  <div className="buttons">
                    <button
                      onClick={() =>
                        downloadDocument(
                          olympiad.documentId,
                          `${olympiad.confirmationDocumentTypeId.title}.pdf`
                        )
                      }
                    >
                      <DownloadIcon />
                    </button>
                  </div>
                </td>
                <td>
                  <CheckListEntryValueSelectWithRoleChecker
                    size={'icons'}
                    getValue={checkList =>
                      checkList.olympiadCheckMarks.find(
                        olymp => olymp.id === olympiad.id
                      )?.status
                    }
                    updateValue={newValue => ({
                      olympiadCheckMarks: [
                        {
                          id: olympiad.id,
                          status: newValue,
                        },
                      ],
                    })}
                  />
                </td>
              </tr>
            ))}
          {[
            ...(privilegeForm.privileges.BENEFIT ?? []),
            ...(privilegeForm.privileges.PREROGATIVE ?? []),
            ...(privilegeForm.privileges.PRIORITY_RIGHT ?? []),
            ...(privilegeForm.privileges.SPECIAL ?? []),
          ]
            .toSorted((privilege1, privilege2) => privilege1.id - privilege2.id)
            .map(privilege => (
              <tr key={`${privilege.groupingType} ${privilege.id}`}>
                <td>
                  {privilegeGroupingTypeToString[privilege.groupingType] ||
                    privilegeGroupingTypeToString['default']}
                </td>
                <td>{privilege.privilegeTypeResponse.title}</td>
                <td>{privilege.confirmationDocumentTypeResponse.title}</td>
                <td>
                  {privilege.documentCode} <br /> {privilege.dateOfIssue}
                </td>
                <td>-</td>
                <td>-</td>
                <td>
                  <AdmittedStatusCheckbox
                    id={privilege.id}
                    value={privilege.admitted}
                    useMutation={
                      privilegesApi.useUpdateAdmittedFlagForPrivilegeMutation
                    }
                  />
                </td>
                <td>
                  <div className="buttons">
                    <button
                      onClick={() =>
                        downloadDocument(
                          privilege.documentId,
                          `${privilege.confirmationDocumentTypeResponse.title}.pdf`
                        )
                      }
                    >
                      <DownloadIcon />
                    </button>
                  </div>
                </td>
                <td>
                  <CheckListEntryValueSelectWithRoleChecker
                    size={'icons'}
                    getValue={checkList =>
                      checkList[
                        privilegeGroupingTypeToChecklistArray[
                          privilege.groupingType
                        ]
                      ].find(prvlg => prvlg.id === privilege.id)?.status
                    }
                    updateValue={newValue => ({
                      prerogativeCheckMarks:
                        privilege.groupingType ===
                        PrivilegeGroupingType.PREROGATIVE
                          ? [
                              {
                                id: privilege.id,
                                status: newValue,
                              },
                            ]
                          : [],

                      benefitCheckMarks:
                        privilege.groupingType === PrivilegeGroupingType.BENEFIT
                          ? [
                              {
                                id: privilege.id,
                                status: newValue,
                              },
                            ]
                          : [],

                      specialQuotaCheckMarks:
                        privilege.groupingType === PrivilegeGroupingType.SPECIAL
                          ? [
                              {
                                id: privilege.id,
                                status: newValue,
                              },
                            ]
                          : [],

                      priorityRightCheckMarks:
                        privilege.groupingType ===
                        PrivilegeGroupingType.PRIORITY_RIGHT
                          ? [
                              {
                                id: privilege.id,
                                status: newValue,
                              },
                            ]
                          : [],
                    })}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {specialConditions && specialConditions.active && (
        <>
          <Checkbox
            readOnly
            checked
            text={
              'Нужны специальные условия для проведения вступительных испытаний'
            }
          />
          {specialConditions?.specialConditions && (
            <ModeratorDataList
              list={[
                {
                  title: 'Специальные условия:',
                  label: specialConditions?.specialConditions,
                },
              ]}
            />
          )}
          <ProfileSection title="">
            <li className="add-files-page__item additional-file">
              <div className="additional-file__icon-wrapper">
                <button
                  className="additional-file__button"
                  onClick={() =>
                    downloadDocument(
                      specialConditions.documentId,
                      'Специальные условия.pdf'
                    )
                  }
                >
                  <FileIcon
                    width={46}
                    height={61}
                    className="additional-file__icon"
                  />
                </button>
              </div>
              <div className="additional-file__info">
                <div className="additional-file__name">Специальные условия</div>
              </div>
            </li>
          </ProfileSection>
        </>
      )}

      {disabledPerson && disabledPerson.active && (
        <>
          <Checkbox
            readOnly
            checked
            text={
              'Имею основание для сдачи экзамена в СПбПУ (основание - справка об инвалидности)'
            }
          />
          <ProfileSection title="">
            <li className="add-files-page__item additional-file">
              <div className="additional-file__icon-wrapper">
                <button
                  className="additional-file__button"
                  onClick={() =>
                    downloadDocument(
                      disabledPerson.documentId,
                      'Основание для сдачи.pdf'
                    )
                  }
                >
                  <FileIcon
                    width={46}
                    height={61}
                    className="additional-file__icon"
                  />
                </button>
              </div>
              <div className="additional-file__info">
                <div className="additional-file__name">Основание для сдачи</div>
              </div>
            </li>
          </ProfileSection>
        </>
      )}

      {militarySupport && militarySupport.active && (
        <>
          <Checkbox
            readOnly
            checked
            text={'Поступаю на основании Особенностей приема в 2024 году'}
          />
          <ProfileSection title="">
            <li className="add-files-page__item additional-file">
              <div className="additional-file__icon-wrapper">
                <button
                  className="additional-file__button"
                  onClick={() =>
                    downloadDocument(
                      militarySupport.documentId,
                      'Основания для поступления в рамках Особенностей приема.pdf'
                    )
                  }
                >
                  <FileIcon
                    width={46}
                    height={61}
                    className="additional-file__icon"
                  />
                </button>
              </div>
              <div className="additional-file__info">
                <div className="additional-file__name">
                  Основания для поступления в рамках Особенностей приема
                </div>
              </div>
            </li>
          </ProfileSection>
        </>
      )}
    </div>
  );
};

export default ModeratorHallmarksPage;
