import {
  ApplicationByStaff,
  ApplicationCheckList,
  ApplicationExpertExaminationReferral,
  ApplicationId,
  ApplicationPageableRequest,
  ApplicationPages,
  ApplicationView,
  EpguRejectReason,
} from '../../../models/applications/applications';
import { DeepPartial } from '../../../types/custom';
import { apiSlice } from '../api-slice';

const baseUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/applications/staff-managed`;
const epguRejectReasonsUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/reasons-reject`;

export const staffApplicationsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getApplicationsByStaff: builder.query<
      ApplicationPages,
      {
        page: number;
        size: number;
        orderBy?: string;
        orderDirection?: 'ASC' | 'DESC';
        request?: ApplicationPageableRequest;
      }
    >({
      query: ({ page, size, request, orderBy, orderDirection }) => ({
        url: `${baseUrl}/all-pageable`,
        method: 'POST',
        params: {
          page,
          size,
          orderBy,
          orderDirection,
        },
        body: request,
      }),
    }),

    getStaffApplicationById: builder.query<ApplicationByStaff, ApplicationId>({
      query: applicationId => ({
        url: `${baseUrl}/${applicationId}`,
      }),
      providesTags: ['ApplicationByStaff'],
    }),

    takeToWorkApplication: builder.mutation<ApplicationView, ApplicationId>({
      query: applicationId => ({
        url: `${baseUrl}/take-to-work/${applicationId}`,
        method: 'POST',
      }),
    }),

    sendApplicationToExpert: builder.mutation<
      ApplicationView,
      {
        applicationId: ApplicationId;
        request: ApplicationExpertExaminationReferral;
      }
    >({
      query: ({ applicationId, request }) => ({
        url: `${baseUrl}/send-to-expert/${applicationId}`,
        method: 'POST',
        body: request,
      }),
    }),

    sendApplicationToEditing: builder.mutation<ApplicationView, ApplicationId>({
      query: applicationId => ({
        url: `${baseUrl}/send-to-editing/${applicationId}`,
        method: 'POST',
      }),
    }),

    sendApplicationToDirectionsEditing: builder.mutation<
      ApplicationView,
      ApplicationId
    >({
      query: applicationId => ({
        url: `${baseUrl}/send-to-directions-editing/${applicationId}`,
        method: 'POST',
      }),
    }),

    sendApplicationToCoordinatorEditing: builder.mutation<
      ApplicationView,
      ApplicationId
    >({
      query: applicationId => ({
        url: `${baseUrl}/send-to-coordinator/${applicationId}`,
        method: 'POST',
      }),
    }),

    rejectApplication: builder.mutation<
      ApplicationView,
      {
        readonly applicationId: ApplicationId;
        readonly reasonRejectExternalId?: number;
      }
    >({
      query: ({ applicationId, reasonRejectExternalId }) => ({
        url: `${baseUrl}/reject/${applicationId}`,
        method: 'POST',
        params: {
          reasonRejectExtId: reasonRejectExternalId,
        },
      }),
    }),

    rejectApplicationEditing: builder.mutation<ApplicationView, ApplicationId>({
      query: applicationId => ({
        url: `${baseUrl}/reject-directions-editing/${applicationId}`,
        method: 'POST',
      }),
    }),

    acceptApplication: builder.mutation<ApplicationView, ApplicationId>({
      query: applicationId => ({
        url: `${baseUrl}/accept/${applicationId}`,
        method: 'POST',
      }),
    }),

    forceAcceptApplication: builder.mutation<ApplicationView, ApplicationId>({
      query: applicationId => ({
        url: `${baseUrl}/accept/${applicationId}?force=true`,
        method: 'POST',
      }),
    }),

    allowApplication: builder.mutation<ApplicationView, ApplicationId>({
      query: applicationId => ({
        url: `${baseUrl}/allow/${applicationId}`,
        method: 'POST',
      }),
    }),

    acceptApplicationDirectionsEditing: builder.mutation<
      ApplicationView,
      ApplicationId
    >({
      query: applicationId => ({
        url: `${baseUrl}/accept-directions-editing/${applicationId}`,
        method: 'POST',
      }),
    }),

    setApplicationComment: builder.mutation<
      ApplicationView,
      {
        id: ApplicationId;
        comment: string;
      }
    >({
      query: ({ id, comment }) => ({
        url: `${baseUrl}/set-comment/${id}`,
        method: 'POST',
        params: {
          comment,
        },
      }),
    }),

    sendToQueue: builder.mutation<void, ApplicationId>({
      query: id => ({
        url: `${baseUrl}/send-to-queue/${id}`,
        method: 'POST',
      }),
    }),

    clearError: builder.mutation<void, ApplicationId>({
      query: id => ({
        url: `${baseUrl}/clear-error/${id}`,
        method: 'POST',
      }),
    }),

    rejectApplicationWithdraw: builder.mutation<ApplicationView, ApplicationId>(
      {
        query: id => ({
          url: `${baseUrl}/reject-withdraw/${id}`,
          method: 'POST',
        }),
      }
    ),

    approveApplicationWithdraw: builder.mutation<
      ApplicationView,
      ApplicationId
    >({
      query: id => ({
        url: `${baseUrl}/approve-withdraw/${id}`,
        method: 'POST',
      }),
    }),

    getApplicationsDuplicates: builder.query<ApplicationByStaff[], number>({
      query: applicationId => ({
        url: `${baseUrl}/${applicationId}/duplicates`,
      }),
    }),

    setProfileSubjectScore: builder.mutation<
      any,
      {
        readonly applicationDirectionId: number;
        readonly profileSubjectScore: number | undefined;
      }
    >({
      query: ({ applicationDirectionId, profileSubjectScore }) => ({
        url: `${baseUrl}/set-profile-subject-score/${applicationDirectionId}`,
        method: 'POST',
        params: {
          profileSubjectScore,
        },
      }),
    }),

    setEpguManualSync: builder.mutation<
      any,
      {
        readonly applicationId: number;
        readonly epguManualSyncRequired: boolean;
      }
    >({
      query: ({ applicationId, epguManualSyncRequired }) => ({
        url: `${baseUrl}/set-epgu-manual-sync/${applicationId}`,
        method: 'POST',
        params: {
          status: epguManualSyncRequired,
        },
      }),
    }),

    getEpguRejectReasons: builder.query<EpguRejectReason[], void>({
      query: () => ({
        url: `${epguRejectReasonsUrl}`,
      }),
    }),

    getEpguRejectReasonDescription: builder.query<EpguRejectReason, number>({
      query: reasonExternalId => ({
        url: `${epguRejectReasonsUrl}/by-external-id/${reasonExternalId}`,
      }),
    }),

    getAllApplicationsAsCSV: builder.query<
      Blob,
      {
        orderBy?: string;
        orderDirection?: 'ASC' | 'DESC';
        request?: ApplicationPageableRequest;
      }
    >({
      query: ({ orderBy, orderDirection, request }) => ({
        url: `${baseUrl}/all-as-csv`,
        responseHandler: response => response.blob(),
        method: 'POST',
        params: {
          orderBy,
          orderDirection,
        },
        body: request,
      }),
    }),

    getApplicationChecklist: builder.query<ApplicationCheckList, ApplicationId>(
      {
        query: applicationId => ({
          url: `${baseUrl}/${applicationId}/checklist`,
        }),
        providesTags: ['CheckList'],
      }
    ),

    setApplicationChecklist: builder.mutation<
      ApplicationCheckList,
      {
        applicationId: ApplicationId;
        request: DeepPartial<ApplicationCheckList>;
      }
    >({
      query: ({ applicationId, request }) => ({
        url: `${baseUrl}/${applicationId}/checklist`,
        method: 'POST',
        body: request,
      }),
    }),

    changeEducationalInformation: builder.mutation<
      void,
      {
        applicationId: ApplicationId;
        educationalInformationDraftId: number;
      }
    >({
      query: ({ applicationId, educationalInformationDraftId }) => ({
        url: `${baseUrl}/${applicationId}/change-educational-information`,
        method: 'PUT',
        params: {
          educationalInformationDraftId,
        },
      }),
    }),

    clearUndefinedFields: builder.mutation<void, number>({
      query: applicationId => ({
        url: `${baseUrl}/${applicationId}/clear-undefined-fields`,
        method: 'POST',
      }),
      invalidatesTags: ['ApplicationByStaff'],
    }),
  }),
});

export const {
  useLazyGetApplicationsByStaffQuery,
  useLazyGetStaffApplicationByIdQuery,
  useGetStaffApplicationByIdQuery,
  useTakeToWorkApplicationMutation,
  useAcceptApplicationDirectionsEditingMutation,
  useAcceptApplicationMutation,
  useForceAcceptApplicationMutation,
  useAllowApplicationMutation,
  useRejectApplicationMutation,
  useRejectApplicationEditingMutation,
  useSendApplicationToEditingMutation,
  useSendApplicationToExpertMutation,
  useSendApplicationToCoordinatorEditingMutation,
  useSendApplicationToDirectionsEditingMutation,
  useSetApplicationCommentMutation,
  useRejectApplicationWithdrawMutation,
  useApproveApplicationWithdrawMutation,
  useSetProfileSubjectScoreMutation,
  useSetEpguManualSyncMutation,
  useLazyGetEpguRejectReasonDescriptionQuery,
  useSendToQueueMutation,
  useClearErrorMutation,
  useLazyGetAllApplicationsAsCSVQuery,
  useGetApplicationsByStaffQuery,
  useGetApplicationChecklistQuery,
  useSetApplicationChecklistMutation,
} = staffApplicationsApi;
