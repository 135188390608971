import React, { FC } from 'react';

import GeneralDataForm from 'components/profile/GeneralDataForm';

const PersonalDataPage: FC = () => (
  <div>
    <GeneralDataForm />
  </div>
);

export default PersonalDataPage;
