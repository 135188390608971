import { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import terms from 'i18n';
import { SelectOption, translate } from 'models/common';
import {
  applicationsApi,
  useSetDesiredDirectionMutation,
} from 'redux/api/applications/applications-api';

import { DirectionRequestType } from '../../../models/applications/applications';
import { ChosenDirectionWithPriority } from '../../../models/applications/directions';
import { ApplicationEducationLevel } from '../../../models/applications/enum';
import { selectCurrentEducationLevel } from '../../../redux/selectors/enrollee/application';
import { applicationSlice } from '../../../redux/slices/enrollee/application-slice';
import useAppDispatch from '../../common/useAppDispatch';
import useAppSelector from '../../common/useAppSelector';
import useFillingStatementsRoutesContext from '../useFillingStatementsRoutesContext';

export type DirectionsFormTypes = {
  directionTraining: string;
  levelEducationId: SelectOption;
};

const useDirectionsForm = (directions?: ChosenDirectionWithPriority[]) => {
  const dispatch = useAppDispatch();
  const { applicationId } = useParams();
  const { onNextPageNavigate } = useFillingStatementsRoutesContext();

  const { register, control, setValue, handleSubmit, formState, reset } =
    useForm<DirectionsFormTypes>();

  const currentTrainingLevel = useAppSelector(selectCurrentEducationLevel);

  const [setDesiredDirection] = useSetDesiredDirectionMutation();
  const { data: desiredDirection } =
    applicationsApi.useGetDesiredDirectionsQuery(
      {
        applicationId: Number(applicationId),
      },
      {
        skip:
          !currentTrainingLevel ||
          currentTrainingLevel !== ApplicationEducationLevel.PREPARATORY,
        refetchOnMountOrArgChange: true,
      }
    );

  useEffect(() => {
    if (desiredDirection) {
      reset({
        directionTraining: desiredDirection.desiredDirection,
        levelEducationId: {
          value: desiredDirection.educationLevel.id,
          label: translate(desiredDirection.educationLevel),
        },
      });
    }
  }, [desiredDirection]);

  const fields = {
    directionTraining: register('directionTraining', {
      required:
        currentTrainingLevel === ApplicationEducationLevel.PREPARATORY &&
        directions?.length !== 0 &&
        terms.REQUIRED_FIELD,
    }),
    levelEducationId: register('levelEducationId', {
      required:
        currentTrainingLevel === ApplicationEducationLevel.PREPARATORY &&
        directions?.length !== 0 &&
        terms.REQUIRED_FIELD,
    }),
  };

  const onSubmitPreparatoryTrainingLevel = handleSubmit(data => {
    if (!applicationId || !directions || directions.length === 0) {
      onNextPageNavigate();
      return;
    }

    setDesiredDirection({
      applicationId: Number(applicationId),
      directionEducationLevelId: data.levelEducationId.value,
      desiredDirection: data.directionTraining,
    })
      .unwrap()
      .then(() => {
        onNextPageNavigate();
      });
  });

  const onAddDirectionsClick = (requestType: DirectionRequestType) =>
    dispatch(applicationSlice.actions.setDirectionsRequestType(requestType));

  return {
    fields,
    control,
    setValue,
    currentTrainingLevel,
    onSubmitPreparatoryTrainingLevel,
    formState,
    onNextPageNavigate,
    onAddDirectionsClick,
  };
};

export default useDirectionsForm;
