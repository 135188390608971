import React, { FC } from 'react';

import SignInForm from 'components/auth/SignInForm';
import terms from 'i18n';
import TemplateAuthPage from 'pages/TemplateAuthPage';

const SignInPage: FC = () => (
  <TemplateAuthPage>
    <SignInForm title={terms.SPBPU_ONLINE_APPLICATION_SYSTEM} />
  </TemplateAuthPage>
);

export default SignInPage;
