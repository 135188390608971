import React, { FC, useMemo } from 'react';

import Loader from 'components/common/ui-kit/Loader';
import CardForDownloadFile from 'components/statements/CardForDownloadFile';
import { ApplicationDocument } from 'models/applications/application-documents';

import { isRussianLocale } from '../../../i18n';

type BlankListProps = {
  documents: ApplicationDocument[] | undefined;
  flag?: boolean;
  refetchDocuments: () => void;
};

const DocumentList: FC<BlankListProps> = ({
  documents,
  flag = false,
  refetchDocuments,
}) => {
  const sortedDocuments = useMemo(() => {
    if (!documents) return [];
    return [...documents].sort((doc1, doc2) => {
      const doc1Type = doc1.type;
      const doc2Type = doc2.type;
      if (doc1Type < doc2Type) {
        return -1;
      }
      if (doc1Type > doc2Type) {
        return 1;
      }
      return 0;
    });
  }, [documents]);

  if (!documents) return <Loader />;

  return (
    <>
      {sortedDocuments.map((blankEl, index) => {
        return (
          <CardForDownloadFile
            key={index}
            blankId={blankEl.id}
            code={'fixme'}
            title={
              isRussianLocale()
                ? blankEl.title
                : blankEl.titleForeign || blankEl.title
            }
            date={blankEl.createdAt}
            showCard={flag}
            acceptBlank={blankEl.isSigned}
            refetchDocuments={refetchDocuments}
            document={blankEl}
          />
        );
      })}
    </>
  );
};

export default DocumentList;
