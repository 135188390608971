import React, { useState } from 'react';

import { Controller } from 'react-hook-form';

import AdminFilter from 'components/admin/AdminFilter/view';
import { defaultMapFiltersToRequest } from 'components/admin/AdminTableListPage/view';
import Button from 'components/common/ui-kit/Button';
import { InputV2 } from 'components/common/ui-kit/Input/view';
import ReactSelect from 'components/common/ui-kit/Select/view';
import useAppDispatch from 'hooks/common/useAppDispatch';
import { SelectOption } from 'models/common';
import { showModal } from 'redux/actions';
import { userManagementApi } from 'redux/api/staff/admin/user-management-api';
import { createInputV2Props, createSelectProps } from 'utils/form-helpers';

import AdminUserListElement from '../../../components/admin/AdminUserListElement';
import Pagination from '../../../components/common/ui-kit/Pagination';

enum Status {
  ACTIVE,
  ARCHIVED,
}

const statusOptions: SelectOption[] = [
  { label: 'Активный', value: Status.ACTIVE },
  { label: 'Удалён', value: Status.ARCHIVED },
];

enum Role {
  STAFF,
  ANY,
}

const roleOptions: SelectOption[] = [
  { label: 'Сотрудник', value: Role.STAFF },
  { label: 'Любая', value: Role.ANY },
];
export const defaultUsersFilters = {
  id: '',
  lastName: '',
  firstName: '',
  middleName: '',
  email: '',
  documentSerial: '',
  documentNumber: '',
  active: null,
  staffOnly: Role.ANY,
};

export const mapUserFiltersToRequest = (filters, map) => {
  const res = map(filters);
  const status = filters.status;
  if (status !== undefined) {
    res.active = filters.status === Status.ACTIVE;
    delete res.status;
  }
  const role = filters.role;
  res.staffOnly = role === Role.STAFF;
  delete res.role;
  return res;
};

export const renderUserAdministrationFilters = ({
  control,
  formState: { errors },
}) => {
  const inputV2Props = createInputV2Props(errors);
  const selectProps = createSelectProps(errors);
  return (
    <>
      <Controller
        name="id"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="ID пользователя"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="Фамилия"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="Имя"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="middleName"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="Отчество"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="Email"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="documentSerial"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="Серия паспорта"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="documentNumber"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="Номер паспорта"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption>
            options={statusOptions}
            placeholder="Статус"
            className="filter-form__field"
            isClearable
            {...selectProps(field, {
              options: statusOptions,
            })}
          />
        )}
      />
      <Controller
        name="role"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption>
            options={roleOptions}
            placeholder="Роль"
            className="filter-form__field"
            {...selectProps(field, {
              options: roleOptions,
            })}
          />
        )}
      />
    </>
  );
};

const UsersPage = () => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [filters, setFilters] = useState({});

  const onFiltersReset = () => {
    setCurrentPage(1);
    setFilters({});
  };

  const onFiltersSubmit = filters => {
    setCurrentPage(1);
    setFilters(filters);
  };

  const { data } = userManagementApi.useGetAllUsersByAdminQuery({
    request: mapUserFiltersToRequest(filters, defaultMapFiltersToRequest),
    page: currentPage,
    size: perPage,
  });

  const handleCreateUserClick = () => {
    dispatch(
      showModal({
        name: 'ADMIN_USER',
        data: {},
      })
    );
  };

  return (
    <main className="moderator-contract-page">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '64px',
          marginBottom: '24px',
        }}
      >
        <h1
          style={{
            lineHeight: '32px',
            fontSize: '24px',
          }}
        >
          Все пользователи
        </h1>
        <Button type="button" theme="success" onClick={handleCreateUserClick}>
          Добавить пользователя
        </Button>
      </div>
      {/* <AccordionTab title="Фильтры">
        <AdminUserListFilter />
      </AccordionTab> */}
      <AdminFilter
        defaultValues={defaultUsersFilters}
        filter={filters}
        onSubmit={onFiltersSubmit}
        onReset={onFiltersReset}
        displayCount={data?.totalElements}
        displayCountLabel="Пользователей"
      >
        {renderUserAdministrationFilters}
      </AdminFilter>
      {data?.result.map((item, index) => (
        <AdminUserListElement user={item} key={index} />
      ))}
      <Pagination
        lastPage={data?.totalPages ?? 0}
        currentPage={currentPage}
        perPage={perPage}
        onPageChange={value => setCurrentPage(value)}
        onPerPageChange={value => setPerPage(value)}
      />
    </main>
  );
};

export default UsersPage;
