import { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useEnrolleeUserId } from 'hooks/questionnaire/common/draft/useQuestionnaire';
import terms, { getTranslationOrEmptyString } from 'i18n';
import { SelectOption } from 'models/common';
import { getIsoDate } from 'utils/date';
import {
  getPassportNumber,
  getPassportSeries,
  normalizeYearTo4Characters,
} from 'utils/form-validators';

import {
  Contract,
  ContractThirdPartyPayerData,
  ContractType,
} from '../../../models/applications/contracts';
import { RUSSIAN_PASSPORT_ENUM } from '../../../models/enum';
import { createToast } from '../../../redux/actions';
import { contractApi } from '../../../redux/api/applications/contract-api';
import { Modify } from '../../../types/extra';
import useAppDispatch from '../../common/useAppDispatch';
import { useLazyUploadFiles } from '../../files/useLazyUploadFiles';
import { useGetContractPaymentTypesSelectProps } from './useGetContractPaymentTypesSelectProps';
import { useGetCreateContractSelectOptions } from './useGetDirectionSelectOptions';

type NotEntrantsContractForm = Modify<
  ContractThirdPartyPayerData,
  {
    directionId: SelectOption;
    paymentForm: SelectOption;
    docType: SelectOption;
    wasDocumentAttached: boolean;
    contractDate?: string;
  }
>;

const useNotEntrantsContractForm = (
  isDateFieldShown: boolean,
  contract?: Contract,
  isFillingStatementsForm?: boolean,
  onSubmitProp?: (res) => void
) => {
  const navigate = useNavigate();
  const [createContract] = contractApi.useLazyCreateQuery();
  const [updateContract] = contractApi.useUpdateContractMutation();
  const contractUserId = contract?.userId;
  const userId = useEnrolleeUserId();
  const dispatch = useAppDispatch();

  const directionOptions = useGetCreateContractSelectOptions(
    Boolean(contract),
    contract?.applicationId
  );
  const paymentForms = useGetContractPaymentTypesSelectProps();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<NotEntrantsContractForm>({ mode: 'all' });

  const { uploadFiles, ...fileProps } = useLazyUploadFiles({
    userId: contractUserId ?? userId,
    initialFileIds: watch('documentScanId'),
    filename: 'Контракт.pdf',
  });

  const documentType = watch('docType')?.value;

  const isSeriesRequired = documentType === RUSSIAN_PASSPORT_ENUM;
  const isRussianPassport = documentType === RUSSIAN_PASSPORT_ENUM;
  const isDateOfExpirationExists = false; // in case of adding new document that requires this field - change condition

  useEffect(() => {
    if (contract && directionOptions.length && paymentForms.length) {
      if (contract?.thirdPartyPayerData) {
        reset({
          ...contract?.thirdPartyPayerData,
          docType: {
            value: contract?.thirdPartyPayerData?.docType,
            label: getTranslationOrEmptyString(
              contract?.thirdPartyPayerData?.docType
            ),
          },
        });
      }

      setValue(
        'directionId',
        directionOptions.find(d => d.value === contract.applicationDirectionId)!
      );

      setValue(
        'paymentForm',
        paymentForms.find(p => p.value === contract.paymentType.id)!
      );
    }
    if (isDateFieldShown) {
      setValue(
        'contractDate',
        contract?.contractDate ?? getIsoDate(new Date())
      );
    }
  }, [
    directionOptions.length,
    paymentForms.length,
    contract,
    isDateFieldShown,
  ]);

  useEffect(() => {
    if (fileProps.filesMeta.length) {
      setValue('wasDocumentAttached', true);
    } else {
      setValue('wasDocumentAttached', false);
    }
  }, [fileProps.filesMeta]);

  const fields = {
    directionId: register('directionId', {
      required: terms.REQUIRED_FIELD,
    }),
    paymentForm: register('paymentForm', {
      required: terms.REQUIRED_FIELD,
    }),

    middleName: register('middleName', { required: terms.REQUIRED_FIELD }),
    firstName: register('firstName', {
      required: { message: terms.REQUIRED_FIELD, value: true },
    }),
    lastName: register('lastName'),
    birthDate: register('birthDate', {
      required: { value: true, message: terms.REQUIRED_FIELD },
      onChange: ({ target: { value } }) =>
        value && setValue('birthDate', normalizeYearTo4Characters(value)),
    }),
    phone: register('phone', {
      required: { message: terms.REQUIRED_FIELD, value: true },
    }),
    docType: register('docType', {
      required: { message: terms.REQUIRED_FIELD, value: true },
      onChange: e => {
        e.target.value === RUSSIAN_PASSPORT_ENUM &&
          setValue('docExpirationDate', undefined);
      },
    }),
    series: register('series', {
      required: isSeriesRequired ? terms.REQUIRED_FIELD : '',
      ...getPassportSeries(isSeriesRequired),
    }),
    number: register('number', {
      required: { message: terms.REQUIRED_FIELD, value: true },
      ...getPassportNumber(isRussianPassport),
    }),
    divisionCode: register('divisionCode', { required: terms.REQUIRED_FIELD }),
    docExpirationDate: register('docExpirationDate', {
      required: {
        value: isDateOfExpirationExists,
        message: terms.REQUIRED_FIELD,
      },
      onChange: ({ target: { value } }) =>
        value &&
        setValue('docExpirationDate', normalizeYearTo4Characters(value)),
    }),
    issuedAt: register('issuedAt', {
      required: terms.REQUIRED_FIELD,
      onChange: ({ target: { value } }) =>
        value && setValue('issuedAt', normalizeYearTo4Characters(value)),
    }),
    issuedBy: register('issuedBy', { required: terms.REQUIRED_FIELD }),
    placeOfBirth: register('placeOfBirth', { required: terms.REQUIRED_FIELD }),
    attorneyRepresentative: register('attorneyRepresentative'),
    attorneyCustomer: register('attorneyCustomer'),
    documentScanId: register('documentScanId'),
    wasDocumentAttached: register('wasDocumentAttached', {
      required: terms.REQUIRED_FIELD,
    }),
    contractDate: register('contractDate', {
      required: isDateFieldShown && terms.REQUIRED_FIELD,
    }),
  };

  const onSubmit = handleSubmit(data => {
    uploadFiles()
      .then(fileId => {
        return contract
          ? updateContract({
              id: contract.id,
              contractType: ContractType.THIRD_PARTY,
              paymentTypeId: data.paymentForm.value,
              applicationDirectionId: data.directionId.value,
              contractThirdPartyPayerData: {
                ...data,
                docType: data.docType.value,
                documentScanId: fileId,
              },
              contractDate: isDateFieldShown ? data.contractDate : undefined,
            }).unwrap()
          : createContract({
              contractType: ContractType.THIRD_PARTY,
              paymentTypeId: data.paymentForm.value,
              applicationDirectionId: data.directionId.value,
              contractThirdPartyPayerData: {
                ...data,
                docType: data.docType.value,
                documentScanId: fileId,
              },
              contractDate: isDateFieldShown ? data.contractDate : undefined,
            }).unwrap();
      })
      .then(res => {
        if (isFillingStatementsForm) {
          navigate('..');
        }
        onSubmitProp?.(res);
      })
      .catch(() =>
        dispatch(createToast(terms.CREATE_CONTRACT_ERROR, 'danger'))
      );
  });

  return {
    fields,
    onSubmit,
    errors,
    control,
    fileProps,
    isSeriesRequired,
    isDateOfExpirationExists,
    directionOptions,
    paymentForms,
  };
};

export default useNotEntrantsContractForm;
