import React, { FC, PropsWithChildren, useState } from 'react';

import classnames from 'classnames';

import { ReactComponent as TabArrowDown } from 'assets/images/icons/tab-arrow-down.svg';
import Button from 'components/common/ui-kit/Button';

type DropdownProps = PropsWithChildren<{
  title: string;
}>;

const Dropdown: FC<DropdownProps> = ({ title, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <section className="dropdown">
      <header className="dropdown__header">
        {title}
        <Button
          className={classnames('dropdown__header-btn', open && 'rotate')}
          onClick={() => setOpen(!open)}
        >
          <TabArrowDown />
        </Button>
      </header>
      {open && <div className="dropdown__main right">{children}</div>}
    </section>
  );
};

export default Dropdown;
