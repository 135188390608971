import { RootState } from '../../store';

export const selectApplicationFilters = (state: RootState) =>
  state.moderatorApplications.filters;

export const selectModeratorApplicationsState = (state: RootState) =>
  state.moderatorApplications;

export const selectCurrentQuestionnaire = (state: RootState) =>
  state.moderatorApplications.currentApplication?.questionnaire;

export const selectCurrentApplication = (state: RootState) =>
  state.moderatorApplications.currentApplication?.application;

export const selectCurrentApplicationUser = (state: RootState) =>
  state.moderatorApplications.currentApplication?.user;
export const selectCurrentApplicationProfileImage = (state: RootState) =>
  state.moderatorApplications.currentApplication?.user.profileImageBase64;
