import React, { FC } from 'react';

import EducationForm from 'components/profile/EducationForm';

const EducationDataPage: FC = () => (
  <div>
    <EducationForm />
  </div>
);

export default EducationDataPage;
