import React, { FC, memo } from 'react';

import classNames from 'classnames';
import { Resizable, Size } from 're-resizable';

import Chat from 'components/chat/ChatWidget/ChatPanel/Chat';
import ChatHeader from 'components/chat/ChatWidget/ChatPanel/ChatHeader';
import MessageForm from 'components/chat/ChatWidget/ChatPanel/MessageForm';
import useAppSelector from 'hooks/common/useAppSelector';
import { selectChatOpened } from 'redux/selectors/common/chat';

const defaultSize: Size = {
  width: 360,
  height: 570,
};

type ChatPanelProps = {
  className?: string;
};

const ChatPanel: FC<ChatPanelProps> = ({ className }) => {
  const chatOpened = useAppSelector(selectChatOpened);

  return (
    <>
      {chatOpened && (
        <Resizable
          className={classNames('chat-panel', className)}
          defaultSize={defaultSize}
          minHeight={defaultSize.height}
          minWidth={defaultSize.width}
          maxHeight="80vh"
          maxWidth="90vw"
          enable={{ top: true, left: true, topLeft: true }}
        >
          <ChatHeader />
          <Chat />
          <MessageForm className="chat-panel__form" />
        </Resizable>
      )}
    </>
  );
};

export default memo(ChatPanel);
