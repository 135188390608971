import React, { FC, useEffect } from 'react';

import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import Button from 'components/common/ui-kit/Button';
import Loader from 'components/common/ui-kit/Loader';
import FillingStatementsForm from 'components/statements/FillingStatementsForm';
import useContractList from 'hooks/statements/contract/useContractList';
import { useDownloadContractDocument } from 'hooks/statements/contract/useDownloadContractDocument';
import useFillingStatementsRoutesContext from 'hooks/statements/useFillingStatementsRoutesContext';
import terms, { getTranslationOrEmptyString } from 'i18n';
import { ApplicationState } from 'models/applications/enum';
import {
  selectApplication,
  selectIsApplicationContractsListReadonly,
} from 'redux/selectors/enrollee/application';
import { useIsImpersonate } from 'utils/domain/impersonate';

import useAppSelector from '../../../hooks/common/useAppSelector';
import { useGetEnrolleeOrQuestionnaireByUserId } from '../../../hooks/moderator/useGetEnrolleeOrQuestionnaireByUserId';
import {
  ContractState,
  ContractType,
} from '../../../models/applications/contracts';
import { translate } from '../../../models/common';
import { Questionnaire } from '../../../models/questionnaire/questionnaire';
import { contractApi } from '../../../redux/api/applications/contract-api';
import { selectUserId } from '../../../redux/selectors';
import { buildContractNameFromDirectionProperties } from '../../../utils/domain/direction';
import ModeratorContractIdInfoEntrants from '../../moderator/ModeratorContractIdInfoEntrants';
import ModeratorContractIdInfoJuridical from '../../moderator/ModeratorContractIdInfoJuridical/view';
import ModeratorContractIdInfoNotEntrants from '../../moderator/ModeratorContractIdInfoNotEntrants';

const ContractList: FC = () => {
  const { applicationId } = useParams();
  const application = useAppSelector(selectApplication);

  const [getContracts, { data: contracts }] =
    contractApi.useLazyGetByApplicationIdQuery();

  useEffect(() => {
    if (applicationId) getContracts(Number(applicationId));
  }, [applicationId]);

  const { onNextPageNavigate } = useFillingStatementsRoutesContext();

  const { onContractSubmit } = useContractList();
  const { userId: impersonatedUserId } = useParams();
  const userId = useAppSelector(selectUserId) as number;

  const questionnaire = useGetEnrolleeOrQuestionnaireByUserId(
    impersonatedUserId ? Number(impersonatedUserId) : userId
  ) as Questionnaire;

  const downloadPrintContract = useDownloadContractDocument();

  const isImpersonate = useIsImpersonate();
  const isContractReadOnly =
    useAppSelector(selectIsApplicationContractsListReadonly) && !isImpersonate;

  if (!contracts || !application) {
    return <Loader />;
  }

  return (
    <FillingStatementsForm
      onSubmit={e => {
        e.preventDefault();
        onNextPageNavigate();
      }}
    >
      <div>
        {contracts
          ? contracts.map((contract, index) => {
              const isConfirmedOrApproved = [
                ContractState.APPROVED,
                ContractState.CONFIRMED,
              ].includes(contract.state);

              return (
                <div className={classNames('card')} key={index}>
                  <span className="card__header">
                    {buildContractNameFromDirectionProperties({
                      title: contract.directionTitle,
                      titleForeign: contract.directionTitleForeign,
                      educationProgramTitle: contract.educationProgramTitle,
                      educationProgramTitleForeign:
                        contract.educationProgramTitleForeign,
                      educationForm: contract.directionEducationForm,
                    })}
                  </span>
                  <span className="card__content">
                    {terms.DOCUMENT_STATUS}:{' '}
                    <strong>
                      {getTranslationOrEmptyString(contract.state)}
                    </strong>
                  </span>
                  <span className="card__content">
                    {terms.PAYMENT_TYPE}:{' '}
                    <strong>{translate(contract.paymentType)}</strong>
                  </span>
                  <span className="card__content">
                    {terms.PAYER}:{' '}
                    <strong>
                      {getTranslationOrEmptyString(contract.contractType)}
                    </strong>
                  </span>

                  {questionnaire &&
                    (contract.contractType === ContractType.SELF_PAYMENT ? (
                      <ModeratorContractIdInfoEntrants
                        questionnaire={questionnaire}
                      />
                    ) : contract.contractType === ContractType.THIRD_PARTY ? (
                      <ModeratorContractIdInfoNotEntrants
                        contractNotEntrants={contract?.thirdPartyPayerData}
                      />
                    ) : contract.contractType === ContractType.JURIDICAL ? (
                      <ModeratorContractIdInfoJuridical
                        contractJuridical={contract?.legalEntityPayerData}
                      />
                    ) : (
                      <></>
                    ))}

                  <div className="card__signature">
                    {isConfirmedOrApproved && (
                      <Button
                        theme="success"
                        className="button card__button-edit"
                        onClick={e => {
                          e.preventDefault();
                          downloadPrintContract(contract);
                        }}
                      >
                        {terms.DOWNLOAD_CONTRACT}
                      </Button>
                    )}
                    {isConfirmedOrApproved ? (
                      <Button
                        theme="success"
                        className="button card__button-edit"
                        onClick={e => {
                          e.preventDefault();
                          window.open(
                            'https://pay.spbstu.ru/www/form_pay.html'
                          );
                        }}
                      >
                        {terms.PAY_TUITION}
                      </Button>
                    ) : (
                      application.state !== ApplicationState.DRAFT && (
                        <Button
                          className="button card__button-edit"
                          theme="success"
                          disabled={
                            contract.state !== ContractState.DRAFT ||
                            isContractReadOnly
                          }
                          onClick={e => {
                            e.preventDefault();
                            onContractSubmit(contract.id, () => {
                              if (applicationId)
                                getContracts(Number(applicationId));
                            });
                          }}
                        >
                          {terms.SEND}
                        </Button>
                      )
                    )}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </FillingStatementsForm>
  );
};

export default ContractList;
