import { apiSlice } from 'redux/api/api-slice';

import {
  ApplicationDocument,
  DocumentTemplateType,
} from '../../../models/applications/application-documents';
import { DirectionRequestType } from '../../../models/applications/applications';

const baseUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/documents`;

export type EnrollmentAgreement = {
  isWithdraw: boolean;
  isSigned: boolean;
};

export const applicationDocumentsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getDocuments: builder.query<ApplicationDocument[], number>({
      query: applicationId => ({
        url: `${baseUrl}/by-application-id/${applicationId}`,
      }),
    }),

    getEnrollmentDocument: builder.query<
      ApplicationDocument,
      {
        readonly applicationId: number;
        readonly applicationDirectionId: number;
      }
    >({
      query: ({ applicationId, applicationDirectionId }) => ({
        url: `${baseUrl}/enrollment-document-by-application-id/${applicationId}`,
        params: {
          applicationDirectionId,
        },
      }),
    }),

    signDocument: builder.mutation<
      void,
      { documentId: number; isSigned: boolean }
    >({
      query: ({ documentId, isSigned }) => ({
        url: `${baseUrl}/set-signed/${documentId}`,
        method: 'POST',
        params: {
          isSigned,
        },
      }),
    }),

    signEnrollmentDocument: builder.mutation<
      void,
      {
        applicationDirectionId: number;
        readonly enrollmentAgreement: EnrollmentAgreement;
      }
    >({
      query: ({ applicationDirectionId, enrollmentAgreement }) => ({
        url: `${baseUrl}/set-enrollment-document-signed/${applicationDirectionId}`,
        method: 'POST',
        body: enrollmentAgreement,
      }),
    }),

    generateDocument: builder.query<
      Blob,
      {
        readonly id: number;
        readonly docType: DocumentTemplateType;
      }
    >({
      query: ({ id, docType }) => ({
        url: `${baseUrl}/generate-file/${id}`,
        params: {
          docType,
        },
        responseHandler: response => response.blob(),
      }),
    }),

    generateDocumentWithMeta: builder.query<
      Blob,
      {
        readonly id: number;
        readonly docType: DocumentTemplateType;
        readonly directionPaymentForm: DirectionRequestType;
      }
    >({
      query: ({ id, docType, directionPaymentForm }) => ({
        url: `${baseUrl}/generate-file/${id}`,
        method: 'POST',
        params: {
          docType,
        },
        body: {
          directionPaymentForm,
        },
        responseHandler: response => response.blob(),
      }),
    }),

    generateEnrollmentDocument: builder.query<
      Blob,
      {
        readonly id: number;
        readonly applicationDirectionId: number;
        readonly docType: DocumentTemplateType;
      }
    >({
      query: ({ id, applicationDirectionId, docType }) => ({
        url: `${baseUrl}/generate-enrollment-file/${id}`,
        params: {
          docType,
          applicationDirectionId,
        },
        responseHandler: response => response.blob(),
      }),
    }),

    generateEnrollmentDocumentWithMeta: builder.query<
      Blob,
      {
        readonly id: number;
        readonly applicationDirectionId: number;
        readonly docType: DocumentTemplateType;
        readonly directionPaymentForm: DirectionRequestType;
      }
    >({
      query: ({
        id,
        applicationDirectionId,
        docType,
        directionPaymentForm,
      }) => ({
        url: `${baseUrl}/generate-enrollment-file/${id}`,
        method: 'POST',
        params: {
          docType,
          applicationDirectionId,
        },
        body: {
          directionPaymentForm,
        },
        responseHandler: response => response.blob(),
      }),
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useGetEnrollmentDocumentQuery,
  useSignDocumentMutation,
  useSignEnrollmentDocumentMutation,
} = applicationDocumentsApi;
