import React, { MouseEventHandler } from 'react';

import Button from '../../../../components/common/ui-kit/Button';
import PrivilegesTable from '../../../../components/statements/PrivilegesTable';
import { PrivilegesTableProps } from '../../../../components/statements/PrivilegesTable/view';
import terms from '../../../../i18n';

export const PrivilegesTableWrapper = ({
  title,
  onAddButtonClick,
  isAddButtonDisabled = false,
  ...tableProps
}: {
  readonly title: string;
  readonly onAddButtonClick: MouseEventHandler<HTMLButtonElement>;
  readonly isAddButtonDisabled?: boolean;
} & PrivilegesTableProps) => (
  <>
    <span className="privileges-page__title">{title}</span>

    <div className="privileges-page__button-container">
      <Button
        type="button"
        disabled={tableProps.readOnly || isAddButtonDisabled}
        onClick={onAddButtonClick}
      >
        {terms.ADD_BUTTON}
      </Button>
    </div>

    {tableProps.privileges.length > 0 && <PrivilegesTable {...tableProps} />}
  </>
);
