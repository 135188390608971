import { AdmissionBasis } from 'models/applications/directions';
import { ApplicationEducationLevel } from 'models/applications/enum';
import { RatingListEntityResponse } from 'models/students';

const firstBachelorColumnSet: (keyof RatingListEntityResponse | string)[] = [
  'userIdOrUniqueId',
  'hasOlympiad',
  'fullScore',
  'subjectScore',
  'subjects',
  'achievementScore',
  'hasFeature',
  'priority',
  'hasOriginalDocuments',
  'state',
];

const secondBachelorColumnSet: (keyof RatingListEntityResponse | string)[] = [
  'userIdOrUniqueId',
  'fullScore',
  'subjectScore',
  'subjects',
  'achievementScore',
  'priority',
  'hasOriginalDocuments',
  'state',
];

const firstMasterColumnSet: (keyof RatingListEntityResponse | string)[] = [
  'userIdOrUniqueId',
  'fullScore',
  'subjectScore',
  'achievementScore',
  'priority',
  'hasOriginalDocuments',
  'state',
];

const secondMasterColumnSet: (keyof RatingListEntityResponse | string)[] = [
  'userIdOrUniqueId',
  'fullScore',
  'subjectScore',
  'subjects',
  'achievementScore',
  'priority',
  'hasOriginalDocuments',
  'state',
];

const firstGraduateColumnSet: (keyof RatingListEntityResponse | string)[] = [
  'userIdOrUniqueId',
  'hasOlympiad',
  'fullScore',
  'subjectScore',
  'subjects',
  'achievementScore',
  'priority',
  'hasOriginalDocuments',
  'hasAgreement',
  'state',
];

const secondGraduateColumnSet: (keyof RatingListEntityResponse | string)[] = [
  'userIdOrUniqueId',
  'hasOlympiad',
  'fullScore',
  'subjectScore',
  'subjects',
  'achievementScore',
  'priority',
  'hasOriginalDocuments',
  'state',
];

export const ratingColumnsSets: {
  [key in ApplicationEducationLevel]?:
    | {
        [key in AdmissionBasis]: (keyof RatingListEntityResponse | string)[];
      }
    | (keyof RatingListEntityResponse | string)[];
} = {
  // Бакалавриат
  [ApplicationEducationLevel.BACHELOR]: {
    [AdmissionBasis.BUDGET]: firstBachelorColumnSet,
    [AdmissionBasis.CONTRACT]: firstBachelorColumnSet,
    [AdmissionBasis.SPECIAL_QUOTA]: firstBachelorColumnSet,
    [AdmissionBasis.EXTRA_QUOTA]: firstBachelorColumnSet,
    [AdmissionBasis.GOVERNMENT]: firstBachelorColumnSet,
    [AdmissionBasis.QUOTA]: secondBachelorColumnSet,
    [AdmissionBasis.FOREIGN]: secondBachelorColumnSet,
  },
  // Магистратура
  [ApplicationEducationLevel.MASTER]: {
    [AdmissionBasis.BUDGET]: firstMasterColumnSet,
    [AdmissionBasis.CONTRACT]: firstMasterColumnSet,
    [AdmissionBasis.SPECIAL_QUOTA]: firstMasterColumnSet,
    [AdmissionBasis.EXTRA_QUOTA]: firstMasterColumnSet,
    [AdmissionBasis.GOVERNMENT]: firstMasterColumnSet,
    [AdmissionBasis.QUOTA]: secondMasterColumnSet,
    [AdmissionBasis.FOREIGN]: secondMasterColumnSet,
  },
  // Аспирантура
  [ApplicationEducationLevel.GRADUATE]: {
    [AdmissionBasis.BUDGET]: firstGraduateColumnSet,
    [AdmissionBasis.CONTRACT]: firstGraduateColumnSet,
    [AdmissionBasis.SPECIAL_QUOTA]: firstGraduateColumnSet,
    [AdmissionBasis.EXTRA_QUOTA]: firstGraduateColumnSet,
    [AdmissionBasis.GOVERNMENT]: firstGraduateColumnSet,
    [AdmissionBasis.QUOTA]: secondGraduateColumnSet,
    [AdmissionBasis.FOREIGN]: secondGraduateColumnSet,
  },
  // СПО
  [ApplicationEducationLevel.SECONDARY]: [
    'userFullNameOrIdOrUniqueId',
    'withoutExam',
    'certificateAverage',
    'certificateProfileAverage',
    'hasAchievement',
    'hasFeature',
    'needDormitory',
    'priority',
    'hasOriginalDocuments',
    'state',
  ],
  // Подготовительный факультет
  [ApplicationEducationLevel.PREPARATORY]: [
    'userIdOrUniqueId',
    'hasOriginalDocuments',
    'state',
  ],
};
