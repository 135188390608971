import React, { useEffect } from 'react';

import classnames from 'classnames';

import Loader from 'components/common/ui-kit/Loader';
import terms from 'i18n';

import useAppSelector from '../../../../hooks/common/useAppSelector';
import { ApplicationState } from '../../../../models/applications/enum';
import { entranceTestsApi } from '../../../../redux/api/applications/entrance-tests-api';
import { selectIsUserCommonUser } from '../../../../redux/selectors';
import {
  selectApplication,
  selectApplicationState,
} from '../../../../redux/selectors/enrollee/application';
import EntranceExamsRow from '../Row';

const EntranceExamsTable = ({ applicationId }) => {
  const [getTests, { data: disciplines, isLoading }] =
    entranceTestsApi.useLazyUpdateEntranceTestQuery();

  const isCommonUser = useAppSelector(selectIsUserCommonUser);
  const isEnrolled =
    useAppSelector(selectApplicationState)?.selectedApplication?.state ===
    ApplicationState.ENROLLED;

  const application = useAppSelector(selectApplication);

  const readOnly = isCommonUser
    ? isEnrolled || !!application?.createdFromEpgu
    : false;

  useEffect(() => {
    if (applicationId) {
      getTests(Number(applicationId));
    }
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div>
      <table className="entry-exams-table">
        <thead className="entry-exams-table__head">
          <tr className="entry-exams-table__row">
            <th className="entry-exams-table__head-cell">{terms.EXAM}</th>
            <th className="entry-exams-table__head-cell">
              {terms.EXAM_DATE_MOSCOW}
            </th>
            <th
              className={classnames(
                'entry-exams-table__head-cell',
                'entry-exams-table__head-cell--align-center'
              )}
            >
              {terms.SCORE}
            </th>
            <th
              className={classnames(
                'entry-exams-table__head-cell',
                'entry-exams-table__head-cell--align-center'
              )}
            />
          </tr>
        </thead>
        <tbody className="entry-exams-table__body">
          {disciplines ? (
            disciplines.map((d, index) => (
              <EntranceExamsRow
                applicationId={applicationId}
                test={d}
                key={index}
                // onEditSubmit={() => getTests(Number(applicationId))}
                readOnly={readOnly}
              />
            ))
          ) : (
            <tr className="entry-exams-table__row">
              <td
                className={classnames(
                  'entry-exams-table__cell',
                  'entry-exams-table__cell--align-center'
                )}
                colSpan={1000}
              >
                {terms.HINT_NOT_AVAILABLE}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default EntranceExamsTable;
