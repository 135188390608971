import { isRussianLocale } from '../../../i18n';
import { useGetCountriesQuery } from '../../../redux/api/common/enum-api';

const swapByIso = (to: number, iso: string, src: { iso: string }[]) => {
  const countryToSwap = src.findIndex(c => c.iso === iso);
  if (countryToSwap === -1 || to >= src.length) return;
  [src[to], src[countryToSwap]] = [src[countryToSwap], src[to]];
};

const DNR_VALUE = 187;
const LNR_VALUE = 188;

export const useGetCountriesSelectProps = (withDnrAndLnrRepublics = false) => {
  const { data: countries } = useGetCountriesQuery();

  if (!countries) {
    return [];
  }

  const copyCountries = countries.map(c => ({
    value: c.id,
    label: isRussianLocale() ? c.title : c.titleForeign,
    iso: c.iso,
  }));

  const primaryCountriesIsoCodes = ['RU', 'BY', 'KZ', 'KG', 'TJ'];

  const [primaryCountries, secondaryCountries] = copyCountries.reduce(
    (
      [primaryCountries, secondaryCountries]: [
        primaryCountries: typeof copyCountries,
        secondaryCountries: typeof copyCountries
      ],
      country
    ) => {
      return primaryCountriesIsoCodes.includes(country.iso)
        ? [[...primaryCountries, country], secondaryCountries]
        : [primaryCountries, [...secondaryCountries, country]];
    },
    [[], []]
  );

  swapByIso(0, 'RU', primaryCountries);
  swapByIso(1, 'BY', primaryCountries);
  swapByIso(2, 'KZ', primaryCountries);
  swapByIso(3, 'KG', primaryCountries);
  swapByIso(4, 'TJ', primaryCountries);

  return primaryCountries
    .concat(
      secondaryCountries.sort((country1, country2) => {
        if (country1.label < country2.label) return -1;
        if (country1.label > country2.label) return 1;
        return 0;
      })
    )
    .map(country => ({
      value: country.value,
      label: country.label,
    }))
    .filter(country =>
      withDnrAndLnrRepublics
        ? true
        : country.value !== DNR_VALUE && country.value !== LNR_VALUE
    );
};
