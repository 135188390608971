import React, { FC, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import RequestResetPasswordForm from 'components/auth/RequestResetPasswordForm';
import terms from 'i18n';
import TemplateAuthPage from 'pages/TemplateAuthPage';

import ResetPasswordForm from '../../components/auth/ResetPasswordForm';

const ResetPasswordPage: FC = () => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const code = query.get('code');
  const userId = query.get('userId');
  const hasCodeAndUserId = code && userId !== null;

  return (
    <TemplateAuthPage>
      {hasCodeAndUserId ? (
        <ResetPasswordForm
          code={code}
          userId={userId}
          title={terms.PASSWORD_RESET}
        />
      ) : (
        <RequestResetPasswordForm title={terms.PASSWORD_RESET} />
      )}
    </TemplateAuthPage>
  );
};

export default ResetPasswordPage;
