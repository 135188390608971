import { DateString } from 'models/common';

export enum DocumentType {
  PERSONAL_INFO_AGREEMENT = 'PERSONAL_INFO_AGREEMENT',
  APPLICATION_DOCUMENT = 'APPLICATION_DOCUMENT',
  ENROLL_AGREEMENT_DOCUMENT = 'ENROLL_AGREEMENT_DOCUMENT',
  ENROLL_WITHDRAW_DOCUMENT = 'ENROLL_WITHDRAW_DOCUMENT',
  APPLICATION_UNDERGRADUATE = 'APPLICATION_UNDERGRADUATE',
  APPLICATION_MAGISTRACY = 'APPLICATION_MAGISTRACY',
  APPLICATION_SPECIALTY = 'APPLICATION_SPECIALTY',
  APPLICATION_PREPARATION = 'APPLICATION_PREPARATION',
  APPLICATION_PHD = 'APPLICATION_PHD',
  LEGAL_REPRESENTATIVE_INFO_AGREEMENT = 'LEGAL_REPRESENTATIVE_INFO_AGREEMENT',
  CONTRACT_FOREIGN_INDIVIDUAL_RU = 'CONTRACT_FOREIGN_INDIVIDUAL_RU',
  CONTRACT_FOREIGN_PARTNER_RU = 'CONTRACT_FOREIGN_PARTNER_RU',
  CONTRACT_FOREIGN_INDIVIDUAL_BILINGUAL = 'CONTRACT_FOREIGN_INDIVIDUAL_BILINGUAL',
  CONTRACT_FOREIGN_PARTNER_BILINGUAL = 'CONTRACT_FOREIGN_PARTNER_BILINGUAL',
  CONTRACT_FOREIGN_PARTNER_BILINGUAL_WITH_PRICE = 'CONTRACT_FOREIGN_PARTNER_BILINGUAL_WITH_PRICE',
  CONTRACT_FOREIGN_DEGREE_INDIVIDUAL_RU = 'CONTRACT_FOREIGN_DEGREE_INDIVIDUAL_RU',
  CONTRACT_FOREIGN_DEGREE_PARTNER_RU = 'CONTRACT_FOREIGN_DEGREE_PARTNER_RU',
  CONTRACT_FOREIGN_PREPARATORY_INDIVIDUAL_BILINGUAL = 'CONTRACT_FOREIGN_PREPARATORY_INDIVIDUAL_BILINGUAL',
  CONTRACT_FOREIGN_PREPARATORY_SEMESTER_INDIVIDUAL_BILINGUAL = 'CONTRACT_FOREIGN_PREPARATORY_SEMESTER_INDIVIDUAL_BILINGUAL',
  CONTRACT_FOREIGN_PREPARATORY_PARTNER_BILINGUAL = 'CONTRACT_FOREIGN_PREPARATORY_PARTNER_BILINGUAL',
  CONTRACT_FOREIGN_PREPARATORY_SEMESTER_PARTNER_BILINGUAL_WITH_PRICE = 'CONTRACT_FOREIGN_PREPARATORY_SEMESTER_PARTNER_BILINGUAL_WITH_PRICE',
  CONTRACT_FOREIGN_PARTNER_RU_WITH_PRICE = 'CONTRACT_FOREIGN_PARTNER_RU_WITH_PRICE',
  CONTRACT_FOREIGN_PREPARATORY_PARTNER_BILINGUAL_WITH_PRICE = 'CONTRACT_FOREIGN_PREPARATORY_PARTNER_BILINGUAL_WITH_PRICE',
  CONTRACT_RF_INDIVIDUAL = 'CONTRACT_RF_INDIVIDUAL',
  CONTRACT_RF_LEGAL_ENTITY = 'CONTRACT_RF_LEGAL_ENTITY',
  CONTRACT_RF_DEGREE_INDIVIDUAL = 'CONTRACT_RF_DEGREE_INDIVIDUAL',
  CONTRACT_RF_DEGREE_LEGAL_ENTITY = 'CONTRACT_RF_DEGREE_LEGAL_ENTITY',
  CONTRACT_RF_SPECIALITY_INDIVIDUAL = 'CONTRACT_RF_SPECIALITY_INDIVIDUAL',
  CONTRACT_RF_SPECIALITY_LEGAL_ENTITY = 'CONTRACT_RF_SPECIALITY_LEGAL_ENTITY',
  SELF_IDENTIFICATION_STATEMENT = 'SELF_IDENTIFICATION_STATEMENT',
  COMPLIANCE_LETTER_BILINGUAL = 'COMPLIANCE_LETTER_BILINGUAL',
  RECEIPT_FOR_ACCEPTED_ORIGINAL_EDUCATION_DOCUMENT = 'RECEIPT_FOR_ACCEPTED_ORIGINAL_EDUCATION_DOCUMENT',
  ENTRANCE_TESTS_INSTRUCTION_BACHELOR = 'ENTRANCE_TESTS_INSTRUCTION_BACHELOR',
  ENTRANCE_TESTS_INSTRUCTION_MASTER = 'ENTRANCE_TESTS_INSTRUCTION_MASTER',
  CONTRACT_CREATION_INSTRUCTION = 'CONTRACT_CREATION_INSTRUCTION',
}

export enum DocumentTemplateType {
  APPLICATION_UNDERGRADUATE = 'APPLICATION_UNDERGRADUATE',
  APPLICATION_MAGISTRACY = 'APPLICATION_MAGISTRACY',
  APPLICATION_PHD = 'APPLICATION_PHD',
  APPLICATION_SPECIALTY = 'APPLICATION_SPECIALTY',
  APPLICATION_PREPARATION = 'APPLICATION_PREPARATION',
  APPLICATION_EXPERT_EXAMINATION_REFERRAL = 'APPLICATION_EXPERT_EXAMINATION_REFERRAL',
  APPLICATION_EXPERT_EXAMINATION_RESULT = 'APPLICATION_EXPERT_EXAMINATION_RESULT',
  PERSONAL_INFO_AGREEMENT = 'PERSONAL_INFO_AGREEMENT',
  // LEGAL_REPRESENTATIVE_INFO_AGREEMENT = 'LEGAL_REPRESENTATIVE_INFO_AGREEMENT',
  SELF_IDENTIFICATION_STATEMENT = 'SELF_IDENTIFICATION_STATEMENT',
  CONTRACT_RF_INDIVIDUAL = 'CONTRACT_RF_INDIVIDUAL',
  CONTRACT_RF_LEGAL_ENTITY = 'CONTRACT_RF_LEGAL_ENTITY',
  CONTRACT_RF_DEGREE_INDIVIDUAL = 'CONTRACT_RF_DEGREE_INDIVIDUAL',
  CONTRACT_RF_DEGREE_LEGAL_ENTITY = 'CONTRACT_RF_DEGREE_LEGAL_ENTITY',
  CONTRACT_RF_SPECIALITY_INDIVIDUAL = 'CONTRACT_RF_SPECIALITY_INDIVIDUAL',
  CONTRACT_RF_SPECIALITY_LEGAL_ENTITY = 'CONTRACT_RF_SPECIALITY_LEGAL_ENTITY',
  CONTRACT_FOREIGN_INDIVIDUAL_RU = 'CONTRACT_FOREIGN_INDIVIDUAL_RU',
  CONTRACT_FOREIGN_PARTNER_RU = 'CONTRACT_FOREIGN_PARTNER_RU',
  CONTRACT_FOREIGN_INDIVIDUAL_BILINGUAL = 'CONTRACT_FOREIGN_INDIVIDUAL_BILINGUAL',
  CONTRACT_FOREIGN_PARTNER_BILINGUAL = 'CONTRACT_FOREIGN_PARTNER_BILINGUAL',
  CONTRACT_FOREIGN_PARTNER_BILINGUAL_WITH_PRICE = 'CONTRACT_FOREIGN_PARTNER_BILINGUAL_WITH_PRICE',
  CONTRACT_FOREIGN_DEGREE_INDIVIDUAL_RU = 'CONTRACT_FOREIGN_DEGREE_INDIVIDUAL_RU',
  CONTRACT_FOREIGN_DEGREE_PARTNER_RU = 'CONTRACT_FOREIGN_DEGREE_PARTNER_RU',
  CONTRACT_FOREIGN_PREPARATORY_INDIVIDUAL_BILINGUAL = 'CONTRACT_FOREIGN_PREPARATORY_INDIVIDUAL_BILINGUAL',
  CONTRACT_FOREIGN_PREPARATORY_SEMESTER_INDIVIDUAL_BILINGUAL = 'CONTRACT_FOREIGN_PREPARATORY_SEMESTER_INDIVIDUAL_BILINGUAL',
  CONTRACT_FOREIGN_PREPARATORY_PARTNER_BILINGUAL = 'CONTRACT_FOREIGN_PREPARATORY_PARTNER_BILINGUAL',
  CONTRACT_FOREIGN_PREPARATORY_SEMESTER_PARTNER_BILINGUAL_WITH_PRICE = 'CONTRACT_FOREIGN_PREPARATORY_SEMESTER_PARTNER_BILINGUAL_WITH_PRICE',
  CONTRACT_FOREIGN_PARTNER_RU_WITH_PRICE = 'CONTRACT_FOREIGN_PARTNER_RU_WITH_PRICE',
  CONTRACT_FOREIGN_PREPARATORY_PARTNER_BILINGUAL_WITH_PRICE = 'CONTRACT_FOREIGN_PREPARATORY_PARTNER_BILINGUAL_WITH_PRICE',
  RECEIPT_FOR_ACCEPTED_ORIGINAL_EDUCATION_DOCUMENT = 'RECEIPT_FOR_ACCEPTED_ORIGINAL_EDUCATION_DOCUMENT',
  COMPLIANCE_LETTER_BILINGUAL = 'COMPLIANCE_LETTER_BILINGUAL',
  COMPLIANCE_LETTER_BILINGUAL_SPECIALITY = 'COMPLIANCE_LETTER_BILINGUAL_SPECIALITY',
  ENTRANCE_TESTS_INSTRUCTION_BACHELOR = 'ENTRANCE_TESTS_INSTRUCTION_BACHELOR',
  ENTRANCE_TESTS_INSTRUCTION_MASTER = 'ENTRANCE_TESTS_INSTRUCTION_MASTER',
  CONTRACT_CREATION_INSTRUCTION = 'CONTRACT_CREATION_INSTRUCTION',

  APPLICATION_ENROLL_AGREEMENT = 'APPLICATION_ENROLL_AGREEMENT',
  APPLICATION_ENROLL_WITHDRAW_AGREEMENT = 'APPLICATION_ENROLL_WITHDRAW_AGREEMENT',

  APPLICATION_DOCUMENTS_WITHDRAW = 'APPLICATION_DOCUMENTS_WITHDRAW',
  APPLICATION_ENROLL_REJECT_ORIGINAL_WITHDRAW = 'APPLICATION_ENROLL_REJECT_ORIGINAL_WITHDRAW',
  APPLICATION_ENROLL_REJECT = 'APPLICATION_ENROLL_REJECT',
  APPLICATION_ORIGINAL_WITHDRAW = 'APPLICATION_ORIGINAL_WITHDRAW',
  APPLICATION_DOCUMENTS_ORIGINAL_WITHDRAW = 'APPLICATION_DOCUMENTS_ORIGINAL_WITHDRAW',
}

export type ApplicationDocument = {
  id: number;
  isSigned: boolean;
  externalDocumentId: number;
  title: string;
  titleForeign: string;
  applicationDirectionId?: number;
  createdAt: DateString;
  updatedAt: DateString;
  type: DocumentType;
};
