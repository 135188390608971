import React, { FC, useRef } from 'react';

import classNames from 'classnames';

import { ReactComponent as CheckMarkIcon } from 'assets/images/icons/check-mark.svg';
import { ReactComponent as CrossIcon } from 'assets/images/icons/cross.svg';
import { ReactComponent as FileIcon } from 'assets/images/icons/file.svg';
import { ReactComponent as RedCrossIcon } from 'assets/images/icons/red-cross.svg';
import Loader from 'components/common/ui-kit/Loader';
import {
  AttachmentStatus,
  SenderAttachment,
  SenderAttachments,
} from 'hooks/chat/useChatAttachments';
import useAppSelector from 'hooks/common/useAppSelector';
import { ChatAttachment } from 'models/chat';
import { useLazyDownloadFileQuery } from 'redux/api/common/files-api';

import useChatApplicantId from '../../../../../hooks/chat/useChatApplicantId';
import { UserRole } from '../../../../../models/user';

type AttachmentsProps = {
  chatAttachments?: ChatAttachment[];
  senderAttachments?: SenderAttachments;
  deleteSenderAttachment?: (key: string) => void;
  className?: string;
  senderId?: number;
};

const Attachments: FC<AttachmentsProps> = ({
  chatAttachments,
  senderAttachments,
  deleteSenderAttachment,
  className,
  senderId,
}) => {
  const fileLink = useRef<HTMLAnchorElement>(null);
  const { id: userId, role } = useAppSelector(state => state.user);
  const applicantId = useChatApplicantId();
  const [downloadFile] = useLazyDownloadFileQuery();

  const openFile = (filename: string, fileId?: number) => {
    const id = role === UserRole.USER ? userId : applicantId;

    if (!fileId || !userId || !senderId || !id) return;
    downloadFile({ userId: id, fileId })
      .unwrap()
      .then(data => {
        const file = new File([data], filename, { type: data.type });
        const url = URL.createObjectURL(file);
        const preview = window.open(url, '_blank');

        if (
          fileLink.current &&
          (!preview || preview.closed || typeof preview.closed === 'undefined')
        ) {
          fileLink.current.href = url;
          fileLink.current.download = filename;
          fileLink.current.click();
          URL.revokeObjectURL(url);
          fileLink.current.removeAttribute('href');
          fileLink.current.removeAttribute('download');
        }
      });
  };

  const getStatusIcon = (status: AttachmentStatus) => {
    if (status === AttachmentStatus.NEW || status === AttachmentStatus.LOADING)
      return (
        <Loader className="attachments__status attachments__status--green" />
      );
    if (status === AttachmentStatus.ERROR)
      return <RedCrossIcon className="attachments__status" />;
    if (status === AttachmentStatus.READY)
      return (
        <CheckMarkIcon className="attachments__status attachments__status--green" />
      );
    return null;
  };

  const renderAttachment = (noFile: boolean, name: string, id?: number) => (
    <a
      className={classNames('attachments__url', {
        'attachments__url--no-file': noFile,
      })}
      onClick={() => {
        if ('ontouchstart' in window) return;
        openFile(name, id);
      }}
      onTouchStart={() => openFile(name, id)}
    >
      <FileIcon className="attachments__file-icon" />
      <span className="attachments__filename">{name}</span>
    </a>
  );

  const renderSenderAttachment = (
    { id, file, status }: SenderAttachment,
    key: string
  ) => (
    <div key={key} className="attachments__attachment">
      {renderAttachment(status !== AttachmentStatus.READY, file.name, id)}
      <div className="attachments__right">
        {getStatusIcon(status)}
        {status !== AttachmentStatus.ERROR && (
          <CrossIcon
            onClick={() => deleteSenderAttachment?.(key)}
            className="attachments__delete"
          />
        )}
      </div>
    </div>
  );

  const renderChatAttachment = (
    { documentId, realName }: ChatAttachment,
    index: number
  ) => (
    <div key={index} className="attachments__attachment">
      {renderAttachment(false, realName, documentId)}
    </div>
  );

  return senderAttachments || chatAttachments ? (
    <div className={classNames('attachments', className)}>
      {senderAttachments
        ? Object.entries(senderAttachments).map(([key, value]) =>
            renderSenderAttachment(value, key)
          )
        : chatAttachments?.map(renderChatAttachment)}
      <a ref={fileLink} style={{ display: 'none' }} />
    </div>
  ) : null;
};

export default Attachments;
