import React, { FC } from 'react';

import { Provider } from 'react-redux';

import store from 'redux/store';

export const withRedux = (Component: FC) => () =>
  (
    <Provider store={store}>
      <Component />
    </Provider>
  );
