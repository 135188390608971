import { ApplicationEducationLevel } from 'models/applications/enum';
import { DateString } from 'models/common';
import { AdmissionListEntity, RatingListEntityResponse } from 'models/students';
import { apiSlice } from 'redux/api/api-slice';

const baseUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/admission-list`;

export const admissionsListApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAdmissionsList: builder.query<
      AdmissionListEntity[],
      {
        applicationEducationLevel?: ApplicationEducationLevel;
        directionEducationFormId?: number;
        directionPaymentFormId?: number;
        directionId?: number;
      }
    >({
      query: params => {
        return {
          url: `${baseUrl}`,
          params: params,
        };
      },
    }),
    getAdmissionsListForm: builder.query<
      {
        directionCapacity: number;
        list: AdmissionListEntity[];
        log: { total: number; datetime: DateString };
        total: number;
        totalWithOriginals: number;
      },
      {
        applicationEducationLevel?: ApplicationEducationLevel;
        directionEducationFormId?: number;
        directionPaymentFormId?: number;
        directionId?: number;
      }
    >({
      query: params => {
        return {
          url: `${baseUrl}/form`,
          params: params,
        };
      },
    }),

    getFormRating: builder.query<
      {
        directionCapacity: number;
        list: RatingListEntityResponse[];
        log: { total: number; datetime: DateString };
        total: number;
        totalWithOriginals: number;
      },
      {
        applicationEducationLevel?: ApplicationEducationLevel;
        directionEducationFormId?: number;
        directionPaymentFormId?: number;
        directionId?: number;
      }
    >({
      query: params => {
        return {
          url: `${baseUrl}/form-rating`,
          params: params,
        };
      },
    }),
  }),
});

export const {
  useGetAdmissionsListQuery,
  useGetAdmissionsListFormQuery,
  useGetFormRatingQuery,
} = admissionsListApi;
