import React, { FC } from 'react';

import { ReactComponent as DownloadIcon } from 'assets/images/icons/download.svg';
import { ReactComponent as EditIcon } from 'assets/images/icons/edit.svg';
import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg';
import { formatDateYYYYMMDD } from 'utils/date';

import { Olympiad } from '../../../../models/applications/olympiads';

const headers = [
  'Название олимпиады',
  'Подтверждающий документ',
  'Серия и номер',
  'Дата выдачи',
];

type TableProps = {
  items: Olympiad[];
  onEdit: (achievement: Olympiad) => void;
  onDelete: (docId: number) => void;
  onDownload: (docId: number) => void;
  readOnly: boolean;
};

const Table: FC<TableProps> = ({
  items,
  onDelete,
  onDownload,
  onEdit,
  readOnly,
}) => (
  <table className="achievements-table">
    <thead>
      <tr>
        {headers.map(header => (
          <th key={header}>{header}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {items.map((item, id) => (
        <tr key={id}>
          <td>{item.olympiadType.title}</td>
          <td>{item.confirmationDocumentTypeId.title}</td>
          <td>{item.documentCode}</td>
          <td>{formatDateYYYYMMDD(item.dateOfIssue) || '---'}</td>
          <td>
            <div className="buttons">
              <button type="button" onClick={() => onDownload(item.documentId)}>
                <DownloadIcon />
              </button>
              <button
                type="button"
                disabled={readOnly}
                onClick={() => onEdit(item)}
              >
                <EditIcon />
              </button>
              <button
                type="button"
                disabled={readOnly}
                onClick={() => onDelete(item.id)}
              >
                <TrashIcon />
              </button>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default Table;
