import React, { FC } from 'react';

import DownloadFilesForm from 'components/statements/DownloadFilesForm';

const DownloadsPage: FC = () => (
  <div>
    <DownloadFilesForm />
  </div>
);

export default DownloadsPage;
