import {
  ApplicationId,
  ApplicationView,
} from '../../../../models/applications/applications';
import { apiSlice } from '../../api-slice';

const baseUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/applications/coordinator-managed`;

export const coordinatorApplicationsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    rejectApplicationByCoordinator: builder.mutation<
      ApplicationView,
      ApplicationId
    >({
      query: applicationId => ({
        url: `${baseUrl}/reject-by-coordinator/${applicationId}`,
        method: 'POST',
      }),
    }),

    approveApplicationByCoordinator: builder.mutation<
      ApplicationView,
      ApplicationId
    >({
      query: applicationId => ({
        url: `${baseUrl}/approve-by-coordinator/${applicationId}`,
        method: 'POST',
      }),
    }),

    takeApplicationToWorkByCoordinator: builder.mutation<
      ApplicationView,
      ApplicationId
    >({
      query: applicationId => ({
        url: `${baseUrl}/take-to-work/${applicationId}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useApproveApplicationByCoordinatorMutation,
  useRejectApplicationByCoordinatorMutation,
} = coordinatorApplicationsApi;
