import React, { useEffect, useMemo } from 'react';

import { Controller, useForm } from 'react-hook-form';

import TemplateModal from 'components/common/modals/TemplateModal';
import Button from 'components/common/ui-kit/Button';
import Input from 'components/common/ui-kit/Input';
import ReactSelect from 'components/common/ui-kit/Select';
import ProfileSection from 'components/profile/ProfileSection';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import { useLazyUploadFiles } from 'hooks/files/useLazyUploadFiles';
import { mapPrivilegeTypeToSelectOption } from 'hooks/privilege/useAddPrivilegesForm';
import terms from 'i18n';
import {
  CreatePrivilegeRequest,
  PrivilegeGroupingType,
  PrivilegeType,
} from 'models/applications/privileges';
import { DateString, SelectOption } from 'models/common';
import { createToast, hideModal } from 'redux/actions';
import { privilegesApi } from 'redux/api/applications/privileges-api';
import { modalDataSelector } from 'redux/selectors';

import LazyFileUploader from '../../ui-kit/LazyFileUploader';

const MasterPrivilegeModal = () => {
  const dispatch = useAppDispatch();
  const { applicationId, privilege, type, userId } =
    useAppSelector(modalDataSelector);

  const { data: privilegeTypes } =
    privilegesApi.useGetMasterLocalPrivilegeTypeQuery();

  const privilegeOptions = useMemo(() => {
    return privilegeTypes?.map(mapPrivilegeTypeToSelectOption);
  }, [privilegeTypes]);

  const [
    getConfirmationDocTypes,
    {
      data: confirmationDocTypes,
      isLoading: isConfirmationDocsListLoading,
      isError: isConfirmationDocsListError,
    },
  ] = privilegesApi.useLazyGetPrivilegesConfirmationDocTypesQuery();

  const confirmationDocTypesOptions = useMemo(() => {
    if (isConfirmationDocsListError || isConfirmationDocsListLoading) return [];
    return confirmationDocTypes?.map(type => ({
      value: type.id,
      label: type.title,
    }));
  }, [
    confirmationDocTypes,
    isConfirmationDocsListError,
    isConfirmationDocsListLoading,
  ]);

  const [create] = privilegesApi.useCreatePrivilegeMutation();
  const [update] = privilegesApi.useUpdatePrivilegeMutation();

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful, isSubmitted },
    reset,
    resetField,
  } = useForm<{
    privilegeType: SelectOption<PrivilegeType>;
    documentId: number;
    dateOfIssue: DateString;
    confirmationDocumentType: SelectOption;
    documentCode: string;
  }>({});

  const fileProps = useLazyUploadFiles({
    initialFileIds: watch('documentId'),
    userId,
  });

  useEffect(() => {
    if (!privilege) return;
    getConfirmationDocTypes(privilege.privilegeTypeResponse.id);
    reset({
      ...privilege,
      privilegeType: {
        label: privilege.privilegeTypeResponse.title,
        value: privilege.privilegeTypeResponse.id,
      },
      confirmationDocumentType: {
        label: privilege.confirmationDocumentTypeResponse.title,
        value: privilege.confirmationDocumentTypeResponse.id,
      },
    });
  }, []);

  const onSubmit = handleSubmit(data => {
    return fileProps
      .uploadFiles()
      .then(fileId => setValue('documentId', fileId))
      .then(() => {
        const request: CreatePrivilegeRequest = {
          ...data,
          confirmationDocumentTypeId: data.confirmationDocumentType?.value,
          privilegeTypeId: data.privilegeType.value as unknown as number,
          documentId: watch('documentId'),
          applicationId,
          groupingType: PrivilegeGroupingType.PREROGATIVE,
        };

        return privilege
          ? update({
              ...request,
              id: privilege.id,
              groupingType: privilege.groupingType,
            }).unwrap()
          : create(request).unwrap();
      })
      .then(() => {
        dispatch(
          createToast(
            privilege
              ? 'Отличительный признак был успешно изменён'
              : 'Отличительный признак был успешно добавлен',
            'success'
          )
        );
        dispatch(hideModal());
      });
  });

  return (
    <TemplateModal
      title={privilege ? terms.EDIT_HALLMARK_TITLE : terms.ADD_HALLMARK_TITLE}
    >
      <form onSubmit={onSubmit}>
        <div className="profile-form__fields">
          <Controller
            name="privilegeType"
            control={control}
            rules={{
              required: terms.REQUIRED_FIELD,
              onChange: ({ target: { value } }) => {
                if (!value) return;
                resetField('confirmationDocumentType');
                getConfirmationDocTypes(value.value);
              },
            }}
            render={({ field }) => (
              <ReactSelect
                options={privilegeOptions}
                placeholder={terms.HALLMARK_TYPE}
                className="profile-form__field"
                markRequired={true}
                {...(field as object)}
                error={errors.privilegeType?.message as string}
              />
            )}
          />

          <Input
            className="profile-form__field"
            placeholder={terms.DATE_ISSUE}
            type="date"
            markRequired
            {...register('dateOfIssue', {
              required: terms.REQUIRED_FIELD,
            })}
            error={errors.dateOfIssue?.message as string}
          />

          <Controller
            name="confirmationDocumentType"
            control={control}
            rules={{
              required: terms.REQUIRED_FIELD,
            }}
            render={({ field }) => (
              <ReactSelect
                options={confirmationDocTypesOptions}
                placeholder={terms.CONFIRMATION_DOCUMENT}
                className="profile-form__field"
                markRequired={true}
                {...(field as object)}
                isDisabled={
                  !confirmationDocTypesOptions?.length ||
                  isConfirmationDocsListLoading
                }
                error={errors.confirmationDocumentType?.message as string}
              />
            )}
          />

          <Input
            className="profile-form__field"
            placeholder={terms.NUMBER_DOCUMENT}
            markRequired={true}
            {...register('documentCode', {
              required: terms.REQUIRED_FIELD,
            })}
            error={errors.documentCode?.message as string}
          />
        </div>

        <ProfileSection title={terms.DOCUMENT} markRequired={true}>
          <LazyFileUploader
            className="profile-form__file-uploader"
            {...fileProps}
          />
          {fileProps.files.length === 0 &&
            isSubmitted &&
            !isSubmitSuccessful && (
              <div className="profile-form__error">{terms.REQUIRED_FIELD}</div>
            )}
        </ProfileSection>
        <Button
          theme="success"
          className="profile-form__button profile-form__button--align-left"
        >
          {terms.SAVE}
        </Button>
      </form>
    </TemplateModal>
  );
};

export default MasterPrivilegeModal;
