import { createSlice } from '@reduxjs/toolkit';

type StatementsInitialState = {
  disableOnIos: boolean;
};

const initialState: StatementsInitialState = {
  disableOnIos: true,
};

/**
 * TODO - migrate
 * @param state
 */
export const statementsSlice = createSlice({
  name: 'statements',
  initialState,
  reducers: {
    setDisableCheckOnIos(state) {
      state.disableOnIos = !state.disableOnIos;
    },
  },
});

export default statementsSlice.reducer;
