import React, { FC, forwardRef, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import Select, { components, GroupBase, Props } from 'react-select';
import type SelectType from 'react-select/dist/declarations/src/Select';

import Checkbox from 'components/common/ui-kit/Checkbox';
import useWindowsSize from 'hooks/common/useWindowsSize';
import terms from 'i18n';
import { SelectOption } from 'models/common';
import mergeRefs from 'utils/merge-refs';

type SelectProps<
  Option = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Props<Option, IsMulti, Group> & {
  error?: string;
  markRequired?: boolean;
  allowSelectAll?: boolean;
  invariant?: boolean;
  onPaginate?: () => void;
  onChange?: (v: unknown) => void;
};

const SelectInner = forwardRef(
  <
    IsMulti extends boolean = false,
    Group extends GroupBase<SelectOption> = GroupBase<SelectOption>
  >(
    {
      error,
      className,
      onFocus,
      invariant = false,
      allowSelectAll = false,
      onBlur,
      markRequired = false,
      onPaginate,
      ...rest
    }: SelectProps<SelectOption, IsMulti, Group>,
    ref
  ) => {
    const selectRef = useRef<SelectType<SelectOption, IsMulti, Group>>(null);
    const [active, setActive] = useState(false);
    const { isMobile } = useWindowsSize();

    const selectAllOption = {
      value: 'all',
      label: 'Выбрать все',
    };

    const handleFocusOut = (e: React.FocusEvent<HTMLInputElement>) => {
      if (e.target.value === '' && !Array.isArray(rest.value)) {
        setActive(false);
      }
    };

    useEffect(() => {
      if ((rest.value || rest.inputValue) && !active) {
        setActive(true);
      }

      if (!rest.value && !rest.inputValue && active) {
        setActive(false);
        selectRef?.current?.clearValue();
      }
    }, [rest.value, rest.inputValue]);

    return (
      <div
        className={classNames('select-container', className, {
          'select-container--active': Boolean(active),
          'select-container--error': Boolean(error),
        })}
      >
        {allowSelectAll ? (
          <Select
            ref={mergeRefs([ref, selectRef]) as any}
            noOptionsMessage={() => terms.HINT_NOT_AVAILABLE}
            loadingMessage={() => `${terms.LOADING}...`}
            {...rest}
            placeholder=""
            className="react-select-container"
            classNamePrefix="react-select"
            autoFocus={false}
            onFocus={e => {
              onFocus && onFocus(e);
              setActive(true);
            }}
            onBlur={e => {
              handleFocusOut(e);
              onBlur && onBlur(e);
            }}
            {...(isMobile && { captureMenuScroll: true })}
            onMenuScrollToBottom={() => {
              onPaginate && onPaginate();
            }}
            options={[selectAllOption, ...(rest.options as any)]}
            onChange={(selected, current) => {
              if (current?.option?.value === 'all' && rest.onChange) {
                invariant &&
                  current.action === 'select-option' &&
                  rest.onChange([selectAllOption]);
                !invariant &&
                  current.action === 'select-option' &&
                  rest.onChange([...(rest.options as any)]);
                current.action === 'deselect-option' && rest.onChange(null);
              } else {
                rest.onChange && rest.onChange(selected);
              }
            }}
          />
        ) : (
          <Select
            ref={selectRef}
            noOptionsMessage={() => terms.HINT_NOT_AVAILABLE}
            {...rest}
            placeholder=""
            className="react-select-container"
            classNamePrefix="react-select"
            autoFocus={false}
            onFocus={e => {
              onFocus && onFocus(e);
              setActive(true);
            }}
            onBlur={e => {
              handleFocusOut(e);
              onBlur && onBlur(e);
            }}
            onInputChange={(newValue, actionMeta) => {
              rest.onInputChange?.(newValue, actionMeta);
              setActive(true);
            }}
            {...(isMobile && { captureMenuScroll: true })}
            onMenuScrollToBottom={() => {
              onPaginate && onPaginate();
            }}
          />
        )}
        <span
          onClick={() => selectRef.current?.focus()}
          className={classNames('select-container__placeholder', {
            'select-container__placeholder--required': markRequired,
          })}
        >
          {rest.placeholder}
        </span>
        {error && <span className="select-container__error">{error}</span>}
      </div>
    );
  }
);

const ReactSelect = SelectInner as <
  Option = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: SelectProps<Option, IsMulti, Group> & {
    ref?: React.Ref<HTMLSelectElement>;
  }
) => ReturnType<typeof SelectInner>;

export default ReactSelect;

export const SingleValue: FC<any> = props => (
  <components.SingleValue {...props}>
    {props.data.chipLabel}
  </components.SingleValue>
);

export const Option: FC<any> = props => (
  <div>
    <components.Option
      {...props}
      className={classNames(
        props.className,
        props?.selectProps?.value?.find(el => el.value === 'all') &&
          'allSelected'
      )}
    >
      <Checkbox
        checked={
          props?.selectProps?.value?.find(el => el.value === 'all')
            ? !props.isSelected
            : props.isSelected
        }
        text={''}
        onChange={() => null}
      />
      <label style={{ marginLeft: '20px' }}>{props.label}</label>
    </components.Option>
  </div>
);

export const MultiValue = props => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);
