import { useMemo } from 'react';

import useAppSelector from 'hooks/common/useAppSelector';
import { UserRole } from 'models/user';

const enrolleeReplierRoles = [
  UserRole.MODERATOR,
  UserRole.ADMIN,
  UserRole.SUPPORT,
  UserRole.CALL_CENTER,
  UserRole.SPECIALIST,
  UserRole.INSTITUTE_STAFF,
];

const useChatIsEnrolleeReplier = () => {
  const userRole = useAppSelector(state => state.user.role);
  return useMemo(() => enrolleeReplierRoles.includes(userRole), [userRole]);
};

export default useChatIsEnrolleeReplier;
