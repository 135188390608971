import React, { Dispatch, FC, SetStateAction } from 'react';

import { Controller } from 'react-hook-form';

import Button from 'components/common/ui-kit/Button';
import { InputV2 } from 'components/common/ui-kit/Input/view';
import ReactSelect from 'components/common/ui-kit/Select';
import terms from 'i18n';
import { DirectionFilters } from 'models/applications/directions';
import { createInputV2Props, createSelectProps } from 'utils/form-helpers';

import useDirectionsTrainingSearch from '../../../hooks/statements/directions/useDirectionsTrainingSearch';
import { useDirectionsTrainingSearchSelectProps } from '../../../hooks/statements/directions/useDirectionsTrainingSearchSelectProps';
import { ApplicationEducationLevel } from '../../../models/applications/enum';
import { educationLevelToText } from '../ApplicationsList/view';

type DirectionsTrainingSearchProps = {
  paymentFormId?: number;
  selectedTrainingLevel: ApplicationEducationLevel;
  isPaymentFormInputDisabled: boolean;
  setFilters: Dispatch<SetStateAction<DirectionFilters | undefined>>;
};

const DirectionsTrainingSearch: FC<DirectionsTrainingSearchProps> = ({
  paymentFormId,
  isPaymentFormInputDisabled,
  selectedTrainingLevel,
  setFilters,
}) => {
  const {
    fields,
    control,
    onSubmit,
    resetForm,
    onBackClick,
    formState: { errors },
  } = useDirectionsTrainingSearch(
    setFilters,
    isPaymentFormInputDisabled,
    paymentFormId
  );

  const selectProps = createSelectProps(errors);
  const inputV2Props = createInputV2Props(errors);

  const { educationFormSelectProps, paymentFormSelectProps } =
    useDirectionsTrainingSearchSelectProps();

  return (
    <div className="directions-training-search mb-24">
      <form
        className="directions-training-search__search-input"
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
        onKeyDown={e => {
          if (e.key !== 'Enter') return;
          e.stopPropagation();
          e.preventDefault();
          onSubmit();
        }}
      >
        {/* <Button onClick={() => onBackClick()}>
          <TabArrowDown />
        </Button> */}

        <Controller
          name={fields.title.name}
          control={control}
          render={({ field }) => (
            <InputV2
              className="directions-training-search__input"
              placeholder={terms.DIRECTIONS_TRAINING}
              type="text"
              {...inputV2Props(field)}
            />
          )}
        />

        <Button type="submit" theme="success">
          {terms.FIND}
        </Button>
      </form>

      <ReactSelect
        className="directions-training-search__select"
        placeholder={terms.STUDY_LEVEL}
        value={{
          value: educationLevelToText[selectedTrainingLevel],
          label: educationLevelToText[selectedTrainingLevel],
          id: 0,
        }}
        isClearable
        markRequired
        isDisabled
      />

      <Controller
        name={fields.educationFormId.name}
        control={control}
        render={({ field }) => (
          <ReactSelect
            className="directions-training-search__select"
            placeholder={terms.MODE_OF_STUDY}
            {...(field as object)}
            {...selectProps(field, educationFormSelectProps)}
            isClearable
          />
        )}
      />

      <Controller
        name={fields.paymentFormId.name}
        control={control}
        render={({ field }) => (
          <ReactSelect
            className="directions-training-search__select"
            placeholder={terms.FORM_PAYMENT_ID}
            {...(field as object)}
            {...selectProps(field, paymentFormSelectProps)}
            // isClearable
            isDisabled={isPaymentFormInputDisabled}
          />
        )}
      />

      <Button
        className="directions-training-search__reset-filter"
        onClick={resetForm}
      >
        {terms.RESET_FILTER}
      </Button>
    </div>
  );
};

export default DirectionsTrainingSearch;
