import {
  EntranceTest,
  EntranceTestDate,
} from '../../../models/applications/entrance-tests';
import { apiSlice } from '../api-slice';

const baseUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/entrance-tests`;

export const entranceTestsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAllEntranceTestsByApplicationId: builder.query<EntranceTest[], number>({
      query: applicationId => ({
        url: `${baseUrl}/by-application-id/${applicationId}`,
      }),
    }),

    getEntranceTestDates: builder.query<
      EntranceTestDate[],
      { testId: number; applicationId: number }
    >({
      query: ({ testId, applicationId }) => ({
        url: `${baseUrl}/schedule/${testId}`,
        params: { applicationId },
      }),
    }),

    updateEntranceTest: builder.query<EntranceTest[], number>({
      query: applicationId => ({
        url: `${baseUrl}/update-tests/${applicationId}`,
        method: 'POST',
      }),
    }),

    updateOrDeleteScore: builder.mutation<
      EntranceTest,
      {
        readonly id: number;
        readonly score?: number;
      }
    >({
      query: ({ id, score }) => ({
        url: `${baseUrl}/update-or-delete/${id}`,
        method: 'POST',
        params: {
          score,
        },
      }),
      invalidatesTags: ['Statement'],
    }),

    setTimeToEntranceTest: builder.mutation<
      EntranceTest,
      {
        readonly testId: number;
        readonly scheduleId: number;
      }
    >({
      query: ({ testId, scheduleId }) => ({
        url: `${baseUrl}/set-datetime/${testId}`,
        method: 'POST',
        params: {
          scheduleId,
        },
      }),
    }),

    deleteTimeOfEntranceTest: builder.mutation<
      EntranceTest,
      { readonly testId: number }
    >({
      query: ({ testId }) => ({
        url: `${baseUrl}/clear-test/${testId}`,
        method: 'POST',
        body: {},
      }),
    }),
  }),
});
