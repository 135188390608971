import { useEffect, useState } from 'react';

import { useGeneralDataDraft } from '../questionnaire/common/draft/useGeneralDataDraft';

const getValueOrEmptyString = (value?: string) => (value ? ` ${value}` : '');

export const useChatUserName = () => {
  const { draft } = useGeneralDataDraft();
  const [name, setName] = useState<string>('');

  useEffect(() => {
    if (draft) {
      if (draft?.name) {
        setName(
          `${getValueOrEmptyString(draft.surname)} ${
            draft.name
          }${getValueOrEmptyString(draft.patronymic)}`
        );
      } else {
        setName(
          `${getValueOrEmptyString(draft.surnameLatin)} ${
            draft.nameLatin
          }${getValueOrEmptyString(draft.patronymicLatin)}`
        );
      }
    }
  }, [draft]);

  return {
    senderName: name,
    senderFirstName: draft?.name,
    senderLastName: draft?.surname,
    senderMiddleName: draft?.patronymic,
    senderFirstNameLatin: draft?.nameLatin,
    senderLastNameLatin: draft?.surnameLatin,
    senderMiddleNameLatin: draft?.patronymicLatin,
  };
};
