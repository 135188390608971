import { FC } from 'react';

import useAppSelector from '../../../hooks/common/useAppSelector';
import { UserRole } from '../../../models/user';
import { selectUserRole } from '../../../redux/selectors';
import CheckListEntryValueSelect, {
  CheckListEntryValueSelectProps,
} from '../CheckListEntryValueSelect/view';

const CheckListEntryValueSelectWithRoleChecker: FC<
  CheckListEntryValueSelectProps
> = ({ ...props }) => {
  const role = useAppSelector(selectUserRole);
  if (!role || [UserRole.USER, UserRole.CALL_CENTER].includes(role))
    return null;
  return <CheckListEntryValueSelect {...props} />;
};

export default CheckListEntryValueSelectWithRoleChecker;
