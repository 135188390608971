import { useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { privilegesApi } from 'redux/api/applications/privileges-api';

import { ApplicationEducationLevel } from '../../../models/applications/enum';
import { achievementsApi } from '../../../redux/api/applications/achievements-api';
import { selectCurrentEducationLevel } from '../../../redux/selectors/enrollee/application';
import useAppSelector from '../../common/useAppSelector';

type AchievementsPageForm = {
  readonly publicationsAmount?: number;
  readonly inventionsAmount?: number;
};

export const useAchievementsPageForm = (applicationId: number) => {
  const educationLevel = useAppSelector(selectCurrentEducationLevel);

  const { data: form, refetch: refetchAchievementsForm } =
    achievementsApi.useGetAchievementFormQuery(applicationId);
  const [update] = achievementsApi.useUpdateAchievementFormInfoMutation();

  const { register, handleSubmit, reset } = useForm<AchievementsPageForm>();

  const { data: privileges } =
    privilegesApi.useGetPrivilegesByApplicationIdQuery(applicationId);

  useEffect(() => {
    if (form) {
      reset({
        publicationsAmount: form.publicationsAmount,
        inventionsAmount: form.inventionsAmount,
      });
    }
  }, [form]);

  const fields = {
    publicationsAmount: register('publicationsAmount', { valueAsNumber: true }),
    inventionsAmount: register('inventionsAmount', { valueAsNumber: true }),
  };

  const onSubmit = handleSubmit(data => {
    update({
      ...data,
      applicationId,
    });
  });

  return {
    fields,
    onSubmit,
    refetchAchievementsForm,
    achievements: form ? form.achievements : [],
    olympiads: form ? form.olympiads : [],
    privileges: privileges ?? [],
    isFormVisible: educationLevel === ApplicationEducationLevel.GRADUATE,
  };
};
