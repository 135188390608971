import React, { FC } from 'react';

import { Controller } from 'react-hook-form';

import AdminTableListPage from 'components/admin/AdminTableListPage';
import { InputV2 } from 'components/common/ui-kit/Input/view';
import Loader from 'components/common/ui-kit/Loader';
import ReactSelect, {
  MultiValue,
  Option,
} from 'components/common/ui-kit/Select/view';
import {
  columnsCoordinatorStatements,
  columnsExpertStatements,
  columnsStatements,
} from 'config/moderator-table';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import {
  ServeMethod,
  useApplicationFilterOptions,
} from 'hooks/moderator/applications/useApplicationFilterOptions';
import terms from 'i18n';
import {
  ApplicationChecklistNames,
  OriginalDocumentSubmissionStatus,
} from 'models/applications/applications';
import {
  ApplicationFlag,
  ApplicationState,
  MessagesState,
} from 'models/applications/enum';
import { SelectOption } from 'models/common';
import { UserRole } from 'models/user';
import { createToast } from 'redux/actions';
import { useGetCountriesQuery } from 'redux/api/common/enum-api';
import {
  staffApplicationsApi,
  useLazyGetAllApplicationsAsCSVQuery,
} from 'redux/api/staff/staff-applications-api';
import { selectIsUserSupport, selectUserRole } from 'redux/selectors';
import { applicationsManagerClient } from 'utils/axios-clients';
import { downloadFile, uploadFile } from 'utils/common';
import { createInputV2Props } from 'utils/form-helpers';

import { isValidSnils, normalizeSnils } from '../../../utils/form-validators';

export const mapApplicationStateToText = (
  state?: ApplicationState,
  flags?: ApplicationFlag[]
) => {
  if (!state || !flags) return '';

  return flags.includes(ApplicationFlag.EDITED_BY_USER) &&
    state === ApplicationState.IN_WORK
    ? terms.APPLICATION_EDITED_BY_USER
    : terms[state];
};

export const mapFiltersToRequest = (filters, map) => {
  const request = {
    ...filters,
  };

  for (const filter in request) {
    if (Array.isArray(request[filter]) && request[filter].length === 0) {
      delete request[filter];
    } else if (request[filter] === '') {
      delete request[filter];
    }
  }

  if (request.serveMethods) {
    request.submitWithRecruiter = request.serveMethods.some(
      el => el.value === ServeMethod.PARTNER
    );
  }

  request.applicationState = filters.applicationState?.map(stateOption => {
    if (stateOption.value === ApplicationFlag.EDITED_BY_USER) {
      request.stateEditedByUser = true;
      return { ...stateOption, value: ApplicationState.IN_WORK };
    }
    return stateOption;
  });

  if (filters.unreadMessages) {
    request.unreadMessages =
      filters.unreadMessages.value === MessagesState.UNREAD;
  }

  return map(request);
};

export const exportUserSchoolTests = () => {
  // Запрос через отдельный клиент, чтобы избежать кэширования rtk-query
  return applicationsManagerClient
    .post(
      '/school-tests/export-students',
      {
        type: 'ALL',
        filters: {},
      },
      {
        responseType: 'blob',
      }
    )
    .then(res => {
      downloadFile(new File([res.data], 'users.zip'));
    });
};

export const importUserSchoolTests = (
  onSuccess?: () => void,
  onError?: () => void,
  userId?: number
) => {
  uploadFile(files => {
    const file = files[0];
    if (!file) return;
    const formData = new FormData();
    formData.append('file', file);
    applicationsManagerClient
      .post('/school-tests/parse', formData, {
        params: {
          userId,
        },
      })
      .then(onSuccess)
      .catch(onError);
  }, 'text/csv');
};

export const ModeratorStatementsFilter = ({
  control,
  formState: { errors },
  setValue,
}) => {
  const inputV2Props = createInputV2Props(errors);
  const {
    educationFormSelectProps,
    paymentFormSelectProps,
    facultiesFormSelectProps,
    countrySelectProps,
    applicationStatusSelectProps,
    expertStatusSelectProps,
    coordinatorStatusSelectProps,
    messagesStateSelectProps,
    recruitersSelectProps,
    educationLevelSelectProps,
    trajectorySelectProps,
    educationProgramsSelectProps,
    applicationAdmissionConditionSelectProps,
    moderatorSelectProps,
    serveMethodProps,
    applicationEpguStatusSelectProps,
    uploadStatusSelectProps,
  } = useApplicationFilterOptions();

  const isUserSupport = useAppSelector(selectIsUserSupport);

  return (
    <>
      <Controller
        name="userId"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="ID пользователя"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="lastName"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Фамилия"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Имя"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="middleName"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Отчество"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Email"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="snils"
        control={control}
        rules={{
          validate: value => {
            return value && value.length >= 14
              ? isValidSnils(normalizeSnils(value))
                ? true
                : terms.SNILS_INVALID
              : true;
          },
          onChange: ({ target: { value } }) =>
            value && setValue('snils', normalizeSnils(value)),
        }}
        render={({ field }) => (
          <InputV2
            placeholder="СНИЛС"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="directionPaymentFormId"
        control={control}
        render={({ field }) => (
          <ReactSelect
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={paymentFormSelectProps.options}
            placeholder="Основания поступления"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />

      <Controller
        name="directionEducationFormId"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            placeholder={terms.MODE_OF_STUDY}
            options={educationFormSelectProps.options}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />

      <Controller
        name="citizenshipIds"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            placeholder={terms.CITIZENSHIP}
            className="filter-form__field"
            {...(field as object)}
            options={countrySelectProps.options}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />

      {!isUserSupport && (
        <>
          <Controller
            name="applicationState"
            control={control}
            render={({ field }) => (
              <ReactSelect<SelectOption, true>
                isMulti
                styles={{
                  valueContainer: provided => ({
                    ...provided,
                    maxHeight: '30px',
                    overflowY: 'auto',
                  }),
                  menu: base => ({ ...base, minWidth: '300px' }),
                }}
                className="filter-form__field"
                {...(field as object)}
                placeholder="Статус заявления"
                options={applicationStatusSelectProps.options}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                allowSelectAll={true}
                components={{ Option, MultiValue }}
              />
            )}
          />

          <Controller
            name="applicationExpertStatus"
            control={control}
            render={({ field }) => (
              <ReactSelect<SelectOption, true>
                isMulti
                styles={{
                  valueContainer: provided => ({
                    ...provided,
                    maxHeight: '30px',
                    overflowY: 'auto',
                  }),
                  menu: base => ({ ...base, minWidth: '300px' }),
                }}
                className="filter-form__field"
                {...(field as object)}
                options={expertStatusSelectProps.options}
                placeholder="Статус эксперта"
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                allowSelectAll={true}
                components={{ Option, MultiValue }}
              />
            )}
          />
          <Controller
            name="applicationCoordinatorStatus"
            control={control}
            render={({ field }) => (
              <ReactSelect<SelectOption, true>
                isMulti
                styles={{
                  valueContainer: provided => ({
                    ...provided,
                    maxHeight: '30px',
                    overflowY: 'auto',
                  }),
                  menu: base => ({ ...base, minWidth: '300px' }),
                }}
                className="filter-form__field"
                {...(field as object)}
                options={coordinatorStatusSelectProps.options}
                placeholder="Статус координатора"
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                allowSelectAll={true}
                components={{ Option, MultiValue }}
              />
            )}
          />
        </>
      )}

      <Controller
        name="unreadMessages"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={messagesStateSelectProps.options}
            placeholder="Непрочитанные сообщения"
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            isClearable={true}
            // allowSelectAll={true}
            // components={{ Option, MultiValue }}
          />
        )}
      />

      <Controller
        name="directionName"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Направление"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="facultyIds"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={facultiesFormSelectProps.options}
            placeholder="Подразделение"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
            // TODO
          />
        )}
      />

      <Controller
        name="recruitedIds"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={recruitersSelectProps}
            placeholder="Партнер"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />

      <Controller
        name="applicationEducationLevel"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={educationLevelSelectProps.options}
            placeholder="Сценарий поступления"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />

      <Controller
        name="applicationTrajectory"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={trajectorySelectProps.options}
            placeholder="Приемная кампания"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />

      <Controller
        name="serveMethods"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            placeholder="Способ подачи"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option, MultiValue }}
            options={serveMethodProps.options}
          />
        )}
      />

      <Controller
        name="educationProgram"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={educationProgramsSelectProps.options}
            placeholder="Программа"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />

      <Controller
        name="creationDate"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Дата подачи заявки"
            type="date"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="moderatorIds"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={moderatorSelectProps.options}
            placeholder="Модератор"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />

      <Controller
        name="applicationAdmissionCondition"
        control={control}
        render={({ field }) => (
          <ReactSelect
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={applicationAdmissionConditionSelectProps.options}
            placeholder="Условия поступления"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />

      <Controller
        name="uploadStatus"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={uploadStatusSelectProps.options}
            placeholder="Состояние выгрузки в 1С"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />

      <Controller
        name="epguStatus"
        control={control}
        render={({ field }) => (
          <ReactSelect
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={applicationEpguStatusSelectProps.options}
            placeholder="Синхронизация с ЕПГУ"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />

      <Controller
        name="hasUndefinedFields"
        control={control}
        render={({ field }) => (
          <ReactSelect
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={[
              { label: 'Есть', value: true },
              { label: 'Нет', value: false },
            ]}
            placeholder="Ошибка при импорте"
          />
        )}
      />

      <Controller
        name="checklistFilters"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={[
              {
                value: ApplicationChecklistNames.EDUCATIONAL_INFORMATION,
                label: 'Сведения об образовании',
              },
              {
                value: ApplicationChecklistNames.PRIVILEGE,
                label: 'Отличительные признаки',
              },
              {
                value:
                  ApplicationChecklistNames.PRIVILEGE_DIRECTION_NOT_ADMITTED,
                label: 'Не подтверждена льгота',
              },
              {
                value: ApplicationChecklistNames.SCHOOL_TEST,
                label: 'Результаты ЕГЭ',
              },
              {
                value: ApplicationChecklistNames.ENTRANCE_TESTS_MISSING,
                label: 'Не сформирован набор ВИ',
              },
              {
                value: ApplicationChecklistNames.ENTRANCE_TEST_NOT_SATISFIED,
                label: 'Не подходящий набор ВИ',
              },
              {
                value: ApplicationChecklistNames.DIRECTION,
                label: 'Направления поступления',
              },
              {
                value: ApplicationChecklistNames.GOVERNMENT_CONTRACT,
                label: 'Целевой договор',
              },
              {
                value: ApplicationChecklistNames.GOVERNMENT_CONTRACT_MISSING,
                label: 'Отсутствует целевой договор',
              },
            ]}
            placeholder="Не подтверждено в чек-листе"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />

      <Controller
        name="originalDocumentSubmissionStatus"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption, true>
            isMulti
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={[
              {
                value: OriginalDocumentSubmissionStatus.PHYSICAL,
                label: 'Бумажный оригинал',
              },
              {
                value: OriginalDocumentSubmissionStatus.ELECTRONIC_COPY,
                label: 'Электронная копия',
              },
              {
                value: OriginalDocumentSubmissionStatus.SIGNED_PHYSICAL_COPY,
                label: 'Заверенная бумажная копия',
              },
              {
                value: OriginalDocumentSubmissionStatus.NO_DOCUMENT,
                label: 'Нет',
              },
            ]}
            placeholder="Наличие оригинала ДОО"
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />
    </>
  );
};

export const renderStatementsFilter = props => (
  <ModeratorStatementsFilter {...props} />
);

const ModeratorStatementsPage: FC = () => {
  const role = useAppSelector(selectUserRole);
  const { data: countries } = useGetCountriesQuery();
  const {
    moderatorSelectProps: { options: moderators },
    expertsSelectProps: { options: experts },
    coordinatorsSelectProps: { options: coordinators },
    recruitersSelectProps: recruiters,
  } = useApplicationFilterOptions();
  const [downloadAsCSV] = useLazyGetAllApplicationsAsCSVQuery();
  const dispatch = useAppDispatch();

  if (!countries) {
    return <Loader />;
  }

  const handleExportUserSchoolTests = () => {
    exportUserSchoolTests().catch(() => {
      dispatch(
        createToast(
          'Во время скачивания файлов проверки ЕГЭ произошла ошибка',
          'danger'
        )
      );
    });
  };

  const handleImportUserSchoolTests = () => {
    importUserSchoolTests(undefined, () => {
      dispatch(
        createToast(
          'Во время загрузки результатов ЕГЭ  произошла ошибка',
          'danger'
        )
      );
    });
  };

  return (
    <AdminTableListPage
      storeKey="applications"
      title="Заявления"
      query={{
        use: staffApplicationsApi.useGetApplicationsByStaffQuery,
        mapQueryResultToData: data =>
          data.map(application => ({
            ...application,
            id: application?.id,
            prettyUserId: application?.userIdWithPrefix,
            faculty: application.faculty?.titleShort,
            directionEducationForm: application.directionEducationForm?.title,
            nationality: countries.find(
              country => country.id === application?.citizenshipId
            )?.title,
            educationForm: application.directionEducationForm?.title,
            formPayment: application.directionPaymentForm?.title,
            moderator: moderators.find(m => m.value === application.moderatorId)
              ?.label,
            expert: experts.find(m => m.value === application.expertId)?.label,
            coordinator: coordinators.find(
              m => m.value === application.coordinatorId
            )?.label,
            recruiter: recruiters.find(m => m.value === application.recruiterId)
              ?.label,
            formattedApplicationId: application.formattedId,
            stateText: mapApplicationStateToText(
              application.state,
              application.flags
            ),
          })),
      }}
      displayCountLabel="Заявлений"
      columns={
        role === UserRole.EXPERT
          ? columnsExpertStatements
          : role === UserRole.COORDINATOR
          ? columnsCoordinatorStatements
          : columnsStatements
      }
      filtersConfig={{
        defaultValues: {
          userId: '',
          lastName: '',
          firstName: '',
          middleName: '',
          directionPaymentFormId: undefined,
          directionEducationFormId: undefined,
          citizenshipIds: undefined,
          applicationState: undefined,
          applicationExpertStatus: undefined,
          applicationCoordinatorStatus: undefined,
          unreadMessages: undefined,
          directionName: '',
          facultyIds: undefined,
          recruitedIds: undefined,
          applicationEducationLevel: undefined,
          applicationTrajectory: undefined,
          educationProgram: undefined,
          creationDate: undefined,
          moderatorIds: undefined,
          hasUndefinedFields: undefined,
        },
        renderFilters: renderStatementsFilter,
        mapFiltersToRequest: mapFiltersToRequest,
        onDownloadCsvButtonClick: ({ sorting, request }) =>
          downloadAsCSV({
            orderBy: sorting?.name,
            orderDirection: sorting?.type,
            request,
          })
            ?.unwrap()
            .then(result => {
              downloadFile(new File([result], 'Выборка.csv'));
            }),
        extraButtons:
          role !== UserRole.ADMIN
            ? []
            : [
                {
                  label: 'Скачать файл проверки ЕГЭ',
                  onClick: handleExportUserSchoolTests,
                },
                {
                  label: 'Загрузить результаты ЕГЭ',
                  onClick: handleImportUserSchoolTests,
                },
              ],
      }}
      sortingConfig={{
        options: [
          { label: 'по дате', value: 'CREATED_AT' },
          { label: 'по ФИО', value: 'NAME' },
          { label: 'по сообщениям', value: 'UNREAD_MESSAGES_COUNT' },
        ],
      }}
      shouldOpenInNewTab
    />
  );
};

export default ModeratorStatementsPage;
