import { ImpersonateResponse } from '../../../models/auth';
import { Staff, StaffPage } from '../../../models/staff/staff';
import { UserId, UserRole } from '../../../models/user';
import { apiSlice } from '../api-slice';

const baseUrl = `${process.env.REACT_APP_USER_MANAGER_API_URL}/data/staff`;

export const staffApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getStaffPageable: builder.query<
      StaffPage,
      {
        readonly pageNumber: number;
        readonly pageSize: number;
        readonly roles: UserRole[];
      }
    >({
      query: ({ pageNumber, pageSize, roles }) => ({
        url: `${baseUrl}`,
        params: {
          pageNumber,
          pageSize,
          role: roles.map(role => UserRole[role]),
        },
      }),
    }),

    impersonate: builder.mutation<
      ImpersonateResponse,
      {
        userId: number;
      }
    >({
      query: body => ({
        url: `${process.env.REACT_APP_USER_MANAGER_API_URL}/auth/impersonate`,
        method: 'POST',
        body,
      }),
    }),

    getStaffById: builder.query<Staff, { id: UserId }>({
      query: ({ id }) => ({
        url: `${baseUrl}/${id}`,
      }),
    }),
  }),
});

export const { useGetStaffPageableQuery, useLazyGetStaffByIdQuery } = staffApi;
