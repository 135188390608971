import React, { FC } from 'react';

import classNames from 'classnames';
import { useLocation, Outlet } from 'react-router-dom';

import ChatWidget from 'components/chat/ChatWidget';
import Navbar from 'components/common/ui-kit/Navbar';

type ViewerLayoutProps = {
  readonly isStaff?: boolean;
  readonly isImpersonate?: boolean;
};

const ViewerLayout: FC<ViewerLayoutProps> = ({ isStaff, isImpersonate }) => {
  const location = useLocation();
  const isNavbarShown = !location.pathname.includes('applicants');

  return (
    <div className="viewer-layout">
      {isNavbarShown && (
        <Navbar isStaff={isStaff} isImpersonate={isImpersonate} />
      )}
      <div
        className={classNames('viewer-layout__content', {
          'viewer-layout__content-statements':
            location.pathname.endsWith('statements'),
        })}
      >
        <Outlet />
      </div>
      <ChatWidget />
    </div>
  );
};

export default ViewerLayout;
