import useInitializeInputs from 'hooks/common/useInitializeInputs';

const getValuesFromConnectionsObject = (directory, labelName) =>
  directory
    ?.reduce((acc, item) => {
      return [...acc, String(item[labelName])];
    }, [])
    .join(',');

const useDirectoryFormInitialization = (setValue, directory) => {
  useInitializeInputs({
    data: directory.connections,
    omitKeys: [],
    onInit: (key, value) => {
      if (value.substring(value.lenght - 3) === 'Eng') {
        setValue(value, directory.nameEng);
        return;
      }
      if (value.substring(value.lenght - 2) === 'Ru') {
        setValue(value, directory.name);
        return;
      }
      setValue(value, directory[value]);
      value === 'typeEduDocToLevel' &&
        setValue(
          value,
          getValuesFromConnectionsObject(directory[value], 'levelEducationId')
        );
      value === 'preemptiveRightToPrDoc' &&
        setValue(
          value,
          getValuesFromConnectionsObject(
            directory[value],
            'preemptiveRightDocId'
          )
        );

      value === 'achivmentDocToAchivment' &&
        setValue(
          value,
          getValuesFromConnectionsObject(directory[value], 'achivmentId')
        );

      value === 'achivmentToTraningLevel' &&
        setValue(
          value,
          getValuesFromConnectionsObject(directory[value], 'scenarioId')
        );
      value === 'phone' &&
        setValue(
          value,
          getValuesFromConnectionsObject(directory[value], 'code')
        );
    },
  });
};

export default useDirectoryFormInitialization;
