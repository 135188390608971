import React from 'react';

import { ReactComponent as EditIcon } from 'assets/images/icons/edit.svg';
import { ReactComponent as FileIcon } from 'assets/images/icons/file.svg';
import Button from 'components/common/ui-kit/Button';
import useAppSelector from 'hooks/common/useAppSelector';
import useAdditionalFiles from 'hooks/document/useAdditionalFIles';
import terms from 'i18n';
import { UserRole } from 'models/user';
import { selectUserId, selectUserRole } from 'redux/selectors';
import {
  convertUtcDateToLocalDate,
  formatDateTimeToLocaleString,
} from 'utils/date';

import useAuthInitialization from '../../hooks/auth/useAuthInitialization';

const AdditionalFilesPage = () => {
  useAuthInitialization();

  const { onOpenModal, filePreviews, onDownload, onEditFile } =
    useAdditionalFiles();

  const userId = useAppSelector(selectUserId);
  const userRole = useAppSelector(selectUserRole);

  return (
    <section className="add-files-page">
      <div className="add-files-page__wrapper">
        <h1 className="add-files-page__title">{terms.ADDITIONAL_FILES}</h1>
        <div className="add-files-page__body">
          <Button
            theme="success"
            className="add-files__button"
            onClick={onOpenModal}
          >
            {terms.ADD_FILE}
          </Button>
          <ul className="add-files-page__items">
            {filePreviews.map(file => (
              <li
                key={file.id}
                className="add-files-page__item additional-file"
              >
                <div className="additional-file__icon-wrapper">
                  <button
                    className="additional-file__button"
                    onClick={() => onDownload(file.id, file.originalName)}
                  >
                    <FileIcon
                      width={46}
                      height={61}
                      className="additional-file__icon"
                    />
                  </button>
                  {file.userId === userId &&
                  (userRole === UserRole.ADMIN ||
                    userRole === UserRole.MODERATOR ||
                    userRole === UserRole.INSTITUTE_STAFF ||
                    userRole === UserRole.SPECIALIST) ? (
                    <button
                      className="additional-file__del-button"
                      onClick={() => {
                        onEditFile(
                          file.originalName,
                          file.id,
                          formatDateTimeToLocaleString(new Date())
                        );
                      }}
                    >
                      <EditIcon
                        width={23}
                        viewBox="-5 0 20 25"
                        height={20}
                        className="additional-file__cross"
                      />
                    </button>
                  ) : null}
                </div>
                <div className="additional-file__info">
                  <div className="additional-file__name">
                    <p>{file.originalName}</p>
                  </div>
                  <div className="additional-file__date">
                    <div>
                      {terms.CREATED}:{' '}
                      {formatDateTimeToLocaleString(
                        convertUtcDateToLocalDate(new Date(file.createdAt))
                      )}
                    </div>
                    {file.createdAt !== file.updatedAt && (
                      <div>
                        {terms.EDITED}:{' '}
                        {formatDateTimeToLocaleString(
                          convertUtcDateToLocalDate(new Date(file.updatedAt))
                        )}
                      </div>
                    )}
                    <span className="additional-file__user">
                      {terms.OWNER} - {file.uploaderId}
                    </span>

                    {/* FIXME restore once understand what is this */}
                    {/* {file.userId === profile?.id ? (*/}
                    {/*  <span className="additional-file__user">*/}
                    {/*    {profile?.usersTranslate?.surname || ' '}{' '}*/}
                    {/*    {profile?.usersTranslate?.name || ' '}{' '}*/}
                    {/*    {profile?.usersTranslate?.patronymic || ' '}*/}
                    {/*  </span>*/}
                    {/* ) : (*/}
                    {/*  <span className="additional-file__user">*/}
                    {/*    {file.uploaderTranslate?.surname || ' '}{' '}*/}
                    {/*    {file.uploaderTranslate?.name || ' '}{' '}*/}
                    {/*    {file.uploaderTranslate?.patronymic || ' '}*/}
                    {/*  </span>*/}
                    {/* )}*/}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AdditionalFilesPage;
