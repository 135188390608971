import React, { useEffect } from 'react';

import cn from 'classnames';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as AvatarIcon } from 'assets/images/icons/avatar.svg';
import { ReactComponent as LogoutIcon } from 'assets/images/icons/logout.svg';
import useAppSelector from 'hooks/common/useAppSelector';
import useOnLogout from 'hooks/common/useOnLogout';
import useWindowsSize from 'hooks/common/useWindowsSize';
import terms from 'i18n';
import {
  selectMobileMenu,
  selectUserRole,
  selectUserProfileImage,
  selectUser,
} from 'redux/selectors';

import { useLocalizedFullName } from '../../../../hooks/questionnaire/common/draft/useLocalizedFullName';
import { useEnrolleeUserId } from '../../../../hooks/questionnaire/common/draft/useQuestionnaire';
import { UserRole } from '../../../../models/user';

type NavbarRoute = {
  to: string;
  title: string;
  openInNewTab?: boolean;
};

const navbarUserRoutes: NavbarRoute[] = [
  { to: '/personal-information', title: terms.PERSONAL_INFORMATION },
  { to: '/application', title: terms.APPLICATION },
  { to: '/additional-files', title: terms.ADDITIONAL_FILES },
];

const navbarAdminRoutes: NavbarRoute[] = [
  { to: '/admin/statements', title: 'Заявления' },
  { to: '/admin/contracts', title: 'Договоры' },
  { to: '/admin/audit', title: 'Лог действий' },
  { to: '/admin/users', title: 'Все пользователи' },
  { to: '/admin/document-templates', title: 'Шаблоны документов' },
  { to: '/admin/upload-queue', title: 'Выгрузка данных' },
  { to: '/admin/registry', title: 'Ведомости' },
];

const navbarModeratorRoutes: NavbarRoute[] = [
  { to: '/admin/statements', title: 'Заявления' },
  { to: '/admin/contracts', title: 'Договоры' },
  { to: '/admin/upload-queue', title: 'Выгрузка данных' },
];

const navbarSpecialistRoutes: NavbarRoute[] = [
  { to: '/admin/statements', title: 'Заявления' },
  { to: '/admin/contracts', title: 'Договоры' },
  { to: '/admin/upload-queue', title: 'Выгрузка данных' },
];

const navbarSupportRoutes: NavbarRoute[] = [
  { to: '/admin/personal-information', title: 'Анкеты' },
  { to: '/admin/statements', title: 'Заявления' },
  { to: '/admin/users', title: 'Все пользователи' },
];

const navbarCallCenterRoutes: NavbarRoute[] = [
  { to: '/admin/statements', title: 'Заявления' },
];

const navbarExpertRoutes: NavbarRoute[] = [
  { to: '/admin/statements', title: 'Заявления' },
];

const navbarCoordinatorRoutes: NavbarRoute[] = [
  { to: '/admin/statements', title: 'Заявления' },
];

const navbarAnAuthRoutes: NavbarRoute[] = [];

const navbarOnQuestionnaireImpersonate: (
  questionnaireId: number
) => NavbarRoute[] = questionnaireId => [
  {
    to: `/admin/users/impersonate/${questionnaireId}/info/personal-information`,
    title: 'Анкеты',
  },
];

const navbarOnApplicationImpersonate: (
  questionnaireId: number,
  applicationId: number | string
) => NavbarRoute[] = (questionnaireId, applicationId) => [
  {
    to: `/admin/users/impersonate/${questionnaireId}/${applicationId}/info/personal-information`,
    title: 'Анкеты',
  },
  {
    to: `/admin/users/impersonate/${questionnaireId}/${applicationId}/statement`,
    title: 'Заявление',
  },
  {
    to: `/admin/users/impersonate/${questionnaireId}/${applicationId}/additional-files`,
    title: 'Дополнительные файлы',
  },
];

const Navbar = ({
  isStaff,
  isImpersonate,
}: {
  isStaff?: boolean;
  isImpersonate?: boolean;
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const showMobileMenu = useAppSelector(selectMobileMenu);
  const { isMobile } = useWindowsSize();
  const onLogout = useOnLogout();

  const {
    name,
    surname,
    isCorrect: isFullNameCorrect,
  } = useLocalizedFullName();

  const user = useAppSelector(selectUser);
  const role = useAppSelector(selectUserRole);
  const profileImage = useAppSelector(selectUserProfileImage);
  const questionnaireId = useEnrolleeUserId();
  const { applicationId } = useParams();

  useEffect(() => {
    if (
      (isStaff && pathname === '/admin') ||
      (isStaff && pathname === '/admin/')
    ) {
      if (role === UserRole.SUPPORT) {
        navigate('personal-information');
      } else {
        navigate('statements');
      }
    }
  }, [pathname]);

  const links = () => {
    if (isImpersonate && applicationId && questionnaireId) {
      return navbarOnApplicationImpersonate(questionnaireId, applicationId);
    }

    if (isImpersonate && questionnaireId)
      return navbarOnQuestionnaireImpersonate(questionnaireId);

    switch (role) {
      case UserRole.ADMIN:
        return navbarAdminRoutes;
      case UserRole.MODERATOR:
        return navbarModeratorRoutes;
      case UserRole.SUPPORT:
        return navbarSupportRoutes;
      case UserRole.EXPERT:
        return navbarExpertRoutes;
      case UserRole.COORDINATOR:
        return navbarCoordinatorRoutes;
      case UserRole.USER:
        return navbarUserRoutes;
      case UserRole.CALL_CENTER:
        return navbarCallCenterRoutes;
      case UserRole.INSTITUTE_STAFF:
        return navbarModeratorRoutes;
      case UserRole.SPECIALIST:
        return navbarSpecialistRoutes;
      default:
        return navbarAnAuthRoutes;
    }
  };

  return (
    <div className={cn('navbar', { 'navbar-active': showMobileMenu })}>
      <nav>
        {!isMobile ? (
          <ul className="navbar__items">
            {links().map(({ title, to, openInNewTab }) => (
              <li key={to} className="navbar__item">
                <NavLink
                  to={to}
                  target={openInNewTab ? '_blank' : undefined}
                  className={cn('navbar__link', {
                    active: pathname === to,
                  })}
                >
                  {title}
                </NavLink>
              </li>
            ))}
          </ul>
        ) : showMobileMenu ? (
          <ul className="navbar__items">
            <li className="navbar__item-avatar">
              <div className="navbar__item-content">
                {profileImage ? (
                  <img
                    src={profileImage}
                    alt={terms.PHOTO}
                    className="user-menu__icon"
                  />
                ) : (
                  <AvatarIcon width="40" height="40" />
                )}
                <div className="user-menu__info">
                  <div className="user-menu__name">
                    {isFullNameCorrect ? `${surname} ${name}` : ''}
                  </div>
                  <div>
                    {terms.YOUR_ID_IN_SYSTEM}: {user.idWithPrefix}
                  </div>
                </div>
              </div>
              <div>
                <button className="user-menu__logout" onClick={onLogout}>
                  <LogoutIcon width="14" height="12" />
                </button>
              </div>
            </li>
            {links().map(({ title, to, openInNewTab }) => (
              <li key={to} className="navbar__item">
                <NavLink
                  to={to}
                  target={openInNewTab ? '_blank' : undefined}
                  className="navbar__link"
                >
                  {title}
                </NavLink>
              </li>
            ))}
          </ul>
        ) : null}
      </nav>
    </div>
  );
};

export default Navbar;
