import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutesContext } from 'types/custom';

import Button from 'components/common/ui-kit/Button';
import Sidenav from 'components/common/ui-kit/Sidenav';
import { TABLET_SCREEN_WIDTH } from 'config/constants';
import useAppSelector from 'hooks/common/useAppSelector';
import useWindowsSize from 'hooks/common/useWindowsSize';
import {
  Invalidateable,
  useRefetchQuestionnaire,
} from 'hooks/questionnaire/common/draft/useQuestionnaire';
import terms from 'i18n';
import {
  ApplicationByStaff,
  ApplicationView,
} from 'models/applications/applications';
import { formatDateTimeToRuFormat } from 'utils/date';

import { swapLocalStorageCredentials } from '../../components/common/containers/WithImpersonate';
import Breadcrumbs from '../../components/common/ui-kit/Breadcrumbs';
import { useSidenav } from '../../hooks/common/useSidenav';
import { UserRole } from '../../models/user';
import { enrolleesApi } from '../../redux/api/questionnaire/enrollees-api';
import {
  selectApplication,
  selectIsApplicationSubmittable,
} from '../../redux/selectors/enrollee/application';
import { selectIsDraftReadonly } from '../../redux/selectors/enrollee/questionnaire';
import { toastSlice } from '../../redux/slices/common/toast-slice';

type PageWithSidenavProps<T> = React.PropsWithChildren<{
  next?: boolean;
  title: string;
  useHooks: () => RoutesContext<T>;
  useSubmitFormHook: () => { onSubmitForm: () => void };
}>;

export const getOriginalStateChangedAtString = (
  application?: ApplicationByStaff | ApplicationView | null
) => {
  return application?.originalStateChangedAt
    ? `Оригинал документа об образовании ${
        application.hasOriginalDocuments ? 'подан' : 'отозван'
      }:
${formatDateTimeToRuFormat(application.originalStateChangedAt)}
`
    : null;
};

const SidenavPage = <Key extends string>({
  next = true,
  children,
  title,
  useHooks,
  useSubmitFormHook,
}: PageWithSidenavProps<Key>) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { role, isBackArrowVisible, getLinkToNavigateOnBackClick } = useSidenav(
    {
      title,
    }
  );

  const { isMobile, windowSize } = useWindowsSize();
  const isTabletScreen = windowSize.innerWidth <= TABLET_SCREEN_WIDTH;

  const { onSubmitForm } = useSubmitFormHook();

  const { routes, visitedRoutes, unvisitedRoutes, lastBtn } = useHooks();
  const [dis, setDis] = useState(false);

  const isApplicationSubmittable = useAppSelector(
    selectIsApplicationSubmittable
  );

  useEffect(() => {
    if (routes.find(el => el.to === 'blank') && lastBtn !== '') {
      setDis(!isApplicationSubmittable);
    } else {
      setDis(false);
    }
  }, [isApplicationSubmittable, routes, lastBtn]);

  const questionnaireId = useAppSelector(
    state => state.questionnaire.questionnaireId
  );

  const supportQuestionnaireId = useAppSelector(
    state => state?.supportQuestionnaires?.currentQuestionnaire?.user?.id
  );

  const readOnly = useAppSelector(selectIsDraftReadonly);

  const [requestCanBeDeleted] = enrolleesApi.useLazyCanBeDeletedQuery();
  const [deleteEnrollee] = enrolleesApi.useLazyDeleteEnrolleeQuery();

  const { pathname } = useLocation();

  const currentApplication = useAppSelector(selectApplication);
  const refetchQuestionnaire = useRefetchQuestionnaire();

  const onEditClick = event => {
    if (!questionnaireId) return;
    event.preventDefault();

    if (role === UserRole.SUPPORT && supportQuestionnaireId) {
      navigate(`/admin/users/impersonate/${supportQuestionnaireId}`);
    } else {
      if (
        role === UserRole.ADMIN ||
        role === UserRole.MODERATOR ||
        role === UserRole.INSTITUTE_STAFF ||
        role === UserRole.SPECIALIST
      ) {
        swapLocalStorageCredentials();
      }

      requestCanBeDeleted(questionnaireId)
        ?.unwrap()
        .then(canBeDeleted => {
          if (canBeDeleted) {
            deleteEnrollee(questionnaireId)
              ?.unwrap()
              .then(() =>
                refetchQuestionnaire(true, Invalidateable.QUESTIONNAIRE)
              );
          } else {
            dispatch(
              toastSlice.actions.createToast(
                terms.CANNOT_EDIT_IF_APPLICATIONS_HAS,
                'danger'
              )
            );
          }
        })
        .finally(() => {
          if (
            role === UserRole.ADMIN ||
            role === UserRole.MODERATOR ||
            role === UserRole.INSTITUTE_STAFF ||
            role === UserRole.SPECIALIST
          ) {
            swapLocalStorageCredentials();
          }
        });
    }
  };

  return (
    <div className="sidenav-page-wrapper">
      <div className="sidenav-page">
        {isBackArrowVisible ? (
          <Breadcrumbs
            link={getLinkToNavigateOnBackClick()}
            className="moderator-statements-id-page__header-title"
            title="Анкета"
          />
        ) : (
          <h1 className="sidenav-page__title">{title}</h1>
        )}
        {isMobile ? (
          <div className="sidenav-page__content">
            <div className="sidenav-page__nav-container">
              <Sidenav
                routes={routes}
                className="sidenav-page__nav"
                mobileMenu={{
                  routeContent: children,
                  onSubmitForm,
                  isMobile,
                  dis,
                  lastBtn,
                  next,
                  readonly: readOnly,
                  onEditClick,
                  currentApplication,
                }}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="sidenav-page__content">
              <div className="sidenav-page__nav-container">
                <Sidenav
                  routes={isTabletScreen ? visitedRoutes : routes}
                  className="sidenav-page__nav"
                />
              </div>
              <div
                className={classNames('sidenav-page__inner', {
                  'sidenav-page__inner--first-selected':
                    pathname.includes('basic-data'),
                })}
              >
                {children}
              </div>
              {isTabletScreen && (
                <div className="sidenav-page__nav-container">
                  <Sidenav
                    routes={unvisitedRoutes}
                    className="sidenav-page__nav"
                  />
                </div>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              {/* <div style={{ display: 'flex' }}>Подан</div> */}
              {pathname.includes('personal-information') &&
                (readOnly ||
                  (role === UserRole.SUPPORT && supportQuestionnaireId)) && (
                  <Button
                    theme="success"
                    className="sidenav-page__button"
                    onClick={onEditClick}
                  >
                    {terms.EDIT}
                  </Button>
                )}

              {pathname.includes('personal-information') && next && !readOnly && (
                <Button
                  theme="success"
                  className="sidenav-page__button"
                  onClick={() => onSubmitForm()}
                  disabled={dis}
                >
                  {lastBtn ? lastBtn : terms.NEXT}
                </Button>
              )}

              {!pathname.includes('personal-information') && next && (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      marginBottom: 10,
                    }}
                  >
                    <Button
                      theme="success"
                      className="sidenav-page__button"
                      onClick={() => onSubmitForm()}
                      disabled={dis}
                    >
                      {lastBtn ? lastBtn : terms.NEXT}
                    </Button>
                  </div>
                  {getOriginalStateChangedAtString(currentApplication)}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SidenavPage;
