import React, { FC } from 'react';

import terms from 'i18n';

import { ContractLegalEntityPayerData } from '../../../models/applications/contracts';

type ModeratorContractIdInfoEntrants = {
  contractJuridical?: ContractLegalEntityPayerData;
};

const ModeratorContractIdInfoEntrants: FC<ModeratorContractIdInfoEntrants> = ({
  contractJuridical,
}) => (
  <div className="moderator-contract-info__inline">
    <div className="moderator-contract-info__block">
      <h2>{terms.PAYER_INFORMATION}:</h2>
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.ORGANISATION_FULL_NAME}:</p>
        <span>{contractJuridical?.organizationName || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>Короткое наименование:</p>
        <span>{contractJuridical?.organizationShortName || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.ORGANISATION_GENERAL_DIRECTOR}:</p>
        <span>{contractJuridical?.generalManager || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.ADDRESS}:</p>
        <span>{contractJuridical?.address || '-'}</span>
      </div>
      {/* <div className="moderator-contract-info__block-row juridical">
        <p>{terms.OGRN}:</p>
        <span>{contractJuridical?.ogrn || '-'}</span>
      </div>*/}
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.INN}:</p>
        <span>{contractJuridical?.inn || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.KPP}:</p>
        <span>{contractJuridical?.kpp || '-'}</span>
      </div>
      {/* <div className="moderator-contract-info__block-row juridical">
        <p>Фактический адрес:</p>
        <span>{contractJuridical.actualAddress || '-'}</span>
      </div>*/}
    </div>
    <div className="moderator-contract-info__block">
      <br />
      <br />
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.MANAGER_BASES}:</p>
        <span>{contractJuridical?.managementBases || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.BANK_NAME}:</p>
        <span>{contractJuridical?.bankName || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.BANK_BIC}:</p>
        <span>{contractJuridical?.bankBic || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.PAYMENT_ACC}:</p>
        <span>{contractJuridical?.paymentAccount || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.CORRESPONDENT_ACC}:</p>
        <span>{contractJuridical?.correspondentAccount || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.CONTACT_NUMBER}:</p>
        <span>{contractJuridical?.phone || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>{terms.EMAIL}:</p>
        <span>{contractJuridical?.email || '-'}</span>
      </div>
    </div>
  </div>
);

export default ModeratorContractIdInfoEntrants;
