import { UserRole } from '../../../models/user';
import { useGetStaffPageableQuery } from '../../../redux/api/staff/staff-api';
import { selectUserRole } from '../../../redux/selectors';
import useAppSelector from '../../common/useAppSelector';

export const useGetExpertSelectProps = () => {
  const role = useAppSelector(selectUserRole);

  const { data: experts } = useGetStaffPageableQuery(
    {
      pageSize: 1000,
      pageNumber: 1,
      roles: [UserRole.EXPERT],
    },
    {
      skip: !role || role === UserRole.COORDINATOR,
    }
  );

  return (experts?.content ?? []).map(expert => ({
    label: `${expert.name} ${expert.surname} ${expert.patronymic ?? ''}`,
    value: expert.userId,
  }));
};
