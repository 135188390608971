import React, { FC, MouseEventHandler } from 'react';

import classnames from 'classnames';

type TrajectoryElementProps = {
  name: string;
  content: string;
  active: boolean;
  setTrajectory: MouseEventHandler<HTMLDivElement>;
};

const TrajectoryElement: FC<TrajectoryElementProps> = ({
  name,
  content,
  active,
  setTrajectory,
}) => {
  const normArr: string[] = content?.split('\n') || [];

  return (
    <div
      className={classnames('trajectory-list__trajectory-element', { active })}
      onClick={setTrajectory}
    >
      <h2>{name}</h2>
      <ul>
        {normArr?.map(
          (el, index) =>
            el.length !== 0 && (
              <li
                key={index}
                className={
                  el[0] === '1' ||
                  el[0] === '2' ||
                  el[0] === '3' ||
                  el[0] === '4'
                    ? 'number'
                    : ''
                }
              >
                {el.slice(-6) === 'здесь.' ? (
                  <>
                    <p>
                      {el.slice(0, -6)}{' '}
                      <a
                        href={process.env.REACT_APP_RU_FOREIGN_ADMISSIONS}
                        className="link"
                        target="_blank"
                      >
                        {el.slice(-6)}
                      </a>
                    </p>
                  </>
                ) : el.slice(-5) === 'here.' ? (
                  <>
                    <p>
                      {el.slice(0, -5)}{' '}
                      <a
                        href={process.env.REACT_APP_EN_FOREIGN_ADMISSIONS}
                        className="link"
                        target="_blank"
                      >
                        {el.slice(-5)}
                      </a>
                    </p>
                  </>
                ) : (
                  <p>{el}</p>
                )}
              </li>
            )
        )}
      </ul>
    </div>
  );
};

export default TrajectoryElement;
