import { useNavigate } from 'react-router-dom';

const useCreatePageHeader = () => {
  const navigate = useNavigate();

  const onSubmit = () => {
    navigate('../trajectory');
  };

  return { onSubmit };
};

export default useCreatePageHeader;
