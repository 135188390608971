import React, { FC, useState } from 'react';

import { Controller } from 'react-hook-form';
import { NavLink } from 'react-router-dom';

import Button from 'components/common/ui-kit/Button';
import Loader from 'components/common/ui-kit/Loader';
import ReactSelect from 'components/common/ui-kit/Select';
import ModeratorFile from 'components/moderator/ModeratorFile';
import EntrantsContractForm from 'components/statements/CreateContractForm/EntrantsContractForm';
import JuridicalContractForm from 'components/statements/CreateContractForm/JuridicalContractForm';
import NotEntrantsContractForm from 'components/statements/CreateContractForm/NotEntrantsContractForm';
import useAppSelector from 'hooks/common/useAppSelector';
import useModeratorContractIdInfoForm from 'hooks/moderator/useModeratorContractIdInfoForm';
import { getTranslationOrEmptyString } from 'i18n';
import { SelectOption } from 'models/common';
import { contractApi } from 'redux/api/applications/contract-api';
import { formatDateTimeToLocaleString } from 'utils/date';

import { useDownloadFile } from '../../../hooks/common/api/useDownloadFile';
import {
  ContractState,
  ContractType,
} from '../../../models/applications/contracts';
import { selectCurrentStaffContract } from '../../../redux/selectors/staff/contracts';
import { buildContractNameFromDirectionProperties } from '../../../utils/domain/direction';
import { savePreviousLocation } from '../../../utils/domain/impersonate';
import ModeratorContractIdInfoEntrants from '../ModeratorContractIdInfoEntrants';
import ModeratorContractIdInfoJuridical from '../ModeratorContractIdInfoJuridical';
import ModeratorContractIdInfoNotEntrants from '../ModeratorContractIdInfoNotEntrants';

const ModeratorContractIdInfo: FC = () => {
  const {
    control,
    reject,
    approve,
    downloadPrintContract,
    downloadTemplate,
    takeToWork,
    sendForEditing,
    confirm,
    cancel,
  } = useModeratorContractIdInfoForm();

  const currentStaffContract = useAppSelector(selectCurrentStaffContract);
  const downloadFile = useDownloadFile();
  const [isEditing, setEditing] = useState(false);
  const [getContract] = contractApi.useLazyGetByIdQuery();

  if (!currentStaffContract) {
    return <Loader />;
  }

  const questionnaire = currentStaffContract.questionnaire;
  const contract = currentStaffContract.contract;
  const user = currentStaffContract.user;
  const onImpersonateClick = () => {
    savePreviousLocation();
  };

  if (isEditing) {
    return contract.contractType === ContractType.SELF_PAYMENT ? (
      <EntrantsContractForm
        contract={contract}
        isFillingStatementsForm={false}
        onSubmit={() => {
          getContract(contract.id)
            .unwrap()
            .then(() => setEditing(false));
        }}
      />
    ) : contract.contractType === ContractType.JURIDICAL ? (
      <JuridicalContractForm
        contract={contract}
        isFillingStatementsForm={false}
        onSubmit={() => {
          getContract(contract.id)
            .unwrap()
            .then(() => setEditing(false));
        }}
      />
    ) : (
      <NotEntrantsContractForm
        contract={contract}
        isFillingStatementsForm={false}
        onSubmit={() => {
          getContract(contract.id)
            .unwrap()
            .then(() => setEditing(false));
        }}
      />
    );
  }

  return (
    <div className="moderator-contract-info">
      <div className="moderator-contract-info__block">
        <h2>
          <span>
            {`${questionnaire.generalDataForm.surname || ''} ${
              questionnaire.generalDataForm.name
            } ${questionnaire.generalDataForm.patronymic ?? ''}`}
          </span>
        </h2>
        <p>
          ID: <span>{user.idWithPrefix}</span>
        </p>
        <p>
          Вид образования:{' '}
          <span>
            {getTranslationOrEmptyString(
              questionnaire.educationalInformationForm[0].educationLevel
            )}
          </span>
        </p>
      </div>
      <div className="moderator-contract-info__block">
        <h2>
          Направление:{' '}
          <span>
            {buildContractNameFromDirectionProperties({
              title: contract.directionTitle,
              titleForeign: contract.directionTitleForeign,
              educationProgramTitle: contract.educationProgramTitle,
              educationProgramTitleForeign:
                contract.educationProgramTitleForeign,
              educationForm: contract.directionEducationForm,
            })}
          </span>
        </h2>
        <p>
          Подразделение: <span></span>
        </p>
        <p>
          Статус договора:{' '}
          <span>{getTranslationOrEmptyString(contract.state)}</span>
        </p>
        <p>
          Тип платежа: <span>{contract.paymentType.title}</span>
        </p>
        <p>
          Плательщик:{' '}
          <span>{getTranslationOrEmptyString(contract.contractType)}</span>
        </p>
      </div>

      {contract.contractType === ContractType.SELF_PAYMENT ? (
        <ModeratorContractIdInfoEntrants questionnaire={questionnaire} />
      ) : contract.contractType === ContractType.THIRD_PARTY ? (
        <ModeratorContractIdInfoNotEntrants
          contractNotEntrants={contract?.thirdPartyPayerData}
        />
      ) : contract.contractType === ContractType.JURIDICAL ? (
        <ModeratorContractIdInfoJuridical
          contractJuridical={contract?.legalEntityPayerData}
        />
      ) : (
        <></>
      )}

      <div className="moderator-contract-info__block">
        <h2>Печатные формы:</h2>
        <div className="moderator-contract-info__block_file-container">
          <ModeratorFile
            title="Договор"
            upload={() => downloadPrintContract(contract)}
          />
          <ModeratorFile
            title="Незаполненный договор"
            upload={() => downloadTemplate(contract)}
          />
          {/* <ModeratorFile title="Квитанция на оплату" upload={() => {}} />*/}
        </div>
      </div>
      {contract.contractType !== ContractType.JURIDICAL && (
        <div className="moderator-contract-info__block">
          <h2>Сканированные документы:</h2>
          <div className="moderator-contract-info__block_file-container">
            <ModeratorFile
              title="Паспорт плательщика"
              upload={() => {
                if (!questionnaire.passportForm.passportCopyUploadId) return;

                downloadFile({
                  userId: user.id,
                  fileId:
                    contract.contractType === ContractType.THIRD_PARTY
                      ? contract.thirdPartyPayerData!.documentScanId
                      : questionnaire.passportForm.passportCopyUploadId,
                  fileName: 'Паспорт.pdf',
                });
              }}
            />
          </div>
        </div>
      )}

      {!isEditing && (
        <>
          <Controller
            name="moderator"
            control={control}
            render={({ field }) => (
              <ReactSelect<SelectOption>
                options={[]}
                placeholder="Модератор по доверенности"
                {...(field as object)}
                className="moderator-contract-info__select"
              />
            )}
          />
          {(contract.uploadDate || contract.uploadError) && (
            <div className="moderator-contract-info__block">
              {contract.uploadDate && (
                <p>
                  Выгрузка в 1С:{' '}
                  <span>
                    {formatDateTimeToLocaleString(contract.uploadDate)}
                  </span>
                </p>
              )}
              {contract.uploadError && (
                <p>
                  Ошибка загрузки в 1С: <span>{contract.uploadError}</span>
                </p>
              )}
            </div>
          )}
          <div className="moderator-contract-info__footer">
            <div className="moderator-contract-info__footer-btn">
              <NavLink
                to={`/admin/users/impersonate/${user.id}/${contract.applicationId}`}
                target="_blank"
                onClick={onImpersonateClick}
              >
                <Button type="button">
                  Зайти в личный кабинет пользователя
                </Button>
              </NavLink>
            </div>

            <div className="moderator-contract-info__management-btns">
              {contract.state === ContractState.PROJECT && (
                <Button onClick={() => takeToWork(contract.id)}>
                  Взять в работу
                </Button>
              )}

              {(contract.state === ContractState.UNDER_CONSIDERATION ||
                contract.state === ContractState.EDITED) && (
                <Button onClick={() => approve(contract.id)} theme="success">
                  Одобрить
                </Button>
              )}

              {(contract.state === ContractState.UNDER_CONSIDERATION ||
                contract.state === ContractState.EDITED ||
                contract.state === ContractState.EDITING) && (
                <Button
                  onClick={() => {
                    if (contract.state === ContractState.EDITING) {
                      setEditing(true);
                      return;
                    }
                    sendForEditing(contract.id).then(() => setEditing(true));
                  }}
                >
                  Редактировать
                </Button>
              )}

              {contract.state === ContractState.APPROVED && (
                <Button onClick={() => confirm(contract.id)} theme="success">
                  Утвердить
                </Button>
              )}

              {(contract.state === ContractState.APPROVED ||
                contract.state === ContractState.CONFIRMED) && (
                <Button onClick={() => cancel(contract.id)}>
                  Аннулировать
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ModeratorContractIdInfo;
