import React, { FC, PropsWithChildren } from 'react';

import ReactSelect, { components } from 'react-select';
import { OptionProps } from 'react-select/dist/declarations/src/components/Option';

import { ReactComponent as TabArrowDown } from 'assets/images/icons/tab-arrow-down.svg';
import Button from 'components/common/ui-kit/Button';

type OptionValue = {
  label: string;
  value: number;
};

const options: OptionValue[] = [
  {
    label: '10',
    value: 0,
  },
  {
    label: '25',
    value: 1,
  },
  {
    label: '50',
    value: 1,
  },
  {
    label: '75',
    value: 1,
  },
  {
    label: '100',
    value: 1,
  },
];

const Option: FC<PropsWithChildren<OptionProps>> = ({ children, ...rest }) => (
  <components.Option {...rest}>{children}</components.Option>
);

const range = (from: number, to: number): number[] => {
  const data: number[] = [];

  for (let i = from; i <= to; i++) {
    data.push(i);
  }

  return data;
};

const fetchPageNumbers = (lastPage: number, currentPage: number): number[] => {
  const pageNeighbours = 1;

  const totalNumbers = pageNeighbours * 2 + 3;
  const totalBtn = totalNumbers + 2;

  if (lastPage > totalBtn) {
    const startPage = Math.max(2, currentPage - pageNeighbours);
    const endPage = Math.min(lastPage - 1, currentPage + pageNeighbours);
    let pages = range(startPage, endPage);

    const hasLeftSpill = startPage > 2;
    const hasRightSpill = lastPage - endPage > 1;
    const spillOffset = totalNumbers - (pages.length + 1);

    switch (true) {
      case hasLeftSpill && !hasRightSpill: {
        const extraPages = range(startPage - spillOffset, startPage - 1);
        pages = [-1, ...extraPages, ...pages];
        break;
      }

      case !hasLeftSpill && hasRightSpill: {
        const extraPages = range(endPage + 1, endPage + spillOffset);
        pages = [...pages, ...extraPages, -1];
        break;
      }

      case hasLeftSpill && hasRightSpill:
      default: {
        pages = [-1, ...pages, -1];
        break;
      }
    }

    return [1, ...pages, lastPage];
  }

  return range(1, lastPage);
};

export type PaginationProps = {
  lastPage: number;
  currentPage: number;
  perPage: number;
  onPageChange: (value: number) => void;
  onPerPageChange: (value: number) => void;
};

const Pagination: FC<PaginationProps> = ({
  lastPage,
  currentPage,
  perPage,
  onPageChange,
  onPerPageChange,
}) => {
  const pages = fetchPageNumbers(lastPage, currentPage);

  return (
    <div className="pagination">
      <div className="pagination__btn-count">
        <p>Отображать на странице:</p>
        <div className="lang-changer">
          <ReactSelect
            defaultValue={
              options.find(x => x.label === String(perPage)) || {
                label: perPage,
                value: -1,
              }
            }
            options={options}
            classNamePrefix="lang-changer"
            onChange={option => {
              onPerPageChange(Number((option as any | undefined)?.label || 0));
              onPageChange(1);
            }}
            components={{ Option }}
          />
        </div>
      </div>
      <div className="pagination__btn-panel">
        <Button
          className="pagination__btn-panel-first"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <TabArrowDown />
        </Button>
        {pages.map((page, index) => {
          if (page === -1) {
            return (
              <Button key={index} disabled>
                ...
              </Button>
            );
          }
          return (
            <Button
              key={index}
              theme={currentPage === page ? 'success' : undefined}
              onClick={() => onPageChange(page)}
            >
              {page}
            </Button>
          );
        })}
        <Button
          className="pagination__btn-panel-last"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === lastPage}
        >
          <TabArrowDown />
        </Button>
      </div>
    </div>
  );
};
export default Pagination;
