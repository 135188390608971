import { EntrantTestType } from 'models/students';

import { IdentificationDocumentType } from '../enum';
import { ExternalEntityNamedResponse } from './applications';

export type OldDocumentRequest = {
  readonly documentType: IdentificationDocumentType;
  readonly oldDocumentSerial?: string;
  readonly oldDocumentNumber: string;
  readonly oldSecondName: string;
  readonly oldFirstName: string;
  readonly oldMiddleName?: string;
};

export type OldDocumentResponse = {
  documentType: IdentificationDocumentType;
  oldDocumentSerial?: string;
  oldDocumentNumber: string;
  oldSecondName: string;
  oldFirstName: string;
  oldMiddleName?: string;
};

export type SubjectReplacementResponse = {
  replacement: number;
  direction: number;
};

export type SubjectMinScore = {
  directionId: number;
  directionExternalId: string;
  directionTitle: string;
  testType: EntrantTestType;
  testTypeExternalId: string;
  testTypeTitle: string;
  minScore: number;
};

export type SubjectResponse = {
  isSchoolTest: boolean;
  replacements: SubjectReplacementResponse[];
  minScores: SubjectMinScore[];
} & ExternalEntityNamedResponse;

type SubjectReplacement = {
  replacement: number;
  direction: number;
};

export enum SchoolTestType {
  SECONDARY = 'SECONDARY',
  UPPER_SECONDARY = 'UPPER_SECONDARY',
}

export type SchoolTest = {
  readonly id: number;
  readonly applicationId: number;
  readonly subject: SubjectResponse;
  readonly year: number;
  readonly score: number;
  readonly testType: SchoolTestType;
  readonly centralTestingDocumentId: number;
};

export type CreateSchoolTestRequest = {
  readonly applicationId: number;
  readonly subjectId: number;
  readonly year: number;
  readonly score?: number;
  readonly testType: SchoolTestType;
  readonly centralTestingDocumentId: number;
  oldDocumentRequest?: OldDocumentRequest;
};

export type UpdateSchoolTestRequest = {
  readonly id: number;
  readonly year: number;
  readonly score?: number;
  readonly centralTestingDocumentId: number;
  readonly subjectId: number;
  oldDocumentRequest?: OldDocumentRequest;
};

export type SchoolTestDocument = {
  readonly id: number;
  readonly documentType: IdentificationDocumentType;
  readonly oldDocumentSerial?: string;
  readonly oldDocumentNumber: string;
  readonly oldSecondName: string;
  readonly oldFirstName: string;
  readonly oldMiddleName?: string;
  readonly applicationId: number;
};

export type UserSchoolTestResponse = {
  id: number;
  userInfoId: number;
  subject: SubjectResponse;
  year: number;
  score?: number;
  testType: SchoolTestType;
  centralTestingDocumentId?: number;
  oldDocumentResponse?: OldDocumentResponse;
  confirmed: boolean;
};

export type SchoolTestExportRequest = {
  type: string;
  filters: SchoolTestExportRequestFilter;
};

export type SchoolTestExportRequestFilter = {
  subjectTitle?: string;
  lastName?: string;
  firstName?: string;
  middleName?: string;
  email?: string;
};
