import React, { FC } from 'react';

import { Controller } from 'react-hook-form';
import { NavLink, Outlet } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';

import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg';
import Breadcrumbs from 'components/common/ui-kit/Breadcrumbs';
import Button from 'components/common/ui-kit/Button';
import ReactSelect from 'components/common/ui-kit/Select';
import useSubmitFormContext, {
  SubmitFormContextProvider,
} from 'hooks/common/useSubmitFormContext';
import useModeratorStatementsRoutesContext, {
  ModeratorStatementsRoutesContextProvider,
} from 'hooks/moderator/useModeratorStatementsRoutesContext';
import terms from 'i18n';
import { SelectOption } from 'models/common';
import SidenavPage from 'pages/SidenavPage';
import { formatDateTimeToRuFormat } from 'utils/date';
import {
  findSelectOption,
  originalEducationDocumentSubmissionTypeOptions,
} from 'utils/enum-select';

import useAppDispatch from '../../../hooks/common/useAppDispatch';
import useAppSelector from '../../../hooks/common/useAppSelector';
import { isApplicationHasStatusWorkOrHigher } from '../../../hooks/moderator/applications/applicationEnumChecks';
import { useGetModeratorSelectProps } from '../../../hooks/moderator/applications/useGetModeratorSelectProps';
import { useInitializeStaffApplication } from '../../../hooks/moderator/applications/useInitializeStaffApplication';
import { useModeratorApplication } from '../../../hooks/moderator/applications/useModeratorApplication';
import {
  ApplicationFlag,
  ApplicationState,
} from '../../../models/applications/enum';
import { UserRole } from '../../../models/user';
import { showModal } from '../../../redux/actions';
import { selectCurrentApplication } from '../../../redux/selectors/staff/applications';

const originalDocumentTypeSelectOptions = [
  { label: terms.NO, value: null },
  ...originalEducationDocumentSubmissionTypeOptions,
];

const applicationStateSelectOptions = [
  { label: terms[ApplicationState.ACCEPTED], value: ApplicationState.ACCEPTED },
  { label: terms[ApplicationState.ALLOWED], value: ApplicationState.ALLOWED },
  {
    label: terms[ApplicationState.WITHDRAWN_APPROVED],
    value: ApplicationState.WITHDRAWN_APPROVED,
  },
];

const ModeratorStatementsIdPage: FC = () => {
  useInitializeStaffApplication();
  const dispatch = useAppDispatch();

  const moderators = useGetModeratorSelectProps();

  const application = useAppSelector(selectCurrentApplication);

  const {
    role,
    state,
    control,
    expertState,
    coordinatorState,
    takeToWork,
    sendToExpert,
    sendToCoordinator,
    changeModerator,
    sendToEditing,
    rejectApplication,
    rejectApplicationEditing,
    acceptApplication,
    allowApplication,
    fields,
    approveByCoordinator,
    declineWithdraw,
    rejectByCoordinator,
    acceptWithdraw,
    withdrawByAdmin,
    rejectByExpert,
    approveByExpert,
    canSendToExpert,
    deleteApplication,
    takeToWorkByExpert,
    takeToWorkByCoordinator,
    expertId,
    coordinatorId,
    navLink,
    stateTitle,
    sendToQueue,
    clearError,
    selectedEducationDocumentType,
    handleOriginalEducationDocumentTypeChange,
    originalSubmission,
    handleApplicationStateChangeChange,
  } = useModeratorApplication(moderators);

  const openModal = () => {
    dispatch(
      showModal({
        name: 'CONFIRM_WITHDRAWN_APPLICATION',
        data: {
          text: terms.CONFIRM_WITHDRAWN_APPLICATION,
          applicationId: application?.id,
          func: withdrawByAdmin,
        },
      })
    );
  };

  return (
    <div className="moderator-statements-id-page">
      <div className="moderator-statements-id-page__header">
        <Breadcrumbs
          link="/admin/statements"
          className="moderator-statements-id-page__header-title"
          title="Проверка заявления"
        />
        {role === UserRole.ADMIN &&
          (isApplicationHasStatusWorkOrHigher(state) ? (
            <Controller
              name={fields.moderator.name}
              control={control}
              render={({ field }) => (
                <ReactSelect<SelectOption>
                  className="moderator-statements-id-page__header-select"
                  {...(field as object)}
                  options={moderators}
                  onChange={e => {
                    if (e) {
                      field.onChange(e);
                      changeModerator(e.value);
                    }
                  }}
                  placeholder="Модератор"
                />
              )}
            />
          ) : (
            <Button
              className="moderator-statements-id-page__header-btn"
              onClick={takeToWork}
            >
              Взять в работу
            </Button>
          ))}

        {(role === UserRole.MODERATOR ||
          role === UserRole.INSTITUTE_STAFF ||
          role === UserRole.SPECIALIST) &&
          (!isApplicationHasStatusWorkOrHigher(state) ? (
            <Button
              className="moderator-statements-id-page__header-btn"
              onClick={takeToWork}
            >
              Взять в работу
            </Button>
          ) : (
            <Button className="moderator-statements-id-page__header-btn">
              В работе
            </Button>
          ))}

        {role === UserRole.EXPERT &&
          state === ApplicationState.IN_WORK &&
          (expertId ? (
            <Button className="moderator-statements-id-page__header-btn">
              В работе
            </Button>
          ) : (
            <Button
              className="moderator-statements-id-page__header-btn"
              onClick={takeToWorkByExpert}
            >
              Взять в работу
            </Button>
          ))}

        {role === UserRole.COORDINATOR &&
          state === ApplicationState.IN_WORK &&
          (coordinatorId ? (
            <Button className="moderator-statements-id-page__header-btn">
              В работе
            </Button>
          ) : (
            <Button
              className="moderator-statements-id-page__header-btn"
              onClick={takeToWorkByCoordinator}
            >
              Взять в работу
            </Button>
          ))}
      </div>

      <SubmitFormContextProvider>
        <ModeratorStatementsRoutesContextProvider>
          <SidenavPage
            useSubmitFormHook={useSubmitFormContext}
            useHooks={useModeratorStatementsRoutesContext}
            title=""
            next={false}
          >
            <Outlet />
          </SidenavPage>
        </ModeratorStatementsRoutesContextProvider>
      </SubmitFormContextProvider>

      {role === UserRole.SUPPORT && (
        <div className="moderator-contract-info">
          <div className="moderator-contract-info__footer">
            <div className="moderator-contract-info__footer-btn">
              <div className="moderator-contract-info__block">
                <NavLink to={navLink()} target="_blank">
                  <Button type="button">
                    Зайти в личный кабинет пользователя
                  </Button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      )}

      {role !== UserRole.SUPPORT && (
        <div className="moderator-contract-info">
          <div className="moderator-contract-info__block">
            <h2>
              Статус заявки:{' '}
              {role === UserRole.ADMIN ? (
                <ReactSelect<SelectOption>
                  className="moderator-contract-info__state-select"
                  options={applicationStateSelectOptions}
                  value={{
                    value: state,
                    label: stateTitle,
                  }}
                  onChange={e => {
                    handleApplicationStateChangeChange(e.value);
                  }}
                />
              ) : (
                <span>{stateTitle}</span>
              )}
            </h2>

            {application && application.uploadError && (
              <div className="moderator-contract-info__send-to-queue-button-wrapper">
                {application.uploadDate &&
                  new Date(application.updatedAt) <=
                    new Date(application.uploadDate) && (
                    <Button
                      type="button"
                      className="moderator-contract-info__send-to-queue-button"
                      onClick={sendToQueue}
                    >
                      Отправить на повторную выгрузку
                    </Button>
                  )}
                {application.externalId && (
                  <Button
                    type="button"
                    className="moderator-contract-info__send-to-queue-button"
                    onClick={clearError}
                  >
                    Очистить ошибку
                  </Button>
                )}
              </div>
            )}
          </div>
          <div style={{ width: '100%' }}>
            <ReactSelect<SelectOption>
              placeholder="Оригинал документа об образовании"
              isDisabled={
                (role !== UserRole.ADMIN &&
                  role !== UserRole.INSTITUTE_STAFF &&
                  role !== UserRole.SPECIALIST) ||
                state === ApplicationState.NOT_CREATED ||
                state === ApplicationState.DRAFT ||
                state === ApplicationState.UNDER_CONSIDERATION
              }
              options={originalDocumentTypeSelectOptions}
              value={findSelectOption(
                originalDocumentTypeSelectOptions,
                selectedEducationDocumentType
              )}
              onChange={e => handleOriginalEducationDocumentTypeChange(e.value)}
            />
          </div>
          {originalSubmission &&
            (originalSubmission.submittedAt ||
              originalSubmission.withdrawnAt) &&
            `Оригинал документа об образовании ${
              originalSubmission.isSubmitted ? 'подан' : 'отозван'
            }: ${
              originalSubmission.isSubmitted
                ? formatDateTimeToRuFormat(originalSubmission.submittedAt)
                : formatDateTimeToRuFormat(originalSubmission.withdrawnAt)
            }`}
          <TextareaAutosize
            {...fields.comment}
            maxLength={2048}
            className="moderator-contract-info__comment-input"
            placeholder="Комментарий"
          />

          {(role === UserRole.MODERATOR ||
            role === UserRole.ADMIN ||
            role === UserRole.INSTITUTE_STAFF ||
            role === UserRole.SPECIALIST) && (
            <div className="moderator-contract-info__footer">
              <div className="moderator-contract-info__footer-btn-left">
                <div className="moderator-contract-info__block">
                  <NavLink to={navLink()} target="_blank">
                    <Button type="button">
                      Зайти в личный кабинет пользователя
                    </Button>
                  </NavLink>
                </div>

                {role === UserRole.ADMIN && (
                  <Button
                    type="button"
                    className="statements-container__element-btn-panel_icon"
                    onClick={deleteApplication}
                  >
                    <TrashIcon />
                  </Button>
                )}
              </div>

              {state !== ApplicationState.WITHDRAWN_APPROVED &&
                state !== ApplicationState.DRAFT &&
                state !== ApplicationState.REJECTED &&
                role === UserRole.ADMIN && (
                  <div className="moderator-contract-info__footer-btn">
                    <Button
                      type="button"
                      className="moderator-contract-info__footer-btn-reject"
                      onClick={e => {
                        e.preventDefault();
                        openModal();
                      }}
                    >
                      Отозвать
                    </Button>
                  </div>
                )}

              {state === ApplicationState.ACCEPTED && (
                <div className="moderator-contract-info__footer-btn">
                  <Button
                    type="button"
                    theme="success"
                    onClick={allowApplication}
                  >
                    Допустить
                  </Button>
                </div>
              )}

              {(state === ApplicationState.IN_WORK ||
                state === ApplicationState.DIRECTIONS_EDITED) && (
                <div className="moderator-contract-info__footer-btn">
                  {coordinatorState !== ApplicationFlag.SENT_TO_COORDINATOR && (
                    <Button type="button" onClick={sendToCoordinator}>
                      Отправить координатору
                    </Button>
                  )}

                  {canSendToExpert &&
                    expertState !== ApplicationFlag.SENT_TO_EXPERT && (
                      <Button
                        type="button"
                        onClick={sendToExpert}
                        className="moderator-contract-info__footer-btn-reject"
                      >
                        Отправить в ЦЭИДО
                      </Button>
                    )}

                  {!application?.epguInfo?.createdFromEpgu && (
                    <Button
                      type="button"
                      onClick={
                        state === ApplicationState.IN_WORK
                          ? () => {
                              if (
                                application?.epguInfo?.createdFromEpgu &&
                                !application?.epguInfo?.reasonRejectExternalId
                              )
                                dispatch(
                                  showModal({ name: 'SET_EPGU_REJECT_REASON' })
                                );
                              else rejectApplication();
                            }
                          : rejectApplicationEditing
                      }
                      className="moderator-contract-info__footer-btn-reject"
                    >
                      Отказать
                    </Button>
                  )}

                  {state !== ApplicationState.DIRECTIONS_EDITED &&
                    !application?.epguInfo?.createdFromEpgu && (
                      <Button type="button" onClick={sendToEditing}>
                        Отправить на редактирование
                      </Button>
                    )}

                  <Button
                    type="button"
                    onClick={acceptApplication}
                    theme="success"
                  >
                    Одобрить
                  </Button>
                </div>
              )}

              {state === ApplicationState.WITHDRAWN &&
                !application?.epguInfo?.createdFromEpgu &&
                (role === UserRole.MODERATOR ||
                  role === UserRole.ADMIN ||
                  role === UserRole.INSTITUTE_STAFF ||
                  role === UserRole.SPECIALIST) && (
                  <div className="moderator-contract-info__footer-btn">
                    <Button
                      type="button"
                      onClick={acceptWithdraw}
                      className="moderator-contract-info__footer-btn-reject"
                    >
                      Одобрить отзыв
                    </Button>
                    <Button
                      type="button"
                      onClick={declineWithdraw}
                      theme="success"
                    >
                      Отказать в отзыве
                    </Button>
                  </div>
                )}
            </div>
          )}

          {(role === UserRole.EXPERT || role === UserRole.COORDINATOR) && (
            <div className="moderator-contract-info__footer">
              <div />
              {role === UserRole.EXPERT &&
                expertState === ApplicationFlag.SENT_TO_EXPERT &&
                expertId && (
                  <div className="moderator-contract-info__footer-btn">
                    <Button
                      type="button"
                      onClick={rejectByExpert}
                      className="moderator-contract-info__footer-btn-reject"
                    >
                      Отказать
                    </Button>
                    <Button
                      type="button"
                      onClick={approveByExpert}
                      theme="success"
                    >
                      Одобрить
                    </Button>
                  </div>
                )}
              {role === UserRole.COORDINATOR &&
                coordinatorState === ApplicationFlag.SENT_TO_COORDINATOR &&
                coordinatorId && (
                  <div className="moderator-contract-info__footer-btn">
                    <Button
                      type="button"
                      onClick={rejectByCoordinator}
                      className="moderator-contract-info__footer-btn-reject"
                    >
                      Отказать
                    </Button>
                    <Button
                      type="button"
                      onClick={approveByCoordinator}
                      theme="success"
                    >
                      Одобрить
                    </Button>
                  </div>
                )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ModeratorStatementsIdPage;
