import React, { forwardRef, PropsWithChildren } from 'react';

import classnames from 'classnames';

import useSubmitFormContext from 'hooks/common/useSubmitFormContext';

const FillingStatementsForm = forwardRef<
  HTMLFormElement,
  PropsWithChildren<React.FormHTMLAttributes<HTMLFormElement>>
>(({ children, ...rest }, ref) => {
  const { HiddenButton } = useSubmitFormContext();

  return (
    <form {...rest} className={classnames(rest.className)} ref={ref}>
      <HiddenButton />
      {children}
    </form>
  );
});

export default FillingStatementsForm;
