import React, { FC } from 'react';

import GovernmentContractForm from 'components/statements/TargetAgreementForm';

const TargetAgreementPage: FC = () => (
  <div>
    <GovernmentContractForm />
  </div>
);

export default TargetAgreementPage;
