import useAppDispatch from 'hooks/common/useAppDispatch';
import { useEnrolleeUserId } from 'hooks/questionnaire/common/draft/useQuestionnaire';
import { showModal } from 'redux/actions';
import { useLazyPreviewFilesQuery } from 'redux/api/common/files-api';
import { selectApplication } from 'redux/selectors/enrollee/application';

import { Olympiad } from '../../../models/applications/olympiads';
import { olympiadsApi } from '../../../redux/api/applications/olympiads-api';
import { useDownloadFile } from '../../common/api/useDownloadFile';
import useAppSelector from '../../common/useAppSelector';

const useOlympiadsForm = (
  applicationId: number,
  refetchAchievementsForm: () => void
) => {
  const dispatch = useAppDispatch();
  const userId = useEnrolleeUserId();

  const downloadFile = useDownloadFile();
  const [deleteOlympiad] = olympiadsApi.useDeleteOlympiadMutation();
  const application = useAppSelector(selectApplication);

  const [previewFiles] = useLazyPreviewFilesQuery();

  const onDownloadFile = (docId: number) => {
    if (!userId) return;
    previewFiles({ userId, ids: [docId] })
      .unwrap()
      .then(files => {
        if (!files[0]) return;
        downloadFile({
          userId,
          fileId: docId,
          fileName: files[0].originalName,
        });
      });
  };

  const onDelete = (olympiadId: number) => {
    deleteOlympiad(olympiadId)
      .unwrap()
      .then(() => refetchAchievementsForm());
  };

  const onEdit = (olympiad: Olympiad) => {
    dispatch(
      showModal({
        name: 'ADD_OLYMPIAD_MODAL',
        data: {
          applicationId,
          olympiad,
          educationLevel: application?.educationLevel,
          refetchAchievementsForm,
          userId,
        },
      })
    );
  };

  return {
    onDelete,
    onDownloadFile,
    onEdit,
  };
};

export default useOlympiadsForm;
