import React, { FC } from 'react';

import classnames from 'classnames';

import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';
import { ReactComponent as QuestionMark } from 'assets/images/icons/question-mark.svg';
import Button from 'components/common/ui-kit/Button';
import useAppDispatch from 'hooks/common/useAppDispatch';
import { hideModal } from 'redux/actions';

type TemplateModalProps = {
  children: React.ReactNode;
  title: string;
  className?: string;
  hint?: string;
};

const TemplateModal: FC<TemplateModalProps> = ({
  children,
  title,
  className,
  hint,
}) => {
  const dispatch = useAppDispatch();

  const closeModal = () => {
    dispatch(hideModal());
  };

  return (
    <div className="modal-layer">
      <div className="modal-layer__overlay" onClick={closeModal} />
      <div
        className={classnames('modal-layer__modal template-modal', className)}
      >
        <header className="template-modal__header">
          <div className={classnames({ 'template-modal__header-group': hint })}>
            <h1 className="template-modal__title">{title}</h1>
            {hint && (
              <div title-hint={hint} className="template-modal__svg">
                <QuestionMark width="24" height="24" fill="#37B34A" />
              </div>
            )}
          </div>
          <div>
            <Button className="template-modal__close-btn" onClick={closeModal}>
              <CloseIcon />
            </Button>
          </div>
        </header>
        <div className="template-modal__body">{children}</div>
      </div>
    </div>
  );
};

export default TemplateModal;
