import React, { FC } from 'react';

import TemplateModal from 'components/common/modals/TemplateModal';
import Button from 'components/common/ui-kit/Button';
import useAppSelector from 'hooks/common/useAppSelector';
import terms from 'i18n';
import { modalDataSelector } from 'redux/selectors';

import useAppDispatch from '../../../../hooks/common/useAppDispatch';
import { hideModal } from '../../../../redux/actions';

const ConfirmWithdrawnApplicationModal: FC = () => {
  const dispatch = useAppDispatch();
  const { applicationId, func, text } = useAppSelector(modalDataSelector);
  const closeModal = () => dispatch(hideModal());

  const handleConfirmation = () => {
    func();
    closeModal();
  };

  return (
    <TemplateModal
      title={`Внимание, это действие необратимо! Вы действительно хотите отозвать заявление (id = ${applicationId})?`}
    >
      <div>
        <p style={{ marginTop: 20 }}>{text}</p>
        <div style={{ display: 'flex', gap: 20, marginTop: 20 }}>
          <Button theme="success" onClick={handleConfirmation}>
            {terms.WITHDRAW_APPLICATION}
          </Button>
          <Button
            onClick={() => {
              closeModal();
            }}
          >
            {terms.CANCEL}
          </Button>
        </div>
      </div>
    </TemplateModal>
  );
};

export default ConfirmWithdrawnApplicationModal;
