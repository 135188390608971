import { DateString } from 'models/common';
import { User } from 'models/enrollee';
import {
  EducationLevel,
  EducationType,
  Gender,
  IdentificationDocumentType,
  MobileCountryCode,
  RelationDegree,
} from 'models/enum';

import { Country } from '../common-entities';
import { FileId } from '../files';
import { VisaCity } from './visa-form';

export type QuestionnaireId = number;
export type DraftId = number;

export type DraftElement = {
  id: DraftId;
};

export enum CheckStatus {
  NOT_VERIFIED = 'NOT_VERIFIED', // Не проверен
  VERIFIED_NOT_CONFIRMED = 'VERIFIED_NOT_CONFIRMED', // Проверен и не подтвержден
  CONFIRMED_FRDO = 'CONFIRMED_FRDO', // Подтвержден в ФРДО
  CONFIRMED_UNIVERSITY = 'CONFIRMED_UNIVERSITY', // Подтвержден вузом
  CONFIRMED_EPGU = 'CONFIRMED_EPGU', // Подтвержден ЕПГУ
  CONFIRMED_FIS_GIA = 'CONFIRMED_FIS_GIA', // Подтвержден в ФИС ГИА и приема
}

export const checkStatusesToStrings: Record<CheckStatus, string> = {
  NOT_VERIFIED: 'Не проверен',
  VERIFIED_NOT_CONFIRMED: 'Проверен и не подтвержден',
  CONFIRMED_FRDO: 'Подтвержден в ФРДО',
  CONFIRMED_UNIVERSITY: 'Подтвержден вузом',
  CONFIRMED_EPGU: 'Подтвержден ЕПГУ',
  CONFIRMED_FIS_GIA: 'Подтвержден в ФИС ГИА и приема',
};

export type WithCheckStatus<T> = T & {
  checkStatus?: CheckStatus | null;
};

export type Draft = DraftElement | DraftElement[];

export type AlternativePassportView = WithCheckStatus<{
  id?: number | null;
  identificationDocumentSerial?: string | null;
  identificationDocumentNumber?: string | null;
  identificationDocumentIssuedAt?: DateString | null;
  identificationDocumentDivisionCode?: string | null;
  identificationDocumentIssuedBy?: string | null;
  epguExternalId?: string | null;
}>;

export type Questionnaire = {
  id: QuestionnaireId;
  user: User;
  createdAt: DateString;
  addressForm: AddressView;
  compatriotForm: CompatriotDraft;
  educationalInformationForm: WithCheckStatus<EducationInformationDraft>[];
  generalDataForm: GeneralDataDraft;
  legalRepresentativeForm: LegalRepresentativeDraft;
  passportForm: WithCheckStatus<PassportDraft>;
  alternativePassportsView: AlternativePassportView[];
  visaRequestForm: VisaRequestDraft;
};

export type GeneralDataDraft = {
  name?: string;
  nameLatin?: string;
  surname?: string;
  surnameLatin?: string;
  patronymic?: string;
  patronymicLatin?: string;
  gender?: Gender;
  birthday?: DateString;
  mobileCountryCode?: MobileCountryCode;
  mobile?: string;
  secondEmail?: string;
  primaryCitizenshipCountry?: Country;
  snils?: string;
  snilsIssuedAt?: DateString;
  recruiterId?: number;
  noSnils?: boolean;
} & DraftElement;

export type SaveGeneralDataDraftRequest = {
  name?: string;
  nameLatin?: string;
  surname?: string;
  surnameLatin?: string;
  patronymic?: string;
  patronymicLatin?: string;
  gender?: Gender;
  birthday?: DateString;
  mobileCountryCode?: MobileCountryCode;
  mobile?: string;
  secondEmail?: string;
  primaryCitizenshipCountryId?: number;
  snils?: string;
  snilsIssuedAt?: DateString;
  recruiterId?: number;
} & DraftElement;

export type VisaRequestDraft = {
  countryToDeliver?: Country;
  cityToDeliverView?: VisaCity;
  hadStudyVisa?: boolean;
} & DraftElement;

export type SaveVisaDraftRequest = {
  countryToDeliverId?: number;
  cityToDeliver?: string;
  hadStudyVisa?: boolean;
} & DraftElement;

export type PassportDraft = {
  identificationDocumentType?: IdentificationDocumentType;
  identificationDocumentSerial?: string;
  identificationDocumentNumber?: string;
  identificationDocumentIssuedAt?: DateString;
  identificationDocumentIssuedBy?: string;
  identificationDocumentExpirationDate?: DateString;
  identificationDocumentPlaceOfBirth?: string;
  identificationDocumentDivisionCode?: string;
  countryOfBirth?: Country;
  // secondaryCitizenshipCountryId?: number;
  secondaryCitizenshipCountry?: {
    id: number;
    externalId: string;
    iso: string;
    titleForeign: string;
    title: string;
  };
  passportCopyUploadId?: FileId;
  needVisa?: boolean;
  haveVisa?: boolean;
  haveTemporaryResidencePermit?: boolean;
  temporaryResidencePermitNumber: string;
  temporaryResidencePermitIssuedBy: string;
  temporaryResidencePermitIssuedAt: DateString;
  temporaryResidencePermitExpirationDate: DateString;
  temporaryResidencePermitUploadId: number;
  isCompatriot?: boolean;
  hasMinobrBenefit?: boolean;
  educationInRussiaWebsiteIdentifier?: string;
  minobrReferralUploadId?: number;
} & DraftElement;

export type SavePassportDraftRequest = {
  identificationDocumentType?: IdentificationDocumentType;
  identificationDocumentSerial?: string;
  identificationDocumentNumber?: string;
  identificationDocumentIssuedAt?: DateString;
  identificationDocumentIssuedBy?: string;
  identificationDocumentExpirationDate?: DateString;
  identificationDocumentPlaceOfBirth?: string;
  identificationDocumentDivisionCode?: string;
  countryOfBirthId?: number;
  secondaryCitizenshipCountryId?: number;
  passportCopyUploadId?: number;
  needVisa?: boolean;
  haveVisa?: boolean;
  haveTemporaryResidencePermit?: boolean;
  temporaryResidencePermitNumber: string;
  temporaryResidencePermitIssuedBy: string;
  temporaryResidencePermitIssuedAt: DateString;
  temporaryResidencePermitExpirationDate: DateString;
  temporaryResidencePermitUploadId: number;
  isCompatriot?: boolean;
  hasMinobrBenefit?: boolean;
  educationInRussiaWebsiteIdentifier?: string;
  minobrReferralUploadId?: number;
} & DraftElement;

export type LegalRepresentativeDraft = {
  name?: string;
  surname?: string;
  patronymic?: string;
  birthday?: DateString;
  mobileCountryCode?: MobileCountryCode;
  mobile?: string;
  relationDegree?: RelationDegree;
  personalDataConsentId?: FileId;
} & DraftElement;

export type EducationInformationDraft = {
  documentType?: EducationType;
  educationalInstitution?: string;
  studiedSpbpuPreparatoryDepartment?: boolean;
  educationLevel?: EducationLevel;
  educationCountry?: Country;
  educationalDocumentNumber?: string;
  educationalDocumentIssuedAt?: DateString;
  russianKladrEducationRegion?: string;
  graduationYear?: number;
  withHonors?: boolean;
  satisfactoryGrades?: number;
  goodGrades?: number;
  excellentGrades?: number;
  documentCopyUploadId?: number;
  educationalInformationDraftId: number;
  epguExternalId?: string;
  epguWithHonorsDocumentGuid?: string;
} & DraftElement;

export type SaveEducationInformationRequest = {
  documentType?: EducationType;
  educationalInstitution?: string;
  studiedSpbpuPreparatoryDepartment?: boolean;
  educationLevel?: EducationLevel;
  educationCountryId?: number;
  educationalDocumentNumber?: string;
  educationalInformationDraftId: number;
  educationalDocumentIssuedAt?: DateString;
  graduationYear?: number;
  withHonors?: boolean;
  satisfactoryGrades?: number;
  goodGrades?: number;
  excellentGrades?: number;
  documentCopyUploadId?: number;
} & DraftElement;

export type AddressView = {
  registrationCountry?: Country;
  actualCountry?: Country;
  russianKladrRegistrationRegion?: string;
  russianKladrRegistrationDistrict?: string;
  russianKladrRegistrationCity?: string;
  russianKladrRegistrationStreet?: string;
  russianKladrRegistrationHouse?: string;
  russianKladrRegistrationHouseNumber?: string;
  russianKladrRegistrationHouseExtension?: string;
  russianKladrRegistrationHouseLitera?: string;
  russianKladrRegistrationApartment?: number;
  russianKladrRegistrationPostcode?: number;
  foreignRegistrationAddress?: string;
  fullRegistrationAddress?: string; // address in one line
  russianKladrActualRegion?: string;
  russianKladrActualDistrict?: string;
  russianKladrActualCity?: string;
  russianKladrActualStreet?: string;
  russianKladrActualHouse?: string;
  russianKladrActualHouseNumber?: string;
  russianKladrActualHouseExtension?: string;
  russianKladrActualHouseLitera?: string;
  russianKladrActualApartment?: number;
  russianKladrActualPostcode?: number;
  foreignActualAddress?: string;
  fullActualAddress?: string; // address in one line
  isAddressesSame?: boolean;
  needDormitory?: boolean;
} & DraftElement;

export type UpdateAddressesDraftRequest = {
  registrationCountryId?: number;
  actualCountryId?: number;
  needDormitory?: boolean;
  isAddressesSame?: boolean;
  foreignActualAddress?: string;
  foreignRegistrationAddress?: string;
} & DraftElement;

export type CompatriotDraft = {
  applicationOfRecognitionUploadId: FileId | null;
  birthCertificateUploadId: FileId | null;
  relativesBirthCertificatesUploadIds: FileId[];
} & DraftElement;
