import React, { FC, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import ModeratorDataList from 'components/moderator/ModeratorDataList';
import ModeratorFile from 'components/moderator/ModeratorFile';
import useAppSelector from 'hooks/common/useAppSelector';
import { FileId } from 'models/files';
import { DataList } from 'models/moderator';
import {
  useLazyDownloadFileQuery,
  useLazyPreviewFilesQuery,
} from 'redux/api/common/files-api';
import { selectCurrentApplicationUser } from 'redux/selectors/staff/applications';
import { downloadFile } from 'utils/common';

import Loader from '../../../components/common/ui-kit/Loader';
import CheckListEntryValueSelectWithRoleChecker from '../../../components/moderator/CheckListEntryValueSelectWithRoleChecker';
import { GovernmentContractRequest } from '../../../models/applications/government-contracts';
import { governmentContractsApi } from '../../../redux/api/applications/government-contracts-api';

const ModeratorTargetContractPage: FC = () => {
  const { id } = useParams<{ id?: string }>();

  const user = useAppSelector(selectCurrentApplicationUser);

  const { data } =
    governmentContractsApi.useGetGovernmentContractsByApplicationIdQuery(
      Number(id)
    );

  const [getFilesMeta, { data: fileMeta }] = useLazyPreviewFilesQuery();
  const [download] = useLazyDownloadFileQuery();
  const file = fileMeta?.[0];

  useEffect(() => {
    if (!data?.[0] || !user) return;
    const ids = [data[0].documentId] as FileId[];
    getFilesMeta({
      userId: user.id,
      ids,
    });
  }, [data, user]);

  if (!data || !user) return <Loader />;

  const contract = data[0] as GovernmentContractRequest;

  const list: DataList = contract && [
    {
      title: 'Наименование организации:',
      label: contract.organization,
    },
    {
      title: 'Идентификационный номер предложения:',
      label: contract.offerNumber,
    },
    {
      title: 'Дата размещения предложения:',
      label: contract.dateOfIssue,
    },
  ];

  return (
    <div className="moderator-data-list">
      {list ? (
        <>
          <ModeratorDataList list={list} />
          {file && (
            <ModeratorFile
              title={file.originalName}
              content={file.createdAt}
              upload={() => {
                download({
                  userId: user.id,
                  fileId: file.id,
                })
                  ?.unwrap()
                  .then(result => {
                    downloadFile(new File([result], `${file.originalName}`));
                  });
              }}
            />
          )}
        </>
      ) : (
        <p>Нет целевого договора</p>
      )}

      <CheckListEntryValueSelectWithRoleChecker
        getValue={checkList => checkList.unrelatedCheckMarks['target-contract']}
        updateValue={newValue => ({
          unrelatedCheckMarks: {
            'target-contract': newValue,
          },
        })}
      />
    </div>
  );
};

export default ModeratorTargetContractPage;
