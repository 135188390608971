import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EnrolleeView, User } from '../../../models/enrollee';
import { Questionnaire } from '../../../models/questionnaire/questionnaire';

type SupportQuestionnaireState = {
  readonly filters: object;
  readonly page: number;
  readonly size: number;
  readonly orderBy?: string;
  readonly orderDirection?: 'ASC' | 'DESC';
  readonly totalPages: number;
  readonly totalElements: number;
  readonly elements: EnrolleeView[];
  readonly currentQuestionnaire?: Questionnaire & { user: User };
};

const initialState: SupportQuestionnaireState = {
  filters: {},
  page: 1,
  size: 10,
  orderBy: undefined,
  orderDirection: undefined,
  totalPages: 0,
  totalElements: 0,
  elements: [],
  currentQuestionnaire: undefined,
};

export const supportQuestionnaireSlice = createSlice({
  name: 'supportQuestionnaire',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    cleanFilters: state => {
      state.filters = {};
    },

    setOrderDirection: (state, action) => {
      if (action.payload) {
        state.orderDirection = action.payload;
      } else {
        state.orderDirection = undefined;
      }
    },

    setOrderBy: (state, action) => {
      if (action.payload) {
        state.orderBy = action.payload;
      } else {
        state.orderBy = undefined;
      }
    },

    setQuestionnaires: (
      state,
      action: PayloadAction<{
        readonly questionnaires: EnrolleeView[];
        readonly page: number;
        readonly totalPages: number;
        readonly size: number;
        readonly totalElements: number;
      }>
    ) => {
      state.elements = action.payload.questionnaires;
      state.size = action.payload.size;
      state.totalPages = action.payload.totalPages;
      state.page = action.payload.page;
      state.totalElements = action.payload.totalElements;
    },

    setPage: (state, action) => {
      state.page = action.payload;
    },

    setSize: (state, action) => {
      state.size = action.payload;
    },

    setCurrentQuestionnaire: (state, action) => {
      state.currentQuestionnaire = action.payload;
    },

    cleanCurrentQuestionnaire: state => {
      state.currentQuestionnaire = undefined;
    },
  },
});

export default supportQuestionnaireSlice.reducer;
