import {
  ChangeItselfPasswordRequest,
  UpdateUserPayload,
  UserDataRespOk,
} from '../../../models/user';
import { apiSlice } from '../api-slice';

const baseUrl = `${process.env.REACT_APP_USER_MANAGER_API_URL}/users`;

export const userApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getUser: builder.query<UserDataRespOk, void>({
      query: () => ({
        url: `${baseUrl}/me`,
        method: 'GET',
      }),
    }),

    updateUser: builder.mutation<void, UpdateUserPayload>({
      query: body => ({
        url: `${baseUrl}/me`,
        method: 'PUT',
        body,
      }),
    }),

    changePassword: builder.mutation<void, ChangeItselfPasswordRequest>({
      query: body => ({
        url: `${baseUrl}/me/password`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useUpdateUserMutation,
  useChangePasswordMutation,
} = userApi;
