import { isRussianLocale } from 'i18n';

export const MOBILE_SCREEN_WIDTH = 640;
export const TABLET_SCREEN_WIDTH = 900;

export const registrationFieldsByLocale: {
  name: 'name_ru' | 'name_en';
  surname: 'surname_ru' | 'surname_en';
  patronymic: 'patronymic_ru' | 'patronymic_en';
} = {
  name: isRussianLocale() ? 'name_ru' : 'name_en',
  surname: isRussianLocale() ? 'surname_ru' : 'surname_en',
  patronymic: isRussianLocale() ? 'patronymic_ru' : 'patronymic_en',
};

export const RUSSIA_CITIZEN_PASSPORT_ID = 1;

export const BLANK_AGREEMENT_TO_ENTER = 2;
export const BLANK_RECEIPT_DOCUMENTS = 5; // Бланк Расписка и опись документов
export const BLANK_ACCEPT_CONSENT = 2;
export const BLANK_WITHDRAW_CONSENT = 4;

export const LEVEL_TRAINING_BACHELOR = 2;
export const LEVEL_TRAINING_PHD = 5; // аспирант
export const LEVEL_TRAINING_PREPARATION = 6; // Подготовительный факультет

export const STATUS_APPROVED = 5; // Одобрено

export const SCENARIO_MASTER = '3';
