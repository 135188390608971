import React, { FC, useMemo } from 'react';

import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg';
import Button from 'components/common/ui-kit/Button';
import { InputV2 } from 'components/common/ui-kit/Input/view';
import useAppSelector from 'hooks/common/useAppSelector';
import terms, { isRussianLocale } from 'i18n';
import { SubjectResponse } from 'models/applications/school-tests';
import { EntrantTestType } from 'models/students';

import useDirectionsTrainingElement from '../../../../hooks/statements/directions/useDirectionsTrainingElement';
import {
  ChosenDirectionWithPriority,
  Direction,
  EntranceTestsSet,
} from '../../../../models/applications/directions';
import {
  ApplicationEducationLevel,
  DirectionApplicationEducationLevel,
  Trajectory,
} from '../../../../models/applications/enum';
import { translate } from '../../../../models/common';
import { entranceTestTypesToString } from '../../../../pages/Enrollment/common/column-renderers';
import {
  selectCurrentEducationLevel,
  selectCurrentTrajectory,
} from '../../../../redux/selectors/enrollee/application';

type DirectionsTrainingElementProps = {
  direction: ChosenDirectionWithPriority;
  readOnly: boolean;
  isFirst: boolean;
  isLast: boolean;
  index: number;
  onSwap: (from: number, to: number) => void;
  onDelete: (directionId: number) => void;
  onPriorityChange: (newPriority: number) => void;
};

export type SubjectTypesToMinScores = { [key in EntrantTestType]?: number };

export const getSubjectTestTypesToMinScores = (
  subject: SubjectResponse,
  directionId: number
): SubjectTypesToMinScores => {
  return subject.minScores
    .filter(minScore => minScore.directionId === directionId)
    .reduce<SubjectTypesToMinScores>((acc, { testType, minScore }) => {
      acc[testType] = minScore;
      return acc as SubjectTypesToMinScores;
    }, {});
};

export const getReplaceableSubjectsString = (
  subject: SubjectResponse,
  subjects: SubjectResponse[],
  direction: Direction
) => {
  const replaceableSubjects = getSubjectWithReplacements(subject, subjects);
  return replaceableSubjects
    .map(replaceableSubject => {
      const subjectTestTypesToMinScores = getSubjectTestTypesToMinScores(
        replaceableSubject,
        direction.id
      );
      const subjectTestTypesToMinScoresEntries = Object.entries(
        subjectTestTypesToMinScores
      );
      return `${
        isRussianLocale()
          ? replaceableSubject.title
          : replaceableSubject.titleForeign
      }${
        subjectTestTypesToMinScoresEntries.length > 0
          ? ` (${subjectTestTypesToMinScoresEntries
              .map(
                ([testType, score]) =>
                  `${entranceTestTypesToString[testType]} - ${score}`
              )
              .join(', ')})`
          : ''
      }`;
    })
    .join(' / ');
};

export const getAllSubjects = (
  entranceTestsSets: EntranceTestsSet[],
  direction: Direction
): SubjectResponse[] => {
  const result = entranceTestsSets.reduce((map, entranceTestsSet) => {
    const subjectIds = entranceTestsSet.subjects.map(subj => subj.id);
    entranceTestsSet.subjects.forEach(subject => {
      map[subject.id] = {
        ...subject,
        replacements: (map[subject.id] ?? subject).replacements.filter(
          replacement =>
            !subjectIds.includes(replacement) &&
            replacement.direction === direction.id
        ),
      };
    });
    return map;
  }, {});
  entranceTestsSets.flatMap(entranceTestsSet =>
    entranceTestsSet.subjects.map(subject => {
      const subjectIds = entranceTestsSet.subjects.map(subj => subj.id);
      return {
        ...subject,
        replacements: subject.replacements.filter(
          replacement => !subjectIds.includes(replacement.replacement)
        ),
      };
    })
  );
  return Object.values(result);
};

export const getUniqueSubjects = (
  entranceTestsSets: EntranceTestsSet[],
  direction: Direction
): SubjectResponse[] => {
  const allSubjects = getAllSubjects(entranceTestsSets, direction);
  const replacementIds: number[] = [];

  return allSubjects.filter((subject, index, self) => {
    const allWithSameId = self.filter(subj => subj.id === subject.id);
    const indexInAllWithSameId = allWithSameId.indexOf(subject);
    const isShown =
      indexInAllWithSameId === 0 && !replacementIds.includes(subject.id);
    if (isShown)
      replacementIds.push(...subject.replacements.map(el => el.replacement));
    return isShown;
  });
};

export const getSubjectWithReplacements = (
  subject: SubjectResponse,
  allSubjects: SubjectResponse[]
) => {
  const replaceableSubjects: SubjectResponse[] = [subject];
  subject.replacements.forEach(replacementSubjectId => {
    const replacement = allSubjects.find(
      subject => subject.id === replacementSubjectId.replacement
    );
    if (replacement) replaceableSubjects.push(replacement);
  });
  return replaceableSubjects;
};

const DirectionsTrainingElementStatement: FC<
  DirectionsTrainingElementProps
> = ({
  direction: { direction, priority, applicationId, id, agreement },
  readOnly,
  onDelete,
  onPriorityChange,
  isFirst,
  isLast,
  onSwap,
  index,
}) => {
  const { openModal } = useDirectionsTrainingElement();
  const level = useAppSelector(selectCurrentEducationLevel);
  const trajectory = useAppSelector(selectCurrentTrajectory);

  const allSubjects = useMemo(
    () => getAllSubjects(direction.entranceTestsSets, direction),
    [direction.entranceTestsSets]
  );

  const subjects = useMemo(
    () => getUniqueSubjects(direction.entranceTestsSets, direction),
    [direction.entranceTestsSets]
  );
  return (
    <div className="directions-training-element">
      <h2>
        {(isRussianLocale()
          ? direction.code + ' ' + direction.title
          : direction.code +
            ' ' +
            (direction.titleForeign || direction.title)) +
          (direction.targetOrganization
            ? ` ${direction.targetOrganization?.shortName}`
            : '')}
      </h2>
      <div className="row">
        <div className="column directions-training-element__vertical-centered-container">
          {direction.asBenefit && (
            <p>
              <b>{terms.LGOT}</b>
            </p>
          )}
          {direction.asQuota && (
            <p>
              <b>{terms.INDIVIDUAL_QUOTA}</b>
            </p>
          )}
          {direction.applicationEducationLevel ===
            DirectionApplicationEducationLevel.SECONDARY && (
            <p>
              <b>{terms.GRADE_9}</b>
            </p>
          )}
          {direction.applicationEducationLevel ===
            DirectionApplicationEducationLevel.UPPER_SECONDARY && (
            <p>
              <b>{terms.GRADE_11}</b>
            </p>
          )}
        </div>
        <i style={{ marginLeft: 'auto' }}>
          {/* {terms.PRIORITY}:{' '}*/}
          <InputV2
            className="profile-form__input profile-form__input--fb-66"
            placeholder={terms.PRIORITY}
            markRequired
            type="number"
            value={priority}
            disabled={readOnly}
            onKeyDown={e => {
              if (['-', 'e', '+', '.'].includes(e.key)) e.preventDefault();
            }}
            onChange={e => {
              if (e.target.valueAsNumber > 0 || e.target.value === '')
                onPriorityChange(e.target.valueAsNumber);
            }}
          />
        </i>
      </div>
      <div className="row">
        <div className="column">
          <p>
            {terms.MODE_OF_STUDY}: <b>{translate(direction.educationForm)}</b>
          </p>
          <p>
            {terms.FORM_PAYMENT_ID}: <b>{translate(direction.paymentForm)}</b>
          </p>
        </div>
        <div className="column">
          <p>
            {terms.LEVEL_OF_TRAINING}:{' '}
            <b>{translate(direction.educationLevel)}</b>
          </p>
          {direction.educationProgram && (
            <p>
              {terms.EDUCATIONAL_PROGRAM}:{' '}
              <b>
                {isRussianLocale()
                  ? direction.educationProgram.title
                  : direction.educationProgram.titleForeign ||
                    direction.educationProgram.title}
              </b>
            </p>
          )}
        </div>
      </div>
      {direction.budgetLevel && (
        <div className="row">
          <p>
            {terms.BUDGET_LEVEL}: <b>{direction.budgetLevel}</b>
          </p>
        </div>
      )}
      <div className="row">
        <p>
          {terms.EDUCATION_LANGUAGE}: <b>{direction.educationLanguageName}</b>
        </p>
      </div>
      {direction.targetOrganization && (
        <div className="row">
          <p>
            {terms.TARGET_ORGANIZATION}:{' '}
            <b>{direction.targetOrganization.name}</b>
          </p>
        </div>
      )}
      <div className="row">
        <p>
          {terms.FACULTY}: <b>{translate(direction.faculty)}</b>
        </p>
      </div>
      <div className="row">
        <div className="column">
          {!(
            trajectory === Trajectory.QUOTA ||
            (trajectory === Trajectory.FOREIGN &&
              level === ApplicationEducationLevel.PREPARATORY) ||
            level === ApplicationEducationLevel.SECONDARY
          ) &&
            subjects.length !== 0 && (
              <>
                <p>
                  {terms.ENTRANCE_EXAMS} / {terms.PASSING_SCORE}
                </p>
                {trajectory &&
                  level &&
                  subjects.map((subject, index) => {
                    return (
                      <b key={index}>
                        {getReplaceableSubjectsString(
                          subject,
                          allSubjects,
                          direction
                        )}
                      </b>
                    );
                  })}
              </>
            )}
        </div>

        <div className="directions-training-element__control-btn-container">
          <div className="directions-training-element__control-btn-container_btn">
            {/* TODO */}
            {/* {statementEl &&
              Number(statementEl.statusapplicationId) === STATUS_APPROVED &&
              !(
                level.id !== LEVEL_TRAINING_MASTER &&
                direction.pivot.acceptIteration >= 3
              ) &&
              level.id !== LEVEL_TRAINING_SPO &&
              !(
                trajectory.id === TRAJECTORY_RF &&
                (level.id === LEVEL_TRAINING_BACHELOR ||
                  level.id === LEVEL_TRAINING_BACHELOR_BOR) &&
                direction.formPayment.code === BUDGET_CODE &&
                !direction.pivot.accept &&
                time.isSameOrAfter(moment('2022-08-03 17:59:59'))
              ) && (
                <>
                  {direction.pivot.accept ? (
                    <Button
                      onClick={e => {
                        e.preventDefault();
                        openModal(direction.id, 'reject');
                      }}
                    >
                      {terms.WITHDRAW_CONSENT}
                    </Button>
                  ) : (
                    !(
                      (trajectory.id === TRAJECTORY_RF &&
                        (level.id === LEVEL_TRAINING_BACHELOR ||
                          level.id === LEVEL_TRAINING_BACHELOR_BOR) &&
                        (direction.formPaymentsId === 6 ||
                          direction.benefits ||
                          direction.specialQuota ||
                          (direction.formEducation.code ===
                            FORM_EDUCATION_FULL_TIME_CODE &&
                            direction.formPayment.code ===
                              FORM_PAYMENT_CONTRACT_CODE &&
                            serverTime.isSameOrAfter(
                              moment('2022-08-15 18:00:00')
                            )) ||
                          (direction.formEducation.code ===
                            FORM_EDUCATION_PART_TIME_CODE &&
                            direction.formPayment.code ===
                              FORM_PAYMENT_CONTRACT_CODE &&
                            serverTime.isSameOrAfter(
                              moment('2022-08-15 18:00:00')
                            )))) ||
                      (level.id === LEVEL_TRAINING_MASTER &&
                        trajectory.id === TRAJECTORY_RF &&
                        ((direction.formEducation.code ===
                          FORM_EDUCATION_FULL_TIME_CODE &&
                          direction.formPayment.code === BUDGET_CODE &&
                          time.isSameOrAfter(moment('2022-08-16 18:00:00'))) ||
                          (direction.formEducation.code ===
                            FORM_EDUCATION_PART_TIME_CODE &&
                            direction.formPayment.code === BUDGET_CODE &&
                            time.isSameOrAfter(moment('2022-08-16 18:00:00')))))
                    ) &&
                    !acceptId && (
                      <Button
                        theme="success"
                        onClick={e => {
                          e.preventDefault();
                          openModal(direction.id);
                        }}
                      >
                        {terms.GIVE_CONSENT}
                      </Button>
                    )
                  )}
                </>
              )}*/}
            {/* TODO: Кнопка согласия на зачисление для подготовительного факультета убрана в #273 */}
            {/* {direction.paymentForm.id === CONTRACT_PAYMENT_FORM_ID &&*/}
            {/*  applicationData?.state === ApplicationState.ACCEPTED &&*/}
            {/*  applicationData?.educationLevel ===*/}
            {/*    ApplicationEducationLevel.PREPARATORY &&*/}
            {/*  (agreement ? (*/}
            {/*    <Button disabled>{terms.CONSENT_GIVEN}</Button>*/}
            {/*  ) : (*/}
            {/*    <Button*/}
            {/*      theme="success"*/}
            {/*      onClick={e => {*/}
            {/*        e.preventDefault();*/}
            {/*        openModal(id);*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      {terms.GIVE_CONSENT}*/}
            {/*    </Button>*/}
            {/*  ))}*/}
            <div className="directions-training-element__control-arrows">
              {/* <Button*/}
              {/*  type="button"*/}
              {/*  onClick={() => onSwap(index, index - 1)}*/}
              {/*  disabled={readOnly || isFirst}*/}
              {/* >*/}
              {/*  <ArrowUp />*/}
              {/* </Button>*/}
              {/* <Button*/}
              {/*  type="button"*/}
              {/*  onClick={() => onSwap(index, index + 1)}*/}
              {/*  disabled={readOnly || isLast}*/}
              {/* >*/}
              {/*  <ArrowDown />*/}
              {/* </Button>*/}
            </div>
            <Button
              type="button"
              onClick={() => onDelete(direction.id)}
              disabled={readOnly}
            >
              <TrashIcon />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectionsTrainingElementStatement;
