import React, {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';

import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

import terms from 'i18n';

type MessageInputProps = {
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  isMessageReady: boolean;
  submitForm: () => void;
  className?: string;
};

const MessageInput: FC<MessageInputProps> = ({
  message,
  setMessage,
  isMessageReady,
  submitForm,
  className,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleKey = e => {
    if (e.key === 'Enter' && e.ctrlKey) {
      e.preventDefault();
      e.stopPropagation();
      isMessageReady ? submitForm() : textareaRef.current?.focus();
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.scrollTo(0, textareaRef.current.scrollHeight);
    }
  }, [message]);

  return (
    <TextareaAutosize
      ref={textareaRef}
      className={classNames('message-input', className)}
      onKeyDown={handleKey}
      autoFocus
      placeholder={terms.CHAT_PLACEHOLDER}
      value={message}
      onChange={e => setMessage(e.target.value)}
    />
  );
};

export default memo(MessageInput);
