import React from 'react';

import ConfirmAccountForm from 'components/auth/ConfirmAccountForm';
import terms from 'i18n';
import TemplateAuthPage from 'pages/TemplateAuthPage';

const ConfirmAccountPage = () => (
  <TemplateAuthPage navigateOnTokenUpdate={false}>
    <ConfirmAccountForm title={terms.EMAIL_CONFIRMED} />
  </TemplateAuthPage>
);

export default ConfirmAccountPage;
