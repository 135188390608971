import React, { FC } from 'react';

import Toast from 'components/common/ui-kit/Toast';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import { deleteToast } from 'redux/actions';
import { selectToasts } from 'redux/selectors';

const ToastsContainer: FC = () => {
  const dispatch = useAppDispatch();
  const toasts = useAppSelector(selectToasts);

  return (
    <div className="toast-container">
      <ul className="toast-container__items">
        {toasts.map(toast => (
          <li className="toast-container__item" key={toast.id}>
            <Toast {...toast} onClose={id => dispatch(deleteToast(id))} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ToastsContainer;
