import React, { forwardRef, PropsWithChildren } from 'react';

import classnames from 'classnames';

import useSubmitFormContext from 'hooks/common/useSubmitFormContext';

const ProfileForm = forwardRef<
  HTMLFormElement,
  PropsWithChildren<React.FormHTMLAttributes<HTMLFormElement>>
>(({ children, ...rest }, ref) => {
  const { HiddenButton } = useSubmitFormContext();

  return (
    <form
      {...rest}
      className={classnames('profile-form', rest.className)}
      ref={ref}
    >
      <HiddenButton />
      {children}
    </form>
  );
});

export default ProfileForm;
