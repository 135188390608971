import React, { FC, ReactNode } from 'react';

import cn from 'classnames';

import SidenavLink from 'components/common/ui-kit/Sidenav/SidenavLink';

export type SidenavRoute = {
  to: string;
  title: string;
  text?: string;
  element: ReactNode;
  index?: boolean;
};

type SideNavProps = {
  className?: string;
  routes: SidenavRoute[];
  isMobile?: boolean;
};

const SidenavV2: FC<SideNavProps> = ({ className, routes, isMobile }) => {
  return (
    <div className={className}>
      <nav>
        <ul>
          {routes.map(({ to, text, title }) => (
            <li key={to} className={cn({ 'mobile-border': isMobile })}>
              <SidenavLink to={to} text={text}>
                {title}
              </SidenavLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default SidenavV2;
