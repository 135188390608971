import { FileId } from 'models/files';
import {
  AddressView,
  CompatriotDraft,
  DraftId,
  EducationInformationDraft,
  GeneralDataDraft,
  LegalRepresentativeDraft,
  PassportDraft,
  Questionnaire,
  QuestionnaireId,
  SaveGeneralDataDraftRequest,
  SaveVisaDraftRequest,
  VisaRequestDraft,
} from 'models/questionnaire/questionnaire';
import { UserId } from 'models/user';
import { apiSlice } from 'redux/api/api-slice';

import { UserInfoPageableRequest } from '../../../models/applications/applications-info';
import { Page, Pagination, Sorting } from '../../../models/common';
import { EnrolleeView, User } from '../../../models/enrollee';

const baseUrl = `${process.env.REACT_APP_USER_MANAGER_API_URL}/questionnaires`;

export const questionnaireApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createOrGetEmptyQuestionnaire: builder.query<
      Questionnaire & { readonly user: User },
      UserId
    >({
      query: id => ({ url: `${baseUrl}/by-user/${id}`, method: 'POST' }),
      providesTags: ['Questionnaire'],
    }),
    getAllQuestionnairesPageable: builder.query<
      Page<EnrolleeView>,
      Pagination & Sorting & { request?: UserInfoPageableRequest }
    >({
      query: ({ page, size, orderBy, orderDirection, request }) => ({
        url: `${baseUrl}/get-all-pageable`,
        method: 'POST',
        body: request,
        params: {
          page,
          size,
          orderBy,
          orderDirection,
        },
      }),
    }),
    getGeneralDataDraft: builder.mutation<GeneralDataDraft, DraftId>({
      query: id => `${baseUrl}/general-data-drafts/${id}`,
    }),
    saveGeneralDataDraft: builder.mutation<
      SaveGeneralDataDraftRequest,
      { id: DraftId; draft: GeneralDataDraft }
    >({
      query: ({ id, draft }) => ({
        url: `${baseUrl}/general-data-drafts/${id}`,
        method: 'PUT',
        body: draft,
      }),
    }),

    getVisaRequestDraft: builder.query<VisaRequestDraft, DraftId>({
      query: id => `${baseUrl}/visa-request-drafts/${id}`,
    }),
    saveVisaRequestDraft: builder.mutation<
      VisaRequestDraft,
      { id: DraftId; draft: SaveVisaDraftRequest }
    >({
      query: ({ id, draft }) => ({
        url: `${baseUrl}/visa-request-drafts/${id}`,
        method: 'PUT',
        body: draft,
      }),
    }),

    getPassportDraft: builder.query<PassportDraft, DraftId>({
      query: id => `${baseUrl}/passport-drafts/${id}`,
    }),
    getPassportDraftLazily: builder.mutation<PassportDraft, DraftId>({
      query: id => `${baseUrl}/passport-drafts/${id}`,
    }),
    savePassportDraft: builder.mutation<
      PassportDraft,
      { id: DraftId; draft: PassportDraft }
    >({
      query: ({ id, draft }) => ({
        url: `${baseUrl}/passport-drafts/${id}`,
        method: 'PUT',
        body: draft,
      }),
    }),

    saveCompatriotDraft: builder.mutation<
      void,
      { id: DraftId; draft: CompatriotDraft }
    >({
      query: ({ id, draft }) => ({
        url: `${baseUrl}/compatriot-drafts/${id}`,
        method: 'PUT',
        body: draft,
      }),
    }),

    getLegalRepresentativeDraft: builder.query<
      LegalRepresentativeDraft,
      DraftId
    >({
      query: id => `${baseUrl}/legal-representative-drafts/${id}`,
    }),
    saveLegalRepresentativeDraft: builder.mutation<
      LegalRepresentativeDraft,
      { id: DraftId; draft: LegalRepresentativeDraft }
    >({
      query: ({ id, draft }) => ({
        url: `${baseUrl}/legal-representative-drafts/${id}`,
        method: 'PUT',
        body: draft,
      }),
    }),

    getEducationInformationDraft: builder.mutation<
      EducationInformationDraft,
      DraftId
    >({
      query: id => `${baseUrl}/education-information-drafts/${id}`,
    }),
    saveEducationInformationDraft: builder.mutation<
      EducationInformationDraft[],
      { id: DraftId; draft: EducationInformationDraft[] }
    >({
      query: ({ id, draft }) => ({
        url: `${baseUrl}/education-information-drafts/${id}`,
        method: 'PUT',
        body: draft,
      }),
    }),

    createEmptyEducationForm: builder.mutation<
      EducationInformationDraft,
      UserId
    >({
      query: userId => ({
        url: `${baseUrl}/education-information-drafts/${userId}`,
        method: 'POST',
      }),
    }),
    deleteEducationForm: builder.mutation<unknown, DraftId>({
      query: id => ({
        url: `${baseUrl}/education-information-drafts/${id}`,
        method: 'DELETE',
      }),
    }),

    getAddressesDraft: builder.query<AddressView, DraftId>({
      query: id => `${baseUrl}/addresses-drafts/${id}`,
    }),
    saveAddressesDraft: builder.mutation<
      AddressView,
      { id: DraftId; draft: AddressView }
    >({
      query: ({ id, draft }) => ({
        url: `${baseUrl}/addresses-drafts/${id}`,
        method: 'PUT',
        body: draft,
      }),
    }),

    getAdditionalFilesIds: builder.query<FileId[], UserId>({
      query: userId => `${baseUrl}/by-user/${userId}/additional-files`,
    }),

    updateAdditionalFilesIds: builder.mutation<
      void,
      {
        userId: UserId;
        files: FileId[];
      }
    >({
      query: ({ userId, files }) => ({
        url: `${baseUrl}/by-user/${userId}/additional-files`,
        method: 'PUT',
        body: files,
      }),
    }),

    getApplicationsByDocumentId: builder.query<QuestionnaireId[], number>({
      query: (documentId: number) =>
        `${baseUrl}/education-information-drafts/can-updated/${documentId}`,
    }),
  }),
});

export const {
  useCreateOrGetEmptyQuestionnaireQuery,
  useLazyCreateOrGetEmptyQuestionnaireQuery,

  useGetGeneralDataDraftMutation,
  useSaveGeneralDataDraftMutation,

  useCreateEmptyEducationFormMutation,
  useDeleteEducationFormMutation,

  useLazyGetLegalRepresentativeDraftQuery,
  useSaveLegalRepresentativeDraftMutation,

  useLazyGetPassportDraftQuery,
  useSavePassportDraftMutation,

  useLazyGetVisaRequestDraftQuery,
  useSaveVisaRequestDraftMutation,

  useSaveEducationInformationDraftMutation,
  useGetEducationInformationDraftMutation,

  useLazyGetAddressesDraftQuery,
  useSaveAddressesDraftMutation,

  useGetAdditionalFilesIdsQuery,
  useUpdateAdditionalFilesIdsMutation,

  useSaveCompatriotDraftMutation,

  useGetApplicationsByDocumentIdQuery,

  endpoints,
} = questionnaireApi;
