import React, { FC } from 'react';

import TemplateAuthForm from 'components/auth/TemplateAuthForm';
import Button from 'components/common/ui-kit/Button';
import Input from 'components/common/ui-kit/Input';
import RouteButtonLink from 'components/common/ui-kit/RouteButtonLink';
import useRequestResetPasswordForm from 'hooks/auth/useRequestResetPasswordForm';
import terms from 'i18n';

type RequestResetPasswordFormProps = {
  title: string;
};

const RequestResetPasswordForm: FC<RequestResetPasswordFormProps> = ({
  title,
}) => {
  const {
    formState: { errors },
    fields,
    onSubmit,
  } = useRequestResetPasswordForm();

  return (
    <TemplateAuthForm title={title}>
      <form onSubmit={onSubmit}>
        <div className="auth-form__body">
          <div className="auth-form__policy">
            <p className="auth-form__paragraph">
              {terms.ENTER_YOUR_EMAIL_FOR_PASSWORD_RESET}
            </p>
          </div>
          <div className="auth-form__fields">
            <Input
              placeholder={terms.EMAIL}
              className="auth-form__input"
              {...fields['email']}
              error={errors.email?.message}
            />
          </div>
        </div>
        <div className="auth-form__buttons">
          <RouteButtonLink to="/sign-in" className="auth-form__button">
            {terms.BACK}
          </RouteButtonLink>
          <Button theme="success" className="auth-form__button">
            {terms.RESET}
          </Button>
        </div>
      </form>
    </TemplateAuthForm>
  );
};

export default RequestResetPasswordForm;
