import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import useAppDispatch from 'hooks/common/useAppDispatch';
import { createToast } from 'redux/actions';

import { contractApi } from '../../redux/api/applications/contract-api';
import { staffContractsSlice } from '../../redux/slices/staff/staffContractsSlice';
import {
  useDownloadContractDocument,
  useDownloadTemplateDocument,
} from '../statements/contract/useDownloadContractDocument';

const useModeratorContractIdInfoForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [sendContractForEditing] =
    contractApi.useLazySendContractForEditingQuery();
  const [approveContract] = contractApi.useLazyApproveContractQuery();
  const [deleteContract] = contractApi.useLazyDeleteContractQuery();
  const [takeToWorkContract] = contractApi.useLazyTakeToWorkQuery();
  const [confirmContract] = contractApi.useLazyConfirmContractQuery();
  const [cancelContract] = contractApi.useLazyCancelContractQuery();

  const downloadPrintContract = useDownloadContractDocument();
  const downloadTemplate = useDownloadTemplateDocument();

  const { register, control } = useForm<{ moderator: number }>({
    mode: 'all',
  });

  const fields = {
    moderator: register('moderator'),
  };

  const reject = id =>
    deleteContract(id)
      .unwrap()
      .then(() => {
        dispatch(createToast('Договор удалён', 'success'));
        navigate('../contracts');
      });

  const sendForEditing = id =>
    sendContractForEditing(id)
      .unwrap()
      .then(result => {
        dispatch(staffContractsSlice.actions.updateContract(result));
      });

  const approve = id =>
    approveContract(id)
      .unwrap()
      .then(result => {
        dispatch(createToast('Договор одобрен', 'success'));
        dispatch(staffContractsSlice.actions.updateContract(result));
      })
      .catch(error => {
        dispatch(createToast(error.data.messageRu, 'danger'));
      });

  const takeToWork = id =>
    takeToWorkContract(id)
      .unwrap()
      .then(result => {
        dispatch(createToast('Договор взят в работу', 'success'));
        dispatch(staffContractsSlice.actions.updateContract(result));
      });

  const confirm = id =>
    confirmContract(id)
      .unwrap()
      .then(result => {
        dispatch(createToast('Договор утверждён', 'success'));
        dispatch(staffContractsSlice.actions.updateContract(result));
      });

  const cancel = id =>
    cancelContract(id)
      .unwrap()
      .then(result => {
        dispatch(createToast('Договор аннулирован', 'success'));
        dispatch(staffContractsSlice.actions.updateContract(result));
      });

  return {
    control,
    fields,
    reject,
    sendForEditing,
    approve,
    downloadPrintContract,
    downloadTemplate,
    takeToWork,
    confirm,
    cancel,
  };
};

export default useModeratorContractIdInfoForm;
