import { isRussianLocale } from '../../../i18n';
import { contractApi } from '../../../redux/api/applications/contract-api';

export const useGetContractPaymentTypesSelectProps = () => {
  const { data: paymentForms } = contractApi.useGetPaymentsTypesQuery();

  return (paymentForms ?? []).map(form => ({
    label: isRussianLocale() ? form.title : form.titleForeign,
    value: form.id,
  }));
};
