import { createSlice } from '@reduxjs/toolkit';

import { AuthCredentials } from '../../../models/auth';

const initialState: AuthCredentials = {
  accessToken: null,
  expiresIn: 0,
  refreshToken: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setAuthCredentials: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.expiresIn = action.payload.expiresIn;
      state.refreshToken = action.payload.refreshToken;
    },
    clearAuthCredentials: state => {
      state.accessToken = null;
      state.expiresIn = 0;
      state.refreshToken = null;
    },
  },
});

export const { setAuthCredentials, clearAuthCredentials } = authSlice.actions;

export default authSlice.reducer;
