import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { ContractState } from 'models/applications/contracts';

import { ChosenDirectionWithPriority } from '../../../models/applications/directions';
import { contractApi } from '../../../redux/api/applications/contract-api';
import { directionsApi } from '../../../redux/api/applications/directions-api';
import { buildContractNameFromDirection } from '../../../utils/domain/direction';

export const useGetCreateContractSelectOptions = (
  isEditing = false,
  applicationId?: number
) => {
  const { applicationId: applicationIdParam } = useParams();

  const [getDirections, { data: directions }] =
    directionsApi.useLazyGetDirectionsByApplicationIdQuery();
  const [getContracts, { data: contracts = [] }] =
    contractApi.useLazyGetByApplicationIdQuery();

  useEffect(() => {
    if (applicationId) {
      getDirections({
        applicationId: applicationId,
      });
      !isEditing && getContracts(applicationId);
    }
    if (applicationIdParam) {
      getDirections({
        applicationId: Number(applicationIdParam),
      });
      !isEditing && getContracts(Number(applicationIdParam));
    }
  }, [applicationIdParam, applicationId]);

  // TODO почему показываем направления только если уже есть контракты когда создаем новый контракт???
  if (!directions || !contracts) {
    return [];
  }

  const usedDirectionsForContracts = contracts
    .filter(c => c.state !== ContractState.CANCELLED)
    .map(c => c.applicationDirectionId);

  return [...(directions?.CONTRACT ?? [])]
    .filter(
      direction =>
        isEditing || !usedDirectionsForContracts.includes(direction.id)
    )
    .map((d: ChosenDirectionWithPriority) => ({
      label: buildContractNameFromDirection(d),
      value: d.id,
    }));
};
