import React, { FC, useCallback, useEffect } from 'react';

import { Controller, useController } from 'react-hook-form';

import Checkbox from 'components/common/ui-kit/Checkbox';
import ReactSelect from 'components/common/ui-kit/Select';
import ProfileAuth from 'components/profile/GeneralDataForm/ProfileAuth';
import ProfileImage from 'components/profile/GeneralDataForm/ProfileImage';
import ProfileForm from 'components/profile/ProfileForm';
import useAppSelector from 'hooks/common/useAppSelector';
import useProfileRoutesContext from 'hooks/profile/useProfileRoutesContext';
import useGeneralDataForm from 'hooks/questionnaire/generalDataDraft/useGeneralDataForm';
import terms from 'i18n';
import { SelectOption } from 'models/common';
import { genderSelectOptions } from 'utils/enum-select';
import {
  createCheckboxProps,
  createInputV2Props,
  createSelectProps,
} from 'utils/form-helpers';

import { useGetQuestionnaireUser } from '../../../hooks/questionnaire/common/useGetQuestionnaireUser';
import { useGetGeneralDataFormEnums } from '../../../hooks/questionnaire/generalDataDraft/useGetGeneralDataFormEnums';
import { COUNTRY_RUSSIA_ID } from '../../../models/enum';
import { UserRole } from '../../../models/user';
import { selectIsDraftReadonly } from '../../../redux/selectors/enrollee/questionnaire';
import {
  dateInRange,
  getMobileValidators,
  isValidSnils,
  normalizeSnils,
  normalizeYearTo4Characters,
} from '../../../utils/form-validators';
import regexp from '../../../utils/regexp';
import { InputV2 } from '../../common/ui-kit/Input/view';
import Loader from '../../common/ui-kit/Loader';

const GeneralDataForm: FC = () => {
  const readOnly = useAppSelector(selectIsDraftReadonly);
  const user = useGetQuestionnaireUser();
  const { onNextPageNavigate } = useProfileRoutesContext();

  const { recruiterViews, countrySelectProps, mobileCountryCodeSelectProps } =
    useGetGeneralDataFormEnums();

  const {
    hasName,
    hasLatinName,
    hasSurname,
    hasLatinSurname,
    hasPatronymic,
    hasPatronymicLatin,
    isPatronymicRequired,
    isLatinPatronymicRequired,
    hasBirthday,
    mobileCountryCode,
    hasSecondEmail,
    hasSnilsIssueDate,
    hasSnils,
    hasNoSnils,
    birthday,
    noSnils,
    noPatronymic,
    mobile,
    isRussianCitizenship,
    control,
    register,
    onSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useGeneralDataForm(onNextPageNavigate);

  const snilsController = useController({
    control,
    name: 'snils',
  });

  const snilsIssueDateController = useController({
    control,
    name: 'snilsIssuedAt',
  });

  const inputV2Props = createInputV2Props(errors, readOnly);
  const selectProps = createSelectProps(errors, readOnly);
  const checkboxProps = createCheckboxProps(errors, readOnly);

  const role = useAppSelector(state => state.user.role);

  const isCommonDisabled = useCallback(
    (param = false) =>
      (role !== UserRole.MODERATOR &&
        role !== UserRole.ADMIN &&
        role !== UserRole.INSTITUTE_STAFF &&
        role !== UserRole.SPECIALIST &&
        param) ||
      readOnly,
    [readOnly, role]
  );

  useEffect(() => {
    if (user) {
      setValue('hasPhoto', Boolean(user.photoUploadId));
      if (user.photoUploadId) {
        clearErrors('hasPhoto');
      }
    }
  }, [JSON.stringify(user)]);

  useEffect(() => {
    if (noSnils) {
      snilsController.field.onChange(null);
      snilsIssueDateController.field.onChange(null);
    }

    clearErrors('snils');
    clearErrors('snilsIssuedAt');
  }, [noSnils]);

  if (!user) {
    return <Loader />;
  }

  const isPatronymicSpecified =
    (hasPatronymic && hasPatronymicLatin) ||
    (!hasPatronymic && !hasPatronymicLatin);

  return (
    <ProfileForm
      onSubmit={
        readOnly
          ? e => {
              e.preventDefault();
              onNextPageNavigate();
            }
          : onSubmit
      }
    >
      <h3 className="profile-form__title form-title personal-data-form__title">
        {terms.YOUR_ID_IN_SYSTEM}: {user?.idWithPrefix}
      </h3>

      <div className="personal-data-form__top">
        <Controller
          name="hasPhoto"
          control={control}
          rules={{
            required: terms.PHOTO_REQUIRED,
          }}
          render={() => <ProfileImage error={errors.hasPhoto?.message} />}
        />
        {user.role === UserRole.USER && (
          <ProfileAuth className="personal-data-form__auth" />
        )}
      </div>

      <div className="profile-form__fields">
        <div className="profile-form__field">
          <Controller
            name="surname"
            control={control}
            disabled={isCommonDisabled(hasSurname)}
            rules={{
              required: {
                value: isRussianCitizenship,
                message: terms.REQUIRED_FIELD,
              },
              pattern: {
                value: regexp.ru,
                message: terms.CYRILLIC_CHARACTERS_ONLY,
              },
            }}
            render={({ field }) => (
              <InputV2
                placeholder={terms.SURNAME}
                hint={terms.CYRILLIC_CHARACTERS_ONLY}
                markRequired={isRussianCitizenship}
                {...inputV2Props(field)}
              />
            )}
          />

          {!isRussianCitizenship && (
            <Checkbox
              text={terms.NO_SURNAME}
              className="profile-form__checkbox"
              {...checkboxProps(
                register('noSurname', {
                  disabled: hasLatinSurname || hasName,
                  onChange: ({ target: { checked } }) => {
                    if (checked) {
                      clearErrors('surname');
                      clearErrors('surnameLatin');
                      !hasSurname && setValue('surname', undefined);
                      !hasLatinSurname && setValue('surnameLatin', undefined);
                    }
                  },
                })
              )}
              disabled={isCommonDisabled(hasSurname || hasLatinSurname)}
            />
          )}
        </div>

        <Controller
          name="surnameLatin"
          control={control}
          disabled={isCommonDisabled(hasLatinSurname)}
          rules={{
            required: {
              value: !isRussianCitizenship,
              message: terms.REQUIRED_FIELD,
            },
            pattern: {
              value: regexp.en,
              message: terms.LATIN_CHARACTERS_ONLY,
            },
          }}
          render={({ field }) => (
            <InputV2
              className="profile-form__field"
              placeholder={terms.SURNAME_IN_LATIN}
              markRequired={!isRussianCitizenship}
              {...inputV2Props(field)}
            />
          )}
        />

        <Controller
          name="name"
          control={control}
          disabled={isCommonDisabled(hasName)}
          rules={{
            required: {
              value: isRussianCitizenship,
              message: terms.REQUIRED_FIELD,
            },
            pattern: {
              value: regexp.ru,
              message: terms.CYRILLIC_CHARACTERS_ONLY,
            },
          }}
          render={({ field }) => (
            <InputV2
              className="profile-form__field"
              placeholder={terms.NAME}
              hint={terms.CYRILLIC_CHARACTERS_ONLY}
              markRequired={isRussianCitizenship}
              {...inputV2Props(field)}
            />
          )}
        />

        <Controller
          name="nameLatin"
          control={control}
          disabled={isCommonDisabled(hasLatinName)}
          rules={{
            required: {
              value: !isRussianCitizenship,
              message: terms.REQUIRED_FIELD,
            },
            pattern: {
              value: regexp.en,
              message: terms.LATIN_CHARACTERS_ONLY,
            },
          }}
          render={({ field }) => (
            <InputV2
              className="profile-form__field"
              placeholder={terms.NAME_IN_LATIN}
              markRequired={!isRussianCitizenship}
              {...inputV2Props(field)}
            />
          )}
        />

        <div className="profile-form__field">
          <Controller
            name="patronymic"
            control={control}
            rules={{
              required: {
                value: isPatronymicRequired,
                message: terms.REQUIRED_FIELD,
              },
              pattern: {
                value: regexp.ru,
                message: terms.CYRILLIC_CHARACTERS_ONLY,
              },
            }}
            render={({ field }) => (
              <InputV2
                placeholder={terms.PATRONYMIC}
                hint={terms.CYRILLIC_CHARACTERS_ONLY}
                markRequired={isPatronymicRequired}
                {...inputV2Props(field)}
                disabled={
                  isCommonDisabled(isPatronymicSpecified || hasPatronymic) ||
                  noPatronymic
                }
              />
            )}
          />
          <Checkbox
            text={terms.NO_PATRONYMIC}
            className="profile-form__checkbox"
            // TODO error when only patronymicLatin specified
            {...checkboxProps(
              register('noPatronymic', {
                onChange: ({ target: { checked } }) => {
                  if (checked) {
                    clearErrors('patronymic');
                    clearErrors('patronymicLatin');
                    !hasPatronymic && setValue('patronymic', undefined);
                    !hasPatronymicLatin &&
                      setValue('patronymicLatin', undefined);
                  }
                },
              })
            )}
            disabled={isCommonDisabled(true)}
          />
        </div>

        <Controller
          name="patronymicLatin"
          control={control}
          rules={{
            required: {
              value: isLatinPatronymicRequired,
              message: terms.REQUIRED_FIELD,
            },
            pattern: { value: regexp.en, message: terms.LATIN_CHARACTERS_ONLY },
          }}
          render={({ field }) => (
            <InputV2
              className="profile-form__field"
              placeholder={terms.PATRONYMIC_IN_LATIN}
              markRequired={isLatinPatronymicRequired}
              {...inputV2Props(field)}
              disabled={
                isCommonDisabled(isPatronymicSpecified || hasPatronymicLatin) ||
                noPatronymic
              }
            />
          )}
        />

        <Controller
          name="gender"
          control={control}
          disabled={isCommonDisabled()}
          rules={{ required: terms.REQUIRED_FIELD }}
          render={({ field }) => (
            <ReactSelect<SelectOption>
              placeholder={terms.GENDER}
              className="profile-form__field"
              markRequired
              {...selectProps(field, { options: genderSelectOptions })}
            />
          )}
        />

        <Controller
          name="birthday"
          control={control}
          disabled={isCommonDisabled(hasBirthday)}
          rules={{
            required: terms.REQUIRED_FIELD,
            validate: value => dateInRange(value, 14, 100) || terms.DATE_RANGE,
            onChange: ({ target: { value } }) =>
              value && setValue('birthday', normalizeYearTo4Characters(value)),
          }}
          render={({ field }) => (
            <InputV2
              className="profile-form__field"
              placeholder={terms.DATE_OF_BIRTH}
              markRequired
              type="date"
              {...inputV2Props(field)}
            />
          )}
        />

        <div className="profile-form__field profile-form__field--container">
          <Controller
            name="mobileCountryCode"
            control={control}
            disabled={isCommonDisabled()}
            rules={{
              required: terms.REQUIRED_FIELD,
              onChange: ({ target: { value } }) =>
                value && clearErrors('mobile'),
            }}
            render={({ field }) => (
              <ReactSelect
                placeholder={terms.COUNTRY_CODE}
                className="profile-form__input profile-form__input--fb-33"
                styles={{ menu: base => ({ ...base, minWidth: '300px' }) }}
                markRequired
                {...selectProps(field, mobileCountryCodeSelectProps)}
              />
            )}
          />

          <Controller
            name="mobile"
            control={control}
            disabled={isCommonDisabled()}
            rules={{
              required: terms.REQUIRED_FIELD,
              ...getMobileValidators(mobileCountryCode),
              onChange: ({ target: { value } }) =>
                setValue(
                  'mobile',
                  isRussianCitizenship
                    ? value?.slice(0, 10)
                    : value?.slice(0, 11)
                ),
            }}
            render={({ field }) => (
              <InputV2
                className="profile-form__input profile-form__input--fb-66"
                placeholder={terms.MOBILE_PHONE}
                markRequired
                type="number"
                {...inputV2Props(field)}
              />
            )}
          />
        </div>

        <Controller
          name="primaryCitizenshipCountryId"
          control={control}
          disabled={isCommonDisabled()}
          rules={{
            required: terms.REQUIRED_FIELD,
            onChange: ({ target: { value } }) => {
              if (value) {
                clearErrors();
              }

              if (value === COUNTRY_RUSSIA_ID) {
                setValue('noSnils', false);
              }

              setValue('mobile', mobile?.slice(0, 10));
            },
          }}
          render={({ field }) => (
            <ReactSelect
              placeholder={terms.CITIZENSHIP}
              markRequired
              className="profile-form__field"
              {...selectProps(field, countrySelectProps)}
            />
          )}
        />

        <Controller
          name="secondEmail"
          control={control}
          disabled={isCommonDisabled(hasSecondEmail)}
          rules={{
            pattern: { value: regexp.email, message: terms.VALID_EMAIL },
          }}
          render={({ field }) => (
            <InputV2
              className="profile-form__field"
              placeholder={terms.ADDITIONAL_EMAIL_IF_ANY}
              {...inputV2Props(field)}
            />
          )}
        />

        <div className="profile-form__field">
          <Controller
            name="snils"
            control={control}
            disabled={isCommonDisabled(hasSnils) || (noSnils ?? hasNoSnils)}
            rules={{
              required: {
                value: !noSnils,
                message: terms.REQUIRED_FIELD,
              },
              validate: value => {
                return value && value.length >= 14
                  ? isValidSnils(normalizeSnils(value))
                    ? true
                    : terms.SNILS_INVALID
                  : true;
              },
              onChange: ({ target: { value } }) =>
                value && setValue('snils', normalizeSnils(value)),
            }}
            render={({ field }) => (
              <InputV2
                placeholder={terms.SNILS}
                markRequired={!noSnils}
                {...inputV2Props(field)}
              />
            )}
          />
          <Checkbox
            text={terms.NO_SNILS}
            className="profile-form__checkbox"
            {...checkboxProps(
              register('noSnils', {
                disabled: isCommonDisabled(hasNoSnils),
              })
            )}
          />
        </div>

        <Controller
          name="snilsIssuedAt"
          control={control}
          disabled={
            isCommonDisabled(hasSnilsIssueDate) || (noSnils ?? hasNoSnils)
          }
          rules={{
            validate: value => {
              if (value) {
                if (birthday) {
                  if (new Date(birthday) < new Date(value)) {
                    setValue(
                      'snilsIssuedAt',
                      normalizeYearTo4Characters(value)
                    );
                  } else if (new Date(value).getFullYear() > 1960) {
                    return terms.ERROR_SNILS_ISSUE_BEFORE_BIRTHDAY;
                  }
                } else {
                  setValue('snilsIssuedAt', undefined);
                  return terms.ERROR_FIRST_SPECIFY_BIRTHDAY;
                }
              }
            },
          }}
          render={({ field }) => (
            <InputV2
              className="profile-form__field"
              placeholder={terms.DATE_OF_ISSUE_SNILS}
              type="date"
              {...inputV2Props(field)}
            />
          )}
        />

        {!isRussianCitizenship && (
          <Controller
            name="recruiter"
            control={control}
            disabled={isCommonDisabled()}
            render={({ field }) => (
              <ReactSelect
                isClearable
                placeholder={terms.RECRUITING_COMPANY}
                className="profile-form__field"
                {...selectProps(field, {
                  options: recruiterViews,
                })}
                onChange={e => {
                  field.onChange(e?.value ?? null);
                }}
              />
            )}
          />
        )}
      </div>
    </ProfileForm>
  );
};

export default GeneralDataForm;
