import { useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { useSetEpguManualSyncMutation } from '../../../redux/api/staff/staff-applications-api';

export type ModeratorEpguInfoForm = {
  epguManualSyncRequired: boolean;
};

const useModeratorEpguInfoForm = (
  applicationId: number,
  manualSyncRequired: boolean
) => {
  const { register, setValue, ...rest } = useForm<ModeratorEpguInfoForm>();

  const [setEpguManualSync] = useSetEpguManualSyncMutation();

  useEffect(() => {
    setValue('epguManualSyncRequired', manualSyncRequired);
  }, [manualSyncRequired]);

  const fields = {
    epguManualSyncRequired: register('epguManualSyncRequired', {
      onChange: ({ target: { checked } }) => {
        setEpguManualSync({
          applicationId: applicationId,
          epguManualSyncRequired: checked,
        });
      },
    }),
  };

  return { fields, ...rest };
};

export default useModeratorEpguInfoForm;
