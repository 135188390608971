import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserData, UserId, UserRole } from '../../../models/user';
import { getViewerProfileImage } from '../../actions';

type UserInitialState = Omit<UserData, 'id'> & { id: UserId | null };

const initialState: UserInitialState = {
  activated: false,
  archived: false,
  blocked: false,
  email: '',
  id: null,
  idWithPrefix: '',
  isPasswordTemporary: false,
  role: UserRole.USER, // annoying legacy
  username: '',
  photoUploadId: undefined,
  createdAt: '',
  prefLang: '',
  profileImageBase64: null,
  canChooseBachelor: null,
  isRussian: false,
  epguId: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserData>) => {
      Object.assign(state, action.payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getViewerProfileImage.fulfilled, (state, action) => {
        state.profileImageBase64 = action.payload.photo;
      })
      .addCase(getViewerProfileImage.rejected, state => {
        state.profileImageBase64 = null;
      });
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
