import { UserRole } from '../../../models/user';
import { useGetStaffPageableQuery } from '../../../redux/api/staff/staff-api';
import { selectUserRole } from '../../../redux/selectors';
import useAppSelector from '../../common/useAppSelector';

export const useGetCoordinatorsSelectProps = () => {
  const role = useAppSelector(selectUserRole);

  const { data: coordinators } = useGetStaffPageableQuery(
    {
      pageSize: 1000,
      pageNumber: 1,
      roles: [UserRole.COORDINATOR],
    },
    {
      skip: !role || role === UserRole.EXPERT,
    }
  );

  return (coordinators?.content ?? []).map(coordinator => ({
    label: `${coordinator.name} ${coordinator.surname} ${
      coordinator.patronymic ?? ''
    }`,
    value: coordinator.userId,
  }));
};
