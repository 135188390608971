import {
  DateString,
  DateTimeString,
  LocalizedEntity,
  LocalizedEntityWithExternalIdAndShortTitle,
} from 'models/common';
import { EducationBasis } from 'models/staff/upload-queue';

import { EducationLevel } from '../enum';
import { UserId, UserRole } from '../user';
import { ApplicationUserEducationalInformation } from './application-user-educational-information';
import {
  ApplicationAdmissionCondition,
  ApplicationCheckStatus,
  ApplicationEducationLevel,
  ApplicationFlag,
  ApplicationState,
  Trajectory,
} from './enum';

export type ApplicationId = number;

export enum DirectionRequestType {
  ALL = 'ALL',
  BUDGET = 'BUDGET',
  CONTRACT = 'CONTRACT',
}

export type AgreementResponse = {
  id: number;
  applicationDirectionId: number;
  isAgreed: boolean;
  documentId?: number;
  updatedAt: DateTimeString;
};

export type ApplicationView = {
  formattedId: string;
  id: ApplicationId;
  userId: UserId;
  createdAt: DateString;
  updatedAt: DateString;
  trajectory: Trajectory;
  educationLevel: ApplicationEducationLevel;
  educationBasis?: EducationBasis;
  educationalProfile: ApplicationUserEducationalInformation;
  state: ApplicationState;
  flags: ApplicationFlag[];
  hasOriginalDocuments: boolean;
  originalStateChangedAt: DateTimeString;
  checkmarked: boolean;
  createdFromEpgu: boolean;
};

export enum OriginalEducationDocumentSubmissionType {
  PHYSICAL = 'PHYSICAL',
  ELECTRONIC_COPY = 'ELECTRONIC_COPY',
  SIGNED_PHYSICAL_COPY = 'SIGNED_PHYSICAL_COPY',
}

export type OriginalEducationDocumentSubmissionRequest = {
  type: OriginalEducationDocumentSubmissionType;
};

export type OriginalEducationDocumentSubmission = {
  id: number;
  applicationId: number;
  isSubmitted: boolean;
  isFromEpgu: boolean;
  submittedAt: DateTimeString;
  withdrawnAt: DateTimeString;
  type: OriginalEducationDocumentSubmissionType;
};

export type CreateApplicationRequest = {
  userId: UserId;
  trajectory: Trajectory;
  educationLevel: ApplicationEducationLevel;
  educationalProfileId: number;
  educationBasis?: 'BUDGET' | 'CONTRACT';
  publicationsAmount?: number;
  inventionsAmount?: number;
};

export type AddDirectionsToApplication = {
  priority: number;
  directionId: number;
}[];

export type SetDesiredDirectionRequest = {
  applicationId: number;
  desiredDirection: string;
  directionEducationLevelId: number;
};

export type GetDesiredDirectionsResponse = {
  applicationId: number;
  desiredDirection: string;
  educationLevel: {
    id: number;
    title: string;
    titleForeign: string;
    externalId: string;
  };
};

export type EpguInfo = {
  createdFromEpgu: boolean;
  epguManualSyncRequired: boolean;
  changedAtEpguTimestamp?: string;
  changedAtEpguComment?: string;
  reasonRejectExternalId?: number;
  epguId?: string;
  epguJwtId?: number;
};

export type EpguRejectReason = {
  id: number;
  externalId: number;
  name: string;
};

/**
 * @deprecated use
 */
export type ExternalEntityNamedResponse = {
  readonly id: number;
  readonly title: string;
  readonly titleForeign: string;
  readonly externalId: string;
};

export enum ApplicationUploadStatus {
  NOT_UPLOADED = 'NOT_UPLOADED', // Не выгружено в 1С
  UPLOAD_ERROR = 'UPLOAD_ERROR', // Выгружено с ошибкой
  UPLOADED = 'UPLOADED', // Выгружено в 1С
  WAITING = 'WAITING', // Выгружено, в ожидании
}

export enum ApplicationChecklistNames {
  EDUCATIONAL_INFORMATION = 'EDUCATIONAL_INFORMATION', // Сведения об образовании (Статус проверки – не подтвержден)
  PRIVILEGE = 'PRIVILEGE', // Отличительные признаки (если есть хотя бы один не подтвержденный есть)
  PRIVILEGE_DIRECTION_NOT_ADMITTED = 'PRIVILEGE_DIRECTION_NOT_ADMITTED', // Льготная конкурсная группа не была загружена в 1с при попытке допустить заявление
  SCHOOL_TEST = 'SCHOOL_TEST', // Результаты ЕГЭ (не проверен хотя бы один)
  ENTRANCE_TESTS_MISSING = 'ENTRANCE_TESTS_MISSING', // «Не сформирован набор ВИ» (ЕГЭ отсутствует полностью и нет набора ВИ)
  ENTRANCE_TEST_NOT_SATISFIED = 'ENTRANCE_TEST_NOT_SATISFIED', // «Не подходящий набор ВИ» (была попытка допустить заявление, но все или часть КГ не подходит под набор ЕГЭ/ВИ)
  DIRECTION = 'DIRECTION', // Направления поступления (Статус проверки – не подтвержден)
  GOVERNMENT_CONTRACT = 'GOVERNMENT_CONTRACT', // Целевой договор (Статус проверки – не подтвержден, если есть хотя бы одна целевая КГ)
  GOVERNMENT_CONTRACT_MISSING = 'GOVERNMENT_CONTRACT_MISSING', // Присутствует Целевые КГ, которые не были загружены в 1с при попытке допуска заявления
}

export enum OriginalDocumentSubmissionStatus {
  PHYSICAL = 'PHYSICAL', // Бумажный оригинал – абитуриенты, у которых проставлен атрибут «Бумажный оригинал»
  ELECTRONIC_COPY = 'ELECTRONIC_COPY', // Электронная копия – абитуриенты, у которых проставлен атрибут «Электронная копия»
  SIGNED_PHYSICAL_COPY = 'SIGNED_PHYSICAL_COPY', // Заверенная бумажная копия - абитуриенты, у которых проставлен атрибут «Заверенная бумажная копия»
  NO_DOCUMENT = 'NO_DOCUMENT', // Нет - абитуриенты, у которых проставлен атрибут «Нет» или не указано ни одно значение
}

export type ApplicationPageableRequest = {
  readonly readerRole?: UserRole;
  readonly userId?: number;
  readonly lastName?: string;
  readonly firstName?: string;
  readonly middleName?: string;
  readonly email?: string;
  readonly directionPaymentFormId?: number[];
  readonly directionEducationFormId?: number[];
  readonly citizenship?: string[];
  readonly applicationState?: ApplicationState[];
  readonly applicationFlags?: ApplicationFlag[];
  readonly applicationExpertStatus?: ApplicationFlag[];
  readonly applicationCoordinatorStatus?: ApplicationFlag[];
  readonly unreadMessages?: boolean;
  readonly directionName?: string;
  readonly recruitedIds?: number[];
  readonly applicationEducationLevel?: EducationLevel[];
  readonly applicationTrajectory?: Trajectory[];
  readonly educationProgram?: number[];
  readonly facultyIds?: number[];
  readonly creationDate?: string;
  readonly moderatorIds?: number[];
  readonly stateEditedByUser?: boolean;
  readonly applicationAdmissionCondition?: ApplicationAdmissionCondition[];
  readonly uploadStatus?: ApplicationUploadStatus[];
  readonly checklistFilters?: ApplicationChecklistNames[];
  readonly originalDocumentSubmissionStatus?: OriginalDocumentSubmissionStatus[];
};

export type ApplicationByStaff = {
  readonly id: number;
  readonly fullName: string;
  readonly citizenshipId: number;
  readonly moderatorId?: number;
  readonly expertId?: number;
  readonly formattedId?: string;
  readonly coordinatorId?: number;
  readonly unreadMessages: number;
  readonly state: ApplicationState;
  readonly applicationNumber: number;
  readonly flags: ApplicationFlag[];
  readonly createdAt: string;
  readonly userId: number;
  readonly userIdWithPrefix: number;
  readonly userCreatedAt: DateString;
  readonly userExternalId?: number;
  readonly updatedAt: string;
  readonly uploadDate: string;
  readonly recruiterId?: number;
  readonly educationLevel: ApplicationEducationLevel;
  readonly faculty?: LocalizedEntityWithExternalIdAndShortTitle;
  readonly direction?: string;
  readonly educationBasis?: EducationBasis;
  readonly educationProgram?: string;
  readonly directionEducationForm?: LocalizedEntity;
  readonly directionPaymentForm?: LocalizedEntity;
  readonly expertStatus?: ApplicationFlag;
  readonly coordinatorStatus?: ApplicationFlag;
  readonly hasOriginalDocuments: boolean;
  readonly comment?: string;
  readonly epguInfo?: EpguInfo;
  readonly trajectory?: Trajectory;
  readonly uploadError?: string;
  readonly externalId?: string;
  readonly educationProfileId?: number;
  readonly originalStateChangedAt?: DateTimeString;
  readonly undefinedFields?: string;
};

export type ApplicationPages = {
  readonly page: number;
  readonly pageSize: number;
  readonly totalPages: number;
  readonly totalElements: number;
  readonly result: ApplicationByStaff[];
};

export type ApplicationCheckList = {
  applicationId?: number;
  readonly unrelatedCheckMarks: {
    'basic-data': ApplicationCheckStatus;
    'legal-representative': ApplicationCheckStatus;
    'passport-data': ApplicationCheckStatus;
    visa: ApplicationCheckStatus;
    'registration-address': ApplicationCheckStatus;
    'actual-address': ApplicationCheckStatus;
    'educational-background': ApplicationCheckStatus;
    'exam-results': ApplicationCheckStatus;
    directions: ApplicationCheckStatus;
    'target-contract': ApplicationCheckStatus;
  };
  readonly olympiadCheckMarks: [
    {
      id: number;
      status: ApplicationCheckStatus;
    }
  ];
  readonly achievementsCheckMarks: [
    {
      id: number;
      status: ApplicationCheckStatus;
    }
  ];
  readonly prerogativeCheckMarks: [
    {
      id: number;
      status: ApplicationCheckStatus;
    }
  ];
  readonly benefitCheckMarks: [
    {
      id: number;
      status: ApplicationCheckStatus;
    }
  ];
  readonly specialQuotaCheckMarks: [
    {
      id: number;
      status: ApplicationCheckStatus;
    }
  ];
  readonly priorityRightCheckMarks: [
    {
      id: number;
      status: ApplicationCheckStatus;
    }
  ];
};

export type ApplicationExpertExaminationReferral = {
  readonly hasIdentificationDocument: boolean;
  readonly hasEducationalDocument: boolean;
  readonly hasEducationGraduationDocuments?: boolean;
  readonly hasEducationDiploma?: boolean;
  readonly hasEducationalDocumentApplication: boolean;
  readonly hasEducationalDocumentsTranslation: boolean;
};

export enum EducationSystemStatus {
  PART_OF_EDUCATION_SYSTEM_OF_DOCUMENT_ISSUER_COUNTRY = 'PART_OF_EDUCATION_SYSTEM_OF_DOCUMENT_ISSUER_COUNTRY',
  PART_OF_EDUCATION_SYSTEM = 'PART_OF_EDUCATION_SYSTEM',
  NOT_PART_OF_EDUCATION_SYSTEM = 'NOT_PART_OF_EDUCATION_SYSTEM',
}

type EducationLevelInfo = {
  checked: boolean;
  comment?: string;
};

export type EducationLevelsInfo = {
  preparatory: EducationLevelInfo;
  secondaryBasic: EducationLevelInfo;
  secondaryVocational: EducationLevelInfo;
  bachelor: EducationLevelInfo;
  speciality: EducationLevelInfo;
  master: EducationLevelInfo;
  phd: EducationLevelInfo;
};

export type ApplicationExpertExaminationResult = {
  readonly registrationNumber: string;
  readonly applicantFullName: string;
  readonly educationCountryName: string;
  readonly educationSystemStatus?: EducationSystemStatus;
  readonly educationSystemStatusComment?: string;
  readonly needNegotiations?: boolean;
  readonly educationLevelsInfo: EducationLevelsInfo;
  readonly disciplineTransferAllowed: boolean;
  readonly comments: string;
  readonly formationDate: DateString;
};

export enum CompetitiveGroupStatus {
  NEW = 'NEW', // Новое
  RECEIVED_BY_UNIVERSITY = 'RECEIVED_BY_UNIVERSITY', // Получено вузом
  ADDITIONAL_INFORMATION_REQUIRED = 'ADDITIONAL_INFORMATION_REQUIRED', // Запрос дополнительных сведений
  UNDER_CONSIDERATION = 'UNDER_CONSIDERATION', // На рассмотрени
  PROFILE_EDITING = 'PROFILE_EDITING', // Редактирование профиля
  EXAM_DATE_ASSIGNED = 'EXAM_DATE_ASSIGNED', // Назначена дата ВИ
  EXAM_DATE_SELECTION = 'EXAM_DATE_SELECTION', // Выбор даты ВИ
  ON_COMPETITION = 'ON_COMPETITION', // Участвует в конкурсе
  AGREEMENT = 'AGREEMENT', // Согласие
  COMPETITION_FAILED = 'COMPETITION_FAILED', // Не прошло по конкурсу
  INCLUDED_IN_ENROLLMENT_ORDER = 'INCLUDED_IN_ENROLLMENT_ORDER', // Включено в приказ на зачисление
  REFUSED = 'REFUSED', // Отклонено
  AGREEMENT_REVOKED = 'AGREEMENT_REVOKED', // Отзыв согласия
  ENROLLMENT_REFUSED = 'ENROLLMENT_REFUSED', // Отказ от зачисления
  REVOKED = 'REVOKED', // Отозвано
  AGREEMENT_EDUCATION_DOCUMENT = 'AGREEMENT_EDUCATION_DOCUMENT', // Согласие/Документ об образовании
  AGREEMENT_EDUCATION_DOCUMENT_REVOKED = 'AGREEMENT_EDUCATION_DOCUMENT_REVOKED', // Отзыв согласие/Документ об образовании
  ENROLLED = 'ENROLLED', // Зачислен
}
