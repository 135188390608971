import React, { createContext, useContext } from 'react';

import { profilePageRoutes } from 'config/routes/profile-page-routes';
import useProvideProfileRoutes from 'hooks/profile/useProvideProfileRoutes';

type ProfileRoutesContextProps = ReturnType<typeof useProvideProfileRoutes>;

const ProfileRoutesContext = createContext<ProfileRoutesContextProps>(
  {} as ProfileRoutesContextProps
);

export const ProfileRoutesContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <ProfileRoutesContext.Provider
      value={useProvideProfileRoutes(profilePageRoutes)}
    >
      {children}
    </ProfileRoutesContext.Provider>
  );
};

export default () => {
  return useContext(ProfileRoutesContext);
};
