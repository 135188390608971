import {
  DownloadFileRequest,
  FileId,
  FileResponse,
  PdfizeRequest,
  PreviewFilesRequest,
  UploadFileRequest,
  UploadFilesRequest,
} from 'models/files';
import { apiSlice } from 'redux/api/api-slice';
import { filesManagerClient } from 'utils/axios-clients';

import { DocumentTemplateType } from '../../../models/applications/application-documents';

const baseUrl = `${process.env.REACT_APP_FILES_MANAGER_API_URL}`;

export const filesApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    uploadFile: builder.mutation<FileId, UploadFileRequest>({
      query: ({ userId, file, fileName }) => {
        const data = new FormData();
        data.append('file', file);
        return {
          url: `${baseUrl}/upload/${userId}`,
          method: 'POST',
          body: data,
          params: {
            fileName: fileName,
          },
        };
      },
    }),

    uploadFiles: builder.mutation<FileId[], UploadFilesRequest>({
      query: ({ userId, files }) => {
        const data = new FormData();
        files.forEach(file => data.append('files', file));
        return {
          url: `${baseUrl}/upload/list/${userId}`,
          method: 'POST',
          body: data,
        };
      },
    }),

    pdfize: builder.mutation<FileId, PdfizeRequest>({
      query: ({ userId, files, fileName }) => {
        const data = new FormData();
        files.forEach(file => data.append('files', file));
        return {
          url: `${baseUrl}/upload/pdfize/${userId}`,
          method: 'POST',
          body: data,
          params: {
            fileName,
          },
        };
      },
    }),

    downloadFile: builder.query<Blob, DownloadFileRequest>({
      query: ({ userId, fileId }) => ({
        url: `${baseUrl}/download/${userId}/${fileId}`,
        method: 'GET',
        responseHandler: response => response.blob(),
      }),
    }),

    previewFiles: builder.query<FileResponse[], PreviewFilesRequest>({
      query: ({ userId, ids: id }) => ({
        url: `${baseUrl}/preview`,
        params: {
          userId,
          id,
        },
        method: 'GET',
      }),
    }),

    /**
     * @deprecated Кэширует целые файлы, что в случае особенно больших файлов крашит браузер
     * Не даёт получить имя файла из Content-Disposition
     */
    downloadTemplate: builder.query<Blob, DocumentTemplateType>({
      query: docType => ({
        url: `${baseUrl}/templates/${docType}/download`,
        responseHandler: response => response.blob(),
      }),
    }),

    uploadTemplate: builder.mutation<
      number,
      { type: DocumentTemplateType; file: Blob }
    >({
      query: ({ type, file }) => {
        const data = new FormData();
        data.append('file', file);
        return {
          url: `${baseUrl}/templates/${type}/upload`,
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useUploadFileMutation,

  useDownloadFileQuery,
  useLazyDownloadFileQuery,

  usePreviewFilesQuery,
  useLazyPreviewFilesQuery,

  useLazyDownloadTemplateQuery,
  useUploadTemplateMutation,
} = filesApi;

export const downloadDocumentTemplate = (
  docType: DocumentTemplateType
): Promise<{
  fileName: string | undefined;
  blob: Blob;
}> => {
  return filesManagerClient
    .get(`/templates/${docType}/download`, {
      responseType: 'blob',
    })
    .then(res => {
      const fileName: string | undefined =
        res.headers.contentDisposition?.split("filename*=UTF-8''")?.[1];
      return {
        fileName: fileName && decodeURIComponent(fileName),
        blob: res.data,
      };
    });
};

export const downloadFileWithName = (
  userId: number,
  fileId: number
): Promise<{
  fileName: string | undefined;
  blob: Blob;
}> => {
  return filesManagerClient
    .get(`/download/${userId}/${fileId}`, {
      responseType: 'blob',
    })
    .then(res => {
      const fileName: string | undefined =
        res.headers.contentDisposition?.split("filename*=UTF-8''")?.[1];
      return {
        fileName: fileName && decodeURIComponent(fileName),
        blob: res.data,
      };
    });
};
