import useAppDispatch from 'hooks/common/useAppDispatch';
import { DocumentTemplateType } from 'models/applications/application-documents';
import { createToast } from 'redux/actions';
import { downloadDocumentTemplate } from 'redux/api/common/files-api';
import { downloadFile } from 'utils/common';

export const useDownloadDocumentTemplateType = () => {
  const dispatch = useAppDispatch();
  return (
    documentTemplateType: DocumentTemplateType,
    defaultFileName: string
  ) => {
    downloadDocumentTemplate(documentTemplateType)
      .then(({ fileName, blob }) => {
        downloadFile(new File([blob], fileName ?? defaultFileName));
      })
      .catch(() => {
        dispatch(
          createToast(
            'Во время скачивания шаблона произошла ошибка. Возможно, он ещё не был загружен',
            'danger'
          )
        );
      });
  };
};
