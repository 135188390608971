import { useGetRecruitersQuery } from '../../../redux/api/questionnaire/recruiter-api';

export const useGetRecruiters = () => {
  const { data: recruiters } = useGetRecruitersQuery();

  return (recruiters ?? []).map(r => ({
    value: r.id,
    label: r.eacTitle ?? r.title,
  }));
};
