import { useEffect, useState } from 'react';

import {
  COUNTRY_BELARUS_ID,
  COUNTRY_KAZAKHSTAN_ID,
  COUNTRY_TAJIKISTAN_ID,
  COUNTRY_KYRGYZSTAN_ID,
} from '../../../../models/enum';
import { useGeneralDataDraft } from './useGeneralDataDraft';

export const useIsCeaEnrollee = () => {
  const { draft: generalDataDraft } = useGeneralDataDraft();
  const [isCeaEnrollee, setIsCeaEnrollee] = useState(false);

  useEffect(() => {
    const countryId = generalDataDraft?.primaryCitizenshipCountry?.id;
    if (
      countryId === COUNTRY_BELARUS_ID ||
      countryId === COUNTRY_KAZAKHSTAN_ID ||
      countryId === COUNTRY_TAJIKISTAN_ID ||
      countryId === COUNTRY_KYRGYZSTAN_ID
    ) {
      setIsCeaEnrollee(true);
    }
  }, [generalDataDraft]);

  return {
    isCeaEnrollee,
    generalDataDraft,
  };
};
