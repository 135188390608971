import { useEffect, useState } from 'react';

import { filesApi } from '../../redux/api/common/files-api';
import { blobToBase64 } from '../../utils/common';

export const useGetUserAvatar = (userId?: number, imageId?: number) => {
  const [image, setImage] = useState<string | null>(null);

  const { data, isLoading } = filesApi.useDownloadFileQuery(
    {
      userId: userId!,
      fileId: imageId!,
    },
    {
      skip: !userId || !imageId,
    }
  );

  useEffect(() => {
    if (!imageId || !data) {
      setImage(null);
      return;
    }
    blobToBase64(data).then(base64Image => setImage(base64Image));
  }, [data, imageId]);

  return { image, isLoading };
};
