import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import useAppDispatch from 'hooks/common/useAppDispatch';
import terms, { isRussianLocale, locale } from 'i18n';
import { SelectOption } from 'models/common';
import { createToast, hideModal, showModal } from 'redux/actions';

import { EntranceTest } from '../../../models/applications/entrance-tests';
import { entranceTestsApi } from '../../../redux/api/applications/entrance-tests-api';
const useEntranceExamsRowForm = (test: EntranceTest, applicationId: number) => {
  const [canPassExam, setCanPassExam] = useState(false);
  const dispatch = useAppDispatch();

  const [setTime] = entranceTestsApi.useSetTimeToEntranceTestMutation();
  const [deleteTime] = entranceTestsApi.useDeleteTimeOfEntranceTestMutation();
  const [getTestDates, { data: availableDates }] =
    entranceTestsApi.useLazyGetEntranceTestDatesQuery();

  const { register, control, setValue, handleSubmit, watch } = useForm<{
    dateTime: SelectOption;
  }>({
    mode: 'all',
  });

  const onDateTimeClear = event => {
    event.preventDefault();
    dispatch(
      showModal({
        name: 'CLEAR_EXAM_DATETIME_MODAL',
        data: {
          onApprove: () => {
            deleteTime({ testId: test.id })
              .unwrap()
              .then(() => {
                setValue('dateTime', {});
              })
              .catch(() =>
                dispatch(createToast(terms.EXAM_DATE_DELETE_ERROR, 'danger'))
              )
              .finally(() => {
                dispatch(hideModal());
              });
          },
          testTitle: isRussianLocale()
            ? test.subject.title
            : test.subject.titleForeign,
          testDateTime: watch('dateTime').label,
          testScore: test.score,
        },
      })
    );
  };

  useEffect(() => {
    getTestDates({
      testId: test.subject.id,
      applicationId: Number(applicationId),
    });
  }, []);

  useEffect(() => {
    if (availableDates && test?.scheduleId && test?.datetime) {
      const option = availableDates.find(date => date.id === test.scheduleId);

      if (option) {
        setValue('dateTime', {
          label: `${option.testDate} ${option.testStart}`,
          value: option.id,
        });

        if (test?.moodleLink && !test.testEnded) {
          setCanPassExam(true);
        } else {
          setCanPassExam(false);
        }
      }
    }
  }, [availableDates, test]);

  const fields = {
    dateTime: register('dateTime', {
      onChange: ({ target: { value } }) => {
        setValue('dateTime', value);
        onSubmit();
      },
    }),
  };

  const onSubmit = handleSubmit(data => {
    setTime({
      testId: test.id,
      scheduleId: data.dateTime.value,
    })
      .unwrap()
      .then(() =>
        dispatch(
          createToast(terms.ENTRANCE_EXAM_DATE_CHANGE_SUCCESS, 'success', null)
        )
      )
      .catch(() =>
        dispatch(createToast(terms.EXAM_DATE_CHANGE_ERROR, 'danger'))
      );
  });

  return {
    fields,
    control,
    availableDatesSelectOptions: (availableDates ?? [])
      .filter(date => date.allowed)
      .map(date => ({
        label: Intl.DateTimeFormat(locale, {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        }).format(new Date(`${date.testDate} ${date.testStart}`)),
        value: date.id,
      })),
    hasExamDate: watch('dateTime'),
    canPassExam: watch('dateTime') && canPassExam,
    handleDateTimeClear: onDateTimeClear,
  };
};

export default useEntranceExamsRowForm;
