import React from 'react';

import { NavLink, useParams } from 'react-router-dom';

import useAppDispatch from 'hooks/common/useAppDispatch';
import { createToast } from 'redux/actions';

import Button from '../../../../components/common/ui-kit/Button';
import DirectionsTrainingElementStatement from '../../../../components/statements/DirectionsTrainingList/DirectionsTrainingElementStatement';
import FillingStatementsForm from '../../../../components/statements/FillingStatementsForm';
import useAppSelector from '../../../../hooks/common/useAppSelector';
import useDirectionsForm from '../../../../hooks/statements/directions/useDirectionsForm';
import { useGetDirections } from '../../../../hooks/statements/directions/useGetDirections';
import terms from '../../../../i18n';
import { DirectionRequestType } from '../../../../models/applications/applications';
import { ApplicationEducationLevel } from '../../../../models/applications/enum';
import { selectIsApplicationDirectionsReadonly } from '../../../../redux/selectors/enrollee/application';
import { useIsImpersonate } from '../../../../utils/domain/impersonate';
import { DirectionsPreparatoryForm } from './DirectionsPreparatoryForm';

export const DirectionsForm = ({
  requestType,
}: {
  readonly requestType: DirectionRequestType;
}): JSX.Element => {
  const { applicationId } = useParams();
  const dispatch = useAppDispatch();

  const {
    deleteDirection,
    directions,
    allDirections,
    swapDirections,
    changePriority,
    submitDirectionsWithPriorities,
  } = useGetDirections({
    applicationId: Number(applicationId),
    requestType,
  });

  const {
    fields,
    control,
    onNextPageNavigate,
    onAddDirectionsClick,
    currentTrainingLevel,
    formState: { errors },
    onSubmitPreparatoryTrainingLevel,
  } = useDirectionsForm(directions);

  const isImpersonate = useIsImpersonate();
  const readOnly =
    useAppSelector(selectIsApplicationDirectionsReadonly) && !isImpersonate;

  return (
    <FillingStatementsForm
      onSubmit={
        currentTrainingLevel === ApplicationEducationLevel.PREPARATORY
          ? onSubmitPreparatoryTrainingLevel
          : e => {
              e.preventDefault();
              submitDirectionsWithPriorities()
                .unwrap()
                .then(() => onNextPageNavigate())
                .catch(() =>
                  dispatch(
                    createToast(terms.ERROR_SETTING_DIRECTIONS, 'danger')
                  )
                );
            }
      }
    >
      <section className="directions-training-list-page">
        <NavLink
          to="create"
          onClick={e => readOnly && e.preventDefault()}
          state={{ directions, allDirections }}
        >
          <Button
            type="button"
            disabled={readOnly}
            theme={!directions ? 'success' : undefined}
            onClick={() => onAddDirectionsClick(requestType)}
          >
            {terms.CHOOSE_DIRECTION}
          </Button>
        </NavLink>

        {!!directions && (
          <div className="mt-24">
            {directions.map((el, index) => (
              <DirectionsTrainingElementStatement
                key={el.id}
                readOnly={readOnly}
                direction={el}
                onDelete={() => deleteDirection(el.id)}
                index={index}
                onSwap={swapDirections}
                isFirst={index === 0}
                isLast={index === directions.length - 1}
                onPriorityChange={newPriority =>
                  changePriority(el, newPriority)
                }
              />
            ))}

            {currentTrainingLevel === ApplicationEducationLevel.PREPARATORY && (
              <DirectionsPreparatoryForm
                readonly={readOnly}
                errors={errors}
                control={control}
                fields={fields}
              />
            )}
          </div>
        )}
      </section>
    </FillingStatementsForm>
  );
};
