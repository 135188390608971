import { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { Modify } from 'types/extra';

import { AutocompleteOption } from 'components/profile/AddressesForm/dadata/view';
import useAppDispatch from 'hooks/common/useAppDispatch';
import terms, { isRussianLocale } from 'i18n';
import {
  COUNTRY_ENUM_RUSSIA,
  COUNTRY_RUSSIA_ID,
  FOREIGN_PASSPORT_ENUM,
  INTERNATIONAL_RUSSIAN_PASSPORT_ENUM,
  RUSSIAN_PASSPORT_ENUM,
} from 'models/enum';
import { DadataFmsUnit } from 'models/questionnaire/dadata';
import {
  PassportDraft,
  SavePassportDraftRequest,
} from 'models/questionnaire/questionnaire';
import { createToast } from 'redux/actions';
import {
  getPassportNumber,
  getPassportSeries,
  normalizeYearTo4Characters,
  useDateLessThanCurrentAndMoreThanBirthdayValidator,
} from 'utils/form-validators';

import { questionnaireSlice } from '../../../redux/slices/enrollee/questionnaire-slice';
import regexp from '../../../utils/regexp';
import { useGeneralDataDraft } from '../common/draft/useGeneralDataDraft';
import { useIsCeaEnrollee } from '../common/draft/useIsCeaEnrollee';
import { useIsRussianEnrollee } from '../common/draft/useIsRussianEnrollee';
import { usePassportDraft } from './usePassportDraft';

export type PassportForm = Modify<
  SavePassportDraftRequest,
  {
    haveSecondNationality?: boolean;
    identificationDocumentIssuedBy?: string | AutocompleteOption<DadataFmsUnit>;
  }
>;

const usePassportForm = (onSuccess?: () => void) => {
  const dispatch = useAppDispatch();

  const { isRussianEnrollee } = useIsRussianEnrollee();
  const { isCeaEnrollee } = useIsCeaEnrollee();

  const { draft, saveDraft, ...draftInfo } = usePassportDraft();
  const { draft: generalDataDraft } = useGeneralDataDraft();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    reset,
    ...rest
  } = useForm<PassportForm>({
    mode: 'all',
  });

  useEffect(() => {
    if (draft) {
      reset({
        ...draft,
        countryOfBirthId: draft.countryOfBirth?.id,
        haveSecondNationality: !!draft.secondaryCitizenshipCountry,
        secondaryCitizenshipCountryId: draft.secondaryCitizenshipCountry?.id,
      });
    }
  }, [draft]);

  // Почему-то reset обновляет внутренне состояние, но не передает актуальные значения в сам компонент
  useEffect(() => {
    setValue(
      'identificationDocumentDivisionCode',
      watch('identificationDocumentDivisionCode')
    );
  }, [watch('identificationDocumentDivisionCode')]);

  const isBirthCountryRussia = watch('countryOfBirthId') === COUNTRY_RUSSIA_ID;

  const documentType = watch('identificationDocumentType');
  const isRussianPassport = documentType === RUSSIAN_PASSPORT_ENUM;
  const isForeignPassport = documentType === FOREIGN_PASSPORT_ENUM;
  const haveSecondNationality = watch('haveSecondNationality');

  const temporaryResidencePermitRequired = watch(
    'haveTemporaryResidencePermit'
  );

  const minobrBenefitRequired = watch('hasMinobrBenefit');

  const validate = useDateLessThanCurrentAndMoreThanBirthdayValidator();

  const fields = {
    identificationDocumentType: register('identificationDocumentType', {
      required: terms.REQUIRED_FIELD,
      onChange: ({ target: { value } }) => {
        if (value) {
          clearErrors('identificationDocumentSerial');
          clearErrors('identificationDocumentNumber');

          if (value === FOREIGN_PASSPORT_ENUM) {
            setValue('identificationDocumentSerial', undefined);
          }

          if (value === RUSSIAN_PASSPORT_ENUM) {
            setValue('identificationDocumentExpirationDate', undefined);
          } else {
            setValue('identificationDocumentDivisionCode', undefined);
          }
        }
      },
    }),
    identificationDocumentSerial: register('identificationDocumentSerial', {
      required: {
        value: !isForeignPassport,
        message: terms.REQUIRED_FIELD,
      },
      ...getPassportSeries(
        !isForeignPassport,
        documentType === INTERNATIONAL_RUSSIAN_PASSPORT_ENUM
      ),
    }),
    identificationDocumentNumber: register('identificationDocumentNumber', {
      required: terms.REQUIRED_FIELD,
      ...getPassportNumber(isRussianPassport),
    }),
    identificationDocumentIssuedAt: register('identificationDocumentIssuedAt', {
      required: terms.REQUIRED_FIELD,
      validate: value => {
        if (!value) return true;
        return validate(value, terms.PASSPORT_ISSUED_AT_DATE_INVALID);
      },
      onChange: ({ target: { value } }) =>
        value &&
        setValue(
          'identificationDocumentIssuedAt',
          normalizeYearTo4Characters(value)
        ),
    }),
    identificationDocumentIssuedBy: register('identificationDocumentIssuedBy', {
      required: terms.REQUIRED_FIELD,
      pattern: {
        value: regexp.ruOrEnWithSpecialSignsAndNumberSign,
        message:
          terms.CYRILLIC_OR_LATIN_WITH_SPECIALS_CHARACTERS_AND_NUMBER_SIGN_ONLY,
      },
    }),
    identificationDocumentExpirationDate: register(
      'identificationDocumentExpirationDate',
      {
        required: {
          value: !isRussianPassport,
          message: terms.REQUIRED_FIELD,
        },
        onChange: ({ target: { value } }) =>
          value &&
          setValue(
            'identificationDocumentExpirationDate',
            normalizeYearTo4Characters(value)
          ),
      }
    ),
    identificationDocumentDivisionCode: register(
      'identificationDocumentDivisionCode',
      {
        required: {
          value: isRussianPassport,
          message: terms.REQUIRED_FIELD,
        },
        pattern: {
          value: /^[0-9]{3}-[0-9]{3}$/,
          message: terms.DIVISION_CODE_TYPE,
        },
        maxLength: {
          value: 7,
          message: terms.DIVISION_CODE_LENGTH,
        },
      }
    ),
    identificationDocumentPlaceOfBirth: register(
      'identificationDocumentPlaceOfBirth',
      {
        required: terms.REQUIRED_FIELD,
        pattern: {
          value: regexp.ruOrEnWithSpecialSigns,
          message: terms.CYRILLIC_OR_LATIN_WITH_SPECIALS_CHARACTERS_ONLY,
        },
      }
    ),
    countryOfBirth: register('countryOfBirthId', {
      required: terms.REQUIRED_FIELD,
      onChange: event => {
        const nationalId = event.target.value;
        const isNationalRussia = nationalId === COUNTRY_ENUM_RUSSIA;
        if (isNationalRussia) {
          setValue('haveTemporaryResidencePermit', false);
          setValue('haveVisa', false);
          setValue('haveSecondNationality', false);
          setValue('secondaryCitizenshipCountryId', undefined);
        }
      },
    }),
    secondaryCitizenship: register('secondaryCitizenshipCountryId', {
      required: {
        value: !!haveSecondNationality,
        message: terms.REQUIRED_FIELD,
      },
    }),
    haveSecondNationality: register('haveSecondNationality', {
      onChange: e => {
        const { checked } = e.target;
        if (!checked) {
          // TODO not working
          setValue('secondaryCitizenshipCountryId', undefined);
          clearErrors('secondaryCitizenshipCountryId');
        }
      },
    }),
    haveTemporaryResidencePermit: register('haveTemporaryResidencePermit'),
    temporaryResidencePermitIssuedAt: register(
      'temporaryResidencePermitIssuedAt',
      {
        required: {
          value: Boolean(temporaryResidencePermitRequired),
          message: terms.REQUIRED_FIELD,
        },
        validate: value => {
          if (!value) return true;
          return validate(
            value,
            'Дата выдачи должна быть позже даты рождения и раньше текущей даты'
          );
        },
        onChange: ({ target: { value } }) =>
          value &&
          setValue(
            'temporaryResidencePermitIssuedAt',
            normalizeYearTo4Characters(value)
          ),
      }
    ),
    temporaryResidencePermitExpirationDate: register(
      'temporaryResidencePermitExpirationDate',

      {
        onChange: ({ target: { value } }) =>
          value &&
          setValue(
            'temporaryResidencePermitExpirationDate',
            normalizeYearTo4Characters(value)
          ),
      }
    ),
    temporaryResidencePermitIssuedBy: register(
      'temporaryResidencePermitIssuedBy',
      {
        required: {
          value: Boolean(temporaryResidencePermitRequired),
          message: terms.REQUIRED_FIELD,
        },
      }
    ),
    temporaryResidencePermitNumber: register('temporaryResidencePermitNumber', {
      required: {
        value: Boolean(temporaryResidencePermitRequired),
        message: terms.REQUIRED_FIELD,
      },
    }),
    haveVisa: register('haveVisa'),
    needVisa: register('needVisa'),
    isCompatriot: register('isCompatriot'),
    hasMinobrBenefit: register('hasMinobrBenefit'),
    educationInRussiaWebsiteIdentifier: register(
      'educationInRussiaWebsiteIdentifier',
      {
        required: {
          value: !!watch('hasMinobrBenefit'),
          message: terms.REQUIRED_FIELD,
        },
      }
    ),
  };

  const onSubmit = (overwriteData: Partial<PassportForm>) =>
    handleSubmit(data => {
      delete data.haveSecondNationality;

      const { identificationDocumentIssuedBy } = overwriteData;

      const draft: PassportDraft = {
        ...data,
        ...overwriteData,
      } as PassportDraft;

      saveDraft(draft)
        ?.unwrap()
        .then(() => {
          dispatch(questionnaireSlice.actions.setNeedVisa(draft.needVisa));
          dispatch(
            questionnaireSlice.actions.setIsCompatriot(draft.isCompatriot)
          );
          onSuccess?.();
        })
        .catch(error => {
          const errorDescriptionLocale: string = isRussianLocale()
            ? error.data?.descriptionRu ?? error.data.description
            : error.data.description;
          dispatch(createToast(errorDescriptionLocale, 'danger'));
        });
    });

  return {
    fields,
    watch,
    onSubmit,
    setValue,
    isBirthCountryRussia,
    isRussianPassport,
    isRussianEnrollee,
    isCeaEnrollee,
    isForeignPassport,
    haveSecondNationality,
    minobrBenefitRequired,
    temporaryResidencePermitRequired,
    ...rest,
    ...draftInfo,
  };
};

export default usePassportForm;
