import React, { createContext, useContext } from 'react';

import { moderatorStatementsRoutes } from 'config/routes/moderator-statements-routes';
import useModeratorStatementsProvideRoutes from 'hooks/moderator/useModeratorStatementsProvideRoutes';

type ModeratorStatementsRoutesContextProps = ReturnType<
  typeof useModeratorStatementsProvideRoutes
>;

const ModeratorStatementsRoutesContext =
  createContext<ModeratorStatementsRoutesContextProps>(
    {} as ModeratorStatementsRoutesContextProps
  );

export const ModeratorStatementsRoutesContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <ModeratorStatementsRoutesContext.Provider
      value={useModeratorStatementsProvideRoutes(moderatorStatementsRoutes)}
    >
      {children}
    </ModeratorStatementsRoutesContext.Provider>
  );
};

export default () => {
  return useContext(ModeratorStatementsRoutesContext);
};
