import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { ApplicationEducationLevel } from 'models/applications/enum';
import {
  normalizeYearTo4Characters,
  useDateLessThanCurrentAndMoreThanBirthdayValidator,
} from 'utils/form-validators';

import terms from '../../../i18n';
import { Olympiad, OlympiadType } from '../../../models/applications/olympiads';
import { DateString, Entity, SelectOption } from '../../../models/common';
import { createToast, hideModal } from '../../../redux/actions';
import { olympiadsApi } from '../../../redux/api/applications/olympiads-api';
import useAppDispatch from '../../common/useAppDispatch';
import { useLazyUploadFiles } from '../../files/useLazyUploadFiles';

type AddOlympiadForm = {
  readonly year: SelectOption;
  readonly olympiadType?: SelectOption;
  readonly confirmationDocType?: SelectOption;
  readonly number: string;
  readonly dateIssue: DateString;
  readonly comment?: string;
  readonly documentId: number;

  readonly wasDocumentAttached: boolean;
};

const useAddOlympiadForm = (
  applicationId: number,
  olympiad: Olympiad,
  educationLevel: ApplicationEducationLevel,
  refetchAchievementsForm: () => void,
  userId?: number | null
) => {
  const dispatch = useAppDispatch();

  const { data: years } = olympiadsApi.useGetOlympiadYearsQuery(applicationId);
  const [getOlympiadTypes] = olympiadsApi.useLazyGetOlympiadTypesQuery();
  const [getConfirmationDocTypes] =
    olympiadsApi.useLazyGetOlympiadConfirmationDocTypesQuery();
  const [createOlympiad] = olympiadsApi.useCreateOlympiadMutation();
  const [updateOlympiad] = olympiadsApi.useUpdateOlympiadMutation();

  const [olympiadTypes, setOlympiadTypes] = useState<OlympiadType[]>([]);
  const [confirmationDocs, setConfirmationDocs] = useState<Entity[]>([]);

  const { register, handleSubmit, getValues, setValue, watch, reset, ...rest } =
    useForm<AddOlympiadForm>();

  const fileProps = useLazyUploadFiles({
    initialFileIds: watch('documentId'),
    userId,
  });

  const validateDate = useDateLessThanCurrentAndMoreThanBirthdayValidator();

  useEffect(() => {
    if (olympiad) {
      Promise.all([
        getOlympiadTypes({
          year: olympiad.olympiadType.year,
          educationLevel,
        }).unwrap(),
        getConfirmationDocTypes(olympiad.olympiadType.id).unwrap(),
      ]).then(([fetchedOlympiadTypes, fetchedConfirmationDocs]) => {
        setOlympiadTypes(fetchedOlympiadTypes);
        setConfirmationDocs(fetchedConfirmationDocs);

        reset({
          year: {
            label: `${olympiad.olympiadType.year}`,
            value: olympiad.olympiadType.year,
          },
          olympiadType: {
            label: olympiad.olympiadType.title,
            value: olympiad.olympiadType.id,
          },
          confirmationDocType: {
            label: olympiad.confirmationDocumentTypeId.title,
            value: olympiad.confirmationDocumentTypeId.id,
          },
          documentId: olympiad.documentId,
          wasDocumentAttached: !!olympiad.documentId,
          number: olympiad.documentCode,
          dateIssue: olympiad.dateOfIssue,
          comment: olympiad.additionalInfo,
        });
      });
    }
  }, []);

  useEffect(() => {
    setValue('wasDocumentAttached', fileProps.files.length > 0);
  }, [fileProps.files]);

  const fields = {
    year: register('year', {
      required: terms.REQUIRED_FIELD,
      onChange: ({ target: { value } }) => {
        if (value) {
          setConfirmationDocs([]);

          setValue('olympiadType', undefined);
          setValue('confirmationDocType', undefined);

          getOlympiadTypes({ year: value.value, educationLevel })
            .unwrap()
            .then(types => setOlympiadTypes(types));
        }
      },
    }),

    olympiadType: register('olympiadType', {
      required: terms.REQUIRED_FIELD,
      onChange: ({ target: { value } }) => {
        if (value) {
          setValue('confirmationDocType', undefined);

          getConfirmationDocTypes(value.value)
            .unwrap()
            .then(types => setConfirmationDocs(types));
        }
      },
    }),

    confirmationDocType: register('confirmationDocType', {
      required: terms.REQUIRED_FIELD,
    }),

    number: register('number', { required: terms.REQUIRED_FIELD }),

    dateIssue: register('dateIssue', {
      required: terms.REQUIRED_FIELD,
      onChange: ({ target: { value } }) =>
        value && setValue('dateIssue', normalizeYearTo4Characters(value)),
      validate: value =>
        validateDate(
          value,
          'Дата выдачи должна быть позже даты рождения и раньше текущей даты'
        ),
    }),

    comment: register('comment'),
    documentId: register('documentId'),

    wasDocumentAttached: register('wasDocumentAttached', {
      required: terms.REQUIRED_FIELD,
    }),
  };

  const onSubmit = handleSubmit(data => {
    if (fileProps.files.length === 0) {
      dispatch(createToast('Необходимо прикрепить документ', 'danger'));
      return;
    }

    fileProps
      .uploadFiles()
      .then(fileId => setValue('documentId', fileId))
      .then(() => {
        const request = {
          applicationId,
          documentId: watch('documentId'),
          olympiadTypeId: data.olympiadType?.value,
          confirmationDocumentTypeId: data.confirmationDocType?.value,
          dateOfIssue: data.dateIssue,
          documentCode: data.number,
          additionalInfo: data.comment || undefined,
        };

        return olympiad
          ? updateOlympiad({
              ...request,
              id: olympiad.id,
            }).unwrap()
          : createOlympiad(request).unwrap();
      })
      .then(() => {
        dispatch(createToast('Олимпиада была успешно добавлена', 'success'));
        dispatch(hideModal());
        refetchAchievementsForm();
      });
  });

  return {
    fields,
    onSubmit,
    setValue,
    getValues,
    ...rest,
    fileProps,
    yearSelectOptions: (years ?? []).map(year => ({
      value: year,
      label: year + '',
    })),
    olympiadTypesSelectOptions: olympiadTypes.map(type => ({
      value: type.id,
      label: type.title,
    })),
    confirmationDocsSelectOptions: confirmationDocs.map(type => ({
      value: type.id,
      label: type.title,
    })),
  };
};

export default useAddOlympiadForm;
