import React, { FC } from 'react';

import terms from 'i18n';

import useAppDispatch from '../../../../hooks/common/useAppDispatch';
import useAppSelector from '../../../../hooks/common/useAppSelector';
import { hideModal } from '../../../../redux/actions';
import { modalDataSelector } from '../../../../redux/selectors';
import Button from '../../ui-kit/Button';
import TemplateModal from '../TemplateModal';

import './UnsentMessageExitModal.scss';

const UnsentMessageExitModal: FC = () => {
  const dispatch = useAppDispatch();
  const closeModal = () => dispatch(hideModal());

  const { messageCount } = useAppSelector(modalDataSelector);
  return (
    <TemplateModal title={terms.CONFIRM_CONSENT}>
      <div className="unsent-message-exit-modal">{`${terms.UNSENT_MESSAGE_WARN_1} (${messageCount}) ${terms.UNSENT_MESSAGE_WARN_2}. ${terms.UNSENT_MESSAGE_WARN_3}.`}</div>

      <div className="unsent-message-exit-modal__buttons">
        <Button
          className="unsent-message-exit-modal__button"
          theme="success"
          onClick={closeModal}
        >
          {terms.STAY_ON_PAGE}
        </Button>
        {/* <Button*/}
        {/*  className="unsent-message-exit-modal__button"*/}
        {/*  onClick={() => {*/}
        {/*    window.onbeforeunload = null;*/}
        {/*    window.close();*/}
        {/*  }}*/}
        {/* >*/}
        {/*  {terms.CONFIRM_EXIT}*/}
        {/* </Button>*/}
      </div>
    </TemplateModal>
  );
};

export default UnsentMessageExitModal;
