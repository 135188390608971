import React, { FC, Suspense } from 'react';

import { BrowserRouter } from 'react-router-dom';

import CenterContainer from 'components/common/ui-kit/CenterContainer';
import Loader from 'components/common/ui-kit/Loader';

export const withRouter = (Component: FC) => () =>
  (
    <BrowserRouter>
      <Suspense
        fallback={
          <CenterContainer fullScreen={true}>
            <Loader />
          </CenterContainer>
        }
      >
        <Component />
      </Suspense>
    </BrowserRouter>
  );
