export default {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  url: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)$/g,
  enWithoutQuotationMarks: /^[a-zA-Z.`' -]+$/g,
  ru: /^[А-Яа-яЁё\-.\s]*$/g,
  en: /^[a-zA-Z.`'\-\s]*$/g,
  ruOrEn: /^[А-Яа-яЁё\-.\sa-zA-Z`'\d]*$/g,
  ruOrEnWithSpecialSigns: /^[А-Яа-яЁё\-.,/\sa-zA-Z`'\d]*$/g,
  ruOrEnWithSpecialSignsAndNumberSign: /^[А-Яа-яЁё\-.,/№\sa-zA-Z`'\d]*$/g,
  ruOrEnWithSpecialSignsAndBrackets: /^[А-Яа-яЁё\-.,/()\sa-zA-Z`'\d]*$/g,
  ruWithoutQuotationMarks: /^[А-Яа-яЁё.' -]+$/g,
  nameExtended: /^[a-zA-ZА-Яа-яЁё.,;`'\\_#%"<>!? -]+$/g,
  lettersAndSymbols: /^[0-9a-zA-ZА-Яа-яЁё,._/\\#%"<>!? -]+$/g,
  lettersAndSymbolsExtended:
    /^[a-zA-ZА-Яа-яЁё0-9,;.|\r\n:_#%"\u00a0«»<>!?+/()№[\]={}&*\\ -]+$/g,
  year: /^\d{4}$/g,
  mobile: /^([+]?[0-9() -]+)$/g,
  passportSeries: /^([0-9]{4})$/g,
  internationalRussianPassportSeries: /^([0-9]{2})$/g,
  passportSeriesForeign: /^([a-zA-ZА-Яa-яЁё0-9])/g,
  divisionCode: /^([0-9]){3}-([0-9]){3}$/g,
  passportNumber: /^([0-9]{6})$/g,
  passportNumberForeign: /^([a-zA-ZА-Яa-яЁё0-9])/g,
  date: /^\d{2}.\d{2}.\d{4}$/g,
  dateOnlyPointFormat: /^\d{2}\.\d{2}\.\d{4}$/g,
  dateOnlyYear: /^\d{4}$/g,
  site: /^((https?):\/\/)(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/g,
  fromOne: /^[1-9][0-9]*$/g,
  positiveNumbers: /^\d*[1-9]\d*$/g,
  textArea: /^[a-zA-ZА-Яа-яЁё0-9,;.|\r\n:_#%"<>!?+/()№[\]={}&*\\ -]*$/g,
  password: /^(?=.*[$&+,:;=?@#|'<>.^*_()%!-])(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
  bankIdentifierCode: /^([0-9]{9})$/g,
  checkingAndCorrespondentAccounts: /^([0-9]{20})$/g,
  averageScore: /^\d+(\.\d{1,2})?$/g,
};
