import { RefObject, useCallback, useEffect } from 'react';

import { isLastMessageViewed } from 'components/chat/ChatWidget/ChatPanel/Chat/ChatMessage/message-commons';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import { ChatMessage } from 'models/chat';
import { setChatViewed } from 'redux/actions';
import {
  selectChatOpened,
  selectChatViewed,
} from 'redux/selectors/common/chat';

export type UseChatInitViewed = {
  chat: RefObject<Element>;
  messages: ChatMessage[];
  isLoading: boolean;
};

const useChatInitViewed = ({
  chat,
  messages,
  isLoading,
}: UseChatInitViewed) => {
  const dispatch = useAppDispatch();
  const chatOpened = useAppSelector(selectChatOpened);
  const chatViewed = useAppSelector(selectChatViewed);

  const updateViewed = useCallback(() => {
    const viewed = isLastMessageViewed(chatOpened, chat.current, !isLoading);
    if (viewed !== chatViewed) dispatch(setChatViewed(viewed));
  }, [chatOpened, chat.current, isLoading]);

  useEffect(() => {
    updateViewed();
    return () => {
      dispatch(setChatViewed(false));
    };
  }, [updateViewed, messages]);

  useEffect(() => {
    window.addEventListener('focus', updateViewed);
    window.addEventListener('blur', updateViewed);
    return () => {
      window.removeEventListener('focus', updateViewed);
      window.removeEventListener('blur', updateViewed);
    };
  }, [updateViewed]);

  return updateViewed;
};

export default useChatInitViewed;
