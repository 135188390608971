import React from 'react';
import './assets/styles/main.scss';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createRoot } from 'react-dom/client';

import App from 'app';
import reportWebVitals from 'reportWebVitals';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE,
});

createRoot(document.getElementById('root') as HTMLElement).render(<App />);

reportWebVitals();
