import { useEffect } from 'react';

import { useForm } from 'react-hook-form';

import useAppDispatch from 'hooks/common/useAppDispatch';
import terms, { locale } from 'i18n';

import { SelectOption } from '../../models/common';
import { hideModal } from '../../redux/actions';
import { userManagementApi } from '../../redux/api/staff/admin/user-management-api';
import { UserDataByStaff } from '../../redux/slices/staff/userManagementSlice';
import regexp from '../../utils/regexp';
import { useAddUserFormSelectProps } from './useAddUserFormSelectProps';

type useAddUserAdminModalFormProps = {
  adminUserIdData?: UserDataByStaff;
  isEditing: boolean;
};

type NewUserForm = {
  readonly id?: number;
  readonly email: string;
  readonly username: string;
  readonly password: string;
  readonly role: SelectOption;
  readonly canChooseBachelor: SelectOption;
  readonly name: string;
  readonly surname: string;
  readonly patronymic: string;
  readonly birthDate: string;
  readonly activated: SelectOption;
  readonly passwordConfirmation: string;
  readonly country: SelectOption;
};

const useAddUserAdminModalForm = ({
  adminUserIdData,
  isEditing,
}: useAddUserAdminModalFormProps) => {
  const dispatch = useAppDispatch();
  const [updateUser, { isLoading }] =
    userManagementApi.useUpdateUserByAdminMutation();
  const [createUser, { isLoading: isUserCreatingLoading }] =
    userManagementApi.useCreateUserByAdminMutation();
  const { canChooseBachelorOptions } = useAddUserFormSelectProps();

  const { register, handleSubmit, setValue, reset, getValues, ...rest } =
    useForm<NewUserForm>();

  useEffect(() => {
    if (adminUserIdData) {
      reset({
        role: {
          value: adminUserIdData.userView.role,
          label: terms[adminUserIdData.userView.role],
        },
        email: adminUserIdData.userView.email,
        canChooseBachelor: canChooseBachelorOptions.find(
          opt =>
            opt.value === (adminUserIdData?.userView.canChooseBachelor || false)
        ),
      });
    }
  }, [adminUserIdData]);

  const fields = {
    email: register('email', { required: terms.REQUIRED_FIELD }),
    role: register('role', { required: terms.REQUIRED_FIELD }),

    activated: register('activated', {
      required: !isEditing && terms.REQUIRED_FIELD,
    }),
    name: register('name', {
      required: !isEditing && terms.REQUIRED_FIELD,
    }),
    surname: register('surname', {
      required: !isEditing && terms.REQUIRED_FIELD,
    }),
    patronymic: register('patronymic'),
    birthDate: register('birthDate', {
      required: !isEditing && terms.REQUIRED_FIELD,
    }),
    country: register('country', {
      required: !isEditing && terms.REQUIRED_FIELD,
    }),
    password: register('password', {
      required: { value: !isEditing, message: terms.REQUIRED_FIELD },
      pattern: {
        value: regexp.password,
        message: terms.PASSWORD_MIXED,
      },
      minLength: {
        value: 6,
        message: terms.PASSWORD_MIN_LENGTH,
      },
    }),
    passwordConfirmation: register('passwordConfirmation', {
      required: { value: !isEditing, message: terms.REQUIRED_FIELD },
      validate: value => {
        return value === getValues('password') || terms.PASSWORDS_MUST_MATCH;
      },
    }),
  };

  const onSubmit = handleSubmit(data => {
    if (isEditing && adminUserIdData?.userView) {
      updateUser({
        userId: adminUserIdData?.userView?.id,
        username: data.email,
        email: data.email,
        role: data.role.value,
        canChooseBachelor: data.canChooseBachelor?.value,
      }).then(() => {
        dispatch(hideModal());
      });
    } else {
      const request = {
        ...data,
        username: data.email,
        role: data.role.value,
        activated: data.activated.value,
        primaryCitizenshipCountryId: data.country.value,
        prefLang: locale,
      };

      delete (request as any).country;
      delete (request as any).passwordConfirmation;

      createUser(request)
        .unwrap()
        .then(() => {
          dispatch(hideModal());
        });
    }

    /* if (isEditing) {
      updateUser
    }*/
    /* setLoading(true);

    const normalizedData: NewUserForm = {
      ...data,
      roleId: data.roleId.value,
      status: data.status.value,
    };

    if (isEditing) {
      api.admin
        .putAdminChangeUser({ ...normalizedData, id: adminUserIdData.id })
        .then(response => {
          const { data } = response as unknown as ResponseOk;
          dispatch(createToast(data.message, 'success'));
          dispatch(getAdminUserList({ page: page, perPage: perPage }));
          dispatch(hideModal());
        })
        .catch(r => handleFormError(r, dispatch, setError))
        .finally(() => setLoading(false));
    } else {
      api.admin
        .postAdminNewUser(normalizedData)
        .then(response => {
          const { data } = response as unknown as ResponseOk;
          dispatch(createToast(data.message, 'success'));
          dispatch(getAdminUserList({ page: page, perPage: perPage }));
          dispatch(hideModal());
        })
        .catch(r => handleFormError(r, dispatch, setError))
        .finally(() => setLoading(false));
    }*/
  });

  return {
    fields,
    loading: isLoading || isUserCreatingLoading,
    setValue,
    getValues,
    onSubmit,
    ...rest,
  };
};

export default useAddUserAdminModalForm;
