export type ChatRequestStatus = 'SENT' | 'UNSENT';

export type ChatMetaInfo = {
  applicantId: number;
  requestStatus: ChatRequestStatus;
};

export type ChatTopicType = 'APPLICANT_REQUESTS' | 'APPLICANT_DISPUTE';

export type ChatTopic = {
  id: number;
  type: ChatTopicType;
  metaInfo: ChatMetaInfo;
};

export enum ChatType {
  ENROLLEE = 1,
  COORDINATOR,
  EXPERT,
}

export type Chat = {
  id: number;
  marker: ChatType;
  topic: ChatTopic;
};

export type UnreadCount = {
  [chatType in ChatType]?: number;
};

export type ChatAttachment = {
  documentId: number;
  realName: string;
};

export type ChatMessage = {
  uuid: string;
  text: string;
  senderId: number;
  senderName?: string;
  senderFirstName?: string;
  senderLastName?: string;
  senderMiddleName?: string;
  senderFirstNameLatin?: string;
  senderLastNameLatin?: string;
  senderMiddleNameLatin?: string;
  senderRole?: string;
  chatId: number;
  timestamp: string;
  read?: boolean;
  attachments?: ChatAttachment[];
  sent?: boolean;
};

export type SendPayload = {
  uuid: string;
  text: string;
  senderId: number;
  chatId: number;
  senderName: string;
  senderFirstName?: string;
  senderLastName?: string;
  senderMiddleName?: string;
  senderFirstNameLatin?: string;
  senderLastNameLatin?: string;
  senderMiddleNameLatin?: string;
  attachments: ChatAttachment[];
};

export type ReadPayload = {
  chatId: number;
  readerId: number;
};

export type ReceivePayload = {
  chatId: number;
};

export type AcceptedPayload = {
  uuid: string;
  chatId: number;
};

export type PingPayload = {
  userId: number;
  jwt: string;
};

export type ChatSignalType =
  | 'SEND'
  | 'READ'
  | 'RECEIVE'
  | 'ACCEPTED'
  | 'PING'
  | 'PONG';

export type MessageData = {
  signal: ChatSignalType;
  payload: string;
};
