import { isRussianLocale } from '../i18n';

export type SelectOption<T = any> = {
  value?: T;
  label?: string;
};

export type DateString = string;
export type DateTimeString = string;

export type Year = {
  value: number;
  leap: boolean;
};

export type Pagination = {
  page?: number;
  size?: number;
};

export type Page<Data> = {
  page: number;
  pageSize: number;
  totalPages: number;
  totalElements: number;
  result: Data[];
};

export type Sorting = {
  orderBy?: string;
  orderDirection?: string;
};

export type Entity = {
  readonly id: number;
  readonly title: string;
};

export type LocalizedEntity = Entity & {
  readonly titleForeign: string;
};

export type LocalizedEntityWithExternalId = LocalizedEntity & {
  readonly externalId: string;
};

export type LocalizedEntityWithExternalIdAndShortTitle =
  LocalizedEntityWithExternalId & {
    readonly titleShort: string;
  };

export const translate = (el: { title: string; titleForeign?: string }) =>
  isRussianLocale() ? el.title : el.titleForeign || el.title;
