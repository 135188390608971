import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'services/api-client';

/**
 * @deprecated
 * @see useGetUserAvatar
 */
export const getViewerProfileImage = createAsyncThunk(
  'viewer/profilePhoto',
  async (data: { userId: number; photoId: number }) => {
    return await api.profile.getViewerProfilePhoto(data.userId, data.photoId);
  }
);
