import React, { FC } from 'react';

import { useParams } from 'react-router-dom';

import Button from 'components/common/ui-kit/Button';
import Table from 'components/statements/AchievementsForm/Table';
import useAppDispatch from 'hooks/common/useAppDispatch';
import { useEnrolleeUserId } from 'hooks/questionnaire/common/draft/useQuestionnaire';
import useAchievementsForm from 'hooks/statements/achievements/useAchievementsForm';
import { showModal } from 'redux/actions';

import useAppSelector from '../../../hooks/common/useAppSelector';
import terms from '../../../i18n';
import { Achievement } from '../../../models/applications/achievements';
import { selectIsApplicationReadonly } from '../../../redux/selectors/enrollee/application';
import { useIsImpersonate } from '../../../utils/domain/impersonate';

interface AchievementsFormProps {
  needToFetchAchievements: boolean;
  achievements: Array<Achievement>;
  refetchAchievementsForm: () => void;
}
const AchievementsForm: FC<AchievementsFormProps> = ({
  achievements,
  refetchAchievementsForm,
}) => {
  const dispatch = useAppDispatch();
  const { applicationId } = useParams();
  const isImpersonate = useIsImpersonate();
  const userId = useEnrolleeUserId();

  const readOnly =
    useAppSelector(selectIsApplicationReadonly) && !isImpersonate;

  const onOpenAchievementsModal = () => {
    dispatch(
      showModal({
        name: 'ADD_INDIVIDUAL_ACHIEVEMENT',
        data: { applicationId, refetchAchievementsForm, userId },
      })
    );
  };

  const { onDelete, onDownloadFile, onEdit } = useAchievementsForm(
    Number(applicationId),
    refetchAchievementsForm
  );

  return (
    <>
      <h4 className="achievements__title">{terms.INDIVIDUAL_ACHIEVEMENTS}</h4>
      <Button
        type="button"
        className="achievements__button"
        onClick={onOpenAchievementsModal}
        disabled={readOnly}
      >
        {terms.ADD_BUTTON}
      </Button>
      <div className="table-overflow">
        {achievements.length > 0 && (
          <Table
            items={achievements}
            onDelete={onDelete}
            onDownload={onDownloadFile}
            onEdit={onEdit}
            readOnly={readOnly}
          />
        )}
      </div>
    </>
  );
};

export default AchievementsForm;
