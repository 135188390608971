import { ActionReducerMapBuilder, AsyncThunk, Draft } from '@reduxjs/toolkit';
import { Entity } from 'types/redux';

export const initialEntityState: Entity<any> = {
  data: null,
  status: 'idle',
  error: null,
};

export const entityReducerBuilder = <State>(
  builder: ActionReducerMapBuilder<State>
) => ({
  add<ResponseData, RequestParams, Data>(
    asyncThunk: AsyncThunk<ResponseData, RequestParams, {}>,
    getStateKey: (state: Draft<State>) => Entity<Data>,
    transformData?: (data: ResponseData, state: Draft<State>, action) => Data
  ) {
    builder
      .addCase(asyncThunk.pending, state => {
        getStateKey(state).status = 'loading';
      })
      .addCase(asyncThunk.fulfilled, (state, action: any) => {
        getStateKey(state).status = 'succeeded';
        getStateKey(state).data = transformData
          ? transformData(action.payload, state, action)
          : (action.payload as unknown as Data);
      })
      .addCase(asyncThunk.rejected, (state, action) => {
        getStateKey(state).status = 'failed';
        getStateKey(state).error = action.payload;
        getStateKey(state).data = null;
      });
    return this;
  },
});
