import React, { FC, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import RadioButton from 'components/common/ui-kit/RadioButton';

import useAppSelector from '../../../hooks/common/useAppSelector';
import { isRussianLocale } from '../../../i18n';
import { ContractType } from '../../../models/applications/contracts';
import {
  ApplicationEducationLevel,
  Trajectory,
} from '../../../models/applications/enum';
import { contractApi } from '../../../redux/api/applications/contract-api';
import {
  selectApplicationEducationLevel,
  selectCurrentTrajectory,
} from '../../../redux/selectors/enrollee/application';
import EntrantsContractForm from './EntrantsContractForm';
import JuridicalContractForm from './JuridicalContractForm';
import NotEntrantsContractForm from './NotEntrantsContractForm';

export type PaymentMethod = {
  readonly type: ContractType;
  readonly title: string;
  readonly titleForeign: string;
};

const foreignPaymentMethods: PaymentMethod[] = [
  {
    type: ContractType.SELF_PAYMENT,
    title: 'Плательщик по договору (заказчик) и абитуриент одно и то же лицо',
    titleForeign:
      'The payer under the contract (the customer) and the applicant are the same person',
  },
  // {
  //   type: ContractType.THIRD_PARTY,
  //   title: 'Плательщик по договору (заказчик) не является абитуриентом',
  //   titleForeign:
  //     'The payer under the contract (the customer) is not an applicant',
  // },
  // {
  //   type: ContractType.JURIDICAL,
  //   title:
  //     'Плательщик по договору (заказчик) является юридической организацией',
  //   titleForeign:
  //     'The payer under the contract (the customer) is a legal organization',
  // },
];

const domesticPaymentMethods: PaymentMethod[] = [
  {
    type: ContractType.SELF_PAYMENT,
    title: 'Плательщик по договору (заказчик) и абитуриент одно и то же лицо',
    titleForeign:
      'The payer under the contract (the customer) and the applicant are the same person',
  },
  {
    type: ContractType.THIRD_PARTY,
    title: 'Плательщик по договору (заказчик) не является абитуриентом',
    titleForeign:
      'The payer under the contract (the customer) is not an applicant',
  },
  {
    type: ContractType.JURIDICAL,
    title:
      'Плательщик по договору (заказчик) является юридической организацией',
    titleForeign:
      'The payer under the contract (the customer) is a legal organization',
  },
];

const getAvailablePaymentMethods = (
  educationLevel: ApplicationEducationLevel | undefined,
  trajectory: Trajectory | undefined
): PaymentMethod[] => {
  if (trajectory === Trajectory.FOREIGN) {
    if (educationLevel === ApplicationEducationLevel.SECONDARY) {
      return domesticPaymentMethods;
    }
    return foreignPaymentMethods;
  }
  return domesticPaymentMethods;
};

const CreateContractPage: FC = () => {
  const { contractId } = useParams();

  const [contractType, setContractType] = useState<ContractType>(
    ContractType.SELF_PAYMENT
  );

  const trajectory = useAppSelector(selectCurrentTrajectory);

  const edLevel = useAppSelector(selectApplicationEducationLevel);

  const { data: contract } = contractApi.useGetByIdQuery(Number(contractId), {
    skip: !contractId,
  });

  useEffect(() => {
    if (contract) setContractType(contract.contractType);
  }, [contract]);

  return (
    <div>
      <RadioButton className="create-contract-form__radio">
        {getAvailablePaymentMethods(edLevel, trajectory).map((p, index) => (
          <RadioButton.Radio
            onChange={() => setContractType(p.type)}
            value={p.type}
            checked={p.type === contractType}
            name="contract"
            text={isRussianLocale() ? p.title : p.titleForeign}
            key={index}
          />
        ))}
      </RadioButton>

      {contractType === ContractType.SELF_PAYMENT ? (
        <EntrantsContractForm contract={contract} />
      ) : contractType === ContractType.JURIDICAL ? (
        <JuridicalContractForm contract={contract} />
      ) : (
        <NotEntrantsContractForm contract={contract} />
      )}
    </div>
  );
};

export default CreateContractPage;
