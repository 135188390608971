import React, { FC } from 'react';

import { Controller } from 'react-hook-form';

import Button from 'components/common/ui-kit/Button';
import Input from 'components/common/ui-kit/Input/view';
import ReactSelect from 'components/common/ui-kit/Select';
import ProfileSection from 'components/profile/ProfileSection';
import FillingStatementsForm from 'components/statements/FillingStatementsForm';
import terms from 'i18n';
import { SelectOption } from 'models/common';
import { useIsImpersonate } from 'utils/domain/impersonate';

import useEntrantsContractForm from '../../../../hooks/statements/entrance-tests/useEntrantsContractForm';
import {
  Contract,
  ContractType,
} from '../../../../models/applications/contracts';

type EntrantsContractFormProps = {
  readonly contract?: Contract;
  readonly isFillingStatementsForm?: boolean;
  onSubmit?: (res) => void;
};

const EntrantsContractForm: FC<EntrantsContractFormProps> = ({
  contract,
  isFillingStatementsForm = true,
  onSubmit: onSubmitProp,
}) => {
  const isImpersonate = useIsImpersonate();
  const isDateFieldShown = !!contract || isImpersonate;

  const {
    control,
    onSubmit,
    fields,
    formState: { errors },
    directionOptions,
    paymentForms,
  } = useEntrantsContractForm(
    isDateFieldShown,
    contract,
    isFillingStatementsForm,
    onSubmitProp
  );

  const formBody = (
    <>
      <ProfileSection title="">
        <div className="profile-form__fields">
          <Controller
            name={fields.directionId.name}
            control={control}
            render={({ field }) => (
              <ReactSelect<SelectOption>
                options={directionOptions}
                placeholder={terms.DIRECTIONS_TRAINING}
                className="profile-form__field"
                markRequired={true}
                {...(field as object)}
                error={errors.directionId?.message}
                isDisabled={Boolean(contract)}
              />
            )}
          />

          <Controller
            name={fields.paymentForm.name}
            control={control}
            render={({ field }) => (
              <ReactSelect<SelectOption>
                options={paymentForms}
                placeholder={terms.CONTRACT_PAYMENT_TYPE}
                className="profile-form__field"
                markRequired={true}
                {...(field as object)}
                error={errors.paymentForm?.message}
              />
            )}
          />

          {isDateFieldShown && (
            <Input
              className="profile-form__field"
              placeholder={terms.DATE_ISSUE}
              type="date"
              markRequired
              {...fields.contractDate}
              error={errors.contractDate?.message}
            />
          )}
        </div>
      </ProfileSection>
      <Button className="create-contract-form__button" theme="success">
        {contract ? terms.EDIT : terms.CREATE_CONTRACT}
      </Button>
    </>
  );

  if (isFillingStatementsForm) {
    return (
      <FillingStatementsForm
        onSubmit={e => {
          e.preventDefault();
          onSubmit(ContractType.SELF_PAYMENT)(e);
        }}
      >
        {formBody}
      </FillingStatementsForm>
    );
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onSubmit(ContractType.SELF_PAYMENT)(e);
      }}
    >
      {formBody}
    </form>
  );
};

export default EntrantsContractForm;
