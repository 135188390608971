import React, { FC } from 'react';

import Button from 'components/common/ui-kit/Button';
import DirectionsTypeList from 'components/statements/DirectionsTypeList';
import useDirectionsTypePage from 'hooks/statements/useDirectionsTypePage';
import terms from 'i18n';
import ProgressTabPage from 'pages/ProgressTabPage';

import { statementsTabRotes } from '../../../config/routes/statements-tab-routes';

const DirectionsTypePage: FC = () => {
  const { onSubmit, isSubmitButtonDisabled } = useDirectionsTypePage();

  return (
    <main className="directions-type-page">
      <ProgressTabPage
        activePage={statementsTabRotes.find(l =>
          location.pathname.includes(l.to)
        )}
      >
        <DirectionsTypeList />
        <Button
          onClick={onSubmit}
          theme="success"
          disabled={isSubmitButtonDisabled}
        >
          {terms.SAVE_AND_NEXT}
        </Button>
      </ProgressTabPage>
    </main>
  );
};

export default DirectionsTypePage;
