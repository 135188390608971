import React, { FC, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import DirectionsTrainingList from 'components/statements/DirectionsTrainingList';
import DirectionsTrainingSearch from 'components/statements/DirectionsTrainingSearch';
import { DirectionFilters } from 'models/applications/directions';

import Loader from '../../../components/common/ui-kit/Loader';
import useAppSelector from '../../../hooks/common/useAppSelector';
import { useLazyGetNumberOfAvailableDirectionsQuery } from '../../../redux/api/applications/directions-api';
import {
  selectApplication,
  selectCurrentEducationLevel,
  selectCurrentTrajectory,
  selectDirectionsRequestType,
} from '../../../redux/selectors/enrollee/application';
import { useIsImpersonate } from '../../../utils/domain/impersonate';

const DirectionsTrainingCreatePage: FC = () => {
  const navigate = useNavigate();
  const application = useAppSelector(selectApplication);
  const directionsRequestType = useAppSelector(selectDirectionsRequestType);
  const [filters, setFilters] = useState<DirectionFilters>({
    applicationId: application?.id,
    showClosed: false,
    paymentFormId: application?.educationBasis === 'BUDGET' ? 1 : 2,
  });
  const [isAnyFilterLoading, setIsAnyFilterLoading] = useState(true);
  const [maxDirections, setMaxDirections] = useState(Number.MAX_VALUE);

  const trajectory = useAppSelector(selectCurrentTrajectory);
  const selectedTrainingLevel = useAppSelector(selectCurrentEducationLevel);
  const [
    getNumberOfAvailableDirections,
    { data: numberOfAvailableDirections },
  ] = useLazyGetNumberOfAvailableDirectionsQuery();

  const isImpersonate = useIsImpersonate();

  useEffect(
    () => setFilters(filters => ({ ...filters, showClosed: isImpersonate })),
    [isImpersonate]
  );

  const userId = useAppSelector(
    state => state.application.selectedApplication?.userId
  );

  useEffect(() => {
    if (!trajectory) navigate('../');
  }, [trajectory]);

  useEffect(() => {
    if (selectedTrainingLevel && userId) {
      getNumberOfAvailableDirections({
        userId,
        applicationId: application?.id,
        educationLevel: selectedTrainingLevel,
        trajectory: trajectory,
        directionsRequestType: directionsRequestType,
      });
    }
  }, [selectedTrainingLevel, userId]);

  useEffect(() => {
    if (!numberOfAvailableDirections) return;
    setFilters(filters => {
      return {
        ...filters,
        educationLevelId: numberOfAvailableDirections.additionalLevelIds
          .concat(numberOfAvailableDirections.id)
          .join(','),
      };
    });
    setMaxDirections(numberOfAvailableDirections.directionsAllowed);
    setIsAnyFilterLoading(false);
  }, [numberOfAvailableDirections, directionsRequestType]);

  if (!trajectory || !numberOfAvailableDirections) {
    return <Loader />;
  }

  return (
    <section className="directions-training-create-page">
      {!isAnyFilterLoading && selectedTrainingLevel ? (
        <>
          <DirectionsTrainingSearch
            paymentFormId={filters.paymentFormId}
            isPaymentFormInputDisabled={
              application?.educationBasis === 'CONTRACT'
            }
            selectedTrainingLevel={selectedTrainingLevel}
            // selectedDirectionsType={directionsRequestType}
            setFilters={filters =>
              setFilters(prevState => {
                return {
                  ...prevState,
                  ...filters,
                };
              })
            }
          />

          <DirectionsTrainingList
            filters={filters}
            trajectory={trajectory}
            maximumDirections={maxDirections}
            otherAppsUsedDirectionCodes={
              numberOfAvailableDirections.otherAppsUsedDirectionCodes
            }
            checkUnique={numberOfAvailableDirections.checkUnique}
            currentOnly={numberOfAvailableDirections.currentOnly}
          />
        </>
      ) : null}
    </section>
  );
};

export default DirectionsTrainingCreatePage;
