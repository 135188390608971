import React, { FC } from 'react';

import { ReactComponent as DownloadIcon } from 'assets/images/icons/download.svg';
import { ReactComponent as EditIcon } from 'assets/images/icons/edit.svg';
import { ReactComponent as RemoveIcon } from 'assets/images/icons/remove.svg';

import { Privilege } from '../../../models/applications/privileges';

export type PrivilegesTableHead = {
  readonly typeLgota: string;
  readonly typeDoc: string;
  readonly number: string;
  readonly dateIssue: string;
};

export type PrivilegesTableProps = {
  readonly head: PrivilegesTableHead;
  readonly privileges: Privilege[];
  readonly readOnly: boolean;
  readonly onDelete: (id: number) => void;
  readonly onEdit: (privilege: Privilege) => void;
  readonly onDownload: (documentId: number) => void;
};

const PrivilegesTable: FC<PrivilegesTableProps> = ({
  head,
  privileges,
  readOnly,
  onDelete,
  onEdit,
  onDownload,
}) => (
  <div className="table-overflow">
    <table className="privileges-table">
      <thead>
        <tr>
          {Object.values(head).map((el, index) => (
            <td key={index}>{el.toString()}</td>
          ))}
          <td />
        </tr>
      </thead>
      <tbody>
        {privileges.map((privilege, index) => (
          <tr key={index}>
            <td>{privilege.privilegeTypeResponse.title}</td>
            <td>{privilege.confirmationDocumentTypeResponse.title}</td>
            <td>{privilege.documentCode}</td>
            <td>{privilege.dateOfIssue}</td>
            <td>
              <div className="privileges-table__icons">
                <button
                  type="button"
                  onClick={() => onDownload(privilege.documentId)}
                >
                  <DownloadIcon fill="#2B2B2B" />
                </button>
                <button
                  type="button"
                  disabled={readOnly}
                  onClick={() => onEdit(privilege)}
                >
                  <EditIcon fill="#2B2B2B" />
                </button>
                <button
                  type="button"
                  disabled={readOnly}
                  onClick={() => onDelete(privilege.id)}
                >
                  <RemoveIcon fill="#2B2B2B" />
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default PrivilegesTable;
