import { useEffect, useState } from 'react';

import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/src/query/endpointDefinitions';

import usePagination, { defaultPage } from 'hooks/common/usePagination';
import { Page, Pagination } from 'models/common';

import {
  selectCurrentEducationLevel,
  selectCurrentTrajectory,
} from '../../redux/selectors/enrollee/application';
import useAppSelector from './useAppSelector';

export const useInfiniteQuery = <Params, Data>(
  useLazyGetQuery: UseLazyQuery<
    QueryDefinition<Pagination & Params, any, any, Page<Data>>
  >,
  params: Params,
  disabled = false,
  defaultPagination?: Pagination
) => {
  const { size, setPage } = usePagination(defaultPagination);
  const [getLazyData, lazyData] = useLazyGetQuery();
  const [hasNext, setHasNext] = useState(false);
  const [data, setData] = useState<Data[]>();
  const trajectory = useAppSelector(selectCurrentTrajectory);
  const educationLevel = useAppSelector(selectCurrentEducationLevel);

  const loadData = (page: number, prev: Data[] = []) =>
    getLazyData({
      ...params,
      trajectory,
      page,
      size,
    })
      .unwrap()
      .then(data => {
        setData([...prev, ...data.result]);
        setHasNext(page < data.totalPages);
      });

  useEffect(() => {
    if (!disabled) {
      setPage(() => {
        const page = defaultPagination?.page ?? defaultPage;
        loadData(page, []);
        return page;
      });
    }
  }, [params]);

  const loadNext = () => {
    if (!hasNext || disabled) return;
    setPage(prev => {
      const page = prev + 1;
      loadData(page, data);
      return page;
    });
  };

  return {
    ...lazyData,
    data,
    loadNext,
    hasNext,
  };
};

export default useInfiniteQuery;
