import React, { FC } from 'react';

import classNames from 'classnames';
import './admissionCampaignBlock.scss';
import { Link } from 'react-router-dom';

type AdmissionCampaignBlock = {
  educationLevel: string;
  competitiveListsUrl: string;
  applicantListsUrl: string;
  ratingListsLabel?: string;
  className?: string;
};

const AdmissionCampaignBlock: FC<AdmissionCampaignBlock> = ({
  educationLevel,
  competitiveListsUrl,
  applicantListsUrl,
  ratingListsLabel,
  className,
}) => {
  return (
    <div className={classNames('campaign-block', className)}>
      <h3 className="campaign-block__title">{educationLevel}</h3>

      <Link
        // target="_blank"
        to={competitiveListsUrl}
        className="campaign-block__button"
        target="_blank"
      >
        {ratingListsLabel ??
          (applicantListsUrl === 'bachelor-specialist' ||
          applicantListsUrl === 'master' ||
          applicantListsUrl === 'spo' ||
          applicantListsUrl === 'postgraduate'
            ? 'Конкурсные списки'
            : 'Предварительные конкурсные списки')}
      </Link>

      <Link
        // target="_blank"
        to={applicantListsUrl}
        className="campaign-block__button"
        target="_blank"
      >
        Списки подавших заявления
      </Link>
    </div>
  );
};

export default AdmissionCampaignBlock;
