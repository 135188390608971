import React, { FC, useMemo } from 'react';

import { ReactComponent as ChatIcon } from 'assets/images/icons/chat.svg';
import { ReactComponent as CrossIcon } from 'assets/images/icons/cross.svg';
import useChatIsEnrolleeReplier from 'hooks/chat/useChatIsEnrolleeReplier';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import { ChatType } from 'models/chat';
import { setChatOpened } from 'redux/actions';
import {
  selectChatOpened,
  selectUnreadCount,
} from 'redux/selectors/common/chat';

const ChatToggle: FC = () => {
  const dispatch = useAppDispatch();
  const chatOpened = useAppSelector(selectChatOpened);
  const unreadCount = useAppSelector(selectUnreadCount) || {};
  const isEnrolleeReplier = useChatIsEnrolleeReplier();

  const unreadCountSum = useMemo(
    () =>
      isEnrolleeReplier
        ? unreadCount[ChatType.ENROLLEE] || 0
        : Object.values(unreadCount).reduce((acc, count) => acc + count, 0),
    [unreadCount, isEnrolleeReplier]
  );

  const toggleChat = () => dispatch(setChatOpened(!chatOpened));

  return (
    <div className="chat-toggle" onClick={toggleChat}>
      {unreadCountSum > 0 && (
        <div className="chat-toggle__badge">
          {unreadCountSum > 99 ? '...' : unreadCountSum}
        </div>
      )}
      {chatOpened ? (
        <CrossIcon className="chat-toggle__icon" />
      ) : (
        <ChatIcon className="chat-toggle__icon" />
      )}
    </div>
  );
};

export default ChatToggle;
