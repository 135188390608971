import React, { FC } from 'react';

import classnames from 'classnames';

import Button from 'components/common/ui-kit/Button';
import Input from 'components/common/ui-kit/Input';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import terms from 'i18n';
import { showModal } from 'redux/actions';
import { selectUser } from 'redux/selectors';

type ProfileAuthProps = {
  className?: string;
};

const ProfileAuth: FC<ProfileAuthProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  return (
    <div className={classnames('profile-auth-block', className)}>
      <div className="profile-auth-block__title form__subtitle">
        {terms.AUTHORIZATION}
      </div>
      <Input
        placeholder={terms.EMAIL}
        className="profile-auth-block__input"
        readOnly={true}
        value={user?.email || ''}
      />
      <Button
        className="profile-auth-block__button"
        onClick={() => dispatch(showModal({ name: 'CHANGE_PASSWORD_MODAL' }))}
        type="button"
      >
        {terms.CHANGE_PASSWORD}
      </Button>
    </div>
  );
};

export default ProfileAuth;
