import { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import terms from 'i18n';
import { getIsoDate } from 'utils/date';

import { Contract, ContractType } from '../../../models/applications/contracts';
import { SelectOption } from '../../../models/common';
import { createToast } from '../../../redux/actions';
import { contractApi } from '../../../redux/api/applications/contract-api';
import useAppDispatch from '../../common/useAppDispatch';
import { useGetContractPaymentTypesSelectProps } from '../contract/useGetContractPaymentTypesSelectProps';
import { useGetCreateContractSelectOptions } from '../contract/useGetDirectionSelectOptions';

type EntrantsContractForm = {
  directionId: SelectOption;
  paymentForm: SelectOption;
  contractDate?: string;
};

const useEntrantsContractForm = (
  isDateFieldShown: boolean,
  contract?: Contract,
  isFillingStatementsForm?: boolean,
  onSubmitProp?: (res) => void
) => {
  const navigate = useNavigate();
  const [createContract] = contractApi.useLazyCreateQuery();
  const [updateContract] = contractApi.useUpdateContractMutation();
  const dispatch = useAppDispatch();

  const directionOptions = useGetCreateContractSelectOptions(
    Boolean(contract),
    contract?.applicationId
  );
  const paymentForms = useGetContractPaymentTypesSelectProps();

  const { register, handleSubmit, watch, setValue, getValues, ...rest } =
    useForm<EntrantsContractForm>({ mode: 'all' });

  useEffect(() => {
    if (contract && directionOptions.length) {
      rest.reset({
        directionId: directionOptions.find(
          d => d.value === contract.applicationDirectionId
        ),
        paymentForm: paymentForms.find(
          p => p.value === contract.paymentType.id
        ),
      });
    }
    if (isDateFieldShown) {
      setValue(
        'contractDate',
        contract?.contractDate ?? getIsoDate(new Date())
      );
    }
  }, [directionOptions.length, paymentForms.length, isDateFieldShown]);

  const fields = {
    directionId: register('directionId', {
      required: terms.REQUIRED_FIELD,
    }),
    paymentForm: register('paymentForm', {
      required: terms.REQUIRED_FIELD,
    }),
    contractDate: register('contractDate', {
      required: isDateFieldShown && terms.REQUIRED_FIELD,
    }),
  };

  const onSubmit = (contractType: ContractType) =>
    handleSubmit(data => {
      const normalizedData = {
        applicationDirectionId: data.directionId.value,
        contractType: contractType,
        paymentTypeId: data.paymentForm.value,
      };
      const contractDate = data.contractDate;
      if (isDateFieldShown) {
        normalizedData['contractDate'] = contractDate;
      }
      let request;
      if (contract) {
        request = updateContract({
          id: contract.id,
          ...normalizedData,
        }).unwrap();
      } else {
        request = createContract(normalizedData)
          .unwrap()
          .catch(() =>
            dispatch(createToast(terms.CREATE_CONTRACT_ERROR, 'danger'))
          );
      }
      request.then(res => {
        if (isFillingStatementsForm) {
          navigate('..');
        }
        onSubmitProp?.(res);
      });
    });

  return {
    fields,
    onSubmit,
    setValue,
    getValues,
    watch,
    ...rest,
    directionOptions,
    paymentForms,
  };
};

export default useEntrantsContractForm;
