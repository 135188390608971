// Склонение существительных по числам
export const getNounDeclension = (n, word_forms) => {
  n = Math.abs(n) % 100;
  const n1 = n % 10;

  if (n > 10 && n < 20) {
    return word_forms[2];
  }
  if (n1 > 1 && n1 < 5) {
    return word_forms[1];
  }
  if (n1 === 1) {
    return word_forms[0];
  }
  return word_forms[2];
};
