import terms, { isRussianLocale } from 'i18n';

const useLazyIdEnumSelectPropsWithParams = (
  useGetEnumQuery: Function,
  params: any
) => {
  const { data, isLoading, isError } = useGetEnumQuery(params);

  return {
    options: data
      ? data.map?.(el => ({
          id: el.id,
          value: el.id,
          label: isRussianLocale() ? el.title : el.titleForeign,
        }))
      : [],
    isLoading,
    error: isError && !data ? terms.LOADING_ERROR : undefined,
  };
};

export default useLazyIdEnumSelectPropsWithParams;
