import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RouteStatus } from '../../../types/custom';

export type Draft = keyof typeof initialState.draftStates;

export type QuestionnaireState = {
  readonly questionnaireId: number | null;
  readonly readonly: boolean;
  readonly passportDraft: {
    readonly needVisa: boolean;
    readonly isCompatriot: boolean;
  };
  draftStates: {
    generalData: RouteStatus;
    legalRepresentative: RouteStatus;
    passport: RouteStatus;
    compatriot: RouteStatus;
    visa: RouteStatus;
    addresses: RouteStatus;
    educationInformation: RouteStatus;
  };
};

export const initialState: QuestionnaireState = {
  questionnaireId: null,
  readonly: false,
  passportDraft: {
    needVisa: false,
    isCompatriot: false,
  },
  draftStates: {
    generalData: 'not visited',
    legalRepresentative: 'not visited',
    passport: 'not visited',
    compatriot: 'not visited',
    visa: 'not visited',
    addresses: 'not visited',
    educationInformation: 'not visited',
  },
};

export const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    initiatePassportDraft: (
      state,
      action: PayloadAction<{
        readonly needVisa: boolean | undefined;
        readonly isCompatriot: boolean | undefined;
      }>
    ) => {
      state.passportDraft.needVisa = action.payload.needVisa ?? false;
      state.passportDraft.isCompatriot = action.payload.isCompatriot ?? false;
    },
    setNeedVisa: (state, action: PayloadAction<boolean | undefined>) => {
      state.passportDraft.needVisa = action.payload ?? false;
    },
    setIsCompatriot: (state, action: PayloadAction<boolean | undefined>) => {
      state.passportDraft.isCompatriot = action.payload ?? false;
    },
    setQuestionnaireId: (state, action: PayloadAction<number>) => {
      state.questionnaireId = action.payload;
    },
    updateDraftStatus: (
      state,
      action: PayloadAction<{
        readonly draft: keyof typeof state.draftStates;
        readonly status: RouteStatus;
      }>
    ) => {
      state.draftStates[action.payload.draft] = action.payload.status;
    },
    setReadonly: (state, action) => {
      state.readonly = action.payload;
    },
  },
});

export default questionnaireSlice.reducer;
