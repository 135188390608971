import React, { FC } from 'react';

import classNames from 'classnames';
import './Separator.scss';

type SeparatorProps = {
  className?: string;
};

const Separator: FC<SeparatorProps> = ({ className }) => {
  return <div className={classNames(className, 'separator')} />;
};

export default Separator;
