import { useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { useSetProfileSubjectScoreMutation } from '../../../redux/api/staff/staff-applications-api';
import regexp from '../../../utils/regexp';
import useDebouncedFunction from '../../common/useDebouncedFunction';

export type ModeratorDirectionElementForm = {
  profileSubjectScore: number;
};

const useModeratorDirectionElementForm = (
  applicationDirectionId: number,
  profileSubjectScore: number | null | undefined
) => {
  const { register, setValue, ...rest } =
    useForm<ModeratorDirectionElementForm>({
      mode: 'all',
    });

  const [updateProfileSubjectScore] = useSetProfileSubjectScoreMutation();

  useEffect(() => {
    if (profileSubjectScore)
      setValue('profileSubjectScore', profileSubjectScore);
  }, [profileSubjectScore]);

  const onProfileSubjectScoreChange = useDebouncedFunction(
    (value: number | undefined) => {
      updateProfileSubjectScore({
        applicationDirectionId: applicationDirectionId,
        profileSubjectScore: value,
      });
    },
    500
  );

  const fields = {
    profileSubjectScore: register('profileSubjectScore', {
      pattern: {
        value: regexp.averageScore,
        message: 'Средний балл до двух знаков после запятой',
      },
      onChange: ({ target: { value } }) => {
        if (regexp.averageScore.test(value)) {
          onProfileSubjectScoreChange(value);
        } else {
          onProfileSubjectScoreChange(undefined);
        }
      },
    }),
  };

  return { fields, ...rest };
};

export default useModeratorDirectionElementForm;
