import { ReactNode } from 'react';

export type ModeratorStatementsSectionKey =
  | 'basicData'
  | 'legalRepresentative'
  | 'passportData'
  | 'visa'
  | 'actualAddress'
  | 'realAddress'
  | 'educationalBackground'
  | 'documents'
  | 'hallmarks'
  | 'examResults'
  | 'directions'
  | 'targetContract'
  | 'additionalForms'
  | 'duplicates'
  | 'downloads'
  | 'expert-examination'
  | 'epguInfo'
  | '1c';

export enum DataListItemAlignment {
  CENTER,
  START,
}

export type DataList = {
  title: string;
  label: ReactNode;
  alignment?: DataListItemAlignment;
}[];
