import React, { FC } from 'react';

import './ImpersonateExitModal.scss';
import TemplateModal from 'components/common/modals/TemplateModal';
import Button from 'components/common/ui-kit/Button';
import useAppSelector from 'hooks/common/useAppSelector';
import terms from 'i18n';
import { useForceAcceptApplicationMutation } from 'redux/api/staff/staff-applications-api';
import { modalDataSelector } from 'redux/selectors';

import useAppDispatch from '../../../../hooks/common/useAppDispatch';
import { createToast, hideModal } from '../../../../redux/actions';

const ForceAcceptApplicationModal: FC = () => {
  const dispatch = useAppDispatch();
  const { applicationId, onSuccess } = useAppSelector(modalDataSelector);
  const [forceAccept] = useForceAcceptApplicationMutation();
  const closeModal = () => dispatch(hideModal());

  const handleConfirmation = () => {
    forceAccept(applicationId)
      .unwrap()
      .then(() => {
        onSuccess();
        closeModal();
      })
      .catch(() => {
        dispatch(
          createToast('Во время одобрения заявления произошла ошибка', 'danger')
        );
      });
  };

  return (
    <TemplateModal title="Во время одобрения заявления произошла ошибка. Всё равно одобрить?">
      <div>
        <div style={{ display: 'flex', gap: 20, marginTop: 20 }}>
          <Button theme="success" onClick={handleConfirmation}>
            {terms.YES}
          </Button>
          <Button
            onClick={() => {
              closeModal();
            }}
          >
            {terms.NO}
          </Button>
        </div>
      </div>
    </TemplateModal>
  );
};

export default ForceAcceptApplicationModal;
