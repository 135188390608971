import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Crop } from 'react-image-crop';

import useAppDispatch from 'hooks/common/useAppDispatch';
import {
  Invalidateable,
  useRefetchQuestionnaire,
} from 'hooks/questionnaire/common/draft/useQuestionnaire';
import terms from 'i18n';
import { createToast, hideModal } from 'redux/actions';
import { useUploadFileMutation } from 'redux/api/common/files-api';
import { userApi, useUpdateUserMutation } from 'redux/api/common/user-api';
import { getCroppedImage, rotateBase64Image90deg } from 'utils/transform-image';

import { UserData, UserRole } from '../../models/user';
import { userSlice } from '../../redux/slices/common/user-slice';
import { useGetUserAvatar } from '../common/useGetUserAvatar';

const initialCrop: Crop = {
  aspect: 3 / 4,
  width: 105,
  height: 140,
  x: 0,
  y: 0,
  unit: 'px',
};

const useViewerProfileImage = (
  user: UserData,
  isImpersonate = false,
  userId?: number | null
) => {
  const dispatch = useAppDispatch();

  const [getUser] = userApi.useLazyGetUserQuery();

  const [crop, setCrop] = useState<Partial<Crop>>(initialCrop);
  const [image, setImage] = useState('');

  const { image: viewerImage, isLoading } = useGetUserAvatar(
    user?.id,
    user?.photoUploadId
  );

  // const viewerImage = useAppSelector(selectUserProfileImage);

  const imgRef = useRef(new Image());
  const canvasRef = useRef(document.createElement('canvas'));

  // useEffect(() => {
  //   if (fetchedUser) {
  //     const fetchedRole: UserRole = UserRole[fetchedUser.role];
  //     dispatch(
  //       userSlice.actions.setUser({ ...fetchedUser, role: fetchedRole })
  //     );
  //   }
  // }, [fetchedUser]);

  useEffect(() => {
    if (!image) {
      setCrop({});
    }
  }, [image]);

  useEffect(() => {
    if (viewerImage) {
      setImage(viewerImage);
      setCrop(initialCrop);
    }
  }, [viewerImage]);

  const onUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const img = e.target.files[0];

      if (img.size > 3_000_000) {
        return dispatch(
          createToast('Размер файла не может быть больше 3мб', 'danger')
        );
      } else if (
        ![
          'image/png',
          'image/webp',
          'image/jpeg',
          'image/gif',
          'image/avif',
        ].includes(img.type)
      ) {
        return dispatch(
          createToast(`Формат файла ${img.type} не поддерживается`, 'danger')
        );
      }

      setImage(URL.createObjectURL(img));
      setCrop(initialCrop);
    }
  };

  const onRotate = (direction: 'left' | 'right') => {
    const result = rotateBase64Image90deg(image, direction === 'right');

    if (result) {
      setImage(result);
      setCrop(initialCrop);
    }
  };

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  const [uploadFile] = useUploadFileMutation();
  const [updateUser] = useUpdateUserMutation();

  const refetchQuestionnaire = useRefetchQuestionnaire();

  const onSave = async () => {
    if (!userId) return;
    const base64 = image
      ? await getCroppedImage(crop as Crop, canvasRef.current, imgRef.current)
      : '';
    if (base64)
      uploadFile({ userId, file: base64 })
        .unwrap()
        .then(photoId =>
          updateUser({
            username: user.username,
            email: user.email,
            photoUploadId: photoId,
            id: userId,
          })
            .unwrap()
            .then(() => photoId)
        )
        .then(() => {
          dispatch(createToast(terms.PROFILE_PHOTO_UPDATED, 'success'));
          if (isImpersonate) {
            refetchQuestionnaire(false, Invalidateable.QUESTIONNAIRE);
          } else {
            getUser()
              .unwrap()
              .then(res => {
                const fetchedRole: UserRole = UserRole[res.role];
                dispatch(
                  userSlice.actions.setUser({ ...res, role: fetchedRole })
                );
              });
          }
          dispatch(hideModal());
        })
        .catch(() => dispatch(createToast(terms.ERROR_HAS_OCCURRED, 'danger')));
  };

  const onClear = () => {
    setImage(viewerImage || '');
    setCrop({});
  };

  return {
    crop,
    image,
    onLoad,
    onSave,
    onClear,
    setCrop,
    onUpload,
    onRotate,
  };
};

export default useViewerProfileImage;
