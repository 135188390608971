import { useGetEducationLevelsQuery } from '../../../redux/api/common/enum-api';
import { messagesStatusSelectOptions } from '../../../utils/enum-select';
import { useGetCountriesSelectProps } from '../../common/api/useGetCountriesSelectProps';
import useLazyEnumSelectProps from '../../common/useI18nEnumSelectProps';

export const useQuestionnaireFilterOptions = () => {
  const educationLevelSelectProps = useLazyEnumSelectProps(
    useGetEducationLevelsQuery
  );

  const countriesSelectProps = useGetCountriesSelectProps();

  return {
    educationLevelSelectProps,
    countriesSelectProps,
    messagesStateSelectProps: {
      options: messagesStatusSelectOptions,
    },
  };
};
