import {
  Questionnaire,
  QuestionnaireId,
} from 'models/questionnaire/questionnaire';
import { apiSlice } from 'redux/api/api-slice';

import { User } from '../../../models/enrollee';
import { UserId } from '../../../models/user';

const baseUrl = `${process.env.REACT_APP_USER_MANAGER_API_URL}/enrollees`;

export const enrolleesApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    isEnrolleeExists: builder.query<boolean, UserId>({
      query: userId => `${baseUrl}/exists-by-user/${userId}`,
      providesTags: ['IsEnrolleeExists'],
    }),

    createEnrolleeFromQuestionnaire: builder.mutation<any, QuestionnaireId>({
      query: questionnaireId => ({
        url: `${baseUrl}/from-questionnaire/${questionnaireId}`,
        method: 'POST',
      }),
    }),

    getByUserId: builder.query<Questionnaire & { readonly user: User }, UserId>(
      {
        query: userId => ({
          url: `${baseUrl}/by-user/${userId}`,
          method: 'GET',
        }),
        providesTags: ['Enrollee'],
      }
    ),

    canBeDeleted: builder.query<boolean, number>({
      query: enrolleeId => ({
        url: `${baseUrl}/${enrolleeId}/can-be-deleted`,
      }),
    }),

    deleteEnrollee: builder.query<void, number>({
      query: enrolleeId => ({
        url: `${baseUrl}/${enrolleeId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreateEnrolleeFromQuestionnaireMutation,
  useGetByUserIdQuery,
  useLazyGetByUserIdQuery,
  useLazyCanBeDeletedQuery,
  useLazyDeleteEnrolleeQuery,
} = enrolleesApi;
