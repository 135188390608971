import React, { forwardRef } from 'react';

import classNames from 'classnames';

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  text?: string;
  disabled?: boolean;
  inputClassName?: string;
  theme?: 'default' | 'success-filled';
  cardSignature?: boolean;
};

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      text,
      theme = 'default',
      disabled,
      inputClassName,
      cardSignature,
      ...rest
    },
    ref
  ) => {
    return (
      <label
        className={classNames(
          'check',
          disabled ? 'disabled' : null,
          rest.className,
          {
            [`check--theme-${theme}`]: theme !== 'default',
            ['label-signature']: cardSignature,
            active: rest.checked,
          }
        )}
      >
        <input
          type="checkbox"
          {...rest}
          disabled={disabled}
          className={classNames('check__input', inputClassName, {
            [`check__input--theme-${theme}`]: theme !== 'default',
          })}
          ref={ref}
        />
        <span
          className={classNames('check__box', {
            ['check-signature']: cardSignature,
          })}
        />
        {text && <span>{text}</span>}
      </label>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
