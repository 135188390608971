import React, { FC, useEffect, useMemo, useState } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import Checkbox from 'components/common/ui-kit/Checkbox';
import { InputV2 } from 'components/common/ui-kit/Input/view';
import ReactSelect from 'components/common/ui-kit/Select/view';
import ProfileSection from 'components/profile/ProfileSection';
import { useGetCountriesSelectProps } from 'hooks/common/api/useGetCountriesSelectProps';
import {
  SavedCompletion,
  ForeignAddressInfo,
} from 'hooks/questionnaire/addressesDraft/useAddressesForm';
import { useIsRussianEnrollee } from 'hooks/questionnaire/common/draft/useIsRussianEnrollee';
import terms from 'i18n';
import { SelectOption } from 'models/common';
import {
  COUNTRY_BELARUS_ID,
  COUNTRY_KAZAKHSTAN_ID,
  COUNTRY_RUSSIA_ID,
  COUNTRY_UZBEKISTAN_ID,
} from 'models/enum';
import {
  DadataCountryIsoCode,
  DadataSuggestion,
} from 'models/questionnaire/dadata';
import {
  createCheckboxProps,
  createInputV2Props,
  createSelectProps,
} from 'utils/form-helpers';
import regexp from 'utils/regexp';

import { DadataAddressAutocomplete } from '../dadata';
import {
  getFieldsDisabledStatusesFromDadataSuggestion,
  getFieldsDisabledStatusesFromSavedCompletion,
} from '../view';

export type TwoStepDadataFormBodyFields =
  | keyof Pick<
      ForeignAddressInfo,
      | 'completion'
      | 'region'
      | 'district'
      | 'city'
      | 'street'
      | 'house'
      | 'houseExtension'
      | 'apartment'
      | 'postcode'
    >
  | 'country'
  | 'isCompletionManuallyDisabled'
  | 'hasNoStreet';

export type AddressFormBodyProps = {
  fieldsToProps: {
    [key in TwoStepDadataFormBodyFields]: {
      name: string;
      placeholder?: string;
      isRequired?: boolean;
    };
  };
  fieldNamesToValues?: any;
  isReadOnly?: boolean;
  fieldsToDisabledState?;
};

export type TwoStepFormBodyProps = AddressFormBodyProps & {
  autocompleteValue?: string;
};

const AddressFormBody: FC<AddressFormBodyProps> = ({
  fieldsToProps,
  fieldNamesToValues,
  isReadOnly,
  fieldsToDisabledState,
}) => {
  const {
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useFormContext();
  const inputV2Props = createInputV2Props(errors, isReadOnly);
  const { isRussianEnrollee } = useIsRussianEnrollee();

  useEffect(() => {
    if (!fieldNamesToValues) return;
    reset(fieldNamesToValues);
  }, [fieldNamesToValues]);

  const hasNoStreet = watch(fieldsToProps.hasNoStreet.name);

  return (
    <div className="profile-form__fields">
      <Controller
        name={fieldsToProps.region.name}
        control={control}
        rules={{
          required: terms.REQUIRED_FIELD,
          pattern: {
            value: regexp.ruOrEnWithSpecialSignsAndBrackets,
            message: terms.CYRILLIC_OR_LATIN_CHARACTERS_ONLY,
          },
        }}
        disabled={fieldsToDisabledState?.region ?? false}
        render={({ field }) => (
          <InputV2
            className="profile-form__field"
            placeholder={terms.REGION}
            markRequired={isRussianEnrollee}
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name={fieldsToProps.district.name}
        control={control}
        rules={{
          pattern: {
            value: regexp.ruOrEnWithSpecialSignsAndBrackets,
            message: terms.CYRILLIC_OR_LATIN_CHARACTERS_ONLY,
          },
        }}
        disabled={fieldsToDisabledState?.district ?? false}
        render={({ field }) => (
          <InputV2
            className="profile-form__field"
            placeholder={terms.DISTRICT}
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name={fieldsToProps.city.name}
        control={control}
        rules={{
          required: terms.REQUIRED_FIELD,
          pattern: {
            value: regexp.ruOrEnWithSpecialSignsAndBrackets,
            message: terms.CYRILLIC_OR_LATIN_CHARACTERS_ONLY,
          },
        }}
        disabled={fieldsToDisabledState?.city ?? false}
        render={({ field }) => (
          <InputV2
            className="profile-form__field"
            placeholder={terms.CITY}
            markRequired
            {...inputV2Props(field)}
          />
        )}
      />
      <div className="profile-form__field">
        <Controller
          name={fieldsToProps.street.name}
          control={control}
          rules={{
            required: {
              value: !hasNoStreet,
              message: terms.REQUIRED_FIELD,
            },
            pattern: {
              value: regexp.ruOrEnWithSpecialSignsAndBrackets,
              message: terms.CYRILLIC_OR_LATIN_CHARACTERS_ONLY,
            },
          }}
          disabled={hasNoStreet || (fieldsToDisabledState?.street ?? false)}
          render={({ field }) => (
            <InputV2
              placeholder={terms.STREET}
              markRequired={!hasNoStreet}
              {...inputV2Props(field)}
            />
          )}
        />

        <Checkbox
          text={terms.NO_STREET}
          className="profile-form__checkbox"
          checked={hasNoStreet}
          onChange={() =>
            setValue(fieldsToProps.hasNoStreet.name, !hasNoStreet)
          }
          disabled={isReadOnly || fieldsToDisabledState?.street}
        />
      </div>
      <div className="profile-form__field profile-form__field--container">
        <Controller
          name={fieldsToProps.house.name}
          control={control}
          rules={{
            required: terms.REQUIRED_FIELD,
            pattern: {
              value: regexp.ruOrEnWithSpecialSignsAndBrackets,
              message: terms.CYRILLIC_OR_LATIN_CHARACTERS_ONLY,
            },
          }}
          disabled={fieldsToDisabledState?.house ?? false}
          render={({ field }) => (
            <InputV2
              className="profile-form__input profile-form__input--fb-33"
              placeholder={terms.BUILDING}
              markRequired
              {...inputV2Props(field)}
            />
          )}
        />

        <Controller
          name={fieldsToProps.houseExtension.name}
          control={control}
          rules={{
            pattern: {
              value: regexp.ruOrEnWithSpecialSignsAndBrackets,
              message: terms.CYRILLIC_OR_LATIN_CHARACTERS_ONLY,
            },
          }}
          disabled={fieldsToDisabledState?.houseExtension ?? false}
          render={({ field }) => (
            <InputV2
              className="profile-form__input profile-form__input--fb-33"
              placeholder={terms.SITE}
              {...inputV2Props(field)}
            />
          )}
        />

        <Controller
          name={fieldsToProps.apartment.name}
          control={control}
          rules={{
            pattern: {
              value: regexp.ruOrEnWithSpecialSignsAndBrackets,
              message: terms.CYRILLIC_OR_LATIN_CHARACTERS_ONLY,
            },
          }}
          disabled={fieldsToDisabledState?.apartment ?? false}
          render={({ field }) => (
            <InputV2
              className="profile-form__input profile-form__input--fb-33"
              placeholder={terms.Apartment}
              {...inputV2Props(field)}
            />
          )}
        />
      </div>
      <Controller
        name={fieldsToProps.postcode.name}
        control={control}
        rules={{
          required: terms.REQUIRED_FIELD,
          pattern: {
            value: regexp.ruOrEnWithSpecialSigns,
            message: terms.CYRILLIC_OR_LATIN_CHARACTERS_ONLY,
          },
        }}
        disabled={fieldsToDisabledState?.postcode ?? false}
        render={({ field }) => (
          <InputV2
            className="profile-form__field"
            placeholder={terms.ZIP_CODE}
            markRequired
            {...inputV2Props(field)}
          />
        )}
      />
    </div>
  );
};

const getAddressFormFieldValuesFromSuggestion = (
  suggestion: DadataSuggestion
): { [key in TwoStepDadataFormBodyFields]?: string } => {
  const {
    area_with_type,
    region_with_type,
    city,
    street_with_type,
    house,
    block,
    postal_code,
    flat,
    settlement_with_type,
  } = suggestion.data;
  return {
    district: area_with_type ?? undefined,
    region: region_with_type ?? undefined,
    city: city ?? settlement_with_type ?? undefined,
    street: street_with_type ?? undefined,
    house: house ?? undefined,
    houseExtension: block ?? undefined,
    postcode: postal_code ?? undefined,
    apartment: flat ?? undefined,
  };
};

// const getFieldsCompletedFromSuggestion = ({data: {
//   are
// }}: DadataSuggestion): { [key in TwoStepDadataFormBodyFields]?: boolean } => {
//   return {
//     district:
//   }
// }

const AddressFormBodyWithAutocomplete: FC<TwoStepFormBodyProps> = ({
  fieldsToProps,
  isReadOnly,
  ...rest
}) => {
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const {
    control,
    watch,
    getValues,
    reset,
    formState: { errors },
    register,
  } = useFormContext();

  const checkboxProps = createCheckboxProps(errors, isReadOnly);

  const suggestion = watch(fieldsToProps.completion.name);
  const selectedCountryId = watch(fieldsToProps.country.name);
  const resetFormFieldsFromSuggestion = suggestion => {
    const fieldsToValues = getAddressFormFieldValuesFromSuggestion(suggestion);
    const fieldNamesToValues = Object.entries(fieldsToValues).reduce(
      (acc, [field, value]) => {
        const fieldName = fieldsToProps[field].name;
        acc[fieldName] = value;
        return acc;
      },
      {}
    );
    reset({
      ...getValues(),
      ...fieldNamesToValues,
    });
  };

  const isCompletionManuallyDisabled = watch(
    fieldsToProps.isCompletionManuallyDisabled.name
  );

  const fieldsToDisabledState = useMemo(() => {
    // return null;
    if (!suggestion || isCompletionManuallyDisabled) return null;
    const presentCompletion: DadataSuggestion | SavedCompletion =
      suggestion.value;
    if ('query' in presentCompletion) {
      return getFieldsDisabledStatusesFromSavedCompletion(presentCompletion);
    }
    return getFieldsDisabledStatusesFromDadataSuggestion(presentCompletion);
  }, [suggestion, isCompletionManuallyDisabled]);

  return (
    <>
      <Controller
        name={fieldsToProps.completion.name}
        control={control}
        rules={{
          required: !isCompletionManuallyDisabled && terms.REQUIRED_FIELD,
        }}
        render={({ field: { value, onChange, disabled } }) => (
          <DadataAddressAutocomplete
            placeholder="Поиск адреса"
            value={value}
            onSelect={option => {
              onChange(option);
              if (!option?.value?.data) return;
              resetFormFieldsFromSuggestion(option.value);
            }}
            countryIsoCode={
              countryIdsToDadataCountryIsoCodes[selectedCountryId]
            }
            inputValue={autocompleteValue ?? ''}
            onInputValueChange={value => {
              setAutocompleteValue(value);
            }}
            isDisabled={disabled || isCompletionManuallyDisabled || isReadOnly}
            markRequired={!isCompletionManuallyDisabled}
            error={errors[fieldsToProps.completion.name]?.message as string}
          />
        )}
      />
      <Checkbox
        text="Нет моего адреса"
        className="profile-form__checkbox addresses-form__checkbox"
        {...checkboxProps(
          register(fieldsToProps.isCompletionManuallyDisabled.name)
        )}
      />
      {/* <span
            style={{
              color: '#37b34a',
              cursor: 'pointer',
              marginTop: 5,
              fontSize: 14,
              pointerEvents: isReadOnly ? 'none' : undefined,
              opacity: isReadOnly ? '0.5' : undefined,
            }}
            onClick={disableDadata}
          ></span> */}
      {(suggestion || isCompletionManuallyDisabled) && (
        <AddressFormBody
          fieldsToProps={fieldsToProps}
          isReadOnly={isReadOnly}
          fieldsToDisabledState={fieldsToDisabledState}
          {...rest}
        />
      )}
    </>
  );
};

export const dadataCountryIds = [
  COUNTRY_RUSSIA_ID,
  COUNTRY_BELARUS_ID,
  COUNTRY_KAZAKHSTAN_ID,
  COUNTRY_UZBEKISTAN_ID,
];

export const countryIdsToDadataCountryIsoCodes = {
  [COUNTRY_RUSSIA_ID]: DadataCountryIsoCode.RU,
  [COUNTRY_BELARUS_ID]: DadataCountryIsoCode.BY,
  [COUNTRY_KAZAKHSTAN_ID]: DadataCountryIsoCode.KZ,
  [COUNTRY_UZBEKISTAN_ID]: DadataCountryIsoCode.UZ,
};

export type AddressFormProps = {
  title: string;
} & AddressFormBodyProps;

export const AddressForm: FC<AddressFormProps> = ({
  fieldsToProps,
  title,
  isReadOnly,
}) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const selectProps = createSelectProps(errors, isReadOnly);
  const countrySelectProps = useGetCountriesSelectProps();

  const selectedCountryId = watch(fieldsToProps.country.name);
  const isCountrySelected = selectedCountryId !== undefined;
  const isDadataAutocompletable =
    isCountrySelected && dadataCountryIds.includes(selectedCountryId);

  return (
    <ProfileSection title={title}>
      <Controller
        name={fieldsToProps.country.name}
        control={control}
        rules={{
          required: terms.REQUIRED_FIELD,
        }}
        render={({ field }) => (
          <ReactSelect<SelectOption>
            className="profile-form__field profile-form__field--fb-100"
            placeholder={terms.COUNTRY}
            markRequired
            {...selectProps(field, {
              options: countrySelectProps,
            })}
          />
        )}
      />
      {isDadataAutocompletable ? (
        <AddressFormBodyWithAutocomplete
          fieldsToProps={fieldsToProps}
          isReadOnly={isReadOnly}
        />
      ) : (
        <AddressFormBody
          fieldsToProps={fieldsToProps}
          isReadOnly={isReadOnly}
        />
      )}
    </ProfileSection>
  );
};

export default AddressForm;
