import { useEffect, useState } from 'react';

import { UseFormReturn } from 'react-hook-form';

import { calculateMeanGrade } from 'components/profile/EducationForm/EducationDocumentFields';
import {
  EducationInformation,
  EducationInformationForm,
} from 'components/profile/EducationForm/view';

import terms, { isRussianLocale } from '../../../i18n';
import { COUNTRY_RUSSIA_ID } from '../../../models/enum';
import { KladrAddress } from '../../../models/kladr';
import { EducationInformationDraft } from '../../../models/questionnaire/questionnaire';
import { createToast } from '../../../redux/actions';
import { kladrApi } from '../../../redux/api/questionnaire/kladr-api';
import useAppDispatch from '../../common/useAppDispatch';
import { isGeneralDataFormSpecified } from '../common/draft/common';
import { useGeneralDataDraft } from '../common/draft/useGeneralDataDraft';
import { useIsEnrolleeRussian } from '../common/draft/useIsRussianEnrollee';
import { useNonValidFields } from '../common/util/useNonValidFields';
import { useUpdateDraftStatus } from '../common/util/useUpdateDraftStatus';
import { useEducationInformationDraft } from './useEducationInformationDraft';

const POLITECH_NAME_RU = 'ФГАОУ ВО «СПбПУ»';

export const useGetEducationFormWithValidation = ({
  reset,
  setValue,
  setError,
  watch,
}: UseFormReturn<EducationInformationForm, any, EducationInformationForm>) => {
  const dispatch = useAppDispatch();
  const fields = watch('education');

  const { isEnrolleeRussian, setIsEnrolleeRussian } = useIsEnrolleeRussian();
  const updateDraftStatus = useUpdateDraftStatus('educationInformation');
  const { nonValidFields, addNonValidField, clearNonValidFields } =
    useNonValidFields<EducationInformation>();

  const { draft: generalDataDraft, isDraftLoading: isGeneralDataDraftLoading } =
    useGeneralDataDraft();

  const [getAddress] = kladrApi.useLazyGetKladrAddressByIdQuery();

  const [addresses, setAddresses] = useState<Array<KladrAddress | undefined>>(
    []
  );

  const {
    draft: educationInformationDraft,
    saveDraft: saveEducationInformationDraft,
    ...educationInformationDraftInfo
  } = useEducationInformationDraft();

  const resetField = (
    field: keyof EducationInformation,
    idx: number,
    error: string
  ) => {
    setValue(`education.${idx}.${field}`, undefined);
    setError(`education.${idx}.${field}`, {
      type: 'custom',
      message: error,
    });
  };

  const resetNonValidFields = (
    fields: (keyof EducationInformation)[],
    idx: number,
    error: string,
    toastMessage: string
  ) => {
    fields.forEach(field => {
      resetField(field, idx, error);
      addNonValidField(field);
    });
    dispatch(createToast(toastMessage, 'danger'));
  };

  useEffect(() => {
    if (generalDataDraft) {
      setIsEnrolleeRussian(generalDataDraft);
    }
  }, [generalDataDraft]);

  useEffect(() => {
    educationInformationDraft?.forEach(el => {
      if (el && el.russianKladrEducationRegion) {
        getAddress({
          type: 'region',
          code: el.russianKladrEducationRegion,
        })
          .unwrap()
          .then(value => {
            setAddresses(prev => [...prev, value]);
          });
      } else {
        setAddresses(prev => [...prev, undefined]);
      }
    });
  }, [educationInformationDraft]);

  useEffect(() => {
    if (generalDataDraft && educationInformationDraft) {
      clearNonValidFields();
      updateDraftStatus('not visited');

      educationInformationDraft.forEach((el, idx) => {
        const address = addresses.find(
          addr => addr?.kladrId === el.russianKladrEducationRegion
        );
        const meanGrade = calculateMeanGrade(
          el.satisfactoryGrades,
          el.goodGrades,
          el.excellentGrades
        );
        if (el.educationCountry?.id === COUNTRY_RUSSIA_ID && !!address) {
          fields[idx] = {
            ...el,
            graduatedFromSpbpu: el.educationalInstitution === POLITECH_NAME_RU,
            meanGrade,
            educationCountryId: el.educationCountry?.id,
            russianKladrEducationRegion: {
              label: isRussianLocale()
                ? address.fullTitle
                : address.foreignTitle,
              value: address.kladrId,
            },
          };
        } else {
          fields[idx] = {
            ...el,
            graduatedFromSpbpu: el.educationalInstitution === POLITECH_NAME_RU,
            meanGrade,
            educationCountryId: el.educationCountry?.id,
          } as EducationInformation;
        }

        reset({
          education: fields,
        });
      });

      if (
        nonValidFields.length === 0 &&
        isEducationInformationDraftSpecified(educationInformationDraft)
      ) {
        updateDraftStatus('valid');
      }
    }
  }, [generalDataDraft, educationInformationDraft, addresses]);

  useEffect(() => {
    if (nonValidFields.length !== 0) {
      updateDraftStatus('invalid');
    }
  }, [nonValidFields]);

  const isGraduationYearGreaterThanBirthdayYear = (
    idx: number,
    birthday: string,
    graduationYear: number
  ): boolean => {
    const birthdayYear = new Date(birthday).getFullYear();
    if (graduationYear - birthdayYear < 14) {
      resetNonValidFields(
        ['graduationYear', 'educationalDocumentIssuedAt'],
        idx,
        terms.REQUIRED_FIELD,
        terms.ERROR_GRAD_YEAR_SHOULD_BE_GREATER_THAN_BIRTHDAY
      );

      return false;
    }

    return true;
  };

  const isDraftValid = (
    draft: EducationInformationDraft,
    idx: number
  ): boolean => {
    clearNonValidFields();

    if (isGeneralDataDraftLoading) return false;
    if (!generalDataDraft || !isGeneralDataFormSpecified(generalDataDraft)) {
      return true;
    }

    if (
      generalDataDraft?.birthday &&
      draft.graduationYear &&
      !isGraduationYearGreaterThanBirthdayYear(
        idx,
        generalDataDraft.birthday,
        draft.graduationYear
      )
    ) {
      return false;
    }

    updateDraftStatus('valid');
    return true;
  };

  return {
    saveDraft: saveEducationInformationDraft,
    isEnrolleeRussian,
    isDraftValid,
    ...educationInformationDraftInfo,
  };
};

export const isEducationInformationDraftSpecified = (
  forms: EducationInformationDraft[]
) =>
  forms.reduce((res, form) => {
    return (
      res &&
      form.documentCopyUploadId !== null &&
      !!form.documentType &&
      !!form.educationCountry?.id &&
      !!form.educationLevel &&
      !!form.educationalDocumentIssuedAt &&
      !!form.educationalDocumentNumber &&
      !!form.educationalInstitution &&
      !!form.graduationYear
    );
  }, true);
