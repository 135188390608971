import { useEffect, useState } from 'react';

import { useDebounce } from 'hooks/questionnaire/common/util/useDebounce';

import { isRussianLocale } from '../../../../i18n';
import { KladrAddress } from '../../../../models/kladr';
import { kladrApi } from '../../../../redux/api/questionnaire/kladr-api';

export const useKladrRegions = (onPick?: (address?: KladrAddress) => void) => {
  const [getRegions, { data: regions, isLoading }] =
    kladrApi.useLazyGetRegionsQuery();

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    getRegions({ name: '' });
  }, []);

  const onChange = event => {
    if (event) {
      onPick?.(regions?.find(v => v.kladrId === event.value));
    } else {
      onPick?.(undefined);
    }
  };

  const onInputChange = (inputValue, actionMeta) => {
    if (
      actionMeta.action === 'input-change' ||
      inputValue !== actionMeta.prevInputValue
    ) {
      setSearch(inputValue);
    }
  };

  useEffect(() => {
    getRegions({ name: debouncedSearch });
  }, [debouncedSearch]);

  return {
    onInputChange,
    isLoading,
    onChange,
    options: (regions ?? []).map(region => ({
      value: region.kladrId,
      label: isRussianLocale() ? region.fullTitle : region.foreignTitle,
    })),
  };
};
