import React, { FC } from 'react';

import classnames from 'classnames';

import terms from 'i18n';

import { ApplicationUserEducationalInformation } from '../../../../models/applications/application-user-educational-information';
import { formatDateYYYYMMDD } from '../../../../utils/date';

type EducationalDocumentElementProps = {
  element: ApplicationUserEducationalInformation;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  active: boolean;
};

const EducationalDocumentElement: FC<EducationalDocumentElementProps> = ({
  element,
  onClick,
  active,
}) => {
  return (
    <div
      className={classnames(
        'educational-information-list__educational-information-element',
        { active }
      )}
      onClick={onClick}
    >
      <h2>{terms[element.documentType]}</h2>
      <ul>
        <li>
          {terms.EDUCATIONAL_INSTITUTION}:{' '}
          <b>{element.educationalInstitution}</b>
        </li>
        <li>
          {terms.DOCUMENT_SERIES_AND_NUMBER}:{' '}
          <b>{element.educationalDocumentNumber}</b>
        </li>
        <li>
          {terms.DATE_OF_ISSUE}:{' '}
          <b>{formatDateYYYYMMDD(element.educationalDocumentIssuedAt)}</b>
        </li>
      </ul>
    </div>
  );
};

export default EducationalDocumentElement;
