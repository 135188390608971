import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import useAppDispatch from 'hooks/common/useAppDispatch';
import terms from 'i18n';
import {
  normalizeYearTo4Characters,
  useDateLessThanCurrentAndMoreThanBirthdayValidator,
} from 'utils/form-validators';

import { Achievement } from '../../../models/applications/achievements';
import { DateString, Entity, SelectOption } from '../../../models/common';
import { createToast, hideModal } from '../../../redux/actions';
import { achievementsApi } from '../../../redux/api/applications/achievements-api';
import { selectApplicationEducationLevel } from '../../../redux/selectors/enrollee/application';
import { useLazyUploadFiles } from '../../files/useLazyUploadFiles';

type AddAchievementForm = {
  readonly achievementType: SelectOption;
  readonly confirmationDocumentType?: SelectOption;
  readonly dateOfIssue: DateString;
  readonly documentCode: string;
  readonly amount?: number;
  readonly additionalInfo?: string;

  readonly documentId: number;
  readonly isDocumentAttached: boolean;
};

const useAddAchievementForm = (
  applicationId: number,
  achievement: Achievement,
  refetchAchievementsForm: () => void,
  userId?: number | null
) => {
  const dispatch = useAppDispatch();
  const educationLevel = useSelector(selectApplicationEducationLevel);

  const { data: achievementTypes } =
    achievementsApi.useGetAchievementTypesQuery({ educationLevel });
  const [getConfirmationDocTypes] =
    achievementsApi.useLazyGetAchievementConfirmationDocTypesQuery();

  const [createAchievement] = achievementsApi.useCreateAchievementMutation();
  const [updateAchievement] = achievementsApi.useUpdateAchievementMutation();

  const [confirmationDocs, setConfirmationDocs] = useState<Entity[]>([]);

  const { register, handleSubmit, reset, watch, setValue, ...rest } =
    useForm<AddAchievementForm>();

  const fileProps = useLazyUploadFiles({
    initialFileIds: watch('documentId'),
    userId,
  });

  useEffect(() => {
    if (achievement) {
      getConfirmationDocTypes(achievement.achievementType.id)
        .unwrap()
        .then(types => {
          setConfirmationDocs(types);

          reset({
            ...achievement,
            achievementType: {
              label: achievement.achievementType.title,
              value: achievement.achievementType.id,
            },
            confirmationDocumentType: {
              label: achievement.confirmationDocumentTypeId.title,
              value: achievement.confirmationDocumentTypeId.id,
            },
            documentId: achievement.documentId,
            isDocumentAttached: !!achievement.documentId,
          });
        });
    }
  }, []);

  useEffect(() => {
    setValue('isDocumentAttached', fileProps.files.length > 0);
  }, [fileProps.files]);

  const validateDate = useDateLessThanCurrentAndMoreThanBirthdayValidator();

  const fields = {
    achievementType: register('achievementType', {
      required: terms.REQUIRED_FIELD,
      onChange: ({ target: { value } }) => {
        if (value) {
          setValue('confirmationDocumentType', undefined);
          getConfirmationDocTypes(value.value)
            .unwrap()
            .then(types => setConfirmationDocs(types));
        }
      },
    }),

    confirmationDocumentType: register('confirmationDocumentType', {
      required: terms.REQUIRED_FIELD,
    }),

    documentCode: register('documentCode', { required: terms.REQUIRED_FIELD }),
    dateOfIssue: register('dateOfIssue', {
      required: terms.REQUIRED_FIELD,
      onChange: ({ target: { value } }) =>
        value && setValue('dateOfIssue', normalizeYearTo4Characters(value)),
      validate: value =>
        validateDate(
          value,
          'Дата выдачи должна быть позже даты рождения и раньше текущей даты'
        ),
    }),

    documentId: register('documentId'),
    amount: register('amount'),
    additionalInfo: register('additionalInfo'),

    isDocumentAttached: register('isDocumentAttached', {
      required: terms.REQUIRED_FIELD,
    }),
  };

  const onSubmit = handleSubmit(data => {
    if (!data.isDocumentAttached) {
      dispatch(createToast('Необходимо прикрепить документ', 'danger'));
      return;
    }

    fileProps
      .uploadFiles()
      .then(fileId => setValue('documentId', fileId))
      .then(() => {
        const request = {
          ...data,
          applicationId,
          documentId: watch('documentId'),
          achievementTypeId: data.achievementType.value,
          confirmationDocumentTypeId: data.confirmationDocumentType?.value,
          amount: data.amount || undefined,
          additionalInfo: data.additionalInfo || undefined,
        };

        return achievement
          ? updateAchievement({
              ...request,
              id: achievement.id,
            }).unwrap()
          : createAchievement(request).unwrap();
      })
      .then(() => {
        dispatch(createToast('Олимпиада была успешно добавлена', 'success'));
        dispatch(hideModal());
        refetchAchievementsForm();
      });
  });

  return {
    fields,
    onSubmit,
    ...rest,
    fileProps,
    typesSelectOptions: (achievementTypes ?? []).map(type => ({
      value: type.id,
      label: type.title,
    })),
    confirmationDocsSelectOptions: confirmationDocs.map(type => ({
      value: type.id,
      label: type.title,
    })),
  };
};

export default useAddAchievementForm;
