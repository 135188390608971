import { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import useChatIsEnrollee from 'hooks/chat/useChatIsEnrollee';
import useChatIsStaff from 'hooks/chat/useChatIsStaff';
import useAppSelector from 'hooks/common/useAppSelector';
import { selectCurrentApplicationUser } from 'redux/selectors/staff/applications';

const useChatApplicantId = (): number | undefined => {
  const { userId: userIdParam } = useParams<{ userId?: string }>();
  const userId = useAppSelector(state => state.user.id);
  const user = useAppSelector(selectCurrentApplicationUser);
  const isEnrollee = useChatIsEnrollee();
  const isChatStaff = useChatIsStaff();

  return useMemo(() => {
    if (isEnrollee) return userId || undefined;
    if (isChatStaff) return Number(userIdParam) || user?.id;
  }, [isEnrollee, isChatStaff, userId, userIdParam, user?.id]);
};

export default useChatApplicantId;
