import React, { FC, ReactNode } from 'react';

import { useLocation } from 'react-router-dom';

import Header from './Header';

import './admissionCampaignPage.scss';

export type AdmissionCampaignPageProps = {
  children: ReactNode;
};

const AdmissionCampaignPageLayout: FC<AdmissionCampaignPageProps> = ({
  children,
}) => {
  const { pathname } = useLocation();
  const isWrapped = pathname.endsWith('applicants');
  return (
    <div className="admission-campaign-page shell">
      <Header />
      <main className="admission-campaign-page__main">
        {isWrapped ? <div className="container">{children}</div> : children}
      </main>
    </div>
  );
};

export default AdmissionCampaignPageLayout;
