import { useState } from 'react';

import { useForm } from 'react-hook-form';

import useAppDispatch from 'hooks/common/useAppDispatch';
import terms from 'i18n';
import { ViewerPasswords } from 'models/viewer';
import { createToast } from 'redux/actions';
import regexp from 'utils/regexp';

import { useChangePasswordMutation } from '../../redux/api/common/user-api';

const useChangePasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [changePassword] = useChangePasswordMutation();

  const { register, handleSubmit, setError, getValues, ...rest } =
    useForm<ViewerPasswords>({ mode: 'all' });

  const fields = {
    passwordCurrent: register('passwordCurrent', {
      required: terms.REQUIRED_FIELD,
    }),
    passwordNew: register('passwordNew', {
      required: terms.REQUIRED_FIELD,
      pattern: {
        value: regexp.password,
        message: terms.PASSWORD_MIXED,
      },
      minLength: {
        value: 6,
        message: terms.PASSWORD_MIN_LENGTH,
      },
    }),
    passwordNewConfirm: register('passwordNewConfirm', {
      required: terms.REQUIRED_FIELD,
      validate: value => {
        return value === getValues('passwordNew') || terms.PASSWORDS_MUST_MATCH;
      },
    }),
  };

  const onSubmit = handleSubmit(data => {
    setLoading(true);
    changePassword({
      oldPassword: data.passwordCurrent,
      newPassword: data.passwordNew,
    })
      .unwrap()
      .then(() => {
        dispatch(createToast(terms.PASSWORD_UPDATED, 'success'));
      })
      .catch(() => {
        dispatch(createToast(terms.ERROR_HAS_OCCURRED, 'danger'));
      })
      .finally(() => setLoading(false));
  });

  return {
    fields,
    onSubmit,
    getValues,
    loading,
    ...rest,
  };
};

export default useChangePasswordForm;
