import { ContractState } from 'models/applications/contracts';
import {
  ApplicationEducationLevel,
  ApplicationState,
  Trajectory,
} from 'models/applications/enum';
import { DateString, DateTimeString } from 'models/common';
import { RelationDegree } from 'models/enum';

export type UploadQueueInfoResponse = {
  userId: number;
  userIdWithPrefix: string;
  name?: string;
  surname?: string;
  patronymic?: string;
  hasErrors: boolean;
  lastUpdateAt: DateTimeString;
};

export type UploadDetails = {
  id: number;
  uploadError?: string;
  uploadAt?: DateTimeString;
  updatedAt?: DateTimeString;
};

export type UploadUserDetails = {
  idWithPrefix: string;
  externalId?: string;
  name?: string;
  surname?: string;
  patronymic?: string;
} & UploadDetails;

export type UploadRepresentativeDetails = {
  name?: string;
  surname?: string;
  patronymic?: string;
  relationDegree?: RelationDegree;
  birthday?: DateString;
  mobile?: string;
} & UploadDetails;

export enum EducationalLevel {
  PRIMARY_BASIC = 'PRIMARY_BASIC',
  SECONDARY_BASIC = 'SECONDARY_BASIC',
  OTHER = 'OTHER',
  NO_EDUCATION_DOCUMENT_TYPE = 'NO_EDUCATION_DOCUMENT_TYPE',
  PRIMARY_VOCATIONAL = 'PRIMARY_VOCATIONAL',
  SECONDARY_VOCATIONAL = 'SECONDARY_VOCATIONAL',
  HIGHER_EDUCATION = 'HIGHER_EDUCATION',
  POSTGRADUATE = 'POSTGRADUATE',
}

export enum EducationalDocumentType {
  ACADEMIC_CERTIFICATE = 'ACADEMIC_CERTIFICATE',
  BASIC_GENERAL_EDUCATION_CERTIFICATE = 'BASIC_GENERAL_EDUCATION_CERTIFICATE',
  SECONDARY_GENERAL_EDUCATION_CERTIFICATE = 'SECONDARY_GENERAL_EDUCATION_CERTIFICATE',
  OTHER = 'OTHER',
  PRIMARY_VOCATIONAL_EDUCATION_DIPLOMA = 'PRIMARY_VOCATIONAL_EDUCATION_DIPLOMA',
  SECONDARY_VOCATIONAL_EDUCATION_DIPLOMA = 'SECONDARY_VOCATIONAL_EDUCATION_DIPLOMA',
  BACHELOR_DEGREE = 'BACHELOR_DEGREE',
  SPECIALIST_DIPLOMA = 'SPECIALIST_DIPLOMA',
  MASTER_DEGREE = 'MASTER_DEGREE',
  POSTGRADUATE_DIPLOMA = 'POSTGRADUATE_DIPLOMA',
}

export type UploadEduDocDetails = {
  educationLevel: EducationalLevel;
  documentType: EducationalDocumentType;
  educationalInstitution: string;
  educationalDocumentNumber: string;
  educationalDocumentIssuedAt: DateTimeString;
} & UploadDetails;

export enum EducationBasis {
  BUDGET = 'BUDGET',
  CONTRACT = 'CONTRACT',
}

export type UploadApplicationDetails = {
  externalId?: string;
  trajectory?: Trajectory;
  educationLevel?: ApplicationEducationLevel;
  educationBasis?: EducationBasis;
  state: ApplicationState;
  deletedAt?: DateTimeString;
} & UploadDetails;

export type UploadSchoolTestsDetails = UploadDetails;

export type UploadOlympiadsDetails = UploadDetails;

export type UploadFeaturesDetails = UploadDetails;

export type UploadContractDetails = {
  externalId: string;
  contractNumber: string;
  state: ContractState;
} & UploadDetails;

export type UploadOriginalDetails = {
  applicationId: number;
  isSubmitted: boolean;
  originalStateChangedAt?: DateTimeString;
} & UploadDetails;

export type UploadAgreementDetails = {
  applicationId: number;
  directionName: string;
  isAgreed: boolean;
} & UploadDetails;

export type UploadQueueDetailedInfoResponse = {
  userDetails?: UploadUserDetails;
  representativeDetails?: UploadRepresentativeDetails;
  eduDocsDetails: UploadEduDocDetails[];
  applicationsDetails: UploadApplicationDetails[];
  schoolTestsDetails?: UploadSchoolTestsDetails;
  olympiadsDetails?: UploadOlympiadsDetails;
  featuresDetails?: UploadFeaturesDetails;
  contractsDetails: UploadContractDetails[];
  originalsDetails: UploadOriginalDetails[];
  agreementsDetails: UploadAgreementDetails[];
  jsonToUpload: string;
};
