import React, { FC } from 'react';

import ModeratorDataList from 'components/moderator/ModeratorDataList';
import useAppSelector from 'hooks/common/useAppSelector';
import { DataList } from 'models/moderator';

import Loader from '../../../components/common/ui-kit/Loader';
import CheckListEntryValueSelectWithRoleChecker from '../../../components/moderator/CheckListEntryValueSelectWithRoleChecker';
import { Country } from '../../../models/common-entities';
import { selectCurrentQuestionnaire } from '../../../redux/selectors/staff/applications';
import { selectCurrentSupportQuestionnaire } from '../../../redux/selectors/staff/questionnaires';

const ModeratorVisaPage: FC<{ isSupport?: boolean }> = ({ isSupport }) => {
  const questionnaire = useAppSelector(
    isSupport ? selectCurrentSupportQuestionnaire : selectCurrentQuestionnaire
  );

  // TODO rewrite in future
  const countryToDeliver: Country = (questionnaire?.visaRequestForm as any)
    ?.countryToDeliver as Country;

  if (!questionnaire) {
    return <Loader />;
  }

  const list: DataList = [
    {
      title: 'Страна:',
      label: countryToDeliver?.title || '-',
    },
    {
      title: 'Город:',
      label: questionnaire.visaRequestForm.cityToDeliverView?.cityRus || '-',
    },
    {
      title: 'Приезжали ли Вы когда-нибудь в Россию по учебной визе:',
      label: questionnaire.visaRequestForm.hadStudyVisa ? 'Да' : 'Нет',
    },
  ];

  return (
    <div>
      <ModeratorDataList list={list} />
      <CheckListEntryValueSelectWithRoleChecker
        getValue={checkList => checkList.unrelatedCheckMarks['visa']}
        updateValue={newValue => ({
          unrelatedCheckMarks: {
            visa: newValue,
          },
        })}
      />
    </div>
  );
};

export default ModeratorVisaPage;
