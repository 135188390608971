import { useState } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import useAppDispatch from 'hooks/common/useAppDispatch';
import terms, { isRussianLocale } from 'i18n';
import { UserCredentials } from 'models/viewer';
import { saveAuthCredentialsToStorage } from 'utils/auth-token';
import regexp from 'utils/regexp';

import { SignInRespForbidden, SignInResp } from '../../models/auth';
import { createToast } from '../../redux/actions';
import { useLoginMutation } from '../../redux/api/common/auth-api';
import { setAuthCredentials } from '../../redux/slices/common/auth-slice';

const useSignInForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const lang = localStorage.getItem('locale');

  const [signIn] = useLoginMutation();

  const { register, handleSubmit, setError, ...rest } =
    useForm<UserCredentials>();

  const fields = {
    email: register('email', {
      required: terms.REQUIRED_FIELD,
      pattern: {
        value: regexp.email,
        message: terms.VALID_EMAIL,
      },
    }),
    password: register('password', {
      required: terms.REQUIRED_FIELD,
    }),
  };

  const onSubmit = handleSubmit(async data => {
    setLoading(true);

    signIn({
      email: data.email,
      password: data.password,
    })
      .unwrap()
      .then(response => {
        const data = response as Exclude<SignInResp, SignInRespForbidden>;
        if (data.needToChangePassword) {
          navigate(
            `/reset-password?code=${data.temporaryAccess.changePasswordCode}&userId=${data.temporaryAccess.userId}`
          );
          return Promise.resolve();
        }
        dispatch(setAuthCredentials(data.access));
        saveAuthCredentialsToStorage(data.access);
      })
      .catch((response: SignInRespForbidden) => {
        const errorDescriptionLocale: string = isRussianLocale()
          ? response.data?.descriptionRu ?? response.data.description
          : response.data.description;
        dispatch(createToast(errorDescriptionLocale, 'danger'));
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return {
    fields,
    onSubmit,
    loading,
    lang,
    ...rest,
  };
};

export default useSignInForm;
