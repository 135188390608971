import React, { FC, useEffect } from 'react';

import EntranceExamsTable from 'components/statements/EntranceExamsForm/Table/view';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import terms from 'i18n';
import {
  SchoolTestType,
  UserSchoolTestResponse,
} from 'models/applications/school-tests';
import { createToast } from 'redux/actions';
import { apiSlice } from 'redux/api/api-slice';

import Button from '../../../components/common/ui-kit/Button';
import Loader from '../../../components/common/ui-kit/Loader';
import ModeratorDataList from '../../../components/moderator/ModeratorDataList';
import { useModeratorSchoolTests } from '../../../hooks/moderator/applications/useModeratorSchoolTests';
import { UserRole } from '../../../models/user';
import { useLazyGetAllTestsQuery } from '../../../redux/api/applications/school-test-api';
import { selectUserRole } from '../../../redux/selectors';
import {
  selectCurrentApplication,
  selectCurrentApplicationUser,
} from '../../../redux/selectors/staff/applications';
import { importUserSchoolTests } from '../ModeratorStatementsPage/view';
const headers = [
  'Предмет',
  'ЦТ',
  'Балл',
  'Год сдачи',
  'Проверено',
  'Тип экзамена',
];

export const schoolTestTypeToString: { [key in SchoolTestType]: string } = {
  SECONDARY: 'ГИА',
  UPPER_SECONDARY: 'ЕГЭ',
};

const ModeratorExamResultPage: FC = () => {
  const dispatch = useAppDispatch();
  const application = useAppSelector(selectCurrentApplication);
  const user = useAppSelector(selectCurrentApplicationUser);
  const role = useAppSelector(selectUserRole);
  const [getTests, { data: tests }] = useLazyGetAllTestsQuery();

  const handleImportUserSchoolTests = () => {
    importUserSchoolTests(
      () => {
        dispatch(apiSlice.util.invalidateTags(['School tests']));
      },
      () => {
        dispatch(
          createToast(
            'Во время загрузки результатов ЕГЭ  произошла ошибка',
            'danger'
          )
        );
      },
      user?.id
    );
  };

  const exportStudentSchoolTestResults = useModeratorSchoolTests(
    user?.id,
    user?.idWithPrefix
  );
  useEffect(() => {
    if (application) {
      getTests(application.id);
    }
  }, [application]);

  if (!application || !tests) {
    return <Loader />;
  }

  function getDataListFromTestResult(testResult: UserSchoolTestResponse) {
    return [
      {
        title: 'Тип документа:',
        label:
          (testResult.oldDocumentResponse?.documentType &&
            terms[testResult.oldDocumentResponse?.documentType]) ??
          '-',
      },
      {
        title: 'Серия:',
        label: testResult.oldDocumentResponse?.oldDocumentSerial ?? '-',
      },
      {
        title: 'Номер:',
        label: testResult.oldDocumentResponse?.oldDocumentNumber ?? '-',
      },
      {
        title: 'Фамилия:',
        label: testResult.oldDocumentResponse?.oldSecondName ?? '-',
      },
      {
        title: 'Имя:',
        label: testResult.oldDocumentResponse?.oldFirstName ?? '-',
      },
      {
        title: 'Отчество:',
        label: testResult.oldDocumentResponse?.oldMiddleName ?? '-',
      },
    ];
  }

  return (
    <div className="moderator-exam-result-page">
      <table className="moderator-exam-result-table">
        <thead>
          <tr>
            {headers.map(header => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tests.map((test, id) => (
            <>
              <tr key={id}>
                <td>{test.subject.title}</td>
                <td>{test.confirmed ? 'Да' : 'Нет'}</td>
                <td>{test.score ?? '-'}</td>
                <td>{test.year}</td>
                <td className={test.confirmed ? 'success' : 'danger'}>
                  {test.confirmed ? 'Проверено' : 'Не проверено'}
                </td>
                <td>{schoolTestTypeToString[test.testType]}</td>
              </tr>
              {test.oldDocumentResponse && (
                <tr>
                  <td>
                    <div className="moderator-old-document-info">
                      <h2>Данные предыдущего документа</h2>
                      <ModeratorDataList
                        list={getDataListFromTestResult(test)}
                      />
                    </div>
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>
      {role === UserRole.ADMIN && (
        <div className="moderator-exam-result-page__btn">
          <Button
            theme="success"
            loading={false}
            onClick={handleImportUserSchoolTests}
          >
            Проверить результаты ЕГЭ
          </Button>
          <Button
            theme="success"
            loading={false}
            onClick={exportStudentSchoolTestResults}
          >
            Скачать CSV
          </Button>
        </div>
      )}
      <EntranceExamsTable applicationId={application.id} />
    </div>
  );
};

export default ModeratorExamResultPage;
