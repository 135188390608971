import React, { FC } from 'react';

import TemplateModal from 'components/common/modals/TemplateModal';
import useAppSelector from 'hooks/common/useAppSelector';
import { modalDataSelector } from 'redux/selectors';

const StatementJsonToUploadModal: FC = () => {
  const json = useAppSelector(modalDataSelector);

  return (
    <TemplateModal title="JSON">
      <div
        style={{ whiteSpace: 'pre' }}
        dangerouslySetInnerHTML={{ __html: json }}
      />
    </TemplateModal>
  );
};

export default StatementJsonToUploadModal;
