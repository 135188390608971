import { NavRoute } from 'types/custom';

import terms from 'i18n';
import { ProfileSectionKey } from 'models/profile';

export const profilePageRoutes: NavRoute<ProfileSectionKey>[] = [
  {
    to: 'basic-data',
    title: terms.BASIC_DATA,
    text: terms.BASIC_DATA_HINT,
    key: 'generalData',
    status: 'not visited',
  },
  {
    to: 'legal-representative',
    title: terms.INFORMATION_ABOUT_PARENTS,
    text: terms.INFORMATION_ABOUT_PARENTS_HINT,
    key: 'legalRepresentative',
    status: 'not visited',
  },
  {
    to: 'passport-data',
    title: terms.PASSPORT_DATA,
    text: terms.PASSPORT_DATA_HINT,
    key: 'passport',
    status: 'not visited',
  },
  {
    to: 'compatriot-data',
    title: terms.COMPATRIOT_DOCUMENTS,
    text: terms.COMPATRIOT_DOCUMENTS_HINT,
    key: 'compatriot',
    status: 'not visited',
  },
  {
    to: 'visa',
    title: terms.VISA,
    text: terms.VISA_HINT,
    key: 'visa',
    status: 'not visited',
  },
  {
    to: 'address',
    title: terms.PERMANENT_ADDRESS_AND_CURRENT_HOME_ADDRESS,
    text: terms.PERMANENT_ADDRESS_AND_CURRENT_HOME_ADDRESS_HINT,
    key: 'addresses',
    status: 'not visited',
  },
  {
    to: 'educational-background',
    title: terms.EDUCATIONAL_BACKGROUND,
    text: terms.EDUCATIONAL_BACKGROUND_HINT,
    key: 'educationInformation',
    status: 'not visited',
  },
];

export const APPLICATION_SECTION_ROUTE = '/application';
