import { ReactNode } from 'react';

import terms from 'i18n';
import { DateString } from 'models/common';
import {
  AdmissionListEntity,
  EACAdmissionListResponse,
  EntrantTestType,
  RatingListEntityResponse,
} from 'models/students';
import {
  convertUtcDateToLocalDate,
  formatDateTimeToLocaleString,
} from 'utils/date';

import { ratingEntityStatesToStrings } from './EnrollmentTable';

export const getPresenceString = (value: boolean) =>
  value ? 'Получено' : 'Отсутствует';

export const renderDefault = value => {
  if (typeof value === 'string' || typeof value === 'number') {
    return value;
  }
  if (typeof value === 'boolean') {
    return getPresenceString(value);
  }
  return '-';
};

export const renderDateString = (value: DateString) =>
  formatDateTimeToLocaleString(convertUtcDateToLocalDate(new Date(value)));

export const entranceTestTypesToString: Record<EntrantTestType, string> = {
  SCHOOL_TEST: 'ЕГЭ',
  ENTRANT_TEST: 'ВИ',
  SCHOOL_AND_ENTRANT_TEST: 'ЕГЭ + ВИ',
  ABSENT: 'Отсутствует',
};

export const commonColumnKeysToRenderers: {
  [key in
    | keyof (
        | AdmissionListEntity
        | RatingListEntityResponse
        | EACAdmissionListResponse
      )
    | string]: (entity) => ReactNode;
} = {
  userIdOrUniqueId: enrollment => (
    <>
      {`${
        enrollment.userSnils && enrollment.userSnils !== '000-000-000 00'
          ? `${enrollment.userSnils} / `
          : ''
      }
      ${enrollment.userUniqueId}`}
    </>
  ),
  userSnilsOrIdWithPrefix: enrollment => (
    <>
      {`${
        enrollment.userSnils && enrollment.userSnils !== '000-000-000 00'
          ? `${enrollment.userSnils} / `
          : ''
      }
      ${enrollment.userIdWithPrefix}`}
    </>
  ),
  userFullNameOrIdOrUniqueId: enrollment => (
    <>
      {enrollment.userFullName ??
        `${
          enrollment.userSnils && enrollment.userSnils !== '000-000-000 00'
            ? `${enrollment.userSnils} / `
            : ''
        }
      ${enrollment.userUniqueId}`}
    </>
  ),
  userFullNameOrUniqueId: enrollment => (
    <>
      {`${enrollment.fullName ? `${enrollment.fullName} / ` : ''}
      ${enrollment.userIdWithPrefix}`}
    </>
  ),
  admissionDate: enrollment =>
    formatDateTimeToLocaleString(
      convertUtcDateToLocalDate(new Date(enrollment.admissionDate))
    ),
  hasFeature: enrollment => getPresenceString(enrollment.hasFeature),
  hasAchievements: enrollment => getPresenceString(enrollment.hasAchievements),
  hasAchievement: enrollment => getPresenceString(enrollment.hasAchievement),
  hasOriginalDocuments: enrollment =>
    getPresenceString(enrollment.hasOriginalDocuments),
  hasOriginal: enrollment => getPresenceString(enrollment.hasOriginal),
  hasOlympiad: enrollment => (enrollment.hasOlympiad ? terms.YES : terms.NO),
  state: enrollment => ratingEntityStatesToStrings[enrollment.state],
  entrantTestType: enrollment =>
    entranceTestTypesToString[enrollment.entrantTestType],
  needDormitory: enrollment =>
    enrollment.needDormitory ? terms.YES : terms.NO,
  withoutExam: enrollment => (enrollment.withoutExam ? terms.YES : terms.NO),
  priorityRight: enrollment =>
    enrollment.priorityRight ? terms.YES : terms.NO,
};
