import React, { FC } from 'react';

import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg';
import Button from 'components/common/ui-kit/Button';
import useApplicationList from 'hooks/statements/useApplicationList';
import terms from 'i18n';
import { ApplicationView } from 'models/applications/applications';
import {
  ApplicationEducationLevel,
  ApplicationState,
} from 'models/applications/enum';
import { mapApplicationStateToText } from 'pages/moderator/ModeratorStatementsPage/view';
import { useGetOriginalEducationDocumentSubmissionQuery } from 'redux/api/applications/applications-api';

import { formatDateTimeToRuFormat } from '../../../utils/date';
import { directionsRequestTypeToText } from '../DirectionsTypeList/view';
import { educationLevelToText } from './view';

const enabled = false;

export const Application: FC<{ el: ApplicationView; refetch: () => void }> = ({
  el,
  refetch,
}) => {
  const {
    withdrawApplication,
    takeApplicationBack,
    deleteApplication,
    navigateToApplication,
    onDirectionsEditingClick,
    navigateToCreateApplication,
    navigateToDirections,
  } = useApplicationList(refetch);

  // Disabled for now as it is not needed
  const { data: originalSubmission } =
    useGetOriginalEducationDocumentSubmissionQuery(el.id, {
      skip: !enabled,
    });

  return (
    <div className="statements-container__element">
      <h2>
        {terms.STATEMENT} №{el.formattedId}
      </h2>
      <div className="statements-container__element-text">
        <p>
          {terms.LEVEL_OF_TRAINING}:{' '}
          <b>{educationLevelToText[el.educationLevel]}</b>
        </p>
        {el.educationBasis && (
          <p>
            {terms.DIRECTIONS_TYPE}:{' '}
            <b>{directionsRequestTypeToText[el.educationBasis]}</b>
          </p>
        )}
        {el.state !== ApplicationState.DRAFT ? (
          <p>
            {terms.DATE_AND_TIME}:{' '}
            <b>{formatDateTimeToRuFormat(el.updatedAt)}</b>
          </p>
        ) : (
          <p>
            {terms.DATE_AND_TIME}: <b>-</b>
          </p>
        )}
        <p>
          {terms.STATUS}:{' '}
          <b className="success">
            {mapApplicationStateToText(el.state, el.flags)}
          </b>
        </p>
        <p>
          {terms.ORIGINAL_DOCUMENT}:{' '}
          <b>
            {el.hasOriginalDocuments
              ? `${terms.SUBMITTED} (${formatDateTimeToRuFormat(
                  el.originalStateChangedAt
                )})`
              : !el.originalStateChangedAt
              ? terms.NOT_SUBMITTED
              : `${terms.REVOKED} (${formatDateTimeToRuFormat(
                  el.originalStateChangedAt
                )})`}
          </b>
        </p>
      </div>

      <div
        className="statements-container__element-btn-panel"
        style={{ marginTop: 'auto' }}
      >
        <Button
          type="button"
          theme="success"
          onClick={event => {
            event.stopPropagation();
            navigateToApplication(el);
          }}
        >
          {terms.OPEN_APPLICATION}
        </Button>

        {el.state === ApplicationState.DRAFT && (
          <>
            <Button
              type="button"
              theme="success"
              onClick={event => {
                event.stopPropagation();
                navigateToCreateApplication(el);
              }}
            >
              {terms.SEND_APPLICATION}
            </Button>
            <Button
              type="button"
              className="statements-container__element-btn-panel_icon"
              onClick={event => {
                event.stopPropagation();
                deleteApplication(el.id);
              }}
            >
              <TrashIcon />
            </Button>
          </>
        )}

        {el.state === ApplicationState.UNDER_CONSIDERATION && (
          <Button
            type="button"
            theme="success"
            onClick={event => {
              event.stopPropagation();
              takeApplicationBack(el.id);
            }}
          >
            {terms.EDIT_APPLICATION}
          </Button>
        )}

        {el.state === ApplicationState.SENT_FOR_EDITING && (
          <Button
            type="button"
            theme="success"
            onClick={event => {
              event.stopPropagation();
              navigateToCreateApplication(el);
            }}
          >
            {terms.SEND_APPLICATION}
          </Button>
        )}

        {(el.state === ApplicationState.ALLOWED ||
          el.state === ApplicationState.ACCEPTED) &&
          !el?.createdFromEpgu && (
            <>
              {el.educationLevel !== ApplicationEducationLevel.PREPARATORY && (
                <Button
                  type="button"
                  theme="success"
                  onClick={event => {
                    event.stopPropagation();
                    onDirectionsEditingClick(el.id);
                  }}
                >
                  {terms.EDIT_FIELDS}
                </Button>
              )}
              <Button
                type="button"
                className="statements-container__element-btn-panel_icon"
                onClick={event => {
                  event.stopPropagation();
                  withdrawApplication(el.id);
                }}
              >
                {terms.WITHDRAW_APPLICATION}
              </Button>
            </>
          )}

        {el.state === ApplicationState.DIRECTIONS_EDITING && (
          <>
            <Button
              type="button"
              theme="success"
              onClick={event => {
                event.stopPropagation();
                navigateToCreateApplication(el);
              }}
            >
              {terms.SEND_APPLICATION}
            </Button>
            <Button
              type="button"
              onClick={event => {
                event.stopPropagation();
                navigateToDirections(el.id);
              }}
            >
              {terms.EDIT_FIELDS}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default Application;
