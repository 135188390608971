import {
  ExamStatementResponse,
  UserWithScoreResponse,
} from 'models/applications/statements';
import { Page, Pagination } from 'models/common';
import { apiSlice } from 'redux/api/api-slice';

const baseUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/statement`;

export type StatementsFilters = {};

export const statementsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAllStatementsPageable: builder.query<
      Page<ExamStatementResponse>,
      Pagination & { request: StatementsFilters }
    >({
      query: ({ page, size, request }) => ({
        url: `${baseUrl}/all-pageable`,
        params: {
          page,
          size,
          ...request,
        },
      }),
    }),

    getStatementById: builder.query<ExamStatementResponse, number | string>({
      query: id => ({
        url: `${baseUrl}/${id}`,
      }),
      providesTags: ['StatementById'],
    }),

    getAllUserInStatementPageable: builder.query<
      Page<UserWithScoreResponse>,
      { statementId: number; request: StatementsFilters } & Pagination
    >({
      query: ({ statementId, page, size, request }) => ({
        url: `${baseUrl}/${statementId}/users-pageable`,
        method: 'POST',
        body: request,
        params: {
          page,
          size,
        },
      }),
      providesTags: ['Statement'],
    }),

    uploadToAdmission: builder.mutation<void, number>({
      query: id => ({
        url: `${baseUrl}/queue/push/${id}`,
        method: 'POST',
      }),
    }),

    fetchTestResults: builder.query<UserWithScoreResponse, number>({
      query: id => ({
        url: `${baseUrl}/fetch-results/by-user-test/${id}`,
      }),
    }),

    fetchResults: builder.query<UserWithScoreResponse, number>({
      query: id => ({
        url: `${baseUrl}/fetch-results/${id}`,
      }),
    }),

    setApproved: builder.mutation<
      UserWithScoreResponse,
      { id: number; status: boolean }
    >({
      query: ({ id, status }) => ({
        url: `${baseUrl}/${id}/set-approve`,
        method: 'PUT',
        params: {
          status,
        },
      }),
      invalidatesTags: ['StatementById'],
    }),

    setScore: builder.mutation<void, number>({
      query: id => ({
        url: `${baseUrl}/${id}/set-score`,
        method: 'POST',
      }),
      invalidatesTags: ['StatementById'],
    }),

    pushExamStatementToQueue: builder.mutation<void, number>({
      query: id => ({
        url: `${baseUrl}/queue/push/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['StatementById'],
    }),

    getAllExamStatementQueuePageable: builder.query<
      Page<ExamStatementResponse>,
      Pagination & { request: StatementsFilters }
    >({
      query: ({ page, size, request }) => ({
        method: 'POST',
        url: `${baseUrl}/queue`,
        body: request,
        params: {
          page,
          size,
        },
      }),
    }),
  }),
});

export const {
  useGetAllStatementsPageableQuery,
  useGetStatementByIdQuery,
  useLazyFetchTestResultsQuery,
} = statementsApi;
