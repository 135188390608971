import { ApplicationEducationLevel } from 'models/applications/enum';

import {
  CreateOlympiadRequest,
  Olympiad,
  OlympiadType,
  UpdateOlympiadRequest,
} from '../../../models/applications/olympiads';
import { Entity } from '../../../models/common';
import { apiSlice } from '../api-slice';

const baseUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/olympiads`;

export const olympiadsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createOlympiad: builder.mutation<Olympiad, CreateOlympiadRequest>({
      query: request => ({
        url: `${baseUrl}/create`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['Olympiad'],
    }),

    updateOlympiad: builder.mutation<Olympiad, UpdateOlympiadRequest>({
      query: request => ({
        url: `${baseUrl}/update`,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['Olympiad'],
    }),

    deleteOlympiad: builder.mutation<Olympiad, number>({
      query: id => ({
        url: `${baseUrl}/delete/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Olympiad'],
    }),

    getOlympiad: builder.query<Olympiad, number>({
      query: id => ({
        url: `${baseUrl}/${id}`,
      }),
    }),

    getOlympiadsByApplicationId: builder.query<Olympiad[], number>({
      query: applicationId => ({
        url: `${baseUrl}/by-application-id/${applicationId}`,
      }),
      providesTags: ['Olympiad'],
    }),

    getOlympiadTypes: builder.query<
      OlympiadType[],
      { year: number; educationLevel?: ApplicationEducationLevel }
    >({
      query: ({ year, educationLevel }) => ({
        url: `${baseUrl}/olympiad-types/${year}`,
        params: { educationLevel },
      }),
    }),

    getOlympiadConfirmationDocTypes: builder.query<Entity[], number>({
      query: olympiadTypeId => ({
        url: `${baseUrl}/olympiad-types/${olympiadTypeId}/confirmation-document-types`,
      }),
    }),

    getOlympiadYears: builder.query<number[], number>({
      query: applicationId => ({
        url: `${baseUrl}/olympiad-types/years`,
        params: { applicationId },
      }),
    }),

    updateAdmittedFlagForOlympiad: builder.mutation<
      void,
      { id: number; value: boolean }
    >({
      query: ({ id, value }) => ({
        url: `${baseUrl}/${id}/admitted`,
        method: 'PUT',
        params: {
          value,
        },
      }),
      invalidatesTags: ['AchievementForm', 'CheckList'],
    }),
  }),
});
