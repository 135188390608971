const ru = {
  LOADING: 'Загрузка',

  // Электронная приемная комиссия
  SPBPU_ONLINE_APPLICATION_SYSTEM: 'Электронная приемная комиссия СПбПУ',
  REVIEW_INFORMATION_ABOUT_ADMISSION:
    'Для поступления в университет внимательно ознакомьтесь с материалами на сайте приёмной комиссии, а затем',
  COMPLETE_REGISTRATION_PROCEDURE: 'пройдите процедуру регистрации',
  USER_MANUAL_FOR_RUSSIAN_APPLICANTS:
    'Инструкция по заполнению Личного кабинета абитуриента для граждан РФ и соотечественников',
  USER_MANUAL_FOR_FOREIGNERS:
    'Инструкция по заполнению Личного кабинета абитуриента для иностранных граждан (контрактная основа)',
  USER_MANUAL_FOR_FOREIGN_OPEN_DOORS:
    'Инструкция по заполнению Личного кабинета абитуриента для иностранных граждан (квота Правительства РФ и олимпиада Open Doors)',
  IF_ALREADY_HAVE_ACCOUNT:
    'Если Вы уже зарегистрированы в системе, то используйте Ваш зарегистрированный адрес электронной почты и пароль для входа в Личный кабинет',
  EMAIL: 'Email',
  PASSWORD: 'Пароль',
  RESTORE_PASSWORD: 'Восстановить пароль',
  REGISTER: 'Зарегистрироваться',
  LOG_IN: 'Войти',
  ACCOUNT_CONFIRMATION_SENT:
    'На вашу почту отправлено письмо с подтверждением учётной записи',
  PASSWORD_UPDATED: 'Пароль изменён',
  ACCOUNT_NOT_FOUND: 'Аккаунт не найден',
  PASSWORD_RESET_EMAIL_SENT:
    'Письмо для изменения пароля отправлено на вашу почту',

  // Регистрация
  REGISTRATION: 'Регистрация',
  BASIC_DATA: 'Основные данные',
  SURNAME_IN_RUSSIAN: 'Фамилия',
  CYRILLIC_CHARACTERS_ONLY: 'Только кириллицей',
  SURNAME_IN_LATIN: 'Фамилия латинскими буквами',
  LATIN_CHARACTERS_ONLY: 'Только латинскими буквами',
  CYRILLIC_OR_LATIN_CHARACTERS_ONLY: 'Только символы кириллицы или латиницы',
  CYRILLIC_OR_LATIN_WITH_SPECIALS_CHARACTERS_ONLY:
    "Только символы кириллицы, латиницы, цифры или символы [,.'`-/]",
  CYRILLIC_OR_LATIN_WITH_SPECIALS_CHARACTERS_AND_NUMBER_SIGN_ONLY:
    "Только символы кириллицы, латиницы, цифры или символы [,.'`-/№]",
  NAME_IN_RUSSIAN: 'Имя',
  NAME_IN_LATIN: 'Имя латинскими буквами',
  PATRONYMIC_IN_LATIN: 'Отчество (если есть) латинскими буквами',
  PATRONYMIC_IN_RUSSIAN: 'Отчество (если есть)',
  DATE_OF_BIRTH: 'Дата рождения',
  CITIZENSHIP: 'Гражданство',
  AUTHORIZATION: 'Данные для авторизации',
  CREATE_PASSWORD: 'Придумайте пароль',
  PASS_HINT:
    'Пароль должен содержать не менее 6 знаков, включать строчные и заглавные латинские буквы, цифры и специальные символы',
  CONSENT_TO_DATA_PROCESSING:
    'Нажимая кнопку "Зарегистрироваться", Вы даёте свое согласие на обработку введенной персональной информации в соответствии с Федеральным Законом №152-ФЗ от 27.07.2006 "О персональных данных"',
  BACK: 'Назад',
  EMAIL_CONFIRMED: 'Email подтвержден',
  ACCOUNT_CREATED_AND_CONFIRMED:
    'Аккаунт успешно создан и подтвержден. Теперь Вы можете войти в личный кабинет абитуриента',
  PASSWORD_RESET: 'Сброс пароля',
  ENTER_YOUR_EMAIL_FOR_PASSWORD_RESET:
    'Введите свой email, с которым вы регистрировались ранее, и мы вышлем Вам инструкции для сброса пароля',
  RESET: 'Сбросить',
  YOUR_ID_IN_SYSTEM: 'Ваш id в системе',
  CONTACT_TECHNICAL_SUPPORT: 'Обратиться в техническую поддержку',

  // Анкета
  PERSONAL_INFORMATION: 'Анкета',
  APPLICATION: 'Заявление на поступление',
  ADDITIONAL_FILES: 'Дополнительные файлы',
  LIST_OF_APPLICANTS: 'Списки поступающих',
  BASIC_DATA_HINT:
    'Заполните в соответствии с паспортом. Если Вы не знаете, как написать Ваше имя по-русски, пропустите данные поля',
  COMPATRIOT_DOCUMENTS: 'Документы, подтверждающие статус соотечественника',
  COMPATRIOT_DOCUMENTS_HINT:
    'Заполните заявление о самоидентификации и загрузите подтверждающие документы',
  INFORMATION_ABOUT_PARENTS: 'Законный представитель',
  INFORMATION_ABOUT_PARENTS_HINT:
    'Заполните данные Вашего родителя или опекуна',
  PASSPORT_DATA: 'Паспортные данные',
  PASSPORT_DATA_HINT: 'Заполните в соответствии с паспортом',
  VISA: 'Виза',
  VISA_HINT:
    'Если Вам необходима виза, Ваш паспорт должен быть действителен не менее 18 месяцев',
  PERMANENT_ADDRESS_AND_CURRENT_HOME_ADDRESS:
    'Адрес регистрации (по паспорту) и адрес проживания в настоящий момент',
  PERMANENT_ADDRESS_AND_CURRENT_HOME_ADDRESS_HINT:
    'Укажите адрес регистрации по паспорту и адрес проживания в настоящий момент',
  EDUCATIONAL_BACKGROUND: 'Сведения об образовании',
  EDUCATIONAL_BACKGROUND_HINT:
    'Загрузите документ об образовании с приложением к нему (оценки) одним файлом.',
  SAVE_AND_CONTINUE: 'Сохранить и продолжить',
  CREATED: 'Создан',
  EDITED: 'Изменён',
  ADDITIONAL_FILE_UPLOADED: 'Дополнительный файл загружен',
  OWNER: 'Владелец',
  REGISTRATION_ADDRESS: 'Адрес по прописке',
  ACTUAL_ADDRESS: 'Адрес фактического проживания',
  ERROR_QUESTIONNAIRE_NOT_SAVED: 'У этого абитуриента не сохранена Анкета',

  // Раздел "Основные данные"
  PHOTO: 'Фото',
  CHANGE_PASSWORD: 'Изменить пароль',
  SURNAME: 'Фамилия',
  NAME: 'Имя',
  PATRONYMIC: 'Отчество',
  NO_PATRONYMIC: 'Нет отчества',
  NO_SURNAME: 'Нет фамилии',
  GENDER: 'Пол',
  MOBILE_PHONE: 'Мобильный телефон',
  ADDITIONAL_EMAIL_IF_ANY: 'Дополнительный email (при наличии)',
  RECRUITING_COMPANY: 'Рекрутинговая компания',
  SNILS: 'СНИЛС',
  NO_SNILS: 'Нет СНИЛС',
  DATE_OF_ISSUE_SNILS: 'Дата СНИЛС',
  COUNTRY_CODE: 'Код страны',

  // Окно "Изменить пароль"
  CURRENT_PASSWORD: 'Текущий пароль',
  NEW_PASSWORD: 'Новый пароль',
  REPEAT_NEW_PASSWORD: 'Повторите новый пароль',
  SAVE: 'Сохранить',

  // Окно "Редактировать фотографию"
  SELECT_PHOTO: 'Выбрать фотографию',
  SELECT_ANOTHER_PHOTO: 'Выбрать другую фотографию',
  PHOTO_REQUIREMENTS:
    'Просим Вас следовать следующим рекомендациям к загружаемой фотографии:',
  PHOTO_REQUIREMENTS_BG: 'светлый (белый или светло-серый) фон без рисунков',
  PHOTO_REQUIREMENTS_FACE:
    'лицо должно занимать не менее 70% площади фотографии',
  PHOTO_REQUIREMENTS_WIDTH: 'ширина фотографии не менее 300 пикселей',
  PHOTO_REQUIREMENTS_HEIGHT:
    'высота фотографии не менее 400 пикселей разрешение фотографии не менее',
  PHOTO_REQUIREMENTS_PX: '300 точек на дюйм',
  PHOTO_REQUIREMENTS_SIZE: 'размер файла не более 1 Мб',
  PHOTO_REQUIREMENTS_EXTENSION:
    'допустимые расширения файлов: .jpg, .jpeg и .png',
  PHOTO_REQUIREMENTS_CARD: '',
  PROFILE_PHOTO_UPDATED: 'Фото профиля обновлено',
  EDIT_PHOTO: 'Редактировать фото',

  // Раздел "Документы, подтверждающие статус соотечественника"
  FILE_UPLOADER_TITLE:
    'Нажмите на иконку или перетащите в эту область файлы для загрузки',
  FILE_UPLOADER_DESCRIPTION:
    'Можно загрузить изображения JPG (будут объединены в один PDF) или один PDF документ',

  // Раздел "Законный представитель"
  RELATIONSHIP: 'Степень родства',
  DOCUMENT_TYPE: 'Тип документа',
  SERIES: 'Серия',
  NUMBER: 'Номер',
  DIVISION_CODE: 'Код подразделения',
  DATE_OF_ISSUE: 'Когда выдан',
  ISSUING_AUTHORITY: 'Кем выдан',
  COUNTRY: 'Страна',
  REGION: 'Регион',
  DISTRICT: 'Район',
  CITY: 'Населенный пункт',
  STREET: 'Улица',
  NO_STREET: 'Нет улицы',
  BUILDING: 'Дом',
  CHARACTER: 'Литера',
  SITE: 'Корпус',
  Apartment: 'Квартира',
  ZIP_CODE: 'Индекс',
  CONSENT_OF_PERSONAL_DATA_PROCESSING:
    'Согласие на обработку персональных данных от законного представителя',

  // Раздел "Паспортные данные"
  DATE_OF_EXPIRATION: 'Срок действия',
  COUNTRY_OF_BIRTH: 'Страна рождения',
  PLACE_OF_BIRTH: 'Место рождения',
  I_HAVE_DUAL_CITIZENSHIP: 'Имеется второе гражданство',
  DUAL_CITIZENSHIP: 'Страна второго гражданства',
  COPY_OF_PASSPORT: 'Копия паспорта',
  COPY_OF_PASSPORT_CLARIFIED:
    'Копия паспорта: главный разворот, а также страница с регистрацией',
  I_AM_COMPATRIOT: 'Являюсь соотечественником',
  I_NEED_VISA: 'Нужна виза',
  I_HAVE_VNZ: 'Имею вид на жительство в России',
  RECEIVED_MINOBR_QUOTA:
    'Поступаю по квоте Минобрнауки России/являюсь победителем или призером олимпиады Open Doors',
  I_HAVE_VISA_OR_RVP: 'Имею российскую визу / РВП в России',
  DOCUMENT_NUMBER: 'Номер',
  VNZ: 'Вид на жительство в России',
  REG_NUMBER_EDUCATION_IN_RUSSIA:
    'Регистрационный номер на сайте education-in-russia.com',
  MSHE_CONFIRMATION_LETTER: 'Направление Минобрнауки РФ',
  DOCUMENTS_REQUIRED: 'Убедитесь, что следующие документы прикреплены:',

  // Раздел "Виза"
  COUNTRY_OF_VISA_APPLICATION: 'Страна получения визы',
  CITY_OF_VISA_APPLICATION: 'Город получения визы',
  HAVE_BEEN_TO_RUSSIA_WITH_VISA:
    'Приезжали ли Вы когда-нибудь в Россию по учебной визе?',
  YES: 'Да',
  NO: 'Нет',

  // Адрес регистрации (по паспорту)
  PERMANENT_ADDRESS: 'Адрес регистрации (по паспорту)',
  PERMANENT_PASSPORT_ADDRESS_AND_HOME_ADDRESS_THE_SAME:
    'Адрес регистрации (по паспорту) и адрес проживания в настоящий момент совпадают',
  CURRENT_HOME_ADDRESS: 'Адрес проживания в настоящий момент ',
  I_NEED_PLACE_OF_DORMITORY: 'Необходимость в общежитии на время учебы',
  NEED: 'Требуется',
  NO_NEED: 'Не требуется',

  // Раздел "Сведения об образовании"
  COUNTRY_WHERE_YOU_STUDIED: 'Страна образовательного учреждения',
  MODE_OF_STUDY: 'Форма обучения',
  EDUCATIONAL_INSTITUTION: 'Наименование образовательного учреждения',
  YEAR_OF_GRADUATION: 'Год окончания образовательного учреждения',
  STUDY_LEVEL: 'Вид образования',
  DOCUMENT_SERIES_AND_NUMBER: 'Серия (если есть) и номер документа',
  INSTITUTION_REGION: 'Регион образовательного учреждения',
  DIPLOMA_WITH_DISTINCTION: 'Аттестат / Диплом с отличием',
  GRADUATED_FROM_SPBPU: 'Окончил СПбПУ',
  STUDIED_IN_SPBPU_FOUNDATION_PROGRAM:
    'Учился на подготовительном отделении в СПбПУ (для иностранных граждан)',
  EDUCATIONAL_DOCUMENT: 'Документ об образовании',
  EDUCATIONAL_DOCUMENT_CLARIFIED: 'Копия документа (в том числе приложение)',
  GPA: 'Средний балл',
  EDUCATIONAL_DOCUMENT_IS_REQUIRED:
    'Необходимо прикрепить документ об образовании',
  GRADE_9: '9 класс',
  GRADE_11: '11 класс',
  ADD_DOCUMENT: 'Добавить документ',
  ERROR_CAN_NOT_EDIT_DOCUMENT_TYPE:
    'Нельзя поменять тип документа, так как он используется в заявлениях c id',
  ERROR_CAN_NOT_DELETE_DOCUMENT:
    'Нельзя удалить документ. Он используется в Заявлениях с id',

  // Дополнительные файлы
  ADD_FILE: 'Добавить файл',
  EDIT_FILE: 'Редактировать файл',
  EDIT: 'Редактировать',
  FILE: 'Файл',
  FILE_DELETED: 'Файл удалён',
  FILENAME_REQUIRED: 'Имя файла обязательно',
  DESIGNATION: 'Наименование',
  AVAILABLE_FORMATS:
    'Данный формат файла не поддерживается. Допустимы только pdf или jpeg',
  SUBMIT_ORIGINAL: 'Подать оригинал',
  REVOKE_ORIGINAL: 'Отозвать оригинал',
  ERROR_SUBMITTING_ORIGINAL: 'Ошибка при прикреплении оригинала',
  SUCCESS_SUBMITTING_ORIGINAL:
    'Тип оригинала документа об образовании успешно изменён',
  ERROR_REVOKING_ORIGINAL: 'Ошибка отзыва оригинала',
  SUCCESS_REVOKING_ORIGINAL: 'Оригинал успешно отозван',

  // Валидация
  REQUIRED_FIELD: 'Обязательно к заполнению',
  TWO_CHARACTERS_MIN: 'Не менее двух символов',
  ONLY_LOCALE_IS_ALLOWED: 'Только кириллицей',
  DATE_RANGE: 'Дата рождения должна быть не менее 14 и не более 100 лет назад',
  VALID_EMAIL: 'Значением поля должен быть валидный email',
  VALID_MOBILE: 'Значением поля должен быть валидный номер',
  PASSWORD_MIXED:
    'Пароль должен обязательно содержать большие и маленькие латинские буквы, цифры и специальные символы',
  PASSWORD_MIN_LENGTH: 'Минимальная длина пароля 6 символов',
  PHONE_LENGTH: 'Длина номера 10 символов',
  PASSPORT_SERIES_LENGTH: 'Длина серии 4 символа',
  INTERNATIONAL_RUSSIAN_PASSPORT_SERIES_LENGTH: 'Длина серии 2 символа',
  ONLY_DIGITS_ALLOWED: 'Может содержать только цифры',
  DIGITS_AND_SYMBOLS_ALLOWED:
    'Может содержать цифры и символы латиницы и кириллицы',
  DIVISION_CODE_LENGTH: 'Длина кода подразделения 7 символов',
  DIVISION_CODE_TYPE: 'Код подразделения имеет вид: 000-000',
  PASSPORT_NUMBER_LENGTH: 'Длина номера 6 символов',
  DATE_DOCUMENT_ISSUE_INVALID: 'Дата выдачи не может быть больше текущей',
  PASSPORT_ISSUED_AT_DATE_INVALID:
    'Год выдачи паспорта не может быть больше текущего или меньше года рождения',

  // FileUploader
  ONLY_ONE_PDF: 'Только один pdf файл',
  ONLY_JPG: 'Только jpg и jpeg форматы',
  SIZE_BIGGER_3: 'Размер файлов превышает 3МБ',
  SIZE_BIGGER_10: 'Размер файлов превышает 10МБ',

  // Раздел Бланки документов
  APPLICATION_DATE: 'Дата формирования',
  APPLICATION_DOWNLOAD: 'Скачать документ',
  HINT_APPLICATION_DOWNLOAD:
    'Скачайте, проверьте данные и нажмите кнопку “Подписать”',
  HINT_RECEIPT_DOWNLOAD:
    'Предъявите распечатанную расписку вместе с оригиналом документа об образовании для зачисления в СПбПУ',

  // Раздел "Заявление на поступление" - "Направления поступления"
  DIRECTIONS_TRAINING: 'Направления поступления',
  MAX_PROGRAMS_COUNT: 'Вы можете выбрать не более ',
  // Родительный падеж (genitive case)
  DIRECTION_GEN_ONE: 'направления',
  DIRECTION_GEN_MANY: 'направлений',
  PROGRAM_GEN_ONE: 'программы',
  PROGRAM_GEN_MANY: 'программ',
  ERROR_SETTING_DIRECTIONS:
    'Во время обновления направлений произошла ошибка. Убедитесь, что для каждого направления корректно задан приоритет.',
  DIRECTIONS_CHOICE_ERROR: 'Ошибка выбора направлений',

  // Раздел "Заявление на поступление" - "Бланки документов"
  FORMS_OF_DOCUMENTS: 'Бланки документов',
  FORMS_OF_DOCUMENTS_TEXT: 'Ознакомьтесь с печатными формами документов',

  // Неотсортированное
  ERROR_HAS_OCCURRED:
    'К сожалению, произошла ошибка. Пожалуйста, попробуйте ещё раз',
  LOADING_ERROR: 'Ошибка загрузки данных.',
  PASSWORDS_MUST_MATCH: 'Пароли должны совпадать',
  NEXT: 'Сохранить и продолжить',
  SNILS_INVALID: 'СНИЛС введен некорректно',

  CREATE_APPLICATION: 'Создать заявление',
  NEW_APPLICATION: 'Новое заявление на поступление',
  TRAJECTORY_FOR_ADMISSION: 'Траектория поступления',
  LEVEL_OF_TRAINING: 'Уровень образования',
  DIRECTIONS_TYPE: 'Форма оплаты',
  FACULTY: 'Подразделение',
  EDUCATION_DOCUMENT: 'Документ об образовании',
  FILLING_APPLICATION: 'Заявление',
  SAVE_AND_NEXT: 'Сохранить и продолжить',
  ADD_MORE: 'Добавить еще',
  DATA_NOT_RECEIVED: 'Данные не получены',
  RESET_FILTER: 'Сбросить фильтры',
  APPLICATION_DELETED: 'Заявление удалено',
  APPLICATION_TAKEN_FOR_EDITING: 'Вы можете редактировать заявление',
  APPLICATION_DELETION_ERROR: 'Во время удаления заявления произошла ошибка',
  APPLICATION_STATUS_CHANGING_ERROR:
    'При изменении статуса заяления произошла ошибка',
  PREPARATORY_DEGREE: 'Подготовительный факультет',
  APPLICATION_EDITED_BY_USER: 'Отредактировано пользователем',

  APPLICATION_TERMS_RESPONSIBILITY:
    'С информацией об ответственности за достоверность сведений, указанных в заявлении, и за подлинность документов, подаваемых при поступлении ознакомлен(а)',
  APPLICATION_TERMS_RULES:
    'С Уставом СПбПУ, Лицензией на право осуществления образовательной деятельности, приложениями к ней, Свидетельством о государственной аккредитации и приложением к нему, Правилами приема СПбПУ, Правилами внутреннего распорядка обучающихся и информацией, регламентирующей организацию и осуществление образовательной деятельности ознакомлен(а)',
  APPLICATION_TERMS_DIPLOMA_ABSENCE:
    'Подтверждаю отсутствие диплома бакалавра, диплома специалиста, диплома магистра (при поступлении на обучение на бюджетное место)',
  APPLICATION_TERMS_PERSONAL_INFORMATION:
    'На обработку моих персональных данных согласен(на)',
  APPLICATION_TERMS_UNIVERSITY_LIMIT:
    'Заявления о приеме поданы мной не более чем в пять вузов',
  APPLICATION_TERMS_EXAMS_PRIVILEGE:
    'Подтверждаю подачу заявления о приеме на основании особого права без вступительных испытаний только в СПбПУ (для лиц, имеющих право на прием без вступительных испытаний)',
  APPLICATION_TERMS_SPECIALIST_DIPLOMA_ABSENCE:
    'Подтверждаю отсутствие диплома специалиста (за исключением наличия диплома о ВПО, с присвоением квалификации «дипломированный специалист»), диплома магистра (при поступлении на обучение на бюджетное место)',
  APPLICATION_TERMS_GRADUATE_COPIES:
    'С копиями Лицензии на право осуществления образовательной деятельности, приложением к ней, Устава СПбПУ, Правилами приема и условиями обучения в данном образовательном учреждении ознакомлен(а)',
  APPLICATION_TERMS_GRADUATE_GOVERNMENT_RIGHT:
    'С фактом наличия/отсутствия (ненужное зачеркнуть) свидетельства о государственной аккредитации и приложением к нему по выбранному направлению подготовки (специальности), дающим право на выдачу документа государственного образца о высшем образовании ознакомлен(а)',
  APPLICATION_TERMS_GRADUATE_DIPLOMA_ABSENCE_BUDGET:
    'Подтверждаю отсутствие диплома об окончании аспирантуры или диплома кандидата наук (при поступлении на обучение на бюджетное место)',
  APPLICATION_TERMS_GRADUATE_APPEAL:
    'С правилами подачи апелляции по результатам экзаменов СПбПУ ознакомлен(а)',
  APPLICATION_TERMS_GRADUATE_DIPLOMA_ABSENCE_CONTRACT:
    'Подтверждаю, что не имею диплом об окончании аспирантуры или диплома кандидата наук (при поступлении на обучение на места в рамках контрольных цифр)',
  APPLICATION_TERMS_GRADUATE_DOCUMENT_ORIGINAL:
    'Оригинал документа об образовании обязуюсь предоставить до 19.09.2024 при поступлении на бюджетную основу',
  APPLICATION_TERMS_GRADUATE_CONTRACT:
    'В случае поступления на контрактной основе обязан(а) дать письменное согласие и заключение договора об оказании платных образовательных услуг на зачисление до 24.09.2024',
  CREATE_CONTRACT_ERROR:
    'Вы не можете создать новый договор, если не сдали все необходимые экзамены или неверно заполнили форму',
  STATEMENT: 'Заявление',
  DATE_AND_TIME: 'Дата и время подачи',
  STATUS: 'Статус',
  ORIGINAL_DOCUMENT: 'Оригинал документа',
  SUBMITTED_AT: 'Дата и время подачи оригинала',
  SUBMITTED: 'Подан',
  NOT_SUBMITTED: 'Не подан',
  REVOKED: 'Отозван',
  ACHIEVEMENTS: 'Достижения',
  PRIORITY_ENROLLMENT: 'Льготы',
  CONTRACT: 'Договор',
  DOWNLOAD_CONTRACT: 'Скачать договор',
  CONTRACT_TEXT:
    'Сформируйте и прикрепите отсканированную подписанную копию договора',
  AGREEMENT: 'Согласие на зачисление',
  SUBMIT_AGREEMENT: 'Подать согласие',
  REVOKE_AGREEMENT: 'Отозвать согласие',
  AGREEMENT_SEND_DATE_CAPTION: 'Согласие было подано:',
  CONTRACT_PAYMENT_TYPE: 'Тип платежа',
  ENTRANCE_EXAMS: 'Вступительные испытания',
  SELECT_EXAM_DATE: 'Укажите дату экзамена',
  WAIT_EXAM_DATE: 'Дождитесь начала экзамена',
  PASSING_SCORE: 'Минимальный балл',
  EDUCATIONAL_PROGRAM: 'Образовательная программа',
  ENTRANCE_EXAMS_TEXT:
    'Выберите дату и время для прохождения вступительного испытания',
  UNIFIED_EXAMS: 'Результаты ЕГЭ',
  OFFLINE_EXAM: 'Экзамен проходит очно',
  UNIFIED_EXAMS_TEXT:
    'Заполните раздел, если Вы сдавали ЕГЭ или Централизованное тестирование (для граждан Республики Беларусь)',

  CHOOSE_DIRECTION: 'Выбрать направление',
  DIRECTION: 'Направление',
  FORM_PAYMENT_ID: 'Форма оплаты',
  BUDGET_LEVEL: 'Уровень бюджета',
  EDUCATION_LANGUAGE: 'Язык обучения',

  PAY_TUITION: 'Оплатить обучение',
  FIND: 'найти',
  CONFIRM: 'Подтвердить выбор',
  CHOOSE: 'Выбрать',
  CANCEL: 'Отмена',

  PAYMENT_TYPE: 'Тип платежа',
  PAYER_APPLICANT_THE_SAME:
    'Плательщик по договору (заказчик) и абитуриент одно и то же лицо',
  PAYER_IS_NOT_APPLICANT:
    'Плательщик по договору (заказчик) не является абитуриентом',
  PAYER_IS_ORGANIZATION:
    'Плательщик по договору (заказчик) является юридической организацией',

  BANK_IDENTIFIER_CODE: 'Длина БИК 9 цифр',
  CHECKING_ACCOUNT: 'Длина расчетного счета 20 цифр',
  CORRESPONDENT_ACCOUNT: 'Длина корреспондентского счета 20 цифр',

  POLYTECH_FULLNAME: 'ФГАОУ ВО «СПбПУ»',
  DOWNLOAD_FILE_ERROR: 'Ошибка при скачивании файла',

  TITLE_BLANK_HEADER:
    'Чтобы отправить заявление в приемную комиссию, Вам необходимо: ',
  TITLE_BLANK_EL1: '1.  Отметить ВСЕ галочки внизу данной страницы. ',
  TITLE_BLANK_EL2:
    '2.  Скачать и ознакомиться с печатной формой заявления на прием документов. ',
  TITLE_BLANK_EL3:
    '3.  Подписать печатную форму заявления на прием документов, нажав на соответствующую кнопку. Согласие на обработку персональных данных сформировано автоматически при регистрации в личном кабинете абитуриента.',
  COMPLIANCE_LETTER: 'Расписка об информировании',
  RECEIPT_FOR_THE_ACCEPTED_ORIGINAL_DOCUMENT_OF_EDUCATION:
    'Расписка за принятый оригинал документа об образовании',

  OPEN_APPLICATION: 'Открыть заявление',
  EDIT_APPLICATION: 'Забрать на редактирование',
  SEND_APPLICATION: 'Отправить заявление на проверку',
  EDIT_FIELDS: 'Изменить направление или приоритет',
  PLAN_CONTINUE:
    'Планирую поступать после окончания подготовительного факультета на:',
  STUDY_FIELD: 'Направление поступления',
  WITHDRAW_APPLICATION: 'Отозвать заявление',

  HINT_EXAM_DATE_TITLE:
    'При выборе даты сдачи вступительного испытания просим обратить внимание на следующую информацию:',
  HINT_EXAM_DATE_CLOSE_24H:
    'Запись на вступительные испытания закрывается за 24 часа до начала вступительного испытания',
  HINT_EXAM_DATE_AUTOMATIC_ENTRY:
    'Запись на вступительные испытания проводится автоматически при выборе даты из выпадающего меню',
  HINT_EXAM_DATE_IF_NOT_PLANNING:
    'Если вы не планируете проходить вступительное испытание, на которое вы записались, то перезаписаться на другую дату можно не позднее, чем за 24 часа до начала вступительного испытания, на которое вы записаны',
  HINT_EXAM_DATE_NEED_PRESS_BUTTON:
    'Для прохождения вступительного испытания необходимо в день его проведения нажать кнопку "Начать экзамен"',
  HINT_EXAM_DATE_MOSCOW_TIME:
    'Вступительные испытания организованы по Московскому времени (GMT+3)',
  EXAM: 'Предмет',
  EXAM_DATE: 'Дата и время экзамена',
  SCORE: 'Оценка (балл)',
  NOT_GRADED: 'Оценки еще нет',
  EXAM_DATE_MOSCOW: 'Дата и время экзамена',
  EXAM_DATE_CHANGE_ERROR: 'Не получилось поменять дату экзамена',
  EXAM_DATE_DELETE_ERROR: 'Не получилось удалить дату экзамена',
  HINT_NOT_AVAILABLE: 'Нет данных',
  DOWNLOAD_FORM: 'Скачать форму',
  HINT_DOWNLOAD_FORM:
    'Скачайте, распечатайте и подпишите договор, затем загрузите подписанный в область ниже',
  SIGNED_CONTRACT: 'Подписанный договор',
  SEND: 'Отправить',
  SET_BY_MODER_MARK: 'Оценка выставлена вручную',

  SING: 'Подписать',
  START_EXAM:
    'Расписание вступительных испытаний будет установлено позже. Следите за обновлениями данного раздела.',
  CONTRACT_HINT: 'Для оплаты договора подгрузите скан подписанного договора',
  DISCIPLINES_HINT:
    'Для того, чтобы положить заявление о согласии на зачисление, нужно сформировать договор об оказании платных образовательных услуг и приложить его скан копию в раздел «Договор»',
  ACHIEVEMENTS_HINT: 'Для сохранения изменений нажмите кнопку "Вперед"',

  CONSENT_GIVEN: 'Согласие подано',
  GIVE_CONSENT: 'Подать согласие',
  CONFIRM_CONSENT: 'Подтвердите действие',

  // Льготы и преимущественные права
  ADD_PRIVILEGES_TITLE: 'Добавить льготу',
  EDIT_PRIVILEGES_TITLE: 'Редактировать льготу',
  ADD_PREEMPTIVE_RIGHT_TITLE: 'Добавить преимущественное право',
  EDIT_PREEMPTIVE_RIGHT_TITLE: 'Редактировать преимущественное право',
  ADD_INDIVIDUAL_QUOTA_TITLE: 'Добавить отдельную квоту',
  EDIT_INDIVIDUAL_QUOTA_TITLE: 'Редактировать отдельную квоту',
  TYPE_PREEMPTIVE: 'Причина',
  TYPE_LGOTA: 'Тип льготы',
  TYPE_INDIVIDUAL_QUOTA: 'Тип отдельной квоты',
  TYPE_ACHIEVEMENT: 'Тип достижения',
  TYPE_DOCUMENT: 'Тип документа',
  NUMBER_DOCUMENT: 'Серия (если есть) и номер документа',
  DATE_ISSUE: 'Дата выдачи',
  DOCUMENT: 'Документ',
  CHECKBOX_LGOTA: 'У меня нет льгот или преимущественные права поступления',
  CHECKBOX_PREEMPTIVE_RIGHTS:
    'У меня нет преимущественного права на поступление',
  ADD_BUTTON: 'Добавить',
  LGOTS: 'Льготы',
  LGOT: 'Льгота',
  SPECIAL_QUOTA: 'Специальная квота',
  SPECIAL_ADMISSION: 'По особенностям приёма 2024 года',
  INDIVIDUAL_QUOTA: 'Отдельная квота',
  PREEMPTIVE_RIGHTS: 'Преимущественное право',
  PRIORITY_RIGHTS: 'Право на зачисление в первоочередном порядке',
  PRIORITY_RIGHT_TYPE: 'Тип приоритетного права',
  ADD_PRIORITY_RIGHT: 'Добавить приоритетное право',
  EDIT_PRIORITY_RIGHT: 'Редактировать приоритетное право',
  CONFIRMATION_DOCUMENT: 'Подтверждающий документ',
  SERIES_AND_NUMBER: 'Серия и номер',

  // Отличительные признаки
  ADD_HALLMARK_TITLE: 'Добавить отличительный признак',
  EDIT_HALLMARK_TITLE: 'Редактировать отличительный признак',
  HALLMARK_TYPE: 'Тип отличительного признака',

  // Направление подготовки (модератор)
  REMOVED: 'Удалено',
  DISCIPLINES: 'Предметы',
  SOURCE_OF_FINANCING: 'Источник финансирования',
  TARGET_ORGANIZATION: 'Целевая организация',
  TARGET_ORGANIZATION_NAME: 'Полное наименование',
  TARGET_ORGANIZATION_SHORTNAME: 'Краткое наименование',
  TARGET_ORGANIZATION_ADDRESS: 'Адрес',
  TARGET_ORGANIZATION_PHONE: 'Телефон',
  TARGET_ORGANIZATION_EMAIL: 'Электронная почта',
  FORM_OF_STUDY: 'Форма обучения',
  THE_LEVEL_OF_EDUCTION: 'Уровень образования',
  SUBDIVISION: 'Подразделение',
  CREATION_DATE: 'Дата и время подачи',
  DELETION_DATE: 'Дата и время отзыва',
  REFUSE_ENROLLMENT_DATE: 'Дата и время отказа от зачисления',
  ADMITTED: 'Доступно к выгрузке',
  ENTRANCE_TESTS_SATISFIED: 'Соответствует набору ВИ',
  PRIVILEGE_ADMITTED: 'Присутствует подтверждённая льгота',
  TARGET_CONTRACT_PRESENT: 'Присутcвует целевой договор',
  DELETE: 'Удалить',
  CONFIRM_MODAL:
    'Подтвердите действие. Нажимая "Да", Вы подписываете заявление о согласии на зачисление',
  CONFIRM_WITHDRAWN_APPLICATION:
    'После нажатия кнопку "Отозвать заявление" заявление будет переведено в статус "Отзыв одобрен"',
  ERROR_CANT_UPDATE_CHECKLIST: 'Не удалось обновить чеклист',
  ERROR_CANT_PARSE_CHECKLIST: 'Не удалось распарсить чеклист',

  // ЕГЭ
  DIDNT_TAKE: 'Не сдавал(а)',
  SUBJECT: 'Предмет',
  ADD_SUBJECT: 'Добавить предмет',
  EXAM_YEAR: 'Год сдачи',
  MINIMUM_SCORE: 'Минимальный балл',
  CHECK_RESULTS: 'Проверить результаты ЕГЭ',
  ANOTHER_PASSPORT: 'Сдавал(а) ЕГЭ с другим паспортом',

  // Договора
  CREATE_CONTRACT: 'Новый договор',
  PAYER_INFORMATION: 'Основные данные плательщика',
  PAYER_PASSPORT: 'Паспортные данные плательщика',

  // Чат
  CHAT_TITLE: 'Чат поддержки абитуриентов',
  CHAT_SUBTITLE: 'Задайте вопрос',
  CHAT_PLACEHOLDER: 'Введите сообщение...',
  CHAT_FILE_UPLOAD:
    'Поместите файл сюда, чтобы прикрепить его к сообщению (не более 5 мб)',

  // Списки поступающих
  APPLICANTS_PROGRAM: 'Списки поступающих по программам',
  STUDIES: 'в',
  PROGRAMS: 'в',
  PHD: 'Аспирантура',
  FOR_RUSSIAN_CITIZEN: '2022 году для граждан РФ',
  FOR_FOREIGN_CITIZEN: '2022 году для Иностранных граждан',
  ENROLLMENT_OPTION: 'Основания поступления',
  INSTITUTE: 'Институт',
  ENTER_APPLICANT_ID: 'Введите ID поступающего',
  PROFILE: 'Профиль',
  WITHOUT_PROFILE: 'Без профиля',
  SEARCH: 'Поиск',
  PRIORITY_ENROLLMENT_APPLICANTS: 'Льготная конкурсная группа',
  LAST_UPDATE: 'Последнее обновление: ',
  TABLE_SEARCH: 'Поиск по таблице',
  TABLE_NUMBER: 'Номер по порядку',
  TABLE_ID: 'Уникальный код',
  TABLE_OLYMPIAD: 'Олимпиада',
  TABLE_GRADE: 'Сумма баллов по предметам',
  TABLE_GRADE_ACHIEVEMENTS: 'Сумма ИД',
  TABLE_GRADE_ALL: 'Сумма баллов всего',
  TABLE_GRADE_ALL_SPO: 'Средний балл аттестата',
  TABLE_ORIGINAL: 'Получено',
  TABLE_COPY: 'Копия',
  TABLE_CONSENT: 'Согласие',
  TABLE_PRIORITY: 'Преимущественное право',
  TABLE_STATUS: 'Состояние',
  TABLE_FULL_NAME: 'ФИО',
  TABLE_NEED_HOSTEL: 'Нуждаемость в общежитии',
  TABLE_PROFILE_POINT: 'Профильный балл',
  TABLE_ADVANTAGE: 'Приоритет',

  // Gender
  MALE: 'Мужской',
  FEMALE: 'Женский',

  // EducationForm
  DISTANT: 'Дистанционная',
  PART_TIME: 'Заочная',
  FULL_TIME: 'Очная',

  // GeneralDataForm
  ERROR_SNILS_ISSUE_BEFORE_BIRTHDAY:
    'Дата выдачи СНИЛС не может быть раньше даты рождения',
  ERROR_FIRST_SPECIFY_BIRTHDAY: 'Сначала введите дату рождения',

  // LegalRepresentativeFrom
  ERROR_SPECIFY_ALL_OR_NOTHING:
    'Вам нужно указать либо все обязательные поля, либо никакие',

  // PassportForm
  ERROR_SAME_PRIMARY_AND_SECONDARY_CITIZENSHIP:
    'Основное и второе гражданство не могу совпадать',
  ERROR_DOC_EXP_DATE_BEFORE_ISSUE:
    'Дата окончания действия документа не может быть раньше даты получения',
  ERROR_DIFF_BIRTHDAY_DOC_ISSUE_SHOULD_BE_LESS_14:
    'Дата рождения должна быть меньше даты выдачи документа минимум на 14 лет',
  ERROR_DOC_EXP_LESS_THAN_1900:
    'Срок действия документа должен быть больше 1900 года',
  ERROR_DOC_ISSUE_LESS_THAN_1900:
    'Дата выдачи документа должна быть больше 1900 года',

  // EducationInformationForm
  ERROR_GRADUATION_YEAR_TOO_SMALL: 'Год окончания должен быть больше 1960',
  ERROR_GRADUATION_YEAR_TOO_BIG:
    'Год окончания не может быть в будущем или после даты выдачи документа',
  ERROR_GRAD_YEAR_SHOULD_BE_GREATER_THAN_BIRTHDAY:
    'Год окончания должен быть больше года рождения минимум на 14 лет',
  ERROR_GRAD_CANT_BE_AFTER_ISSUE:
    'Год окончания не может быть после года получения документа об образовании',
  ERROR_FIRST_SPECIFY_ISSUED_AT: 'Сначала укажите дату выдачи документа',

  // ApplicationState
  NOT_CREATED: 'Не создано',
  DRAFT: 'Черновик',
  UNDER_CONSIDERATION: 'На рассмотрении',
  IN_WORK: 'В работе',
  SENT_FOR_EDITING: 'Отправлено на редактирование',
  ACCEPTED: 'Одобрено',
  ALLOWED: 'Допущено',
  ENROLLED: 'Зачислен',
  DIRECTIONS_EDITING: 'Редактируется после одобрения',
  DIRECTIONS_EDITED: 'Изменено после одобрения',
  WITHDRAWN: 'Отозвано',
  WITHDRAWN_APPROVED: 'Отзыв одобрен',
  REJECTED: 'Отказано',
  EDITED_BY_USER: 'Отредактировано пользователем',

  // ContactState
  PROJECT: 'Проект',
  EDITING: 'На редактировании',
  APPROVED: 'Одобрен',
  CONFIRMED: 'Утвержден',
  CANCELLED: 'Аннулирован',

  SENT_TO_EXPERT: 'Отправлено на экспертизу',
  APPROVED_BY_EXPERT: 'Одобрено экспертом',
  REJECTED_BY_EXPERT: 'Отказано экспертом',

  SENT_TO_COORDINATOR: 'Отправлено координатору',
  APPROVED_BY_COORDINATOR: 'Одобрено координатором',
  REJECTED_BY_COORDINATOR: 'Отказано координатором',

  READ: 'Нет непрочитанных сообщений',
  UNREAD: 'Есть непрочитанные сообщения',

  SECONDARY: 'СПО',

  // Directions request type
  BUDGET_FORM: 'Бюджет',
  CONTRACT_FORM: 'Контракт',

  // Education levels
  BACHELOR_EDUCATION_LEVEL: 'Бакалавриат/Специалитет',
  SECONDARY_VOCATIONAL_EDUCATION_LEVEL: 'СПО',
  SPECIALIST_EDUCATION_LEVEL: 'Специалитет (ИЯЭ филиал г. Сосновый Бор)',
  MASTER_EDUCATION_LEVEL: 'Магистратура',
  GRADUATE_EDUCATION_LEVEL: 'Аспирантура',
  UNIVERSITY_FOUNDATION_PROGRAMS_EDUCATION_LEVEL: 'Подготовительный факультет',

  LOCAL: 'Поступаю как гражданин РФ',
  FOREIGN: 'Поступаю как иностранный гражданин',
  QUOTA: 'Поступаю по квоте Министерства науки и высшего образования РФ',
  APPLY_CONTRACT_ONLY: 'Поступаю только на контрактные (платные) места',
  GETTING_DORMITORY_ONLY_IF_ANY_LEFT:
    'Получаю место в общежитии при наличии свободных мест',
  ADMISSION_INFO_HERE: 'Информация о поступлении здесь',

  HAVE_MINOBR_DIRECTION: 'Имею направление Минобрнауки РФ',
  OPEN_DOORS_WINNER: 'Являюсь победителем/призером олимпиады Open Doors',
  STATE_FUNDED_OR_PAID_PLACES: 'Поступаю на бюджетные или платные места',
  GETTING_DORMITORY_COMPETITIVE:
    'Получаю место в общежитии на конкурсной основе',
  HAS_COMPATRIOT_DOCS: 'Имею документы, подтверждающие статус соотечественника',
  OR_CITIZEN: 'или являюсь гражданином',
  BELARUS: 'Республики Беларусь',
  KAZAKHSTAN: 'Республики Казахстан',
  KYRGYZ: 'Киргизской республики',
  TAJIKISTAN: 'Республики Таджикистан',

  OLYMPIAD: 'Без вступительных испытаний/Олимпиада',
  GOVERNMENT_CONTRACT: 'Целевой прием',
  BENEFIT: 'Особое право/Льгота',
  PREROGATIVE: 'Преимущественное право',

  RUSSIAN_PASSPORT: 'Российский паспорт',
  INTERNATIONAL_RUSSIAN_PASSPORT: 'Международный российский паспорт',
  FOREIGN_PASSPORT: 'Иностранный паспорт',
  STATELESS_IDENTITY_CARD: 'Удостоверение личности без гражданства',
  OTHER: 'Другое',

  POSTGRADUATE: 'Аспирантура',
  BACHELOR: 'Высшее образование (бакалавриат, специалитет)',
  MASTER: 'Высшее образование (магистр)',
  PRIMARY_VOCATIONAL: 'Начальное профессиональное образование',
  SECONDARY_VOCATIONAL: 'Среднее профессиональное образование',
  PRIMARY_BASIC: 'Основное общее образование',
  SECONDARY_BASIC: 'Среднее общее образование',
  NO_EDUCATION_DOCUMENT_TYPE: 'Без типа образования (ЕПГУ)',
  HIGHER_EDUCATION: 'Высшее образование',

  POSTGRADUATE_DIPLOMA: 'Диплом об окончании аспирантуры',
  BACHELOR_DEGREE: 'Диплом бакалавра',
  MASTER_DEGREE: 'Диплом магистра',
  SPECIALIST_DIPLOMA: 'Диплом специалиста',
  PRIMARY_VOCATIONAL_EDUCATION_DIPLOMA:
    'Диплом о начальном профессиональном образовании',
  SECONDARY_VOCATIONAL_EDUCATION_DIPLOMA:
    'Диплом о среднем профессиональном образовании',
  BASIC_GENERAL_EDUCATION_CERTIFICATE: 'Аттестат об основном общем образовании',
  SECONDARY_GENERAL_EDUCATION_CERTIFICATE:
    'Аттестат о среднем общем образовании',
  ACADEMIC_CERTIFICATE: 'Академическая справка',

  CANNOT_EDIT_IF_APPLICATIONS_HAS:
    'Нельзя редактировать анкету, если она была получена с портала гос. услуг, или имеется заявление, отправленное на проверку',
  CANNOT_CREATE_NEW_APPLICATION_WITH_USED_EDU_LEVEL:
    'Нельзя создавать несколько заявлений на один уровень образования',
  CANNOT_CREATE_NEW_APPLICATION_WITHOUT_EDUCATIONAL_DOCUMENT:
    'Нельзя создавать заявление без указания документа об образовании',

  ERROR_ALREADY_HAS_SIGNED_AGREEMENT_DOCUMENT:
    'Вы не можете подписать согласие на зачисление при наличии ранее подписанного согласия для другой программы',

  DOC_WAS_SIGNED: 'Документ подписан',
  ERROR_DOC_DESIRED_DIRECTION_NOT_CHOSEN:
    'Необходимо сохранить анкету в личном кабинете абитуриента',
  ERROR_FIRST_CHOOSE_DIRECTIONS:
    'Для создания заявления вы должны выбрать хотя бы одно направление поступления',
  ERROR_TRAJECTORY_NOT_CHOSEN:
    'Нельзя создать заявления, если не выбрана траектория поступления',

  PHOTO_REQUIRED: 'Фото обязательно',

  MAKE_SURE_ALL_FILES_ATTACHED: 'Убедитесь, что все файлы прикреплены',
  APPLICATION_OF_RECOGNITION: 'Заявление о самоидентификации',
  BIRTH_CERTIFICATE: 'Копия свидетельства о рождении абитуриента',
  COMPATRIOT_STATUS_DOCS:
    'Копии документов, подтверждающих статус соотечественника:\n          документы, подтверждающие гражданство СССР, проживание в государствах, входивших в состав СССР, получение гражданства этих государств;\n          документы выходца (эмигранта) из Российского государства, Российской республики, РСФСР, СССР и Российской Федерации, имеющего соответствующую гражданскую принадлежность и ставшего гражданином иностранного государства;\n          документы, подтверждающие родство по прямой восходящей  линии с вышеперечисленными гражданами',
  REQUIRED_FOR_SECOND_VOCATIONAL:
    'Обязательно для заполнения поступающим в Институт среднего профессионального образования (колледж)',
  NUMBER_OF_SATISFACTORY_GRADES: 'Количество оценок «Удовлетворительно»',
  NUMBER_OF_GOOD_GRADES: 'Количество оценок «Хорошо»',
  NUMBER_OF_EXCELLENT_GRADES: 'Количество оценок «Отлично»',
  SUBMIT_QUESTIONNAIRE_BEFORE_ACCESSING_APPLICATIONS:
    'Нажмите кнопку «СОХРАНИТЬ И ПРОДОЛЖИТЬ» в разделе «СВЕДЕНИЯ ОБ ОБРАЗОВАНИИ» внизу страницы',

  // Enroll list
  SNILS_OR_UNIQUE_CODE: 'СНИЛС / Уникальный код',
  FULLNAME_OR_SNILS_OR_UNIQUE_CODE: 'ФИО / СНИЛС / Уникальный код',
  NO_ENTRANCE_EXAMS_OR_OLYMPIC: 'Без вступительных испытаний / Олимпиада',
  TOTAL_SCORE: 'Сумма конкурсных баллов',
  TOTAL_SUBJECTS_SCORE: 'Сумма баллов по предметам',
  MATH: 'Математика / Элементы высшей математики',
  RUSSIAN: 'Русский язык',
  INFORMATICS_OR_PHYSICS:
    'Информатика и ИКТ / Физика / Информационные технологии',
  PERSONAL_ACHIEVEMENTS_SCORE: 'Сумма баллов за инд.дост.(конкурсные)',
  FEATURE: 'Преимущ. право',
  EDUCATION_DOCUMENT_TYPE: 'Вид документа об образовании',
  ENROLLMENT_AGREEMENT: 'Согласие на зачисление',
  PRIORITY: 'Приоритет',
  EDUCATION_FORMAT: 'Форма обучения',
  ADMISSION_CONDITIONS: 'Условия поступления',
  DIRECTION_EDUCATIONAL_PROGRAM:
    'Направление подготовки / Образовательная программа',
  ENTER_DIRECTION_CODE: 'Введите код направления подготовки',

  BACHELOR_OR_SPECIALIST: 'Бакалавр/Специалист',
  SECONDARY_DEGREE_SHORT: 'СПО',
  GRADUATE: 'Аспирант',
  PREPARATORY: 'Подготовительный факультет',

  SELF_PAYMENT:
    'Плательщик по договору (заказчик) и абитуриент одно и то же лицо',
  THIRD_PARTY: 'Плательщик по договору (заказчик) не является абитуриентом',
  MATERNAL: '',
  JURIDICAL:
    'Плательщик по договору (заказчик) является юридической организацией',

  ERROR_SUBMITTING_APPLICATION: 'Ошибка при отправке заявления',

  USER_ADDED_ADDITIONAL_FILE:
    'Пользователь добавил файл в раздел "Дополнительные файлы"',

  // RelationDegree
  MOTHER: 'Мать',
  FATHER: 'Отец',
  TRUSTEE: 'Доверенное лицо',

  // Roles
  USER: 'Пользователь',
  ADMIN: 'Админ',
  COORDINATOR: 'Координатор',
  MODERATOR: 'Модератор',
  SUPPORT: 'Поддержка',
  EXPERT: 'Эксперт',
  SPECIALIST: 'Специалист',
  INSTITUTE_STAFF: 'Сотрудник института',
  CALL_CENTER: 'Колл-центр',

  // Modal
  DIRECTIONS_EDITING_MODAL_TEXT:
    'Вы уверены, что вам нужно отредактировать направления в заявлении?',
  EXAM_DATETIME_DELETE_TEXT_1: 'Вы собираетесь стереть выбор экзамена ',
  EXAM_DATETIME_DELETE_TEXT_2: 'на',
  EXAM_DATETIME_DELETE_PASSED:
    'Этот экзамен сдан и за него выставлена оценка. Это действие нельзя отменить. ' +
    'Вы сотрете выбор даты экзамена и выставленную оценку. ' +
    'Ранее выбранная дата может быть больше недоступна для выбора. ' +
    'Хотите продолжить?',

  // AuditEvent
  APPLICATION_STATUS_UPDATE: 'Изменение статуса заявления',
  APPLICATION_AGREEMENT_UPDATE: 'Подача / отзыв согласия',
  APPLICATION_CREATION: 'Создание заявления',
  APPLICATION_CONTRACT_CREATE: 'Создание договора',
  APPLICATION_CONTRACT_UPDATE: 'Обновление договора заявления',
  APPLICATION_DIRECTIONS_UPDATE: 'Обновление направлений заявления',
  APPLICATION_MINOR_UPDATE: 'Редактирование заявления',
  APPLICATION_DIRECTION_DELETE: 'Удаление направления',
  APPLICATION_EDU_DOC_CHANGE: 'Изменение ДОО в заявление',
  APPLICATION_ORIGINAL_CHANGE: 'Изменение оригинала',
  APPLICATION_DESIRED_DIRECTION_CHANGE: 'Изменение "планируемого" направления',
  APPLICATION_ACHIEVEMENT_FORM_CHANGE:
    'Изменение данных о количестве публикаций',
  APPLICATION_SENT_TO_QUEUE_CHANGE: 'Ручная отправка заявления в очередь',
  APPLICATION_PROFILE_SUBJECT_SCORE_SET: 'Изменение бала профильных предметов',
  APPLICATION_PRIVILEGE_CREATE: 'Создание особой отметки',
  APPLICATION_PRIVILEGE_UPDATE: 'Редактирование особой отметки',
  APPLICATION_PRIVILEGE_DELETE: 'Удаление особой отметки',
  APPLICATION_OLYMPIAD_CREATE: 'Создание олимпиады',
  APPLICATION_OLYMPIAD_UPDATE: 'Редактирование олимпиады',
  APPLICATION_OLYMPIAD_DELETE: 'Удаление олимпиады',
  APPLICATION_ACHIEVEMENT_CREATE: 'Создание индивидуального достижения',
  APPLICATION_ACHIEVEMENT_UPDATE: 'Редактирование индивидуального достижения',
  APPLICATION_ACHIEVEMENT_DELETE: 'Удаление индивидуального достижения',
  APPLICATION_GOVERNMENT_CONTRACT_CREATE: 'Создание целевого договора',
  APPLICATION_GOVERNMENT_CONTRACT_UPDATE: 'Редактирование целевого договора',
  APPLICATION_GOVERNMENT_CONTRACT_DELETE: 'Удаление целевого договора',
  APPLICATION_PRIVILEGE_EXTRA_CREATE:
    'Создание особенности приёма для сдачи ВИ',
  APPLICATION_PRIVILEGE_EXTRA_UPDATE:
    'Редактирование особенности приёма для сдачи ВИ',
  APPLICATION_PRIVILEGE_EXTRA_DELETE:
    'Удаление особенности приёма для сдачи ВИ',
  USER_REGISTERED: 'Регистрация пользователя',
  USER_EDITED: 'Пользователь отредактирован',
  USER_ARCHIVED: 'Пользователь заблокирован',
  USER_UNARCHIVED: 'Пользователь разблокирован',
  QUESTIONNAIRE_SAVED: 'Сохранение анкеты',
  QUESTIONNAIRE_TAKEN: 'Редактирование анкеты',
  UPLOAD_USER_BLOCKS: 'Выгрузка информации в 1С',
  PROCESS_BLOCKS_UPLOAD_CONFIRMATION: 'Подтверждение обработки выгрузки',

  // AuditEventTypeHead
  QUESTIONNAIRE: 'Анкета',
  ONE_C: 'Взаимодействие с 1С',

  // Other
  INDIVIDUAL_ACHIEVEMENTS: 'Индивидуальные достижения',
  ADMISSION_CAMPAIGN: 'Приемная кампания',

  // Juridical contract form
  ORGANISATION_NAME: 'Наименование юридического лица (полное)',
  ORGANISATION_SHORT_NAME: 'Короткое наименование юридического лица',
  MANAGER_BASES: 'Реквизиты документа представителя Заказчика',
  BANK_NAME: 'Банк получателя',
  BANK_BIC: 'БИК',
  PAYMENT_ACC: 'Расчетный счет',
  CORRESPONDENT_ACC: 'Корреспондентский счет',
  PHONE_NUMBER: 'Телефон',
  ORGANISATION_PHYS_ADDR: 'Фактический адрес нахождения организации',
  JURIDICAL_ADDR: 'Юридический адрес',
  IN_NAME: 'Фамилия, имя, отчество представителя Заказчика',
  INN: 'ИНН',
  KPP: 'КПП',
  OGRN: 'ОГРН',

  // Entrance exams form
  EXAM_PASSED: 'Экзамен сдан',
  EXAM_FAILED: 'Экзамен не сдан',
  EXAM_START: 'Начать экзамен',
  ENTRANCE_EXAM_DATE_CHANGE_SUCCESS:
    'Обращаем внимание, что допуск на экзамен производится, когда статус заявки становится "Одобрено". В случае выявления факта отсутствия прав на сдачу вступительного испытания, результаты экзаменов аннулируются.',

  // Olympiads form
  OLYMPIADS: 'Олимпиады',

  // Table directories
  ADD_DIRECTORY: 'Добавить раздел в справочник',

  // Target agreement form
  HAS_NO_CONTRACT: 'Я не планирую поступать по целевой квоте',
  OFFER_DATA: 'Данные заявки на заключение договора о целевом обучении',
  ORGANIZATION_NAME: 'Наименование организации',
  OFFER_NUMBER: 'Идентификационный номер предложения',
  ISSUE_DATE: 'Дата размещения предложения',
  DOCUMENT_FILE: 'Файл заявки на заключения договора о целевом обучении',

  // ContractList
  DOCUMENT_STATUS: 'Статус договора',
  DOCUMENT_NAME: 'Название документа',
  VALID_UNTIL: 'Действителен до',
  PAYER: 'Плательщик',
  ORGANISATION_FULL_NAME: 'Полное наименование',
  ORGANISATION_GENERAL_DIRECTOR: 'Генеральный директор',
  ADDRESS: 'Адрес',
  CONTACT_NUMBER: 'Контактный телефон',
  FIO: 'Фамилия, имя, отчество',
  PHONE_NUMBERS: 'Телефон(ы)',
  PROFILE_SCORE: 'Профильный балл',

  // ЕПГУ
  NOT_RELATED: 'Не из ЕПГУ',
  SUCCESS: 'Загружено из ЕПГУ',
  ATTENTION: 'Ручная синхронизация',

  // Инструкции
  ENTRANCE_TESTS_INSTRUCTION: 'Инструкция для сдачи вступительных испытаний',
  CONTRACT_CREATION_INSTRUCTION: 'Инструкция по созданию договоров',
  CONTRACT_HEADER_MESSAGE:
    'Уважаемые абитуриенты! После формирования информации о договоре на Госуслугах просим вас предоставить оригиналы подписанных договоров (со стороны Заказчика и Обучающегося) в СПбПУ. Пожалуйста, сделайте это в сроки, указанные в Правилах приема и инструкции ниже.',

  INSTRUCTIONS_WILL_BE_LATER:
    'Абитуриентам, поступающим по траектории "Поступаю как иностранный гражданин", инструкции будут загружены в раздел "дополнительные файлы".',

  UNSENT_MESSAGE_WARN_1: 'У вас есть сообщения',
  UNSENT_MESSAGE_WARN_2: 'ожидающие отправки',
  UNSENT_MESSAGE_WARN_3: 'Закрыв вкладку вы рискуете их потерять',
  CONFIRM_EXIT: 'Покинуть страницу',
  STAY_ON_PAGE: 'Остаться',

  SIGN_UP_UNAVAILABLE_MESSAGE:
    'Регистрация в системе будет доступна с 25 марта 2024 года.',

  NONE: 'Нет мер',
  LOW: 'Менее 15 тыс. руб. в год',
  MEDIUM: 'от 15 до 30 тыс. руб. в год',
  HIGH: 'свыше 30 тыс. руб. в год',

  PHYSICAL: 'Бумажный оригинал',
  ELECTRONIC_COPY: 'Электронная копия',
  SIGNED_PHYSICAL_COPY: 'Заверенная бумажная копия',

  // TargetAgreeStatus
  REFUSED_BY_EMPLOYER: 'Заявка отклонена Заказчиком',
  REFUSED_DUE_TO_EXPIRATION:
    'Заявка отклонена в связи с истечением срока рассмотрения',
  ACCEPTED_BY_EMPLOYER: 'Заявка одобрена Заказчиком',
  UNDER_CONSIDERATION_BY_EMPLOYER: 'На рассмотрении у заказчика',
  REVOKED_BY_APPLICANT: 'Заявка отозвана гражданином',

  // epguStatus статус КГ из ССПВО
  NEW: 'Новое',
  RECEIVED_BY_UNIVERSITY: 'Получено вузом',
  ADDITIONAL_INFORMATION_REQUIRED: 'Запрос дополнительных сведений',
  PROFILE_EDITING: 'Редактирование профиля',
  EXAM_DATE_ASSIGNED: 'Назначена дата ВИ',
  EXAM_DATE_SELECTION: 'Выбор даты ВИ',
  ON_COMPETITION: 'Участвует в конкурсе',
  COMPETITION_FAILED: 'Не прошло по конкурсу',
  INCLUDED_IN_ENROLLMENT_ORDER: 'Включено в приказ на зачисление',
  REFUSED: 'Отклонено',
  AGREEMENT_REVOKED: 'Отзыв согласия',
  ENROLLMENT_REFUSED: 'Отказ от зачисления',
  AGREEMENT_EDUCATION_DOCUMENT: 'Согласие/Документ об образовании',
  AGREEMENT_EDUCATION_DOCUMENT_REVOKED:
    'Отзыв согласие/Документ об образовании',
  // EXAMINATION_FORMATION_CREATED:
  //   'При формировании бланка предварительной экспертизы успешно создан',
  // EXAMINATION_FORMATION_ERROR:
  //   'При формировании бланка предварительной экспертизы произошла ошибка',
  PROGRESS_OF_THE_ADMISSION_CAMPAIGN: 'Ход приёма',
};

export type TermKey = keyof typeof ru;
export default ru;
