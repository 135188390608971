import React from 'react';

import { Control, Controller } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';

import terms from 'i18n';

import Input from '../../../../components/common/ui-kit/Input';
import ReactSelect from '../../../../components/common/ui-kit/Select';
import ProfileSection from '../../../../components/profile/ProfileSection';
import useLazyIdEnumSelectPropsWithParams from '../../../../hooks/common/useLazyIdEnumSelectPropsWithParams';
import { useGetEducationLevelsQuery } from '../../../../redux/api/applications/directions-api';

export const DirectionsPreparatoryForm = ({
  readonly,
  errors,
  fields,
  control,
}: {
  readonly readonly: boolean;
  readonly errors: FieldErrors<any>;
  readonly control: Control<any>;
  readonly fields: any;
}): JSX.Element => {
  const educationLevelSelectProps = useLazyIdEnumSelectPropsWithParams(
    useGetEducationLevelsQuery,
    {
      forPreparatory: true,
    }
  );

  return (
    <ProfileSection title={terms.PLAN_CONTINUE}>
      <div className="profile-form__fields">
        <Input
          disabled={readonly}
          className="profile-form__field"
          placeholder={terms.STUDY_FIELD}
          {...fields.directionTraining}
          markRequired
        />
        <Controller
          name="levelEducationId"
          control={control}
          render={({ field }) => (
            <ReactSelect
              placeholder={terms.LEVEL_OF_TRAINING}
              className="profile-form__field"
              markRequired={true}
              {...field}
              options={educationLevelSelectProps.options}
              isDisabled={readonly}
            />
          )}
        />
      </div>
    </ProfileSection>
  );
};
