import React, { FC } from 'react';

import classnames from 'classnames';

import profileImagePlaceholder from 'assets/images/profile.png';
import ModeratorDataList from 'components/moderator/ModeratorDataList';
import useAppSelector from 'hooks/common/useAppSelector';
import terms, { isRussianLocale } from 'i18n';
import { DataList } from 'models/moderator';
import { formatDateYYYYMMDD } from 'utils/date';

import Loader from '../../../components/common/ui-kit/Loader';
import { useGetRecruiters } from '../../../hooks/common/api/useGetRecruiters';
import { useGetUserAvatar } from '../../../hooks/common/useGetUserAvatar';
import { Country } from '../../../models/common-entities';
import { COUNTRY_RUSSIA_ID, Gender } from '../../../models/enum';
import {
  selectCurrentSupportQuestionnaire,
  selectCurrentSupportUser,
} from '../../../redux/selectors/staff/questionnaires';

const SupportBasicDataPage: FC = () => {
  const questionnaire = useAppSelector(selectCurrentSupportQuestionnaire);
  const user = useAppSelector(selectCurrentSupportUser);

  const { image, isLoading } = useGetUserAvatar(user?.id, user?.photoUploadId);

  const recruiters = useGetRecruiters();

  if (!questionnaire || !user) {
    return <Loader />;
  }

  // TODO rewrite in future
  const primaryCitizenship: Country = (questionnaire?.generalDataForm as any)
    ?.primaryCitizenshipCountry as Country;

  const smallList: DataList = [
    {
      title: 'Гражданство:',
      label: isRussianLocale()
        ? primaryCitizenship.title
        : primaryCitizenship.titleForeign,
    },
    {
      title: 'Иностранец:',
      label: primaryCitizenship.id === COUNTRY_RUSSIA_ID ? 'Нет' : 'Да',
    },
    {
      title: 'Необходимость визы:',
      label: questionnaire.passportForm?.needVisa ? 'Да' : 'Нет',
    },
    {
      title: 'Соотечественник:',
      label: questionnaire.passportForm?.isCompatriot ? 'Да' : 'Нет',
    },
  ];

  const list: DataList = [
    {
      title: 'Партнер:',
      label:
        recruiters.find(
          r => r.value === questionnaire.generalDataForm.recruiterId
        )?.label || '-',
    },
    {
      title: 'ID в системе:',
      label: user.id + '' || '-',
    },
    {
      title: 'Email:',
      label: user.email || '-',
    },
    {
      title: 'Фамилия:',
      label: questionnaire.generalDataForm.surname || '-',
    },
    {
      title: 'Имя:',
      label: questionnaire.generalDataForm.name || '-',
    },
    {
      title: 'Отчество:',
      label: questionnaire.generalDataForm.patronymic || '-',
    },
    {
      title: 'Дата рождения:',
      label: questionnaire.generalDataForm.birthday
        ? formatDateYYYYMMDD(questionnaire.generalDataForm.birthday)
        : '-',
    },
    {
      title: 'Пол:',
      label: questionnaire.generalDataForm.gender
        ? terms[Gender[questionnaire.generalDataForm.gender]]
        : '-',
    },
    {
      title: 'Мобильный телефон:',
      label: questionnaire.generalDataForm.mobile || '-',
    },
    {
      title: 'СНИЛС:',
      label: questionnaire.generalDataForm.snils || '-',
    },
    {
      title: 'Дата СНИЛС:',
      label: questionnaire.generalDataForm.snilsIssuedAt
        ? formatDateYYYYMMDD(questionnaire.generalDataForm.snilsIssuedAt)
        : '-',
    },
  ];

  const englishName = [
    {
      title: 'Фамилия латинскими буквами:',
      label: questionnaire.generalDataForm.surnameLatin || '-',
    },
    {
      title: 'Имя латинскими буквами:',
      label: questionnaire.generalDataForm.nameLatin || '-',
    },
    {
      title: 'Отчество латинскими буквами:',
      label: questionnaire.generalDataForm.patronymicLatin || '-',
    },
  ];

  if (
    questionnaire.generalDataForm.surnameLatin ||
    questionnaire.generalDataForm.nameLatin ||
    questionnaire.generalDataForm.patronymicLatin
  ) {
    list.splice(6, 0, ...englishName);
  }

  return (
    <div className="moderator-basic-data-page">
      <div
        className={classnames(
          'moderator-basic-data-page__image',
          'profile-image-block'
        )}
      >
        <div className="profile-image-block__img-wrapper">
          {isLoading ? (
            <Loader />
          ) : (
            <img
              src={image || profileImagePlaceholder}
              alt={terms.PHOTO}
              className="profile-image-block__img"
            />
          )}
        </div>
        <ModeratorDataList list={smallList} />
      </div>
      <ModeratorDataList list={list} />
    </div>
  );
};

export default SupportBasicDataPage;
