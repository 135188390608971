import { NavRoute } from 'types/custom';

import { moveItem } from 'utils/array';

export const getRouteStatus = <T>(
  invalidKeys: T[],
  visitedPaths: string[],
  route: NavRoute<T>,
  currentRoute?: string
) => {
  if (route.to === currentRoute) return 'active';
  return invalidKeys.includes(route.key) ? 'invalid' : 'valid';
};

export const findNextRoute = <T>(
  routes: NavRoute<T>[],
  currentRoutePath: string
) => {
  if (routes.length === 0) return null;
  const currentRouteIndex = routes.findIndex(r => r.to === currentRoutePath);
  if (currentRouteIndex === -1) return null;
  const nextPageIndex = routes.findIndex(
    (r, index) => !r.hidden && index > currentRouteIndex
  );
  if (!nextPageIndex) return null;
  return routes[nextPageIndex];
};

export const validateRoutes = <T>(
  routes: NavRoute<T>[],
  invalidRouteKeys: NavRoute<T>['key'][],
  currentRoute?: string
): NavRoute<T>[] =>
  routes.map(route => ({
    ...route,
    status:
      currentRoute === route.to
        ? 'active'
        : invalidRouteKeys.includes(route.key)
        ? 'invalid'
        : 'valid',
  }));

export const findFirstInvalidRoute = <T>(routes: NavRoute<T>[]) =>
  routes.find(r => !r.hidden && r.status === 'invalid') || null;

type GetFilteredRoutesReturnValue<T> = Record<
  | 'hiddenRoutes'
  | 'visibleRoutes'
  | 'visitedRoutes'
  | 'visitedSortedRoutes'
  | 'unvisitedRoutes',
  NavRoute<T>[]
>;

export const getFilteredRoutes = <T>(
  routes: NavRoute<T>[],
  currentRoute?: string
): GetFilteredRoutesReturnValue<T> => {
  const hiddenRoutes: NavRoute<T>[] = [];
  const visibleRoutes: NavRoute<T>[] = [];
  const visitedRoutes: NavRoute<T>[] = [];
  const unvisitedRoutes: NavRoute<T>[] = [];

  for (const route of routes) {
    if (route.hidden) {
      hiddenRoutes.push(route);
    } else {
      visibleRoutes.push(route);

      if (route.status === 'not visited') {
        unvisitedRoutes.push(route);
      } else {
        visitedRoutes.push(route);
      }
    }
  }

  const currentRouteIndex = visitedRoutes.findIndex(r => r.to === currentRoute);
  const visitedSortedRoutes =
    currentRouteIndex === -1
      ? [...visitedRoutes]
      : moveItem(visitedRoutes, currentRouteIndex, visitedRoutes.length);

  return {
    hiddenRoutes,
    visibleRoutes,
    visitedRoutes,
    unvisitedRoutes,
    visitedSortedRoutes,
  };
};
