// TODO DELETE ME -- I"M USELESS
import { FC, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { ChildrenProps } from 'utils/default-props';

import useAppDispatch from '../../../hooks/common/useAppDispatch';
import { AuthCredentials } from '../../../models/auth';
import { staffApi } from '../../../redux/api/staff/staff-api';
import { setAuthCredentials } from '../../../redux/slices/common/auth-slice';
import { getAuthCredentialsFromStorage } from '../../../utils/auth-token';

const STAFF_CREDENTIALS = 'staff:credentials';
const AUTH_CREDENTIALS = 'auth:credentials';

export const swapLocalStorageCredentials = () => {
  const first = sessionStorage.getItem(AUTH_CREDENTIALS);
  const second = sessionStorage.getItem(STAFF_CREDENTIALS);
  if (!first || !second) return;
  sessionStorage.setItem(AUTH_CREDENTIALS, second);
  sessionStorage.setItem(STAFF_CREDENTIALS, first);
};

const saveStaffUserCredentials = (credentials: AuthCredentials) => {
  sessionStorage.setItem(STAFF_CREDENTIALS, JSON.stringify(credentials));
};

export const getImpersonateCredentials = () =>
  sessionStorage.getItem(AUTH_CREDENTIALS);

const removeStaffCredentials = () =>
  sessionStorage.removeItem(STAFF_CREDENTIALS);

const WithImpersonate: FC<ChildrenProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { userId } = useParams();

  const [impersonate] = staffApi.useImpersonateMutation();

  useEffect(() => {
    sessionStorage.setItem(
      AUTH_CREDENTIALS,
      localStorage.getItem(AUTH_CREDENTIALS)!
    );

    const staffCredentials = sessionStorage.getItem(STAFF_CREDENTIALS);
    if (staffCredentials) {
      dispatch(setAuthCredentials(JSON.parse(staffCredentials)));
      swapLocalStorageCredentials();
    }

    impersonate({
      userId: Number(userId),
    })
      .unwrap()
      .then(({ access }) => {
        dispatch(setAuthCredentials(access));
        saveStaffUserCredentials(access);
        swapLocalStorageCredentials();
      });

    return () => {
      swapLocalStorageCredentials();
      dispatch(setAuthCredentials(getAuthCredentialsFromStorage()));
      removeStaffCredentials();
    };
  }, []);

  return <>{children}</>;
};

export default WithImpersonate;
