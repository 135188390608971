import { useEffect, useState } from 'react';

import { useFieldArray, useForm, useWatch } from 'react-hook-form';

import terms from 'i18n';

import { ApplicationEducationLevel } from '../../../models/applications/enum';
import { applicationsApi } from '../../../redux/api/applications/applications-api';
import {
  selectApplication,
  selectCurrentEducationLevel,
} from '../../../redux/selectors/enrollee/application';
import { applicationSlice } from '../../../redux/slices/enrollee/application-slice';
import useAppDispatch from '../../common/useAppDispatch';
import useAppSelector from '../../common/useAppSelector';

type CheckBox = {
  checked: boolean;
  label: string;
};

const createCheckbox = (label: string): CheckBox => ({
  checked: false,
  label,
});

const checkboxes: { [key in ApplicationEducationLevel]: CheckBox[] } = {
  [ApplicationEducationLevel.PREPARATORY]: [
    createCheckbox(terms.APPLICATION_TERMS_RESPONSIBILITY),
    createCheckbox(terms.APPLICATION_TERMS_RULES),
  ],
  [ApplicationEducationLevel.SECONDARY]: [
    createCheckbox(terms.APPLICATION_TERMS_RESPONSIBILITY),
    createCheckbox(terms.APPLICATION_TERMS_RULES),
    createCheckbox(terms.APPLICATION_TERMS_PERSONAL_INFORMATION),
  ],
  [ApplicationEducationLevel.BACHELOR]: [
    createCheckbox(terms.APPLICATION_TERMS_RESPONSIBILITY),
    createCheckbox(terms.APPLICATION_TERMS_RULES),
    createCheckbox(terms.APPLICATION_TERMS_DIPLOMA_ABSENCE),
    createCheckbox(terms.APPLICATION_TERMS_UNIVERSITY_LIMIT),
    createCheckbox(terms.APPLICATION_TERMS_EXAMS_PRIVILEGE),
  ],
  [ApplicationEducationLevel.MASTER]: [
    createCheckbox(terms.APPLICATION_TERMS_RESPONSIBILITY),
    createCheckbox(terms.APPLICATION_TERMS_RULES),
    createCheckbox(terms.APPLICATION_TERMS_SPECIALIST_DIPLOMA_ABSENCE),
  ],
  [ApplicationEducationLevel.GRADUATE]: [
    createCheckbox(terms.APPLICATION_TERMS_RESPONSIBILITY),
    createCheckbox(terms.APPLICATION_TERMS_RULES),
    createCheckbox(terms.APPLICATION_TERMS_GRADUATE_COPIES),
    createCheckbox(terms.APPLICATION_TERMS_GRADUATE_GOVERNMENT_RIGHT),
    createCheckbox(terms.APPLICATION_TERMS_GRADUATE_DIPLOMA_ABSENCE_BUDGET),
    createCheckbox(terms.APPLICATION_TERMS_GRADUATE_APPEAL),
    createCheckbox(terms.APPLICATION_TERMS_PERSONAL_INFORMATION),
    createCheckbox(terms.APPLICATION_TERMS_GRADUATE_DIPLOMA_ABSENCE_CONTRACT),
    createCheckbox(terms.APPLICATION_TERMS_GRADUATE_DOCUMENT_ORIGINAL),
    createCheckbox(terms.APPLICATION_TERMS_GRADUATE_CONTRACT),
    createCheckbox(terms.APPLICATION_TERMS_RESPONSIBILITY),
  ],
};

export type DocumentCheckBoxesForm = {
  checkboxes: CheckBox[];
};

export const useDownloadFormCheckboxes = () => {
  const dispatch = useAppDispatch();

  const { control, register, reset, setValue, getValues } =
    useForm<DocumentCheckBoxesForm>();
  const educationLevel = useAppSelector(selectCurrentEducationLevel);
  const application = useAppSelector(selectApplication);

  const [isAllCheckBoxesChecked, setIsAllCheckBoxesChecked] =
    useState<boolean>(false);
  const [isCheckMarked, setIsCheckMarked] = useState<boolean>(false);

  const [setCheckMarkedMutation] = applicationsApi.useSetCheckMarkedMutation();

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'checkboxes',
  });

  const formValues = useWatch({
    control,
    name: 'checkboxes',
  });

  useEffect(() => {
    if (educationLevel) {
      remove();
      checkboxes[educationLevel].map(checkbox => append(checkbox));
    }
  }, [educationLevel]);

  useEffect(() => {
    if (application && fields && application.checkmarked) {
      setIsCheckMarked(true);
      checkAllCheckboxes();
    }
  }, [application, fields.length]);

  useEffect(() => {
    if (formValues) {
      setIsAllCheckBoxesChecked(formValues.every(checkbox => checkbox.checked));
    }
  }, [formValues]);

  const checkAllCheckboxes = () => {
    fields.forEach((_, index) => setValue(`checkboxes.${index}.checked`, true));
  };

  const updateCheckMark = (value: boolean) => {
    if (!application) return;
    setIsCheckMarked(value);
    setCheckMarkedMutation({
      applicationId: application.id,
      checkmarked: value,
    })
      ?.unwrap()
      .then(() => dispatch(applicationSlice.actions.setCheckMarked(value)));
  };

  return {
    reset,
    fields,
    register,
    onCheckboxChange: ({ target: { checked } }) => {
      if (
        checked &&
        getValues().checkboxes?.every(checkbox => checkbox.checked)
      ) {
        updateCheckMark(true);
      }

      if (
        isCheckMarked &&
        getValues().checkboxes?.some(checkbox => !checkbox.checked)
      ) {
        updateCheckMark(false);
      }
    },
    checkAllCheckboxes,
    isAllCheckBoxesChecked,
    isDocumentsDisabled: formValues?.every(checkbox => checkbox.checked),
  };
};
