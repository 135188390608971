import React, { FC } from 'react';

import Button from 'components/common/ui-kit/Button';
import TrajectoryList from 'components/statements/TrajectoryList';
import { statementsTabRotes } from 'config/routes/statements-tab-routes';
import useTrajectoryPage from 'hooks/statements/useTrajectoryPage';
import terms from 'i18n';
import ProgressTabPage from 'pages/ProgressTabPage';

const TrajectoryPage: FC = () => {
  const { onSubmit, isSubmitButtonActive } = useTrajectoryPage();

  return (
    <main className="trajectory-page">
      <ProgressTabPage
        activePage={statementsTabRotes.find(l =>
          location.pathname.includes(l.to)
        )}
      >
        <TrajectoryList />
        <Button
          onClick={onSubmit}
          theme="success"
          disabled={isSubmitButtonActive}
        >
          {terms.SAVE_AND_NEXT}
        </Button>
      </ProgressTabPage>
    </main>
  );
};

export default TrajectoryPage;
