import { useNavigate, useParams } from 'react-router-dom';

import {
  Invalidateable,
  useRefetchQuestionnaire,
} from 'hooks/questionnaire/common/draft/useQuestionnaire';

import { swapLocalStorageCredentials } from '../../components/common/containers/WithImpersonate';
import { isRussianLocale } from '../../i18n';
import { UserRole } from '../../models/user';
import { createToast } from '../../redux/actions';
import { useCreateEnrolleeFromQuestionnaireMutation } from '../../redux/api/questionnaire/enrollees-api';
import { selectUserRole } from '../../redux/selectors';
import { getReturnRouteFromImpersonate } from '../../utils/domain/impersonate';
import useAppDispatch from '../common/useAppDispatch';
import useAppSelector from '../common/useAppSelector';

export const useProfileRoutes = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userId, applicationId } = useParams();

  const { questionnaireId } = useAppSelector(state => state.questionnaire);
  const role = useAppSelector(selectUserRole);

  const [createEnrolleeFromQuestionnaireRequest] =
    useCreateEnrolleeFromQuestionnaireMutation();

  const refetchQuestionnaire = useRefetchQuestionnaire();

  const createEnrolleeFromQuestionnaire = () => {
    if (!questionnaireId) return;

    if (
      role === UserRole.ADMIN ||
      role === UserRole.MODERATOR ||
      role === UserRole.INSTITUTE_STAFF ||
      role === UserRole.SPECIALIST
    ) {
      swapLocalStorageCredentials();
    }

    createEnrolleeFromQuestionnaireRequest(questionnaireId)
      .unwrap()
      .then(() => {
        if (role === UserRole.USER) {
          navigate('../../application');
        } else {
          navigate(
            getReturnRouteFromImpersonate({
              applicationId,
              userId,
            })
          );
        }
        refetchQuestionnaire(true, Invalidateable.ENROLLEE);
      })
      .catch(e => {
        if (e.data.status === 'ALREADY_EXISTS') navigate('../../application');
        const errorDescriptionLocale: string = isRussianLocale()
          ? e.data?.descriptionRu ?? e.data.description
          : e.data.description;
        dispatch(createToast(errorDescriptionLocale, 'danger'));
      })
      .finally(() => {
        if (
          role === UserRole.ADMIN ||
          role === UserRole.MODERATOR ||
          role === UserRole.INSTITUTE_STAFF ||
          role === UserRole.SPECIALIST
        ) {
          swapLocalStorageCredentials();
        }
      });
  };

  return {
    questionnaireId,
    createEnrolleeFromQuestionnaire,
  };
};
