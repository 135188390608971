import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApplicationByStaff } from '../../../models/applications/applications';
import { User } from '../../../models/enrollee';
import { Questionnaire } from '../../../models/questionnaire/questionnaire';

type StaffApplicationsState = {
  readonly filters: object;
  readonly page: number;
  readonly size: number;
  readonly orderBy?: string;
  readonly orderDirection?: 'ASC' | 'DESC';
  readonly totalPages: number;
  readonly totalElements: number;
  readonly elements: ApplicationByStaff[];
  readonly currentApplication?: {
    readonly application: ApplicationByStaff;
    readonly questionnaire: Questionnaire;
    readonly user: User;
  };
};

const initialState: StaffApplicationsState = {
  filters: {},
  page: 1,
  size: 10,
  orderBy: undefined,
  orderDirection: undefined,
  totalPages: 0,
  totalElements: 0,
  elements: [],
  currentApplication: undefined,
};

export const staffApplicationsSlice = createSlice({
  name: 'moderatorApplications',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    cleanFilters: state => {
      state.filters = {};
    },

    setOrderDirection: (state, action) => {
      if (action.payload) {
        state.orderDirection = action.payload;
      } else {
        state.orderDirection = undefined;
      }
    },

    setOrderBy: (state, action) => {
      if (action.payload) {
        state.orderBy = action.payload;
      } else {
        state.orderBy = undefined;
      }
    },

    setApplications: (
      state,
      action: PayloadAction<{
        readonly applications: ApplicationByStaff[];
        readonly page: number;
        readonly totalPages: number;
        readonly size: number;
        readonly totalElements: number;
      }>
    ) => {
      state.elements = action.payload.applications;
      state.size = action.payload.size;
      state.totalPages = action.payload.totalPages;
      state.page = action.payload.page;
      state.totalElements = action.payload.totalElements;
    },

    setPage: (state, action) => {
      state.page = action.payload;
    },

    setSize: (state, action) => {
      state.size = action.payload;
    },

    setCurrentApplication: (
      state,
      action: PayloadAction<{
        readonly application: ApplicationByStaff;
        readonly questionnaire?: Questionnaire;
        readonly user?: User;
      }>
    ) => {
      state.currentApplication = {
        questionnaire:
          action.payload.questionnaire ??
          state.currentApplication!.questionnaire,
        application: action.payload.application,
        user: action.payload.user ?? state.currentApplication!.user,
      };
    },

    setCurrentApplicationProfileImage: (
      state,
      action: PayloadAction<string>
    ) => {
      if (state.currentApplication) {
        state.currentApplication.user.profileImageBase64 = action.payload;
      }
    },

    cleanCurrentApplicationProfileImage: state => {
      if (state.currentApplication) {
        state.currentApplication.user.profileImageBase64 = undefined;
      }
    },

    cleanCurrentApplication: state => {
      state.currentApplication = undefined;
    },
  },
});

export default staffApplicationsSlice.reducer;
