import React, { FC, useEffect } from 'react';

import Loader from 'components/common/ui-kit/Loader';
import useAppSelector from 'hooks/common/useAppSelector';
import { FileId } from 'models/files';
import { achievementsApi } from 'redux/api/applications/achievements-api';
import { olympiadsApi } from 'redux/api/applications/olympiads-api';
import { privilegesApi } from 'redux/api/applications/privileges-api';

import ModeratorFile from '../../../components/moderator/ModeratorFile';
import {
  useLazyDownloadFileQuery,
  useLazyPreviewFilesQuery,
} from '../../../redux/api/common/files-api';
import {
  selectCurrentApplication,
  selectCurrentApplicationUser,
  selectCurrentQuestionnaire,
} from '../../../redux/selectors/staff/applications';
import {
  selectCurrentSupportQuestionnaire,
  selectCurrentSupportUser,
} from '../../../redux/selectors/staff/questionnaires';
import { downloadFile } from '../../../utils/common';

const ModeratorDocumentsPage: FC<{ isSupport?: boolean }> = ({ isSupport }) => {
  const application = useAppSelector(selectCurrentApplication);
  const questionnaire = useAppSelector(
    isSupport ? selectCurrentSupportQuestionnaire : selectCurrentQuestionnaire
  );
  const user = useAppSelector(
    isSupport ? selectCurrentSupportUser : selectCurrentApplicationUser
  );

  const [getFilesMeta, { data: fileMeta }] = useLazyPreviewFilesQuery();
  const [download] = useLazyDownloadFileQuery();

  const { data: olympiads, isLoading: isOlympiadsListLoading } =
    olympiadsApi.useGetOlympiadsByApplicationIdQuery(application?.id ?? 0, {
      skip: !application,
    });

  const { data: achievements, isLoading: isAchievementsListLoading } =
    achievementsApi.useGetAchievementsByApplicationIdQuery(
      application?.id ?? 0,
      {
        skip: !application,
      }
    );

  const { data: privileges, isLoading: isPrivilegesListLoading } =
    privilegesApi.useGetPrivilegesByApplicationIdQuery(application?.id ?? 0, {
      skip: !application,
    });

  useEffect(() => {
    if (!questionnaire || !user) return;
    const ids = [
      questionnaire.legalRepresentativeForm.personalDataConsentId,
      questionnaire.passportForm.minobrReferralUploadId,
      questionnaire.passportForm.passportCopyUploadId,
      questionnaire.passportForm.temporaryResidencePermitUploadId,
      questionnaire.compatriotForm.applicationOfRecognitionUploadId,
      questionnaire.compatriotForm.birthCertificateUploadId,
      ...questionnaire.compatriotForm.relativesBirthCertificatesUploadIds,
      questionnaire.educationalInformationForm[0].documentCopyUploadId,
    ] as FileId[];

    if (olympiads) {
      ids.push(...olympiads.map(olympiad => olympiad.documentId));
    }

    if (achievements) {
      ids.push(...achievements.map(achievement => achievement.documentId));
    }

    if (privileges) {
      ids.push(...privileges.map(privilege => privilege.documentId));
    }

    getFilesMeta({
      userId: user.id,
      ids: ids.filter(id => id !== undefined),
    });
  }, [questionnaire, user, olympiads, achievements, privileges]);

  if (
    !questionnaire ||
    !fileMeta ||
    isOlympiadsListLoading ||
    isAchievementsListLoading ||
    isPrivilegesListLoading
  ) {
    return <Loader />;
  }

  return (
    <div className="moderator-documents-page">
      {fileMeta.length !== 0 &&
        fileMeta.map((file, index) => (
          <ModeratorFile
            key={index}
            title={file.originalName}
            content={file.createdAt}
            upload={() => {
              download({
                userId: user!.id,
                fileId: file.id,
              })
                ?.unwrap()
                .then(result => {
                  downloadFile(new File([result], `${file.originalName}`));
                });
            }}
          />
        ))}
    </div>
  );
};

export default ModeratorDocumentsPage;
