import React, { FC, useEffect, useMemo, useState } from 'react';

import ReactSelect from 'components/common/ui-kit/Select/view';
import ModeratorDataList from 'components/moderator/ModeratorDataList';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import terms from 'i18n';
import { ApplicationEducationLevel } from 'models/applications/enum';
import { SelectOption } from 'models/common';
import { UserRole } from 'models/user';
import { createToast } from 'redux/actions';
import { apiSlice } from 'redux/api/api-slice';
import { staffApplicationsApi } from 'redux/api/staff/staff-applications-api';
import { selectUserRole } from 'redux/selectors';
import { formatDateYYYYMMDD } from 'utils/date';

import Loader from '../../../components/common/ui-kit/Loader';
import CheckListEntryValueSelectWithRoleChecker from '../../../components/moderator/CheckListEntryValueSelectWithRoleChecker';
import {
  EducationInformationDraft,
  checkStatusesToStrings,
} from '../../../models/questionnaire/questionnaire';
import { applicationsInfoApi } from '../../../redux/api/applications/applications-info-api';
import {
  selectCurrentApplication,
  selectCurrentQuestionnaire,
} from '../../../redux/selectors/staff/applications';
import { selectCurrentSupportQuestionnaire } from '../../../redux/selectors/staff/questionnaires';

const EducationInfoSelect: FC<{
  userId: number;
  applicationId: number;
  educationProfileId?: number;
  educationalLevel: ApplicationEducationLevel;
}> = ({ userId, applicationId, educationalLevel, educationProfileId }) => {
  const dispatch = useAppDispatch();
  const role = useAppSelector(selectUserRole);
  const { data, isLoading } =
    applicationsInfoApi.useGetUserEducationalInformationQuery({
      userId,
      educationalLevel,
    });

  const [changeEducationalInformation] =
    staffApplicationsApi.useChangeEducationalInformationMutation();

  const options = useMemo(
    () =>
      data?.map(information => ({
        value: information.educationProfileId,
        label: `${terms[information.documentType]} (${
          information.educationalInstitution
        }, ${information.graduationYear})`,
      })),
    [data]
  );

  const [value, setValue] = useState<SelectOption>();

  useEffect(() => {
    if (!educationProfileId || !options) return;
    setValue(options.find(option => option.value === educationProfileId));
  }, [educationProfileId, options]);

  const handleChange = newValue => {
    setValue(newValue);
    const { value: educationalInformationDraftId } = newValue;

    changeEducationalInformation({
      applicationId,
      educationalInformationDraftId,
    })
      .unwrap()
      .then(() => {
        dispatch(createToast('Документ об образовании изменён', 'success'));
        dispatch(apiSlice.util.invalidateTags(['ApplicationByStaff']));
      })
      .catch(() => {
        dispatch(
          createToast(
            'Во время изменения документа об образовании произошла ошибка',
            'danger'
          )
        );
        setValue(value);
      });
  };

  return (
    <ReactSelect
      isDisabled={
        // TODO наверное можно сделать это более красиво
        (role !== UserRole.ADMIN &&
          role !== UserRole.MODERATOR &&
          role !== UserRole.SPECIALIST &&
          role !== UserRole.INSTITUTE_STAFF) ||
        !options
      }
      isLoading={isLoading}
      options={options}
      value={value}
      onChange={handleChange}
    />
  );
};

const meanGrade = (draft: EducationInformationDraft) => {
  if (
    Number(draft.goodGrades) ||
    Number(draft.excellentGrades) ||
    Number(draft.satisfactoryGrades)
  ) {
    return String(
      (
        (4 * Number(draft.goodGrades) +
          5 * Number(draft.excellentGrades) +
          3 * Number(draft.satisfactoryGrades)) /
        (Number(draft.goodGrades) +
          Number(draft.excellentGrades) +
          Number(draft.satisfactoryGrades))
      ).toFixed(3)
    );
  } else {
    return '-';
  }
};

const ModeratorEducationPage: FC<{ isSupport?: boolean }> = ({ isSupport }) => {
  const questionnaire = useAppSelector(
    isSupport ? selectCurrentSupportQuestionnaire : selectCurrentQuestionnaire
  );
  const role = useAppSelector(selectUserRole);
  const application = useAppSelector(selectCurrentApplication);

  const document = useMemo(() => {
    if (!questionnaire || !application) return;

    return questionnaire.educationalInformationForm.find(
      education =>
        education.educationalInformationDraftId ===
        application.educationProfileId
    );
  }, [questionnaire, application]);

  const list = useMemo(() => {
    if (!application) return;
    if (!document)
      return [
        {
          title: 'Документ об образовании:',
          label: (
            <EducationInfoSelect
              userId={application.userId}
              applicationId={application.id}
              educationalLevel={application.educationLevel}
              educationProfileId={application.educationProfileId}
            />
          ),
        },
      ];
    const result = [
      {
        title: 'Вид образования:',
        label: document.educationLevel ? terms[document.educationLevel] : '-',
      },
      {
        title: 'Тип документа:',
        label: document.documentType ? terms[document.documentType] : '-',
      },
      {
        title: 'Серия и номер документа:',
        label: document.educationalDocumentNumber || '-',
      },
      {
        title: 'Дата выдачи:',
        label: document.educationalDocumentIssuedAt
          ? formatDateYYYYMMDD(document.educationalDocumentIssuedAt)
          : '-',
      },
      {
        title: 'Аттестат/Диплом с отличием:',
        label: document.withHonors ? 'Да' : 'Нет',
      },
      {
        title: 'Количество оценок «Удовлетворительно»:',
        label: document.satisfactoryGrades
          ? document.satisfactoryGrades + ''
          : '-',
      },
      {
        title: 'Количество оценок «Хорошо»:',
        label: document.goodGrades ? document.goodGrades + '' : '-',
      },
      {
        title: 'Количество оценок «Отлично»:',
        label: document.excellentGrades ? document.excellentGrades + '' : '-',
      },
      {
        title: 'Средний балл:',
        label: meanGrade(document),
      },
      {
        title: 'Наименование ОУ:',
        label: document.educationalInstitution || '-',
      },
      {
        title: 'Страна ОУ:',
        label: document.educationCountry?.title || '-',
      },
      {
        title: 'Год окончания ОУ:',
        label: document.graduationYear + '' || '-',
      },
      {
        title: 'Учился на подготовительном отделении в СПбПУ:',
        label: document.studiedSpbpuPreparatoryDepartment ? 'Да' : 'Нет',
      },
      {
        title: 'Статус проверки в ССПВО:',
        label: document.checkStatus
          ? checkStatusesToStrings[document.checkStatus]
          : '-',
      },
      {
        title: 'Документ об образовании:',
        label: (
          <EducationInfoSelect
            userId={application.userId}
            applicationId={application.id}
            educationalLevel={application.educationLevel}
            educationProfileId={application.educationProfileId}
          />
        ),
      },
    ];
    if (role === UserRole.MODERATOR) return result;
    return result.toSpliced(
      -1,
      0,
      {
        title: 'ЕПГУ GUID документа:',
        label: document.epguExternalId ? document.epguExternalId : '-',
      },
      {
        title: 'ЕПГУ GUID документа с отличием:',
        label: document.epguWithHonorsDocumentGuid
          ? document.epguWithHonorsDocumentGuid
          : '-',
      }
    );
  }, [application, document]);

  if (!questionnaire || !application) {
    return <Loader />;
  }

  return (
    <div className="moderator-education-page">
      {!document && (
        <b>
          Не удалось найти документ об образовании, указанный во время
          заполнения заявления. Выберите другой из списка ниже:
        </b>
      )}
      {list && <ModeratorDataList list={list} />}
      <CheckListEntryValueSelectWithRoleChecker
        getValue={checkList =>
          checkList.unrelatedCheckMarks['educational-background']
        }
        updateValue={newValue => ({
          unrelatedCheckMarks: {
            'educational-background': newValue,
          },
        })}
      />
    </div>
  );
};

export default ModeratorEducationPage;
