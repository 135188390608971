import React, { forwardRef, PropsWithChildren } from 'react';

import classnames from 'classnames';

type RadioButtonProps = PropsWithChildren<{
  className?: string;
}>;

const RadioButton = ({ children, className }: RadioButtonProps) => (
  <div className={classnames('radio-button', className)}>{children}</div>
);

type RadioButtonRadioProps = React.InputHTMLAttributes<HTMLInputElement> & {
  text?: string;
};

RadioButton.Radio = forwardRef(
  (
    { text, ...rest }: RadioButtonRadioProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <label className="radio-button__label">
        <input
          ref={ref}
          {...rest}
          type="radio"
          className="radio-button__input"
        />
        {text && <p className="radio-button__text">{text}</p>}
      </label>
    );
  }
);

export default RadioButton;
