import React, { FC, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import FillingStatementsForm from 'components/statements/FillingStatementsForm';
import { useDownloadFile } from 'hooks/common/api/useDownloadFile';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import { useEnrolleeUserId } from 'hooks/questionnaire/common/draft/useQuestionnaire';
import useFillingStatementsRoutesContext from 'hooks/statements/useFillingStatementsRoutesContext';
import terms from 'i18n';
import { PrivilegeGroupingType } from 'models/applications/privileges';
import { showModal } from 'redux/actions';
import { privilegesApi } from 'redux/api/applications/privileges-api';
import { useLazyPreviewFilesQuery } from 'redux/api/common/files-api';

import { selectIsApplicationReadonly } from '../../../redux/selectors/enrollee/application';
import { useIsImpersonate } from '../../../utils/domain/impersonate';
import { PrivilegesTableWrapper } from '../PrivilegesPage/ui/PrivilegesTableWrapper';

const PriorityRightsPage: FC = () => {
  const dispatch = useAppDispatch();
  const { applicationId } = useParams();

  const isImpersonate = useIsImpersonate();
  const userId = useEnrolleeUserId();
  const readOnly =
    useAppSelector(selectIsApplicationReadonly) && !isImpersonate;

  const { onNextPageNavigate } = useFillingStatementsRoutesContext();

  const [deletePriority] = privilegesApi.useDeletePrivilegeMutation();

  const onDeleteButtonClick = (id: number) => deletePriority(id);

  const { data: formData, isSuccess } = privilegesApi.useGetPrivilegeFormQuery(
    Number(applicationId),
    { refetchOnMountOrArgChange: true }
  );

  const priorityRights = useMemo(() => {
    return formData?.privileges.PRIORITY_RIGHT;
  }, [formData]);

  const [previewFiles] = useLazyPreviewFilesQuery();
  const download = useDownloadFile();

  const onDownloadDocumentsClick = (documentId: number) => {
    if (!userId) return;
    previewFiles({ userId, ids: [documentId] })
      .unwrap()
      .then(files => {
        if (!files[0]) return;
        download({
          userId,
          fileId: documentId,
          fileName: files[0].originalName,
        });
      });
  };

  const onOpenModalClick = (priorityRight?) =>
    dispatch(
      showModal({
        name: 'PRIVILEGES',
        data: {
          applicationId,
          type: PrivilegeGroupingType.PRIORITY_RIGHT,
          privilege: priorityRight,
          userId,
        },
      })
    );

  return (
    <FillingStatementsForm
      onSubmit={e => {
        e.preventDefault();
        onNextPageNavigate();
      }}
    >
      <div className="privileges-page">
        <PrivilegesTableWrapper
          title={terms.PRIORITY_RIGHTS}
          onAddButtonClick={() => onOpenModalClick()}
          isAddButtonDisabled={
            priorityRights ? priorityRights.length >= 1 : !isSuccess
          }
          head={{
            typeLgota: terms.PRIORITY_RIGHT_TYPE,
            typeDoc: terms.CONFIRMATION_DOCUMENT,
            number: terms.SERIES_AND_NUMBER,
            dateIssue: terms.DATE_ISSUE,
          }}
          privileges={priorityRights ?? []}
          readOnly={readOnly}
          onDelete={onDeleteButtonClick}
          onEdit={onOpenModalClick}
          onDownload={onDownloadDocumentsClick}
        />
      </div>
    </FillingStatementsForm>
  );
};

export default PriorityRightsPage;
