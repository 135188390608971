import { createToast } from '../../redux/actions';
import { authApi } from '../../redux/api/common/auth-api';
import { userManagementApi } from '../../redux/api/staff/admin/user-management-api';
import { savePreviousLocation } from '../../utils/domain/impersonate';
import useAppDispatch from '../common/useAppDispatch';

const useAdminUserListElement = () => {
  const dispatch = useAppDispatch();

  const [archive] = userManagementApi.useArchiveUserMutation();
  const [block] = userManagementApi.useBlockUserMutation();
  const [unblock] = userManagementApi.useUnblockUserMutation();
  const [resetPassword] = authApi.useRequestResetPasswordMutation();
  const [unarchive] = userManagementApi.useUnarchiveUserMutation();
  const [activate] = userManagementApi.useActivateUserMutation();

  // const { getAllData } = useUserListWithFilters();

  const blockUser = (id: number) => {
    block(id);
  };

  const unblockUser = (id: number) => {
    unblock(id);
  };

  const archiveUser = (id: number) => {
    archive(id);
  };

  const restoreUser = (id: number) => {
    unarchive(id);
  };

  const activateUser = (userId: number) => {
    activate({ userId });
  };

  const requestPasswordReset = (username: string, email: string) => {
    resetPassword({
      username,
      email,
    })
      .unwrap()
      .then(() => dispatch(createToast('Запрос успешно отправлен', 'success')));
  };

  const onQuestionnaireImpersonate = () => {
    savePreviousLocation();
  };

  return {
    blockUser,
    unblockUser,
    archiveUser,
    requestPasswordReset,
    restoreUser,
    activateUser,
    onQuestionnaireImpersonate,
  };
};

export default useAdminUserListElement;
