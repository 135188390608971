import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { enumApi } from 'redux/api/common/enum-api';

import { apiSlice } from './api/api-slice';
import { authApi } from './api/common/auth-api';
import auth from './slices/common/auth-slice';
import chat from './slices/common/chat-slice';
import enrollList from './slices/common/enroll-lists-slice';
import modal from './slices/common/modal-slice';
import toast from './slices/common/toast-slice';
import user from './slices/common/user-slice';
import application from './slices/enrollee/application-slice';
import questionnaire from './slices/enrollee/questionnaire-slice';
import profile from './slices/profile-slice';
import adminAuditSlice from './slices/staff/adminAuditSlice';
import adminUploadQueueSlice from './slices/staff/adminUploadQueueSlice';
import moderatorApplications from './slices/staff/staffApplicationsSlice';
import staffContractSlice from './slices/staff/staffContractsSlice';
import supportQuestionnaires from './slices/staff/supportQuestionnaireSlice';
import userManagementSlice from './slices/staff/userManagementSlice';
import statements from './slices/statements-slice';

const combinedReducer = combineReducers({
  chat,
  auth,
  user,
  modal,
  toast,
  profile,
  statements,
  enrollList,
  application,
  questionnaire,
  adminAuditSlice,
  adminUploadQueueSlice,
  staffContractSlice,
  userManagementSlice,
  supportQuestionnaires,
  moderatorApplications,
  [enumApi.reducerPath]: enumApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const reducer = (state, action) =>
  combinedReducer(action.type === 'store/reset' ? undefined : state, action);

const store = configureStore({
  reducer,
  middleware: gDM =>
    gDM({ serializableCheck: false })
      .concat(apiSlice.middleware)
      .concat(authApi.middleware)
      .concat(enumApi.middleware),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
