import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';

import classNames from 'classnames';

import {
  getAllSubjects,
  getUniqueSubjects,
} from 'components/statements/DirectionsTrainingList/DirectionsTrainingElementStatement/view';
import {
  Direction,
  DirectionEntranceTestPriority,
} from 'models/applications/directions';
import { SubjectResponse } from 'models/applications/school-tests';
import {
  AdmissionListEntity,
  AdmissionListSubjectResponse,
  RatingEntityState,
  RatingListEntityResponse,
} from 'models/students';
import 'pages/Enrollment/common/enrollListsTable.scss';
import {
  commonColumnKeysToRenderers,
  renderDefault,
} from 'pages/Enrollment/common/column-renderers';

export type EnrollmentTableProps = {
  enrollments: any[];
  direction: Direction;
  orderedColumnKeys: string[];
  columnKeysToRenderers?: {
    [key in keyof (AdmissionListEntity | RatingListEntityResponse) | string]: (
      entity
    ) => ReactNode;
  };
  columnKeysToLabels: { string?: string };
  isRowHighlighted?: (enrollment: any) => boolean;
  priorities: DirectionEntranceTestPriority[];
};

export const ratingEntityStatesToStrings: {
  [key in RatingEntityState]: string;
} = {
  [RatingEntityState.PARTICIPATION]: 'Участвует в конкурсе',
  [RatingEntityState.FOR_ENROLLMENT]: 'К зачислению',
  [RatingEntityState.FOR_ENROLLMENT_HIGHER]:
    'К зачислению по более высокому приоритету',
  [RatingEntityState.FOR_ENROLLMENT_LOWER]:
    'К зачислению по более низкому приоритету',
  [RatingEntityState.ENROLLED]: 'Зачислен',
  [RatingEntityState.ENROLLED_ANOTHER]: 'Зачислен по другому приоритету',
  [RatingEntityState.RESERVED]: 'В резерве к зачислению',
};

export enum DocTypeFilter {
  ALL = 'ALL',
  ORIGINAL = 'ORIGINAL',
  COPY = 'COPY',
}

const getScoresStringForReplaceableSubjects = (
  replaceableSubjectsGroup: SubjectResponse[],
  admissionSubjects: AdmissionListSubjectResponse[]
) => {
  const replaceableSubjectsIdsToAdmissionScores =
    replaceableSubjectsGroup.reduce(
      (result, subject) => ({
        ...result,
        [subject.externalId]: admissionSubjects.find(
          admissionSubject => admissionSubject.externalId === subject.externalId
        )?.score,
      }),
      {}
    );
  const scores: (number | undefined)[] = Object.values(
    replaceableSubjectsIdsToAdmissionScores
  );
  const definedScores = scores.filter(score => score !== undefined);

  if (definedScores.length <= 1) return definedScores[0]?.toString() ?? '-';

  return scores.map(
    (score, index) =>
      (score !== undefined ? score : '-') +
      (index === scores.length - 1 ? '' : ' / ')
  );
};

enum NeedDormitoryOption {
  ALL = 'ALL',
  YES = 'YES',
  NO = 'NO',
}

export const EnrollmentTable = ({
  enrollments,
  direction,
  isRowHighlighted,
  orderedColumnKeys,
  columnKeysToRenderers: columnKeysToRenderersProp,
  columnKeysToLabels,
  priorities,
}: EnrollmentTableProps): ReactElement => {
  const [snilsSearch, setSnilsSearch] = useState('');
  const [docTypeFilter, setDocTypeFilter] = useState(DocTypeFilter.ALL);
  const [needDormitoryFilter, setNeedDormitoryFilter] = useState(
    NeedDormitoryOption.ALL
  );
  const [stateFilter, setStateFilter] = useState<RatingEntityState | null>(
    null
  );
  const [
    isEveryFilteringColumnHighlighted,
    setEveryFilteringColumnHighlighted,
  ] = useState(false);

  const [filteredEnrollments, setFilteredEnrollments] =
    useState<any[]>(enrollments);

  useEffect(() => {
    setFilteredEnrollments(enrollments);
  }, [enrollments]);

  const filterEnrollments = (
    search: string,
    docTypeFilter: string,
    stateFilter: RatingEntityState | null,
    needDormitoryFilter: NeedDormitoryOption
  ) => {
    const normalizedSearch = search.trim().toLowerCase();
    setFilteredEnrollments(
      enrollments.filter(admission => {
        return (
          (admission.userSnils?.includes(normalizedSearch) ||
            admission.userUniqueId?.includes(normalizedSearch) ||
            admission.userIdWithPrefix?.includes(normalizedSearch) ||
            admission.userFullName?.toLowerCase().includes(normalizedSearch)) &&
          (docTypeFilter === DocTypeFilter.ALL
            ? true
            : docTypeFilter === DocTypeFilter.ORIGINAL
            ? admission.hasOriginal
            : !admission.hasOriginal) &&
          (stateFilter === null
            ? true
            : 'state' in admission && admission['state'] === stateFilter) &&
          (needDormitoryFilter === NeedDormitoryOption.ALL
            ? true
            : admission.needDormitory ===
              (needDormitoryFilter === NeedDormitoryOption.YES))
        );
      })
    );
    setEveryFilteringColumnHighlighted(true);
  };

  const handleInputKeyDown = e => {
    if (e.key !== 'Enter') return;
    filterEnrollments(
      snilsSearch,
      docTypeFilter,
      stateFilter,
      needDormitoryFilter
    );
  };

  const allSubjects = useMemo(
    () => getAllSubjects(direction.entranceTestsSets, direction),
    [direction.entranceTestsSets]
  );

  const subjectIdsToPriorities: { [key: number]: number } = useMemo(
    () =>
      priorities.reduce((result, priority) => {
        result[priority.subjectId] = priority.priotity;
        return result;
      }, {}),
    [priorities]
  );

  const uniqueSubjects = useMemo(
    () =>
      getUniqueSubjects(direction.entranceTestsSets, direction).sort(
        (subj1, subj2) => {
          const priority1 = subjectIdsToPriorities[subj1.id];
          const priority2 = subjectIdsToPriorities[subj2.id];
          if (!priority1) return 1;
          if (!priority2) return -1;
          return priority1 - priority2;
        }
      ),
    [direction.entranceTestsSets, subjectIdsToPriorities]
  );

  const replaceableSubjectsGroups = useMemo(() => {
    return uniqueSubjects.map(subject => {
      const replaceableSubjects: SubjectResponse[] = [subject];
      subject.replacements.forEach(replacementSubject => {
        const replacement = allSubjects.find(
          subject => subject.id === replacementSubject.replacement
        );
        if (replacement) replaceableSubjects.push(replacement);
      });
      return replaceableSubjects;
    });
  }, [allSubjects, uniqueSubjects]);

  const columnKeysToRenderers = columnKeysToRenderersProp
    ? { ...commonColumnKeysToRenderers, ...columnKeysToRenderersProp }
    : commonColumnKeysToRenderers;

  return (
    <div className="enroll-lists-table">
      <table className="enroll-lists-table__table">
        <thead>
          <tr>
            <th className="enroll-lists-table__cell enroll-lists-table__cell--head">
              №
            </th>
            {orderedColumnKeys.map(columnKey =>
              columnKey === 'subjects' ? (
                replaceableSubjectsGroups.map(
                  (replaceableSubjectsGroup, index) => (
                    <th
                      key={index}
                      className="enroll-lists-table__cell enroll-lists-table__cell--head"
                    >
                      {replaceableSubjectsGroup.map(
                        (subject, index) =>
                          subject.title +
                          (index === replaceableSubjectsGroup.length - 1
                            ? ''
                            : ' / ')
                      )}
                    </th>
                  )
                )
              ) : (
                <th
                  key={columnKey.toString()}
                  className={classNames(
                    'enroll-lists-table__cell enroll-lists-table__cell--head',
                    {
                      'enroll-lists-table__cell--highlighted':
                        (columnKey === 'userUniqueId' &&
                          isEveryFilteringColumnHighlighted &&
                          snilsSearch !== '') ||
                        (columnKey === 'hasOriginalDocuments' &&
                          isEveryFilteringColumnHighlighted &&
                          docTypeFilter !== DocTypeFilter.ALL),
                    }
                  )}
                  data-tablefilter-filtertype="input"
                >
                  {columnKeysToLabels[columnKey]}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          <tr className="enroll-lists-table__row">
            <td className="enroll-lists-table__cell" />
            {orderedColumnKeys.map(columnKey =>
              columnKey === 'subjects' ? (
                replaceableSubjectsGroups.map((_, index) => (
                  <td key={index} className="enroll-lists-table__cell" />
                ))
              ) : (
                <td
                  key={columnKey.toString()}
                  className="enroll-lists-table__cell"
                >
                  {(columnKey === 'userIdOrUniqueId' ||
                    columnKey === 'userFullNameOrIdOrUniqueId' ||
                    columnKey === 'userSnilsOrIdWithPrefix') && (
                    <input
                      className="enroll-lists-table__input-search"
                      type="text"
                      onKeyDown={handleInputKeyDown}
                      value={snilsSearch}
                      onChange={e => {
                        if (e.target.value === '')
                          setEveryFilteringColumnHighlighted(false);
                        setSnilsSearch(e.target.value);
                      }}
                    />
                  )}
                  {columnKey === 'hasOriginalDocuments' ||
                    (columnKey === 'hasOriginal' && (
                      <select
                        style={{ width: '100%' }}
                        className="enroll-lists-table__input-search"
                        id="flt10_tf_70ac9949-7ea9-7598-49bf-4b343ef744ea"
                        value={docTypeFilter}
                        onChange={e => {
                          setDocTypeFilter(e.target.value as DocTypeFilter);
                          filterEnrollments(
                            snilsSearch,
                            e.target.value as DocTypeFilter,
                            stateFilter,
                            needDormitoryFilter
                          );
                        }}
                      >
                        <option value={DocTypeFilter.ALL}>Все</option>
                        <option value={DocTypeFilter.COPY}>Копия</option>
                        <option value={DocTypeFilter.ORIGINAL}>Оригинал</option>
                      </select>
                    ))}
                  {columnKey === 'needDormitory' && (
                    <select
                      style={{ width: '100%' }}
                      className="enroll-lists-table__input-search"
                      id="flt10_tf_70ac9949-7ea9-7598-49bf-4b343ef744ea"
                      value={needDormitoryFilter}
                      onChange={e => {
                        setNeedDormitoryFilter(
                          e.target.value as NeedDormitoryOption
                        );
                        filterEnrollments(
                          snilsSearch,
                          docTypeFilter,
                          stateFilter,
                          e.target.value as NeedDormitoryOption
                        );
                      }}
                    >
                      <option value={NeedDormitoryOption.ALL}>Все</option>
                      <option value={NeedDormitoryOption.YES}>Да</option>
                      <option value={NeedDormitoryOption.NO}>Нет</option>
                    </select>
                  )}
                  {columnKey === 'state' && (
                    <select
                      style={{ width: '100%' }}
                      className="enroll-lists-table__input-search"
                      id="flt10_tf_70ac9949-7ea9-7598-49bf-4b343ef744ea"
                      value={stateFilter ?? ''}
                      onChange={e => {
                        setStateFilter(
                          e.target.value === ''
                            ? null
                            : (e.target.value as RatingEntityState)
                        );
                        filterEnrollments(
                          snilsSearch,
                          docTypeFilter,
                          e.target.value === ''
                            ? null
                            : (e.target.value as RatingEntityState),
                          needDormitoryFilter
                        );
                      }}
                    >
                      <option value="">Все</option>
                      {Object.entries(RatingEntityState).map(([key, value]) => (
                        <option key={key} value={value}>
                          {ratingEntityStatesToStrings[key]}
                        </option>
                      ))}
                    </select>
                  )}
                </td>
              )
            )}
          </tr>
          {filteredEnrollments.map((enrollment, index) => (
            <tr
              className={classNames('enroll-lists-table__row', {
                'enroll-lists-table__row--highlighted':
                  isRowHighlighted?.(enrollment),
              })}
              key={index}
            >
              <td className="enroll-lists-table__cell">{index + 1}</td>
              {orderedColumnKeys.map(columnKey =>
                columnKey === 'subjects' ? (
                  replaceableSubjectsGroups.map(subjectsGroup => (
                    <td className="enroll-lists-table__cell">
                      {getScoresStringForReplaceableSubjects(
                        subjectsGroup,
                        enrollment.subjects
                      )}
                    </td>
                  ))
                ) : (
                  <td
                    key={columnKey.toString()}
                    className="enroll-lists-table__cell"
                  >
                    {columnKeysToRenderers?.[columnKey.toString()]?.(
                      enrollment
                    ) ?? renderDefault(enrollment[columnKey])}
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
