import { useForm } from 'react-hook-form';

import useAppDispatch from 'hooks/common/useAppDispatch';
import { LegacyEnrollListFormData } from 'models/students';

import { enrollListsSlice } from '../../redux/slices/common/enroll-lists-slice';

const useEnrollListPage = () => {
  const { register, handleSubmit, control, watch, setValue, ...rest } =
    useForm<LegacyEnrollListFormData>({ mode: 'all' });
  const dispatch = useAppDispatch();

  const onSubmit = handleSubmit(data => {
    dispatch(
      enrollListsSlice.actions.setPaymentFormId(data.paymentForm?.value)
    );
    dispatch(
      enrollListsSlice.actions.setEducationFormId(data.educationForm?.value)
    );
    dispatch(enrollListsSlice.actions.setDirectionId(data.direction?.value));
  });

  const clearCurrentDirection = () => {
    setValue('direction', undefined);
    dispatch(enrollListsSlice.actions.setDirectionId(undefined));
  };

  const fields = {
    educationLevel: register('educationLevel', {
      onChange: () => {
        clearCurrentDirection();
        onSubmit();
      },
    }),
    educationForm: register('educationForm', {
      onChange: () => {
        clearCurrentDirection();
        onSubmit();
      },
    }),
    paymentForm: register('paymentForm', {
      onChange: () => {
        clearCurrentDirection();
        onSubmit();
      },
    }),
    direction: register('direction', {
      onChange: () => {
        onSubmit();
      },
    }),
  };

  return { fields, control, watch, onSubmit, ...rest };
};

export default useEnrollListPage;
