import React, { FC } from 'react';

import { Controller } from 'react-hook-form';

import useAppSelector from '../../../../hooks/common/useAppSelector';
import useModeratorCreateExpertExaminationForm from '../../../../hooks/moderator/useModeratorCreateExpertExaminationForm';
import { modalDataSelector } from '../../../../redux/selectors';
import Button from '../../ui-kit/Button';
import Checkbox from '../../ui-kit/Checkbox';
import TemplateModal from '../TemplateModal';

const CreateExpertExaminationModal: FC = () => {
  const { applicationId, postSubmit } = useAppSelector(modalDataSelector);

  const {
    control,
    fields,
    onSubmit,
    loading,
    formState: { errors },
  } = useModeratorCreateExpertExaminationForm(applicationId, postSubmit);

  return (
    <TemplateModal title="Направление на предварительную экспертизу">
      <form onSubmit={onSubmit}>
        <div className="profile-form__fields">
          <Controller
            name={fields.hasIdentificationDocument.name}
            control={control}
            render={({ field }) => (
              <Checkbox
                text="Копия страниц документа, удостоверяющего личность, с фамилией, именами, датой рождения (с заверенным в установленном порядке переводом на русский язык, при наличии)"
                className="profile-form__field"
                {...(field as object)}
              />
            )}
          />

          <Controller
            name={fields.hasEducationalDocument.name}
            control={control}
            render={({ field }) => (
              <Checkbox
                text="Копия основного документа об образовании"
                className="profile-form__field"
                {...(field as object)}
              />
            )}
          />

          <Controller
            name={fields.hasEducationGraduationDocuments.name}
            control={control}
            render={({ field }) => (
              <Checkbox
                text="Копия документа об окончании программы подготовки, соответственно, бакалавров или магистров"
                className="profile-form__field"
                {...(field as object)}
              />
            )}
          />

          <Controller
            name={fields.hasEducationDiploma.name}
            control={control}
            render={({ field }) => (
              <Checkbox
                text="Копия диплома о присвоении степени, соответственно, бакалавра или магистра"
                className="profile-form__field"
                {...(field as object)}
              />
            )}
          />

          <Controller
            name={fields.hasEducationalDocumentApplication.name}
            control={control}
            render={({ field }) => (
              <Checkbox
                text="Копия(и) приложения(ий) к основному документу об образовании за все годы обучения для получения основного документа с указанием предметов и отметок"
                className="profile-form__field"
                {...(field as object)}
              />
            )}
          />

          <Controller
            name={fields.hasEducationalDocumentsTranslation.name}
            control={control}
            render={({ field }) => (
              <Checkbox
                text="Перевод основного документа и приложения(ий) к нему"
                className="profile-form__field"
                {...(field as object)}
              />
            )}
          />

          <Button
            theme="success"
            loading={loading}
            className="profile-form__button profile-form__button--align-left"
          >
            Сформировать направление на предварительную экспертизу
          </Button>
        </div>
      </form>
    </TemplateModal>
  );
};

export default CreateExpertExaminationModal;
