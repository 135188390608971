import React, { forwardRef } from 'react';

import classNames from 'classnames';

import Loader from 'components/common/ui-kit/Loader';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  theme?: 'success' | 'danger';
  size?: 'micro' | 'small';
  loading?: boolean;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ theme, loading = false, size, ...rest }, ref) => (
    <button
      {...rest}
      ref={ref}
      disabled={loading || rest.disabled}
      className={classNames('button', rest.className, {
        [`button--theme-${theme}`]: Boolean(theme),
        [`button--size-${size}`]: Boolean(size),
      })}
    >
      {loading ? <Loader className="btn-loader" /> : rest.children}
    </button>
  )
);

export default Button;
