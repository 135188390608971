import React, { FC, useEffect } from 'react';

import TemplateModal from 'components/common/modals/TemplateModal';
import Button from 'components/common/ui-kit/Button';
import ProfileSection from 'components/profile/ProfileSection';
import useAppSelector from 'hooks/common/useAppSelector';
import useAdditionalFileUploading from 'hooks/document/useAdditionalFIleUploading';
import terms from 'i18n';
import { modalDataSelector } from 'redux/selectors/common/modal';

import Input from '../../ui-kit/Input';
import LazyFileUploader from '../../ui-kit/LazyFileUploader';

const AddFileModal: FC = () => {
  const data = useAppSelector(modalDataSelector);

  const { uploaderProps, inputProps, onSave, fileError } =
    useAdditionalFileUploading(
      data?.name,
      data?.fileId,
      data?.userId,
      data?.date,
      data?.refetchFileIds
    );

  useEffect(() => {
    if (data?.name) inputProps.value = data?.name;
  }, [data]);

  return (
    <TemplateModal title={data?.name ? terms.EDIT_FILE : terms.ADD_FILE}>
      <Input
        className="add-file-modal__input"
        placeholder={terms.DESIGNATION}
        markRequired={true}
        {...inputProps}
      />
      <ProfileSection title={terms.FILE} markRequired={true}>
        <LazyFileUploader
          className="add-file-modal__uploader"
          {...uploaderProps}
        />
        {fileError && (
          <div className="add-file-modal__error"> {fileError} </div>
        )}
      </ProfileSection>
      <Button
        theme="success"
        className="add-file-modal__button"
        onClick={onSave}
      >
        {data?.name ? terms.EDIT : terms.SAVE}
      </Button>
    </TemplateModal>
  );
};

export default AddFileModal;
