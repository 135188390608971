import React, { FC } from 'react';

import { NavLink } from 'react-router-dom';

import { ReactComponent as NotFound } from 'assets/images/not-found.svg';
import Button from 'components/common/ui-kit/Button';

const NotFound404: FC = () => (
  <div className="not-found-page">
    <NotFound className="not-found-page__image" />
    <h1>Страница не найдена</h1>
    <NavLink to="/" className="not-found-page__btn">
      <Button theme="success" className="not-found-page__btn">
        Перейти на главную страницу
      </Button>
    </NavLink>
  </div>
);

export default NotFound404;
