import { useEffect, useState } from 'react';

import terms from '../../i18n';
import { User } from '../../models/enrollee';
import { Questionnaire } from '../../models/questionnaire/questionnaire';
import { createToast } from '../../redux/actions';
import { enrolleesApi } from '../../redux/api/questionnaire/enrollees-api';
import { questionnaireApi } from '../../redux/api/questionnaire/questionnaire-api';
import useAppDispatch from '../common/useAppDispatch';

export const useGetEnrolleeOrQuestionnaireByUserId = (
  id: number | undefined
) => {
  const [questionnaire, setQuestionnaire] = useState<
    (Questionnaire & { user: User }) | undefined
  >();

  const [getEnrollee] = enrolleesApi.useLazyGetByUserIdQuery();
  const [getRawEnrollee] =
    questionnaireApi.useLazyCreateOrGetEmptyQuestionnaireQuery();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      getEnrollee(id)
        .unwrap()
        .then(response => setQuestionnaire(response))
        .catch(() => {
          dispatch(createToast(terms.ERROR_QUESTIONNAIRE_NOT_SAVED, 'danger'));
          getRawEnrollee(id)
            .unwrap()
            .then(response => setQuestionnaire(response));
        });
    }
  }, [id]);

  return questionnaire;
};
