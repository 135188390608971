import { useEffect } from 'react';

import useChatId from 'hooks/chat/useChatId';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import { getChatMessages, resetChatMessages } from 'redux/actions';
import {
  selectChatMessages,
  selectChatOpened,
} from 'redux/selectors/common/chat';

const useChatInitMessages = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(state => state.user.id);
  const chatOpened = useAppSelector(selectChatOpened);
  const chatMessages = useAppSelector(selectChatMessages);
  const chatId = useChatId();

  const refetchMessages = () => {
    if (!chatId || !chatOpened) return;
    dispatch(getChatMessages({ chatId }));
  };

  useEffect(() => {
    refetchMessages();
    return () => {
      dispatch(resetChatMessages());
    };
  }, [userId, chatId]);

  useEffect(() => {
    if (!chatMessages) {
      refetchMessages();
    }
  }, [chatOpened]);

  return refetchMessages;
};

export default useChatInitMessages;
