import { Modify } from 'types/extra';

import { ExternalEntityNamedResponse } from 'models/applications/applications';
import { ApplicationEducationLevel } from 'models/applications/enum';
import { DateTimeString, SelectOption } from 'models/common';

export type StudentsFilterFormData = {
  page?: number;
  perPage?: number;
  competitive?: boolean;
  trajectory?: string | number | null;
  trainingLevel?: number | null;
  formEducation?: number | null;
  formPayment?: number | null;
  faculty?: number | null;
  educationProfile?: number | null;
  directionTraining?: number | null;
  search?: string;
  benefits?: 0 | 1;
  userId?: string;
};

export type StudentsFilterForm = Modify<
  StudentsFilterFormData,
  {
    trajectory: string | number;
    trainingLevel: SelectOption;
    formEducation: SelectOption | null;
    formPayment: SelectOption | null;
    faculty: SelectOption | null;
    educationProfile: SelectOption | null;
    directionTraining: SelectOption | null;
  }
>;

export type LegacyEnrollListFormData = {
  educationForm?: SelectOption;
  paymentForm?: SelectOption;
  educationLevel?: SelectOption;
  direction?: SelectOption;
};

export type EnrollListFormData = {
  educationForm?: SelectOption;
  admissionBasis?: SelectOption;
  educationLevel?: SelectOption;
  direction?: SelectOption;
};

export type AdmissionListSubjectResponse = {
  title: string;
  externalId: string;
  score: number;
};

export type AdmissionListEntity = {
  applicationEducationLevel: ApplicationEducationLevel;
  directionEducationForm: ExternalEntityNamedResponse;
  directionPaymentForm: ExternalEntityNamedResponse;
  subjects: AdmissionListSubjectResponse[];
  userSnils: string;
  userUniqueId: string;
  userExternalId: string;
  priority?: string;
  withoutExam: boolean;
  fullScore: number;
  subjectScore: number;
  hasFeature: boolean;
  hasAgreement: boolean;
  hasOriginalDocuments: boolean;
  achievementScore: number;
  hasOlympiad: boolean;
  certificateAverage: number;
  certificateProfileAverage: number;
  needDormitory: boolean;
  hasSpecialFeature: boolean;
  userFullName?: string;
  hasAchievement: boolean;
};

export enum RatingEntityState {
  PARTICIPATION = 'PARTICIPATION', // Участвует в конкурсе
  FOR_ENROLLMENT = 'FOR_ENROLLMENT', // К зачислению
  FOR_ENROLLMENT_HIGHER = 'FOR_ENROLLMENT_HIGHER', // К зачислению по более высокому приоритету
  FOR_ENROLLMENT_LOWER = 'FOR_ENROLLMENT_LOWER', // К зачислению по более низкому приоритету
  ENROLLED = 'ENROLLED', // Зачислен
  ENROLLED_ANOTHER = 'ENROLLED_ANOTHER', // Зачислен по другому приоритету
  RESERVED = 'RESERVED', // В резерве к зачислению
}

export type RatingListEntityResponse = AdmissionListEntity & {
  achievementScoreExtra: number;
  hasGovernmentContract: boolean;
  state: RatingEntityState | null;
};

export enum EntrantTestType {
  SCHOOL_TEST = 'SCHOOL_TEST', // ЕГЭ
  ENTRANT_TEST = 'ENTRANT_TEST', // Вступительные испытания
  SCHOOL_AND_ENTRANT_TEST = 'SCHOOL_AND_ENTRANT_TEST', // ЕГЭ + ВИ
  ABSENT = 'ABSENT', // Отсутствует
}

export type EACAdmissionListResponse = {
  id: number;
  userIdWithPrefix: string;
  userSnils?: string;
  admissionDate: DateTimeString; // дата подачи заявления
  hasOlympiad: boolean; // наличие олимпиады
  entrantTestType?: EntrantTestType; // тип вступительных испытаний
  hasAchievement: boolean; // наличие индивидуального достижения
  hasFeature: boolean; // наличие преимущественного права
  priority: number; // приоритет
  hasOriginal: boolean; // информация о наличии оригинала или отметки с ЕПГУ
  hasContractOriginal: boolean; // наличие оригинала договора
  applicationDirectionId: number;
  createdAt: DateTimeString;
};

export type EACAdmissionListFormResponse = {
  list: EACAdmissionListResponse[];
  directionCapacity: number;
  total: number;
  totalWithOriginals: number;
  createdDate: DateTimeString;
};
