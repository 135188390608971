import React, { FC, ReactNode, useMemo } from 'react';

import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import Breadcrumbs from 'components/common/ui-kit/Breadcrumbs';
import CenterContainer from 'components/common/ui-kit/CenterContainer';
import Loader from 'components/common/ui-kit/Loader';
import { SidenavRoute } from 'components/common/ui-kit/SidenavV2/view';
import terms from 'i18n';
import { DocumentType } from 'models/applications/application-documents';
import { ContractState } from 'models/applications/contracts';
import { Trajectory } from 'models/applications/enum';
import { DateString } from 'models/common';
import { RelationDegree } from 'models/enum';
import {
  EducationBasis,
  EducationalLevel,
  UploadApplicationDetails,
  UploadDetails,
  UploadEduDocDetails,
  UploadFeaturesDetails,
  UploadOlympiadsDetails,
  UploadOriginalDetails,
  UploadQueueDetailedInfoResponse,
  UploadRepresentativeDetails,
  UploadSchoolTestsDetails,
  UploadUserDetails,
} from 'models/staff/upload-queue';
import { renderDateString } from 'pages/Enrollment/common/column-renderers';
import SidenavPageV2 from 'pages/SidenavPageV2';
import { uploadQueueApi } from 'redux/api/staff/admin/upload-queue-api';

const renderUploadQueueDetailField = (value: ReactNode) => {
  if (typeof value === 'boolean') {
    return value ? terms.YES : terms.NO;
  }
  return value ?? '-';
};

export const uploadDetailsKeysToTitles: Record<
  keyof Omit<
    Required<
      UploadUserDetails &
        UploadRepresentativeDetails &
        UploadEduDocDetails &
        UploadApplicationDetails &
        UploadOriginalDetails &
        UploadSchoolTestsDetails &
        UploadOlympiadsDetails &
        UploadFeaturesDetails
    >,
    'id' | 'externalId'
  >,
  string
> = {
  idWithPrefix: 'ID абитуриента',
  externalId: 'ID абитуриента в 1С',
  uploadError: 'Ошибка выгрузки',
  uploadAt: 'Дата последней выгрузки',
  updatedAt: 'Дата изменения',
  name: 'Имя',
  surname: 'Фамилия',
  patronymic: 'Отчество',
  relationDegree: 'Степень родства',
  birthday: 'Дата рождения',
  mobile: 'Мобильный телефон',
  educationLevel: 'Уровень образования',
  documentType: 'Тип документа',
  educationalInstitution: 'Образовательная организация',
  educationalDocumentNumber: 'Серия и номер документа',
  educationalDocumentIssuedAt: 'Дата выдачи документа',
  trajectory: 'Траектория',
  educationBasis: 'Форма оплаты',
  state: 'Статус',
  deletedAt: 'Дата удаления',
  contractNumber: 'Номер',
  applicationId: 'ID заявления',
  isSubmitted: 'Оригинал подан',
  originalStateChangedAt: 'Дата подачи/отзыва',
  directionName: 'Направление подготовки',
  isAgreed: 'Согласие подано',
};

const renderDate = (value?: DateString) => {
  if (!value) return '-';
  return renderDateString(value);
};

export const uploadDetailsKeysToRenderers: Partial<
  Record<
    keyof UploadUserDetails & UploadRepresentativeDetails,
    (value: any) => ReactNode
  >
> = {
  uploadAt: renderDate,
  updatedAt: renderDate,
  lastUpdateAt: renderDate,
  educationalDocumentIssuedAt: renderDate,
  deletedAt: renderDate,
  originalStateChangedAt: renderDate,
  relationDegree: (value: RelationDegree) => terms[value] ?? '-',
  educationLevel: (value: EducationalLevel) => terms[value] ?? '-',
  educationBasis: (value: EducationBasis) => terms[value] ?? '-',
  trajectory: (value: Trajectory) => terms[value] ?? '-',
  state: (value: ContractState) => terms[value] ?? '-',
  documentType: (value: DocumentType) => terms[value] ?? '-',
};

// TODO заменить на ObjectEntriesView, когда дойдут руки
export const UploadQueueDetailsView: FC<{
  details: UploadDetails;
  className?: string;
  route: { to: string };
}> = ({ details, className, route }) => {
  return (
    <div className={classNames('upload-queue-details-entry', className)}>
      {Object.entries(details).map(([key, value]) => {
        const title =
          key === 'externalId'
            ? route.to === 'applications-details'
              ? 'ID заявления в 1С'
              : uploadDetailsKeysToTitles[key]
            : uploadDetailsKeysToTitles[key];
        if (!title) return;
        const render = uploadDetailsKeysToRenderers[key];
        return (
          <div key={key} className="upload-queue-details-entry__element">
            <p>{title}</p>
            <span>
              {render ? render(value) : renderUploadQueueDetailField(value)}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export const UploadQueueDetailsViewList: FC<{
  detailsList: UploadDetails[];
  className?: string;
  route: { to: string };
}> = ({ detailsList, className, route }) => {
  return (
    <>
      {detailsList.map((details, index) => (
        <UploadQueueDetailsView
          key={index}
          details={details}
          className={className}
          route={route}
        />
      ))}
    </>
  );
};

const uploadQueueEntryKeysToRoutes: Record<
  keyof UploadQueueDetailedInfoResponse,
  {
    title: string;
    to: string;
  }
> = {
  userDetails: {
    to: 'user-details',
    title: 'Анкета',
  },
  representativeDetails: {
    to: 'representative-details',
    title: 'Законный представитель',
  },
  eduDocsDetails: {
    to: 'edu-docs-details',
    title: 'Документ об образовании',
  },
  applicationsDetails: {
    to: 'applications-details',
    title: 'Заявление',
  },
  schoolTestsDetails: {
    to: 'school-tests-details',
    title: 'Результаты ЕГЭ',
  },
  olympiadsDetails: {
    to: 'olympiads-details',
    title: 'Олимпиады',
  },
  featuresDetails: {
    to: 'features-details',
    title: 'Льготы, привилегии, особые права',
  },
  contractsDetails: {
    to: 'contracts-details',
    title: 'Договор',
  },
  originalsDetails: {
    to: 'originals-details',
    title: 'Оригинал документа об образовании',
  },
  agreementsDetails: {
    to: 'agreements-details',
    title: 'Согласие на зачисление',
  },
  jsonToUpload: {
    to: 'json',
    title: 'JSON',
  },
};

const UploadQueueEntryPage = () => {
  const { userId } = useParams();

  const { data: uploadQueueEntry } =
    uploadQueueApi.useGetDetailedUploadInfoByUserQuery(userId!);

  const routes = useMemo<SidenavRoute[]>(() => {
    if (!uploadQueueEntry) return [];
    return Object.entries(uploadQueueEntry)
      .filter(
        ([, value]) =>
          value && (Array.isArray(value) ? value.length !== 0 : true)
      )
      .map(([key, value]) => {
        const route: {
          to: string;
          title: string;
        } = uploadQueueEntryKeysToRoutes[key];
        if (Array.isArray(value)) {
          return {
            ...route,
            element: (
              <UploadQueueDetailsViewList
                className="upload-queue-entry-page__details-entry"
                detailsList={value}
                route={route}
              />
            ),
          };
        }
        if (typeof value === 'string') {
          return {
            ...route,
            element: (
              <div
                style={{ whiteSpace: 'pre' }}
                dangerouslySetInnerHTML={{ __html: value }}
              ></div>
            ),
          };
        }
        return {
          ...route,
          element: (
            <UploadQueueDetailsView
              className="upload-queue-entry-page__details-entry"
              details={value}
              route={route}
            />
          ),
        };
      });
  }, [uploadQueueEntry]);

  if (!uploadQueueEntry) {
    return (
      <CenterContainer>
        <Loader />
      </CenterContainer>
    );
  }

  return (
    <main className="upload-queue-entry-page">
      <Breadcrumbs
        className="upload-queue-entry-page__breadcrumbs"
        title="Выгрузка"
        link="/admin/upload-queue"
      />
      <SidenavPageV2 routes={routes} />
    </main>
  );
};

export default UploadQueueEntryPage;
