import React, { FC } from 'react';

import LazyFileUploader from '../../../../components/common/ui-kit/LazyFileUploader';
import { FileMetaView } from '../../../../hooks/files/useLazyUploadFiles';

type SectionProps = {
  title: string;
  readOnly?: boolean;
  docs: File[];
  onChange: (files: File[]) => void;
  onDelete: (file: FileMetaView) => void;
  onDrop: (files: File[]) => void;
  filesMeta: FileMetaView[];
  uploaderDescription?: string;
  onDownloadFile: (fileMeta: FileMetaView) => void;
};

const Section: FC<SectionProps> = ({
  title,
  readOnly,
  docs,
  onDelete,
  onChange,
  onDrop,
  uploaderDescription,
  filesMeta,
  onDownloadFile,
}) => (
  <section>
    <h3 className="compatriot__title">{title}</h3>
    <LazyFileUploader
      onDownloadFile={onDownloadFile}
      filesMeta={filesMeta}
      disabled={readOnly}
      files={docs}
      onDrop={onDrop}
      onChange={onChange}
      onDelete={onDelete}
      description={uploaderDescription}
    />
  </section>
);

export default Section;
