import React, { FC } from 'react';

import classNames from 'classnames';

import RouteLink from 'components/common/ui-kit/RouteLink';

import { RouteLinkProps } from '../RouteLink/view';

type RouteButtonLinkProps = RouteLinkProps & {
  theme?: 'success';
};

const RouteButtonLink: FC<RouteButtonLinkProps> = ({ theme, ...rest }) => {
  return (
    <RouteLink
      {...rest}
      className={classNames('button', 'route-button-link', rest.className, {
        [`button--theme-${theme}`]: Boolean(theme),
      })}
    />
  );
};

export default RouteButtonLink;
