import React, { FC } from 'react';

import classNames from 'classnames';

import TemplateAuthForm from 'components/auth/TemplateAuthForm';
import Button from 'components/common/ui-kit/Button';
import Input from 'components/common/ui-kit/Input';
import Link from 'components/common/ui-kit/Link';
import RouteButtonLink from 'components/common/ui-kit/RouteButtonLink';
import RouteLink from 'components/common/ui-kit/RouteLink';
import useSignInForm from 'hooks/auth/useSignInForm';
import terms from 'i18n';

import { isRegistrationDisabled } from '../SignUpForm/view';

type SignInFormProps = {
  title: string;
  moderator?: boolean;
};

const SignInForm: FC<SignInFormProps> = ({ title, moderator }) => {
  const {
    fields,
    onSubmit,
    loading,
    lang,
    formState: { errors },
  } = useSignInForm();

  return (
    <TemplateAuthForm title={title}>
      <form onSubmit={onSubmit}>
        {moderator ? (
          <>
            <div className="auth-form__body auth-form__body-moderator">
              <div className="auth-form__policy">
                <Input
                  placeholder={terms.EMAIL}
                  className="auth-form__input"
                  error={errors.email?.message}
                  {...fields.email}
                />
              </div>
              <div className="auth-form__fields">
                <Input
                  placeholder={terms.PASSWORD}
                  enableSwitcher={true}
                  className="auth-form__input"
                  error={errors.password?.message}
                  {...fields.password}
                />
              </div>
            </div>
            <div className="auth-form__buttons">
              <Button
                theme="success"
                className="auth-form__button"
                type="submit"
                disabled={loading}
              >
                {terms.LOG_IN}
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="auth-form__body">
              <div className="auth-form__policy">
                <p className="auth-form__paragraph">
                  {terms.REVIEW_INFORMATION_ABOUT_ADMISSION + ' '}
                  <RouteLink to="/sign-up">
                    {terms.COMPLETE_REGISTRATION_PROCEDURE}
                  </RouteLink>
                </p>
                <p className="auth-form__paragraph">
                  <Link
                    href="/documents/Инструкция по заполнению Личного кабинета абитуриента для граждан РФ и соотечественников.pdf"
                    target="_blank"
                  >
                    {terms.USER_MANUAL_FOR_RUSSIAN_APPLICANTS}
                  </Link>
                </p>
                <p className="auth-form__paragraph">
                  <Link
                    href={
                      lang === 'en'
                        ? '/documents/User manual for foreign applicants (contract-based studies).pdf'
                        : '/documents/Инструкция по заполнению Личного кабинета абитуриента для иностранных граждан (контрактная основа).pdf'
                    }
                    target="_blank"
                  >
                    {terms.USER_MANUAL_FOR_FOREIGNERS}
                  </Link>
                </p>
                <p className="auth-form__paragraph">
                  <Link
                    href={
                      lang === 'en'
                        ? '/documents/User manual for foreign applicants (Russian Governmental scholarship holders and Open Doors Olympiad winners).pdf'
                        : '/documents/Инструкция по заполнению Личного кабинета абитуриента для иностранных граждан (квота Правительства РФ и олимпиада Open Doors).pdf'
                    }
                    target="_blank"
                  >
                    {terms.USER_MANUAL_FOR_FOREIGN_OPEN_DOORS}
                  </Link>
                </p>
                {isRegistrationDisabled && (
                  <>
                    <p className="auth-form__paragraph">
                      {terms.IF_ALREADY_HAVE_ACCOUNT}
                    </p>
                    <p className="auth-form__paragraph">
                      {terms.SIGN_UP_UNAVAILABLE_MESSAGE}
                    </p>
                  </>
                )}
              </div>
              <div className="auth-form__fields">
                <Input
                  placeholder={terms.EMAIL}
                  className="auth-form__input"
                  error={errors.email?.message}
                  {...fields.email}
                />
                <Input
                  placeholder={terms.PASSWORD}
                  enableSwitcher={true}
                  className="auth-form__input"
                  error={errors.password?.message}
                  {...fields.password}
                />
                <RouteLink to="/reset-password" className="auth-form__question">
                  {terms.RESTORE_PASSWORD}
                </RouteLink>
              </div>
            </div>
            <div className="auth-form__buttons">
              <RouteButtonLink
                to={isRegistrationDisabled ? '/sign-in' : '/sign-up'}
                className={classNames('auth-form__button', {
                  'auth-form__button--disabled': isRegistrationDisabled,
                })}
              >
                {terms.REGISTER}
              </RouteButtonLink>
              <Button
                theme="success"
                className="auth-form__button"
                type="submit"
                disabled={loading}
              >
                {terms.LOG_IN}
              </Button>
            </div>
          </>
        )}
      </form>
    </TemplateAuthForm>
  );
};

export default SignInForm;
