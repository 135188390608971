import React, { FC, useId, useRef } from 'react';

import classnames from 'classnames';

import { ApplicationEducationLevel } from '../../../../models/applications/enum';

type LevelListElementProps = {
  name: string;
  disabled?: boolean;
  level: ApplicationEducationLevel;
  active: boolean;
  setActive: any;
};

const LevelListElement: FC<LevelListElementProps> = ({
  name,
  active,
  disabled = false,
  setActive,
  level,
}) => {
  const changeTrajectory = () => setActive(level);
  const levelElementRef = useRef<HTMLDivElement>(null);
  const id = useId();

  return (
    <>
      <div
        className={classnames('level-list__level-element', {
          active,
          'level-list__level-element--disabled': disabled,
        })}
        ref={levelElementRef}
        onClick={!disabled ? changeTrajectory : undefined}
        data-tooltip-id="level-element"
      >
        <h2>{name}</h2>
        {/* {disabled && (
          <FloatingTooltip
            content={<EpguWarning />}
            getElement={() => levelElementRef.current}
          />
        )} */}
      </div>
    </>
  );
};

export default LevelListElement;
