import { useRef } from 'react';

const useDebouncedFunction = (func: (...arg) => void, delay: number) => {
  const ref = useRef<NodeJS.Timeout>();

  return (...args) => {
    clearTimeout(ref.current);
    ref.current = setTimeout(() => func(...args), delay);
  };
};

export default useDebouncedFunction;
