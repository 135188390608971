import React from 'react';

import { Controller } from 'react-hook-form';

import TemplateModal from 'components/common/modals/TemplateModal';
import Button from 'components/common/ui-kit/Button';
import Input from 'components/common/ui-kit/Input';
import ReactSelect from 'components/common/ui-kit/Select';
import ProfileSection from 'components/profile/ProfileSection';
import useAppSelector from 'hooks/common/useAppSelector';
import useAddPrivilegesForm from 'hooks/privilege/useAddPrivilegesForm';
import terms from 'i18n';
import { modalDataSelector } from 'redux/selectors';

import { PrivilegeGroupingType } from '../../../../models/applications/privileges';
import LazyFileUploader from '../../ui-kit/LazyFileUploader';

const privilegeGroupingTypeToModalTitles: {
  [key in PrivilegeGroupingType]?: { add: string; edit: string };
} = {
  [PrivilegeGroupingType.BENEFIT]: {
    add: terms.ADD_PRIVILEGES_TITLE,
    edit: terms.EDIT_PRIVILEGES_TITLE,
  },
  [PrivilegeGroupingType.PREROGATIVE]: {
    add: terms.ADD_PREEMPTIVE_RIGHT_TITLE,
    edit: terms.EDIT_PREEMPTIVE_RIGHT_TITLE,
  },
  [PrivilegeGroupingType.SPECIAL]: {
    add: terms.ADD_INDIVIDUAL_QUOTA_TITLE,
    edit: terms.EDIT_INDIVIDUAL_QUOTA_TITLE,
  },
  [PrivilegeGroupingType.PRIORITY_RIGHT]: {
    add: terms.ADD_PRIORITY_RIGHT,
    edit: terms.EDIT_PRIORITY_RIGHT,
  },
};

const privilegeGroupingTypeLabel: {
  [key in PrivilegeGroupingType]?: string;
} = {
  [PrivilegeGroupingType.BENEFIT]: terms.TYPE_LGOTA,
  [PrivilegeGroupingType.PREROGATIVE]: terms.TYPE_PREEMPTIVE,
  [PrivilegeGroupingType.SPECIAL]: terms.TYPE_INDIVIDUAL_QUOTA,
  [PrivilegeGroupingType.PRIORITY_RIGHT]: terms.PRIORITY_RIGHT_TYPE,
};

const AddPrivilegesModal = () => {
  const { applicationId, privilege, type, userId } =
    useAppSelector(modalDataSelector);

  const {
    control,
    fields,
    onSubmit,
    fileProps,
    privilegeSelectOptions,
    confirmationDocsSelectOptions,
    formState: { errors },
  } = useAddPrivilegesForm(applicationId, privilege, type, userId);

  return (
    <TemplateModal
      title={
        privilege
          ? privilegeGroupingTypeToModalTitles[type].edit
          : privilegeGroupingTypeToModalTitles[type].add
      }
    >
      <form onSubmit={onSubmit}>
        <div className="profile-form__fields">
          <Controller
            name={fields.privilegeType.name}
            control={control}
            render={({ field }) => (
              <ReactSelect
                options={privilegeSelectOptions}
                placeholder={privilegeGroupingTypeLabel[type]}
                className="profile-form__field"
                markRequired={true}
                {...(field as object)}
                error={errors.privilegeType?.message}
              />
            )}
          />

          <Controller
            name={fields.confirmationDocumentType.name}
            control={control}
            render={({ field }) => (
              <ReactSelect
                options={confirmationDocsSelectOptions}
                placeholder={terms.TYPE_DOCUMENT}
                className="profile-form__field"
                markRequired={true}
                {...(field as object)}
                isDisabled={!confirmationDocsSelectOptions.length}
                error={errors.confirmationDocumentType?.message}
              />
            )}
          />

          <Input
            className="profile-form__field"
            placeholder={terms.NUMBER_DOCUMENT}
            markRequired={true}
            {...fields.documentCode}
            error={errors.documentCode?.message}
          />

          <Input
            className="profile-form__field"
            placeholder={terms.DATE_ISSUE}
            type="date"
            markRequired
            {...fields.dateOfIssue}
            error={errors.dateOfIssue?.message}
          />
        </div>

        <ProfileSection title={terms.DOCUMENT} markRequired={true}>
          <LazyFileUploader
            className="profile-form__file-uploader"
            {...fileProps}
          />
          {errors.isDocumentAttached && (
            <div className="profile-form__error">
              {errors.isDocumentAttached?.message}
            </div>
          )}
        </ProfileSection>
        <Button
          theme="success"
          className="profile-form__button profile-form__button--align-left"
        >
          {terms.SAVE}
        </Button>
      </form>
    </TemplateModal>
  );
};

export default AddPrivilegesModal;
