import { isRussianLocale } from 'i18n';

import { useGeneralDataDraft } from './useGeneralDataDraft';

export const useLocalizedFullName = () => {
  const { draft } = useGeneralDataDraft();
  const isRussian = isRussianLocale();

  if (!draft) return { isCorrect: false };

  if (isRussian) {
    if (!draft.name) return { isCorrect: false };
    return {
      isCorrect: true,
      name: draft.name,
      surname: draft.surname,
      patronymic: draft.patronymic,
    };
  }

  if (!draft.nameLatin) return { isCorrect: false };
  return {
    isCorrect: true,
    name: draft.nameLatin,
    surname: draft.surnameLatin,
    patronymic: draft.patronymicLatin,
  };
};
