import React, { FC, useEffect, useState } from 'react';

import { Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import Button from 'components/common/ui-kit/Button';
import ReactSelect from 'components/common/ui-kit/Select';
import ProfileSection from 'components/profile/ProfileSection';
import useDirectionAgreementForm from 'hooks/statements/direction-agreement/useDirectionAgreementForm';
import useFillingStatementsRoutesContext from 'hooks/statements/useFillingStatementsRoutesContext';
import terms, { isRussianLocale } from 'i18n';
import { SelectOption } from 'models/common';

import './SubmitDirectionAgreementForm.scss';
import { useGetEnrollmentDocumentQuery } from '../../../redux/api/applications/application-documents-api';
import { getLocalDateTimeFormattedString } from '../../../utils/date';
import CardForDownloadEnrollmentFile from '../CardForDownloadEnrollmentFile';
import FillingStatementsForm from '../FillingStatementsForm';

const SubmitDirectionAgreementForm: FC<{}> = () => {
  const { applicationId } = useParams();

  const [checkboxDisabled, setCheckboxDisabled] = useState(true);

  const {
    control,
    onSubmit,
    watch,
    getValues,
    fields,
    formState: { errors },
    directionOptions,
    agreement,
  } = useDirectionAgreementForm(Number(applicationId));

  const { data: document, refetch: refetchDocument } =
    useGetEnrollmentDocumentQuery({
      applicationId: Number(applicationId),
      applicationDirectionId: !agreement
        ? getValues('directionId.value')
        : agreement.applicationDirectionId,
    });

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setCheckboxDisabled(true);
      refetchDocument();
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const [directionSelectDisabled, setDirectionSelectDisabled] = useState(
    document?.isSigned || agreement?.isAgreed
  );

  const { onNextPageNavigate } = useFillingStatementsRoutesContext();

  const [isAccepted, setIsAccepted] = useState(false);

  useEffect(() => {
    setIsAccepted(false);
  }, [agreement]);

  const formBody = (
    <>
      <span className="submit-direction-agreement-form__header">
        {terms.AGREEMENT}
      </span>
      <ProfileSection title="">
        <div className="submit-direction-agreement-form__fields">
          <Controller
            name={fields.directionId.name}
            control={control}
            render={({ field }) => (
              <ReactSelect<SelectOption>
                placeholder={terms.DIRECTIONS_TRAINING}
                className="submit-direction-agreement-form__field"
                markRequired={true}
                {...(field as object)}
                options={directionOptions}
                error={errors.directionId?.message}
                isDisabled={agreement?.isAgreed || document?.isSigned}
              />
            )}
          />
        </div>
      </ProfileSection>
      <div className="submit-direction-agreement-form__documents">
        <Button
          className="submit-direction-agreement-form__button"
          theme="success"
          disabled={!isAccepted}
          onClick={e => {
            e.preventDefault();
            onSubmit(refetchDocument)(e);
            setCheckboxDisabled(true);
          }}
        >
          {agreement ? terms.REVOKE_AGREEMENT : terms.SUBMIT_AGREEMENT}
        </Button>
        {agreement && document && (
          <span className="submit-direction-agreement-form__agreement-send-date">
            {terms.AGREEMENT_SEND_DATE_CAPTION}{' '}
            <strong>
              {getLocalDateTimeFormattedString(document.updatedAt)}
            </strong>
          </span>
        )}
        <CardForDownloadEnrollmentFile
          blankId={Number(applicationId)}
          code={'fixme'}
          checkboxDisableFlag={checkboxDisabled}
          title={
            (isRussianLocale() ? document?.title : document?.titleForeign) || ''
          }
          date={document?.createdAt || ''}
          showCard={true}
          acceptBlank={isAccepted}
          refetchDocuments={() => {
            refetchDocument();
            setDirectionSelectDisabled(!directionSelectDisabled);
          }}
          document={document}
          onValueChanged={value => {
            setIsAccepted(value);
          }}
          afterDownload={() => {
            setCheckboxDisabled(false);
          }}
        />
      </div>
    </>
  );

  return (
    <div>
      <FillingStatementsForm
        onSubmit={e => {
          e.preventDefault();
          onNextPageNavigate();
        }}
      >
        {formBody}
      </FillingStatementsForm>
    </div>
  );
};

export default SubmitDirectionAgreementForm;
