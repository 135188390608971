import React, { FC, useEffect, useMemo, useState } from 'react';

import classnames from 'classnames';

import { ReactComponent as CheckedIcon } from 'assets/images/icons/checked.svg';
import { ReactComponent as ConfirmedIcon } from 'assets/images/icons/confirmed.svg';
import { ReactComponent as NotCheckedIcon } from 'assets/images/icons/not_checked.svg';

import useAppDispatch from '../../../hooks/common/useAppDispatch';
import useAppSelector from '../../../hooks/common/useAppSelector';
import terms from '../../../i18n';
import { ApplicationCheckList } from '../../../models/applications/applications';
import {
  ApplicationCheckStatus,
  ApplicationState,
} from '../../../models/applications/enum';
import { createToast } from '../../../redux/actions';
import { apiSlice } from '../../../redux/api/api-slice';
import { staffApplicationsApi } from '../../../redux/api/staff/staff-applications-api';
import { selectCurrentApplication } from '../../../redux/selectors/staff/applications';
import { DeepPartial } from '../../../types/custom';
import SelectOneButton from '../../common/ui-kit/SelectOneButton';

export type CheckListEntryValueSelectProps = {
  getValue: (
    checkList: ApplicationCheckList
  ) => ApplicationCheckStatus | undefined;
  updateValue: (
    newValue: ApplicationCheckStatus
  ) => DeepPartial<ApplicationCheckList>;
  size?: 'normal' | 'icons';
  disabled?: boolean;
};

const CheckListEntryValueSelect: FC<CheckListEntryValueSelectProps> = ({
  getValue,
  updateValue,
  size = 'normal',
  disabled,
}) => {
  const application = useAppSelector(selectCurrentApplication);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<ApplicationCheckStatus | undefined>(
    undefined
  );

  const { data: checklist } =
    staffApplicationsApi.useGetApplicationChecklistQuery(application?.id ?? 0, {
      skip: application === undefined,
    });

  const inactive = useMemo(() => {
    if (
      !checklist ||
      disabled ||
      !value ||
      application?.state === ApplicationState.ALLOWED
    )
      return true;
  }, [checklist, disabled, value]);

  useEffect(() => {
    try {
      if (!checklist) return;
      const curValue = getValue(checklist);
      setValue(curValue);
      if (curValue === undefined) throw new Error();
    } catch {
      dispatch(createToast(terms.ERROR_CANT_PARSE_CHECKLIST, 'danger'));
    }
  }, [checklist]);

  const [updateApplicationCheckList] =
    staffApplicationsApi.useSetApplicationChecklistMutation();

  const onCheckListEntryValueChanged = newValue => {
    if (!checklist) return;

    setValue(newValue);
    const updatedCheckList = updateValue(newValue);

    updateApplicationCheckList({
      applicationId: application?.id ?? 0,
      request: updatedCheckList,
    })
      .unwrap()
      .then(resp => {
        dispatch(
          apiSlice.util.updateQueryData(
            // eslint-disable-next-line
            // @ts-ignore
            'getApplicationChecklist',
            application?.id ?? 0,
            () => resp
          )
        );
      })
      .catch(() => {
        setValue(value);
        dispatch(createToast(terms.ERROR_CANT_UPDATE_CHECKLIST, 'danger'));
      });
  };

  return (
    <div
      className={classnames(
        'ckecklist-entry-selector-container',
        `ckecklist-entry-selector--size-${size}`
      )}
    >
      {size === 'normal' && (
        <span style={{ fontWeight: '600' }}>
          Статус проверки заполнения заявления:{' '}
        </span>
      )}
      <SelectOneButton
        className="ckecklist-entry-selector"
        size={size}
        options={[
          {
            value: ApplicationCheckStatus.NOT_CHECKED,
            label: 'Не проверено',
            icon: <NotCheckedIcon />,
            className: 'not-checked-option',
          },
          {
            value: ApplicationCheckStatus.CHECKED,
            label: 'Проверено',
            icon: <CheckedIcon />,
            className: 'checked-option',
          },
          {
            value: ApplicationCheckStatus.CONFIRMED,
            label: 'Подтверждено',
            icon: <ConfirmedIcon />,
            className: 'confirmed-option',
          },
        ]}
        value={value}
        disabled={inactive}
        onChange={onCheckListEntryValueChanged}
      />
    </div>
  );
};

export default CheckListEntryValueSelect;
