import { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import useFillingStatementsRoutesContext from 'hooks/statements/useFillingStatementsRoutesContext';
import terms from 'i18n';

import { GovernmentContractRequest } from '../../../models/applications/government-contracts';
import { SelectOption } from '../../../models/common';
import { governmentContractsApi } from '../../../redux/api/applications/government-contracts-api';
import { Modify } from '../../../types/extra';
import { useLazyUploadFiles } from '../../files/useLazyUploadFiles';

type GovernmentContractForm = Modify<
  GovernmentContractRequest,
  {
    readonly hasNotContract: boolean;

    readonly scholarship?: SelectOption;
    readonly travelCompensation?: SelectOption;
    readonly educationCompensation?: SelectOption;
    readonly accommodationCompensation?: SelectOption;

    readonly customerRegion?: SelectOption;
    readonly employerRegion?: SelectOption;

    readonly wasDocumentAttached?: boolean;
    readonly documentId?: number;
    // readonly workAddress: AutocompleteOption<DadataSuggestion> | string;
  }
>;

const useGovernmentContractForm = () => {
  const { applicationId } = useParams();
  const { onNextPageNavigate } = useFillingStatementsRoutesContext();

  const { data: contracts } =
    governmentContractsApi.useGetGovernmentContractsByApplicationIdQuery(
      Number(applicationId),
      { refetchOnMountOrArgChange: true }
    );

  const [deleteContract] =
    governmentContractsApi.useDeleteGovernmentContractMutation();
  const [createContract] =
    governmentContractsApi.useCreateGovernmentContractMutation();
  const [updateContract] =
    governmentContractsApi.useUpdateGovernmentContractMutation();

  const {
    register,
    clearErrors,
    watch,
    control,
    handleSubmit,
    setValue,
    reset,
    ...rest
  } = useForm<GovernmentContractForm>({ mode: 'all' });

  const fileProps = useLazyUploadFiles({
    filename: 'Contract',
    initialFileIds: watch('documentId'),
  });

  useEffect(() => {
    if (contracts) {
      if (contracts.length) {
        const contract = contracts[0];

        reset({
          ...contract,
          hasNotContract: false,
          wasDocumentAttached: !!contract.documentId,
        });
      } else {
        reset({
          hasNotContract: true,
        });
      }
    }
  }, [contracts]);

  useEffect(() => {
    if (fileProps.files.length) {
      setValue('wasDocumentAttached', true);
      clearErrors('wasDocumentAttached');
    } else {
      setValue('wasDocumentAttached', false);
    }
  }, [fileProps.files.length]);

  const hasNotContract = watch('hasNotContract');

  const fields = {
    hasNotContract: register('hasNotContract', {
      onChange: ({ target: { checked } }) => {
        if (checked) {
          reset();
          clearErrors();
          setValue('hasNotContract', true);
        }
      },
    }),

    organization: register('organization', {
      required: {
        value: !hasNotContract,
        message: terms.REQUIRED_FIELD,
      },
    }),
    offerNumber: register('offerNumber', {
      minLength: {
        value: 2,
        message: terms.TWO_CHARACTERS_MIN,
      },
      required: {
        value: !hasNotContract,
        message: terms.REQUIRED_FIELD,
      },
    }),
    dateOfIssue: register('dateOfIssue', {
      required: {
        value: !hasNotContract,
        message: terms.REQUIRED_FIELD,
      },
    }),

    documentId: register('documentId'),
    wasDocumentAttached: register('wasDocumentAttached', {
      required: {
        value: !hasNotContract,
        message: terms.REQUIRED_FIELD,
      },
    }),
  };

  const onSubmit = handleSubmit(data => {
    if (contracts === undefined) return;

    if (hasNotContract && contracts.length === 0) {
      onNextPageNavigate();
      return;
    }

    if (hasNotContract && contracts.length === 1) {
      const contract = contracts[0];
      deleteContract(contract.id)
        .unwrap()
        .then(() => onNextPageNavigate());
    }

    if (!hasNotContract) {
      fileProps
        .uploadFiles()
        .then(fileId => setValue('documentId', fileId))
        .then(() => {
          const documentId = watch('documentId');
          // const workAddress =
          //   typeof data.workAddress === 'string'
          //     ? data.workAddress
          //     : data.workAddress.label;
          if (contracts.length === 0 && documentId) {
            return createContract({
              ...data,
              applicationId: Number(applicationId),
              documentId,
            }).unwrap;
          } else if (documentId) {
            const contract = contracts[0];
            return updateContract({
              ...data,
              id: contract.id,
              documentId,
            }).unwrap;
          }
        })
        .then(() => onNextPageNavigate());
    }
  });

  return {
    hasNotContract,
    control,
    fields,
    clearErrors,
    onSubmit,
    setValue,
    fileProps,
    ...rest,
  };
};

export default useGovernmentContractForm;
