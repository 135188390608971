import React, { FC } from 'react';

import { Controller } from 'react-hook-form';

import RadioButton from 'components/common/ui-kit/RadioButton';
import ReactSelect from 'components/common/ui-kit/Select';
import ProfileForm from 'components/profile/ProfileForm';
import ProfileSection from 'components/profile/ProfileSection';
import useAppSelector from 'hooks/common/useAppSelector';
import useProfileRoutesContext from 'hooks/profile/useProfileRoutesContext';
import terms from 'i18n';
import { SelectOption } from 'models/common';

import { useVisaForm } from '../../../hooks/questionnaire/visa/useVisaForm';
import { selectIsDraftReadonly } from '../../../redux/selectors/enrollee/questionnaire';

const VisaForm: FC = () => {
  const readOnly = useAppSelector(selectIsDraftReadonly);
  const { onNextPageNavigate } = useProfileRoutesContext();

  const {
    fields,
    control,
    onSubmit,
    countryProps,
    citiesProps,
    onCitiesInputChange,
    onCountriesInputChange,
    watch,
    formState: { errors },
  } = useVisaForm(onNextPageNavigate);

  return (
    <ProfileForm
      onSubmit={
        readOnly
          ? e => {
              e.preventDefault();
              onNextPageNavigate();
            }
          : onSubmit
      }
    >
      <ProfileSection>
        <div className="profile-form__fields">
          <Controller
            name={fields.countryToDeliver.name}
            control={control}
            render={({ field }) => (
              <ReactSelect<SelectOption>
                placeholder={terms.COUNTRY_OF_VISA_APPLICATION}
                className="profile-form__field"
                markRequired
                {...field}
                onInputChange={onCountriesInputChange}
                options={countryProps}
                isClearable
                isDisabled={readOnly}
              />
            )}
          />

          <Controller
            name={fields.cityToDeliver.name}
            control={control}
            render={({ field }) => (
              <ReactSelect<SelectOption>
                placeholder={terms.CITY_OF_VISA_APPLICATION}
                className="profile-form__field"
                markRequired
                {...field}
                options={citiesProps}
                onInputChange={onCitiesInputChange}
                isClearable
                isDisabled={!watch('countryToDeliver') || readOnly}
              />
            )}
          />
        </div>

        <ProfileSection
          markRequired
          title={terms.HAVE_BEEN_TO_RUSSIA_WITH_VISA}
        >
          <RadioButton className="profile-form__radio">
            <RadioButton.Radio
              {...fields.stringifiedHadStudyVisa}
              disabled={readOnly}
              value="yes"
              text={terms.YES}
            />
            <RadioButton.Radio
              {...fields.stringifiedHadStudyVisa}
              disabled={readOnly}
              value="no"
              text={terms.NO}
            />
          </RadioButton>
          {errors.stringifiedHadStudyVisa?.message && (
            <div className="profile-form__error">
              {errors.stringifiedHadStudyVisa?.message}
            </div>
          )}
        </ProfileSection>
      </ProfileSection>
    </ProfileForm>
  );
};

export default VisaForm;
