import React, { FC } from 'react';

import { Controller } from 'react-hook-form';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as EditIcon } from 'assets/images/icons/edit.svg';
import AdminEntryViewPage from 'components/admin/AdminEntryViewPage';
import { AdminTableList } from 'components/admin/AdminTableListPage/view';
import ObjectEntriesView from 'components/admin/ObjectEntriesView';
import Button from 'components/common/ui-kit/Button';
import { InputV2 } from 'components/common/ui-kit/Input/view';
import ReactSelect from 'components/common/ui-kit/Select/view';
import useAppDispatch from 'hooks/common/useAppDispatch';
import terms from 'i18n';
import { EntranceTestStatus } from 'models/applications/entrance-tests';
import { ExamStatementResponse } from 'models/applications/statements';
import { SelectOption } from 'models/common';
import { createToast, showModal } from 'redux/actions';
import { apiSlice } from 'redux/api/api-slice';
import { statementsApi } from 'redux/api/applications/statements-api';
import { getMoscowDateTimeFormattedString } from 'utils/date';
import { createInputV2Props, createSelectProps } from 'utils/form-helpers';

import { getFullName } from '../UploadQueuePage/view';

export const getPartOfUrl = (url: string) => {
  if (!url) return '-';
  const parts = url.split('?');
  if (parts.length < 2) return url;
  return `${parts[0]}...${parts[1].slice(-5)}`;
};

export const entranceTestStatusesToStrings: Record<EntranceTestStatus, string> =
  {
    NOT_STARTED: 'Не началось',
    PASSED: 'Пройдено',
    FAILED: 'Провалено',
  };

export const entranceTestStatusSelectOptions = Object.entries(
  entranceTestStatusesToStrings
).map(([key, value]) => ({
  value: key,
  label: value,
}));

const renderFilterRegistryUsersComponent = ({
  control,
  formState: { errors },
  watch,
}) => {
  const inputV2Props = createInputV2Props(errors);
  const selectProps = createSelectProps(errors);
  return (
    <>
      <Controller
        name="id"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="ID пользователя"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="Фамилия"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="Имя"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="middleName"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="Отчество"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="Email"
            {...inputV2Props(field)}
          />
        )}
      />
      {/* <Controller
        name="passportSerialDocument"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="Серия паспорта"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="passportNumberDocument"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="Номер паспорта"
            {...inputV2Props(field)}
          />
        )}
      /> */}
      <Controller
        name="score"
        control={control}
        render={({ field }) => (
          <InputV2
            className="filter-form__field"
            placeholder="Балл"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption>
            options={entranceTestStatusSelectOptions}
            placeholder="Статус"
            className="filter-form__field"
            {...selectProps(field, {
              options: entranceTestStatusSelectOptions,
            })}
          />
        )}
      />
    </>
  );
};

const RegistryView: FC<{
  registry: ExamStatementResponse;
}> = ({ registry }) => {
  const dispatch = useAppDispatch();
  const [uploadToAdmission] = statementsApi.useUploadToAdmissionMutation();
  const [fetchResults] = statementsApi.useLazyFetchResultsQuery();
  const [setApproved] = statementsApi.useSetApprovedMutation();

  const handleUploadToAdmission = () => {
    uploadToAdmission(registry.id)
      .unwrap()
      .then(() => {
        dispatch(createToast('Ведомость успешно загружена в 1C', 'success'));
      })
      .catch(e => {
        const message =
          e.status === 400
            ? e.data.messageRu
            : 'Во время загрузки ведомости в 1C произошла ошибка';
        dispatch(createToast(message, 'danger'));
      });
  };

  const handleFetchResults = () => {
    fetchResults(registry.id)
      .unwrap()
      .then(() => {
        dispatch(createToast('Результаты из Moodle получены', 'success'));
        dispatch(apiSlice.util.invalidateTags(['Statement']));
      })
      .catch(() => {
        dispatch(
          createToast(
            'Во время получения результатов из Moodle произошла ошибка',
            'danger'
          )
        );
      });
  };

  const handleApprove = () => {
    setApproved({ id: registry.id, status: true })
      .unwrap()
      .then(() => {
        dispatch(createToast('Баллы утверждены', 'success'));
      })
      .catch(() => {
        dispatch(
          createToast('Во время утверждения баллов произошла ошибка', 'danger')
        );
      });
  };

  // const queryCacheKeyRef = useRef();

  // const handleGetResultFromMoodle = (testId: number, index: number) => {
  //   fetchResults(testId)
  //     .unwrap()
  //     .then(res => {
  //       // dispatch(apiSlice.util.invalidateTags(['Statement']));
  //       const { current: queryKey } = queryCacheKeyRef;
  //       console.log({ res, queryKey });
  //       dispatch(
  //         apiSlice.util.updateQueryData(
  //           'getAllUserInStatementPageable',
  //           queryKey,
  //           prev => {
  //             console.log({
  //               prevBefore: prev,
  //             });
  //             if (!prev) return prev;
  //             const result = prev.result;
  //             const el = result[index];
  //             result[index] = { ...el, score: res.score };
  //             console.log({
  //               prev,
  //               result,
  //             });
  //             return { ...prev, result };
  //           }
  //         )
  //       );
  //     });
  // };

  const handleEditButtonClick = (testId: number, score?: number) => {
    dispatch(
      showModal({
        name: 'EDIT_ENTRANCE_TEST_MODAL',
        data: {
          score: score,
          // onSubmit: onEditSubmit,
          applicationEntranceTestId: testId,
        },
      })
    );
  };

  const handleShowJsonButtonClick = (json: string) => {
    dispatch(
      showModal({
        name: 'STATEMENT_JSON_TO_UPLOAD_MODAL',
        data: json,
      })
    );
  };

  const registryUsersTableColumns = [
    {
      Header: '№',
      accessor: 'number',
      render: (_, __, totalIndex) =>
        totalIndex !== undefined ? totalIndex + 1 : undefined,
    },
    {
      Header: 'id',
      accessor: 'idWithPrefix',
      render: ({ user: { idWithPrefix } }) => idWithPrefix,
    },
    {
      Header: 'email',
      accessor: 'email',
      render: ({ user: { email } }) => email,
    },
    {
      Header: 'Ссылка на экзамен',
      accessor: 'emuserMoodleCourseLinkail',
      render: ({ userMoodleCourseLink }) => (
        <a className="link" href={userMoodleCourseLink}>
          {getPartOfUrl(userMoodleCourseLink)}
        </a>
      ),
    },
    {
      Header: 'ФИО',
      accessor: 'fullName',
      render: ({ user: { surname, name, patronymic } }) =>
        getFullName(surname, name, patronymic),
    },
    {
      Header: 'Статус заявления',
      accessor: 'applicationState',
      render: ({ applicationState }) => terms[applicationState],
    },
    {
      Header: 'Выгружен в 1С',
      accessor: 'isUploadedTo1C',
      render: ({ user: { externalId } }) =>
        externalId !== null ? terms.YES : terms.NO,
    },
    {
      Header: 'Статус',
      accessor: 'status',
      render: ({ testStatus }) => entranceTestStatusesToStrings[testStatus],
    },
    {
      Header: 'Балл',
      accessor: 'score',
      render: ({ setByModer, score }) => (
        <span>
          {score}
          {score !== undefined && setByModer && (
            <>
              <Tooltip
                anchorSelect=".set-by-moder-mark"
                clickable
                style={{ maxWidth: 400, padding: '10px 15px' }}
                place="top"
                variant="dark"
              >
                {'Оценка выставлена вручную'}
              </Tooltip>
              <span
                className="set-by-moder-mark"
                style={{ color: '#37b34a', fontSize: '1.2rem' }}
              >
                *
              </span>
            </>
          )}
        </span>
      ),
    },
    {
      Header: 'Студент выгружен в ведомость',
      accessor: 'uploadDate',
      render: ({ uploadDate }) => (uploadDate ? terms.YES : terms.NO),
    },
    {
      Header: '',
      accessor: 'edit',
      render: ({ testId, score }) => (
        <button
          type="button"
          onClick={() => handleEditButtonClick(testId, score)}
        >
          <EditIcon />
        </button>
      ),
    },
    // {
    //   Header: '',
    //   accessor: 'get-moodle-result',
    //   render: ({ testId }, index) => (
    //     <Button
    //       type="button"
    //       style={{ whiteSpace: 'nowrap' }}
    //       onClick={() => handleGetResultFromMoodle(testId, index)}
    //       size="micro"
    //     >
    //       Получить результат из Moodle
    //     </Button>
    //   ),
    // },
  ];

  const { jsonToUpload } = registry;

  return (
    <div className="registry-entry-view">
      <ObjectEntriesView<ExamStatementResponse>
        object={registry}
        keysToTitles={{
          name: 'Название',
          subjectTitle: 'Название предмета',
          campaignName: 'Название Приёмной кампании',
          dateTimeOfTest: 'Дата экзамена',
          moodleCourseLink: 'Ссылка на курс',
          uploadDate: 'Дата выгрузки',
          uploadError: 'Ошибка выгрузки',
          approved: 'Баллы утверждены',
          quantity: 'Количество записанных / общее количество мест',
        }}
        keysToRenderers={{
          dateTimeOfTest: dateTimeOfTest => {
            return getMoscowDateTimeFormattedString(dateTimeOfTest);
          },
          moodleCourseLink: moodleCourseLink => {
            return (
              <a className="link" href={moodleCourseLink}>
                {moodleCourseLink}
              </a>
            );
          },
          quantity: (_, record) =>
            `${record.numberOfUsers} / ${record.quantity}`,
        }}
      />
      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <Button onClick={handleUploadToAdmission}>
          Загрузить ведомость в 1c
        </Button>
        <Button onClick={handleFetchResults}>
          Получить результаты из Moodle
        </Button>
        {jsonToUpload && (
          <Button
            type="button"
            onClick={() => handleShowJsonButtonClick(jsonToUpload)}
          >
            Показать JSON
          </Button>
        )}
        <Button theme="success" onClick={handleApprove}>
          Утвердить баллы ВИ
        </Button>
      </div>
      <AdminTableList
        storeKey="registry-entry"
        query={{
          use: statementsApi.useGetAllUserInStatementPageableQuery,
          params: {
            statementId: registry.id,
          },
        }}
        displayCountLabel="Пользователей"
        // onDataLoadSuccess={queryKey => (queryCacheKeyRef.current = queryKey)}
        columns={registryUsersTableColumns}
        filtersConfig={{
          defaultValues: {
            id: '',
            lastName: '',
            firstName: '',
            middleName: '',
            email: '',
            score: '',
            status: null,
          },
          renderFilters: renderFilterRegistryUsersComponent,
          mapFiltersToRequest: (filters, map) => {
            const res = map(filters);
            if (res.score !== null && res.score !== undefined) {
              try {
                res.score = new Number(res.score);
              } catch (e) {
                delete res.score;
              }
            }
            return res;
          },
        }}
      />
    </div>
  );
};

const RegistryEntryPage = () => {
  return (
    <AdminEntryViewPage
      useGetEntryQuery={statementsApi.useGetStatementByIdQuery}
      getBreadcrumbsLabel={() => 'Ведомость'}
    >
      {registry => <RegistryView registry={registry} />}
    </AdminEntryViewPage>
  );
};

export default RegistryEntryPage;
