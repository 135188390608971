import React, { FC } from 'react';

import { Controller } from 'react-hook-form';
import { Tooltip } from 'react-tooltip';

import TemplateAuthForm from 'components/auth/TemplateAuthForm';
import Button from 'components/common/ui-kit/Button';
import Checkbox from 'components/common/ui-kit/Checkbox';
import Input from 'components/common/ui-kit/Input';
import Link from 'components/common/ui-kit/Link';
import RouteButtonLink from 'components/common/ui-kit/RouteButtonLink';
import ReactSelect from 'components/common/ui-kit/Select';
import { registrationFieldsByLocale } from 'config/constants';
import useSignUpForm from 'hooks/auth/useSignUpForm';
import terms, { locale } from 'i18n';

import { useGetCountriesSelectProps } from '../../../hooks/common/api/useGetCountriesSelectProps';

type SignUpFormProps = {
  title: string;
};

const isRuLocale = locale === 'ru';
const localeCharactersOnly = isRuLocale
  ? terms.CYRILLIC_CHARACTERS_ONLY
  : terms.LATIN_CHARACTERS_ONLY;

export const EpguWarning = () => {
  return (
    <p
      style={{
        marginBottom: 24,
        fontSize: '1.2rem',
        background: '#37b34a',
        padding: '12px',
        borderRadius: '8px',
        color: '#FFFFFF',
      }}
    >
      <b>ВНИМАНИЕ!</b> Подача документов при поступлении на обучение по
      программам бакалавриата и программам специалитета{' '}
      <b>
        для граждан Российской Федерации осуществляется через{' '}
        <Link
          href="https://www.gosuslugi.ru/"
          target="_blank"
          style={{ color: '#FFFFFF' }}
        >
          Единый портал государственных услуг
        </Link>
      </b>
    </p>
  );
};

const registrationStartDate = process.env.REACT_APP_REGISTRATION_START_DATE;
export const isRegistrationDisabled = registrationStartDate
  ? new Date(registrationStartDate) > new Date()
  : true;

const SignUpForm: FC<SignUpFormProps> = ({ title }) => {
  const {
    fields,
    onSubmit,
    control,
    loading,
    isSnilsRequired,
    isBirthdayFilled,
    formState: { errors },
  } = useSignUpForm();

  const countries = useGetCountriesSelectProps();

  const isRegistrationButtonDisabled = loading || isRegistrationDisabled;

  return (
    <TemplateAuthForm title={title}>
      <EpguWarning />
      <form onSubmit={onSubmit}>
        <div className="auth-form__body auth-form__body--mobile-column-reverse">
          <div className="auth-form__fields">
            <h3 className="auth-form__subtitle">{terms.BASIC_DATA}</h3>

            <Input
              className="auth-form__input"
              hint={localeCharactersOnly}
              placeholder={
                isRuLocale ? terms.SURNAME_IN_RUSSIAN : terms.SURNAME_IN_LATIN
              }
              markRequired={true}
              {...fields.surname}
              error={errors[registrationFieldsByLocale['surname']]?.message}
            />
            <Input
              className="auth-form__input"
              hint={localeCharactersOnly}
              placeholder={
                isRuLocale ? terms.NAME_IN_RUSSIAN : terms.NAME_IN_LATIN
              }
              markRequired={true}
              {...fields.name}
              error={errors[registrationFieldsByLocale['name']]?.message}
            />
            <Input
              className="auth-form__input"
              hint={localeCharactersOnly}
              placeholder={
                isRuLocale
                  ? terms.PATRONYMIC_IN_RUSSIAN
                  : terms.PATRONYMIC_IN_LATIN
              }
              {...fields.patronymic}
              error={errors[registrationFieldsByLocale['patronymic']]?.message}
            />
            <Input
              className="auth-form__input"
              placeholder={terms.DATE_OF_BIRTH}
              type="date"
              max="100"
              markRequired={true}
              {...fields.birthday}
              error={errors.date_birthday?.message}
            />
            <Controller
              name="nationality"
              control={control}
              render={({ field }) => (
                <ReactSelect
                  placeholder={terms.CITIZENSHIP}
                  className="auth-form__input"
                  markRequired={true}
                  {...(field as any)}
                  options={countries}
                  error={errors.nationality?.message}
                />
              )}
            />
            <Input
              className="auth-form__input"
              placeholder={terms.SNILS}
              markRequired={!isSnilsRequired}
              {...fields.snils}
              error={errors.snils?.message}
            />
            <Controller
              name={fields.snilsIssuedAt.name}
              control={control}
              render={({ field }) => (
                <Input
                  className="profile-form__field"
                  placeholder={terms.DATE_OF_ISSUE_SNILS}
                  type="date"
                  // {...field}
                  {...fields.snilsIssuedAt}
                  error={errors.snilsIssuedAt?.message}
                />
              )}
            />
            <Checkbox text={terms.NO_SNILS} {...fields.noSnils} />
          </div>
          <div className="auth-form__fields">
            <h3 className="auth-form__subtitle">{terms.AUTHORIZATION}</h3>
            <Input
              placeholder={terms.EMAIL}
              className="auth-form__input"
              markRequired={true}
              {...fields['email']}
              error={errors.email?.message}
            />
            <Input
              className="auth-form__input"
              hint={terms.PASS_HINT}
              placeholder={terms.CREATE_PASSWORD}
              enableSwitcher={true}
              markRequired={true}
              {...fields['password']}
              error={errors.password?.message}
            />
          </div>
        </div>
        <div className="auth-form__agreement">
          <Checkbox
            text={terms.CONSENT_TO_DATA_PROCESSING}
            {...fields['accept']}
          />
        </div>
        <div className="auth-form__buttons">
          <RouteButtonLink to="/sign-in" className="auth-form__button">
            {terms.BACK}
          </RouteButtonLink>
          <Button
            id="auth-form__register-button"
            theme="success"
            className="auth-form__button"
            disabled={isRegistrationButtonDisabled}
          >
            {terms.REGISTER}
          </Button>
          {isRegistrationDisabled && (
            <Tooltip
              anchorSelect="#auth-form__register-button"
              clickable
              style={{ maxWidth: 400, padding: '10px 15px' }}
              place="bottom"
              variant="dark"
            >
              {terms.SIGN_UP_UNAVAILABLE_MESSAGE}
            </Tooltip>
          )}
        </div>
      </form>
    </TemplateAuthForm>
  );
};

export default SignUpForm;
