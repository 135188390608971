import { EducationInformationDraft } from '../../../models/questionnaire/questionnaire';
import { useSaveEducationInformationDraftMutation } from '../../../redux/api/questionnaire/questionnaire-api';
import useDraft from '../common/draft/useDraft';

export const useEducationInformationDraft = () => {
  return useDraft<EducationInformationDraft[]>(
    questionnaire => questionnaire.educationalInformationForm,
    useSaveEducationInformationDraftMutation
  );
};
