import { UploadQueueInfoResponse } from 'models/staff/upload-queue';

import { Page, Pagination } from '../../../../models/common';
import { AuditFilters } from '../../../../models/staff/audit';
import { apiSlice } from '../../api-slice';

const baseUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/import-tasks`;

export const importTasksApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAllImportTasksPageable: builder.query<
      Page<UploadQueueInfoResponse>,
      Pagination & {
        readonly request: AuditFilters;
      }
    >({
      query: ({ page, size, request }) => ({
        url: `${baseUrl}/all-pageable`,
        method: 'POST',
        body: request,
        params: {
          page,
          size,
        },
      }),
      providesTags: ['ImportTasks'],
    }),
  }),
});
