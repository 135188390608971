import { useEffect, useRef } from 'react';

import useChatApplicantId from 'hooks/chat/useChatApplicantId';
import useChatId from 'hooks/chat/useChatId';
import useChatIsEnrollee from 'hooks/chat/useChatIsEnrollee';
import useChatIsEnrolleeReplier from 'hooks/chat/useChatIsEnrolleeReplier';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import {
  ChatSignalType,
  MessageData,
  ReadPayload,
  ChatMessage,
  AcceptedPayload,
  ReceivePayload,
  ChatType,
  PingPayload,
} from 'models/chat';
import { UserRole } from 'models/user';
import {
  addUpdateChatMessage,
  applyToChatMessages,
  incUnreadCountById,
  resetUnreadCountById,
  resetUnreadCountByType,
} from 'redux/actions';
import { selectAccessToken } from 'redux/selectors';
import {
  selectChatMessages,
  selectChatSocket,
  selectChatType,
  selectChatViewed,
  selectUserChats,
} from 'redux/selectors/common/chat';

const pingTimeout = 40 * 1000;

export type UseChatActions = {
  refetchMessages: () => void;
  refetchBadge: () => void;
};

const extractMessage = <Payload>(data: string) => {
  const { signal, payload } = JSON.parse(data) as MessageData;
  return { signal, payload: JSON.parse(payload) as Payload };
};

export const toMessage = <Payload>(signal: ChatSignalType, payload: Payload) =>
  JSON.stringify({ signal, payload: JSON.stringify(payload) } as MessageData);

const useChatInitMethods = ({
  refetchMessages,
  refetchBadge,
}: UseChatActions) => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectAccessToken);
  const userId = useAppSelector(state => state.user.id);
  const userRole = useAppSelector(state => state.user.role);
  const chatType = useAppSelector(selectChatType);
  const chatSocket = useAppSelector(selectChatSocket);
  const chatMessages = useAppSelector(selectChatMessages) || {};
  const userChats = useAppSelector(selectUserChats) || {};
  const chatViewed = useAppSelector(selectChatViewed);
  const chatId = useChatId();
  const applicantId = useChatApplicantId();
  const isEnrollee = useChatIsEnrollee();
  const isEnrolleeReplier = useChatIsEnrolleeReplier();

  const sendMessage = <Payload>(signal: ChatSignalType, payload: Payload) => {
    chatSocket?.send(toMessage(signal, payload));
  };

  const markMessagesAsRead = () => {
    if (!userId || !chatId) return;
    sendMessage<ReadPayload>('READ', { chatId, readerId: userId });
    dispatch(resetUnreadCountByType(chatType));
  };

  const signalToAction = {
    SEND: (chatMessage: ChatMessage) => {
      const isSenderMessage = userId === chatMessage.senderId;
      const isChatTypeEnrollee = chatType === ChatType.ENROLLEE;

      if (
        !isSenderMessage &&
        (!isChatTypeEnrollee ||
          (isChatTypeEnrollee &&
            (isEnrollee ||
              (isEnrolleeReplier && applicantId === chatMessage.senderId))))
      ) {
        dispatch(incUnreadCountById(chatMessage.chatId));
      }

      if (chatMessage.chatId === chatId && chatMessages) {
        if (chatViewed) markMessagesAsRead();
        chatMessage.sent = true;
        dispatch(addUpdateChatMessage(chatMessage));
      }
    },
    READ: payload => {
      dispatch(resetUnreadCountById(payload.chatId));
    },
    RECEIVE: (payload: ReceivePayload) => {
      if (chatId !== payload.chatId) return;
      dispatch(
        applyToChatMessages(chatMessage => ({ ...chatMessage, read: true }))
      );
    },
    ACCEPTED: (payload: AcceptedPayload) => {
      const chatMessage = { ...chatMessages[payload.uuid], sent: true };
      dispatch(addUpdateChatMessage(chatMessage));
    },
    PONG: () => {
      waitingPong.current = false;
    },
  };

  const waitingPong = useRef(false);

  const setPingInterval = () =>
    setInterval(() => {
      if (!userId || !token || waitingPong.current) return;
      sendMessage<PingPayload>('PING', { userId, jwt: token });
      waitingPong.current = true;
    }, pingTimeout);

  useEffect(() => {
    if (!chatSocket) return;
    chatSocket.onmessage = ({ data }) => {
      const { signal, payload } = extractMessage<ChatMessage>(data);
      signalToAction[signal]?.(payload);
    };
    const pingInterval = setPingInterval();
    return () => {
      chatSocket.onmessage = () => {};
      clearInterval(pingInterval);
    };
  }, [chatSocket, chatId, userChats, chatMessages, chatViewed, userId, token]);

  useEffect(() => {
    if (!chatSocket) return;
    chatSocket.onopen = () => {
      if (chatSocket.OPEN) {
        refetchMessages();
        refetchBadge();
      }
    };
    return () => {
      chatSocket.onopen = () => {};
    };
  }, [chatSocket, chatId, userId]);

  useEffect(() => {
    if (
      chatViewed &&
      userRole !== UserRole.MODERATOR &&
      userRole !== UserRole.INSTITUTE_STAFF &&
      userRole !== UserRole.SPECIALIST
    ) {
      markMessagesAsRead();
    }
  }, [chatType, chatViewed]);
};

export default useChatInitMethods;
