import React, { FC } from 'react';

import { Outlet } from 'react-router-dom';

import useSubmitFormContext, {
  SubmitFormContextProvider,
} from 'hooks/common/useSubmitFormContext';
import useFillingStatementsRoutesContext, {
  FillingStatementsRoutesContextProvider,
} from 'hooks/statements/useFillingStatementsRoutesContext';
import SidenavPage from 'pages/SidenavPage';

import useAuthInitialization from '../../../hooks/auth/useAuthInitialization';

const FillingStatementsPage: FC = () => {
  useAuthInitialization();

  return (
    <main className="filling-statements">
      <SubmitFormContextProvider>
        <FillingStatementsRoutesContextProvider>
          <SidenavPage
            useHooks={useFillingStatementsRoutesContext}
            title=""
            useSubmitFormHook={useSubmitFormContext}
          >
            <Outlet />
          </SidenavPage>
        </FillingStatementsRoutesContextProvider>
      </SubmitFormContextProvider>
    </main>
  );
};

export default FillingStatementsPage;
