import useAppDispatch from 'hooks/common/useAppDispatch';
import { useEnrolleeUserId } from 'hooks/questionnaire/common/draft/useQuestionnaire';
import { showModal } from 'redux/actions';
import { useLazyPreviewFilesQuery } from 'redux/api/common/files-api';

import { Achievement } from '../../../models/applications/achievements';
import { achievementsApi } from '../../../redux/api/applications/achievements-api';
import { useDownloadFile } from '../../common/api/useDownloadFile';

const useAchievementsForm = (
  applicationId: number,
  refetchAchievementsForm: () => void
) => {
  const dispatch = useAppDispatch();

  const downloadFile = useDownloadFile();
  const [deleteAchievement] = achievementsApi.useDeleteAchievementMutation();
  const [previewFiles] = useLazyPreviewFilesQuery();
  const userId = useEnrolleeUserId();

  const onDownloadFile = (docId: number) => {
    if (!userId) return;
    previewFiles({ userId, ids: [docId] })
      .unwrap()
      .then(files => {
        if (!files[0]) return;
        downloadFile({
          userId,
          fileId: docId,
          fileName: files[0].originalName,
        });
      });
  };

  const onDelete = (achievementId: number) =>
    deleteAchievement(achievementId)
      .unwrap()
      .then(() => refetchAchievementsForm());

  const onEdit = (achievement: Achievement) => {
    dispatch(
      showModal({
        name: 'ADD_INDIVIDUAL_ACHIEVEMENT',
        data: { applicationId, achievement, userId },
      })
    );
  };

  return {
    onEdit,
    onDelete,
    onDownloadFile,
  };
};

export default useAchievementsForm;
