import { ApplicationEducationLevel } from 'models/applications/enum';
import { EACAdmissionListFormResponse } from 'models/students';
import { apiSlice } from 'redux/api/api-slice';

const baseUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/eac-admission-list`;

export const eacAdmissionsListApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAdmissionsListForm: builder.query<
      EACAdmissionListFormResponse,
      {
        applicationEducationLevel?: ApplicationEducationLevel;
        directionEducationFormId?: number;
        directionPaymentFormId?: number;
        directionId?: number;
      }
    >({
      query: params => {
        return {
          url: `${baseUrl}/form`,
          params: params,
        };
      },
    }),
  }),
});

export const { useGetAdmissionsListFormQuery } = eacAdmissionsListApi;
