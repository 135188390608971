import { SelectOption } from '../../../models/common';
import {
  useGetEducationFormsQuery,
  useGetEducationProgramsQuery,
  useGetFacultiesQuery,
  useGetPaymentFormsQuery,
} from '../../../redux/api/applications/directions-api';
import {
  applicationStatusSelectOptions,
  contractStateSelectOptions,
  educationLevelSelectOptions,
  trajectorySelectOptions,
} from '../../../utils/enum-select';
import { useGetCountriesSelectProps } from '../../common/api/useGetCountriesSelectProps';
import { useGetRecruiters } from '../../common/api/useGetRecruiters';
import useLazyIdEnumSelectProps from '../../common/useLazyIdEnumSelectProps';
import { ServeMethod } from '../applications/useApplicationFilterOptions';
import { useGetModeratorSelectProps } from '../applications/useGetModeratorSelectProps';

export const useContractFilterOptions = () => {
  const paymentFormSelectProps = useLazyIdEnumSelectProps(
    useGetPaymentFormsQuery
  );

  const educationFormSelectProps = useLazyIdEnumSelectProps(
    useGetEducationFormsQuery
  );

  const moderators = useGetModeratorSelectProps();
  const countrySelectProps = useGetCountriesSelectProps();

  const facultiesFormSelectProps =
    useLazyIdEnumSelectProps(useGetFacultiesQuery);
  const educationProgramsSelectProps = useLazyIdEnumSelectProps(
    useGetEducationProgramsQuery
  );

  const recruiters = useGetRecruiters();

  const serveMethodOptions: SelectOption[] = [
    {
      value: ServeMethod.INDIVIDUAL,
      label: 'Индивидуально',
    },
    {
      value: ServeMethod.PARTNER,
      label: 'Партнер(Рекрутинговая компания)',
    },
  ];

  return {
    moderatorSelectProps: {
      options: moderators,
    },
    paymentFormSelectProps,
    educationFormSelectProps,
    countrySelectProps: {
      options: countrySelectProps,
    },
    applicationStatusSelectProps: {
      options: applicationStatusSelectOptions,
    },
    contractStateSelectOptions: {
      options: contractStateSelectOptions,
    },
    facultiesFormSelectProps,
    recruitersSelectProps: recruiters,
    educationLevelSelectProps: {
      options: educationLevelSelectOptions,
    },
    trajectorySelectProps: {
      options: trajectorySelectOptions,
    },
    educationProgramsSelectProps,
    serveMethodProps: {
      options: serveMethodOptions,
    },
  };
};
