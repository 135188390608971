import { useMemo } from 'react';

import useAppSelector from 'hooks/common/useAppSelector';
import { selectChatType, selectUserChats } from 'redux/selectors/common/chat';

const useChatId = () => {
  const userChats = useAppSelector(selectUserChats);
  const chatType = useAppSelector(selectChatType);
  return useMemo(() => userChats?.[chatType]?.id, [userChats, chatType]);
};

export default useChatId;
