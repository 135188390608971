import { useParams } from 'react-router-dom';

import { UserRole } from '../../models/user';
import { selectUserRole } from '../../redux/selectors';
import { getReturnRouteFromImpersonate } from '../../utils/domain/impersonate';
import useAppSelector from './useAppSelector';

export const useSidenav = ({ title }: { readonly title: string }) => {
  const { userId, applicationId } = useParams();

  const role = useAppSelector(selectUserRole);

  const getLinkToNavigateOnBackClick = () =>
    getReturnRouteFromImpersonate({
      applicationId,
      userId,
    });

  return {
    role,
    getLinkToNavigateOnBackClick,
    isBackArrowVisible:
      role !== UserRole.USER &&
      window.location.pathname.includes('personal-information') &&
      title,
  };
};
