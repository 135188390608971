import React, { FC } from 'react';

import { Controller } from 'react-hook-form';

import AdminTableListPage from 'components/admin/AdminTableListPage';
import { InputV2 } from 'components/common/ui-kit/Input/view';
import ReactSelect from 'components/common/ui-kit/Select/view';
import { columnsLogList } from 'config/moderator-table';
import { useLogFiltersOptions } from 'hooks/admin/useLogFiltersOptions';
import { EventUser } from 'models/staff/audit';
import { auditApi } from 'redux/api/staff/admin/audit-api';
import { formatDateTimeToRuFormat } from 'utils/date';
import { createInputV2Props } from 'utils/form-helpers';

export const mapAuditUserToName = (user?: EventUser): string | undefined => {
  if (!user) return undefined;

  if (user.firstName && user.secondName) {
    return `${user.secondName ?? ''} ${user.firstName ?? ''} ${
      user.lastName ?? ''
    }`;
  }

  if (user.firstNameForeign && user.secondNameForeign) {
    return `${user.firstNameForeign ?? ''} ${user.secondNameForeign ?? ''} ${
      user.lastNameForeign ?? ''
    }`;
  }

  return undefined;
};

export const LogPageFilter = ({ control, formState: { errors } }) => {
  const {
    auditEventTypeOptions,
    auditEventTypeHeadOptions,
    roleSelectOptions,
  } = useLogFiltersOptions();

  const inputV2Props = createInputV2Props(errors);
  return (
    <>
      <Controller
        name="applicationId"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="ID заявления"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="applicantId"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="ID владельца заявления"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="actorId"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="ID инициатора"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="actorRole"
        control={control}
        render={({ field }) => (
          <ReactSelect
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={roleSelectOptions}
            placeholder="Роль инициатора"
            isClearable
          />
        )}
      />

      <Controller
        name="startDate"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Дата начала"
            className="filter-form__field"
            type="datetime-local"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="endDate"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Дата окончания"
            className="filter-form__field"
            type="datetime-local"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="eventType"
        control={control}
        render={({ field }) => (
          <ReactSelect
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={auditEventTypeOptions}
            placeholder="Тип события"
            isClearable
          />
        )}
      />

      <Controller
        name="eventTypeHead"
        control={control}
        render={({ field }) => (
          <ReactSelect
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={auditEventTypeHeadOptions}
            placeholder="Обобщенный тип"
            isClearable
          />
        )}
      />

      <Controller
        name="message"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Сообщение"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />
    </>
  );
};

export const renderLogPageFilter = props => <LogPageFilter {...props} />;

const LogPage: FC = () => {
  return (
    <AdminTableListPage
      storeKey="log-page"
      title="Лог действий"
      query={{
        use: auditApi.useGetAllAuditEventsPageableQuery,
        mapQueryResultToData: elements =>
          elements.map(event => ({
            ...event,
            applicantId: event.applicant.id,
            applicantName: mapAuditUserToName(event.applicant),
            applicantRole: event.applicant?.role,
            actorId: event?.actor?.id,
            actorName: mapAuditUserToName(event?.actor),
            actorRole: event?.actor?.role,
            eventTime: formatDateTimeToRuFormat(event.eventTime),
          })),
      }}
      columns={columnsLogList}
      filtersConfig={{
        defaultValues: {
          applicationId: '',
          applicantId: '',
          actorId: '',
          actorRole: undefined,
          startDate: '',
          endDate: '',
          eventType: undefined,
          eventTypeHead: undefined,
          message: '',
        },
        renderFilters: renderLogPageFilter,
        mapFiltersToRequest: (filters, map) => {
          const request = {
            ...filters,
          };

          for (const filter in request) {
            if (
              Array.isArray(request[filter]) &&
              request[filter].length === 0
            ) {
              delete request[filter];
            } else if (request[filter] === '') {
              delete request[filter];
            }
          }

          return map(request);
        },
      }}
      sortingConfig={{
        options: [
          { label: 'по дате', value: 'CREATED_AT' },
          { label: 'по ФИО', value: 'NAME' },
        ],
      }}
    />
  );
};

export default LogPage;
