import { contractApi } from '../../../redux/api/applications/contract-api';
import { useLazyDownloadFileQuery } from '../../../redux/api/common/files-api';
import { selectUserId } from '../../../redux/selectors';
import { downloadFile } from '../../../utils/common';
import useAppSelector from '../../common/useAppSelector';
import { useDownloadContractDocument } from './useDownloadContractDocument';

const useContractList = () => {
  const userId = useAppSelector(selectUserId);

  const [download] = useLazyDownloadFileQuery();
  const [submitContract] = contractApi.useLazySubmitContractQuery();

  const downloadPrintContract = useDownloadContractDocument();

  const onContractSubmit = (contractId, onSuccess: () => void) => {
    if (!userId) return;

    submitContract({
      id: contractId,
    })
      .unwrap()
      .then(() => onSuccess());
  };

  const downloadSignedDocument = fileId => {
    if (!userId) return;

    download({
      userId,
      fileId,
    })
      ?.unwrap()
      .then(result => {
        downloadFile(new File([result], 'Договор.pdf'));
      });
  };

  return {
    onContractSubmit,
    downloadPrintContract: contract => {
      return downloadPrintContract(contract);
    },
    downloadSignedDocument,
  };
};

export default useContractList;
