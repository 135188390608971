import React, { FC } from 'react';

import classnames from 'classnames';

import { educationLevelToText } from 'components/statements/ApplicationsList/view';
import { directionsRequestTypeToText } from 'components/statements/DirectionsTypeList/view';
import useAppSelector from 'hooks/common/useAppSelector';

import {
  selectCurrentEducationLevel,
  selectCurrentTrajectory,
  selectDirectionsRequestType,
} from '../../../../redux/selectors/enrollee/application';
import { trainingLevels } from '../../LevelStatementsList/view';
import { trajectories } from '../../TrajectoryList/view';

type ProgressTabElementProps = {
  route: { title: string; to: string; id: string };
  active: string | undefined;
  className: string | undefined;
};

const ProgressTabElement: FC<ProgressTabElementProps> = ({
  active,
  route,
  className,
}) => {
  const { title, id } = route;
  const trajectory = useAppSelector(selectCurrentTrajectory);
  const trainingLevel = useAppSelector(selectCurrentEducationLevel);
  const directionsRequestType = useAppSelector(selectDirectionsRequestType);

  const currentTrainingLevel = trainingLevels.find(
    level => level === trainingLevel
  );

  return (
    <div
      className={classnames(className, {
        active: active === id,
        disabled: 0 < Number(id) - Number(active),
      })}
    >
      <h2>{title}</h2>
      <p>
        {id === '1'
          ? trajectory
            ? trajectories[trajectory]?.name
            : ''
          : id === '2'
          ? currentTrainingLevel
            ? educationLevelToText[currentTrainingLevel]
            : ''
          : id === '3'
          ? directionsRequestType
            ? directionsRequestTypeToText[directionsRequestType]
            : ''
          : ''}
      </p>
    </div>
  );
};

export default ProgressTabElement;
