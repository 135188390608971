import { useEffect, useState } from 'react';

import { User } from '../../../models/enrollee';
import { UserRole } from '../../../models/user';
import { selectUser, selectUserRole } from '../../../redux/selectors';
import useAppSelector from '../../common/useAppSelector';
import { useQuestionnaire } from './draft/useQuestionnaire';

export const useGetQuestionnaireUser = () => {
  const { data: questionnaire } = useQuestionnaire();
  const role = useAppSelector(selectUserRole);
  const userFromState = useAppSelector(selectUser);

  const [user, setUser] = useState<User | null | undefined>(null);

  useEffect(() => {
    if (role) {
      if (role === UserRole.USER) {
        setUser(userFromState as unknown as User);
      } else {
        setUser(questionnaire?.user);
      }
    }
  }, [role, userFromState, questionnaire]);

  return user;
};
