import React, { FC, useMemo } from 'react';

import Checkbox from 'components/common/ui-kit/Checkbox';
import ModeratorDataList from 'components/moderator/ModeratorDataList';
import useAppSelector from 'hooks/common/useAppSelector';
import { DataList } from 'models/moderator';
import { checkStatusesToStrings } from 'models/questionnaire/questionnaire';
import { formatDateYYYYMMDD } from 'utils/date';

import Loader from '../../../components/common/ui-kit/Loader';
import CheckListEntryValueSelectWithRoleChecker from '../../../components/moderator/CheckListEntryValueSelectWithRoleChecker';
import terms from '../../../i18n';
import { Country } from '../../../models/common-entities';
import { selectCurrentQuestionnaire } from '../../../redux/selectors/staff/applications';
import { selectCurrentSupportQuestionnaire } from '../../../redux/selectors/staff/questionnaires';

const ModeratorPassportDataPage: FC<{ isSupport?: boolean }> = ({
  isSupport,
}) => {
  const questionnaire = useAppSelector(
    isSupport ? selectCurrentSupportQuestionnaire : selectCurrentQuestionnaire
  );

  // TODO rewrite in future
  const countryOfBirth: Country = (questionnaire?.passportForm as any)
    ?.countryOfBirth as Country;

  const alternativePassportsDataLists = useMemo<DataList[] | undefined>(() => {
    if (!questionnaire) return;
    return questionnaire.alternativePassportsView.map(alternativePassport => [
      {
        title: 'Серия:',
        label: alternativePassport.identificationDocumentSerial || '-',
      },
      {
        title: 'Номер:',
        label: alternativePassport.identificationDocumentNumber || '-',
      },
      {
        title: 'Код подразделения:',
        label: alternativePassport.identificationDocumentDivisionCode || '-',
      },
      {
        title: 'Когда выдан:',
        label: alternativePassport.identificationDocumentIssuedAt
          ? formatDateYYYYMMDD(
              alternativePassport.identificationDocumentIssuedAt
            )
          : '-',
      },
      {
        title: 'Кем выдан:',
        label: alternativePassport.identificationDocumentIssuedBy || '-',
      },
      {
        title: 'ЕПГУ GUID документа:',
        label: alternativePassport.epguExternalId ?? '-',
      },
    ]);
  }, [questionnaire]);

  if (!questionnaire) {
    return <Loader />;
  }

  const list: DataList = [
    {
      title: 'Название документа:',
      label: questionnaire.passportForm.identificationDocumentType
        ? terms[questionnaire.passportForm.identificationDocumentType]
        : '-',
    },
    {
      title: 'Серия:',
      label: questionnaire.passportForm.identificationDocumentSerial || '-',
    },
    {
      title: 'Номер:',
      label: questionnaire.passportForm.identificationDocumentNumber || '-',
    },
    {
      title: 'Срок действия:',
      label: questionnaire.passportForm.identificationDocumentExpirationDate
        ? formatDateYYYYMMDD(
            questionnaire.passportForm.identificationDocumentExpirationDate
          )
        : '-',
    },
    {
      title: 'Код подразделения:',
      label:
        questionnaire.passportForm.identificationDocumentDivisionCode || '-',
    },
    {
      title: 'Когда выдан:',
      label: questionnaire.passportForm.identificationDocumentIssuedAt
        ? formatDateYYYYMMDD(
            questionnaire.passportForm.identificationDocumentIssuedAt
          )
        : '-',
    },
    {
      title: 'Кем выдан:',
      label: questionnaire.passportForm.identificationDocumentIssuedBy || '-',
    },
    {
      title: 'Страна рождения:',
      label: countryOfBirth?.title || '-',
    },
    {
      title: 'Место рождения:',
      label:
        questionnaire.passportForm.identificationDocumentPlaceOfBirth || '-',
    },
    {
      title: 'Нужна виза:',
      label: questionnaire.passportForm.needVisa ? 'Да' : 'Нет',
    },
    {
      title: 'Имеет российскую визу / РВП:',
      label: questionnaire.passportForm.haveVisa ? 'Да' : 'Нет',
    },
    {
      title: 'Статус проверки в ССПВО:',
      label: questionnaire.passportForm.checkStatus
        ? checkStatusesToStrings[questionnaire.passportForm.checkStatus]
        : '-',
    },
  ];

  return (
    <div className="moderator-data-list">
      <ModeratorDataList list={list} />
      <>
        <Checkbox
          text="Имеется второе гражданство"
          checked={!!questionnaire.passportForm.secondaryCitizenshipCountry}
          readOnly
        />

        {questionnaire.passportForm.secondaryCitizenshipCountry && (
          <ModeratorDataList
            list={[
              {
                title: 'Страна второго гражданства:',
                label:
                  questionnaire.passportForm.secondaryCitizenshipCountry
                    .title ?? '-',
              },
            ]}
          />
        )}

        <Checkbox
          text="Имеет вид на жительство"
          checked={!!questionnaire.passportForm.haveTemporaryResidencePermit}
          readOnly
        />

        {questionnaire.passportForm.haveTemporaryResidencePermit && (
          <ModeratorDataList
            list={[
              {
                title: 'Номер:',
                label:
                  questionnaire.passportForm.temporaryResidencePermitNumber ||
                  '-',
              },
              {
                title: 'Кем выдан:',
                label:
                  questionnaire.passportForm.temporaryResidencePermitIssuedBy ||
                  '-',
              },
              {
                title: 'Когда выдан:',
                label: questionnaire.passportForm
                  .temporaryResidencePermitIssuedAt
                  ? formatDateYYYYMMDD(
                      questionnaire.passportForm
                        .temporaryResidencePermitIssuedAt
                    )
                  : '-',
              },
              {
                title: 'Срок действия:',
                label: questionnaire.passportForm
                  .temporaryResidencePermitExpirationDate
                  ? formatDateYYYYMMDD(
                      questionnaire.passportForm
                        .temporaryResidencePermitExpirationDate
                    )
                  : '-',
              },
            ]}
          />
        )}

        <Checkbox
          text={
            'Поступает по квоте Минобрнауки России/являюсь победителем или призером олимпиады Open Doors'
          }
          checked={questionnaire.passportForm.hasMinobrBenefit}
          readOnly
        />

        {questionnaire.passportForm.hasMinobrBenefit && (
          <ModeratorDataList
            list={[
              {
                title: 'Регистрационный номер',
                label:
                  questionnaire.passportForm
                    .educationInRussiaWebsiteIdentifier || '-',
              },
            ]}
          />
        )}
      </>

      {alternativePassportsDataLists &&
        alternativePassportsDataLists.length !== 0 && (
          <>
            <hr />
            <h2 style={{ fontWeight: 'bold' }}>Дополнительные паспорта:</h2>
            {alternativePassportsDataLists.map(alternativePassportDataList => (
              <ModeratorDataList
                list={alternativePassportDataList}
                style={{ marginBottom: 40 }}
              />
            ))}
          </>
        )}

      <CheckListEntryValueSelectWithRoleChecker
        getValue={checkList => checkList.unrelatedCheckMarks['passport-data']}
        updateValue={newValue => ({
          unrelatedCheckMarks: {
            'passport-data': newValue,
          },
        })}
      />
    </div>
  );
};

export default ModeratorPassportDataPage;
