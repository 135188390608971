import React from 'react';

import ProgressTabPage from 'pages/ProgressTabPage';

import Button from '../../../components/common/ui-kit/Button';
import EducationalDocumentList from '../../../components/statements/EducationalDocumentList';
import { statementsTabRotes } from '../../../config/routes/statements-tab-routes';
import useEducationalInfoPage from '../../../hooks/statements/useEducationalInfoPage';
import terms from '../../../i18n';

const EducationalInfoPage = () => {
  const { isSubmitButtonDisabled, onSubmit } = useEducationalInfoPage();

  return (
    <main className="educational-page">
      <ProgressTabPage
        activePage={statementsTabRotes.find(l =>
          location.pathname.includes(l.to)
        )}
      >
        <EducationalDocumentList />
        <Button
          onClick={onSubmit}
          theme="success"
          disabled={isSubmitButtonDisabled}
        >
          {terms.SAVE_AND_NEXT}
        </Button>
      </ProgressTabPage>
    </main>
  );
};

export default EducationalInfoPage;
