import React, { FC, useEffect, useRef } from 'react';

import Inputmask from 'inputmask';
import { Controller } from 'react-hook-form';

import Checkbox from 'components/common/ui-kit/Checkbox';
import LazyFileUploader from 'components/common/ui-kit/LazyFileUploader';
import ReactSelect from 'components/common/ui-kit/Select';
import ProfileForm from 'components/profile/ProfileForm';
import ProfileSection from 'components/profile/ProfileSection';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import { FileUploadError } from 'hooks/files/useUploadFiles';
import useProfileRoutesContext from 'hooks/profile/useProfileRoutesContext';
import usePassportForm from 'hooks/questionnaire/passportDraft/usePassportForm';
import terms from 'i18n';
import { createToast } from 'redux/actions';
import {
  createCheckboxProps,
  createInputV2Props,
  createSelectProps,
} from 'utils/form-helpers';

import { useGetCountriesSelectProps } from '../../../hooks/common/api/useGetCountriesSelectProps';
import { useLazyUploadFiles } from '../../../hooks/files/useLazyUploadFiles';
import { useGetIdentificationDocumentTypeOptions } from '../../../hooks/questionnaire/passportDraft/useGetIdentificationDocumentTypeOptions';
import { PassportDraft } from '../../../models/questionnaire/questionnaire';
import { selectIsDraftReadonly } from '../../../redux/selectors/enrollee/questionnaire';
import Input, { InputV2 } from '../../common/ui-kit/Input/view';
import { DadataFmsAutocomplete } from '../AddressesForm/dadata';

const PassportDataForm: FC = () => {
  const readOnly = useAppSelector(selectIsDraftReadonly);
  const { onNextPageNavigate } = useProfileRoutesContext();
  const dispatch = useAppDispatch();

  const {
    fields,
    control,
    watch,
    onSubmit,
    setValue,
    formState: { errors },
    isRussianEnrollee,
    isCeaEnrollee,
    haveSecondNationality,
    minobrBenefitRequired,
    isRussianPassport,
    isForeignPassport,
    temporaryResidencePermitRequired,
  } = usePassportForm(onNextPageNavigate);

  const countrySelectProps = useGetCountriesSelectProps();
  const identificationDocumentTypeOptions =
    useGetIdentificationDocumentTypeOptions(isRussianEnrollee);

  const { uploadFiles: uploadPassportFiles, ...passportFilesCallBacks } =
    useLazyUploadFiles({
      filename: terms.COPY_OF_PASSPORT,
      initialFileIds: watch('passportCopyUploadId'),
    });

  const { uploadFiles: uploadMinobrFiles, ...minobrFilesCallBacks } =
    useLazyUploadFiles({
      filename: terms.MSHE_CONFIRMATION_LETTER,
      initialFileIds: watch('minobrReferralUploadId'),
    });

  const { uploadFiles: uploadVnzFiles, ...vnzFilesCallBacks } =
    useLazyUploadFiles({
      filename: terms.VNZ,
      initialFileIds: watch('temporaryResidencePermitUploadId'),
    });

  const inputV2Props = createInputV2Props(errors, readOnly);
  const checkboxProps = createCheckboxProps(errors, readOnly);
  const selectProps = createSelectProps(errors, readOnly);

  const divisionCodeInputMask = new Inputmask('999[-999]', {
    placeholder: ' ',
  });

  const divisionCodeInputRef = useRef(null);

  useEffect(() => {
    if (divisionCodeInputRef.current) {
      divisionCodeInputMask.mask(divisionCodeInputRef.current);
    }
  }, [divisionCodeInputRef.current]);

  return (
    <ProfileForm
      onSubmit={
        readOnly
          ? e => {
              e.preventDefault();
              onNextPageNavigate();
            }
          : e => {
              e.preventDefault();
              // todo do it better way
              if (Object.keys(errors).length === 0) {
                const uploads: Promise<number | undefined>[] = [
                  uploadPassportFiles(),
                ];
                watch('hasMinobrBenefit') &&
                minobrFilesCallBacks.files.length !== 0
                  ? uploads.push(uploadMinobrFiles())
                  : uploads.push(Promise.resolve(undefined));
                watch('haveTemporaryResidencePermit') &&
                vnzFilesCallBacks.files.length !== 0
                  ? uploads.push(uploadVnzFiles())
                  : uploads.push(Promise.resolve(undefined));
                Promise.all(uploads)
                  .then(([passportId, minobrId, vnzId]) => {
                    const overwriteData: Partial<PassportDraft> = {
                      passportCopyUploadId: passportId,
                    };
                    if (minobrId !== undefined)
                      overwriteData.minobrReferralUploadId = minobrId;
                    if (vnzId !== undefined)
                      overwriteData.temporaryResidencePermitUploadId = vnzId;
                    onSubmit(overwriteData)(e);
                  })
                  .catch(error => {
                    if (error === FileUploadError.FILE_NOT_ATTACHED)
                      dispatch(
                        createToast(
                          `${terms.DOCUMENTS_REQUIRED + ' '}
                           ${terms.COPY_OF_PASSPORT}${
                            minobrBenefitRequired
                              ? ', ' + terms.MSHE_CONFIRMATION_LETTER
                              : ''
                          }${
                            temporaryResidencePermitRequired
                              ? ', ' + terms.VNZ
                              : ''
                          }`,
                          'danger'
                        )
                      );
                  });
              }
            }
      }
    >
      <ProfileSection>
        <div className="profile-form__fields">
          <Controller
            name={fields.identificationDocumentType.name}
            control={control}
            render={({ field }) => (
              <ReactSelect
                isClearable
                className="profile-form__field"
                placeholder={terms.DOCUMENT_TYPE}
                markRequired
                {...selectProps(field, identificationDocumentTypeOptions)}
              />
            )}
          />

          {!isForeignPassport ? (
            <div className="profile-form__field profile-form__field--container">
              <Controller
                name={fields.identificationDocumentSerial.name}
                control={control}
                render={({ field }) => (
                  <InputV2
                    className="profile-form__input profile-form__input--fb-50"
                    placeholder={terms.SERIES}
                    markRequired={isRussianPassport}
                    maxLength={4}
                    {...inputV2Props(field)}
                  />
                )}
              />

              <Controller
                name={fields.identificationDocumentNumber.name}
                control={control}
                render={({ field }) => (
                  <InputV2
                    className="profile-form__input profile-form__input--fb-50"
                    placeholder={terms.NUMBER}
                    markRequired
                    maxLength={6}
                    {...inputV2Props(field)}
                  />
                )}
              />
            </div>
          ) : (
            <Controller
              name={fields.identificationDocumentNumber.name}
              control={control}
              render={({ field }) => (
                <InputV2
                  className="profile-form__field"
                  placeholder={terms.NUMBER}
                  markRequired
                  {...inputV2Props(field)}
                />
              )}
            />
          )}

          <Controller
            name={fields.identificationDocumentIssuedAt.name}
            control={control}
            render={({ field }) => (
              <InputV2
                className="profile-form__field"
                placeholder={terms.DATE_OF_ISSUE}
                markRequired
                type="date"
                {...inputV2Props(field)}
              />
            )}
          />

          <Controller
            name={fields.identificationDocumentIssuedBy.name}
            control={control}
            render={({ field }) =>
              isRussianPassport ? (
                <DadataFmsAutocomplete
                  className="profile-form__field"
                  placeholder={terms.ISSUING_AUTHORITY}
                  value={null}
                  onSelect={option => {
                    field.onChange(option);
                    const code = option?.value.data.code;
                    if (!code) return;
                    setValue(
                      fields.identificationDocumentDivisionCode.name,
                      code
                    );
                  }}
                  onInputValueChange={value => {
                    field.onChange(value);
                  }}
                  inputValue={
                    field.value
                      ? typeof field.value === 'string'
                        ? field.value ?? ''
                        : field.value.value.value
                      : ''
                  }
                  isDisabled={field.disabled || readOnly}
                  markRequired
                  error={
                    errors[fields.identificationDocumentIssuedBy.name]
                      ?.message as string
                  }
                />
              ) : (
                <InputV2
                  className="profile-form__field"
                  placeholder={terms.ISSUING_AUTHORITY}
                  markRequired
                  {...inputV2Props({
                    ...field,
                    value: field.value
                      ? typeof field.value === 'string'
                        ? field.value ?? ''
                        : field.value.value.value
                      : '',
                  })}
                />
              )
            }
          />

          {!isRussianPassport ? (
            <Controller
              name={fields.identificationDocumentExpirationDate.name}
              control={control}
              render={({ field }) => (
                <InputV2
                  className="profile-form__field"
                  placeholder={terms.DATE_OF_EXPIRATION}
                  markRequired
                  type="date"
                  {...inputV2Props(field)}
                />
              )}
            />
          ) : (
            <Controller
              name={fields.identificationDocumentDivisionCode.name}
              control={control}
              render={({ field }) => (
                <Input
                  className="profile-form__field"
                  placeholder={terms.DIVISION_CODE}
                  markRequired
                  {...inputV2Props(field)}
                  ref={divisionCodeInputRef}
                />
              )}
            />
          )}

          <Controller
            name={fields.identificationDocumentPlaceOfBirth.name}
            control={control}
            render={({ field }) => (
              <InputV2
                className="profile-form__field division-code"
                placeholder={terms.PLACE_OF_BIRTH}
                markRequired
                {...inputV2Props(field)}
              />
            )}
          />

          <div className="profile-form__field">
            <Controller
              name={fields.countryOfBirth.name}
              control={control}
              render={({ field }) => (
                <ReactSelect
                  isClearable
                  placeholder={terms.COUNTRY_OF_BIRTH}
                  markRequired
                  {...selectProps(field, {
                    options: countrySelectProps,
                  })}
                />
              )}
            />
          </div>

          {!isRussianEnrollee && (
            <div className="profile-form__field">
              <Controller
                name={fields.secondaryCitizenship.name}
                control={control}
                disabled={!haveSecondNationality}
                render={({ field }) => {
                  const props = selectProps(field, {
                    options: countrySelectProps,
                  });
                  return (
                    <ReactSelect
                      isClearable
                      placeholder={terms.DUAL_CITIZENSHIP}
                      markRequired={haveSecondNationality}
                      {...props}
                    />
                  );
                }}
              />
              <Checkbox
                text={terms.I_HAVE_DUAL_CITIZENSHIP}
                className="profile-form__checkbox"
                {...checkboxProps(fields.haveSecondNationality)}
              />
            </div>
          )}
        </div>
      </ProfileSection>

      <ProfileSection
        title={terms.COPY_OF_PASSPORT_CLARIFIED}
        markRequired={true}
      >
        <LazyFileUploader
          disabled={readOnly}
          className="profile-form__file-uploader"
          {...passportFilesCallBacks}
        />
      </ProfileSection>

      {!isRussianEnrollee && (
        <>
          <ProfileSection>
            {!isCeaEnrollee && (
              <Checkbox
                text={terms.I_AM_COMPATRIOT}
                className="profile-form__checkbox profile-form__checkbox--mt-24"
                {...checkboxProps(fields.isCompatriot)}
              />
            )}
            <Checkbox
              text={terms.I_NEED_VISA}
              className="profile-form__checkbox profile-form__checkbox--mt-24"
              {...checkboxProps(fields.needVisa)}
            />
            <Checkbox
              text={terms.I_HAVE_VISA_OR_RVP}
              className="profile-form__checkbox profile-form__checkbox--mt-24"
              {...fields.haveVisa}
              disabled={readOnly}
            />
            <Checkbox
              text={terms.I_HAVE_VNZ}
              className="profile-form__checkbox profile-form__checkbox--mt-24"
              {...fields.haveTemporaryResidencePermit}
              disabled={readOnly}
            />
            <Checkbox
              text={terms.RECEIVED_MINOBR_QUOTA}
              className="profile-form__checkbox profile-form__checkbox--mt-24"
              {...checkboxProps(fields.hasMinobrBenefit)}
            />
            {watch('hasMinobrBenefit') && (
              <Controller
                name={fields.educationInRussiaWebsiteIdentifier.name}
                control={control}
                render={({ field }) => (
                  <InputV2
                    className="profile-form__field"
                    placeholder={terms.REG_NUMBER_EDUCATION_IN_RUSSIA}
                    markRequired={minobrBenefitRequired}
                    {...inputV2Props(field)}
                  />
                )}
              />
            )}
          </ProfileSection>
        </>
      )}

      {watch('hasMinobrBenefit') && (
        <ProfileSection title={terms.MSHE_CONFIRMATION_LETTER}>
          <LazyFileUploader
            disabled={readOnly}
            className="profile-form__file-uploader"
            {...minobrFilesCallBacks}
          />
        </ProfileSection>
      )}

      {watch('haveTemporaryResidencePermit') && (
        <ProfileSection title={terms.VNZ}>
          <div className="profile-form__fields">
            <Controller
              name={fields.temporaryResidencePermitNumber.name}
              control={control}
              render={({ field }) => (
                <InputV2
                  className="profile-form__field"
                  placeholder={terms.DOCUMENT_NUMBER}
                  markRequired={temporaryResidencePermitRequired}
                  {...inputV2Props(field)}
                />
              )}
            />

            <Controller
              name={fields.temporaryResidencePermitIssuedBy.name}
              control={control}
              render={({ field }) => (
                <InputV2
                  className="profile-form__field"
                  placeholder={terms.ISSUING_AUTHORITY}
                  markRequired={temporaryResidencePermitRequired}
                  {...inputV2Props(field)}
                />
              )}
            />

            <Controller
              name={fields.temporaryResidencePermitIssuedAt.name}
              control={control}
              render={({ field }) => (
                <InputV2
                  className="profile-form__field"
                  placeholder={terms.DATE_OF_ISSUE}
                  markRequired={temporaryResidencePermitRequired}
                  type="date"
                  {...inputV2Props(field)}
                />
              )}
            />

            <Controller
              name={fields.temporaryResidencePermitExpirationDate.name}
              control={control}
              render={({ field }) => (
                <InputV2
                  className="profile-form__field"
                  placeholder={terms.DATE_OF_EXPIRATION}
                  type="date"
                  {...inputV2Props(field)}
                />
              )}
            />
          </div>
          <LazyFileUploader
            disabled={readOnly}
            className="profile-form__file-uploader"
            {...vnzFilesCallBacks}
          />
        </ProfileSection>
      )}
    </ProfileForm>
  );
};

export default PassportDataForm;
