export enum Trajectory {
  LOCAL = 'LOCAL',
  FOREIGN = 'FOREIGN',
  QUOTA = 'QUOTA',
}

export enum ApplicationEducationLevel {
  SECONDARY = 'SECONDARY',
  BACHELOR = 'BACHELOR',
  MASTER = 'MASTER',
  GRADUATE = 'GRADUATE',
  PREPARATORY = 'PREPARATORY',
}
export enum DirectionApplicationEducationLevel {
  SECONDARY = 'SECONDARY',
  UPPER_SECONDARY = 'UPPER_SECONDARY',
  BACHELOR = 'BACHELOR',
  MASTER = 'MASTER',
  GRADUATE = 'GRADUATE',
  PREPARATORY = 'PREPARATORY',
}

export enum ApplicationState {
  NOT_CREATED = 'NOT_CREATED',
  DRAFT = 'DRAFT',
  UNDER_CONSIDERATION = 'UNDER_CONSIDERATION',
  IN_WORK = 'IN_WORK',
  SENT_FOR_EDITING = 'SENT_FOR_EDITING',
  ACCEPTED = 'ACCEPTED',
  ALLOWED = 'ALLOWED',
  ENROLLED = 'ENROLLED',
  DIRECTIONS_EDITING = 'DIRECTIONS_EDITING',
  DIRECTIONS_EDITED = 'DIRECTIONS_EDITED',
  WITHDRAWN = 'WITHDRAWN',
  WITHDRAWN_APPROVED = 'WITHDRAWN_APPROVED',
  REJECTED = 'REJECTED',
}

export enum MessagesState {
  READ = 'READ',
  UNREAD = 'UNREAD',
}

export enum ApplicationFlag {
  EDITED_BY_USER = 'EDITED_BY_USER',
  SENT_TO_EXPERT = 'SENT_TO_EXPERT',
  APPROVED_BY_EXPERT = 'APPROVED_BY_EXPERT',
  REJECTED_BY_EXPERT = 'REJECTED_BY_EXPERT',
  SENT_TO_COORDINATOR = 'SENT_TO_COORDINATOR',
  APPROVED_BY_COORDINATOR = 'APPROVED_BY_COORDINATOR',
  REJECTED_BY_COORDINATOR = 'REJECTED_BY_COORDINATOR',
}

export enum ExpertStatus {
  SENT_TO_EXPERT = 'SENT_TO_EXPERT',
  APPROVED_BY_EXPERT = 'APPROVED_BY_EXPERT',
  REJECTED_BY_EXPERT = 'REJECTED_BY_EXPERT',
}

export enum CoordinatorStatus {
  SENT_TO_COORDINATOR = 'SENT_TO_COORDINATOR',
  APPROVED_BY_COORDINATOR = 'APPROVED_BY_COORDINATOR',
  REJECTED_BY_COORDINATOR = 'REJECTED_BY_COORDINATOR',
}

export enum ApplicationAdmissionCondition {
  OLYMPIAD = 'OLYMPIAD',
  GOVERNMENT_CONTRACT = 'GOVERNMENT_CONTRACT',
  BENEFIT = 'BENEFIT',
  PREROGATIVE = 'PREROGATIVE',
  SPECIAL_QUOTA = 'SPECIAL_QUOTA',
  SPECIAL_ADMISSION = 'SPECIAL_ADMISSION',
}

export enum ApplicationEpguStatus {
  NOT_RELATED = 'NOT_RELATED',
  SUCCESS = 'SUCCESS',
  ATTENTION = 'ATTENTION',
}

export enum ApplicationCheckStatus {
  NOT_CHECKED = 'NOT_CHECKED',
  CHECKED = 'CHECKED',
  CONFIRMED = 'CONFIRMED',
}
