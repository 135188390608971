import React, { FC, useEffect, useMemo } from 'react';

import { ReactComponent as FileIcon } from 'assets/images/icons/file.svg';
import { ReactComponent as CrossIcon } from 'assets/images/icons/red-cross.svg';
import useAppSelector from 'hooks/common/useAppSelector';
import { useLocalizedFullName } from 'hooks/questionnaire/common/draft/useLocalizedFullName';
import { FileResponse } from 'models/files';
import { useLazyGetStaffByIdQuery } from 'redux/api/staff/staff-api';
import { selectUser, selectUserRole } from 'redux/selectors';
import { selectCurrentQuestionnaire } from 'redux/selectors/staff/applications';
import {
  convertUtcDateToLocalDate,
  formatDateTimeToLocaleString,
} from 'utils/date';

import Button from '../../../components/common/ui-kit/Button';
import useAdditionalFiles from '../../../hooks/document/useAdditionalFIles';
import terms from '../../../i18n';
import { UserRole } from '../../../models/user';

type AdditionalFileProps = {
  file: FileResponse;
  onDownload: (filedId: number, fileName: string) => void;
  onDelete: (fileId: number) => void;
};

export const AdditionalFile: FC<AdditionalFileProps> = ({
  file,
  onDownload,
  onDelete,
}) => {
  const userRole = useAppSelector(selectUserRole);
  const questionnaire = useAppSelector(selectCurrentQuestionnaire);
  const currentStaff = useAppSelector(selectUser);
  const [getStaffById, { data: fetchedStaff }] = useLazyGetStaffByIdQuery();
  const { name, surname } = useLocalizedFullName();

  const fileOwnerName = useMemo(() => {
    if (fetchedStaff) return fetchedStaff.name + fetchedStaff.surname;
    if (!questionnaire || !currentStaff) return currentStaff.id;
    if (file.uploaderId === currentStaff.id) return `${name} ${surname}`;
    if (file.uploaderId === questionnaire.user.id)
      return `${questionnaire.generalDataForm.name} ${questionnaire.generalDataForm.surname}`;
    return currentStaff.id;
  }, [file, questionnaire, currentStaff, name, surname, fetchedStaff]);

  useEffect(() => {
    if (!fileOwnerName) {
      getStaffById({ id: file.uploaderId });
    }
  }, [fileOwnerName]);

  if (!questionnaire || !currentStaff) return null;

  return (
    <li className="add-files-page__item additional-file">
      <div className="additional-file__icon-wrapper">
        <button
          className="additional-file__button"
          onClick={() => onDownload(file.id, file.originalName)}
        >
          <FileIcon width={46} height={61} className="additional-file__icon" />
        </button>
        {(userRole === UserRole.ADMIN ||
          userRole === UserRole.MODERATOR ||
          userRole === UserRole.INSTITUTE_STAFF ||
          userRole === UserRole.SPECIALIST) && (
          <button
            className="additional-file__del-button"
            onClick={() => onDelete(file.id)}
          >
            <CrossIcon
              width={14}
              height={15}
              className="additional-file__cross"
            />
          </button>
        )}
      </div>
      <div className="additional-file__info">
        <div className="additional-file__name">{file.originalName}</div>
        <div className="additional-file__date">
          <div>
            {terms.CREATED}:{' '}
            {formatDateTimeToLocaleString(
              convertUtcDateToLocalDate(new Date(file.createdAt))
            )}
          </div>
          {file.createdAt !== file.updatedAt && (
            <div>
              {terms.EDITED}:{' '}
              {formatDateTimeToLocaleString(
                convertUtcDateToLocalDate(new Date(file.updatedAt))
              )}
            </div>
          )}
          <span className="additional-file__user">
            {terms.OWNER} - {fileOwnerName}
          </span>
        </div>
      </div>
    </li>
  );
};

const ModeratorAdditionalFormsPage: FC = () => {
  const { onDownload, onDelete, filePreviews, onOpenModal } =
    useAdditionalFiles();

  const userRole = useAppSelector(selectUserRole);

  return (
    <>
      {(userRole === UserRole.ADMIN ||
        userRole === UserRole.MODERATOR ||
        userRole === UserRole.INSTITUTE_STAFF ||
        userRole === UserRole.SPECIALIST) && (
        <div>
          <Button theme="success" onClick={onOpenModal}>
            Добавить файл
          </Button>
        </div>
      )}
      <ul className="add-files-page__items">
        {filePreviews.map((file, index) => {
          return (
            <AdditionalFile
              key={index}
              file={file}
              onDelete={onDelete}
              onDownload={onDownload}
            />
          );
        })}
      </ul>
    </>
  );
};

export default ModeratorAdditionalFormsPage;
