import React, { FC } from 'react';

import './ImpersonateExitModal.scss';
import TemplateModal from 'components/common/modals/TemplateModal';
import Button from 'components/common/ui-kit/Button';
import useAppSelector from 'hooks/common/useAppSelector';
import terms, { isRussianLocale } from 'i18n';
import { modalDataSelector } from 'redux/selectors';

import useAppDispatch from '../../../../hooks/common/useAppDispatch';
import { createToast, hideModal } from '../../../../redux/actions';
import { useCreateEnrolleeFromQuestionnaireMutation } from '../../../../redux/api/questionnaire/enrollees-api';

const ImpersonateExitModal: FC = () => {
  const { questionnaireId, onNavigate } = useAppSelector(modalDataSelector);
  const dispatch = useAppDispatch();

  const [createEnrolleeFromQuestionnaireRequest] =
    useCreateEnrolleeFromQuestionnaireMutation();

  const closeModal = () => dispatch(hideModal());

  const handleConfirmation = () => {
    if (!questionnaireId) return;
    createEnrolleeFromQuestionnaireRequest(questionnaireId)
      .unwrap()
      .then(() => {
        onNavigate();
        closeModal();
      })
      .catch(e => {
        const errorDescriptionLocale: string = isRussianLocale()
          ? e.data?.descriptionRu ?? e.data.description
          : e.data.description;
        dispatch(createToast(errorDescriptionLocale, 'danger'));
      });
  };

  return (
    <TemplateModal
      className="impersonate-exit-modal"
      title={terms.CONFIRM_CONSENT}
    >
      <div className="impersonate-exit-modal__content">
        Вы выходите из ЛК Абитуриента с несохранённой анкетой. Сохранить анкету?
        <div>
          <Button theme="success" onClick={handleConfirmation}>
            {terms.YES}
          </Button>
          <Button
            onClick={() => {
              onNavigate();
              closeModal();
            }}
          >
            {terms.NO}
          </Button>
        </div>
      </div>
    </TemplateModal>
  );
};

export default ImpersonateExitModal;
