import { useEffect, useMemo, useState } from 'react';

import { FileRejection } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';

import useAppSelector from 'hooks/common/useAppSelector';
import { useUploadFileMutation } from 'redux/api/common/files-api';

import terms from '../../i18n';
import { UserRole } from '../../models/user';
import { createToast } from '../../redux/actions';
import { selectUserRole } from '../../redux/selectors';
import useAppDispatch from '../common/useAppDispatch';
import useChatApplicantId from './useChatApplicantId';

export enum AttachmentStatus {
  NEW,
  LOADING,
  READY,
  ERROR,
}

export type SenderAttachment = {
  id?: number;
  file: File;
  status: AttachmentStatus;
};

export type SenderAttachments = {
  [key: string]: SenderAttachment;
};

const useChatAttachments = () => {
  const dispatch = useAppDispatch();
  const [attachments, setAttachments] = useState<SenderAttachments>({});
  const [uploadFile] = useUploadFileMutation();

  const userId = useAppSelector(state => state.user.id);
  const role = useAppSelector(selectUserRole);
  const applicantId = useChatApplicantId();

  const deleteAttachment = (key: string) =>
    setAttachments(prev => {
      const attachments = { ...prev };
      delete attachments[key];
      return attachments;
    });

  const onAddAttachments = (accepted: File[], rejected: FileRejection[]) => {
    if (accepted.some(file => file.size > 3145728)) {
      dispatch(createToast(terms.SIZE_BIGGER_3, 'danger'));
      return;
    }

    setAttachments(prev => {
      const newAttachments = {
        ...prev,
        ...accepted.reduce((acc, file) => {
          acc[uuidv4()] = {
            file: file,
            status: AttachmentStatus.NEW,
          };
          return acc;
        }, {}),
      };

      const errorAttachments = rejected.reduce((acc, rejection) => {
        acc[uuidv4()] = {
          file: rejection.file,
          status: AttachmentStatus.ERROR,
        };
        return acc;
      }, {});

      Object.keys(errorAttachments).forEach(key => {
        setTimeout(() => deleteAttachment(key), 2000);
      });

      return { ...newAttachments, ...errorAttachments };
    });
  };

  const setAttachmentReady = (key: string, id) =>
    setAttachments(prev => {
      const attachments = { ...prev };

      if (attachments[key]) {
        attachments[key].id = id;
        attachments[key].status = AttachmentStatus.READY;
      }

      return attachments;
    });

  const setAttachmentError = (key: string) => {
    setAttachments(prev => {
      const attachments = { ...prev };

      if (attachments[key]) {
        attachments[key]!.status = AttachmentStatus.ERROR;
      }

      return attachments;
    });
    setTimeout(() => deleteAttachment(key), 2000);
  };

  useEffect(() => {
    Object.entries(attachments).forEach(([key, value]) => {
      if (value?.status === AttachmentStatus.NEW) {
        if (applicantId && userId) {
          const id = role === UserRole.USER ? userId : applicantId;
          const reader = new FileReader();

          reader.onload = () =>
            uploadFile({ userId: id, file: value.file })
              .unwrap()
              .then(fileId => setAttachmentReady(key, fileId))
              .catch(() => setAttachmentError(key));
          reader.readAsArrayBuffer(value.file);
        }
      }
    });
  }, [attachments]);

  const attachmentValues = useMemo(
    () => Object.values(attachments).filter(a => a),
    [attachments]
  );

  return {
    attachments,
    chatAttachments: useMemo(
      () =>
        attachmentValues
          .filter(a => a && a.status === AttachmentStatus.READY)
          .map(att => ({ documentId: att!.id!, realName: att!.file.name })),
      [attachments]
    ),
    onAddAttachments,
    deleteAttachment,
    clearAttachments: () => setAttachments({}),
    isLoading: attachmentValues.some(
      a => a?.status === AttachmentStatus.LOADING
    ),
  };
};

export default useChatAttachments;
