import React, { FC, useEffect } from 'react';

import { Outlet, useParams } from 'react-router-dom';

import Breadcrumbs from 'components/common/ui-kit/Breadcrumbs';
import useSubmitFormContext, {
  SubmitFormContextProvider,
} from 'hooks/common/useSubmitFormContext';
import useModeratorStatementsRoutesContext, {
  ModeratorStatementsRoutesContextProvider,
} from 'hooks/moderator/useModeratorStatementsRoutesContext';
import SidenavPage from 'pages/SidenavPage';

import useAppDispatch from '../../../hooks/common/useAppDispatch';
import { useGetEnrolleeOrQuestionnaireByUserId } from '../../../hooks/moderator/useGetEnrolleeOrQuestionnaireByUserId';
import { supportQuestionnaireSlice } from '../../../redux/slices/staff/supportQuestionnaireSlice';

const ModeratorPersonalInformationIdPage: FC = () => {
  const dispatch = useAppDispatch();
  const { userId } = useParams();

  const questionnaire = useGetEnrolleeOrQuestionnaireByUserId(Number(userId));

  useEffect(() => {
    if (questionnaire) {
      dispatch(
        supportQuestionnaireSlice.actions.setCurrentQuestionnaire(questionnaire)
      );

      /* return () => {
        dispatch(supportQuestionnaireSlice.actions.cleanCurrentQuestionnaire());
      };*/
    }
  }, [questionnaire]);

  return (
    <div className="moderator-statements-id-page">
      <div className="moderator-statements-id-page__header">
        <Breadcrumbs
          link="/admin/personal-information"
          className="moderator-statements-id-page__header-title"
          title="Просмотр анкеты"
        />
      </div>
      <SubmitFormContextProvider>
        <ModeratorStatementsRoutesContextProvider>
          <SidenavPage
            useSubmitFormHook={useSubmitFormContext}
            useHooks={useModeratorStatementsRoutesContext}
            title=""
            next={false}
          >
            <Outlet />
          </SidenavPage>
        </ModeratorStatementsRoutesContextProvider>
      </SubmitFormContextProvider>
    </div>
  );
};

export default ModeratorPersonalInformationIdPage;
