import React, { FC, PropsWithChildren } from 'react';

type TemplateFormProps = PropsWithChildren<{
  title: string;
}>;

export const TemplateAuthForm: FC<TemplateFormProps> = ({
  title,
  children,
}) => (
  <div className="template-auth-form">
    <h1 className="template-auth-form__title">{title}</h1>
    <div className="template-auth-form__content">{children}</div>
  </div>
);

export default TemplateAuthForm;
