import React, { FC, memo, useEffect, useMemo, useRef } from 'react';

import { timestampToDate } from 'components/chat/ChatWidget/ChatPanel/Chat/ChatMessage/message-commons';
import Loader from 'components/common/ui-kit/Loader';
import useChatInitViewed from 'hooks/chat/useChatInitViewed';
import useAppSelector from 'hooks/common/useAppSelector';
import { ChatMessage } from 'models/chat';
import { selectChatMessages } from 'redux/selectors/common/chat';

import ReceiverMessage from './ChatMessage/ReceiverMessage';
import SenderMessage, { MessageStatus } from './ChatMessage/SenderMessage';

export type ChatMessages = {
  [chatUuid: string]: ChatMessage;
};

const Chat: FC = () => {
  const chatRef = useRef<HTMLDivElement>(null);
  const userId = useAppSelector(state => state.user.id);

  const chatMessages = useAppSelector(selectChatMessages);
  const messages = useMemo(
    () => Object.values(chatMessages || {}).reverse(),
    [chatMessages]
  );
  const isLoading = useMemo(() => !chatMessages, [chatMessages]);

  const updateViewed = useChatInitViewed({
    chat: chatRef,
    messages,
    isLoading,
  });

  const isSenderMessage = (senderId: number) => senderId === userId;

  const getMessageStatus = (read?: boolean, sent?: boolean): MessageStatus => {
    if (read) return MessageStatus.READ;
    if (sent) return MessageStatus.SENT;
    return MessageStatus.PENDING;
  };

  const scrollToLastMessage = () => {
    const lastMessage = messages[0];
    if (lastMessage?.senderId === userId && !lastMessage?.read) {
      chatRef.current?.scrollTo(0, chatRef.current.scrollHeight);
    }
  };

  useEffect(() => {
    scrollToLastMessage();
  }, [messages]);

  const formReceiveMessageSenderName = ({
    senderLastName,
    senderLastNameLatin,
    senderMiddleName,
    senderFirstNameLatin,
    senderMiddleNameLatin,
    senderFirstName,
    senderName,
    senderRole,
  }: {
    senderName?: string;
    senderFirstName?: string;
    senderLastName?: string;
    senderMiddleName?: string;
    senderFirstNameLatin?: string;
    senderLastNameLatin?: string;
    senderMiddleNameLatin?: string;
    senderRole?: string;
  }): string => {
    if (!senderRole || senderRole === 'USER') return senderName ?? '';

    if (senderFirstNameLatin || senderMiddleNameLatin) {
      return `${senderFirstNameLatin} ${senderMiddleNameLatin} ${
        senderLastNameLatin ?? ''
      } `;
    }

    if (senderFirstName || senderMiddleName) {
      return `${senderLastName ?? ''} ${senderFirstName} ${senderMiddleName}`;
    }

    return senderName ?? '';
  };

  const renderMessage = ({
    uuid,
    senderId,
    senderName,
    text,
    timestamp,
    read,
    attachments,
    sent,
    ...restNames
  }: ChatMessage) =>
    isSenderMessage(senderId) ? (
      <SenderMessage
        className="chat__message"
        senderId={senderId}
        key={uuid}
        text={text}
        date={timestampToDate(timestamp)}
        status={getMessageStatus(read, sent)}
        attachments={attachments}
      />
    ) : (
      <ReceiverMessage
        className="chat__message"
        key={uuid}
        senderId={senderId}
        sender={formReceiveMessageSenderName({
          senderName,
          ...restNames,
        })}
        text={text}
        date={timestampToDate(timestamp)}
        attachments={attachments}
      />
    );

  return (
    <div onScroll={updateViewed} className="chat" ref={chatRef}>
      {isLoading ? (
        <Loader className="chat__loader" />
      ) : (
        messages.map(renderMessage)
      )}
    </div>
  );
};

export default memo(Chat);
