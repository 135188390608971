import { DateString, Entity } from '../common';

export enum PrivilegeGroupingType {
  // COMMON = 'COMMON',
  BENEFIT = 'BENEFIT',
  PREROGATIVE = 'PREROGATIVE',
  SPECIAL = 'SPECIAL',
  PRIORITY_RIGHT = 'PRIORITY_RIGHT',
}

export enum PrivilegeExtrasType {
  ENTRANCE_TEST_SPECIAL_CONDITION = 'ENTRANCE_TEST_SPECIAL_CONDITION',
  DISABLED_PERSON = 'DISABLED_PERSON',
  MILITARY_SUPPORT = 'MILITARY_SUPPORT',
}

export type PrivilegeType = Entity & {
  readonly groupingType: PrivilegeGroupingType[];
};

export type Privilege = {
  readonly id: number;
  readonly applicationId: number;
  readonly groupingType: PrivilegeGroupingType;
  readonly privilegeTypeResponse: PrivilegeType;
  readonly confirmationDocumentTypeResponse: Entity;
  readonly dateOfIssue: DateString;
  readonly documentCode: string;
  readonly documentId: number;
  readonly admitted: boolean;
};

export type CreatePrivilegeRequest = {
  readonly applicationId: number;
  readonly privilegeTypeId: number;
  readonly groupingType: PrivilegeGroupingType;
  readonly confirmationDocumentTypeId: number;
  readonly dateOfIssue: DateString;
  readonly documentCode: string;
  readonly documentId: number;
};

export type UpdatePrivilegeRequest = {
  readonly id: number;
  readonly privilegeTypeId: number;
  readonly groupingType: PrivilegeGroupingType;
  readonly confirmationDocumentTypeId: number;
  readonly dateOfIssue: DateString;
  readonly documentCode: string;
  readonly documentId: number;
};

export type PrivilegeExtras = {
  readonly id: number;
  readonly type: PrivilegeExtrasType;
  readonly active: boolean;
  readonly documentId: number;
  readonly specialConditions?: string;
};
