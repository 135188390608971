import { useEffect } from 'react';

import ReconnectingWebSocket, { Options } from 'reconnecting-websocket';

import useChatApplicantId from 'hooks/chat/useChatApplicantId';
import useChatVisible from 'hooks/chat/useChatVisible';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import { locale } from 'i18n';
import { UserRole } from 'models/user';
import { setChatSocket } from 'redux/actions';
import { selectAccessToken } from 'redux/selectors';

const options: Options = {
  maxReconnectionDelay: 60000,
  minReconnectionDelay: 1000 + Math.random() * 7000,
};

const getWebsocketProtocol = () =>
  location.protocol === 'https:' ? 'wss:' : 'ws:';

const devSocketUrl = process.env.REACT_APP_CHAT_SOCKET_URL;

const prodSocketUrl = `${getWebsocketProtocol()}//${window.location.host}/${
  process.env.REACT_APP_CHAT_SOCKET_URL
}`;

const getChatSocketUrl = (
  token: string,
  userId: number,
  userRole: UserRole,
  applicantId?: number
) => {
  const chatSocketUrl = new URL(
    process.env.NODE_ENV === 'development' ? devSocketUrl : prodSocketUrl
  );
  const query = chatSocketUrl.searchParams;
  query.append('userId', String(userId));
  query.append('jwt', token);
  query.append('role', UserRole[userRole]);
  query.append('locale', locale);
  if (applicantId) query.append('applicantId', String(applicantId));
  return chatSocketUrl.href;
};

const useChatInitSocket = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectAccessToken);
  const user = useAppSelector(state => state.user);
  const chatVisible = useChatVisible();
  const applicantId = useChatApplicantId();

  useEffect(() => {
    if (!token || !user.id || !chatVisible) return undefined;
    const chatSocketUrl = getChatSocketUrl(
      token,
      user.id,
      user.role,
      applicantId
    );
    const webSocket = new ReconnectingWebSocket(chatSocketUrl, [], options);
    dispatch(setChatSocket(webSocket));
    return () => {
      webSocket?.close();
      dispatch(setChatSocket(null));
    };
  }, [token, user, chatVisible]);
};

export default useChatInitSocket;
