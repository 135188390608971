import React, { FC } from 'react';

import SignUpForm from 'components/auth/SignUpForm';
import terms from 'i18n';
import TemplateAuthPage from 'pages/TemplateAuthPage';

const SignUpPage: FC = () => (
  <TemplateAuthPage>
    <SignUpForm title={terms.REGISTRATION} />
  </TemplateAuthPage>
);

export default SignUpPage;
