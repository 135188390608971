import React, { FC } from 'react';

import { Controller } from 'react-hook-form';

import Checkbox from 'components/common/ui-kit/Checkbox';
import ProfileSection from 'components/profile/ProfileSection';
import FillingStatementsForm from 'components/statements/FillingStatementsForm';
import useGovernmentContractForm from 'hooks/statements/government-contracts/useGovernmentContractForm';
import terms from 'i18n';

import useAppSelector from '../../../hooks/common/useAppSelector';
import { selectIsApplicationReadonly } from '../../../redux/selectors/enrollee/application';
import { useIsImpersonate } from '../../../utils/domain/impersonate';
import { createInputV2Props } from '../../../utils/form-helpers';
import Input, { InputV2 } from '../../common/ui-kit/Input/view';
import LazyFileUploader from '../../common/ui-kit/LazyFileUploader';

const GovernmentContractForm: FC = () => {
  const isImpersonate = useIsImpersonate();
  const readOnly =
    useAppSelector(selectIsApplicationReadonly) && !isImpersonate;

  const {
    fields,
    control,
    onSubmit,
    fileProps,
    hasNotContract,
    formState: { errors },
  } = useGovernmentContractForm();

  const inputProps = createInputV2Props(errors, readOnly);

  return (
    <FillingStatementsForm
      onSubmit={e => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Checkbox
        className="profile-form__checkbox--mb-24"
        text={terms.HAS_NO_CONTRACT}
        {...fields.hasNotContract}
        disabled={readOnly}
      />

      {!hasNotContract && (
        <>
          <ProfileSection title={terms.OFFER_DATA}>
            <div className="profile-form__fields">
              <Input
                markRequired
                disabled={readOnly}
                placeholder={terms.ORGANIZATION_NAME}
                className="profile-form__field profile-form__field--fb-100"
                {...fields.organization}
                error={errors?.organization?.message}
              />

              <Input
                markRequired
                disabled={readOnly}
                placeholder={terms.OFFER_NUMBER}
                className="profile-form__field"
                {...fields.offerNumber}
                error={errors?.offerNumber?.message}
              />

              <Controller
                name={fields.dateOfIssue.name}
                control={control}
                render={({ field }) => (
                  <InputV2
                    type="date"
                    markRequired
                    placeholder={terms.ISSUE_DATE}
                    className="profile-form__field"
                    {...inputProps(field)}
                  />
                )}
              />
            </div>
          </ProfileSection>

          <ProfileSection title={terms.DOCUMENT_FILE} markRequired>
            <LazyFileUploader
              {...fileProps}
              className="profile-form__file-uploader"
            />
            {errors.wasDocumentAttached && (
              <div className="profile-form__error">
                {errors.wasDocumentAttached?.message}
              </div>
            )}
          </ProfileSection>
        </>
      )}
    </FillingStatementsForm>
  );
};

export default GovernmentContractForm;
