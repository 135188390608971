import React from 'react';

import compose from 'compose-function';
import { useLocation } from 'react-router-dom';

import AdmissionCampaignPageLayout from 'components/common/layouts/admission-campaign/AdmissionCampaignPageLayout';
import ModalLayer from 'components/common/modals/ModalLayer';
import Footer from 'components/common/ui-kit/Footer';
import Header from 'components/common/ui-kit/Header';
import ToastsContainer from 'components/common/ui-kit/ToastsContainer';
import { withRedux } from 'hocs/with-redux-provider';
import { withRouter } from 'hocs/with-router-provider';
import { locale } from 'i18n';
import Routing from 'routing';

import { useUnsentMessagesWarn } from './hooks/chat/useUnsentMessagesWarn';

export const App = () => {
  const { pathname } = useLocation();
  const isAdmissionCampaignPage =
    pathname.includes('applicants') && !pathname.includes('legacy');

  useUnsentMessagesWarn();

  if (isAdmissionCampaignPage) {
    return (
      <AdmissionCampaignPageLayout>
        <div className="app__body">
          <Routing />
        </div>
      </AdmissionCampaignPageLayout>
    );
  }

  return (
    <div className="app" lang={locale}>
      {!isAdmissionCampaignPage && <Header />}
      <ToastsContainer />
      <div className="app__body">
        <Routing />
      </div>
      <Footer />
      <ModalLayer />
    </div>
  );
};

const withProviders = compose(withRedux, withRouter);
export default withProviders(App);
