import { useState } from 'react';

export const useNonValidFields = <Draft>() => {
  const [nonValidFields, setNonValidFields] = useState<(keyof Draft)[]>([]);

  const addNonValidField = (field: keyof Draft) => {
    setNonValidFields(prev => [...prev, field]);
  };

  const clearNonValidFields = () => setNonValidFields([]);

  return {
    nonValidFields,
    addNonValidField,
    clearNonValidFields,
  };
};
