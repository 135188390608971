import {
  CreateSchoolTestRequest,
  SchoolTest,
  SubjectResponse,
  UpdateSchoolTestRequest,
  UserSchoolTestResponse,
} from '../../../models/applications/school-tests';
import { apiSlice } from '../api-slice';

const baseUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/school-tests`;

export const schoolTestApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    createSchoolTest: build.mutation<SchoolTest, CreateSchoolTestRequest>({
      query: request => ({
        url: `${baseUrl}/create`,
        method: 'POST',
        body: request,
      }),
    }),

    updateSchoolTest: build.mutation<SchoolTest, UpdateSchoolTestRequest>({
      query: request => ({
        url: `${baseUrl}/update`,
        method: 'POST',
        body: request,
      }),
    }),

    getAllTests: build.query<UserSchoolTestResponse[], number>({
      query: applicationId => ({
        url: `${baseUrl}/by-application-id/${applicationId}`,
        method: 'GET',
      }),
      providesTags: ['School tests'],
    }),

    getAllSubjects: build.query<SubjectResponse[], number>({
      query: applicationId => ({
        url: `${baseUrl}/all-subjects`,
        params: {
          applicationId,
        },
      }),
    }),

    deleteSchoolTest: build.mutation<void, number>({
      query: testId => ({
        url: `${baseUrl}/delete/${testId}`,
        method: 'DELETE',
      }),
    }),

    exportSchoolTestsResult: build.query<Blob, number>({
      query: userId => ({
        url: `${baseUrl}/export-students/${userId}`,
        method: 'GET',
        responseHandler: response => response.blob(),
      }),
    }),
  }),
});

export const {
  useCreateSchoolTestMutation,
  useUpdateSchoolTestMutation,
  useLazyGetAllTestsQuery,
  useLazyGetAllSubjectsQuery,
  useDeleteSchoolTestMutation,
  useLazyExportSchoolTestsResultQuery,
} = schoolTestApi;
