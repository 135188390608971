import React, { FC } from 'react';

import { useLocation } from 'react-router-dom';

import terms from 'i18n';

const currentYear = new Date().getUTCFullYear();

const Footer: FC = () => {
  const { pathname } = useLocation();

  return (
    <footer className="footer">
      <span>
        © {currentYear} {terms.SPBPU_ONLINE_APPLICATION_SYSTEM}
      </span>{' '}
      {(pathname === '/sign-up' || pathname === '/sign-in') && (
        <a href="mailto:support@spbstu.ru" className="footer__link">
          {terms.CONTACT_TECHNICAL_SUPPORT}
        </a>
      )}
    </footer>
  );
};

export default Footer;
