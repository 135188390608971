import { useGetMobileCountryCodesQuery } from '../../../redux/api/common/enum-api';
import { useGetCountriesSelectProps } from '../../common/api/useGetCountriesSelectProps';
import { useGetRecruiters } from '../../common/api/useGetRecruiters';
import useLazyEnumSelectProps from '../../common/useI18nEnumSelectProps';

export const useGetGeneralDataFormEnums = () => {
  const countrySelectProps = useGetCountriesSelectProps();
  const recruiterViews = useGetRecruiters();
  const mobileCountryCodeSelectProps = useLazyEnumSelectProps(
    useGetMobileCountryCodesQuery
  );

  return {
    countrySelectProps: {
      options: countrySelectProps,
    },
    recruiterViews,
    mobileCountryCodeSelectProps,
  };
};
