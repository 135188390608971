import React, { useRef, useEffect, FC } from 'react';

import classNames from 'classnames';

import { ReactComponent as FileIcon } from 'assets/images/icons/file.svg';
import { ReactComponent as CrossIcon } from 'assets/images/icons/red-cross.svg';
import { ReactComponent as FileDownloadIcon } from 'assets/images/icons/upload-file.svg';
import useFileUploaderComponent from 'hooks/document/useFileUploaderComponent';
import terms from 'i18n';

import { FileMetaView } from '../../../../hooks/files/useLazyUploadFiles';

export type FileUploaderProps = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'onChange' | 'onDrop' | 'onDelete'
> & {
  files: File[];
  filesMeta: FileMetaView[];
  disabled?: boolean;
  onChange: (files: File[]) => void;
  onDownloadFile: (fileMeta: FileMetaView) => void;
  onDrop: (files: File[]) => void;
  onDelete: (file: FileMetaView) => void;
  onError?: (error: string) => void;
  description?: string;
};

const LazyFileUploader: FC<FileUploaderProps> = ({
  files,
  filesMeta,
  disabled,
  className,
  onClick,
  onDownloadFile,
  onChange,
  onDrop,
  onDelete,
  description = terms.FILE_UPLOADER_DESCRIPTION,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const inputAreaRef = useRef<HTMLLabelElement>(null);

  const {
    handleChange,
    handleTriggerInput,
    handleDragEnter,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    isActive,
  } = useFileUploaderComponent(files, inputRef, onChange, onClick, onDrop);

  const addDndEventListeners = () => {
    if (inputAreaRef.current) {
      const inputArea = inputAreaRef.current;

      inputArea.addEventListener('dragenter', handleDragEnter);
      inputArea.addEventListener('dragover', handleDragOver);
      inputArea.addEventListener('dragleave', handleDragLeave);
      inputArea.addEventListener('drop', handleDrop);
    }
  };

  const removeDndEventListeners = () => {
    if (inputAreaRef.current) {
      const inputArea = inputAreaRef.current;

      inputArea.removeEventListener('dragenter', handleDragEnter);
      inputArea.removeEventListener('dragover', handleDragOver);
      inputArea.removeEventListener('dragleave', handleDragLeave);
      inputArea.removeEventListener('drop', handleDrop);
    }
  };

  useEffect(() => {
    addDndEventListeners();

    return () => {
      removeDndEventListeners();
    };
  }, [files]);

  return (
    <div className={classNames('file-uploader__wrapper', className)}>
      {!!filesMeta.length && (
        <div className="file-uploader__files">
          {filesMeta.map((fileMeta, index) => (
            <div key={index} className="file-uploader__file">
              <FileIcon
                className="file-uploader__file-icon"
                onClick={() => onDownloadFile(fileMeta)}
              />

              {!disabled && (
                <button
                  className="file-uploader__cross"
                  onClick={() => onDelete(fileMeta)}
                  type="button"
                >
                  <CrossIcon className="file-uploader__cross-icon" />
                </button>
              )}

              <span
                className="file-uploader__filename"
                onClick={() => onDownloadFile(fileMeta)}
                title={fileMeta.name}
              >
                {fileMeta.name}
              </span>
            </div>
          ))}
        </div>
      )}

      <label
        className={classNames(
          'file-uploader__area',
          {
            'file-uploader__active': isActive,
          },
          disabled && 'disabled'
        )}
        ref={inputAreaRef}
      >
        <input
          type="file"
          className="file-uploader"
          multiple
          disabled={disabled}
          onChange={handleChange}
          ref={inputRef}
        />

        <button
          className="file-uploader__button"
          onClick={handleTriggerInput}
          disabled={disabled}
          type="button"
        >
          <div className="file-uploader__info">
            <FileDownloadIcon width="18" height="24" />

            <div className="file-uploader__description">
              <div>{terms.FILE_UPLOADER_TITLE}</div>

              {!files.length && <div>{description}</div>}
            </div>

            <div className="file-uploader__mobile-description">
              {description}
            </div>
          </div>
        </button>
      </label>
    </div>
  );
};

export default LazyFileUploader;
