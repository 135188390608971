import React, { CSSProperties, ReactElement } from 'react';

import classnames from 'classnames';

import { SelectOption } from '../../../../models/common';

type SelectOneButtonOption<T = any> = SelectOption<T> & {
  icon?: any;
  className?: string;
};

type SelectOneButtonProps<T = any> = {
  options: SelectOneButtonOption<T>[];
  value: T;
  disabled?: boolean;
  onChange?: (option: SelectOneButtonOption | undefined) => void;
  size?: 'normal' | 'icons';
  className?: string;
  style?: CSSProperties | undefined;
  // multiple?: false; // not implemented
};

const SelectOneButton = <T,>({
  options,
  value,
  size = 'normal',
  disabled = false,
  className,
  onChange,
  ...rest
}: SelectOneButtonProps<T>): ReactElement => {
  const onSelect = (option: SelectOneButtonOption) => {
    onChange && onChange(option.value);
  };

  return (
    <div
      className={classnames(
        'select-one-button',
        `select-one-button--size-${size}`,
        disabled ? 'select-one-button--disabled' : '',
        className
      )}
      {...rest}
    >
      {options &&
        options.map((option, index) => {
          const selected = option.value === value;
          return (
            <button
              key={`${index} ${option.label}`}
              onClick={() => onSelect(option)}
              disabled={selected}
              className={classnames(
                'select-one-button__option',
                option.className,
                selected ? 'select-one-button__option--selected' : ''
              )}
            >
              {option.icon && (
                <i className="select-one-button__option-icon">{option.icon}</i>
              )}
              {size === 'normal' && option.label}
            </button>
          );
        })}
    </div>
  );
};

export default SelectOneButton;
