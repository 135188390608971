import React, { FC, ReactNode, useCallback, useMemo } from 'react';

import classNames from 'classnames';
import { components } from 'react-select';

import ReactSelect from 'components/common/ui-kit/Select/view';
import { useDebounce } from 'hooks/questionnaire/common/util/useDebounce';
import {
  DadataCountryIsoCode,
  DadataFmsUnit,
  DadataSuggestion,
} from 'models/questionnaire/dadata';
import {
  useGetDadataFmsSuggestionsQuery,
  useGetDadataSuggestionsQuery,
} from 'redux/api/questionnaire/dadata-api';

const Input = props => <components.Input {...props} isHidden={false} />;

export type AutocompleteOption<T> = {
  label: string;
  value: T;
};

export type DadataAutocompleteProps<T> = {
  className?: string;
  inputValue: string;
  onInputValueChange: (e) => void;
  onSelect: (value?: AutocompleteOption<T>) => void;
  placeholder?: string;
  markRequired?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  value?: AutocompleteOption<T> | null;
  options?: AutocompleteOption<T>[];
  error?: string;
  resetOnBlur?: boolean;
  isOptionListShown?: boolean;
  formatOptionLabel?: (option: AutocompleteOption<T>) => ReactNode;
};

const DadataAutocomplete = <T,>({
  value,
  options,
  onSelect,
  placeholder,
  inputValue,
  onInputValueChange,
  markRequired,
  isDisabled,
  isLoading,
  error,
  resetOnBlur = false,
  isOptionListShown = true,
  className,
  formatOptionLabel,
}: DadataAutocompleteProps<T>) => {
  // Без этого не работает: не обновляются опции в дропдауне!!!
  const filterOption = useCallback(() => true, []);

  const handleInputChange = (newInputValue, { action }) => {
    if (action === 'input-blur') {
      onInputValueChange(value ? value.label : resetOnBlur ? '' : inputValue);
      return;
    }

    if (action === 'input-change') {
      onInputValueChange(newInputValue);
    }
  };

  const handleSelect = option => {
    onSelect(option);
    onInputValueChange(option ? option.label : '');
  };

  return (
    <ReactSelect
      className={classNames('dadata-autocomplete', className)}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      blurInputOnSelect={false}
      menuIsOpen={isOptionListShown ? undefined : false}
      options={options}
      isClearable
      placeholder={placeholder}
      onChange={handleSelect}
      value={value}
      components={{
        Input,
        DropdownIndicator: null,
      }}
      markRequired={markRequired}
      isDisabled={isDisabled}
      isLoading={isLoading}
      error={error}
      // Без этого не работает: не обновляются опции в дропдауне!!!
      filterOption={filterOption}
      formatOptionLabel={formatOptionLabel}
    />
  );
};

export type DadataAddressAutocompleteProps<T> = Omit<
  DadataAutocompleteProps<T>,
  'options' | 'isLoading'
> & { countryIsoCode: DadataCountryIsoCode };

export const DadataAddressAutocomplete: FC<
  DadataAddressAutocompleteProps<DadataSuggestion>
> = ({ inputValue, countryIsoCode, ...rest }) => {
  const debouncedSearch = useDebounce(inputValue, 500);
  const isOptionListShown = debouncedSearch.length >= 3;
  const { data: dadataResponse, isLoading } = useGetDadataSuggestionsQuery(
    {
      query: debouncedSearch,
      locations: countryIsoCode ? [{ countryIsoCode }] : undefined,
    },
    {
      skip: !isOptionListShown,
    }
  );

  const selectOptions = useMemo(() => {
    if (!dadataResponse) return [];
    return dadataResponse.suggestions.map(suggestion => ({
      label: suggestion.value,
      value: suggestion,
    }));
  }, [dadataResponse]);

  return (
    <DadataAutocomplete
      inputValue={inputValue}
      options={selectOptions}
      isLoading={isLoading}
      isOptionListShown={isOptionListShown}
      {...rest}
    />
  );
};

export type DadataFmsAutocompleteProps<T> = Omit<
  DadataAutocompleteProps<T>,
  'options' | 'isLoading'
>;

export const DadataFmsAutocomplete: FC<
  DadataFmsAutocompleteProps<DadataFmsUnit>
> = ({ inputValue, ...rest }) => {
  const debouncedSearch = useDebounce(inputValue, 500);
  const isOptionListShown = debouncedSearch.length >= 3;
  const { data: dadataResponse, isLoading } = useGetDadataFmsSuggestionsQuery(
    debouncedSearch,
    {
      skip: !isOptionListShown,
    }
  );

  const selectOptions = useMemo(() => {
    if (!dadataResponse) return [];
    return dadataResponse.suggestions.map(suggestion => ({
      label: `${suggestion.value}`,
      value: suggestion,
    }));
  }, [dadataResponse]);

  return (
    <DadataAutocomplete
      inputValue={inputValue}
      options={selectOptions}
      isLoading={isLoading}
      isOptionListShown={isOptionListShown}
      {...rest}
      formatOptionLabel={option =>
        `${option.label} (${option.value.data.code})`
      }
    />
  );
};

export type DadataRegionAutocompleteProps<T> = Omit<
  DadataAutocompleteProps<T>,
  'options' | 'isLoading'
>;

export const DadataRegionAutocomplete: FC<
  DadataAddressAutocompleteProps<DadataSuggestion>
> = ({ inputValue, ...rest }) => {
  const debouncedSearch = useDebounce(inputValue, 500);
  const isOptionListShown = debouncedSearch.length >= 3;
  const { data: dadataResponse, isLoading } = useGetDadataSuggestionsQuery(
    debouncedSearch,
    {
      skip: !isOptionListShown,
    }
  );

  const selectOptions = useMemo(() => {
    if (!dadataResponse) return [];
    return dadataResponse.suggestions.map(suggestion => ({
      label: suggestion.value,
      value: suggestion,
    }));
  }, [dadataResponse]);

  return (
    <DadataAutocomplete
      inputValue={inputValue}
      options={selectOptions}
      isLoading={isLoading}
      isOptionListShown={isOptionListShown}
      {...rest}
    />
  );
};
