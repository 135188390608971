import { useEffect, useState } from 'react';

import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import useAppSelector from 'hooks/common/useAppSelector';
import { isRussianLocale } from 'i18n';
import {
  selectCurrentEducationLevel,
  selectCurrentTrajectory,
} from 'redux/selectors/enrollee/application';

import {
  SchoolTestType,
  SubjectResponse,
  UserSchoolTestResponse,
} from '../../../models/applications/school-tests';
import { SelectOption } from '../../../models/common';
import { createToast } from '../../../redux/actions';
import {
  useCreateSchoolTestMutation,
  useDeleteSchoolTestMutation,
  useLazyGetAllSubjectsQuery,
  useLazyGetAllTestsQuery,
  useUpdateSchoolTestMutation,
} from '../../../redux/api/applications/school-test-api';
import useAppDispatch from '../../common/useAppDispatch';

type CommonTestValues = {
  readonly testId: number | undefined;
  readonly subjectId: SelectOption | undefined;
  readonly score: number | undefined;
  readonly year: SelectOption | undefined;
};

type TestValuesWithoutOldDocument = {
  readonly wasAnotherPassportInUse: false;
} & CommonTestValues;

export type TestValuesWithOldDocument = {
  readonly wasAnotherPassportInUse: true;
  readonly identificationDocumentType: SelectOption;
  readonly identificationDocumentSerial: string;
  readonly identificationDocumentNumber: string;
  readonly surname: string;
  readonly name: string;
  readonly patronymic: string;
} & CommonTestValues;

export type Test = TestValuesWithoutOldDocument | TestValuesWithOldDocument;

const isDocumentSpecified = (test: Test): test is TestValuesWithOldDocument => {
  return (
    test.wasAnotherPassportInUse &&
    !!test.identificationDocumentType &&
    !!test.identificationDocumentNumber &&
    !!test.surname &&
    !!test.name
  );
};

type SchoolTestForm = {
  readonly hasTestResults: boolean;
  readonly tests: Test[];
};

export const useSchoolTestForm = (
  onSuccess: () => void,
  options: SelectOption[]
) => {
  const { applicationId } = useParams();

  const dispatch = useAppDispatch();

  const [getAllSubjects] = useLazyGetAllSubjectsQuery();
  const [getAllTests] = useLazyGetAllTestsQuery();
  const [createTest] = useCreateSchoolTestMutation();
  const [updateTest] = useUpdateSchoolTestMutation();
  const [deleteTest] = useDeleteSchoolTestMutation();

  const [fetchedSubjects, setFetchedSubjects] = useState<SubjectResponse[]>([]);
  const [fetchedTests, setFetchedTests] = useState<UserSchoolTestResponse[]>(
    []
  );
  const trainingLevel = useAppSelector(selectCurrentEducationLevel);
  const trajectory = useAppSelector(selectCurrentTrajectory);

  const {
    register,
    watch,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SchoolTestForm>({ mode: 'all' });

  const {
    fields: testFields,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: 'tests',
  });

  const tests = watch('tests');

  const onWasAnotherPassportInUseChange = (index: number) => {
    const firstTestWithOldDoc = tests.find(
      test => test.wasAnotherPassportInUse && test.identificationDocumentType
    );
    update(index, {
      ...(firstTestWithOldDoc ?? {}),
      ...tests[index],
    });
  };

  useEffect(() => {
    if (options.length === 0) return;
    getAllSubjects(Number(applicationId))
      ?.unwrap()
      .then(subjects => {
        setFetchedSubjects(subjects);
      });

    getAllTests(Number(applicationId))
      ?.unwrap()
      .then(schoolTests => {
        if (schoolTests.length === 0) {
          setValue('hasTestResults', true);
        } else {
          setValue('hasTestResults', false);
          setFetchedTests(schoolTests);
          setValue(
            'tests',
            schoolTests.map(schoolTest => {
              const testFields = {
                testId: schoolTest.id,
                subjectId: {
                  label: isRussianLocale()
                    ? schoolTest.subject.title
                    : schoolTest.subject.titleForeign,
                  value: schoolTest.subject.id,
                },
                score: schoolTest.score,
                year: {
                  label: schoolTest.year + '',
                  value: schoolTest.year,
                },
              };

              return schoolTest.oldDocumentResponse
                ? ({
                    ...testFields,
                    wasAnotherPassportInUse: true,
                    identificationDocumentType: options.find(
                      value =>
                        value.value ===
                        schoolTest.oldDocumentResponse?.documentType
                    ),
                    identificationDocumentSerial:
                      schoolTest.oldDocumentResponse.oldDocumentSerial,
                    identificationDocumentNumber:
                      schoolTest.oldDocumentResponse.oldDocumentNumber,
                    surname: schoolTest.oldDocumentResponse.oldSecondName,
                    name: schoolTest.oldDocumentResponse.oldFirstName,
                    patronymic: schoolTest.oldDocumentResponse.oldMiddleName,
                  } as TestValuesWithOldDocument)
                : ({
                    wasAnotherPassportInUse: false,
                    ...testFields,
                  } as TestValuesWithoutOldDocument);
            })
          );
        }
      });
  }, [trainingLevel, trajectory, options]);

  const fields = {
    hasTestResults: register('hasTestResults', {
      onChange: event => {
        const { checked } = event.target;
        if (checked) {
          setValue('tests', []);
        }
      },
    }),

    testsScore: index =>
      register(`tests.${index}.score`, {
        valueAsNumber: true,
        // required: terms.REQUIRED_FIELD,
        onChange: event => {
          if (event.target.valueAsNumber > 100) {
            setValue(`tests.${index}.score`, 100);
          }
          if (event.target.valueAsNumber < 0) {
            setValue(`tests.${index}.score`, 0);
          }
        },
      }),
  };

  const onTestAppend = () =>
    append(
      {
        testId: undefined,
        score: undefined,
        subjectId: undefined,
        year: undefined,
        wasAnotherPassportInUse: false,
      },
      {
        shouldFocus: false,
      }
    );

  const onSubjectChange = (field, index) => e => {
    if (e) {
      field.onChange(e);
      setValue(`tests.${index}.score`, undefined);
    }
  };

  const onTestRemove = (index, id) => {
    remove(index);
    if (id) deleteTest(id);
  };

  const onSubmit = handleSubmit(data => {
    if (getUniqueTests(data.tests).length !== data.tests.length) {
      dispatch(createToast('Duplicated subjects', 'danger'));
      return;
    }

    const promises: Promise<any>[] = [];
    data.tests.forEach(test => {
      const oldDocumentRequest = isDocumentSpecified(test)
        ? {
            documentType: test.identificationDocumentType.value,
            oldDocumentSerial: test.identificationDocumentSerial,
            oldDocumentNumber: test.identificationDocumentNumber,
            oldSecondName: test.surname,
            oldFirstName: test.name,
            oldMiddleName: test.patronymic,
          }
        : undefined;
      const score =
        test.score !== null && test.score !== undefined && !isNaN(test.score)
          ? test.score
          : undefined;
      if (fetchedTests.find(schoolTest => schoolTest.id === test.testId)) {
        const subjectId = test.subjectId?.value;
        if (!subjectId) return;
        promises.push(
          updateTest({
            id: test.testId ?? -1,
            year: test.year?.value,
            score,
            centralTestingDocumentId: 0,
            subjectId,
            oldDocumentRequest,
          }).unwrap()
        );
      } else {
        promises.push(
          createTest({
            applicationId: Number(applicationId),
            subjectId: test.subjectId?.value,
            year: test.year?.value,
            score,
            testType: SchoolTestType.UPPER_SECONDARY,
            centralTestingDocumentId: 0,
            oldDocumentRequest,
          }).unwrap()
        );
      }
    });

    Promise.all(promises).then(() => onSuccess());
  });

  return {
    onSubmit,
    testFields,
    fields,
    append: onTestAppend,
    onSubjectChange,
    remove: onTestRemove,
    control,
    register,
    errors,
    hasTestResults: watch('hasTestResults'),
    educationLevel: trainingLevel,
    watch,
    onWasAnotherPassportInUseChange,
  };
};

const getUniqueTests = (tests: Test[]) =>
  tests.filter(
    (v, i, a) =>
      a.findIndex(value => value.subjectId?.value === v.subjectId?.value) === i
  );
