import React, { FC, useState } from 'react';

import { Controller } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';

import Button from '../../../components/common/ui-kit/Button';
import Checkbox from '../../../components/common/ui-kit/Checkbox';
import { InputV2 } from '../../../components/common/ui-kit/Input/view';
import useAppSelector from '../../../hooks/common/useAppSelector';
import useExpertExaminationResultForm from '../../../hooks/expert/useExpertExaminationResultForm';
import { useExpertExaminationResultFormProps } from '../../../hooks/expert/useExpertExaminationResultFormProps';
import { EducationSystemStatus } from '../../../models/applications/applications';
import {
  selectCurrentApplication,
  selectCurrentQuestionnaire,
} from '../../../redux/selectors/staff/applications';
import { createInputV2Props } from '../../../utils/form-helpers';

const ExpertExaminationResultPage: FC = () => {
  const application = useAppSelector(selectCurrentApplication);
  const questionnaire = useAppSelector(selectCurrentQuestionnaire);

  const fullNameEn = (
    surnameEn?: string,
    nameEn?: string,
    patronymicEn?: string
  ) => `${surnameEn ?? ''} ${nameEn ?? ''} ${patronymicEn ?? ''}`.trim();

  const {
    control,
    getValues,
    setValue,
    fields,
    onSubmit,
    formState: { errors },
  } = useExpertExaminationResultForm(
    application?.id ?? 0,
    fullNameEn(
      questionnaire?.generalDataForm.surnameLatin,
      questionnaire?.generalDataForm.nameLatin,
      questionnaire?.generalDataForm.patronymicLatin
    ),
    questionnaire?.generalDataForm.primaryCitizenshipCountry?.title ?? ''
  );

  const { educationLevelSelectOptions, educationSystemStatusOptions } =
    useExpertExaminationResultFormProps();

  const inputV2Props = createInputV2Props(errors);

  const [
    educationSystemStateCommentAllowed,
    setEducationSystemStateCommentAllowed,
  ] = useState(false);

  const normalizeRegistrationNumber = (value: string) => {
    const numberParts = value.replace(/\D/g, '').match(/.{1,4}/g) ?? [];

    if (numberParts.length === 0) return '';
    else return `Э-${numberParts.join('/').substring(0, 7)}`;
  };

  return (
    <form onSubmit={onSubmit}>
      <div>
        <Controller
          name={fields.registrationNumber.name}
          control={control}
          rules={{
            onChange: ({ target: { value } }) =>
              value &&
              setValue(
                'registrationNumber',
                normalizeRegistrationNumber(value)
              ),
          }}
          render={({ field }) => (
            <div className="expert-examination-result-page__block">
              <InputV2
                className="expert-examination-result-page__field"
                placeholder="Регистрационный номер"
                markRequired
                {...inputV2Props(field)}
              />
            </div>
          )}
        />

        <Controller
          name={fields.applicantFullName.name}
          control={control}
          render={({ field }) => (
            <div className="expert-examination-result-page__block">
              <InputV2
                className="expert-examination-result-page__field"
                placeholder="Фамилия и имена обладателя(ьницы)"
                markRequired
                {...inputV2Props(field)}
              />
            </div>
          )}
        />

        <Controller
          name={fields.educationCountryName.name}
          control={control}
          render={({ field }) => (
            <div className="expert-examination-result-page__block">
              <InputV2
                className="expert-examination-result-page__field"
                placeholder="Страна выдачи ИДО"
                markRequired
                {...inputV2Props(field)}
              />
            </div>
          )}
        />

        <Controller
          name={fields.educationSystemStatus.name}
          control={control}
          render={({ field }) => (
            <div className="expert-examination-result-page__block">
              <InputV2
                hidden={true}
                placeholder="Образовательная организация, выдавшая представленные ИДО:"
              />
              {educationSystemStatusOptions.map(opt => (
                <>
                  <Checkbox
                    className="expert-examination-result-page__field"
                    text={opt.label}
                    onChange={e => {
                      setValue(
                        'educationSystemStatus',
                        e.target.checked ? opt.value : undefined
                      );
                      setEducationSystemStateCommentAllowed(
                        getValues('educationSystemStatus') ===
                          EducationSystemStatus.PART_OF_EDUCATION_SYSTEM
                      );
                    }}
                    checked={field.value === opt.value}
                  />
                  {opt.value ===
                    EducationSystemStatus.PART_OF_EDUCATION_SYSTEM && (
                    <Controller
                      name={fields.educationSystemStatusComment.name}
                      control={control}
                      render={({ field }) => (
                        <TextareaAutosize
                          className="expert-examination-result-page__comment-input"
                          {...field}
                          placeholder="Комментарий"
                          maxLength={2048}
                          disabled={!educationSystemStateCommentAllowed}
                        />
                      )}
                    />
                  )}
                </>
              ))}
            </div>
          )}
        />

        <Controller
          name={fields.needNegotiations.name}
          control={control}
          render={({ field }) => (
            <div className="expert-examination-result-page__block">
              <InputV2 hidden={true} placeholder="С обладателем(ьницей) ИДО:" />
              <Checkbox
                className="expert-examination-result-page__field"
                text="Можно вести дальнейшие переговоры об обучении в СПбПУ"
                onChange={e => {
                  setValue(
                    'needNegotiations',
                    e.target.checked ? true : undefined
                  );
                }}
                checked={field.value === true}
              />
              <Checkbox
                className="expert-examination-result-page__field"
                text="Дальнейшие переговоры об обучении в СПбПУ нецелесообразны"
                onChange={e => {
                  setValue(
                    'needNegotiations',
                    e.target.checked ? false : undefined
                  );
                }}
                checked={field.value === false}
              />
            </div>
          )}
        />

        <Controller
          name={fields.educationLevelsInfo.name}
          control={control}
          render={({ field }) => (
            <div className="expert-examination-result-page__block">
              <InputV2
                hidden={true}
                placeholder="Обладатель(ница) документов может претендовать на отмеченное или более
          низкий уровень образования:"
              />
              {educationLevelSelectOptions.map(opt => (
                <>
                  <Checkbox
                    className="expert-examination-result-page__field"
                    text={opt.label}
                    onChange={e => {
                      field.value[opt.value].checked = e.target.checked;
                      setValue('educationLevelsInfo', field.value);
                    }}
                    checked={field.value[opt.value].checked === true}
                  />
                  <TextareaAutosize
                    className="expert-examination-result-page__comment-input"
                    placeholder="Комментарии"
                    maxLength={100}
                    onChange={e => {
                      field.value[opt.value].comment = e.target.value;
                    }}
                  />
                </>
              ))}
            </div>
          )}
        />

        <Controller
          name={fields.disciplineTransferAllowed.name}
          control={control}
          render={({ field }) => (
            <div className="expert-examination-result-page__block">
              <InputV2 hidden={true} placeholder="Особые отметки:" />
              <Checkbox
                className="expert-examination-result-page__field"
                text="Возможен перезачёт дисциплин с последующим переводом документообладателя(ьницы) на старшие курсы программы обучения"
                {...(field as object)}
                checked={field.value === true}
              />
            </div>
          )}
        />
        <Controller
          name={fields.comments.name}
          control={control}
          render={({ field }) => (
            <div className="expert-examination-result-page__block">
              <TextareaAutosize
                className="expert-examination-result-page__comment-input"
                {...(field as object)}
                placeholder="Комментарии"
                maxLength={2048}
              />
            </div>
          )}
        />
        <Controller
          name={fields.formationDate.name}
          control={control}
          render={({ field }) => (
            <div className="expert-examination-result-page__block">
              <InputV2
                type="date"
                className="expert-examination-result-page__field"
                markRequired
                placeholder="Дата составления заключения"
                {...inputV2Props(field)}
              />
            </div>
          )}
        />
        <Button
          theme="success"
          className="expert-examination-result-page__send-button"
        >
          Сохранить результаты предварительной экспертизы
        </Button>
      </div>
    </form>
  );
};

export default ExpertExaminationResultPage;
