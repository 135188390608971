import React, { useEffect } from 'react';

import classNames from 'classnames';

import { ReactComponent as CheckMarkIcon } from 'assets/images/icons/check-mark.svg';
import { ReactComponent as ExclamationMarkIcon } from 'assets/images/icons/exclamation-mark.svg';
import useAppDispatch from 'hooks/common/useAppDispatch';
import { Toast as ToastType } from 'models/toast';
import { deleteToast } from 'redux/actions';

type ToastProps = ToastType & {
  onClose: (toastId: ToastType['id']) => void;
};

const Toast = ({ id, theme, text, onClose, time = 5000 }: ToastProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (time === null) return;
    const timeoutId = setTimeout(() => dispatch(deleteToast(id)), time);
    return () => void clearTimeout(timeoutId);
  }, []);

  return (
    <div className={classNames('toast', `toast--theme-${theme}`)}>
      <div className="toast__body">
        <div>
          {theme === 'danger' ? (
            <ExclamationMarkIcon
              className="toast__icon"
              width={16}
              height={17}
            />
          ) : (
            <CheckMarkIcon className="toast__icon" width={16} height={17} />
          )}
        </div>
        <span className="toast__text">{text}</span>
      </div>
      <button
        className={classNames('toast__button', `toast__button--theme-${theme}`)}
        onClick={() => onClose(id)}
      >
        x
      </button>
    </div>
  );
};

export default Toast;
