import React, { FC } from 'react';

import { Controller } from 'react-hook-form';

import Button from 'components/common/ui-kit/Button';
import ReactSelect from 'components/common/ui-kit/Select';
import { DadataAddressAutocomplete } from 'components/profile/AddressesForm/dadata';
import ProfileSection from 'components/profile/ProfileSection';
import FillingStatementsForm from 'components/statements/FillingStatementsForm';
import terms from 'i18n';
import { SelectOption } from 'models/common';
import { DadataCountryIsoCode } from 'models/questionnaire/dadata';
import { useIsImpersonate } from 'utils/domain/impersonate';

import useJuridicalContractForm from '../../../../hooks/statements/contract/useJuridicalContractForm';
import { Contract } from '../../../../models/applications/contracts';
import { createInputV2Props } from '../../../../utils/form-helpers';
import {
  getBankIdentifierCode,
  getCheckingAccount,
  getCorrespondentAccount,
} from '../../../../utils/form-validators';
import Input, { InputV2 } from '../../../common/ui-kit/Input/view';

type JuridicalContractFormProps = {
  readonly contract?: Contract;
  readonly isFillingStatementsForm?: boolean;
  onSubmit?: (res) => void;
};

const JuridicalContractForm: FC<JuridicalContractFormProps> = ({
  contract,
  isFillingStatementsForm = true,
  onSubmit: onSubmitProp,
}) => {
  const isImpersonate = useIsImpersonate();
  const isDateFieldShown = !!contract || isImpersonate;

  const {
    control,
    onSubmit,
    paymentForms,
    directionOptions,
    formState: { errors },
    fields,
  } = useJuridicalContractForm(
    isDateFieldShown,
    contract,
    isFillingStatementsForm,
    onSubmitProp
  );

  const inputV2Props = createInputV2Props(errors);

  const formBody = (
    <>
      <ProfileSection title="">
        <div className="profile-form__fields">
          <Controller
            name="directionId"
            control={control}
            rules={{
              required: terms.REQUIRED_FIELD,
            }}
            render={({ field }) => (
              <ReactSelect<SelectOption>
                options={directionOptions}
                placeholder={terms.DIRECTIONS_TRAINING}
                className="profile-form__field"
                markRequired
                {...(field as object)}
                error={errors.directionId?.message}
                isDisabled={Boolean(contract)}
              />
            )}
          />

          <Controller
            name="paymentForm"
            control={control}
            rules={{
              required: terms.REQUIRED_FIELD,
            }}
            render={({ field }) => (
              <ReactSelect<SelectOption>
                options={paymentForms}
                placeholder={terms.CONTRACT_PAYMENT_TYPE}
                className="profile-form__field"
                markRequired
                {...(field as object)}
                error={errors.paymentForm?.message}
              />
            )}
          />

          {isDateFieldShown && (
            <Input
              className="profile-form__field"
              placeholder={terms.DATE_ISSUE}
              type="date"
              markRequired
              {...fields.contractDate}
              error={errors.contractDate?.message}
            />
          )}
        </div>

        <div className="profile-form__fields">
          <Controller
            name="organizationName"
            control={control}
            rules={{
              required: terms.REQUIRED_FIELD,
            }}
            render={({ field }) => (
              <InputV2
                className="profile-form__field"
                placeholder={terms.ORGANISATION_NAME}
                markRequired
                {...inputV2Props(field)}
              />
            )}
          />
          <Controller
            name="organizationShortName"
            control={control}
            render={({ field }) => (
              <InputV2
                placeholder={terms.ORGANISATION_SHORT_NAME}
                className="profile-form__field"
                {...inputV2Props(field)}
              />
            )}
          />
          <Controller
            name="generalManager"
            control={control}
            rules={{
              required: terms.REQUIRED_FIELD,
            }}
            render={({ field }) => (
              <InputV2
                placeholder={terms.IN_NAME}
                className="profile-form__field"
                markRequired
                {...inputV2Props(field)}
              />
            )}
          />
          <Controller
            name="managementBases"
            control={control}
            rules={{
              required: terms.REQUIRED_FIELD,
            }}
            render={({ field }) => (
              <InputV2
                className="profile-form__field"
                placeholder={terms.MANAGER_BASES}
                markRequired
                {...inputV2Props(field)}
              />
            )}
          />
          <Controller
            name="address"
            rules={{
              required: terms.REQUIRED_FIELD,
            }}
            control={control}
            render={({ field: { onChange, value, disabled } }) => (
              <DadataAddressAutocomplete
                className="profile-form__field"
                placeholder={terms.JURIDICAL_ADDR}
                countryIsoCode={DadataCountryIsoCode.RU}
                value={null}
                onSelect={option => {
                  onChange(option?.label);
                }}
                onInputValueChange={value => {
                  onChange(value);
                }}
                inputValue={value ?? ''}
                isDisabled={disabled}
                markRequired
                error={errors['address']?.message as string}
              />
            )}
          />
          <Controller
            name="inn"
            control={control}
            rules={{
              required: terms.REQUIRED_FIELD,
            }}
            render={({ field }) => (
              <InputV2
                placeholder={terms.INN}
                className="profile-form__field"
                markRequired
                {...inputV2Props(field)}
              />
            )}
          />
          <Controller
            name="kpp"
            control={control}
            rules={{
              required: terms.REQUIRED_FIELD,
            }}
            render={({ field }) => (
              <InputV2
                placeholder={terms.KPP}
                className="profile-form__field"
                markRequired
                {...inputV2Props(field)}
              />
            )}
          />
          <Controller
            name="paymentAccount"
            control={control}
            rules={{
              required: terms.REQUIRED_FIELD,
              ...getCheckingAccount(),
            }}
            render={({ field }) => (
              <InputV2
                placeholder={terms.PAYMENT_ACC}
                className="profile-form__field"
                markRequired
                {...inputV2Props(field)}
              />
            )}
          />
          <Controller
            name="bankName"
            control={control}
            rules={{
              required: terms.REQUIRED_FIELD,
            }}
            render={({ field }) => (
              <InputV2
                placeholder={terms.BANK_NAME}
                className="profile-form__field"
                markRequired
                {...inputV2Props(field)}
              />
            )}
          />
          <Controller
            name="bankBic"
            control={control}
            rules={{
              required: terms.REQUIRED_FIELD,
              ...getBankIdentifierCode(),
            }}
            render={({ field }) => (
              <InputV2
                placeholder={terms.BANK_BIC}
                className="profile-form__field"
                markRequired
                {...inputV2Props(field)}
              />
            )}
          />
          <Controller
            name="correspondentAccount"
            control={control}
            rules={{
              required: terms.REQUIRED_FIELD,
              ...getCorrespondentAccount(),
            }}
            render={({ field }) => (
              <InputV2
                placeholder={terms.CORRESPONDENT_ACC}
                className="profile-form__field"
                markRequired
                {...inputV2Props(field)}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            rules={{
              required: terms.REQUIRED_FIELD,
            }}
            render={({ field }) => (
              <InputV2
                placeholder={terms.PHONE_NUMBER}
                type="tel"
                className="profile-form__field"
                markRequired
                {...inputV2Props(field)}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{
              required: terms.REQUIRED_FIELD,
            }}
            render={({ field }) => (
              <InputV2
                placeholder={terms.EMAIL}
                type="email"
                className="profile-form__field"
                markRequired
                {...inputV2Props(field)}
              />
            )}
          />
        </div>
      </ProfileSection>
      <Button className="create-contract-form__button" theme="success">
        {contract ? terms.EDIT : terms.CREATE_CONTRACT}
      </Button>
    </>
  );

  if (isFillingStatementsForm) {
    return (
      <FillingStatementsForm
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        {formBody}
      </FillingStatementsForm>
    );
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onSubmit();
      }}
    >
      {formBody}
    </form>
  );
};

export default JuridicalContractForm;
