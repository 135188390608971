import terms from '../../../i18n';
import { createToast } from '../../../redux/actions';
import { downloadFileWithName } from '../../../redux/api/common/files-api';
import { downloadFile } from '../../../utils/common';
import useAppDispatch from '../useAppDispatch';

export const useDownloadFileRaw = () => {
  const dispatch = useAppDispatch();

  return (userId: number, fileId: number, defaultFileName: string) =>
    downloadFileWithName(userId, fileId)
      .then(({ fileName, blob }) => {
        downloadFile(new File([blob], fileName ?? defaultFileName));
      })
      .catch(() => {
        dispatch(createToast(terms.DOWNLOAD_FILE_ERROR, 'danger'));
      });
};
