import React, { FC } from 'react';

import { Controller } from 'react-hook-form';

import TemplateModal from 'components/common/modals/TemplateModal';
import Button from 'components/common/ui-kit/Button';
import Input from 'components/common/ui-kit/Input';
import ReactSelect from 'components/common/ui-kit/Select';
import ProfileSection from 'components/profile/ProfileSection';
import useAppSelector from 'hooks/common/useAppSelector';
import useAddOlympiadForm from 'hooks/statements/achievements/useAddOlympiadForm';
import { SelectOption } from 'models/common';
import { modalDataSelector } from 'redux/selectors';

import LazyFileUploader from '../../ui-kit/LazyFileUploader';

const AddOlympiadModal: FC = () => {
  const {
    applicationId,
    olympiad,
    educationLevel,
    refetchAchievementsForm,
    userId,
  } = useAppSelector(modalDataSelector);

  const {
    control,
    fields,
    onSubmit,
    yearSelectOptions,
    olympiadTypesSelectOptions,
    confirmationDocsSelectOptions,
    fileProps,
    formState: { errors },
  } = useAddOlympiadForm(
    applicationId,
    olympiad,
    educationLevel,
    refetchAchievementsForm,
    userId
  );

  return (
    <TemplateModal
      title={olympiad ? 'Редактировать олимпиаду' : 'Добавить олимпиаду'}
      hint={
        'Если олимпиады нет в выпадающем списке, значит она не может быть учтена» в разделе «Достижения» личного кабинета абитуриента'
      }
    >
      <form onSubmit={onSubmit}>
        <div className="profile-form__fields">
          <Controller
            name={fields.year.name}
            control={control}
            render={({ field }) => (
              <ReactSelect
                options={yearSelectOptions}
                placeholder="Год проведения"
                className="profile-form__field"
                markRequired
                {...(field as object)}
                error={errors.year?.message}
              />
            )}
          />

          <Controller
            name={fields.olympiadType.name}
            control={control}
            render={({ field }) => (
              <ReactSelect<SelectOption>
                options={olympiadTypesSelectOptions}
                placeholder="Название олимпиады"
                className="profile-form__field"
                markRequired={true}
                {...(field as object)}
                isDisabled={!olympiadTypesSelectOptions.length}
                error={errors.olympiadType?.message}
              />
            )}
          />

          <Controller
            name={fields.confirmationDocType.name}
            control={control}
            render={({ field }) => (
              <ReactSelect<SelectOption>
                options={confirmationDocsSelectOptions}
                placeholder="Подтверждающий документ"
                className="profile-form__field"
                markRequired={true}
                {...(field as object)}
                isDisabled={!confirmationDocsSelectOptions.length}
                error={errors.confirmationDocType?.message}
              />
            )}
          />

          <Input
            className="profile-form__field"
            placeholder="Серия (если есть) и номер документа"
            markRequired={true}
            {...fields.number}
            error={errors.number?.message}
          />

          <Input
            className="profile-form__field"
            placeholder="Дата выдачи"
            markRequired={true}
            type="date"
            {...fields.dateIssue}
            error={errors.dateIssue?.message}
          />

          <Input
            className="profile-form__field"
            placeholder="Дополнительная информация"
            {...fields.comment}
            error={errors.comment?.message}
          />
        </div>

        <ProfileSection title="Документ" markRequired={true}>
          <LazyFileUploader
            className="profile-form__file-uploader"
            {...fileProps}
          />
          {errors.wasDocumentAttached?.message && (
            <div className="profile-form__error">
              {errors.wasDocumentAttached?.message}
            </div>
          )}
        </ProfileSection>
        <Button
          theme="success"
          className="profile-form__button profile-form__button--align-left"
        >
          Сохранить
        </Button>
      </form>
    </TemplateModal>
  );
};

export default AddOlympiadModal;
