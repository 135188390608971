import React, { FC, useEffect } from 'react';

import { Tooltip } from 'react-tooltip';

import { EpguWarning } from 'components/auth/SignUpForm/view';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';

import {
  ApplicationEducationLevel,
  Trajectory,
} from '../../../models/applications/enum';
import { applicationsInfoApi } from '../../../redux/api/applications/applications-info-api';
import {
  selectCurrentEducationLevel,
  selectCurrentTrajectory,
} from '../../../redux/selectors/enrollee/application';
import { applicationSlice } from '../../../redux/slices/enrollee/application-slice';
import Loader from '../../common/ui-kit/Loader';
import { educationLevelToText } from '../ApplicationsList/view';
import LevelListElement from './LevelListElement';

export const trainingLevels: ApplicationEducationLevel[] = [
  ApplicationEducationLevel.SECONDARY,
  ApplicationEducationLevel.BACHELOR,
  ApplicationEducationLevel.MASTER,
  ApplicationEducationLevel.GRADUATE,
  ApplicationEducationLevel.PREPARATORY,
];

export const hiddenLocalTrainingLevels: ApplicationEducationLevel[] = process
  .env.REACT_APP_HIDDEN_LOCAL_TRAINING_LEVELS
  ? JSON.parse(process.env.REACT_APP_HIDDEN_LOCAL_TRAINING_LEVELS)
  : [];

export const hiddenForeignTrainingLevels: ApplicationEducationLevel[] = process
  .env.REACT_APP_HIDDEN_FOREIGN_TRAINING_LEVELS
  ? JSON.parse(process.env.REACT_APP_HIDDEN_FOREIGN_TRAINING_LEVELS)
  : [];

export const hiddenQuotaTrainingLevels: ApplicationEducationLevel[] = process
  .env.REACT_APP_HIDDEN_QUOTA_TRAINING_LEVELS
  ? JSON.parse(process.env.REACT_APP_HIDDEN_QUOTA_TRAINING_LEVELS)
  : [];

const trajectoryToHiddenTrainingLevels: Record<
  Trajectory,
  ApplicationEducationLevel[]
> = {
  LOCAL: hiddenLocalTrainingLevels,
  FOREIGN: hiddenForeignTrainingLevels,
  QUOTA: hiddenQuotaTrainingLevels,
};

const LevelStatementsList: FC = () => {
  const dispatch = useAppDispatch();
  const { id: userId } = useAppSelector(state => state.user);

  const [getAvailableEducationLevels, { data: educationLevels }] =
    applicationsInfoApi.useLazyGetPossibleEducationLevelsForUserQuery();

  const currentLevel = useAppSelector(selectCurrentEducationLevel);
  const trajectory = useAppSelector(selectCurrentTrajectory);

  const setActive = value => {
    dispatch(applicationSlice.actions.setCurrentTrainingLevel(value));
  };

  useEffect(() => {
    if (userId && trajectory) {
      getAvailableEducationLevels({
        userId,
        trajectory,
      });
    }
  }, [userId, trajectory]);

  if (!educationLevels || !trajectory) {
    return <Loader />;
  }

  const hiddenTrainingLevels = trajectoryToHiddenTrainingLevels[trajectory];

  return (
    <section className="level-list">
      {educationLevels
        .filter(res => {
          return !hiddenTrainingLevels.includes(res.educationLevel);
        })
        .map((level, index) => (
          <LevelListElement
            key={index}
            setActive={setActive}
            disabled={level.epguOnly}
            active={level.educationLevel === currentLevel}
            level={level.educationLevel}
            name={educationLevelToText[level.educationLevel]}
          />
        ))}
      <Tooltip
        anchorSelect=".level-list__level-element--disabled"
        clickable
        style={{ maxWidth: 400, padding: '10px 15px' }}
        place="bottom"
        variant="dark"
      >
        <EpguWarning />
      </Tooltip>
    </section>
  );
};

export default LevelStatementsList;
