import React, { FC } from 'react';

import { Controller, useForm } from 'react-hook-form';

import TemplateModal from 'components/common/modals/TemplateModal';
import Button from 'components/common/ui-kit/Button';
import FileUploaderV2 from 'components/common/ui-kit/FileUploaderV2';
import ReactSelect from 'components/common/ui-kit/Select/view';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import terms from 'i18n';
import { CompetitiveGroupStatus } from 'models/applications/applications';
import { SelectOption } from 'models/common';
import { createToast } from 'redux/actions';
import { adminApplicationsApi } from 'redux/api/staff/admin/admin-applications-api';
import { modalDataSelector } from 'redux/selectors/common/modal';
import { createSelectProps } from 'utils/form-helpers';

const statusOptions: SelectOption<CompetitiveGroupStatus>[] = [
  { value: CompetitiveGroupStatus.NEW, label: 'Новое' },
  {
    value: CompetitiveGroupStatus.RECEIVED_BY_UNIVERSITY,
    label: 'Получено вузом',
  },
  {
    value: CompetitiveGroupStatus.ADDITIONAL_INFORMATION_REQUIRED,
    label: 'Запрос дополнительных сведений',
  },
  {
    value: CompetitiveGroupStatus.UNDER_CONSIDERATION,
    label: 'На рассмотрени',
  },
  {
    value: CompetitiveGroupStatus.PROFILE_EDITING,
    label: 'Редактирование профиля',
  },
  {
    value: CompetitiveGroupStatus.EXAM_DATE_ASSIGNED,
    label: 'Назначена дата ВИ',
  },
  { value: CompetitiveGroupStatus.EXAM_DATE_SELECTION, label: 'Выбор даты ВИ' },
  {
    value: CompetitiveGroupStatus.ON_COMPETITION,
    label: 'Участвует в конкурсе',
  },
  { value: CompetitiveGroupStatus.AGREEMENT, label: 'Согласие' },
  {
    value: CompetitiveGroupStatus.COMPETITION_FAILED,
    label: 'Не прошло по конкурсу',
  },
  {
    value: CompetitiveGroupStatus.INCLUDED_IN_ENROLLMENT_ORDER,
    label: 'Включено в приказ на зачисление',
  },
  { value: CompetitiveGroupStatus.REFUSED, label: 'Отклонено' },
  { value: CompetitiveGroupStatus.AGREEMENT_REVOKED, label: 'Отзыв согласия' },
  {
    value: CompetitiveGroupStatus.ENROLLMENT_REFUSED,
    label: 'Отказ от зачисления',
  },
  { value: CompetitiveGroupStatus.REVOKED, label: 'Отозвано' },
  {
    value: CompetitiveGroupStatus.AGREEMENT_EDUCATION_DOCUMENT,
    label: 'Согласие/Документ об образовании',
  },
  {
    value: CompetitiveGroupStatus.AGREEMENT_EDUCATION_DOCUMENT_REVOKED,
    label: 'Отзыв согласие/Документ об образовании',
  },
  { value: CompetitiveGroupStatus.ENROLLED, label: 'Зачислен' },
];

const ChangeCompetitiveGroupStatusModal: FC = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(modalDataSelector);

  const {
    control,
    handleSubmit: onSubmit,
    formState: { errors },
  } = useForm<{
    status: CompetitiveGroupStatus;
    file: File;
  }>();

  const selectProps = createSelectProps(errors);

  const [changeCompetitiveGroup] =
    adminApplicationsApi.useChangeCompetitiveGroupStatusMutation();

  const handleSubmit = onSubmit(({ file, status }) => {
    changeCompetitiveGroup({
      file,
      status,
    })
      .unwrap()
      .then(() => dispatch(createToast('Статус изменён', 'success')))
      .catch(() =>
        dispatch(
          createToast('Во время изменения статуса произошла ошибка', 'danger')
        )
      );
  });

  return (
    <TemplateModal title="Смена статусов конкурсных групп">
      <form onSubmit={handleSubmit}>
        <Controller
          control={control}
          name="status"
          rules={{
            required: terms.REQUIRED_FIELD,
          }}
          render={({ field }) => (
            <ReactSelect<SelectOption, true>
              className="filter-form__field"
              placeholder="Статус"
              markRequired={true}
              {...selectProps(field, {
                options: statusOptions,
              })}
            />
          )}
        />
        <Controller
          name="file"
          control={control}
          rules={{
            required: terms.REQUIRED_FIELD,
          }}
          render={({ field: { value, onChange } }) => {
            return (
              <FileUploaderV2
                className="add-file-modal__uploader"
                isMultiple={false}
                accept=".csv"
                value={value}
                onChange={onChange}
              />
            );
          }}
        />
        <Button
          type="submit"
          theme="success"
          className="add-file-modal__button"
        >
          {data?.name ? terms.EDIT : terms.SAVE}
        </Button>
      </form>
    </TemplateModal>
  );
};

export default ChangeCompetitiveGroupStatusModal;
