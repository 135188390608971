import { useEffect, useMemo, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import useAppDispatch from 'hooks/common/useAppDispatch';

import { isRussianLocale } from '../../i18n';
import { ActivateRespForbidden, ActivateRespOk } from '../../models/auth';
import { createToast } from '../../redux/actions';
import { useActivateMutation } from '../../redux/api/common/auth-api';
import { setAuthCredentials } from '../../redux/slices/common/auth-slice';
import { saveAuthCredentialsToStorage } from '../../utils/auth-token';

const useConfirmAccountForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(true);
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const [activate] = useActivateMutation();

  useEffect(() => {
    const code = query.get('code');
    const userId = query.get('userId');
    if (!code || !userId) {
      return navigate('/');
    }

    activate({
      userId: Number(userId),
      code,
    })
      .unwrap()
      .then(response => {
        const data = response as ActivateRespOk;
        dispatch(setAuthCredentials(data.access));
        saveAuthCredentialsToStorage(data.access);
        setLoading(false);
      })
      .catch((response: ActivateRespForbidden) => {
        const errorDescriptionLocale: string = isRussianLocale()
          ? response.data?.descriptionRu ?? response.data.description
          : response.data.description;
        dispatch(createToast(errorDescriptionLocale, 'danger'));
        navigate('/');
      });
  }, []);

  return { loading };
};

export default useConfirmAccountForm;
