import { useState } from 'react';

import { Pagination } from 'models/common';

export const defaultPage = 1;
export const defaultSize = 10;

export const usePagination = (defaultPagination?: Pagination) => {
  const [page, setPage] = useState(defaultPagination?.page ?? defaultPage);
  const [size, setSize] = useState(defaultPagination?.size ?? defaultSize);
  return { page, setPage, size, setSize };
};

export default usePagination;
