import { AdmissionBasis } from 'models/applications/directions';
import { ApplicationEducationLevel } from 'models/applications/enum';
import { AdmissionListEntity, EACAdmissionListResponse } from 'models/students';

const bachelorRussianColumnSet: (keyof EACAdmissionListResponse | string)[] = [
  'userSnilsOrIdWithPrefix',
  'admissionDate',
  'hasOlympiad',
  'entrantTestType',
  'hasAchievement',
  'hasFeature',
  'priority',
  'hasOriginal',
  'hasContractOriginal',
];

const bachelorForeignOrQuotaColumnSet: (
  | keyof EACAdmissionListResponse
  | string
)[] = [
  'userSnilsOrIdWithPrefix',
  'admissionDate',
  'entrantTestType',
  'priority',
  'hasOriginal',
  'hasContractOriginal',
];

const masterColumnSet: (keyof EACAdmissionListResponse | string)[] = [
  'userSnilsOrIdWithPrefix',
  'admissionDate',
  'hasAchievement',
  'priority',
  'hasOriginal',
  'hasContractOriginal',
];

const secondaryColumnSet: (keyof EACAdmissionListResponse | string)[] = [
  'userFullNameOrUniqueId',
  'priorityRight',
  'admissionDate',
  'hasAchievement',
  'hasFeature',
  'priority',
  'hasOriginal',
  'hasContractOriginal',
];

export const admissionColumnKeysToSets: {
  [key in ApplicationEducationLevel]?:
    | {
        [key in AdmissionBasis]: (keyof AdmissionListEntity | string)[];
      }
    | (keyof AdmissionListEntity | string)[];
} = {
  // Бакалавриат
  [ApplicationEducationLevel.BACHELOR]: {
    [AdmissionBasis.BUDGET]: bachelorRussianColumnSet,
    [AdmissionBasis.CONTRACT]: bachelorRussianColumnSet,
    [AdmissionBasis.SPECIAL_QUOTA]: bachelorRussianColumnSet,
    [AdmissionBasis.EXTRA_QUOTA]: bachelorRussianColumnSet,
    [AdmissionBasis.GOVERNMENT]: bachelorRussianColumnSet,
    [AdmissionBasis.QUOTA]: bachelorForeignOrQuotaColumnSet,
    [AdmissionBasis.FOREIGN]: bachelorForeignOrQuotaColumnSet,
  },
  // Магистратура
  [ApplicationEducationLevel.MASTER]: masterColumnSet,
  // Аспирантура
  [ApplicationEducationLevel.GRADUATE]: masterColumnSet,
  // СПО
  [ApplicationEducationLevel.SECONDARY]: secondaryColumnSet,
  // Подготовительный факультет
  [ApplicationEducationLevel.PREPARATORY]: [
    'userSnilsOrIdWithPrefix',
    'hasOriginal',
  ],
};
