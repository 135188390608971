export const moveItem = <Item>(array: Item[], from: number, to: number) =>
  insertItemAtIndex(removeItemAtIndex(array, from), array[from], to);

export const removeItemAtIndex = <Item>(array: Item[], index: number) => [
  ...array.slice(0, index),
  ...array.slice(index + 1),
];

export const insertItemAtIndex = <Item>(
  array: Item[],
  item: Item,
  index: number
) => [...array.slice(0, index), item, ...array.slice(index)];
