import { useEffect } from 'react';

import { useForm } from 'react-hook-form';

import terms from 'i18n';

import {
  EducationLevelsInfo,
  EducationSystemStatus,
} from '../../models/applications/applications';
import { DateString } from '../../models/common';
import { createToast } from '../../redux/actions';
import { expertApplicationsApi } from '../../redux/api/staff/expert/expert-applications-api';
import useAppDispatch from '../common/useAppDispatch';

type ExpertExaminationResultForm = {
  registrationNumber: string;
  applicantFullName: string;
  educationCountryName: string;
  educationSystemStatus?: EducationSystemStatus;
  educationSystemStatusComment?: string;
  needNegotiations?: boolean;
  educationLevelsInfo: EducationLevelsInfo;
  disciplineTransferAllowed: boolean;
  comments: string;
  formationDate: DateString;
};

const useExpertExaminationResultForm = (
  applicationId: number,
  applicantFullName: string,
  educationCountryName: string
) => {
  const { useGetExaminationResultQuery, useSaveExaminationResultMutation } =
    expertApplicationsApi;

  const { data: form } = useGetExaminationResultQuery(applicationId);
  const [saveExaminationResult] = useSaveExaminationResultMutation();
  const dispatch = useAppDispatch();

  const { register, handleSubmit, setValue, reset, getValues, ...rest } =
    useForm<ExpertExaminationResultForm>({
      defaultValues: {
        applicantFullName: applicantFullName,
        educationCountryName: educationCountryName,
        educationLevelsInfo: {
          preparatory: { checked: false },
          secondaryBasic: { checked: false },
          secondaryVocational: { checked: false },
          bachelor: { checked: false },
          speciality: { checked: false },
          master: { checked: false },
          phd: { checked: false },
        },
        disciplineTransferAllowed: false,
        formationDate: new Date().toLocaleDateString('en-CA'),
      },
    });

  useEffect(() => {
    if (form) reset(form);
    else {
      setValue('applicantFullName', applicantFullName);
      setValue('educationCountryName', educationCountryName);
    }
  }, [applicantFullName, educationCountryName, form]);

  const fields = {
    registrationNumber: register('registrationNumber', {
      required: terms.REQUIRED_FIELD,
    }),
    applicantFullName: register('applicantFullName', {
      required: terms.REQUIRED_FIELD,
    }),
    educationCountryName: register('educationCountryName', {
      required: terms.REQUIRED_FIELD,
    }),
    educationSystemStatus: register('educationSystemStatus'),
    educationSystemStatusComment: register('educationSystemStatusComment'),
    needNegotiations: register('needNegotiations'),
    educationLevelsInfo: register('educationLevelsInfo'),
    disciplineTransferAllowed: register('disciplineTransferAllowed'),
    comments: register('comments'),
    formationDate: register('formationDate', {
      required: terms.REQUIRED_FIELD,
    }),
  };

  const onSubmit = handleSubmit(data => {
    saveExaminationResult({
      applicationId: applicationId,
      request: data,
    })
      .unwrap()
      .then(() => {
        dispatch(
          createToast(
            'Бланк предварительной экспертизы успешно создан',
            'success'
          )
        );
      })
      .catch(() => {
        dispatch(
          createToast(
            'При формировании бланка предварительной экспертизы произошла ошибка',
            'danger'
          )
        );
      });
  });

  return {
    fields,
    onSubmit,
    getValues,
    setValue,
    ...rest,
  };
};

export default useExpertExaminationResultForm;
