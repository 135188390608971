import { NavRoute } from 'types/custom';

import terms from 'i18n';

export const statementsTabRotes: NavRoute[] = [
  {
    to: '/trajectory',
    title: terms.NEW_APPLICATION,
    text: '1',
    key: 'trajectory',
    status: 'not visited',
  },
  {
    to: '/training-level',
    title: terms.NEW_APPLICATION,
    text: '2',
    key: 'level',
    status: 'not visited',
  },
  {
    to: '/directions-type',
    title: terms.NEW_APPLICATION,
    text: '3',
    key: 'directions-type',
    status: 'not visited',
  },
  {
    to: '/educational-documents',
    title: terms.NEW_APPLICATION,
    text: '4',
    key: 'educational-documents',
    status: 'not visited',
  },
];
