import { ApplicationEducationLevelResponse } from 'models/applications/application-education-level';
import { DirectionRequestType } from 'models/applications/applications';
import { UserInfoPageableRequest } from 'models/applications/applications-info';
import { Page, Pagination, Sorting } from 'models/common';
import { UserId } from 'models/user';
import { apiSlice } from 'redux/api/api-slice';

import { ApplicationUserEducationalInformation } from '../../../models/applications/application-user-educational-information';
import {
  ApplicationEducationLevel,
  Trajectory,
} from '../../../models/applications/enum';
import { EnrolleeView } from '../../../models/enrollee';

const baseUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/info`;

export const applicationsInfoApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getPossibleEducationBasesForUser: builder.query<
      DirectionRequestType[],
      {
        readonly userId: UserId;
        readonly trajectory: Trajectory;
        readonly educationLevel: ApplicationEducationLevel;
      }
    >({
      query: ({ userId, trajectory, educationLevel }) => ({
        url: `${baseUrl}/possible-user-education-bases/${userId}`,
        method: 'GET',
        params: {
          trajectory,
          educationLevel,
        },
      }),
    }),
    getPossibleEducationLevelsForUser: builder.query<
      ApplicationEducationLevelResponse[],
      {
        readonly userId: UserId;
        readonly trajectory: Trajectory;
      }
    >({
      query: ({ userId, trajectory }) => ({
        url: `${baseUrl}/possible-user-education-levels/${userId}`,
        method: 'GET',
        params: {
          trajectory,
        },
      }),
    }),

    getPossibleTrajectoriesForUser: builder.query<Trajectory[], UserId>({
      query: userId => ({
        url: `${baseUrl}/possible-user-trajectories/${userId}`,
        method: 'GET',
      }),
    }),

    getUserEducationalInformation: builder.query<
      ApplicationUserEducationalInformation[],
      { userId: UserId; educationalLevel?: ApplicationEducationLevel }
    >({
      query: ({ userId, educationalLevel }) => ({
        url: `${baseUrl}/possible-user-educational-information/${userId}`,
        method: 'GET',
        params: {
          educationalLevel,
        },
      }),
    }),

    getAllUserInfoPageable: builder.query<
      Page<EnrolleeView>,
      Pagination & Sorting & { request?: UserInfoPageableRequest }
    >({
      query: ({ page, size, orderBy, orderDirection, request }) => ({
        url: `${baseUrl}/all-user-infos-pageable`,
        method: 'POST',
        body: request,
        params: {
          page,
          size,
          orderBy,
          orderDirection,
        },
      }),
    }),
  }),
});
