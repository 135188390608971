import React, { FC } from 'react';

import CreateContractForm from 'components/statements/CreateContractForm';

const CreateContractPage: FC = () => (
  <div>
    <CreateContractForm />
  </div>
);

export default CreateContractPage;
