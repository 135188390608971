import React, { FC, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as LogoutIcon } from 'assets/images/icons/logout.svg';
import { ReactComponent as PasswordChange } from 'assets/images/icons/password-change.svg';
import Dropdown from 'components/common/ui-kit/Dropdown';
import useAppDispatch from 'hooks/common/useAppDispatch';
import { changeLocale, locale } from 'i18n';
import { resetStore, showModal } from 'redux/actions';
import { deleteAuthCredentialsFromStorage } from 'utils/auth-token';

import { useLocalizedFullName } from '../../../../../hooks/questionnaire/common/draft/useLocalizedFullName';
import { clearAuthCredentials } from '../../../../../redux/slices/common/auth-slice';

const ModeratorMenu: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onLogout = () => {
    deleteAuthCredentialsFromStorage();
    dispatch(clearAuthCredentials());
    dispatch(resetStore());
    navigate('/admin/sign-in');
  };

  useEffect(() => {
    if (locale !== 'ru') {
      changeLocale('ru');
    }
  });

  const {
    name,
    surname,
    isCorrect: isFullNameCorrect,
  } = useLocalizedFullName();

  return (
    <div className="user-menu">
      <div className="user-menu__info user-menu__info-moderator">
        <div className="user-menu__name">
          {isFullNameCorrect ? `${surname} ${name}` : ''}
        </div>
      </div>
      <Dropdown title={''}>
        <div
          className="dropdown-element"
          onClick={() => dispatch(showModal({ name: 'CHANGE_PASSWORD_MODAL' }))}
        >
          <PasswordChange /> Изменить пароль
        </div>
        <div className="dropdown-element" onClick={onLogout}>
          <LogoutIcon width="14" height="12" /> Выйти
        </div>
      </Dropdown>
    </div>
  );
};

export default ModeratorMenu;
