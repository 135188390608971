export type MimeType =
  | 'image/png'
  | 'image/jpg'
  | 'image/jpeg'
  | 'image/gif'
  | 'image/tiff'
  | 'text/plain'
  | 'text/csv'
  | 'application/pdf'
  | 'application/octet-stream'
  | 'application/msword'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export type FileExtension =
  | 'png'
  | 'jpg'
  | 'jpeg'
  | 'gif'
  | 'tiff'
  | 'txt'
  | 'csv'
  | 'pdf'
  | 'doc'
  | 'docx'
  | 'xlsx';

export const mimeTypes: { [key in FileExtension]: MimeType } = {
  png: 'image/png',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  gif: 'image/gif',
  tiff: 'image/tiff',
  csv: 'text/csv',
  txt: 'text/plain',
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const fileExtensions: { [key in MimeType]: FileExtension } = {
  'image/png': 'png',
  'image/jpg': 'jpg',
  'image/jpeg': 'jpeg',
  'image/gif': 'gif',
  'image/tiff': 'tiff',
  'text/csv': 'csv',
  'text/plain': 'txt',
  'application/pdf': 'pdf',
  'application/msword': 'doc',
  'application/octet-stream': 'pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'docx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
};
