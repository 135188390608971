import { useMemo } from 'react';

import { useSaveAddressesDraftMutation } from '../../../redux/api/questionnaire/questionnaire-api';
import useDraft from '../common/draft/useDraft';
import { ForeignAddressInfo } from './useAddressesForm';

export const useAddressesDraft = () => {
  const result = useDraft(
    questionnaire => questionnaire.addressForm,
    useSaveAddressesDraftMutation
  );

  const { draft } = result;

  const foreignRegistrationData: ForeignAddressInfo | undefined =
    useMemo(() => {
      if (!draft) return;
      return (
        draft.foreignRegistrationAddress &&
        JSON.parse(draft.foreignRegistrationAddress)
      );
    }, [draft]);

  const foreignActualData: ForeignAddressInfo | undefined = useMemo(() => {
    if (!draft) return;
    return draft.foreignActualAddress && JSON.parse(draft.foreignActualAddress);
  }, [draft]);

  // Возможно, стоит придумать способ получше, чтобы проверять, заполнялась ли форма ранее
  const isAddressesFormFilled = draft
    ? draft.needDormitory !== undefined && draft.needDormitory !== null
    : false;

  return {
    ...result,
    foreignRegistrationData,
    foreignActualData,
    isAddressesFormFilled,
  };
};
