import { ControllerRenderProps, UseFormRegisterReturn } from 'react-hook-form';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';

import { findSelectOption } from 'utils/enum-select';

const parseFieldName = (name: string) => name.split('.');
const checkArray = (errors: object[], name?: string, idx?: number) =>
  (!!idx || idx === 0) &&
  name !== undefined &&
  !!errors[idx] &&
  !!errors[idx][name];

export const createInputV2Props =
  (errors, disabled?: boolean) =>
  <T extends FieldValues, S extends FieldPath<T>>(
    field: ControllerRenderProps<T, S>
  ) => {
    const [, idx, name] = parseFieldName(field.name);

    return {
      value: field.value,
      onChange: field.onChange,
      error: checkArray(errors, name, Number(idx))
        ? errors[idx][name]?.message
        : errors[field.name]?.message,
      disabled: field.disabled || disabled,
    };
  };

export const createCheckboxProps =
  (errors, disabled?: boolean) => (field: UseFormRegisterReturn) => {
    const [, idx, name] = parseFieldName(field.name);

    return {
      ...field,
      error: checkArray(errors, name, Number(idx))
        ? errors[idx][name]?.message
        : errors[field.name]?.message,
      disabled: disabled || field.disabled,
    };
  };

export const createSelectProps =
  (errors, disabled?: boolean) => (field, selectProps) => {
    const [, idx, name] = parseFieldName(field.name);

    return {
      ...field,
      ...selectProps,
      value: findSelectOption(selectProps.options, field.value),
      onChange: e => field.onChange(e?.value),
      error: selectProps.error
        ? selectProps.error
        : checkArray(errors, name, Number(idx))
        ? errors[idx][name]?.message
        : errors[field.name]?.message,
      isDisabled: disabled || field.disabled,
    };
  };

export const clearValues = values => {
  Object.keys(values).forEach(key => {
    if (values[key] && typeof values[key] === 'object') {
      clearValues(values[key]);
    } else if (!values[key]) {
      delete values[key];
    }
  });
};
