export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum RelationDegree {
  MOTHER = 'MOTHER',
  FATHER = 'FATHER',
  TRUSTEE = 'TRUSTEE',
}

export enum EducationForm {
  DISTANT = 'DISTANT',
  PART_TIME = 'PART_TIME',
  FULL_TIME = 'FULL_TIME',
}

/**
 * @deprecated
 */
export type Country = string;
export type MobileCountryCode = string;
export type IdentificationDocumentType = string;
export type EducationLevel = string;
export type EducationType = string;

export const COUNTRY_ENUM_RUSSIA = 'RUSSIA';
export const RUSSIAN_PASSPORT_ENUM = 'RUSSIAN_PASSPORT';
export const INTERNATIONAL_RUSSIAN_PASSPORT_ENUM =
  'INTERNATIONAL_RUSSIAN_PASSPORT';
export const FOREIGN_PASSPORT_ENUM = 'FOREIGN_PASSPORT';
export const COUNTRY_RUSSIA_ID = 1;
export const COUNTRY_KAZAKHSTAN_ID = 4;
export const COUNTRY_BELARUS_ID = 5;
export const COUNTRY_TAJIKISTAN_ID = 6;
export const COUNTRY_KYRGYZSTAN_ID = 80;
export const COUNTRY_UZBEKISTAN_ID = 7;

export type I18nEnum<T> = {
  [id: string]: T;
};
