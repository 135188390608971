import React, { ReactNode, useEffect, useState } from 'react';

import { UseFormReturn, useForm } from 'react-hook-form';

import AccordionTab from 'components/common/ui-kit/AccordionTab';
import Button from 'components/common/ui-kit/Button';

export type ExtraButton<T> = {
  label: string;
  onClick: (filters: Partial<T>) => void;
};

export type AdminFilterProps<T extends object> = {
  filter: Partial<T>;
  onSubmit: (data: Partial<T>) => void;
  onReset: () => void;
  displayCount?: number;
  displayCountLabel?: string;
  children: (form: UseFormReturn<T>) => ReactNode;
  defaultValues: T;
  onDownloadCsvButtonClick?: () => Promise<any>;
  extraButtons?: ExtraButton<T>[];
};

const AdminFilter = <T extends object>({
  filter,
  onSubmit,
  onReset,
  displayCount,
  displayCountLabel = 'Отображаемое количество',
  children,
  defaultValues,
  onDownloadCsvButtonClick,
  extraButtons,
}: AdminFilterProps<T>) => {
  const form = useForm<T>({ defaultValues: defaultValues as any });
  const [isDownloadCsvButtonDisabled, setIsDownloadCsvButtonDisabled] =
    useState(false);
  useEffect(() => {
    form.reset({ ...defaultValues, ...filter });
  }, [filter]);

  const handleReset = () => {
    onReset();
  };

  return (
    <AccordionTab title="Фильтры">
      <section>
        <form onSubmit={form.handleSubmit(onSubmit)} className="admin-filter">
          {children(form)}
          <footer>
            {displayCount !== undefined && (
              <h2>
                {displayCountLabel}: {displayCount}
              </h2>
            )}
            <div>
              {extraButtons?.map(({ label, onClick }) => (
                <Button
                  type="button"
                  onClick={() => onClick(filter)}
                  key={label}
                >
                  {label}
                </Button>
              ))}
              {onDownloadCsvButtonClick && (
                <Button
                  type="button"
                  onClick={() => {
                    setIsDownloadCsvButtonDisabled(true);
                    onDownloadCsvButtonClick().finally(() =>
                      setIsDownloadCsvButtonDisabled(false)
                    );
                  }}
                  disabled={isDownloadCsvButtonDisabled}
                >
                  Скачать CSV
                </Button>
              )}
              <Button type="button" onClick={handleReset}>
                Сбросить фильтры
              </Button>
              <Button theme="success" type="submit">
                Отфильтровать
              </Button>
            </div>
          </footer>
        </form>
      </section>
    </AccordionTab>
  );
};

export default AdminFilter;
