import React, { FC, useMemo } from 'react';

import Loader from 'components/common/ui-kit/Loader';
import ModeratorDataList from 'components/moderator/ModeratorDataList';
import useAppSelector from 'hooks/common/useAppSelector';
import { ForeignAddressInfo } from 'hooks/questionnaire/addressesDraft/useAddressesForm';
import { DataList } from 'models/moderator';

import CheckListEntryValueSelectWithRoleChecker from '../../../components/moderator/CheckListEntryValueSelectWithRoleChecker';
import {
  selectCurrentApplication,
  selectCurrentQuestionnaire,
} from '../../../redux/selectors/staff/applications';
import { selectCurrentSupportQuestionnaire } from '../../../redux/selectors/staff/questionnaires';

export interface Address extends Partial<ForeignAddressInfo> {
  region?: string | undefined;
  district?: string | undefined;
  litera?: string | undefined;
}

const ModeratorRegistrationAddressPage: FC<{ isSupport?: boolean }> = ({
  isSupport,
}) => {
  const questionnaire = useAppSelector(
    isSupport ? selectCurrentSupportQuestionnaire : selectCurrentQuestionnaire
  );

  const foreignAddressData = useMemo<Address | null>(() => {
    if (
      !questionnaire ||
      !questionnaire.addressForm?.foreignRegistrationAddress
    )
      return null;
    try {
      return JSON.parse(
        questionnaire.addressForm.foreignRegistrationAddress
      ) as ForeignAddressInfo;
    } catch (e) {
      return null;
    }
  }, [questionnaire]);

  const addressData = foreignAddressData;

  const application = useAppSelector(selectCurrentApplication);

  if (!addressData) {
    return <Loader />;
  }

  const list: DataList = [
    // {
    //   title: 'Адрес:',
    //   label: questionnaire.addressForm.foreignRegistrationAddress || '-',
    // },
    {
      title: 'Страна:',
      label: questionnaire?.addressForm?.registrationCountry?.title || '-',
    },
    {
      title: 'Регион:',
      label: addressData.region || '-',
    },
    {
      title: 'Район:',
      label: addressData.district || '-',
    },
    {
      title: 'Населенный пункт:',
      label: addressData.city || '-',
    },
    {
      title: 'Улица:',
      label: addressData.street || '-',
    },
    {
      title: 'Дом:',
      label: addressData.house || '-',
    },
    {
      title: 'Корпус:',
      label: addressData.houseExtension || '-',
    },
    {
      title: 'Квартира:',
      label: addressData.apartment || '-',
    },
    {
      title: 'Индекс:',
      label: addressData.postcode || '-',
    },
    {
      title: 'Необходимость в общежитии на время учебы:',
      label:
        (questionnaire?.addressForm &&
          (questionnaire.addressForm.needDormitory ? 'Да' : 'Нет')) ||
        '-',
    },
  ];

  const additionalList: DataList = [
    {
      title: 'Адрес из ЕПГУ',
      label: questionnaire?.addressForm.fullRegistrationAddress || '-',
    },
  ];

  return (
    <div>
      <ModeratorDataList
        list={[...list, ...(application?.epguInfo ? additionalList : [])]}
      />
      <CheckListEntryValueSelectWithRoleChecker
        getValue={checkList =>
          checkList.unrelatedCheckMarks['registration-address']
        }
        updateValue={newValue => ({
          unrelatedCheckMarks: {
            'registration-address': newValue,
          },
        })}
      />
    </div>
  );
};

export default ModeratorRegistrationAddressPage;
