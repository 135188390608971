import React, { FC, useCallback, useRef } from 'react';

import classNames from 'classnames';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Modify } from 'types/extra';

import Button from 'components/common/ui-kit/Button';
import Separator from 'components/common/ui-kit/Separator';
import ProfileForm from 'components/profile/ProfileForm';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import useProfileRoutesContext from 'hooks/profile/useProfileRoutesContext';
import { useEnrolleeUserId } from 'hooks/questionnaire/common/draft/useQuestionnaire';
import { useTimeoutToast } from 'hooks/questionnaire/common/util/useTimeoutToast';
import { useGetEducationFormWithValidation } from 'hooks/questionnaire/educationInformationDraft/useGetEducationFormWithValidation';
import terms, { isRussianLocale } from 'i18n';
import { SelectOption } from 'models/common';
import {
  EducationInformationDraft,
  SaveEducationInformationRequest,
} from 'models/questionnaire/questionnaire';
import { createToast } from 'redux/actions';
import { useCreateEmptyEducationFormMutation } from 'redux/api/questionnaire/questionnaire-api';

import { UploadFilesFunction } from '../../../hooks/files/useLazyUploadFiles';
import { FileUploadError } from '../../../hooks/files/useUploadFiles';
import { selectIsDraftReadonly } from '../../../redux/selectors/enrollee/questionnaire';
import { EducationFields } from './EducationDocumentFields';

export type EducationInformation = Modify<
  SaveEducationInformationRequest,
  {
    graduatedFromSpbpu: boolean;
    meanGrade?: string;
    russianKladrEducationRegion?: SelectOption;
    educationalInformationDraftId: number;
    epguExternalId?: string;
    epguWithHonorsDocumentGuid?: string;
  }
>;

export type EducationInformationForm = {
  education: EducationInformation[];
};

const EducationForm: FC = () => {
  const readOnly = useAppSelector(selectIsDraftReadonly);
  const { onNextPageNavigate } = useProfileRoutesContext();
  const dispatch = useAppDispatch();

  const form = useForm<EducationInformationForm>({
    mode: 'onChange',
    defaultValues: {
      education: [],
    },
  });

  const { append, remove } = useFieldArray({
    name: 'education',
    control: form.control,
  });

  const educations = form.watch('education');

  const { isDraftValid, saveDraftArray, isEnrolleeRussian, ...draftInfo } =
    useGetEducationFormWithValidation(form);

  const onSubmit = (overwriteData: Partial<EducationInformationDraft>[]) =>
    form.handleSubmit(
      data => {
        const draft = data.education.reduce(
          (acc: EducationInformationDraft[], elem, idx) => {
            delete elem.meanGrade;

            const draftElem: EducationInformationDraft = {
              ...elem,
              ...overwriteData[idx],
              // TODO Stub!
              // educationalForm: EducationForm.FULL_TIME,
              russianKladrEducationRegion:
                elem.russianKladrEducationRegion?.value,
            } as EducationInformationDraft;

            if (!isDraftValid(draftElem, idx)) {
              return acc;
            }

            return [...acc, draftElem];
          },
          []
        );

        saveDraftArray(draft)
          .then(() => onNextPageNavigate())
          .catch(error => {
            const errorDescriptionLocale: string = isRussianLocale()
              ? error.data?.descriptionRu ?? error.data.description
              : error.data.description;
            dispatch(createToast(errorDescriptionLocale, 'danger'));
          });
      },
      errors => console.log({ errors })
    );

  const uploadFunctions = useRef<UploadFilesFunction[]>([]);

  const { dangerToast } = useTimeoutToast();

  const addUploadFilesFunction = useCallback(func => {
    uploadFunctions.current.push(func);
  }, []);

  const removeUploadFilesFunction = useCallback(func => {
    uploadFunctions.current = uploadFunctions.current.filter(el => el !== func);
  }, []);

  const userId = useEnrolleeUserId();

  const [createEmptyEducationForm, { isLoading: isCreatingEducation }] =
    useCreateEmptyEducationFormMutation();

  const handleAddEducation = async () => {
    if (!userId) return;
    try {
      const result = await createEmptyEducationForm(userId).unwrap();
      append({
        ...result,
        graduatedFromSpbpu: false,
        russianKladrEducationRegion: undefined,
      });
    } catch (e) {
      dangerToast('Во время добавления данных об образовании произошла ошибка');
    }
  };

  return (
    <ProfileForm
      onSubmit={
        readOnly
          ? e => {
              e.preventDefault();
              onNextPageNavigate();
            }
          : e => {
              e.preventDefault();
              if (Object.keys(form.formState.errors).length === 0) {
                Promise.all(uploadFunctions.current.map(el => el()))
                  .then(fileIds => {
                    onSubmit(
                      fileIds.map(fileId => ({ documentCopyUploadId: fileId }))
                    )(e);
                  })
                  .catch(error => {
                    if (error === FileUploadError.FILE_NOT_ATTACHED) {
                      dispatch(
                        createToast(
                          terms.EDUCATIONAL_DOCUMENT_IS_REQUIRED,
                          'danger'
                        )
                      );
                    }
                  });
              }
            }
      }
    >
      <FormProvider {...form}>
        {educations.map((education, idx) => (
          <>
            <EducationFields
              education={education}
              isEnrolleeRussian={isEnrolleeRussian}
              formIndex={idx}
              addUploadFilesFunction={addUploadFilesFunction}
              removeUploadFilesFunction={removeUploadFilesFunction}
              onRemove={remove}
            />
            {idx !== educations.length - 1 && (
              <Separator
                className={classNames(
                  'profile-form__separator__secondary',
                  'profile-form__button'
                )}
              />
            )}
          </>
        ))}
        {educations.length < 5 && (
          <Button
            className="profile-form__button"
            disabled={readOnly || isCreatingEducation}
            type="button"
            onClick={handleAddEducation}
          >
            {terms.ADD_DOCUMENT}
          </Button>
        )}
      </FormProvider>
    </ProfileForm>
  );
};

export default EducationForm;
