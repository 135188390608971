import { RootState } from '../../store';

export const selectStaffContractsState = (state: RootState) =>
  state.staffContractSlice;

export const selectStaffContracts = (state: RootState) =>
  selectStaffContractsState(state).elements;

export const selectCurrentStaffContract = (state: RootState) =>
  selectStaffContractsState(state).currentContract;
