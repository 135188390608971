import React, { FC } from 'react';

import { Controller } from 'react-hook-form';

import TemplateModal from 'components/common/modals/TemplateModal';
import Button from 'components/common/ui-kit/Button';
import Input from 'components/common/ui-kit/Input';
import ReactSelect from 'components/common/ui-kit/Select';
import ProfileSection from 'components/profile/ProfileSection';
import useAppSelector from 'hooks/common/useAppSelector';
import useAddAchievementForm from 'hooks/statements/achievements/useAddAchievementForm';
import terms from 'i18n';
import { SelectOption } from 'models/common';
import { modalDataSelector } from 'redux/selectors';

import LazyFileUploader from '../../ui-kit/LazyFileUploader';

const AddIndividualAchievementModal: FC = () => {
  const { applicationId, achievement, refetchAchievementsForm, userId } =
    useAppSelector(modalDataSelector);

  const {
    control,
    fields,
    onSubmit,
    fileProps,
    confirmationDocsSelectOptions,
    typesSelectOptions,
    formState: { errors },
  } = useAddAchievementForm(
    applicationId,
    achievement,
    refetchAchievementsForm,
    userId
  );

  return (
    <TemplateModal
      title={achievement ? 'Редактировать достижение' : 'Добавить достижение'}
    >
      <form onSubmit={onSubmit}>
        <div className="profile-form__fields">
          <Controller
            name={fields.achievementType.name}
            control={control}
            render={({ field }) => (
              <ReactSelect
                options={typesSelectOptions}
                placeholder="Тип достижения"
                className="profile-form__field"
                markRequired={true}
                {...(field as object)}
                error={errors.achievementType?.message}
              />
            )}
          />

          <Input
            className="profile-form__field"
            placeholder="Дата выдачи"
            type="date"
            {...fields.dateOfIssue}
            error={errors.dateOfIssue?.message}
          />

          <Controller
            name={fields.confirmationDocumentType.name}
            control={control}
            render={({ field }) => (
              <ReactSelect<SelectOption>
                options={confirmationDocsSelectOptions}
                placeholder="Подтверждающий документ"
                className="profile-form__field"
                markRequired={true}
                {...(field as object)}
                isDisabled={!confirmationDocsSelectOptions.length}
                error={errors.confirmationDocumentType?.message}
              />
            )}
          />

          <Input
            className="profile-form__field"
            placeholder="Серия (если есть) и номер документа"
            markRequired={true}
            {...fields.documentCode}
            error={errors.documentCode?.message}
          />

          <Input
            className="profile-form__field"
            placeholder="Количество"
            type="number"
            {...fields.amount}
            error={errors.amount?.message}
          />

          <Input
            className="profile-form__field"
            placeholder="Дополнительная информация"
            {...fields.additionalInfo}
            error={errors.additionalInfo?.message}
          />
        </div>

        <ProfileSection title="Документ" markRequired={true}>
          <LazyFileUploader
            className="profile-form__file-uploader"
            {...fileProps}
          />
          {errors.isDocumentAttached && (
            <div className="profile-form__error">
              {errors.isDocumentAttached?.message}
            </div>
          )}
        </ProfileSection>
        <Button
          theme="success"
          className="profile-form__button profile-form__button--align-left"
        >
          {terms.SAVE}
        </Button>
      </form>
    </TemplateModal>
  );
};

export default AddIndividualAchievementModal;
