import React, { FC } from 'react';

import { Outlet } from 'react-router-dom';

import useSubmitFormContext, {
  SubmitFormContextProvider,
} from 'hooks/common/useSubmitFormContext';
import useProfileRoutesContext, {
  ProfileRoutesContextProvider,
} from 'hooks/profile/useProfileRoutesContext';
import terms from 'i18n';
import SidenavPage from 'pages/SidenavPage';

import useAuthInitialization from '../../../hooks/auth/useAuthInitialization';

const ProfilePage: FC = () => {
  useAuthInitialization();

  return (
    <SubmitFormContextProvider>
      <ProfileRoutesContextProvider>
        <SidenavPage
          title={terms.PERSONAL_INFORMATION}
          useHooks={useProfileRoutesContext}
          useSubmitFormHook={useSubmitFormContext}
        >
          <Outlet />
        </SidenavPage>
      </ProfileRoutesContextProvider>
    </SubmitFormContextProvider>
  );
};

export default ProfilePage;
