import { useMemo } from 'react';

import useAppSelector from 'hooks/common/useAppSelector';
import { UserRole } from 'models/user';
import { selectUserRole } from 'redux/selectors';

import { roleSelectOptions } from '../../utils/enum-select';
import { useGetCountriesSelectProps } from '../common/api/useGetCountriesSelectProps';

export const useAddUserFormSelectProps = () => {
  const countries = useGetCountriesSelectProps();
  const role = useAppSelector(selectUserRole);

  const filteredRoleSelectOptions = useMemo(() => {
    if (role === UserRole.SPECIALIST) {
      return roleSelectOptions.filter(option => option.value === 'USER');
    }
    return roleSelectOptions;
  }, [role]);

  return {
    roleSelectOptions: filteredRoleSelectOptions,
    activatedSelectOptions: [
      { value: false, label: 'Нет' },
      { value: true, label: 'Да' },
    ],
    canChooseBachelorOptions: [
      { value: false, label: 'Запрещена' },
      { value: true, label: 'Разрешена' },
    ],
    countries,
  };
};
