import { useNavigate } from 'react-router-dom';

import useAppSelector from 'hooks/common/useAppSelector';
import { DirectionRequestType } from 'models/applications/applications';
import { createToast } from 'redux/actions';

import {
  selectCurrentEducationLevel,
  selectCurrentTrajectory,
  selectDirectionsRequestType,
} from '../../redux/selectors/enrollee/application';

const useDirectionsTypePage = () => {
  const navigate = useNavigate();

  const { id: userId } = useAppSelector(state => state.user);

  const trajectory = useAppSelector(selectCurrentTrajectory);
  const trainingLevel = useAppSelector(selectCurrentEducationLevel);
  const directionsRequestType = useAppSelector(selectDirectionsRequestType);

  const onSubmit = () => {
    if (!userId) return;

    if (trainingLevel && trajectory && directionsRequestType) {
      navigate('../educational-documents');
    } else {
      dispatch(
        createToast('SOMETHING WENT WRONG IN useDirectionsTypePage', 'danger')
      );
    }
  };

  return {
    onSubmit,
    isSubmitButtonDisabled: directionsRequestType === DirectionRequestType.ALL,
  };
};

export default useDirectionsTypePage;
function dispatch(arg0: {
  payload: {
    id: string;
    text: string;
    theme: import('../../models/toast').ToastTheme;
    time: any;
  };
  type: string;
}) {
  throw new Error('Function not implemented.');
}
