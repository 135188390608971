import terms from 'i18n';
import { AdmissionListEntity, RatingListEntityResponse } from 'models/students';

export const columnLabels: {
  [key in
    | keyof (RatingListEntityResponse & AdmissionListEntity)
    | string]?: string;
} = {
  userIdOrUniqueId: terms.SNILS_OR_UNIQUE_CODE,
  userFullNameOrIdOrUniqueId: terms.FULLNAME_OR_SNILS_OR_UNIQUE_CODE,
  hasNoEntranceExamsOrOlympic: terms.NO_ENTRANCE_EXAMS_OR_OLYMPIC,
  fullScore: terms.TOTAL_SCORE,
  subjectScore: terms.TOTAL_SUBJECTS_SCORE,
  priority: terms.PRIORITY,
  state: 'Статус',
  hasOriginalDocuments:
    'Наличие оригинала документа об образовании/отметки о документе об образовании/оригинала договора об оказании платных образовательных услуг',
  hasFeature: 'Преимущественное право',
  achievementScore: 'Баллы за индивидуальные достижения',
  certificateAverage: 'Средний балл аттестата',
  hasGovernmentContract: 'Наличие договора о целевом обучении',
  certificateProfileAverage: 'Средний балл по профильным предметам',
  hasOriginalCertificate: 'Наличие оригинала аттестата',
  needDormitory: 'Нуждаемость в общежитии',
  hasAchievements: 'Наличие индивидуального достижения',
  hasSpecialFeature: 'Наличие особых прав',
  hasOlympiad: 'Олимпиада / БВИ',
  userSnilsOrIdWithPrefix: terms.SNILS_OR_UNIQUE_CODE,
  admissionDate: 'Дата подачи заявления',
  entrantTestType: 'Тип вступительного испытания',
  hasOriginal:
    'Наличие оригинала / отметки оригинала  документа об образовании',
  hasContractOriginal:
    'Наличие оригинала договора об оказании платных образовательных услуг',
  hasAchievement: 'Индивидуальное достижение',
  withoutExam: 'Приоритетное право зачисления',
  priorityRight: 'Приоритетное право зачисления',
  hasAgreement: 'Согласие на зачисление',
};
