import React, { FC } from 'react';

import { useParams } from 'react-router-dom';

import EntranceExamsTable from 'components/statements/EntranceExamsForm/Table';
import terms from 'i18n';

import useAppSelector from '../../../hooks/common/useAppSelector';
import { DocumentTemplateType } from '../../../models/applications/application-documents';
import { ApplicationEducationLevel } from '../../../models/applications/enum';
import { useLazyDownloadTemplateQuery } from '../../../redux/api/common/files-api';
import { selectCurrentEducationLevel } from '../../../redux/selectors/enrollee/application';
import { downloadFile } from '../../../utils/common';
import CardForDownloadFile from '../CardForDownloadFile';

const EntranceExamsForm: FC = () => {
  const { applicationId } = useParams();
  const educationLevel = useAppSelector(selectCurrentEducationLevel);

  const [downloadTemplate] = useLazyDownloadTemplateQuery();

  return (
    <>
      <p className="entry-exams__text">{terms.HINT_EXAM_DATE_TITLE}</p>
      <p className="entry-exams__text">1. {terms.HINT_EXAM_DATE_CLOSE_24H};</p>
      <p className="entry-exams__text">
        2. {terms.HINT_EXAM_DATE_AUTOMATIC_ENTRY};
      </p>
      <p className="entry-exams__text">
        3. {terms.HINT_EXAM_DATE_IF_NOT_PLANNING};
      </p>
      <p className="entry-exams__text">
        4. {terms.HINT_EXAM_DATE_NEED_PRESS_BUTTON};
      </p>
      <p className="entry-exams__text">5. {terms.HINT_EXAM_DATE_MOSCOW_TIME}</p>

      <p className="entry-exams__text">
        <b>{terms.INSTRUCTIONS_WILL_BE_LATER}</b>
      </p>

      {(educationLevel === ApplicationEducationLevel.BACHELOR ||
        educationLevel === ApplicationEducationLevel.MASTER) && (
        <CardForDownloadFile
          className="entry-exams__file"
          showCard={true}
          title={`${terms.ENTRANCE_TESTS_INSTRUCTION}`}
          acceptBlank={true}
          onDownload={() => {
            downloadTemplate(
              educationLevel === ApplicationEducationLevel.BACHELOR
                ? DocumentTemplateType.ENTRANCE_TESTS_INSTRUCTION_BACHELOR
                : DocumentTemplateType.ENTRANCE_TESTS_INSTRUCTION_MASTER
            )
              ?.unwrap()
              .then(result => {
                downloadFile(
                  new File([result], `${terms.ENTRANCE_TESTS_INSTRUCTION}.pdf`)
                );
              });
          }}
        />
      )}

      <EntranceExamsTable applicationId={applicationId} />
    </>
  );
};

export default EntranceExamsForm;
