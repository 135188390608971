import React, { FC } from 'react';

import { Controller } from 'react-hook-form';

import AdminTableListPage from 'components/admin/AdminTableListPage';
import { InputV2 } from 'components/common/ui-kit/Input/view';
import Loader from 'components/common/ui-kit/Loader';
import ReactSelect from 'components/common/ui-kit/Select/view';
import ModeratorFile from 'components/moderator/ModeratorFile';
import useAppDispatch from 'hooks/common/useAppDispatch';
import { ImportTaskStatus, ImportTaskType } from 'models/staff/import-tasks';
import { importUserSchoolTests } from 'pages/moderator/ModeratorStatementsPage/view';
import { createToast, showModal } from 'redux/actions';
import { apiSlice } from 'redux/api/api-slice';
import { useGetCountriesQuery } from 'redux/api/common/enum-api';
import { importTasksApi } from 'redux/api/staff/admin/import-tasks';
import { filesManagerClient } from 'utils/axios-clients';
import { downloadFile } from 'utils/common';
import { createInputV2Props } from 'utils/form-helpers';

const importTaskTypesToStrings: { [key in ImportTaskType]: string } = {
  [ImportTaskType.FIS_GIA]: 'ФИС ГИА',
  [ImportTaskType.MANUAL_COMPETITIVE_GROUP_CHANGE]:
    'Ручная смена конкурсной группы',
};

const importTaskStatusesToStrings: { [key in ImportTaskStatus]: string } = {
  [ImportTaskStatus.NEW]: 'Новая',
  [ImportTaskStatus.IN_PROCESS]: 'В процессе',
  [ImportTaskStatus.SUCCEEDED]: 'Успех',
  [ImportTaskStatus.FAILED]: 'Ошибка',
};

const importTaskTypesOptions = Object.entries(importTaskTypesToStrings).map(
  ([key, value]) => ({
    value: key,
    label: value,
    id: key,
  })
);

const importTaskStatusesOptions = Object.entries(
  importTaskStatusesToStrings
).map(([key, value]) => ({
  value: key,
  label: value,
  id: key,
}));

// export const mapFiltersToRequest = (filters, map) => {
//   const request = {
//     ...filters,
//   };

//   for (const filter in request) {
//     if (Array.isArray(request[filter]) && request[filter].length === 0) {
//       delete request[filter];
//     } else if (request[filter] === '') {
//       delete request[filter];
//     }
//   }

//   if (request.serveMethods) {
//     request.submitWithRecruiter = request.serveMethods.some(
//       el => el.value === ServeMethod.PARTNER
//     );
//   }

//   request.applicationState = filters.applicationState?.map(stateOption => {
//     if (stateOption.value === ApplicationFlag.EDITED_BY_USER) {
//       request.stateEditedByUser = true;
//       return { ...stateOption, value: ApplicationState.IN_WORK };
//     }
//     return stateOption;
//   });

//   if (filters.unreadMessages) {
//     request.unreadMessages =
//       filters.unreadMessages.value === MessagesState.UNREAD;
//   }

//   return map(request);
// };

export const ModeratorStatementsFilter = ({
  control,
  formState: { errors },
}) => {
  const inputV2Props = createInputV2Props(errors);

  return (
    <>
      <Controller
        name="id"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="ID загрузки"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="authorUserId"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="ID автора"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />

      <Controller
        name="type"
        control={control}
        render={({ field }) => (
          <ReactSelect
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={importTaskTypesOptions}
            placeholder="Тип"
            hideSelectedOptions={false}
          />
        )}
      />

      <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <ReactSelect
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={importTaskStatusesOptions}
            placeholder="Статус"
            hideSelectedOptions={false}
          />
        )}
      />

      <Controller
        name="createdAt"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Дата создания"
            type="datetime-local"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />
    </>
  );
};

export const renderStatementsFilter = props => (
  <ModeratorStatementsFilter {...props} />
);

const ImportTasksPage: FC = () => {
  const dispatch = useAppDispatch();
  const { data: countries } = useGetCountriesQuery();

  const handleChangeCompetitiveGroupStatusClick = () => {
    dispatch(
      showModal({
        name: 'CHANGE_COMPETITIVE_GROUP_MODAL',
      })
    );
  };

  const handleImportUserSchoolTests = () => {
    importUserSchoolTests(
      () => {
        dispatch(apiSlice.util.invalidateTags(['ImportTasks']));
      },
      () => {
        dispatch(
          createToast(
            'Во время загрузки результатов ЕГЭ  произошла ошибка',
            'danger'
          )
        );
      }
    );
  };

  if (!countries) {
    return <Loader />;
  }

  return (
    <AdminTableListPage
      storeKey="applications"
      title="Загрузки"
      query={{
        use: importTasksApi.useGetAllImportTasksPageableQuery,
      }}
      displayCountLabel="Загрузок"
      onRowClick={null}
      columns={[
        { Header: 'ID', accessor: 'id' },
        {
          Header: 'Тип',
          accessor: 'type',
          render: task => importTaskTypesToStrings[task.type],
        },
        {
          Header: 'Статус',
          accessor: 'status',
          render: task => importTaskStatusesToStrings[task.status],
        },
        { Header: 'Дата создания', accessor: 'createdAt' },
        { Header: 'Изменено', accessor: 'updatedAt' },
        {
          Header: 'Файл',
          accessor: 'documentId',
          render: task => (
            <ModeratorFile
              title=""
              upload={() => {
                filesManagerClient
                  .get(`/import-files/download/${task.documentId}`, {
                    responseType: 'blob',
                  })
                  .then(response => {
                    downloadFile(new File([response.data], 'Загрузка.csv'));
                  });
              }}
              size={30}
            />
          ),
        },
      ]}
      verticalAlign={'center'}
      filtersConfig={{
        defaultValues: {
          userId: '',
          lastName: '',
          firstName: '',
          middleName: '',
          directionPaymentFormId: undefined,
          directionEducationFormId: undefined,
          citizenshipIds: undefined,
          applicationState: undefined,
          applicationExpertStatus: undefined,
          applicationCoordinatorStatus: undefined,
          unreadMessages: undefined,
          directionName: '',
          facultyIds: undefined,
          recruitedIds: undefined,
          applicationEducationLevel: undefined,
          applicationTrajectory: undefined,
          educationProgram: undefined,
          creationDate: undefined,
          moderatorIds: undefined,
          hasUndefinedFields: undefined,
        },
        renderFilters: renderStatementsFilter,
        extraButtons: [
          {
            label: 'Загрузить результаты ЕГЭ',
            onClick: handleImportUserSchoolTests,
          },
          {
            label: 'Сменить статусы конкурсных групп',
            onClick: handleChangeCompetitiveGroupStatusClick,
          },
        ],
      }}
    />
  );
};

export default ImportTasksPage;
