import React from 'react';

import { Controller } from 'react-hook-form';

import AdminTableListPage from 'components/admin/AdminTableListPage';
import { InputV2 } from 'components/common/ui-kit/Input/view';
import ReactSelect from 'components/common/ui-kit/Select/view';
import { ExamStatementUploadStatus } from 'models/applications/statements';
import { SelectOption } from 'models/common';
import { statementsApi } from 'redux/api/applications/statements-api';
import { getMoscowDateTimeFormattedString } from 'utils/date';
import { createInputV2Props } from 'utils/form-helpers';

export type StatementFilter = {
  campaignName?: string;
  subjectTitle?: string;
  dateOfTest?: string;
  timeOfTest?: string;
};

export const renderRegistryFilter = ({ control, formState: { errors } }) => {
  const inputV2Props = createInputV2Props(errors);
  const hasErrorOptions: SelectOption<ExamStatementUploadStatus>[] = [
    { value: ExamStatementUploadStatus.NOT_UPLOADED, label: 'Не выгружено' },
    {
      value: ExamStatementUploadStatus.UPLOAD_ERROR,
      label: 'Выгружено с ошибкой',
    },
    {
      value: ExamStatementUploadStatus.UPLOADED,
      label: 'Выгружено в 1С',
    },
  ];
  return (
    <>
      <Controller
        name="campaignName"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Название Приемной кампании"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="subjectTitle"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Название предмета"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="dateOfTest"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Дата экзамена"
            type="date"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="timeOfTest"
        control={control}
        render={({ field }) => (
          <InputV2
            placeholder="Время экзамена"
            type="time"
            className="filter-form__field"
            {...inputV2Props(field)}
          />
        )}
      />
      <Controller
        name="uploadStatus"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption>
            styles={{
              valueContainer: provided => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: base => ({ ...base, minWidth: '300px' }),
            }}
            className="filter-form__field"
            {...(field as object)}
            options={hasErrorOptions}
            placeholder="Состояние выгрузки"
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            isClearable={true}
          />
        )}
      />
    </>
  );
};

const registryTableColumns = [
  { Header: 'Название', accessor: 'name' },
  { Header: 'Название предмета', accessor: 'subjectTitle' },
  { Header: 'Номер Приёмной кампании', accessor: 'campaignId' },
  { Header: 'Название Приёмной кампании', accessor: 'campaignName' },
  {
    Header: 'Дата экзамена',
    accessor: 'dateTimeOfTest',
    render: statement =>
      getMoscowDateTimeFormattedString(statement.dateTimeOfTest),
  },
];

const RegistryPage = () => {
  return (
    <AdminTableListPage
      storeKey="registries"
      query={{
        use: statementsApi.useGetAllStatementsPageableQuery,
      }}
      shouldOpenInNewTab
      filtersConfig={{
        defaultValues: {
          campaignName: '',
          subjectTitle: '',
          dateOfTest: '',
          timeOfTest: '',
          uploadStatus: null,
        },
        renderFilters: renderRegistryFilter,
      }}
      title="Ведомости"
      columns={registryTableColumns}
    />
  );
};

export default RegistryPage;
