export enum DadataCountryIsoCode {
  'ALL' = '*',
  'RU' = 'RU',
  'BY' = 'BY',
  'KZ' = 'KZ',
  'UZ' = 'UZ',
}
export type DadataRequest = {
  query: string;
  locations?: { countryIsoCode: DadataCountryIsoCode }[];
};

export type DadataSuggestionAddress = {
  area_with_type: string | null;
  region_with_type: string | null;
  city: string | null;
  settlement_with_type: string | null;
  street_with_type: string | null;
  house: string | null;
  block: string | null;
  postal_code: string | null;
  flat: string | null;
};

export type DadataSuggestion = {
  data: {
    kladr_id: string | null;
    area_kladr_id: string | null;
    region_kladr_id: string | null;
    city_kladr_id: string | null;
    settlement_kladr_id: string | null;
    street_kladr_id: string | null;
    house_kladr_id: string | null;
  } & DadataSuggestionAddress;
  unrestricted_value: string;
  value: string;
};

export type DadataFmsUnit = {
  value: string;
  unrestricted_value: string;
  data: {
    code: string;
    name: string;
    region_code: string;
    type: number;
  };
};
