import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UploadQueueInfoResponse } from 'models/staff/upload-queue';

import { AuditFilters } from '../../../models/staff/audit';

export type AdminUploadQueueState = {
  readonly filters: AuditFilters;
  readonly page: number;
  readonly size: number;
  readonly orderDirection?: 'ASC' | 'DESC';
  readonly orderBy?: 'name' | 'createdAt';
  readonly totalPages: number;
  readonly totalElements: number;
  readonly elements: UploadQueueInfoResponse[];
};

const initialState: AdminUploadQueueState = {
  filters: {},
  page: 1,
  size: 10,
  totalPages: 0,
  totalElements: 0,
  elements: [],
};

export const adminUploadQueueSlice = createSlice({
  name: 'uploadQueueSlice',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    cleanFilters: state => {
      state.filters = {};
    },

    setOrderDirection: (state, action) => {
      if (action.payload) {
        state.orderDirection = action.payload;
      } else {
        state.orderDirection = undefined;
      }
    },

    setOrderBy: (state, action) => {
      if (action.payload) {
        state.orderBy = action.payload;
      } else {
        state.orderBy = undefined;
      }
    },

    setUploads: (
      state,
      action: PayloadAction<{
        readonly uploads: UploadQueueInfoResponse[];
        readonly page: number;
        readonly totalPages: number;
        readonly size: number;
        readonly totalElements: number;
      }>
    ) => {
      state.elements = action.payload.uploads;
      state.size = action.payload.size;
      state.totalPages = action.payload.totalPages;
      state.page = action.payload.page;
      state.totalElements = action.payload.totalElements;
    },

    setPage: (state, action) => {
      state.page = action.payload;
    },

    setSize: (state, action) => {
      state.size = action.payload;
    },
  },
});

export default adminUploadQueueSlice.reducer;
