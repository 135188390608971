import { FC } from 'react';

import classNames from 'classnames';

import { DefaultProps } from 'utils/default-props';

type CenterContainerProps = DefaultProps & {
  fullScreen?: boolean;
};

const CenterContainer: FC<CenterContainerProps> = ({
  children,
  className,
  fullScreen = false,
}) => (
  <div
    className={classNames(
      'center-container',
      { 'center-container--full-screen': fullScreen },
      className
    )}
  >
    {children}
  </div>
);

export default CenterContainer;
