import React, { FC, useLayoutEffect } from 'react';

import {
  UploadFilesFunction,
  useLazyUploadFiles,
} from '../../../../hooks/files/useLazyUploadFiles';
import { UserId } from '../../../../models/user';
import LazyFileUploader from '../../../common/ui-kit/LazyFileUploader';

type MultiLazyFileUploaderProps = {
  disabled?: boolean;
  className?: string;
  filename: string;
  initialFileIds?: number[] | number;
  addUploadFilesFunction: (func: UploadFilesFunction) => void;
  removeUploadFilesFunction: (func: UploadFilesFunction) => void;
  userId?: UserId;
};

const MultiLazyFileUploader: FC<MultiLazyFileUploaderProps> = ({
  addUploadFilesFunction,
  removeUploadFilesFunction,
  filename,
  initialFileIds,
  userId,
  ...restProps
}) => {
  const { uploadFiles, ...filesCallBacks } = useLazyUploadFiles({
    filename,
    initialFileIds,
    userId,
  });

  useLayoutEffect(() => {
    addUploadFilesFunction(uploadFiles);

    return () => {
      removeUploadFilesFunction(uploadFiles);
    };
  }, [uploadFiles]);

  return (
    <LazyFileUploader
      className="profile-form__file-uploader"
      {...filesCallBacks}
      {...restProps}
    />
  );
};

export default MultiLazyFileUploader;
