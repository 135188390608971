import { RootState } from 'types/redux';

import { UserRole } from '../../../models/user';

export const selectUser = (state: RootState) => state.user;

export const selectUserId = (state: RootState) => state.user.id;

export const selectUserRole = (state: RootState) => state.user.role;

export const selectUserProfileImage = (state: RootState) => {
  const image = state.user.profileImageBase64;
  return image ?? null;
};

export const selectIsUserModerator = (state: RootState) =>
  selectUserRole(state) === UserRole.MODERATOR;

export const selectIsUserExpert = (state: RootState) =>
  selectUserRole(state) === UserRole.EXPERT;

export const selectIsUserCommonUser = (state: RootState) =>
  selectUserRole(state) === UserRole.USER;

export const selectIsUserSupport = (state: RootState) =>
  selectUserRole(state) === UserRole.SUPPORT;

export const selectIsUserAdmin = (state: RootState) =>
  selectUserRole(state) === UserRole.ADMIN;
