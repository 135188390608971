import React, { FC, useMemo } from 'react';

import { Navigate } from 'react-router-dom';

import Loader from 'components/common/ui-kit/Loader';
import useAuthInitialization from 'hooks/auth/useAuthInitialization';
import useAppSelector from 'hooks/common/useAppSelector';
import { UserRole } from 'models/user';
import { selectUser } from 'redux/selectors';
import { ChildrenProps } from 'utils/default-props';

// TODO maybe rewrite all routes with this
const AuthChecker: FC<
  ChildrenProps & {
    allowedRoles?: UserRole[];
    redirectUrl?: string;
    signInUrl?: string;
  }
> = ({
  children,
  allowedRoles,
  redirectUrl = '/',
  signInUrl = '/admin/sign-in',
}) => {
  const { token, loading } = useAuthInitialization();
  const { id, role } = useAppSelector(selectUser);
  const isRoleRequirementFulfilled = useMemo(() => {
    if (!allowedRoles) return true;
    return allowedRoles.includes(role);
  }, [role, allowedRoles]);

  if (loading) {
    return <Loader />;
  }

  if (!token) {
    return <Navigate to={signInUrl} />;
  }

  // Old but working trick with id check.
  // Causes quick blink of children before navigating to redirectUrl,
  // but currently is the best option because of two sources of truth (token and user)
  if (!isRoleRequirementFulfilled && id !== null) {
    return <Navigate to={redirectUrl} />;
  }

  return <>{children}</>;
};

export default AuthChecker;
