import { createSlice } from '@reduxjs/toolkit';

import { ApplicationEducationLevel } from 'models/applications/enum';

export type EnrollListsSliceState = {
  currentEducationLevel: ApplicationEducationLevel | undefined;
  currentEducationFormId: number | undefined;
  currentPaymentFormId: number | undefined;
  currentDirectionId: number | undefined;
};

const initialState: EnrollListsSliceState = {
  currentEducationLevel: undefined,
  currentEducationFormId: undefined,
  currentPaymentFormId: undefined,
  currentDirectionId: undefined,
};

export const enrollListsSlice = createSlice({
  name: 'enrollLists',
  initialState,
  reducers: {
    setEducationLevel: (state, action) => {
      state.currentEducationLevel = action.payload;
    },
    setEducationFormId: (state, action) => {
      state.currentEducationFormId = action.payload;
    },
    setPaymentFormId: (state, action) => {
      state.currentPaymentFormId = action.payload;
    },
    setDirectionId: (state, action) => {
      state.currentDirectionId = action.payload;
    },
  },
});

export default enrollListsSlice.reducer;
