import React, { FC } from 'react';

import ReactSelect, { components } from 'react-select';
import { OptionProps } from 'react-select/dist/declarations/src/components/Option';
import { Locale } from 'types/custom';

import { locale, changeLocale } from 'i18n';
import { SelectOption } from 'models/common';

type OptionValue = {
  label: Locale;
  value: number;
};

const options: OptionValue[] = [
  {
    label: 'ru',
    value: 0,
  },
  {
    label: 'en',
    value: 1,
  },
];

const Option: FC<OptionProps<SelectOption>> = ({ children, ...rest }) => {
  if (rest.data.label === locale) {
    return null;
  }

  return <components.Option {...rest}>{children}</components.Option>;
};

const LangChanger: FC = () => (
  <div className="lang-changer">
    <ReactSelect
      defaultValue={options.find(x => x.label === locale)}
      options={options}
      classNamePrefix="lang-changer"
      onChange={option => {
        changeLocale((option as any).label);
      }}
      components={{ Option }}
    />
  </div>
);

export default LangChanger;
