import React, { FC, useEffect, useMemo } from 'react';

import { Controller, useForm } from 'react-hook-form';

import Button from 'components/common/ui-kit/Button';
import Loader from 'components/common/ui-kit/Loader';
import ReactSelect from 'components/common/ui-kit/Select';
import ProfileSection from 'components/profile/ProfileSection';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import useLazyIdEnumSelectPropsWithParams from 'hooks/common/useLazyIdEnumSelectPropsWithParams';
import { DirectionsFormTypes } from 'hooks/statements/directions/useDirectionsForm';
import terms from 'i18n';
import { ApplicationEducationLevel } from 'models/applications/enum';
import { translate } from 'models/common';
import { UserRole } from 'models/user';
import { createToast } from 'redux/actions';
import {
  applicationsApi,
  useSetDesiredDirectionMutation,
} from 'redux/api/applications/applications-api';
import { selectUserRole } from 'redux/selectors';

import { InputV2 } from '../../../components/common/ui-kit/Input/view';
import CheckListEntryValueSelectWithRoleChecker from '../../../components/moderator/CheckListEntryValueSelectWithRoleChecker';
import ModeratorDirectionsElement from '../../../components/moderator/ModeratorDirectionsElement';
import {
  useGetEducationLevelsQuery,
  useLazyGetDirectionsByApplicationIdQuery,
} from '../../../redux/api/applications/directions-api';
import { selectCurrentApplication } from '../../../redux/selectors/staff/applications';
import { collectDirections } from '../../../utils/domain/direction';
import { createInputV2Props } from '../../../utils/form-helpers';

export const DesiredDirectionForm = ({
  readonly,
  directions,
  applicationId,
}: {
  readonly: boolean;
  directions: any[];
  applicationId: number;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DirectionsFormTypes>();

  const [setDesiredDirection] = useSetDesiredDirectionMutation();
  const { data: desiredDirection } =
    applicationsApi.useGetDesiredDirectionsQuery(
      {
        applicationId: Number(applicationId),
      },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const educationLevelSelectProps = useLazyIdEnumSelectPropsWithParams(
    useGetEducationLevelsQuery,
    {
      forPreparatory: true,
    }
  );

  useEffect(() => {
    if (!desiredDirection) return;
    reset({
      directionTraining: desiredDirection.desiredDirection,
      levelEducationId: {
        value: desiredDirection.educationLevel.id,
        label: translate(desiredDirection.educationLevel),
      },
    });
  }, [desiredDirection]);

  const handleFormSubmit = handleSubmit(data => {
    setDesiredDirection({
      applicationId: Number(applicationId),
      directionEducationLevelId: data.levelEducationId.value,
      desiredDirection: data.directionTraining,
    })
      .unwrap()
      .then(() => {
        dispatch(createToast('Желаемое направление изменено', 'success'));
      })
      .catch(() => {
        dispatch(
          createToast(
            'Во время изменения направления произошла ошибка',
            'danger'
          )
        );
      });
  });

  const inputV2Props = createInputV2Props(errors, readonly);

  return (
    <ProfileSection title={terms.PLAN_CONTINUE}>
      <form onSubmit={handleFormSubmit}>
        <div className="profile-form__fields">
          <Controller
            name="directionTraining"
            control={control}
            rules={{
              required: directions?.length !== 0 && terms.REQUIRED_FIELD,
            }}
            render={({ field }) => (
              <InputV2
                className="profile-form__field"
                placeholder={terms.STUDY_FIELD}
                {...inputV2Props(field)}
                markRequired
              />
            )}
          />
          <Controller
            name="levelEducationId"
            rules={{
              required: directions?.length !== 0 && terms.REQUIRED_FIELD,
            }}
            control={control}
            render={({ field }) => (
              <ReactSelect
                placeholder={terms.LEVEL_OF_TRAINING}
                className="profile-form__field"
                markRequired={true}
                {...field}
                options={educationLevelSelectProps.options}
                isDisabled={readonly}
              />
            )}
          />
        </div>
        <Button type="submit" theme="success" disabled={readonly}>
          Сохранить
        </Button>
      </form>
    </ProfileSection>
  );
};

const ModeratorDirectionsPage: FC = () => {
  const application = useAppSelector(selectCurrentApplication);
  const role = useAppSelector(selectUserRole);

  const [getDirections, { data: directions }] =
    useLazyGetDirectionsByApplicationIdQuery();

  useEffect(() => {
    getDirections({
      applicationId: application?.id as number,
      showDeleted: true,
    });
  }, [application]);

  const allDirections = useMemo(
    () => directions && collectDirections(directions),
    [directions]
  );

  if (!allDirections || !application) return <Loader />;

  return (
    <div>
      {allDirections.map((item, index) => (
        <ModeratorDirectionsElement key={index} direction={item} />
      ))}

      {/* {application.educationLevel === ApplicationEducationLevel.PREPARATORY && (
        <DirectionsPreparatoryForm
          readonly={false}
          educationLevel={currentTrainingLevel}
          errors={errors}
          control={control}
          fields={fields}
        />
      )} */}

      {application.educationLevel === ApplicationEducationLevel.PREPARATORY && (
        <DesiredDirectionForm
          directions={allDirections}
          applicationId={application.id}
          readonly={
            !(
              role === UserRole.MODERATOR ||
              role === UserRole.ADMIN ||
              role === UserRole.INSTITUTE_STAFF ||
              role === UserRole.SPECIALIST
            )
          }
        />
      )}

      <CheckListEntryValueSelectWithRoleChecker
        getValue={checkList => checkList.unrelatedCheckMarks['directions']}
        updateValue={newValue => ({
          unrelatedCheckMarks: {
            directions: newValue,
          },
        })}
      />
    </div>
  );
};

export default ModeratorDirectionsPage;
