import React, { FC } from 'react';

import terms from 'i18n';
import { AdmissionListEntity } from 'models/students';

export type EnrollListsTableProps = {
  admissions: AdmissionListEntity[];
};

export const EnrollListsTable: FC<EnrollListsTableProps> = ({ admissions }) => {
  return (
    <section className="table-list">
      <table className="table-list__body">
        <thead>
          <tr>
            <th>№</th>
            <th data-tablefilter-filtertype="input">
              {terms.SNILS_OR_UNIQUE_CODE}
            </th>
            <th>{terms.NO_ENTRANCE_EXAMS_OR_OLYMPIC}</th>
            <th>{terms.TOTAL_SCORE}</th>
            <th>{terms.TOTAL_SUBJECTS_SCORE}</th>
            <th>{terms.MATH}</th>
            <th>{terms.INFORMATICS_OR_PHYSICS}</th>
            <th>{terms.RUSSIAN}</th>
            <th>{terms.PERSONAL_ACHIEVEMENTS_SCORE}</th>
            <th>{terms.FEATURE}</th>
            <th>{terms.EDUCATION_DOCUMENT_TYPE}</th>
            <th>{terms.ENROLLMENT_AGREEMENT}</th>
            <th>{terms.PRIORITY}</th>
          </tr>
        </thead>
        <tbody>
          {admissions.map((admission, index) => (
            <tr key={index}>
              <td></td>
              <td className="text-left">
                {admission.userSnils && admission.userSnils !== '000-000-000 00'
                  ? `${admission.userSnils} / `
                  : null}
                {admission.userUniqueId}
              </td>
              <td>{admission.subjectScore ? terms.YES : terms.NO}</td>
              <td>{admission.fullScore}</td>
              <td>{admission.subjectScore}</td>
              {/* fixme make correct subject mappings once back is done*/}
              <td>{admission.subjects[0]?.score || '-'}</td>
              <td>{admission.subjects[1]?.score || '-'}</td>
              <td>{admission.subjects[2]?.score || '-'}</td>
              <td>{admission.fullScore - admission.subjectScore}</td>
              <td>{admission.hasFeature ? terms.YES : terms.NO}</td>
              <td>-</td>
              <td>{admission.hasFeature ? terms.YES : terms.NO}</td>
              <td>{admission.priority}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};
