import React, { FC, memo, SVGAttributes, useEffect, useRef } from 'react';

import classNames from 'classnames';

import { ReactComponent as CheckDoubleIcon } from 'assets/images/icons/check-double.svg';
import { ReactComponent as CheckIcon } from 'assets/images/icons/check.svg';
import { ReactComponent as ClockIcon } from 'assets/images/icons/clock.svg';
import Attachments from 'components/chat/ChatWidget/ChatPanel/Attachments';

import { urlifyMessage } from '../../../../../../../utils/domain/chat';
import { MessageProps, formatDate } from '../message-commons';

export enum MessageStatus {
  SENT,
  READ,
  PENDING,
}

export type SenderMessageProps = MessageProps & {
  status: MessageStatus;
  senderId: number;
};

const statusToIcon: { [key in MessageStatus]: FC<SVGAttributes<SVGElement>> } =
  {
    [MessageStatus.SENT]: CheckIcon,
    [MessageStatus.READ]: CheckDoubleIcon,
    [MessageStatus.PENDING]: ClockIcon,
  };

const SenderMessage: FC<SenderMessageProps> = ({
  text,
  date,
  status,
  attachments,
  className,
  senderId,
}) => {
  const StatusIcon = statusToIcon[status];
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (text && textRef.current)
      textRef.current.innerHTML = urlifyMessage(text);
  }, [text, textRef]);

  return (
    <div className={classNames('chat-message chat-message--sender', className)}>
      {text && <div ref={textRef} className="chat-message__text"></div>}
      <Attachments
        className="chat-message__attachments"
        chatAttachments={attachments}
        senderId={senderId}
      />
      <div className="chat-message__bottom">
        <div className="chat-message__date">{formatDate(date)}</div>
        <StatusIcon className="chat-message__status" />
      </div>
    </div>
  );
};

export default memo(SenderMessage);
