import React, { FC } from 'react';

import ProgressTabElement from 'components/statements/ProgressTab/ProgressTabElement';
import { progressTabRoutes } from 'config/routes/progress-tab-routes';

import { NavRoute } from '../../../types/custom';

type SideNavProps = {
  className?: string;
  classNameElement?: string;
  activePage?: NavRoute;
};

const ProgressTab: FC<SideNavProps> = ({
  className,
  classNameElement,
  activePage,
}) => (
  <div className={className}>
    {progressTabRoutes.map(route => (
      <ProgressTabElement
        key={route.id}
        active={activePage?.text}
        className={classNameElement}
        route={route}
      />
    ))}
  </div>
);

export default ProgressTab;
