import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EntranceTest } from 'models/applications/entrance-tests';
import {
  ApplicationEducationLevel,
  Trajectory,
} from 'models/applications/enum';

import { ApplicationUserEducationalInformation } from '../../../models/applications/application-user-educational-information';
import {
  ApplicationView,
  DirectionRequestType,
} from '../../../models/applications/applications';

export type ApplicationState = {
  current: {
    readonly trajectory?: Trajectory;
    readonly trainingLevel?: ApplicationEducationLevel;
    readonly educationalInfo?: ApplicationUserEducationalInformation;
    readonly directionsRequestType: DirectionRequestType;
    readonly entranceTests: EntranceTest[];
  };
  selectedApplication: ApplicationView | null;
  isApplicationSubmittable: boolean | null;
};

export const initialState: ApplicationState = {
  current: {
    trajectory: undefined,
    trainingLevel: undefined,
    educationalInfo: undefined,
    directionsRequestType: DirectionRequestType.ALL,
    entranceTests: [],
  },
  selectedApplication: null,
  isApplicationSubmittable: null,
};

export const applicationSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    setCurrentTrajectory: (state, action: PayloadAction<Trajectory>) => {
      state.current.trajectory = action.payload;
    },
    setCurrentTrainingLevel: (
      state,
      action: PayloadAction<ApplicationEducationLevel>
    ) => {
      state.current.trainingLevel = action.payload;
    },
    setCurrentEducationalInfo: (
      state,
      action: PayloadAction<ApplicationUserEducationalInformation>
    ) => {
      state.current.educationalInfo = action.payload;
    },
    cleanCurrentTrajectoryAndLevel: state => {
      state.current.trajectory = undefined;
      state.current.trainingLevel = undefined;
    },

    setSelectedApplication: (state, action) => {
      state.selectedApplication = action.payload;
    },
    setIsApplicationSubmittable: (state, action) => {
      state.isApplicationSubmittable = action.payload;
    },

    setDirectionsRequestType: (state, action) => {
      state.current.directionsRequestType = action.payload;
    },

    setCheckMarked: (state, action) => {
      if (state.selectedApplication)
        state.selectedApplication.checkmarked = action.payload;
    },

    setEntranceTests: (state, action) => {
      state.current.entranceTests = action.payload;
    },
  },
});

export default applicationSlice.reducer;
