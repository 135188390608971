import {
  Draft,
  questionnaireSlice,
} from '../../../../redux/slices/enrollee/questionnaire-slice';
import { RouteStatus } from '../../../../types/custom';
import useAppDispatch from '../../../common/useAppDispatch';

export const useUpdateDraftStatus = (draft: Draft) => {
  const dispatch = useAppDispatch();

  return (status: RouteStatus) =>
    dispatch(
      questionnaireSlice.actions.updateDraftStatus({
        draft,
        status,
      })
    );
};
