import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import terms, { isRussianLocale } from 'i18n';
import { ChosenDirectionWithPriority } from 'models/applications/directions';

import { createToast } from '../../../redux/actions';
import { useLazyAddDirectionsQuery } from '../../../redux/api/applications/applications-api';
import { selectDirectionsRequestType } from '../../../redux/selectors/enrollee/application';
import useAppDispatch from '../../common/useAppDispatch';
import useAppSelector from '../../common/useAppSelector';

const useDirectionTrainingList = (
  savedSelectedDirections?: ChosenDirectionWithPriority[]
) => {
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const requestType = useAppSelector(selectDirectionsRequestType);

  const [addDirections] = useLazyAddDirectionsQuery();
  const [selectedDirections, setSelectedDirections] = useState<
    { id: number; code: string }[]
  >([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!savedSelectedDirections) return;
    const selectedDirectionInfos = savedSelectedDirections
      .sort(
        (direction1, direction2) => direction1.priority - direction2.priority
      )
      .map(direction => ({
        id: direction.direction.id,
        code: direction.direction.code,
      }));
    setSelectedDirections(selectedDirectionInfos);
  }, [savedSelectedDirections]);

  const selectDirection = (direction: { id: number; code: string }) => {
    const dir = selectedDirections.find(dir => dir.id === direction.id);
    if (dir !== undefined) {
      setSelectedDirections([
        ...selectedDirections.filter(item => item.id !== dir.id),
      ]);
      return;
    }
    setSelectedDirections(selectedDirections => [
      ...selectedDirections,
      { id: direction.id, code: direction.code },
    ]);
  };

  const onSubmit = () => {
    if (!applicationId) {
      return;
    }

    addDirections({
      applicationId: +applicationId,
      request: selectedDirections.map((direction, index) => ({
        directionId: direction.id,
        priority: index + 1,
      })),
      requestType,
    })
      .unwrap()
      .then(() => navigate('../'))
      .catch(e => {
        if (e.data?.message && e.data?.messageRu) {
          dispatch(
            createToast(
              isRussianLocale() ? e.data.messageRu : e.data.message,
              'danger'
            )
          );
        } else {
          dispatch(createToast(terms.DIRECTIONS_CHOICE_ERROR, 'danger'));
        }
      });
  };

  return { selectedDirections, onSubmit, selectDirection };
};

export default useDirectionTrainingList;
