import { useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { Modify } from 'types/extra';

import useAppDispatch from 'hooks/common/useAppDispatch';
import { isRussianLocale } from 'i18n';
import { COUNTRY_RUSSIA_ID } from 'models/enum';

import { SaveGeneralDataDraftRequest } from '../../../models/questionnaire/questionnaire';
import { createToast } from '../../../redux/actions';
import { useGetRecruitersQuery } from '../../../redux/api/questionnaire/recruiter-api';
import { isGeneralDataFormSpecified } from '../common/draft/common';
import { useGeneralDataDraft } from '../common/draft/useGeneralDataDraft';
import { useUpdateDraftStatus } from '../common/util/useUpdateDraftStatus';

const NO_SURNAME = '~';

export type GeneralDataForm = Modify<
  SaveGeneralDataDraftRequest,
  {
    noSnils?: boolean;
    noPatronymic?: boolean;
    noSurname?: boolean;

    hasPhoto: boolean;
    readonly recruiter?: number;
  }
>;

export const parseMobile = (
  inputNumber: string | undefined
): string | undefined => {
  return inputNumber ? inputNumber.replace(/[()]/g, '') : inputNumber;
};

const useGeneralDataForm = (onSuccess?: () => void) => {
  const dispatch = useAppDispatch();
  const updateDraftStatus = useUpdateDraftStatus('generalData');
  const { data: recruiters } = useGetRecruitersQuery();

  const { draft, saveDraft, ...draftInfo } = useGeneralDataDraft();

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    reset,
    watch,
    ...rest
  } = useForm<GeneralDataForm>({
    mode: 'all',
  });

  useEffect(() => {
    if (draft && recruiters) {
      updateDraftStatus('not visited');
      const recruiter = recruiters.find(view => view.id === draft.recruiterId);

      reset({
        ...draft,
        noPatronymic: !draft.patronymic && !draft.patronymicLatin,
        primaryCitizenshipCountryId: draft.primaryCitizenshipCountry?.id,
        noSurname:
          draft.surname === NO_SURNAME && draft.surnameLatin === NO_SURNAME,
        hasPhoto: watch('hasPhoto'),
        mobile: parseMobile(draft.mobile),
        recruiter: recruiter?.id,
      });

      if (isGeneralDataFormSpecified(draft)) {
        updateDraftStatus('valid');
      }
    }
  }, [JSON.stringify(draft), JSON.stringify(recruiters)]);

  const mobileCountryCode = watch('mobileCountryCode');
  const mobile = watch('mobile');
  const birthday = watch('birthday');
  const primaryCitizenshipCountryId = watch('primaryCitizenshipCountryId');

  const isRussianCitizenship = useMemo(
    () => primaryCitizenshipCountryId === COUNTRY_RUSSIA_ID,
    [primaryCitizenshipCountryId]
  );

  const noSnils = watch('noSnils');

  const noSurname = watch('noSurname');

  const hasSurname = useMemo(
    () => noSurname || draft?.surname !== null,
    [noSurname, draft?.surname]
  );
  const hasLatinSurname = useMemo(
    () => noSurname || draft?.surnameLatin !== null,
    [noSurname, draft?.surnameLatin]
  );

  const hasName = useMemo(() => draft?.name !== null, [draft?.name]);
  const hasLatinName = useMemo(
    () => draft?.nameLatin !== null,
    [draft?.nameLatin]
  );

  const noPatronymic = watch('noPatronymic');

  const hasPatronymic = useMemo(
    () => draft?.patronymic !== null,
    [draft?.patronymic]
  );
  const hasPatronymicLatin = useMemo(
    () => draft?.patronymicLatin !== null,
    [draft?.patronymicLatin]
  );

  const hasPrimaryCitizenship = useMemo(
    () => draft?.primaryCitizenshipCountry !== null,
    [draft?.primaryCitizenshipCountry]
  );

  const hasBirthday = useMemo(
    () => draft?.birthday !== null,
    [draft?.birthday]
  );

  const hasGender = useMemo(() => draft?.gender !== null, [draft?.gender]);

  const hasMobileCountryCode = useMemo(
    () => draft?.mobileCountryCode !== null,
    [draft?.mobileCountryCode]
  );
  const hasMobilePhone = useMemo(() => draft?.mobile !== null, [draft?.mobile]);

  const isPatronymicRequired = useMemo(
    () => !noPatronymic && isRussianCitizenship,
    [noPatronymic, isRussianCitizenship]
  );
  const isLatinPatronymicRequired = useMemo(
    () => !noPatronymic && !isRussianCitizenship,
    [noPatronymic, isRussianCitizenship]
  );

  const hasSecondEmail = useMemo(
    () => draft?.secondEmail !== null,
    [draft?.secondEmail]
  );

  const hasSnils = useMemo(() => draft?.snils !== null, [draft?.snils]);
  const hasSnilsIssueDate = useMemo(
    () => draft?.snilsIssuedAt !== null,
    [draft?.snilsIssuedAt]
  );
  const hasNoSnils = useMemo(
    () => !!draft?.noSnils || (hasSnils && hasSnilsIssueDate),
    [draft?.noSnils, hasSnils, hasSnilsIssueDate]
  );

  const hasRecruiter = useMemo(
    () => draft?.recruiterId !== null,
    [draft?.recruiterId]
  );

  const onSubmit = handleSubmit(
    values => {
      Object.keys(values).forEach(key => {
        if (values[key] === undefined) {
          delete values[key];
        }
      });
      const draftToSave: SaveGeneralDataDraftRequest = {
        ...(draft ?? {}),
        ...values,
        surname: values.noSurname
          ? NO_SURNAME
          : values.surname ?? draft?.surname,
        surnameLatin: values.noSurname
          ? NO_SURNAME
          : values.surnameLatin ?? draft?.surnameLatin,
        recruiterId:
          values.recruiter !== undefined
            ? values.recruiter
            : draft?.recruiterId,
        primaryCitizenshipCountryId:
          values.primaryCitizenshipCountryId ??
          draft?.primaryCitizenshipCountry?.id,
        patronymic: values.noPatronymic
          ? undefined
          : values.patronymic || undefined,
        patronymicLatin: values.noPatronymic
          ? undefined
          : values.patronymicLatin || undefined,
      };

      saveDraft(draftToSave)
        ?.unwrap()
        .then(() => {
          updateDraftStatus('valid');
          onSuccess?.();
        })
        .catch(error => {
          const errorDescriptionLocale: string = isRussianLocale()
            ? error.data?.descriptionRu ?? error.data.description
            : error.data.description;
          dispatch(createToast(errorDescriptionLocale, 'danger'));
        });
    },
    () => updateDraftStatus('invalid')
  );

  return {
    watch,
    register,
    onSubmit,
    setValue,
    clearErrors,
    hasName,
    hasLatinName,
    hasSurname,
    hasLatinSurname,
    hasPatronymic,
    hasPatronymicLatin,
    isPatronymicRequired,
    isLatinPatronymicRequired,
    hasPrimaryCitizenship,
    hasBirthday,
    hasGender,
    hasMobileCountryCode,
    hasMobilePhone,
    mobileCountryCode,
    noPatronymic,
    hasSecondEmail,
    hasSnils,
    hasSnilsIssueDate,
    hasNoSnils,
    birthday,
    noSnils,
    hasRecruiter,
    mobile,
    isRussianCitizenship,
    ...rest,
    ...draftInfo,
  };
};

export default useGeneralDataForm;
