import React, { FC } from 'react';

import useChatIsEnrollee from 'hooks/chat/useChatIsEnrollee';
import useChatIsStaff from 'hooks/chat/useChatIsStaff';
import useAppSelector from 'hooks/common/useAppSelector';
import terms from 'i18n';
import { ChatType } from 'models/chat';
import { selectChatType, selectUserChats } from 'redux/selectors/common/chat';

import ChatSelector from './ChatSelector';

export const chatTypeToName: { [type in ChatType]: string } = {
  [ChatType.ENROLLEE]: 'Абитуриент',
  [ChatType.COORDINATOR]: 'Координатор',
  [ChatType.EXPERT]: 'Эксперт',
};

const ChatHeader: FC = () => {
  const isEnrollee = useChatIsEnrollee();
  const isChatStaff = useChatIsStaff();
  const userChats = useAppSelector(selectUserChats) || {};
  const chatType = useAppSelector(selectChatType);

  return (
    <div className="chat-header">
      <h1 className="chat-header__title">{terms.CHAT_TITLE}</h1>
      {isEnrollee && (
        <h5 className="chat-header__subtitle">{terms.CHAT_SUBTITLE}</h5>
      )}
      {isChatStaff &&
        (Object.keys(userChats).length === 1 ? (
          <h5 className="chat-header__subtitle">{chatTypeToName[chatType]}</h5>
        ) : (
          <ChatSelector />
        ))}
    </div>
  );
};

export default ChatHeader;
