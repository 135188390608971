import React from 'react';

import useAppDispatch from 'hooks/common/useAppDispatch';

const DirectoryPage = () => {
  const dispatch = useAppDispatch();

  /* useEffect(() => {
    dispatch(getDirectoriesList({ page, perPage }));
  }, [page, perPage]);*/

  /* if (!directoriesList?.data?.length) {
    dispatch(setAdminPageDirectories(1));
    return <Loader />;
  }*/

  return (
    <main className="directory-page">
      <div className="directory-page-header">
        <h1 className="directory-page__title">Справочники</h1>
      </div>
      {/* <TableDirectories rows={directoriesList?.data} />*/}
      <div className="directory-page__pagination">
        {/* {directoriesList?.data.length && (
          <Pagination
            lastPage={directoriesList.lastPage}
            currentPage={directoriesList.currentPage}
            perPage={directoriesList.perPage}
            setPage={setAdminPageDirectories}
            setPerPage={setAdminPerPageDirectories}
          />
        )}*/}
      </div>
    </main>
  );
};

export default DirectoryPage;
