import {
  DadataFmsUnit,
  DadataRequest,
  DadataSuggestion,
} from 'models/questionnaire/dadata';

import { apiSlice } from '../api-slice';

const baseUrl = `${process.env.REACT_APP_USER_MANAGER_API_URL}/dadata`;

export const dadataApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getSuggestions: builder.query<
      { suggestions: DadataSuggestion[] },
      DadataRequest
    >({
      query: request => ({
        method: 'POST',
        url: `${baseUrl}/address`,
        body: request,
      }),
    }),

    getFmsUnitSuggestions: builder.query<
      { suggestions: DadataFmsUnit[] },
      string
    >({
      query: search => ({
        method: 'POST',
        url: `${baseUrl}/fms_unit`,
        body: {
          query: search,
        },
      }),
    }),

    getSuggestionByKladrId: builder.query<
      { suggestions: DadataSuggestion[] },
      string
    >({
      query: search => ({
        method: 'POST',
        url: `${baseUrl}/find-by-id`,
        body: {
          query: search,
        },
      }),
    }),
  }),
});

export const {
  useGetSuggestionsQuery: useGetDadataSuggestionsQuery,
  useGetFmsUnitSuggestionsQuery: useGetDadataFmsSuggestionsQuery,
  useLazyGetSuggestionByKladrIdQuery: useLazyGetDadataSuggestionByKladrIdQuery,
} = dadataApi;
