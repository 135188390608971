import React, { FC } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import Button from 'components/common/ui-kit/Button';
import DocumentList from 'components/statements/DocumentList/view';
import FillingStatementsForm from 'components/statements/FillingStatementsForm';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useFillingStatementsRoutesContext from 'hooks/statements/useFillingStatementsRoutesContext';
import terms from 'i18n';
import {
  DocumentTemplateType,
  DocumentType,
} from 'models/applications/application-documents';
import {
  ApplicationEducationLevel,
  ApplicationState,
} from 'models/applications/enum';
import { createToast, hideModal, showModal } from 'redux/actions';
import {
  applicationsApi,
  useTakeToDirectionsEditingMutation,
  useWithdrawApplicationMutation,
} from 'redux/api/applications/applications-api';
import { useGetStaffApplicationByIdQuery } from 'redux/api/staff/staff-applications-api';
import { useIsImpersonate } from 'utils/domain/impersonate';

import { BLANK_RECEIPT_DOCUMENTS } from '../../../config/constants';
import useDownloadFilesForm from '../../../hooks/statements/documents/useDownloadFilesForm';
import { useDownloadFormCheckboxes } from '../../../hooks/statements/documents/useDownloadFormCheckboxes';
import { applicationDocumentsApi } from '../../../redux/api/applications/application-documents-api';
import { downloadFile } from '../../../utils/common';
import Checkbox from '../../common/ui-kit/Checkbox';
import CardForDownloadFile from '../CardForDownloadFile';

const DownloadFilesForm: FC = () => {
  const { onNextPageNavigate } = useFillingStatementsRoutesContext();
  const isImpersonate = useIsImpersonate();

  const {
    register,
    fields,
    isAllCheckBoxesChecked,
    checkAllCheckboxes,
    onCheckboxChange,
    isDocumentsDisabled,
  } = useDownloadFormCheckboxes();

  const { documents, refetchDocuments, isFormDisabled } = useDownloadFilesForm({
    isAllCheckBoxesChecked,
    checkAllCheckboxes,
  });

  const { applicationId } = useParams();
  const [generateDocument] =
    applicationDocumentsApi.useLazyGenerateDocumentQuery();

  const { data: application, refetch } = useGetStaffApplicationByIdQuery(
    +applicationId!,
    {
      skip: applicationId === undefined && !isImpersonate,
    }
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [takeToDirectionEditingMutationFn] =
    useTakeToDirectionsEditingMutation();
  const [withdrawApplicationMutationFn] = useWithdrawApplicationMutation();
  const [takeBack] = applicationsApi.useTakeBackMutation();
  const navigateToDirections = () => navigate('../directions');
  const takeApplicationBack = () => {
    if (!application) return;
    takeBack(application.id)
      .unwrap()
      .then(() => {
        dispatch(createToast(terms.APPLICATION_TAKEN_FOR_EDITING, 'success'));
        refetch();
      })
      .catch(() => {
        dispatch(createToast(terms.APPLICATION_DELETION_ERROR, 'danger'));
      });
  };
  const onDirectionsEditingClick = () => {
    if (!application) return;
    dispatch(
      showModal({
        name: 'DIRECTIONS_EDITING_MODAL',
        data: {
          onApprove: () => {
            takeToDirectionEditingMutationFn(application.id)
              ?.unwrap()
              .then(application => {
                if (application) navigateToDirections();
              })
              .catch(() => {
                dispatch(
                  createToast(terms.APPLICATION_STATUS_CHANGING_ERROR, 'danger')
                );
              })
              .finally(() => dispatch(hideModal()));
          },
        },
      })
    );
  };

  const withdrawApplication = () => {
    if (!application) return;
    withdrawApplicationMutationFn(application.id)
      .unwrap()
      .then(() => {
        dispatch(createToast(terms.APPLICATION_DELETED, 'success'));
        refetch();
      })
      .catch(() => {
        dispatch(createToast(terms.APPLICATION_DELETION_ERROR, 'danger'));
      });
  };

  return (
    <>
      <FillingStatementsForm
        onSubmit={e => {
          e.preventDefault();
          onNextPageNavigate();
        }}
        className="download-files-form"
      >
        <header className="download-files-form__header">
          <p>{terms.TITLE_BLANK_HEADER}</p>
          <p>{terms.TITLE_BLANK_EL1}</p>
          <p>{terms.TITLE_BLANK_EL2}</p>
          <p>{terms.TITLE_BLANK_EL3}</p>
        </header>

        <DocumentList
          documents={documents?.filter(
            document =>
              ![
                DocumentType.ENROLL_AGREEMENT_DOCUMENT,
                DocumentType.ENROLL_WITHDRAW_DOCUMENT,
              ].includes(document.type)
          )}
          flag={isDocumentsDisabled}
          refetchDocuments={refetchDocuments}
        />

        <CardForDownloadFile
          showCard={true}
          title={terms.COMPLIANCE_LETTER}
          acceptBlank={true}
          code={String(BLANK_RECEIPT_DOCUMENTS)}
          onDownload={() => {
            generateDocument({
              id: Number(applicationId),
              docType: DocumentTemplateType.COMPLIANCE_LETTER_BILINGUAL,
            })
              ?.unwrap()
              .then(result => {
                downloadFile(
                  new File([result], `${terms.COMPLIANCE_LETTER}.docx`)
                );
              });
          }}
        />
        <CardForDownloadFile
          showCard={true}
          title={terms.RECEIPT_FOR_THE_ACCEPTED_ORIGINAL_DOCUMENT_OF_EDUCATION}
          acceptBlank={true}
          code={String(BLANK_RECEIPT_DOCUMENTS)}
          onDownload={() => {
            generateDocument({
              id: Number(applicationId),
              docType:
                DocumentTemplateType.RECEIPT_FOR_ACCEPTED_ORIGINAL_EDUCATION_DOCUMENT,
            })
              ?.unwrap()
              .then(result => {
                downloadFile(
                  new File(
                    [result],
                    `${terms.RECEIPT_FOR_THE_ACCEPTED_ORIGINAL_DOCUMENT_OF_EDUCATION}.docx`
                  )
                );
              });
          }}
        />

        {fields.map((field, index) => (
          <Checkbox
            key={field.id}
            text={field.label}
            {...register(`checkboxes.${index}.checked`, {
              onChange: onCheckboxChange,
            })}
            disabled={isFormDisabled}
          />
        ))}
      </FillingStatementsForm>
      {isImpersonate && (
        <div className="download-files-form__impersonate-controls">
          {/* {application.state === ApplicationState.DRAFT && (*/}
          {/*  <>*/}
          {/*    <Button*/}
          {/*      type="button"*/}
          {/*      theme="success"*/}
          {/*      onClick={event => {*/}
          {/*        event.stopPropagation();*/}
          {/*        // navigateToCreateApplication(el);*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      {terms.SEND_APPLICATION}*/}
          {/*    </Button>*/}
          {/*    <Button*/}
          {/*      type="button"*/}
          {/*      className="statements-container__element-btn-panel_icon"*/}
          {/*      onClick={event => {*/}
          {/*        event.stopPropagation();*/}
          {/*        // deleteApplication(el.id);*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      /!* <TrashIcon />*!/*/}
          {/*    </Button>*/}
          {/*  </>*/}
          {/* )}*/}

          {application?.state === ApplicationState.UNDER_CONSIDERATION && (
            <Button
              type="button"
              theme="success"
              onClick={event => {
                event.stopPropagation();
                takeApplicationBack();
              }}
            >
              {terms.EDIT_APPLICATION}
            </Button>
          )}

          {/* {application.state === ApplicationState.SENT_FOR_EDITING && (*/}
          {/*  <Button*/}
          {/*    type="button"*/}
          {/*    theme="success"*/}
          {/*    onClick={event => {*/}
          {/*      event.stopPropagation();*/}
          {/*      // navigateToCreateApplication(el);*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {terms.SEND_APPLICATION}*/}
          {/*  </Button>*/}
          {/* )}*/}

          {(application?.state === ApplicationState.ALLOWED ||
            application?.state === ApplicationState.ACCEPTED) && (
            <>
              {application.educationLevel !==
                ApplicationEducationLevel.PREPARATORY && (
                <Button
                  type="button"
                  theme="success"
                  onClick={event => {
                    event.stopPropagation();
                    onDirectionsEditingClick();
                  }}
                >
                  {terms.EDIT_FIELDS}
                </Button>
              )}
              <Button
                type="button"
                className="statements-container__element-btn-panel_icon"
                onClick={event => {
                  event.stopPropagation();
                  withdrawApplication();
                }}
              >
                {terms.WITHDRAW_APPLICATION}
              </Button>
            </>
          )}

          {application?.state === ApplicationState.DIRECTIONS_EDITING && (
            <>
              {/* <Button*/}
              {/*  type="button"*/}
              {/*  theme="success"*/}
              {/*  onClick={event => {*/}
              {/*    event.stopPropagation();*/}
              {/*    // navigateToCreateApplication(el);*/}
              {/*  }}*/}
              {/* >*/}
              {/*  {terms.SEND_APPLICATION}*/}
              {/* </Button>*/}
              <Button
                type="button"
                onClick={event => {
                  event.stopPropagation();
                  navigateToDirections();
                }}
              >
                {terms.EDIT_FIELDS}
              </Button>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default DownloadFilesForm;
