import React, { FC, useEffect } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import {
  BLANK_AGREEMENT_TO_ENTER,
  SCENARIO_MASTER,
  STATUS_APPROVED,
  LEVEL_TRAINING_PREPARATION,
  LEVEL_TRAINING_BACHELOR,
  LEVEL_TRAINING_PHD,
} from 'config/constants';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import terms from 'i18n';

const resultExamData = (arr, examData) => {
  if (!arr || !examData) return [];

  const egeColumns: {
    accessor: string;
    Header: string;
  }[] = [];

  examData.map(examList => {
    egeColumns.push({
      accessor: examList[0]?.trial + '',
      Header: [
        ...Array.from(
          new Set(
            examList?.map(item => {
              return item?.disciplineName;
            })
          )
        ),
      ].join('/ '),
    });
  });

  return egeColumns;
};

const studentsHeader = (columnsExam, scenario, trajectory) => {
  const columnsExamToAdd =
    Number(scenario) === LEVEL_TRAINING_PREPARATION ? [] : columnsExam;

  const visibleSum =
    scenario === SCENARIO_MASTER || LEVEL_TRAINING_PREPARATION
      ? []
      : [
          {
            accessor: 'sumBySubject',
            Header: terms.TABLE_GRADE,
          },
        ];

  return [
    {
      accessor: 'id',
      Header: terms.TABLE_NUMBER,
    },
    Number(scenario) !== LEVEL_TRAINING_PREPARATION
      ? {
          accessor: 'code',
          Header: terms.TABLE_ID,
        }
      : {
          accessor: 'fullName',
          Header: terms.TABLE_FULL_NAME,
        },
    Number(scenario) !== LEVEL_TRAINING_PREPARATION && {
      accessor: 'olympiads',
      Header: terms.TABLE_OLYMPIAD,
    },
    ...columnsExamToAdd,
    ...visibleSum,
    (trajectory !== '1' ||
      (Number(scenario) !== LEVEL_TRAINING_PREPARATION &&
        Number(scenario) !== LEVEL_TRAINING_BACHELOR &&
        Number(scenario) !== LEVEL_TRAINING_PHD)) && {
      accessor: 'sumById',
      Header: terms.TABLE_GRADE_ACHIEVEMENTS,
    },
    (trajectory !== '1' ||
      (Number(scenario) !== LEVEL_TRAINING_BACHELOR &&
        Number(scenario) !== LEVEL_TRAINING_PHD)) && {
      accessor: 'sumOfBall',
      sortable: true,
      Header:
        Number(scenario) !== LEVEL_TRAINING_PREPARATION
          ? terms.TABLE_GRADE_ALL
          : terms.TABLE_GRADE_ALL_SPO,
    },
    (trajectory !== '1' ||
      (Number(scenario) !== LEVEL_TRAINING_BACHELOR &&
        Number(scenario) !== LEVEL_TRAINING_PHD)) && {
      accessor: 'original',
      sortable: true,
      Header: terms.TABLE_ORIGINAL,
    },
    (trajectory !== '1' ||
      (Number(scenario) !== LEVEL_TRAINING_BACHELOR &&
        Number(scenario) !== LEVEL_TRAINING_PHD)) &&
      trajectory !== '1' && {
        accessor: 'agreement',
        Header: terms.TABLE_CONSENT,
      },
    (trajectory !== '1' ||
      (Number(scenario) !== LEVEL_TRAINING_BACHELOR &&
        Number(scenario) !== LEVEL_TRAINING_PHD &&
        Number(scenario) !== LEVEL_TRAINING_PREPARATION)) && {
      accessor: 'preemptiveRight',
      Header: terms.TABLE_PRIORITY,
    },
    {
      accessor: 'state',
      Header: terms.TABLE_STATUS,
    },
    Number(scenario) === LEVEL_TRAINING_PREPARATION && {
      accessor: 'needHostel',
      sortable: true,
      Header: terms.TABLE_NEED_HOSTEL,
    },
    Number(scenario) === LEVEL_TRAINING_PREPARATION && {
      accessor: 'profilePoint',
      Header: terms.TABLE_PROFILE_POINT,
    },
    Number(scenario) === LEVEL_TRAINING_PREPARATION && {
      accessor: 'priority',
      Header: terms.TABLE_ADVANTAGE,
    },
  ];
};

type StudentListItem = {
  id: string;
  code: string;
  original: { value: '1'; name: 'Да' } | { value: '0'; name: 'нет' };
  agreement: string;
  sumOfBall: string;
  preemptiveRight: string;
  state: string;
  fullName: string;
  needHostel: { value: '1'; name: 'Да' } | { value: '0'; name: 'нет' };
  profilePoint: string;
  priority: string;
};

const studentsMapper = (
  arr,
  competitiveGroups,
  scenario,
  directionTraining
) => {
  if (!arr) return [];
  const rows: StudentListItem[] = [];

  arr.forEach(item => {
    const rowsExam: number[] = [];

    if (competitiveGroups?.length > 0) {
      competitiveGroups.map(exams => {
        let maxResult = 0;
        exams.map(exam => {
          if (exam?.ege) {
            maxResult =
              (item?.ege?.resultEge?.length > 0 &&
                item.ege.resultEge.reduce((prev, el) => {
                  if (el?.name === exam?.disciplineName) {
                    return Math.max(prev, el?.result);
                  }
                  return prev;
                }, 0)) ||
              0;
          }
        });
        rowsExam[exams[0]?.trial + ''] = maxResult;
      });
    }
    const id = 0;

    const visibleSum = [];
    if (scenario !== SCENARIO_MASTER) {
      visibleSum['sumBySubject'] =
        Object.values(rowsExam)?.reduce((prev, current) => prev + current) || 0;
    }

    rows.push({
      id: item.serialNumber,
      code: item.users.noSnils ? item.users.id : item.users.snils,
      ...(Number(scenario) !== LEVEL_TRAINING_PREPARATION && {
        olympiads: item.olympiads.length > 0 ? 'Да' : 'Нет',
      }),
      original: item.hasOriginal
        ? { value: '1', name: 'Да' }
        : { value: '0', name: 'нет' },
      agreement: item?.statementBlanks?.find(
        el => el.blankId === BLANK_AGREEMENT_TO_ENTER
      )?.acceptBlank
        ? 'Да'
        : 'Нет',
      ...rowsExam,
      ...visibleSum,
      ...(Number(scenario) !== LEVEL_TRAINING_PREPARATION && {
        sumById: id || '-',
      }),
      sumOfBall:
        Number(scenario) !== LEVEL_TRAINING_PREPARATION
          ? (Object.values(rowsExam)?.reduce(
              (prev, current) => prev + current
            ) || 0) + (id || 0)
          : item.gpa,

      preemptiveRight: item.userPreemptiveRight.length > 0 ? 'Да' : 'Нет',
      state:
        item.statusStatement.code === '' + STATUS_APPROVED
          ? 'Подано'
          : 'Не участвует в конкурсе',
      fullName: item.users?.usersTranslate?.length
        ? `${item.users?.usersTranslate[0]?.surname}
        ${item.users?.usersTranslate[0]?.name}
        ${item.users?.usersTranslate[0]?.patronymic ?? ''}`
        : '',
      needHostel: item.users.needHostel
        ? { value: '1', name: 'Да' }
        : { value: '0', name: 'нет' },
      profilePoint: item.competitiveGroups.find(
        group => group.directionTrainingId === directionTraining
      ).pivot.gpa,
      priority: `${
        item.competitiveGroups.findIndex(
          group => group.directionTrainingId === directionTraining
        ) + 1
      }`,
    });
  });
  return rows;
};

type StudentsCompetitiveListItem = {
  id: string;
  code: string;
  olympiads: string;
  original: string;
  agreement: string;
  sumBySubject: string;
  sumById: string;
  sumOfBall: string;
  preemptiveRight: string;
  state: string;
};

const studentsCompetitiveMapper = (arr, competitiveGroups) => {
  if (!arr) return [];
  const rows: StudentsCompetitiveListItem[] = [];

  arr.forEach(item => {
    const rowsExam = [];

    if (competitiveGroups?.length > 0) {
      competitiveGroups.forEach((exams, i) => {
        rowsExam[exams[0]?.trial + ''] = item['subject' + (i + 1)];
      });
    }

    rows.push({
      id: item?.id,
      code: (item?.user?.noSnils ? item.user?.id : item.user?.snils) || '-',
      olympiads: '-',
      original: 'Нет',
      agreement: item?.consentEnrollment ? 'Да' : 'Нет',
      ...rowsExam,
      sumBySubject: item?.sumPointsSubjects || 0,
      sumById: item?.sumPointsIndividualAchievements || 0,
      sumOfBall: item?.sumCompetitivePoints || 0,
      preemptiveRight: item?.preemptiveRightId ? 'Да' : 'Нет',
      state:
        (item.withoutEntranceTest && 'Без вступительных испытаний') || 'Подано',
    });
  });
  return rows;
};

const ListStudents: FC = () => {
  const dispatch = useAppDispatch();
  const { scenario } = useParams<{ scenario: string }>();
  const { pathname } = useLocation();

  const trajectory = scenario?.split('-')[0];
  const level = scenario?.split('-')[1];

  useEffect(() => {
    if (trainingLevels.length === 0) {
      // dispatch(getTrainingLevels(localStorage.getItem('locale') === 'en'));
    }
  }, []);

  useEffect(() => {
    return () => {
      // dispatch(cleanStudentsList());
    };
  }, []);

  const studentsList = useAppSelector(() => []) || [];
  const trainingLevels = useAppSelector(() => []) || [];
  const competitiveGroups = useAppSelector(() => []) || [];

  /* const columnsExam = resultExamData(studentsList.data, competitiveGroups);
  const mappedHeader = studentsHeader(columnsExam, level, trajectory);
  const mappedData = pathname.includes('competitive')
    ? studentsCompetitiveMapper(studentsList.data, competitiveGroups)
    : studentsMapper(
        studentsList.data,
        competitiveGroups,
        level,
        studentsList.directionTraining
      );
  const search = 'useAppSelector(selectSearchStudents)';*/

  return (
    <div className="list-students">
      {/* <Breadcrumbs
        className="list-students__breadcrumbs"
        title={`${
          pathname.includes('competitive')
            ? terms.COMPETITIVE_LISTS
            : terms.LIST_OF_APPLICANTS
        } ${terms.BY_PROGRAMS} ${
          trainingLevels.find(el => el.value === Number(level))?.label || ''
        } ${terms.PROGRAMS}
           ${
             trajectory === '1'
               ? terms.FOR_RUSSIAN_CITIZEN
               : terms.FOR_FOREIGN_CITIZEN
           }`}
      />
      <StudentsFilterForm lastUpdate={studentsList.lastUpdate} />
      {(mappedData.length > 0 || search) && (
        <>
          <TableStudents mappedData={mappedData} columns={mappedHeader} />
          <Pagination
            lastPage={studentsList.lastPage}
            currentPage={studentsList.currentPage}
            perPage={studentsList.perPage}
            setPage={setAdminPageDirectories}
            setPerPage={setAdminPerPageDirectories}
          />
        </>
      )}*/}
    </div>
  );
};

export default ListStudents;
