import {
  CreateGovernmentContractRequest,
  GovernmentContract,
  UpdateGovernmentContractRequest,
} from '../../../models/applications/government-contracts';
import { apiSlice } from '../api-slice';

const baseUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/government-contracts`;

export const governmentContractsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createGovernmentContract: builder.mutation<
      GovernmentContract,
      CreateGovernmentContractRequest
    >({
      query: request => ({
        url: `${baseUrl}/create`,
        method: 'POST',
        body: request,
      }),
    }),

    updateGovernmentContract: builder.mutation<
      GovernmentContract,
      UpdateGovernmentContractRequest
    >({
      query: request => ({
        url: `${baseUrl}/update`,
        method: 'POST',
        body: request,
      }),
    }),

    deleteGovernmentContract: builder.mutation<void, number>({
      query: id => ({
        url: `${baseUrl}/delete/${id}`,
        method: 'DELETE',
      }),
    }),

    getGovernmentContractsByApplicationId: builder.query<
      GovernmentContract[],
      number
    >({
      query: applicationId => ({
        url: `${baseUrl}/by-application-id/${applicationId}`,
      }),
    }),
  }),
});
