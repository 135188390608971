import terms from '../../../i18n';
import { createToast } from '../../../redux/actions';
import { useLazyDownloadFileQuery } from '../../../redux/api/common/files-api';
import { downloadFile } from '../../../utils/common';
import useAppDispatch from '../useAppDispatch';

export const useDownloadFile = () => {
  const dispatch = useAppDispatch();
  const [download] = useLazyDownloadFileQuery();

  return ({
    userId,
    fileId,
    fileName,
  }: {
    readonly userId: number;
    readonly fileId: number;
    readonly fileName: string;
  }) =>
    download({
      fileId,
      userId,
    })
      .unwrap()
      .then(result => downloadFile(new File([result], `${fileName}`)))
      .catch(() => dispatch(createToast(terms.DOWNLOAD_FILE_ERROR, 'danger')));
};
