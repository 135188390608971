import { Page, Pagination } from '../../../../models/common';
import {
  ChangeUserPasswordByAdminRequest,
  CreateUserByAdminRequest,
  UpdateUserByAdminRequest,
  UserByAdminFilters,
} from '../../../../models/staff/user-management';
import { UserData, UserDataRespOk, UserId } from '../../../../models/user';
import { UserDataByStaff } from '../../../slices/staff/userManagementSlice';
import { apiSlice } from '../../api-slice';

const baseUrl = `${process.env.REACT_APP_USER_MANAGER_API_URL}/user-management`;

const authBaseUrl = `${process.env.REACT_APP_USER_MANAGER_API_URL}/auth`;

export const userManagementApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    createUserByAdmin: builder.mutation<UserData, CreateUserByAdminRequest>({
      query: body => ({
        url: `${baseUrl}/create-user`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UsersByAdmin'],
    }),

    activateUser: builder.mutation<
      void,
      {
        readonly userId: number;
      }
    >({
      query: body => ({
        url: `${authBaseUrl}/activate`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UsersByAdmin'],
    }),

    updatePasswordByAdmin: builder.mutation<
      void,
      ChangeUserPasswordByAdminRequest
    >({
      query: body => ({
        url: `${baseUrl}/set-password`,
        method: 'POST',
        body,
      }),
    }),

    updateUserByAdmin: builder.mutation<void, UpdateUserByAdminRequest>({
      query: body => ({
        url: `${baseUrl}/update-user`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UsersByAdmin'],
    }),

    archiveUser: builder.mutation<void, UserId>({
      query: userId => ({
        url: `${baseUrl}/archive-user`,
        method: 'POST',
        params: {
          userId,
        },
      }),
      invalidatesTags: ['UsersByAdmin'],
    }),

    unarchiveUser: builder.mutation<void, UserId>({
      query: userId => ({
        url: `${baseUrl}/unarchive-user`,
        method: 'POST',
        params: {
          userId,
        },
      }),
      invalidatesTags: ['UsersByAdmin'],
    }),

    blockUser: builder.mutation<void, UserId>({
      query: userId => ({
        url: `${baseUrl}/block-user`,
        method: 'POST',
        params: {
          userId,
        },
      }),
      invalidatesTags: ['UsersByAdmin'],
    }),

    unblockUser: builder.mutation<void, UserId>({
      query: userId => ({
        url: `${baseUrl}/unblock-user`,
        method: 'POST',
        params: {
          userId,
        },
      }),
      invalidatesTags: ['UsersByAdmin'],
    }),

    getAllUsersByAdmin: builder.query<
      Page<UserDataByStaff>,
      Pagination & { request: UserByAdminFilters } & {
        readonly staffOnly?: boolean;
      }
    >({
      query: ({ page, request, size }) => ({
        url: `${baseUrl}/users`,
        method: 'POST',
        body: request,
        params: {
          page,
          size,
        },
      }),
      providesTags: ['UsersByAdmin'],
    }),

    getUserById: builder.query<UserDataRespOk, number>({
      query: userId => ({
        url: `${baseUrl}/users/${userId}`,
        method: 'GET',
      }),
    }),

    resetLoginAttemptCount: builder.mutation<void, UserId>({
      query: userId => ({
        url: `${baseUrl}/reset-login-attempt-count`,
        method: 'POST',
        params: {
          userId,
        },
      }),
      invalidatesTags: ['UsersByAdmin'],
    }),
  }),
});
