import React, { FC, memo, useEffect, useRef } from 'react';

import classNames from 'classnames';

import Attachments from 'components/chat/ChatWidget/ChatPanel/Attachments';

import { urlifyMessage } from '../../../../../../../utils/domain/chat';
import { MessageProps, formatDate } from '../message-commons';

export type ReceiverMessageProps = MessageProps & {
  sender: string;
  senderId: number;
};

const ReceiverMessage: FC<ReceiverMessageProps> = ({
  sender,
  senderId,
  text,
  date,
  attachments,
  className,
}) => {
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (text && textRef.current)
      textRef.current.innerHTML = urlifyMessage(text);
  }, [text, textRef]);

  return (
    <div
      className={classNames('chat-message chat-message--receiver', className)}
    >
      <div className="chat-message__name">{sender}</div>
      {text && <div className="chat-message__text" ref={textRef}></div>}
      <Attachments
        className="chat-message__attachments"
        chatAttachments={attachments}
        senderId={senderId}
      />
      <div className="chat-message__bottom">
        <div className="chat-message__date">{formatDate(date)}</div>
      </div>
    </div>
  );
};

export default memo(ReceiverMessage);
