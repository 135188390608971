import { Page, Pagination } from '../../../../models/common';
import {
  EventWithUsers,
  AuditFilters,
  DetailedEvent,
} from '../../../../models/staff/audit';
import { apiSlice } from '../../api-slice';

const baseUrl = `${process.env.REACT_APP_EVENTS_MANAGER_API_URL}/audit`;

export const auditApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAllAuditEventsPageable: builder.query<
      Page<EventWithUsers>,
      Pagination & {
        readonly request: AuditFilters;
        readonly orderDirection?: 'ASC' | 'DESC';
      }
    >({
      query: ({ page, size, request, orderDirection }) => ({
        url: `${baseUrl}/all-pageable`,
        method: 'POST',
        body: request,
        params: {
          page,
          size,
          orderDirection,
        },
      }),
    }),
    getDetailedAuditById: builder.query<DetailedEvent, number>({
      query: id => ({
        url: `${baseUrl}/${id}`,
      }),
    }),
  }),
});
