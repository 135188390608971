import React, { FC } from 'react';

import classnames from 'classnames';

import { ReactComponent as AddIcon } from 'assets/images/icons/add.svg';
import { ReactComponent as FullscreenIcon } from 'assets/images/icons/fullscreen.svg';
import { ReactComponent as TrashIcon } from 'assets/images/icons/trash.svg';
import profileImagePlaceholder from 'assets/images/profile.png';
import useAppDispatch from 'hooks/common/useAppDispatch';
import useAppSelector from 'hooks/common/useAppSelector';
import { useEnrolleeUserId } from 'hooks/questionnaire/common/draft/useQuestionnaire';
import terms from 'i18n';
import { showModal } from 'redux/actions';
import { useIsImpersonate } from 'utils/domain/impersonate';

import { useGetUserAvatar } from '../../../../hooks/common/useGetUserAvatar';
import { useGetQuestionnaireUser } from '../../../../hooks/questionnaire/common/useGetQuestionnaireUser';
import { selectIsDraftReadonly } from '../../../../redux/selectors/enrollee/questionnaire';

type ProfileImageProps = {
  className?: string;
  error?: string;
};

const ProfileImage: FC<ProfileImageProps> = ({ className, error }) => {
  const dispatch = useAppDispatch();
  const user = useGetQuestionnaireUser();
  const enrolleeUserId = useEnrolleeUserId();

  const isImpersonate = useIsImpersonate();

  const onOpenProfileImageModal = (mode: 'upload' | 'edit' | 'delete') => {
    dispatch(
      showModal({
        name: 'EDIT_PROFILE_IMAGE_MODAL',
        data: { mode, user, isImpersonate, userId: enrolleeUserId },
      })
    );
  };

  const readOnly = useAppSelector(selectIsDraftReadonly);
  const { image } = useGetUserAvatar(user?.id, user?.photoUploadId);

  return (
    <div className={classnames(className, 'profile-image-block')}>
      <div className="profile-image-block__title form-subtitle">
        {terms.PHOTO}
      </div>
      <div className="profile-image-block__img-wrapper">
        <img
          src={image || profileImagePlaceholder}
          alt={terms.PHOTO}
          className="profile-image-block__img"
        />

        {!readOnly && (
          <div className="profile-image-block__controls image-controls">
            <div className="image-controls__top-row">
              <button
                className="image-controls__button image-controls__button--theme-rounded"
                type="button"
                onClick={() => onOpenProfileImageModal('delete')}
              >
                <TrashIcon className="image-controls__icon" />
              </button>
            </div>
            <div className="image-controls__bottom-row">
              <button
                className="image-controls__button"
                onClick={() => onOpenProfileImageModal('upload')}
                type="button"
              >
                <AddIcon className="image-controls__icon" />
                <span className="image-controls__label">
                  {terms.SELECT_PHOTO}
                </span>
              </button>
              <button
                type="button"
                className="image-controls__button"
                onClick={() => onOpenProfileImageModal('edit')}
              >
                <FullscreenIcon className="image-controls__icon" />
                <span className="image-controls__label">
                  {terms.EDIT_PHOTO}
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
      {error && <div className="profile-image-block__error">{error}</div>}
    </div>
  );
};

export default ProfileImage;
