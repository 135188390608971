import useAppDispatch from 'hooks/common/useAppDispatch';
import useFillingStatementsRoutesContext from 'hooks/statements/useFillingStatementsRoutesContext';
import terms from 'i18n';
import { createToast, hideModal, showModal } from 'redux/actions';

import { applicationsApi } from '../../../redux/api/applications/applications-api';

const useDirectionsTrainingElement = () => {
  const dispatch = useAppDispatch();

  const { onNextPageNavigate } = useFillingStatementsRoutesContext();
  const [setAgreement] = applicationsApi.useSetAgreementMutation();

  const assent = id => {
    setAgreement({
      applicationDirectionId: id,
      agreement: true,
    })
      ?.unwrap()
      .then(() => {
        dispatch(hideModal());
        dispatch(
          createToast('Согласие на зачисление было подписано', 'success')
        );
        onNextPageNavigate();
      });
  };

  const openModal = (itemId: number) => {
    dispatch(
      showModal({
        name: 'DIRECTIONS_MODAL',
        data: {
          func: () => assent(itemId),
          text: terms.CONFIRM_MODAL,
        },
      })
    );
  };

  return { assent, openModal };
};

export default useDirectionsTrainingElement;
