import React, { FC, useMemo } from 'react';

import Loader from 'components/common/ui-kit/Loader';
import ModeratorDataList from 'components/moderator/ModeratorDataList';
import useAppSelector from 'hooks/common/useAppSelector';
import { ForeignAddressInfo } from 'hooks/questionnaire/addressesDraft/useAddressesForm';
import { DataList } from 'models/moderator';
import { Address } from 'pages/moderator/ModeratorRegistrationAddressPage/view';

import CheckListEntryValueSelectWithRoleChecker from '../../../components/moderator/CheckListEntryValueSelectWithRoleChecker';
import {
  selectCurrentApplication,
  selectCurrentQuestionnaire,
} from '../../../redux/selectors/staff/applications';
import { selectCurrentSupportQuestionnaire } from '../../../redux/selectors/staff/questionnaires';

const ModeratorActualAddressPage: FC<{ isSupport?: boolean }> = ({
  isSupport,
}) => {
  const questionnaire = useAppSelector(
    isSupport ? selectCurrentSupportQuestionnaire : selectCurrentQuestionnaire
  );

  const foreignAddressData = useMemo<Address | null>(() => {
    if (!questionnaire) return null;
    const isAddressesSame = questionnaire.addressForm.isAddressesSame;
    if (isAddressesSame === undefined) return {};
    try {
      if (
        isAddressesSame &&
        questionnaire.addressForm.foreignRegistrationAddress
      ) {
        return JSON.parse(
          questionnaire.addressForm.foreignRegistrationAddress
        ) as ForeignAddressInfo;
      }
      if (questionnaire.addressForm.foreignActualAddress)
        return JSON.parse(
          questionnaire.addressForm.foreignActualAddress
        ) as ForeignAddressInfo;
      return null;
    } catch (e) {
      return null;
    }
  }, [questionnaire]);

  const addressData = foreignAddressData;

  const application = useAppSelector(selectCurrentApplication);

  if (!questionnaire || !addressData) {
    return <Loader />;
  }

  const list: DataList = [
    // {
    //   title: 'Адрес:',
    //   label: questionnaire.addressForm.foreignActualAddress || '-',
    // },
    {
      title: 'Страна:',
      label:
        (questionnaire?.addressForm?.isAddressesSame
          ? questionnaire?.addressForm?.registrationCountry?.title
          : questionnaire?.addressForm?.actualCountry?.title) || '-',
    },
    {
      title: 'Регион:',
      label: addressData.region || '-',
    },
    {
      title: 'Район:',
      label: addressData.district || '-',
    },
    {
      title: 'Населенный пункт:',
      label: addressData.city || '-',
    },
    {
      title: 'Улица:',
      label: addressData.street || '-',
    },
    {
      title: 'Дом:',
      label: addressData.house || '-',
    },
    {
      title: 'Корпус:',
      label: addressData.houseExtension || '-',
    },
    {
      title: 'Квартира:',
      label: addressData.apartment || '-',
    },
    {
      title: 'Индекс:',
      label: addressData.postcode || '-',
    },
    {
      title: 'Необходимость в общежитии на время учебы:',
      label:
        (questionnaire?.addressForm &&
          (questionnaire.addressForm.needDormitory ? 'Да' : 'Нет')) ||
        '-',
    },
  ];

  const additionalList: DataList = [
    {
      title: 'Адрес из ЕПГУ',
      label: questionnaire?.addressForm.fullActualAddress || '-',
    },
  ];

  return (
    <div>
      <ModeratorDataList
        list={[...list, ...(application?.epguInfo ? additionalList : [])]}
      />
      <CheckListEntryValueSelectWithRoleChecker
        getValue={checkList => checkList.unrelatedCheckMarks['actual-address']}
        updateValue={newValue => ({
          unrelatedCheckMarks: {
            'actual-address': newValue,
          },
        })}
      />
    </div>
  );
};

export default ModeratorActualAddressPage;
