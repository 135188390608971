import { AuthCredentials } from '../models/auth';

const AUTH_TOKEN_KEY = 'auth:token';
const AUTH_CREDENTIALS_KEY = 'auth:credentials';

export const saveAuthTokenToStorage = (token: string) =>
  localStorage.setItem(AUTH_TOKEN_KEY, token);

export const deleteAuthTokenFromStorage = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
};

export const getAuthTokenFromStorage = () =>
  localStorage.getItem(AUTH_TOKEN_KEY);

export const saveAuthCredentialsToStorage = (credentials: AuthCredentials) => {
  localStorage.setItem(AUTH_CREDENTIALS_KEY, JSON.stringify(credentials));
};

export const deleteAuthCredentialsFromStorage = () => {
  localStorage.removeItem(AUTH_CREDENTIALS_KEY);
};

export const getAuthCredentialsFromStorage = (): AuthCredentials | null => {
  const authCredentials = localStorage.getItem(AUTH_CREDENTIALS_KEY);
  if (!authCredentials) return null;
  return JSON.parse(authCredentials);
};
