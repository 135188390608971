import React, { PropsWithChildren } from 'react';

import classNames from 'classnames';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { ReactComponent as Checkmark } from 'assets/images/icons/check-mark.svg';
import { ReactComponent as Exclamation } from 'assets/images/icons/exclamation-mark.svg';

type SidenavLinkProps = PropsWithChildren<NavLinkProps> & {
  text?: string;
  status?: 'valid' | 'invalid';
  className?: string;
};

const SidenavLink = ({
  text,
  children,
  status,
  className,
  ...rest
}: SidenavLinkProps) => {
  return (
    <NavLink {...rest} className={classNames('sidenav-link', className)}>
      <h4
        className={classNames('sidenav-link__title', {
          [`sidenav-link__title--${status}`]: status,
        })}
      >
        {status === 'valid' && (
          <Checkmark className="sidenav-link__icon sidenav-link__icon--check" />
        )}
        {status === 'invalid' && (
          <Exclamation className="sidenav-link__icon sidenav-link__icon--exclamation" />
        )}
        {children}
      </h4>
      {text && <span className="sidenav-link__text">{text}</span>}
    </NavLink>
  );
};

export default SidenavLink;
