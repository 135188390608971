import {
  UploadQueueDetailedInfoResponse,
  UploadQueueInfoResponse,
} from 'models/staff/upload-queue';

import { Page, Pagination } from '../../../../models/common';
import { AuditFilters } from '../../../../models/staff/audit';
import { apiSlice } from '../../api-slice';

const baseUrl = `${process.env.REACT_APP_APPLICATIONS_MANAGER_API_URL}/upload-queue`;

export const uploadQueueApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAllUploadQueuePageable: builder.query<
      Page<UploadQueueInfoResponse>,
      Pagination & {
        readonly request: AuditFilters;
      }
    >({
      query: ({ page, size, request }) => ({
        url: `${baseUrl}/all-pageable`,
        method: 'POST',
        body: request,
        params: {
          page,
          size,
        },
      }),
    }),

    getDetailedUploadInfoByUser: builder.query<
      UploadQueueDetailedInfoResponse,
      string | number
    >({
      query: userId => ({
        url: `${baseUrl}/${userId}/details`,
      }),
    }),

    sendWholeUserToQueue: builder.mutation<void, string | number>({
      query: userId => ({
        url: `${baseUrl}/send-to-queue/${userId}`,
        method: 'POST',
      }),
    }),

    sendQuestionnaireToQueue: builder.mutation<void, string | number>({
      query: userId => ({
        url: `${baseUrl}/send-to-queue/${userId}/questionnaire`,
        method: 'POST',
      }),
    }),

    sendRepresentativeToQueue: builder.mutation<void, string | number>({
      query: userId => ({
        url: `${baseUrl}/send-to-queue/${userId}/representative`,
        method: 'POST',
      }),
    }),

    sendEduDocsToQueue: builder.mutation<void, string | number>({
      query: userId => ({
        url: `${baseUrl}/send-to-queue/${userId}/edu-docs`,
        method: 'POST',
      }),
    }),

    sendApplicationToQueue: builder.mutation<void, string | number>({
      query: applicationId => ({
        url: `${baseUrl}/send-to-queue/applications/${applicationId}`,
        method: 'POST',
      }),
    }),

    sendApplicationsToQueue: builder.mutation<void, string | number>({
      query: userId => ({
        url: `${baseUrl}/send-to-queue/${userId}/applications`,
        method: 'POST',
      }),
    }),

    sendOriginalsToQueue: builder.mutation<void, string | number>({
      query: userId => ({
        url: `${baseUrl}/send-to-queue/${userId}/originals`,
        method: 'POST',
      }),
    }),

    sendAgreementsToQueue: builder.mutation<void, string | number>({
      query: userId => ({
        url: `${baseUrl}/send-to-queue/${userId}/agreements`,
        method: 'POST',
      }),
    }),

    sendContractsToQueue: builder.mutation<void, string | number>({
      query: userId => ({
        url: `${baseUrl}/send-to-queue/${userId}/contracts`,
        method: 'POST',
      }),
    }),

    sendSchoolTestsToQueue: builder.mutation<void, string | number>({
      query: userId => ({
        url: `${baseUrl}/send-to-queue/${userId}/school-tests`,
        method: 'POST',
      }),
    }),

    sendOlympiadsToQueue: builder.mutation<void, string | number>({
      query: userId => ({
        url: `${baseUrl}/send-to-queue/${userId}/olympiads`,
        method: 'POST',
      }),
    }),

    sendFeaturesToQueue: builder.mutation<void, string | number>({
      query: userId => ({
        url: `${baseUrl}/send-to-queue/${userId}/features`,
        method: 'POST',
      }),
    }),
  }),
});
