import { Contract } from '../../../models/applications/contracts';
import { applicationDocumentsApi } from '../../../redux/api/applications/application-documents-api';
import { contractApi } from '../../../redux/api/applications/contract-api';
import { useLazyDownloadTemplateQuery } from '../../../redux/api/common/files-api';
import { downloadFile } from '../../../utils/common';

export const useDownloadContractDocument = () => {
  const [generateDocument] =
    applicationDocumentsApi.useLazyGenerateDocumentQuery();

  const [getDocumentType] = contractApi.useLazyGetContractDocumentTypeQuery();

  return (contract: Contract) => {
    getDocumentType(contract.id).then(result => {
      if (!result.data) return;

      const documentType = result.data;

      generateDocument({
        id: contract.id,
        docType: documentType,
      })
        ?.unwrap()
        .then(result => {
          downloadFile(new File([result], 'Договор.docx'));
        });
    });
  };
};

export const useDownloadTemplateDocument = () => {
  const [downloadTemplate] = useLazyDownloadTemplateQuery();

  const [getDocumentType] = contractApi.useLazyGetContractDocumentTypeQuery();

  return (contract: Contract) => {
    getDocumentType(contract.id).then(result => {
      if (!result.data) return;

      const documentType = result.data;

      downloadTemplate(documentType)
        ?.unwrap()
        .then(result => {
          downloadFile(new File([result], 'Незаполненный_договор.docx'));
        });
    });
  };
};
