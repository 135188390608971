import React, { FC } from 'react';

import TemplateAuthForm from 'components/auth/TemplateAuthForm';
import Loader from 'components/common/ui-kit/Loader';
import RouteButtonLink from 'components/common/ui-kit/RouteButtonLink';
import useConfirmAccountForm from 'hooks/auth/useConfirmAccountForm';
import terms from 'i18n';

type EmailConfirmedFormProps = {
  title: string;
};

const EmailConfirmedForm: FC<EmailConfirmedFormProps> = ({ title }) => {
  const { loading } = useConfirmAccountForm();

  if (loading) {
    return <Loader />;
  }

  return (
    <TemplateAuthForm title={title}>
      <div className="auth-form__body auth-form__body--flex-column">
        <p className="auth-form__paragraph">
          {terms.ACCOUNT_CREATED_AND_CONFIRMED}
        </p>
        <RouteButtonLink
          to="/sign-in"
          theme="success"
          className="auth-form__button auth-form__button--only-child"
        >
          {terms.LOG_IN}
        </RouteButtonLink>
      </div>
    </TemplateAuthForm>
  );
};

export default EmailConfirmedForm;
