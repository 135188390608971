import { useState } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { registrationFieldsByLocale } from 'config/constants';
import useAppDispatch from 'hooks/common/useAppDispatch';
import terms, { isRussianLocale, locale } from 'i18n';
import {
  UserRegistrationData,
  RegistrationDataRu,
  RegistrationDataEn,
  SignUpResp,
  SignUpRespForbidden,
} from 'models/auth';
import {
  dateInRange,
  isValidSnils,
  normalizeSnils,
  normalizeYearTo4Characters,
} from 'utils/form-validators';
import regexp from 'utils/regexp';

import { createToast } from '../../redux/actions';
import { useRegisterMutation } from '../../redux/api/common/auth-api';
import { setUser } from '../../redux/slices/common/user-slice';

const useSignUpForm = () => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, clearErrors, setValue, watch, ...rest } =
    useForm<UserRegistrationData>({ mode: 'all' });

  const [signUp] = useRegisterMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isSnilsRequired = watch('noSnils');
  const isBirthdayFilled = !!watch('date_birthday');

  const localeNamePattern = {
    value: locale === 'ru' ? regexp.ru : regexp.en,
    message: terms.ONLY_LOCALE_IS_ALLOWED,
  };

  const fields = {
    surname: register(registrationFieldsByLocale.surname, {
      required: terms.REQUIRED_FIELD,
      pattern: localeNamePattern,
    }),
    name: register(registrationFieldsByLocale.name, {
      required: terms.REQUIRED_FIELD,
      pattern: localeNamePattern,
    }),
    patronymic: register(registrationFieldsByLocale.patronymic, {
      pattern: localeNamePattern,
    }),
    birthday: register('date_birthday', {
      required: terms.REQUIRED_FIELD,
      validate: value => dateInRange(value, 10, 100) || terms.DATE_RANGE,
      onChange: ({ target: { value } }) =>
        value && setValue('date_birthday', normalizeYearTo4Characters(value)),
    }),
    snilsIssuedAt: register('snilsIssuedAt', {
      validate: value => {
        if (value) {
          const birthday = watch('date_birthday');

          if (birthday) {
            if (new Date(birthday) < new Date(value)) {
              setValue('snilsIssuedAt', normalizeYearTo4Characters(value));
            } else if (new Date(value).getFullYear() > 1960) {
              return terms.ERROR_SNILS_ISSUE_BEFORE_BIRTHDAY;
            }
          } else {
            setValue('snilsIssuedAt', undefined);
            return terms.ERROR_FIRST_SPECIFY_BIRTHDAY;
          }
        }
      },
      disabled: isSnilsRequired || !isBirthdayFilled,
    }),
    noSnils: register('noSnils', {
      onChange: event => {
        if (event.target.value) {
          clearErrors('snils');
          clearErrors('snilsIssuedAt');
        }
      },
    }),
    snils: register('snils', {
      required: {
        value: !isSnilsRequired,
        message: terms.REQUIRED_FIELD,
      },
      validate: value => {
        return value && value.length >= 14
          ? isValidSnils(normalizeSnils(value))
            ? true
            : terms.SNILS_INVALID
          : terms.SNILS_INVALID;
      },
      onChange: event => {
        event.preventDefault();
        event.target.value &&
          setValue('snils', normalizeSnils(event.target.value));
      },
      disabled: isSnilsRequired || !isBirthdayFilled,
    }),
    email: register('email', {
      required: terms.REQUIRED_FIELD,
      pattern: {
        value: regexp.email,
        message: terms.VALID_EMAIL,
      },
    }),
    password: register('password', {
      required: 'Обязательное поле',
      pattern: {
        value: regexp.password,
        message: terms.PASSWORD_MIXED,
      },
      minLength: {
        value: 6,
        message: terms.PASSWORD_MIN_LENGTH,
      },
    }),
    accept: register('accept_fz', {
      required: terms.REQUIRED_FIELD,
    }),
    nationality: register('nationality', { required: terms.REQUIRED_FIELD }),
  };

  const onSubmit = handleSubmit((userData: UserRegistrationData) => {
    setLoading(true);

    let name = '';
    let surname = '';
    let patronymic: string | undefined;

    if (locale === 'ru') {
      name = (userData as RegistrationDataRu).name_ru;
      surname = (userData as RegistrationDataRu).surname_ru;
      patronymic = (userData as RegistrationDataRu).patronymic_ru || undefined;
    }
    if (locale === 'en') {
      name = (userData as RegistrationDataEn).name_en;
      surname = (userData as RegistrationDataEn).surname_en;
      patronymic = (userData as RegistrationDataEn).patronymic_en || undefined;
    }

    signUp({
      name,
      surname,
      patronymic,
      email: userData.email,
      username: userData.email,
      password: userData.password,
      primaryCitizenshipCountryId: +userData.nationality.value,
      birthDate: userData.date_birthday,
      prefLang: locale,
      snils: userData.snils,
      snilsIssuedAt: userData.snilsIssuedAt,
      noSnils: isSnilsRequired,
    })
      .unwrap()
      .then(async response => {
        const data = response as Exclude<SignUpResp, SignUpRespForbidden>;
        dispatch(setUser(data));
        navigate('/');
        dispatch(createToast(terms.ACCOUNT_CONFIRMATION_SENT, 'success'));
      })
      .catch((response: SignUpRespForbidden) => {
        const errorDescriptionLocale: string = isRussianLocale()
          ? response.data?.descriptionRu ?? response.data.description
          : response.data.description;
        dispatch(createToast(errorDescriptionLocale, 'danger'));
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return {
    fields,
    setValue,
    onSubmit,
    loading,
    isSnilsRequired,
    isBirthdayFilled,
    ...rest,
  };
};

export default useSignUpForm;
