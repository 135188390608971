import { useForm } from 'react-hook-form';

import { hideModal } from '../../redux/actions';
import { staffApplicationsApi } from '../../redux/api/staff/staff-applications-api';
import useAppDispatch from '../common/useAppDispatch';

type CreateExpertExamineReferralForm = {
  readonly hasIdentificationDocument: boolean;
  readonly hasEducationalDocument: boolean;
  readonly hasEducationGraduationDocuments?: boolean;
  readonly hasEducationDiploma?: boolean;
  readonly hasEducationalDocumentApplication: boolean;
  readonly hasEducationalDocumentsTranslation: boolean;
};

const useModeratorCreateExpertExaminationForm = (
  applicationId: number,
  postSubmit: Function
) => {
  const dispatch = useAppDispatch();
  const [sendToExpert, { isLoading }] =
    staffApplicationsApi.useSendApplicationToExpertMutation();

  const { register, handleSubmit, setValue, reset, getValues, ...rest } =
    useForm<CreateExpertExamineReferralForm>({
      defaultValues: {
        hasIdentificationDocument: false,
        hasEducationalDocument: false,
        hasEducationalDocumentApplication: false,
        hasEducationalDocumentsTranslation: false,
      },
    });

  const fields = {
    hasIdentificationDocument: register('hasIdentificationDocument'),
    hasEducationalDocument: register('hasEducationalDocument'),
    hasEducationGraduationDocuments: register(
      'hasEducationGraduationDocuments'
    ),
    hasEducationDiploma: register('hasEducationDiploma'),
    hasEducationalDocumentApplication: register(
      'hasEducationalDocumentApplication'
    ),
    hasEducationalDocumentsTranslation: register(
      'hasEducationalDocumentsTranslation'
    ),
  };

  const onSubmit = handleSubmit(data => {
    sendToExpert({ applicationId: applicationId, request: { ...data } }).then(
      () => {
        postSubmit();
        dispatch(hideModal());
      }
    );
  });

  return {
    fields,
    setValue,
    getValues,
    onSubmit,
    loading: isLoading,
    ...rest,
  };
};

export default useModeratorCreateExpertExaminationForm;
